/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_array,
    array_to_select,
    timeConverter,
} from "../../helper/array";
import DetailPush from "../../global/push/DetailPush";
import Select from 'react-select';
import CanvasJSReact from '../../helper/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const INIT_STATE={
    data:null,
    DetailPush:{
        status:false,
        data:null
    },
    page:0,
    statics:{
        push:null,
        group:null
    },
    AllGroups:null,
    item_select:null,
    tab_active:0,
    ListAdmin:null,
};

export default class mcclub extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcclub", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    getGroups(){


        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('required_push', 1);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    AllGroups:data.data
                });
            }


        }).catch((e)=>{



        });


    }

    getListAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcclub/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListAdmin:data.data
                });
            }


        }).catch((e)=>{



        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getStaticsPushMarketing();

        this.getGroups();

        this.staticsGroupMarketing();

        this.getListAdmin();
    }



    getStaticsPushMarketing(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcclub/staticpush", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.SetUpStaticsMonthPush(data.data);
            }


        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");


        });


    }

    SetUpStaticsMonthPush(data) {

        var data_report_push = [];

        Object.entries(data).forEach(entry => {
            const [key, value] = entry;

            data_report_push.push({
                x:new Date(key * 1000),
                y:value
            });


        });

        const options = {
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Nhận số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_push
                }

            ]
        };


         var statics=this.state.statics;

        statics.push=options;

        this.setState({
            statics:statics
        });


    }

    staticsGroupMarketing(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcclub/staticgroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.StaticsGroupMonth(data.data);
            }


        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");


        });


    }

    StaticsGroupMonth(data){

        var dataPoints=[];

        if(data.length>0){

            data.map(function (value,key){
                dataPoints.push({
                    label: value.name, y: value.total_send_month,color:value.color
                });
            });

        }

        const options={
            animationEnabled: true,
            theme: "light2",
            title: {
            },
            axisY: {
                title: "Số"
            },
            axisX: {
                title: ""
            },
            data: [{
                type: "column",
                dataPoints: dataPoints
            }]
        };

        var statics=this.state.statics;

        statics.group=options;

        this.setState({
            statics:statics
        });

    }


    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Người gửi</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Team MKT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Bộ môn</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Thời gian</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã kH</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Nguồn</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Giới tính</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td> {value['id_admin']['code_user']}</td>
                                    <td> {value['id_admin']['group_id']!==null && _isset_array(value['id_admin']['group_id'])?(
                                        <>
                                            {value['id_admin']['group_id'].map(function(it){
                                                return(
                                                    <span className={"badge bg-dark font10 rounded-0"}>
                                                        {it.name}
                                                    </span>
                                                );
                                            })}
                                        </>
                                    ):(
                                        <>Chưa rõ</>
                                    )}</td>
                                    <td>
                                        {value.id_subject && value.id_subject.length>0?(
                                            value.id_subject.map(function(i,t){
                                                return(
                                                    <span className={"badge p-1 font10 m-1 bg-primary"}>{i.name}</span>
                                                );
                                            })
                                        ):(
                                            <span className={"badge p-1 font10 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </td>

                                    <td>{timeConverter(value['time_create'])}</td>

                                    <td>{value.id_user?(
                                        <span>{value.id_user.code_user}</span>
                                    ):(
                                        <span>___</span>
                                    )}</td>

                                    <td>{value.id_user && value.id_user.source && value.id_user.source.length>0?(
                                        value.id_user.source.map(function(i,k){
                                            return(
                                                <span className={"badge p-1 font10 m-1 bg-success"}>{i.name}</span>
                                            );
                                        })
                                    ):(
                                        <span className={"badge p-1 font10 bg-danger"}>Chưa rõ</span>
                                    )}</td>




                                    <td>{value.id_user && value.id_user.sex===1?(
                                        <span>Nam</span>
                                    ):(
                                        <span>Nữ</span>
                                    )}</td>


                                    <td className={"fix-item-table"}>
                                        <a onClick={()=>{
                                            self.setState({
                                                DetailPush:{
                                                    status:true,
                                                    data:value
                                                }
                                            });
                                        }} className={"btn btn-outline-primary rounded-0 font12 mt-1"}>
                                            <i className="fas fa-file-import" /> Đẩy số
                                        </a>
                                    </td>




                                </tr>
                            );
                        })}
                        </tbody>
                    </table>


                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-4 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold">
                                   Nhận số Club
                                </h3>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 text-right text-end">

                                <div className="col-12 p-0">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>


                            </div>

                        </div>




                        <div className={"row mt-4 m-0"}>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-3"}>

                                <h3 className="font16 mb-3 text-uppercase font-weight-bold font-weight-bold">
                                    Nhận số marketing trong tháng
                                </h3>

                                {this.state.statics.push!==null?(
                                    <CanvasJSChart options={this.state.statics.push} />
                                ):(
                                    <ItemLoading/>
                                )}




                            </div>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-3"}>

                                <h3 className="font16 mb-3 text-uppercase font-weight-bold font-weight-bold">
                                    Nhận số marketing theo nhóm
                                </h3>

                                {this.state.statics.group!==null?(
                                    <CanvasJSChart options={this.state.statics.group} />
                                ):(
                                    <ItemLoading/>
                                )}


                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3 mt-2"}>
                            {this.thisRenderData()}
                        </div>





                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>







                        {this.state.DetailPush.status===true?(
                            <DetailPush submit={true} TemplateSubmit={()=>{
                                return this.TemplateSubmit();
                            }} data={this.state.DetailPush.data} onClose={()=>{
                                this.setState({
                                    DetailPush:{
                                        status:false,
                                        data:null
                                    }
                                });

                            }} AppThis={this.props.AppThis} />
                        ):(<></>)}




                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    TemplateSubmit(){

        if(this.state.AllGroups===null){
            return(<ItemLoading />);
        }
        else{

            return(
                <div className={"row align-items-end"}>

                    <div className={"col-12 col-sm-12 col-md-6"}>

                        <div className={"row"}>


                            <div className={"col-12 col-sm-12 col-md-12"}>

                                <ul className="nav nav-pills nav-justified mb-3">
                                    <li className="nav-item">
                                        <a onClick={()=>{
                                            this.setState({
                                                tab_active:0,
                                                item_select:null
                                            });
                                        }} className={this.state.tab_active===0?"nav-link rounded-0 text-white bg-primary":"nav-link bg-dark text-white rounded-0"} aria-current="page" href="#">Team</a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={()=>{
                                            this.setState({
                                                tab_active:1,
                                                item_select:null
                                            });
                                        }} className={this.state.tab_active===1?"nav-link rounded-0 text-white bg-primary":"nav-link text-white bg-dark rounded-0"} href="#">Cá nhân</a>
                                    </li>
                                </ul>


                            </div>


                            <div className={"col-12 col-sm-12 col-md-12"}>

                                {this.state.tab_active===0?(
                                    <>
                                        {this.state.AllGroups!==null?(
                                            <Select menuPlacement={"top"} isSearchable={true} onChange={(selectedOption)=>{
                                                this.setState({
                                                    item_select:selectedOption.value
                                                });

                                            }} options={array_to_select(this.state.AllGroups,'name','_id')} />
                                        ):(
                                            <ItemLoading />
                                        )}
                                    </>

                                ):(
                                    <>{this.state.ListAdmin!==null?(
                                        <Select menuPlacement={"top"} isSearchable={true} onChange={(selectedOption)=>{
                                            this.setState({
                                                item_select:selectedOption.value
                                            });

                                        }} options={array_to_select(this.state.ListAdmin,'username','_id')} />
                                    ):(
                                        <ItemLoading />
                                    )}</>

                                )}


                            </div>


                        </div>






                    </div>
                    <div className={"col-12 col-sm-12 col-md-6"}>
                        <a onClick={()=>{
                            this.SubmitPush();
                        }} className={"btn btn-primary rounded-0 w-100"}>
                            <i className="fas fa-layer-group" /> Chuyển số
                        </a>
                    </div>

                </div>
            );
        }

    }

    SubmitPush(){

        if(this.state.item_select===null && this.state.DetailPush.data!==null){

            toast("Vui lòng chọn nhóm hoặc cá nhân");
        }
        else{

           this.props.AppThis.PageLoad(true);

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('action', 'push_item_event');

            formData.append('id_push', this.state.DetailPush.data._id);

            formData.append('id_event', this.state.item_select);

            if(this.state.tab_active===1){
                formData.append('type', 2);

            }

            const self=this;
            fetch(Appthis.ConstApp.home_api+"mcclub/update", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='error'){
                    toast("Cập nhật không thành công");
                    Appthis.PageLoad(false);
                }
                else{
                    toast("Cập nhật thành công");
                    self.getData(true);

                }

                this.setState({
                    DetailPush:{
                        status:false,
                        data:null
                    }
                });

            }).catch((e)=>{
                toast("Cập nhật không thành công");
                Appthis.PageLoad(false);

                this.setState({
                    DetailPush:{
                        status:false,
                        data:null
                    }
                });

            });





        }

    }


}
