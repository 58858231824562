/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "./ItemLoading";
import Breadcrumb from "./Breadcrumb";
import {
    _isset_string, array_to_select,
    base64_encode,
    ConvertStringToDate, get_data_in_args,
    timeConverterDate,
    range, _isset, getItemInArray, validateEmail, _isset_array, _isset_object,
} from "../helper/array";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import {toast} from "react-toastify";
import Select from 'react-select';
import {_Header_DatePicker} from "../helper/DatePicker";
const hiddenFileInput = React.createRef();

export default class Profile extends Component {

    constructor(props) {
        super(props);
        this.state={
            data:null,
            UpdateAdmin:{},
            ActiveTab:1,
            UpdatePassword:{},
            AvatarUpload:null,
            Subject:null,
            GroupData:null
        };

    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {
        this.props.AppThis.SetActionModule(this);
        this.UpdateData();

        this.getSubject();

        this.getGroup();
    }

    async getGroup(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        var get_data=await fetch(AppThis.ConstApp.home_api+"group", {
            method: 'POST',
            body: formData
        });

        get_data=await get_data.json();

        this.setState({
            GroupData:get_data.data
        });


    }




    async getSubject(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        var get_data=await fetch(AppThis.ConstApp.home_api+"subject", {
            method: 'POST',
            body: formData
        });

         get_data=await get_data.json();

        this.setState({
            Subject:get_data.data
        });


    }

    async UpdateData(){
        const self=this;

            if(self.props.AppThis.GetInfoProfile()===null){
                await self.props.AppThis.GetProfile();
            }
            var get_profile=self.props.AppThis.GetInfoProfile();

            var UpdateAdmin={};

            ['code_user','email','birthday','username','phone_number'].map(function(item){
                UpdateAdmin[item]=get_profile[item];
            });


            ['subject_id','group_id'].map(function(item){

                var tmp=null;

                if(get_profile[item]!==null){
                     tmp=get_data_in_args(get_profile[item],"_id");
                }

                UpdateAdmin[item]=tmp;
            });

            UpdateAdmin['data_profile']=_isset_object(get_profile['data_profile'])?get_profile['data_profile']:{};

            this.setState({
                UpdateAdmin:UpdateAdmin,
                data: get_profile
            });
    }


    async SubmitUpdateProfile(){

        var UpdateAdmin=this.state.UpdateAdmin;

        var number_error=0;

        if(Object.keys(UpdateAdmin['data_profile']).length>0){

            ['current_address','degree','schools','certificate','citizen_identification','day_citizen_identification','address_citizen_identification','permanent_residence','ethnic','religion','name_protector','phone_protector'].map(function(item){
                if(typeof UpdateAdmin['data_profile'][item]==='undefined' || UpdateAdmin['data_profile'][item]===null){
                    number_error+=1;
                }
                else{

                    if(UpdateAdmin['data_profile'][item].length<3){
                        number_error+=1;
                    }
                }

            });

            ['code_user','email','birthday','username','phone_number'].map(function(item){
                if(typeof UpdateAdmin[item]==='undefined' || UpdateAdmin[item]===null){
                    number_error+=1;
                }
                else{
                    if(UpdateAdmin[item].length<3){
                        number_error+=1;
                    }
                }

            });

            if(number_error===0 && validateEmail(UpdateAdmin['email'])===false){
                number_error+=1;
            }

            if(number_error===0 && Number.isInteger(UpdateAdmin['birthday'])===false){
                number_error+=1;
            }
        }
        else{
            number_error+=1;
        }



        if(number_error>0){
            toast("Vui lòng kiểm tra lại");
        }
        else{

           var AppThis=this.props.AppThis;

            AppThis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', AppThis.getToken());

            formData.append('action',"changeProfile");

            formData.append('data',base64_encode(UpdateAdmin));

            var get_data=await fetch(AppThis.ConstApp.home_api+"profile", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

             get_data=await get_data.json();

            if(get_data.status==='error'){
                toast("Cập nhật không thành công");
            }
            else{
                toast("Cập nhật thành công");
            }


            await AppThis.GetProfile();

            await this.UpdateData();

            AppThis.PageLoad(false);

            window.location.reload();
        }


    }

    async EventUpdatePassWord(){

        var UpdatePassword=this.state.UpdatePassword;


        if(Object.keys(UpdatePassword).length===2){

            if(typeof UpdatePassword['new_password']!=='undefined' && typeof UpdatePassword['current_password']!=='undefined'){

                if(UpdatePassword['new_password']===UpdatePassword['current_password']){

                    toast("Mật khẩu mới không được giống với mật khẩu mới");
                }
                else{

                    var AppThis=this.props.AppThis;

                    AppThis.PageLoad(true);

                    const formData = new FormData();

                    formData.append('token', AppThis.getToken());

                    formData.append('action',"changePassword");

                    var data={
                        current_password:UpdatePassword['current_password'],
                        new_password:UpdatePassword['new_password']
                    };
                    formData.append('data',base64_encode(data));


                    var get_data=await fetch(AppThis.ConstApp.home_api+"profile", {
                        method: 'POST',
                        mode: 'cors',
                        body: formData
                    });

                     get_data=await get_data.json();

                    if(get_data.status==='error'){
                        toast("Cập nhật không thành công");
                    }
                    else{
                        toast("Cập nhật thành công");
                    }

                    AppThis.PageLoad(false);

                    setTimeout(function(){
                        window.location.reload();
                    },200);


                }

            }
            else{
                toast("Vui lòng nhập đầy đủ thông tin");
            }
        }
        else{
            toast("Vui lòng nhập đầy đủ thông tin");
        }

    }

    render(){

        if(this.state.data===null){
            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }
        else{
            return(this.ViewProfile());
        }
    }

    RenderProfileFirstRequired(){

        if(this.state.data!==null){

            var data=this.state.data;

            var args_required={
                current_address:"Địa chỉ thường trú",
                degree:"Trình độ",
                schools:"Trường học",
                certificate:"Bằng cấp",
                citizen_identification:"Căn cước công dân",
                day_citizen_identification:"Ngày cấp",
                address_citizen_identification:"Nơi cấp",
                permanent_residence:"Hộ khẩu thường trú",
                ethnic:"Dân tộc",
                religion:"Tôn giáo",
                name_protector:"Người liên hệ khẩn cấp",
                phone_protector:"Số điện thoại khẩn cấp",
            };

            const self=this;

            return Object.keys(args_required).map(function(value,key){

                    return(
                        <div className="col-12 col-sm-6 mb-2">
                            <label className={"d-block w-100 my-2"}>{args_required[value]} <span
                                className="text-red">( * )</span></label>
                            <input type="text" defaultValue={typeof data['data_profile'][value]!=='undefined'?data['data_profile'][value]:""} onChange={event => {
                                var UpdateAdmin = self.state.UpdateAdmin;
                                UpdateAdmin['data_profile'][value] = event.target.value;
                                self.setState({
                                    UpdateAdmin: UpdateAdmin
                                });
                            }} key={value} placeholder={args_required[value]} autoComplete="off" required="required" className="form-control"/>
                        </div>
                    );


            });

        }

    }

    RenderProfileFirst(){

        if(this.state.data!==null){

            var data=this.state.data;

            var args_not_required={
                specialized:"Chuyên ngành",
                tax_code:"Mã số thuế",
                insurance_code:"Mã số bảo hiểm",
                bank_account_number:"TK Ngân hàng VietTinBank",
            };

            const self=this;

            return Object.keys(args_not_required).map(function(value,key){

                    return(
                        <div className="col-12 col-sm-6 mb-2">
                            <label className={"d-block w-100 my-2"}>{args_not_required[value]}
                                <span
                                    className="text-red ms-1">( * )</span>
                            </label>
                            <input type="text" defaultValue={typeof data['data_profile'][value]!=='undefined'?data['data_profile'][value]:""} onChange={event => {
                                var UpdateAdmin = self.state.UpdateAdmin;
                                UpdateAdmin['data_profile'][value] = event.target.value;
                                self.setState({
                                    UpdateAdmin: UpdateAdmin
                                });
                            }} key={value} placeholder={args_not_required[value]} autoComplete="off" required="required" className="form-control"/>
                        </div>
                    );


            });

        }

    }

    UploadAvatarNow(event){

        const self=this;

        var fileUploaded = event.target.files[0];

        if((fileUploaded.size/1024)<3000){

            if(fileUploaded.type==='image/png' || fileUploaded.type==='image/jpg' || fileUploaded.type==='image/jpeg'){

                let reader = new FileReader();
                reader.readAsDataURL(fileUploaded);

                reader.onload = (e) => {
                    this.setState({
                        AvatarUpload: e.target.result,
                    },function(){
                        self.AutoUploadFile();
                    });


                }
            }
            else{
                toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG,JPG,JPEG");
            }


        }
        else{
            toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG");
        }

    }

     async AutoUploadFile(){

        if(this.state.AvatarUpload!==null){

            var AppThis=this.props.AppThis;

            AppThis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', AppThis.getToken());

            formData.append('avatar', this.state.AvatarUpload);
            formData.append('action',"UploadAvatar");

            var get_data=await fetch(AppThis.ConstApp.home_api+"profile", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

             get_data=await get_data.json();

            if(get_data.status==='error'){
                toast("Tải lên thất bại");
            }
            else{
                toast("Tải lên thành công");

            }

            await AppThis.GetProfile();

            this.UpdateData();

            AppThis.PageLoad(false);

        }

    }

    ViewProfile(){

        const AppThis=this.props.AppThis;

        var get_profile=this.props.AppThis.GetInfoProfile();

        const self=this;

        return(
            <div className={"container-fluid container-main-template mb-5"}>
            <Breadcrumb title={"Thông tin cá nhân"} />

                <div className={"col-12 mt-4 bg-white"}>

                    <div className={"row m-0"}>

                        <div className={"col-12 col-sm-12 col-md-12 col-lg-4 pt-5 pb-5"}>

                            <div className={"col-12 text-center"}>

                               <div className={"position-relative m-auto"}>

                                   <a onClick={()=>{
                                       hiddenFileInput.current.click();
                                   }} className={"font26 upload-images-avatar text-dark position-absolute right-0 top-0"}>
                                       <i className="fas fa-camera-retro" />
                                       <input onChange={(event)=>{
                                           this.UploadAvatarNow(event);
                                       }} ref={hiddenFileInput} accept="image/*"  type={"file"} className={"d-none"} />
                                   </a>

                                   {_isset_string(this.state.data.avatar)?(
                                           <div className={"m-auto box-avatar-150 d-flex justify-content-center align-self-center align-items-center"}>
                                               <img src={AppThis.ConstApp.home_url+this.state.data.avatar} />
                                           </div>
                                   ):(
                                       <div className={"box-avatar-150 d-flex justify-content-center align-self-center align-items-center m-auto"}>
                                       <img src={AppThis.ConstApp.home_url+'/assets/images/004-boy-1.svg'} />
                                       </div>
                                   )}
                               </div>


                            </div>

                            <div className={"m-0 mb-2 alert alert-info font-weight-500 mt-3 w-100 mb-3 text-center"}>

                                <i className={"far fa-hand-point-right me-2"}></i>
                                Xin chào : <strong>{this.state.data.username}</strong>

                            </div>

                            <div className={"col-12 text-center"}>

                                <div className={"badge bg-danger font12 me-2"}>Email : </div>
                                {this.state.data.email}

                            </div>


                        </div>

                        <div className={"col-12 col-sm-12 col-lg-8 pb-4 pt-4"}>

                            <ul className={"nav-theme nav nav-tabs text-uppercase"}>
                                <li className={"nav-item"}>
                                    <a href={"#tab1"} onClick={()=>{
                                        this.setState({
                                            ActiveTab:1,
                                        });
                                    }} className={this.state.ActiveTab===1?("nav-link active"):("nav-link")}>
                                        Cập nhật tài khoản
                                    </a>

                                </li>

                                <li className={"nav-item"}>
                                    <a onClick={()=>{
                                        this.setState({
                                            ActiveTab:2,
                                        });
                                    }} href={"#tab2"} className={this.state.ActiveTab===2?("nav-link active"):("nav-link")}>
                                    Đổi mật khẩu
                                    </a>
                                </li>
                            </ul>

                            {this.state.ActiveTab===1?(
                                <div className="row mt-2">


                                    <div className={"col-12 mt-3"}>
                                        <div className={"alert alert-danger rounded-0"}>
                                            <i className="fas fa-exclamation-triangle"></i> Vui lòng nhập đầy đủ thông tin theo yêu cầu của phòng hành chính nhân sự
                                        </div>
                                    </div>


                                    <div className="col-12 col-sm-6 mb-2">
                                        <label className={"d-block w-100 my-2"}>Email <span
                                            className="text-red">( * )</span></label>
                                        <input type="text" defaultValue={this.state.data.email} onChange={event => {
                                            var UpdateAdmin = this.state.UpdateAdmin;
                                            UpdateAdmin.email = event.target.value;
                                            this.setState({
                                                UpdateAdmin: UpdateAdmin
                                            });

                                        }} name="Email" placeholder="Email"
                                               autoComplete="off" required="required" className="form-control"/>
                                    </div>

                                    <div className="col-12 col-sm-6 mb-2">
                                        <label className={"d-block w-100 my-2"}>Sinh nhật <span
                                            className="text-red">( * )</span></label>

                                        {this.state.UpdateAdmin.birthday!==null?(
                                            <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat={"dd/MM/yyyy"}
                                                renderCustomHeader={({
                                                                         date,
                                                                         changeYear,
                                                                         changeMonth,
                                                                         decreaseMonth,
                                                                         increaseMonth,
                                                                         prevMonthButtonDisabled,
                                                                         nextMonthButtonDisabled
                                                                     })=>{
                                                    return _Header_DatePicker(date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled);
                                                }}
                                                className={"form-control"} selected={ConvertStringToDate(timeConverterDate(this.state.UpdateAdmin.birthday))} onChange={(date) => {
                                                var getTime= parseInt(Math.ceil(date.getTime()/1000));
                                                var UpdateAdmin = this.state.UpdateAdmin;
                                                UpdateAdmin.birthday = getTime;
                                                this.setState({
                                                    UpdateAdmin: UpdateAdmin
                                                });

                                            }} />

                                        ):(
                                            <DatePicker
                                                dateFormat={"dd/MM/yyyy"}
                                                renderCustomHeader={({
                                                                         date,
                                                                         changeYear,
                                                                         changeMonth,
                                                                         decreaseMonth,
                                                                         increaseMonth,
                                                                         prevMonthButtonDisabled,
                                                                         nextMonthButtonDisabled
                                                                     })=>{
                                                    return _Header_DatePicker(date,changeYear,changeMonth,decreaseMonth,increaseMonth,prevMonthButtonDisabled,nextMonthButtonDisabled);
                                                }}
                                                className={"form-control"} onChange={(date) => {
                                                var getTime= parseInt(Math.ceil(date.getTime()/1000));
                                                var UpdateAdmin = this.state.UpdateAdmin;

                                                UpdateAdmin.birthday = getTime;

                                                this.setState({
                                                    UpdateAdmin: UpdateAdmin
                                                });

                                            }} />
                                        )}


                                    </div>

                                    <div className="col-12 col-sm-6 mb-2">
                                        <label className={"d-block w-100 my-2"}>Họ và tên <span
                                            className="text-red">( * )</span></label>
                                        <input type="text" defaultValue={this.state.data.username} onChange={event => {
                                            var UpdateAdmin = this.state.UpdateAdmin;
                                            UpdateAdmin.username = event.target.value;
                                            this.setState({
                                                UpdateAdmin: UpdateAdmin
                                            });
                                        }} placeholder="Họ và tên" autoComplete="off" required="required" className="form-control"/>
                                    </div>

                                    <div className="col-12 col-sm-6 mb-2">
                                        <label className={"d-block w-100 my-2"}>Điện thoại <span
                                            className="text-red">( * )</span></label>

                                        <InputMask mask="9999999999" onChange={(event)=>{
                                            var UpdateAdmin = this.state.UpdateAdmin;
                                            UpdateAdmin.phone_number = event.target.value;
                                            this.setState({
                                                UpdateAdmin: UpdateAdmin
                                            });
                                        }} className={"form-control"} placeholder={"Số điện thoại"} defaultValue={this.state.data.phone_number} />
                                    </div>

                                    <div className="col-12 col-sm-6 mb-2">
                                        <label className={"d-block w-100 my-2"}>Mã nhân viên <span
                                            className="text-red">( * )</span></label>
                                        <input type="text" defaultValue={this.state.data.code_user} onChange={event => {
                                            var UpdateAdmin = this.state.UpdateAdmin;
                                            UpdateAdmin.code_user = event.target.value;
                                            this.setState({
                                                UpdateAdmin: UpdateAdmin
                                            });
                                        }} placeholder="Mã theo bảng lương" autoComplete="off" required="required" className="form-control"/>
                                    </div>


                                    {this.state.data.type!==1 && (this.state.data.group_id===null || this.state.data.group_id.length===0)?(
                                        <div className="col-12 col-sm-6 mb-2">
                                            <label className={"d-block w-100 my-2"}>Team

                                            </label>

                                            {this.state.GroupData!==null?(
                                                <Select isSearchable={true} onChange={(selectedOption)=>{

                                                    var UpdateAdmin=self.state.UpdateAdmin;

                                                    UpdateAdmin['group_id']=[selectedOption.value];

                                                    self.setState({
                                                        UpdateAdmin:UpdateAdmin,
                                                    });

                                                }} defaultValue={array_to_select(get_profile.group_id,'name','_id')} options={array_to_select(self.state.GroupData,'name','_id')} />
                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>
                                    ):(
                                        <></>
                                    )}


                                    {this.state.data.type===3  && (this.state.data.subject_id===null || this.state.data.subject_id.length===0)?(
                                        <div className="col-12 col-sm-6 mb-2">
                                            <label className={"d-block w-100 my-2"}>Bộ môn</label>

                                            {this.state.Subject!==null?(
                                                <Select isMulti={true} isSearchable={true} onChange={(selectedOption)=>{

                                                    var subject_id=get_data_in_args(selectedOption,"value");

                                                    var UpdateAdmin=self.state.UpdateAdmin;

                                                    UpdateAdmin['subject_id']=subject_id;

                                                    self.setState({
                                                        UpdateAdmin:UpdateAdmin,
                                                    });

                                                }} defaultValue={array_to_select(get_profile.subject_id,'name','_id')} options={array_to_select(self.state.Subject,'name','_id')} />
                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>
                                    ):(
                                        <></>
                                    )}


                                    <div className={"col-12 mt-2 mb-2"}>
                                        <div className={"col-12 w-100 bg-dark p-2 font-weight-bold ps-3 text-white"}>
                                            <i className="far fa-user"></i> HỒ SƠ NHÂN SỰ
                                        </div>
                                    </div>

                                    {this.RenderProfileFirstRequired()}

                                    {this.RenderProfileFirst()}

                                    <div className={"col-12"}>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-3 mb-2">

                                            <a href={"#"} className={"btn btn-success w-100 mt-3 text-white"} onClick={()=>{
                                                this.SubmitUpdateProfile()
                                            }}><i className="far fa-save me-2" /> Cập nhật</a>

                                        </div>

                                    </div>


                                </div>
                            ):(
                                <div className="row mt-2">

                                    <div className="col-12 col-sm-6 mb-2">
                                        <label className={"d-block w-100 my-2"}>Mật khẩu hiện tại <span
                                            className="text-red">( * )</span></label>
                                        <input type="password" onChange={event => {
                                            var UpdatePassword = this.state.UpdatePassword;
                                            UpdatePassword.current_password = event.target.value;
                                            this.setState({
                                                UpdatePassword: UpdatePassword
                                            });
                                        }} id={"password"} key={"password"} name={"password"} placeholder="Password" autoComplete="off" required="required" className="form-control"/>
                                    </div>

                                    <div className="col-12 col-sm-6 mb-2">
                                        <label className={"d-block w-100 my-2"}>Mật khẩu mới <span
                                            className="text-red">( * )</span></label>

                                        <input type="password" onChange={event => {
                                            var UpdatePassword = this.state.UpdatePassword;
                                            UpdatePassword.new_password = event.target.value;
                                            this.setState({
                                                UpdatePassword: UpdatePassword
                                            });

                                        }} key={"new_password"} placeholder="New Password" autoComplete="off" required="required" className="form-control"/>


                                    </div>

                                    <div className="col-12 col-sm-6 mb-2">

                                        <a href={"#"} className={"btn btn-success w-100 mt-3 text-white"} onClick={()=>{
                                            this.EventUpdatePassWord()
                                        }}>Cập nhật</a>

                                    </div>

                                </div>
                            )}





                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
