/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import {
    _isset_array, _isset_object,
    array_to_select, ArrayInArray, base64_encode,
    formatNumber,
    get_data_in_args, get_name_day, getItemInArray,
    remove_empty_array,
    timeConverterDate
} from "../../helper/array";
import {get_name_card, status_contract} from "../../helper/member";
import BoxContract from "../contract/BoxContract";
import Select, {components} from "react-select";
import lodash from "lodash";
import {confirmAlert} from "react-confirm-alert";
export default class EventCheckin extends Component {
    constructor(props) {
        super(props);

        this.state={
            data:null,
            ListAdmin:null,
            ListSubject:null,
            ListProduct:null,
            tab_active:{
                index:0,
                data:null
            },
            CurrentSessionCheckin:{
                id_contract:null,
                admin_pt_session:null,
                current_subject:[],
                product_in_card:[],
                product_buy_session:[],
                statics_member_group:null,
                method_checkin:null,
                number_coffer:null
            },
            PTList:[],
            StaticsList:[],
            MemberList:[],
            FlexibleList:[],
            NumberAction:0
        };

    }

    componentDidMount() {

        const self=this;

        this.getAdmin();

        this.getSubject();

        this.GetGroupStatics();

        this.getProduct();

        if(this.props.data){

            var get_data=this.props.data;

            var tab_active={};

            tab_active['index']=0;

            var PTList=[];

            var StaticsList=[];

            var MemberList=[];

            var FlexibleList=[];

            var NumberAction=0;

            if(get_data.length>0){
                get_data.map(function (item){

                    switch (item.type_card) {
                        case 1:
                            PTList.push(item);
                            break;

                        case 2:
                            MemberList.push(item);
                            break;

                        case 3:
                            FlexibleList.push(item);
                            break;

                        case 4:
                            StaticsList.push(item);
                            break;
                    }

                });


                if (MemberList.length>0){

                    tab_active['data']=MemberList[0];

                    this.SetCurrentSessionCheckin(MemberList[0]);

                    get_data=MemberList;
                }
                else{

                    NumberAction=1;

                    tab_active['data']=get_data[0];
                    this.SetCurrentSessionCheckin(get_data[0]);
                }

            }

            /*
                        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

            var product_in_card=[];

            if(Object.keys(tab_active['data']).length>0){

                var get_product_in_card_info=tab_active['data']['product_sale'];

                if(_isset_array(get_product_in_card_info)){

                    get_product_in_card_info.map(function(item,index){

                        if(item['took']<item['number']){

                            product_in_card.push({
                                _id:item._id,
                                number:1,
                                status:true
                            });

                        }

                    });

                }


                CurrentSessionCheckin['id_contract']=tab_active['data']['_id'];

            }

            CurrentSessionCheckin['product_in_card']=product_in_card;

             */

            this.setState({
                data:get_data,
                tab_active:tab_active,
                PTList:PTList,
                StaticsList:StaticsList,
                MemberList:MemberList,
                FlexibleList:FlexibleList,
                NumberAction:NumberAction
            },function (){

                self.forceUpdate();
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }
    KeyEventFunction=(event)=>{

        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.CloseModal!=='undefined'){
                this.props.CloseModal();
            }
        }
        if(event.key==='Enter'){
            this.SaveCheckin();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    SetCurrentSessionCheckin(data){

       var CurrentSessionCheckin={
            id_contract:null,
                admin_pt_session:null,
                current_subject:[],
                product_in_card:[],
                product_buy_session:[],
           statics_member_group:null,
           method_checkin:null,
           number_coffer:null
        };

       const self=this;

        var product_in_card=[];

        var admin_pt_session=null;

        var statics_member_group=null;

        if(Object.keys(data).length>0){

            var get_product_in_card_info=data['product_sale'];

            if(_isset_array(get_product_in_card_info)){

                get_product_in_card_info.map(function(item,index){

                    if(item['took']<item['number']){

                        product_in_card.push({
                            _id:item._id,
                            number:1,
                            status:true
                        });

                    }

                });

            }

            CurrentSessionCheckin['id_contract']=data['_id'];

            if(data['id_admin_pt']!==null &&_isset_object(data['id_admin_pt'])){
                admin_pt_session=data['id_admin_pt']['_id'];
            }

            if(data['group_member_statics']!==null &&_isset_object(data['group_member_statics'])){
                statics_member_group=data['group_member_statics']['_id'];
            }

        }

        CurrentSessionCheckin['product_in_card']=product_in_card;
        CurrentSessionCheckin['admin_pt_session']=admin_pt_session;
        CurrentSessionCheckin['statics_member_group']=statics_member_group;
        CurrentSessionCheckin['method_checkin']=this.props.method;

        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        },function(){
            self.forceUpdate();
        });

    }

    getProduct(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"product", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListProduct:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListSubject:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getAdmin(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=[];

                ListAdmin.push({
                    key:'none',
                    value:"Không tập PT"
                });

                ListAdmin=ListAdmin.concat(data.data);

                self.setState({
                    ListAdmin:ListAdmin
                });

            }

        }).catch((e)=>{

        });


    }

    GetGroupStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());

        fetch(Appthis.ConstApp.home_api+"groupstatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var GroupStatics=data.data;

                GroupStatics.unshift({
                    _id:'none',
                    name:"Không tập lớp nhóm"
                });

                this.setState({
                    GroupStatics:GroupStatics
                });

            }


        }).catch((e)=>{

        });


    }

    OptionLayout(props,GroupStatics){
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null && get_data_item!=='none'){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{props.data.label}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>

                    {typeof get_data_item.id_admin_pt!=='undefined'?(
                    <>
                     <p className={"m-0"}>HLV : {get_data_item.id_admin_pt.username+" - "+get_data_item.id_admin_pt.code_user}</p>
                    <p className={"m-0"}>Ngày :
                        {Object.keys(get_data_item.days).length>0?(
                            Object.entries(get_data_item.days).map(function(item,index){
                                return(<span className={"m-1 badge font10 bg-black-50 p-1"}>{get_name_day(item[1])}</span>);
                            })
                        ):(<></>)}
                    </p>
                    <p className={"m-0"}>giờ : {get_data_item.time_start} - {get_data_item.time_end}</p>
                    </>
                    ):(
                    <></>)}
                   
                </components.Option>
            );
        }
        else{
            return(<></>);
        }

    }

    DeleteItemBuyProduct(index){

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_buy_session=CurrentSessionCheckin['product_buy_session'];

        if(typeof product_buy_session[index]!=='undefined'){

            delete product_buy_session[index];
        }

        CurrentSessionCheckin['product_buy_session']=remove_empty_array(product_buy_session);

        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        });

    }

    UpdateItemBuyProduct(index,value,type){

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_buy_session=CurrentSessionCheckin['product_buy_session'];

        if(typeof product_buy_session[index]!=='undefined'){

            product_buy_session[index][type]=value;
        }

        CurrentSessionCheckin['product_buy_session']=remove_empty_array(product_buy_session);


        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        });

    }

    GetTotalPriceProduct(){

        var response=0;

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_buy_session=CurrentSessionCheckin['product_buy_session'];

        var ListProduct=this.state.ListProduct;

        product_buy_session.map(function(item,index){

            var get_tmp_product=getItemInArray(item['_id'],"_id",ListProduct);

            if(get_tmp_product!==null){
                response+=Math.ceil(get_tmp_product.price*item['number']);
            }


        });

        return response;
    }


    AlertSaveCheckin(){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui bg-dark'>
                        <h4 className={"mb-2"}>CHECK-IN</h4>
                        <div className={"row w-100"}>

                            <div className={"col-12 col-sm-6"}>
                                <a className={"w-100 btn btn-danger rounded-0"} onClick={onClose}>Từ chối</a>
                            </div>
                            <div className={"col-12 col-sm-6"}>
                                <a className={"w-100 btn btn-green rounded-0"}
                                   onClick={() => {

                                       self.SaveCheckin();

                                       onClose();
                                   }}
                                > Đồng ý
                                </a>
                            </div>

                        </div>


                    </div>
                );
            }
        });

    }

    AlertSubmitCheckin(){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui bg-dark'>
                        <h4 className={"mb-3"}>TẬP PT / LỚP NHÓM ?</h4>
                        <div className={"row"}>


                            <div className={"col-12 col-sm-6"}>
                                <a className={"btn btn-danger rounded-0 w-100"} onClick={() => {
                                        self.props.CloseModal();
                                        onClose();
                                    }}
                                > Từ chối
                                </a>

                            </div>

                            <div className={"col-12 col-sm-6"}>
                                <a className={"btn btn-green rounded-0 w-100"} onClick={onClose}>Đồng ý</a>
                            </div>


                        </div>


                    </div>
                );
            }
        });

    }

    SaveCheckin(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;

        var number_error=0;

        var data_send=lodash.cloneDeep(this.state.CurrentSessionCheckin);

        if(data_send.id_contract===null){
            number_error+=1;
        }

        var update_product_in_card=[];

        var product_in_card=data_send['product_in_card'];

        if(_isset_array(product_in_card)){

            product_in_card.map(function(item){

                if(item['status']===true){

                    update_product_in_card.push({
                        _id:item['_id'],
                        number:item['number']
                    });
                }

            })

        }

        data_send['product_in_card']=update_product_in_card;

        var data_send_base=base64_encode(data_send);

        if(number_error===0){

            formData.append('data',data_send_base);

            AppThis.PageLoad(true);

            fetch(AppThis.ConstApp.home_api+this.props.path+"/submit", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    toast("Cập nhật thành công");

                    if (self.state.PTList.length>0 || self.state.StaticsList.length>0 || self.state.FlexibleList.length>0){

                        self.AlertSubmitCheckin();

                        self.setState({
                            NumberAction:1
                        });

                    }
                    else{

                        self.props.CloseModal();
                        onClose();

                    }



                }
                else{
                    toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
                    self.props.CloseModal();
                }
                AppThis.PageLoad(false);

            }).catch((e)=>{
                toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
                AppThis.PageLoad(false);
                self.props.CloseModal();

            });
        }
        else{
            toast("Vui lòng kiểm tra lại");
        }



    }

    changeStatusProduct(index){

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;
        var product_in_card=CurrentSessionCheckin['product_in_card'];
        if(_isset_array(product_in_card)){

            if(typeof product_in_card[index]!=='undefined'){

                var get_status=product_in_card[index]['status'];

                if(get_status===true){
                    product_in_card[index]['status']=false;
                }
                else{
                    product_in_card[index]['status']=true;
                }

            }
        }
        CurrentSessionCheckin['product_in_card']=product_in_card;

        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        });

    }

     headerEventcheckin() {
    return (
      <div className="font20 font-weight-bold text-uppercase text-red">
        {_isset_object(this.state.tab_active.data.id_card) &&
        this.state.tab_active.data.id_card.type !== 2 ? (
          <span className="me-3">
            SỐ BUỔI TẬP PT / LỚP NHÓM CÒN LẠI :
            {this.state.tab_active.data.info_contract_data.number_card -
              this.state.tab_active.data.info_contract_data.number_did_pt >=
            0 ? (
              <>
                {Math.ceil(
                  this.state.tab_active.data.info_contract_data.number_card -
                    this.state.tab_active.data.info_contract_data.number_did_pt,
                )}
              </>
            ) : (
              <>0</>
            )}
          </span>
        ) : (
          <></>
        )}

        <span>
          SỐ NGÀY CÒN LẠI :
          {new Date().getTime() / 1000 <
          this.state.tab_active.data.end_day_contract ? (
            <>
              {Math.ceil(
                (this.state.tab_active.data.end_day_contract -
                  new Date().getTime() / 1000) /
                  86400,
              )}
            </>
          ) : (
            <>0</>
          )}
        </span>
      </div>
    );
  }
    ViewCheckin(){

        var data=this.state.data;
        const self=this;
            return(
                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-3"}>

                        <ul className="nav nav-tab-member">

                            {data.map(function(item,index){

                                return(
                                    <li key={"list_contract_"+item._id} onClick={()=>{

                                        var tab_active=self.state.tab_active;

                                        tab_active['index']=index;

                                        tab_active['data']=item;

                                        self.setState({
                                            tab_active:tab_active
                                        },function(){

                                            self.forceUpdate();
                                            self.SetCurrentSessionCheckin(item);
                                        });



                                    }} className={self.state.tab_active['index']===index?("w-100 border-bottom p-3 bg-white mb-2 ps-5 active_tab"):("w-100 border-bottom p-3 bg-white mb-2 ps-5")}>


                                        <p className={"mb-2 font-weight-bold text-uppercase"}>
                                            <i className="fas fa-file-contract me-1" />
                                            Số HD : {item.number_contract}. ( {get_name_card(item.id_card.type)} ) </p>

                                       <p className={"mb-1"}>
                                           <i className="fas fa-shield-alt me-1" />  Trạng thái HD :
                                          ( {status_contract(item)} )
                                       </p>

                                        <p className={"mb-0"}>
                                            <i className="far fa-clock me-1" />
                                            Ngày bắt đầu : {timeConverterDate(item.start_day)}</p>


                                    </li>
                                );
                            })}

                        </ul>

                    </div>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-9"}>


                        {this.state.tab_active.data && Object.keys(this.state.tab_active.data.id_card).length>0?(

                            <div className={"col-12 mb-3"} key={"info_checkin_"+this.state.tab_active._id}>

                                <div className={"col-12 p-0 mb-5"}>

                                    {this.headerEventcheckin()}


                                    <div className={"row"}>

                                        {_isset_object(this.state.tab_active.data.id_card) && this.state.tab_active.data.id_card.id_product.length>0?(
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                                                <div className={"col-12 col-sm-12 col-md-12"}>

                                                    <label className="d-block w-100 font-weight-bold pb-2 border-bottom text-uppercase mb-2">
                                                        Sản phẩm đi kèm thẻ tập
                                                    </label>

                                                    {this.state.CurrentSessionCheckin.product_in_card.length>0?(
                                                        this.state.CurrentSessionCheckin.product_in_card.map(function(item,index){
                                                            return(
                                                                <div className={"row m-0 mb-2"}>
                                                                    <div className={"col-2 col-sm-2 d-flex flex-row justify-content-center align-items-center"}>
                                                                        {item.status===true?(
                                                                            <input onChange={()=>{
                                                                                self.changeStatusProduct(index);
                                                                            }} type={"checkbox"} checked={true} className={"font18 rounded-0 form-check-input"} />

                                                                        ):(
                                                                            <input onChange={()=>{
                                                                                self.changeStatusProduct(index);
                                                                            }} type={"checkbox"} checked={false} className={"font18 rounded-0 form-check-input"} />

                                                                        )}

                                                                    </div>

                                                                    <div className={"col-6 col-sm-6 d-flex flex-row flex-wrap align-items-center"}>
                                                                        {getItemInArray(item._id,"_id",self.state.tab_active.data['id_card']['id_product']).name}
                                                                    </div>
                                                                    <div className={"col-4 col-sm-4 p-2 ps-4 pe-4 bg-light-bold"}>
                                                                        {item.number}
                                                                    </div>

                                                                </div>
                                                            );
                                                        })
                                                    ):(
                                                        <div className={"alert alert-danger p-2 m-0 rounded-0 w-100"}>
                                                            Không có SP nào
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        ):(
                                            <div className={"col-12"}>
                                            <div className="alert alert-info rounded-0 w-100">
                                                <i className="far fa-heart"></i> HD không có sản phẩm khuyến mãi
                                            </div>
                                            </div>
                                        )}


                                        {_isset_object(self.state.tab_active['data']) && _isset_object(self.state.tab_active['data'].id_card) && (self.state.tab_active['data'].id_card.type===4 || self.state.tab_active['data'].id_card.type===3) ?(
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                                                <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                                    Chọn nhóm cố định
                                                </label>

                                                {self.state.GroupStatics!==null?(

                                                    <>
                                                        {self.state.tab_active['data'].id_card.type===3?(
                                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                                var CurrentSessionCheckin=self.state.CurrentSessionCheckin;
                                                                CurrentSessionCheckin.statics_member_group=selectedOption.value;
                                                                self.setState({
                                                                    CurrentSessionCheckin:CurrentSessionCheckin
                                                                });

                                                            }} options={array_to_select(this.state.GroupStatics,"name","_id")} />

                                                        ):(

                                                            <>
                                                                {self.state.tab_active['data'].group_member_statics!==null && self.state.tab_active['data'].group_member_statics._id!=='undefined' ?(
                                                                    <Select isSearchable={true} options={self.state.tab_active['data'].group_member_statics!==null?[
                                                                    {
                                                                        label:"Không tập lớp nhóm",
                                                                        value:"none"
                                                                    },
                                                                    {
                                                                        label:self.state.tab_active['data'].group_member_statics.name,
                                                                        value:self.state.tab_active['data'].group_member_statics._id
                                                                    }
                                                                    ]:array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                                                        Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                                                    }} onChange={(selectedOption)=>{
                                                                var CurrentSessionCheckin=self.state.CurrentSessionCheckin;
                                                                CurrentSessionCheckin.statics_member_group=selectedOption.value;
                                                                self.setState({
                                                                    CurrentSessionCheckin:CurrentSessionCheckin
                                                                });
                                                            }}
                                                                     />
                                                                ):(
                                                                    <></>
                                                                )}
                                                            </>


                                                        )}
                                                    </>

                                                ):(
                                                    <ItemLoading />
                                                )}




                                            </div>
                                        ):(
                                            <></>
                                        )}


                                        {this.state.ListAdmin!==null && self.state.tab_active['data'].info_contract_data.number_did_pt<self.state.tab_active['data'].info_contract_data.number_card && self.state.tab_active['data'].id_card['type']===1 && self.state.tab_active['data'].id_admin_pt!==null && Object.keys(self.state.tab_active['data'].id_admin_pt).length>0?(
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">

                                                <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                                   PT hôm nay
                                                </label>

                                                <div className='col-12 col-sm-12 col-md-12'>

                                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                                        var CurrentSessionCheckin=self.state.CurrentSessionCheckin;
                                                        CurrentSessionCheckin['admin_pt_session']=selectedOption.value;
                                                        self.setState({
                                                            CurrentSessionCheckin:CurrentSessionCheckin
                                                        });
                                                    }} value={
                                                        self.state.CurrentSessionCheckin.admin_pt_session===null?getItemInArray(self.state.tab_active['data'].id_admin_pt._id,'value',array_to_select(this.state.ListAdmin,"value","key")):
                                                            getItemInArray(self.state.CurrentSessionCheckin.admin_pt_session,'value',array_to_select(this.state.ListAdmin,"value","key"))
                                                    } options={array_to_select(this.state.ListAdmin,"value","key")} />

                                                </div>

                                                <div className={"alert alert-warning w-100 mt-2"}>
                                                    <i className="fas fa-exclamation-triangle me-1"></i>
                                                    1 PT chỉ được dậy 1 khách.Nếu PT đang dạy khách A mà khách B đến cùng giờ thì khách B phải chờ khách A tập xong mới được checkin.
                                                </div>

                                            </div>
                                        ):(
                                            <></>
                                        )}

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">

                                            <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                                Bộ môn hôm nay
                                            </label>


                                            {this.state.ListSubject!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var CurrentSessionCheckin=self.state.CurrentSessionCheckin;
                                                    CurrentSessionCheckin['current_subject']=get_data_in_args(selectedOption,"value");
                                                    self.setState({
                                                        CurrentSessionCheckin:CurrentSessionCheckin
                                                    });
                                                }} isMulti={true} value={ArrayInArray(self.state.CurrentSessionCheckin['current_subject'],array_to_select(this.state.ListSubject,'name','_id'))} options={array_to_select(this.state.ListSubject,'name','_id')} />

                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">

                                            <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                                Số tủ đồ
                                            </label>


                                            <input className={"form-control w-100 rounded-0"} onChange={(event)=>{

                                                var CurrentSessionCheckin=self.state.CurrentSessionCheckin;
                                                CurrentSessionCheckin['number_coffer']=event.target.value;
                                                self.setState({
                                                    CurrentSessionCheckin:CurrentSessionCheckin
                                                });

                                            }} type={"text"} placeholder={"Số tủ đồ"} />


                                        </div>





                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">

                                            <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                              Mua thêm sản phẩm

                                                <a className={"ms-2 font16"} onClick={()=>{

                                                    var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

                                                    var product_buy_session=CurrentSessionCheckin['product_buy_session'];

                                                    product_buy_session.push({
                                                        _id:null,
                                                        number:null
                                                    });

                                                    CurrentSessionCheckin['product_buy_session']=product_buy_session;

                                                    self.setState({
                                                        CurrentSessionCheckin:CurrentSessionCheckin
                                                    });

                                                }}><i className="fas fa-plus-circle" /></a>
                                            </label>

                                            <div className={"alert alert-warning p-2 rounded-0"}>
                                                Mua thêm sản phẩm ngoài hợp đồng
                                            </div>

                                            {this.state.ListProduct!==null?(
                                                <>
                                                    {this.state.CurrentSessionCheckin.product_buy_session.length>0?(
                                                        this.state.CurrentSessionCheckin.product_buy_session.map(function(item,index){
                                                            return(
                                                                <div className={"row mb-2"}>
                                                                    <div className={"col-5 col-sm-5"}>
                                                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                                                            if(selectedOption!==null && typeof selectedOption!=='undefined'){
                                                                                self.UpdateItemBuyProduct(index,selectedOption.value,'_id');
                                                                            }
                                                                        }} options={array_to_select(self.state.ListProduct,"name","_id")} />
                                                                    </div>
                                                                    <div className={"col-5 col-sm-5"}>
                                                                        <input className={"form-control w-100 rounded-0"} defaultValue={0} onChange={(event)=>{
                                                                           self.UpdateItemBuyProduct(index,event.target.value,'number');
                                                                        }} type={"number"} />
                                                                    </div>
                                                                    <div className={"col-2 col-sm-2 d-flex flex-row align-items-end"}>
                                                                        <a onClick={()=>{
                                                                            self.DeleteItemBuyProduct(index)
                                                                        }}><i className="font16 fas fa-trash"></i>
                                                                        </a>

                                                                    </div>
                                                                </div>
                                                            );

                                                        })
                                                    ):(
                                                       <></>
                                                    )}
                                                </>
                                            ):(
                                               <div className={"col-12 text-center"}>
                                                   <ItemLoading />
                                               </div>
                                            )}

                                            {this.state.CurrentSessionCheckin.product_buy_session.length>0?(
                                                <div className={"col-12 mt-3"}>
                                                    <p>Tổng tiền sản phẩm : <span className={"font-weight-bold text-red font15"}> {formatNumber(this.GetTotalPriceProduct())} VNĐ</span>
                                                    </p>
                                                </div>
                                            ):(<></>)}


                                        </div>

                                        <div className={"col-12"} />

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 offset-lg-6 p-0 mt-2 d-flex flex-row align-items-center">

                                            <div className={"row m-0 w-100"}>

                                                <div className={"col-12 col-sm-6 offset-sm-6"}>
                                                    <a onClick={()=>{
                                                        self.AlertSaveCheckin();
                                                    }} className={"btn btn-outline-primary rounded-0 w-100"}>
                                                        <i className="far fa-save me-2" />  Checkin
                                                    </a>

                                                </div>
                                            </div>

                                        </div>



                                    </div>

                                </div>


                                {this.state.NumberAction===1?(
                                    <div className={"row w-100 mt-3 mb-2"}>

                                        {this.state.PTList.length>0?(
                                            <div className={"col-6 col-sm-6 col-md-3 show-type-card"}>
                                                <button onClick={()=>{

                                                    var tab_active={
                                                        index:0,
                                                        data:this.state.PTList[0]
                                                    };

                                                    this.SetCurrentSessionCheckin(this.state.PTList[0]);

                                                    this.setState({
                                                        tab_active:tab_active,
                                                        data:this.state.PTList
                                                    },function(){
                                                        self.forceUpdate();
                                                    });


                                                }} className={"btn btn-primary w-100 rounded-0"}>
                                                    Check-in PT
                                                </button>
                                            </div>
                                        ):(<></>)}



                                        {this.state.StaticsList.length>0?(
                                            <div className={"col-6 col-sm-6 col-md-3 show-type-card"}>
                                                <button onClick={()=>{

                                                    var tab_active={
                                                        index:0,
                                                        data:lodash.cloneDeep(this.state.StaticsList[0])
                                                    };

                                                    this.SetCurrentSessionCheckin(this.state.StaticsList[0]);

                                                    this.setState({
                                                        tab_active:tab_active,
                                                        data:lodash.cloneDeep(this.state.StaticsList)
                                                    },function(){
                                                        self.forceUpdate();
                                                    });


                                                }} className={"btn btn-danger w-100 rounded-0"}>
                                                    Check-in LNCD
                                                </button>
                                            </div>
                                        ):(<></>)}


                                        {this.state.FlexibleList.length>0?(
                                            <div className={"col-6 col-sm-6 col-md-3 show-type-card"}>
                                                <button onClick={()=>{

                                                    var tab_active={
                                                        index:0,
                                                        data:lodash.cloneDeep(this.state.FlexibleList[0])
                                                    };

                                                    this.SetCurrentSessionCheckin(this.state.FlexibleList[0]);

                                                    this.setState({
                                                        tab_active:tab_active,
                                                        data:lodash.cloneDeep(this.state.FlexibleList)
                                                    },function(){
                                                        self.forceUpdate();
                                                    });


                                                }} className={"btn btn-warning w-100 rounded-0"}>
                                                    Check-in LNLH
                                                </button>
                                            </div>
                                        ):(<></>)}



                                        {this.state.MemberList.length>0?(
                                            <div className={"col-6 col-sm-6 col-md-3 show-type-card"}>
                                                <button onClick={()=>{

                                                    var tab_active={
                                                        index:0,
                                                        data:this.state.MemberList[0]
                                                    };

                                                    this.SetCurrentSessionCheckin(this.state.MemberList[0]);

                                                    this.setState({
                                                        tab_active:tab_active,
                                                        data:lodash.cloneDeep(this.state.MemberList)
                                                    });

                                                }} className={"btn btn-dark w-100 rounded-0"}>
                                                    Check-in Membership
                                                </button>
                                            </div>
                                        ):(<></>)}



                                    </div>
                                ):(
                                    <></>
                                )}




                                <div className={"alert alert-danger rounded-0"}>
                                    <i className="fas fa-file-contract me-2" /> Thông tin chi tiết HD
                                </div>


                                <BoxContract data={this.state.tab_active.data} />

                            </div>

                        ):(
                            <div className={"col-12 mt-5 d-flex flex-row justify-content-center align-items-center"}>
                                <ItemLoading size={"Large"} />
                            </div>
                        )}

                    </div>

                </div>
            );

    }

    render(){
        return(
            <div className="modal d-block position-fixed modal-dialog-end DataCheckin">

                <div className={"modal-dialog modal-fullscreen modal-dialog-scrollable"}>

                    <div className="modal-content bg-white shadow border-0">

                        <div className="modal-header bg-light p-2 pb-2">
                            <div className={"container position-relative"}>
                                <strong className={"font18 text-black text-uppercase"}>
                                    <i className="fas fa-plus-circle me-2" /> Checkin khách hàng</strong>

                                <a onClick={()=>{
                                    this.props.CloseModal();
                                }} className={"font-weight-bold position-absolute top-0 right-0 font15"}>
                                    <i className="far fa-times-circle" /> Đóng
                                </a>

                            </div>
                        </div>

                        <div className="modal-body">
                            <div className={"container position-relative p-0"}>
                                {this.state.data?(
                                    this.ViewCheckin()
                                ):(
                                    <div className={"col-12 pt-5 mb-5 d-flex flex-row justify-content-center align-items-center"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>


                        </div>



                    </div>


                </div>
            </div>
        );
    }
}
