/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    array_to_select, base64_encode, formatNumber, getItemInArray, in_module, timeConverter,
} from "../../helper/array";
import {get_name_card, status_payment} from "../../helper/member";
import DetailContract from "../../global/contract/DetailContract";
import Floater from 'react-floater';
import Select from "react-select";
import DatePicker from "react-datepicker";
import DetailCheckin from "../../global/checkin/DetailCheckin";
import {confirmAlert} from "react-confirm-alert";

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        admin_pt_session:null,
        start_time:null,
        end_time:null,
        current_subject:null,
        number_contract:null,
        type_checkin:null
    },
    page:0,
    ListAdmin:null,
    DataDetailContract:null,
    DataStatics:null,
    SearchAdvanced:false,
    ListSubject:null,
    MemberAction:{
        IdMemmber:null,
        action:null
    },
    DetailCheckin:null,
};

export default class clubhistorycheckin extends Component {
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListSubject=data.data;
                ListSubject.unshift({
                    name:"Tất cả",
                    _id:null
                });

                self.setState({
                    ListSubject:ListSubject
                });

            }


        }).catch((e)=>{

        });


    }


    getQuerySearch(){
        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }


    async ExportCheckin(){

        this.props.AppThis.PageLoad(true);


        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        fetch(Appthis.ConstApp.home_api+"clubhistorycheckin/exports", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác đã được gửi tới máy chủ");
            }

            Appthis.PageLoad(false);


        }).catch((e)=>{
            toast("Thao tác thất bại");
        });


    }

    AlertExportCheckin(){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ExportCheckin();
                                self.setState({
                                    SearchAdvanced:false
                                });
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }


    componentDidMount() {

        this.getData();

        this.getAdmin();

        this.getStatics();

        this.getSubject();
    }

    getData(load=false){

        var AppThis=this.props.AppThis;

        if(load===true){
            AppThis.PageLoad(true);
        }

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        var data_query=this.getQuerySearch();

        if(Object.keys(data_query).length>0){
            formData.append('query', base64_encode(data_query));
        }

        formData.append('page', this.state.page);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubhistorycheckin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                AppThis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                AppThis.PageLoad(false);
            }

        });


    }

    getStatics(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubhistorycheckin/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    DataStatics:data.data
                });
            }



        }).catch((e)=>{


        });


    }


    getAdmin(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubhistorycheckin/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=[];

                ListAdmin.push({
                    key:null,
                    value:"Tất cả"
                });

                ListAdmin=ListAdmin.concat(data.data);

                self.setState({
                    ListAdmin:ListAdmin
                });

            }

        }).catch((e)=>{

        });


    }


    RenderDataStatics(){

        if(this.state.DataStatics!==null){

            return(
                <div className={"row m-0 mt-4"}>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-warning"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tập PT trong ngày</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.DataStatics.total_pt}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-tenge rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tập nhóm CD</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.DataStatics.total_statics_group}</p>
                            </div>
                        </div>
                    </div>



                </div>
            );

        }
        else{
            return(
                <div className={"col-12 pt-3 pb-3 d-flex flex-row justify-content-center"}>
                    <ItemLoading />
                </div>
            );
        }
    }


    RenderView(){

        const self=this;
        return(
            <div className={"row mt-2 mb-4 m-0"}>

                <div className={"col-12"}>
                    <div className={"col-12 box-table-fix"}>
                    <div className={"overflow-auto"}>

                        <table className={"mt-3 w-100 table border-0 mw1500 table-style-old"}>
                            <thead>
                            <tr className={"border-bottom bg-light"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-user me-2"/> TÊN HỘI VIÊN</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-mobile-alt me-2" /> SDT/ID</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-credit-card me-2" /> DỊCH VỤ</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-file-excel me-2" />SỐ HD</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-dollar-sign me-2" />GIÁ TRỊ HD</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-clock me-2" />Cập nhật</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-info me-2" />
                                    PT DẠY
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-info me-2" />
                                    LỚP NHÓM
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                    <i className="fas fa-directions me-2" />
                                    THAO TÁC</td>
                            </tr>
                            </thead>

                            <tbody>

                            {this.state.data.data.map(function(value,index){
                                return(
                                    <tr>
                                        <td>
                                            <span>{value.id_user.username}</span>
                                        </td>
                                        <td>
                                            <span>{value.id_user.user_login}</span>
                                        </td>
                                        <td>

                                            {typeof value.id_member!=='undefined' && value.id_member!==null?(
                                                <>
                                                    <span className={"me-2"}>{value.id_member.id_card.name}</span>
                                                    {get_name_card(value.id_member.id_card.type)}
                                                </>
                                            ):(
                                                <>
                                                    <p className={"m-0"}>
                                                        <span>Tập thử</span>
                                                    </p>
                                                </>
                                            )}

                                        </td>

                                        <td>
                                            {value.id_member!==null && value.id_member.group_member!==null?(
                                                <i className="fas fa-check-circle text-primary me-1" />
                                            ):(<></>)}


                                            {value.id_member!==null?(
                                                <>
                                                    <span className={"me-1"}>{value.id_member.number_contract}</span>
                                                    {status_payment(value.id_member)}
                                                </>
                                            ):(
                                                <>
                                                    <p className={"m-0"}>
                                                        <span>Tập thử</span>
                                                    </p>
                                                </>
                                            )}

                                        </td>


                                        <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {value.id_member!==null?formatNumber(value.id_member.info_contract_data.price_sale):0} VNĐ
                                       </span>
                                        </td>

                                        <td>
                                            <span>{timeConverter(value.time)}</span>
                                        </td>


                                        <td>
                                            {value.admin_pt_session!==null && Object.keys(value.admin_pt_session).length>0?(
                                                <span>{value.admin_pt_session.username+" - "+value.admin_pt_session.code_user}</span>
                                            ):(
                                                <span className={"badge font12 rounded-0 bg-danger"}>Không</span>
                                            )}
                                        </td>

                                        <td>
                                            {value.statics_member_group!==null?(
                                                <span>{value.statics_member_group.name+ " - "+value.statics_member_group.code_group}</span>
                                            ):(
                                                <span className={"badge font12 rounded-0 bg-danger"}>Không</span>
                                            )}
                                        </td>


                                        <td className={"fix-item-table"}>


                                            {value.id_member!==null?(
                                                <> <a onClick={()=>{
                                                    self.setState({
                                                        DataDetailContract:value.id_member
                                                    });
                                                }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xem chi tiết HD">
                                                        <i className={"far fa-folder-open"}></i>
                                                    </Floater>
                                                </a>


                                                    <a onClick={()=>{
                                                        self.setState({
                                                            DetailCheckin:value
                                                        });
                                                    }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                                        <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết buổi tập">
                                                            <i className="fas fa-info-circle" />
                                                        </Floater>
                                                    </a></>
                                            ):(
                                                <>
                                                    <span className={"badge bg-success"}>Tập thử</span>
                                                </>
                                            )}


                                        </td>

                                    </tr>
                                );
                            })}


                            </tbody>

                        </table>
                    </div>
                    </div>

                    <div className={"col-12 p-3 mt-3"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>


                </div>

            </div>
        );
    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubhistorycheckin/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }

    render(){

        const self=this;

            if(this.state.data!==null){
                return(
                    <div className={"container-fluid container-main-template mb-5"}>
                        <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 d-flex align-items-center pt-2">
                                    <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Lịch sử ra vào hội viên</h3>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 text-end">

                                    <div className="col-12 p-0 mb-2">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>

                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>



                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>



                                            <input onChange={(event)=>{
                                                this.UpdateQuerySearch("input_search",event.target.value);

                                            }} autoComplete={"off"} type="text" name="input_search"
                                                   className="font13 form-control d-inline-block w-auto rounded-0"
                                                   placeholder="Số điện thoại"/>

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>


                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:true,
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                                <i className="fas fa-search-plus" /> Tìm kiếm nâng cao </a>


                                        </div>

                                    </div>



                                </div>

                            </div>


                            {this.RenderDataStatics()}

                            {this.state.data.data.length>0?(
                                this.RenderView()
                            ):(
                                <div className={"col-12 p-3"}>
                                    <div className={"alert alert-danger rounded-0"}>
                                        Không có dữ liệu
                                    </div>
                                </div>
                            )}



                            {this.state.DataDetailContract!==null?(
                                <DetailContract OnClose={()=>{
                                    this.setState({
                                        DataDetailContract:null
                                    });

                                }} EventDetail={(id)=>{
                                    this.setState({
                                        DataDetailContract:null
                                    },function(){
                                        self.getDetailContract(id);
                                    });
                                }} data={this.state.DataDetailContract} />
                            ):(
                                <></>
                            )}



                            {this.state.SearchAdvanced===true?(
                                this.SearchAdvanced()
                            ):(<></>)}



                        </div>

                        {this.state.DetailCheckin!==null?(
                            <DetailCheckin data={this.state.DetailCheckin} eventClose={()=>{
                                this.setState({
                                    DetailCheckin:null
                                },()=>{
                                    self.getData(true);
                                });
                            }}  AppThis={this.props.AppThis} path={"clubhistorycheckin"}  />
                        ):(
                            <></>
                        )}

                    </div>
                );
            }
            else{

                return(
                    <div className={"col-12 pt-5 mt-5 d-flex flex-row justify-content-center align-items-center"}>
                        <ItemLoading size={"Large"} />
                    </div>
                );
            }



    }


    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm nâng cao</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row box_input"}>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian bắt đầu"} />


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>





                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Tên khách hàng/SDT</p>
                                    <input defaultValue={this.state.query.input_search} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("input_search",event.target.value);
                                    }} className={"form-control"} placeholder={"Tên khách hàng/SDT"} />
                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số HD</p>
                                    <input defaultValue={this.state.query.number_contract} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("number_contract",event.target.value);
                                    }} className={"form-control"} placeholder={"Số HD"} />
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>PT buổi dạy</p>

                                    {this.state.ListAdmin!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            this.UpdateQuerySearch("admin_pt_session",selectedOption.value);
                                        }} className={"w-100 text-start me-1"} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                    ):(
                                        <></>
                                    )}

                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Bộ môn</p>

                                    {this.state.ListSubject!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var current_subject=selectedOption.value;
                                            self.UpdateQuerySearch("current_subject",current_subject);
                                        }} options={array_to_select(this.state.ListSubject,"name","_id")}
                                                defaultValue={this.state.query.current_subject!==null?getItemInArray(this.state.query.current_subject,"value",array_to_select(this.state.ListSubject,"name","_id")):null}
                                        />
                                    ):(
                                        <ItemLoading />
                                    )}


                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Nhóm CD</p>
                                    <select onChange={(event)=>{
                                        this.UpdateQuerySearch('type_checkin',event.target.value);
                                    }} className="mr-2 font13 rounded-0 form-control w-100">
                                        <option value={"none"}>Tất cả</option>
                                        <option value="1">Tập lớp nhóm</option>
                                        <option value="3">Tập cá nhân</option>
                                    </select>

                                </div>




                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"row"}>


                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                self.getData(true);

                                                self.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>



                                        {in_module('clubexport',this.props.AppThis.GetInfoProfile().module)===true?(
                                            <div className={"col-12 col-sm-12 col-lg-3"}>
                                                <a onClick={()=>{
                                                    this.AlertExportCheckin();

                                                }} className={"btn btn-danger w-100 text-white mt-3 font14"} >
                                                    <i className="fas fa-cloud-download-alt" /> Xuất dữ liệu
                                                </a>
                                            </div>
                                        ):(
                                            <></>
                                        )}


                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-dark w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }
}
