/* eslint-disable */
import React,{Component} from "react";
import {
    base64_encode,
} from "../../../helper/array";
import lodash from "lodash";
import {toast} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
const INIT_STATE={
    data:{
        _id:null,
        vat:0,
        insurance:0,
        price_sale:0,
        status:0
    },
    DetailContract:null
};

export default class EditPriceContract extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }


    componentDidMount() {
        if(typeof this.props.data!=='undefined'){
            this.UpdateDataMember(this.props.data);
        }
    }

    UpdateDataMember(data){

        var update_data=this.state.data;

        update_data['_id']=data['_id'];

        ['vat','insurance','status'].map(function (item){
            if (typeof data[item]!=='undefined'){
                update_data[item]=parseInt(data[item]);
            }
        });

        ['price_sale'].map(function (item){
            if (typeof data['info_contract_data'][item]!=='undefined'){
                update_data[item]=parseInt(data['info_contract_data'][item]);
            }
        });


        this.setState({
            data:update_data,
            DetailContract:data
        });
    }

    async SaveContract() {

        var GetData = this.state.data;

        var Appthis = this.props.AppThis;

        var number_error = 0;

        var DetailContract=this.state.DetailContract;

        ['vat', 'insurance','price_sale'].map(function (item, index) {
            if (GetData[item] === null || typeof GetData[item] === 'undefined') {
                number_error += 1;
            }
            else{

                if(item==='price_sale'){

                    if(parseInt(DetailContract['billing_information']['total_price'])>GetData['price_sale']){
                        number_error+=1;
                    }

                }
            }
        });



        if (number_error > 0) {
            toast("Vui lòng kiểm tra lại");
        }
        else {


            var update_data={};

            Object.entries(GetData).map(function(item){

                var [key,value]=item;

                if(value!==null){
                    update_data[key]=value;
                }
            });


            Appthis.PageLoad(true);

            var base64_data = base64_encode(update_data);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('data', base64_data);


            var fetch_data = await fetch(Appthis.ConstApp.home_api + this.props.path + "/editprice", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            fetch_data = await fetch_data.json();

            if (fetch_data.status === 'ok') {
                toast("Sửa hợp đồng thành công");

            } else {
                toast("Sửa hợp đồng không thành công vui lòng kiểm tra lại");
            }

            if(typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }

            Appthis.PageLoad(false);




        }

    }

    render(){

        const self=this;

        return(
            <div className={"col-12"}>

                <h2 className="d-inline-block mb-4 font18 mt-2 font-weight-bold text-uppercase text-dark border-primary border-bottom pb-2">
                   Giá và phí dịch vụ
                </h2>


                <div className={"alert alert-danger"}>
                    <i className="fas fa-info-circle me-1"></i> Số tiền của HD không nhỏ hơn số tiền đã thanh toán
                </div>

                <div className={"row"}>


                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                        <p className={"mb-1"}>Giá trị HD <span className={"text-red"}>( * )</span></p>

                        <input onChange={(event)=>{
                            var data=self.state.data;
                            data.price_sale=parseInt(event.target.value);
                            self.setState({
                                data:data
                            });

                        }} type={"number"} value={self.state.data.price_sale} placeholder={"Giá trị HD"} className={"form-control w-100 rounded-0"} />


                    </div>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                        <p className={"mb-1"}>VAT <span className={"text-red"}>( * )</span></p>
                        <input onChange={(event)=>{
                            var data=self.state.data;
                            data.vat=parseInt(event.target.value);
                            self.setState({
                                data:data
                            });

                        }} type={"number"} value={self.state.data.vat} placeholder={"VAT (%)"} className={"form-control w-100 rounded-0"} />


                    </div>


                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                        <p className={"mb-1"}>Phí bảo hiểm <span className={"text-red"}>( * )</span></p>

                        <input onChange={(event)=>{
                            var data=self.state.data;
                            data.insurance=parseInt(event.target.value);
                            self.setState({
                                data:data
                            });
                        }} type={"number"} value={self.state.data.insurance} placeholder={"Phí bảo hiểm"} className={"form-control w-100 rounded-0"} />

                    </div>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                        <p className={"mb-1"}>Trạng thái HD</p>

                        <select onChange={(event)=>{
                            var data=self.state.data;
                            data.status=parseInt(event.target.value);
                            self.setState({
                                data:data
                            });
                        }} className={"form-control w-100"}>
                            {[
                                {
                                    key:0,
                                    value:"Bảo lưu"
                                },
                                {
                                    key:1,
                                    value:"Chưa thanh toán hết"
                                },
                                {
                                    key:2,
                                    value:"Đã thanh toán hết"
                                },
                                {
                                    key:3,
                                    value:"Hủy"
                                },
                            ].map(function(item){
                                return(
                                    <option value={item.key} selected={self.state.data.status === item.key}>{item.value}</option>
                                );
                            })}
                        </select>
                    </div>



                </div>


                <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">
                    <div className="row mt-4">
                        <div className="col-12 col-sm-12 col-md-6"></div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <a onClick={()=>{

                                confirmAlert({
                                    customUI: ({ onClose }) => {
                                        return (
                                            <div className='custom-ui'>
                                                <h1>Cảnh báo !</h1>
                                                <p>Bạn có chắc chắn muốn cập nhật HD này ?</p>
                                                <button onClick={()=>{

                                                    onClose();

                                                }}>Không</button>
                                                <button
                                                    onClick={() => {
                                                        self.SaveContract();
                                                        onClose();
                                                    }}
                                                >Chắc chắn
                                                </button>
                                            </div>
                                        );
                                    }
                                });


                            }}
                               className="font16 btn btn-primary rounded-0 w-100 btn-sm">
                                <i className="fas fa-angle-right me-2" /> Hoàn tất
                            </a>
                        </div>
                    </div>
                </div>


                <div className={"col-12 mt-3"} />


            </div>
        );

    }

}
