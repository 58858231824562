/* eslint-disable */
import React,{Component} from "react";
import {
    _isset_array,
    array_to_select, array_to_select_basic, base64_encode,
    timeConverter,
} from "../../helper/array";
import ItemLoading from "../ItemLoading";
import no_avatar from "../../assets/images/_no_avatar.png";
import Select from "react-select";
import {toast} from "react-toastify";
import DetailPush from "./DetailPush";
import lodash from "lodash";
import EditModal from "../EditModal";

export default class HistoryPush extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
            page:0,
            DetailPush:{
                status:false,
                data:null
            },
            StaticsAll:null,
            selectedOption:null,
            InfoProfile:null,
            DataModal:{
                status:false,
                title:null,
                data:null,
            },
            DisableUpdate:false,
            DisableAddNew:false,
            AllSubject:null
        };

    }


    componentDidMount() {

        if (this.props.data!==null && typeof this.props.data!=='undefined'){
            this.getData(this.props.data._id);
            this.StaticsAll();
        }

        this.getSubject();

        if(typeof this.props.DisableUpdate!=='undefined'){

            this.setState({
                DisableUpdate:lodash.cloneDeep(this.props.DisableUpdate)
            });
        }
        if(typeof this.props.DisableAddNew!=='undefined'){
            this.setState({
                DisableAddNew:lodash.cloneDeep(this.props.DisableAddNew)
            });
        }

        if(typeof this.props.AppThis!=='undefined' && this.props.AppThis!==null){

            var InfoProfile=this.props.AppThis.GetInfoProfile();

            this.setState({
                InfoProfile:InfoProfile
            });

        }

    }


    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    AllSubject:data.data
                });

            }


        }).catch((e)=>{

        });
    }


    getData(id_user,loading=false){

        var AppThis=this.props.AppThis;

        if(loading===true){
            AppThis.PageLoad(true);
        }

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_user', id_user);

        formData.append('page', this.state.page);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/pushhistory", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    data:data.data
                });
            }

            if(loading===true){
                AppThis.PageLoad(false);
            }


        }).catch((e)=>{


            if(loading===true){
                AppThis.PageLoad(false);
            }

        });


    }


    StaticsAll(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListStatics=lodash.cloneDeep(data.data);

                self.setState({
                    StaticsAll:ListStatics,
                });

            }


        }).catch((e)=>{

        });

    }

    OpenNewPush(){

        const self=this;

        if(self.state.StaticsAll===null){
            return;
        }

        var DataModal={
            status:true,
            title:'Chi tiết chăm sóc KH',
            data:[
                {
                    title:"Nội dung",
                    value:null,
                    name:"note",
                    type:"textarea",
                    fullscreen:true
                },
                {
                    title:"Bộ môn",
                    value:null,
                    name:"id_subject",
                    type:"select",
                    data:array_to_select(self.state.AllSubject,"name","_id"),
                    multiple:true
                },
                {
                    title:"Trạng thái",
                    value:null,
                    name:"id_statics",
                    type:"select",
                    data:array_to_select_basic(self.state.StaticsAll,"_id","name"),
                }
            ],
            _id:null
        };

        this.setState({
            DataModal:DataModal
        });
    }

    ResetDataModal(){
        var Reset_DataModal={
            status:false,
            title:null,
            data:null,
        };

        this.setState({
            DataModal:Reset_DataModal
        })

    }

    async UpdateModal(response){

        if(typeof this.props.data==='undefined' || this.props.data===null){
            return;
        }

        var AppThis= this.props.AppThis;

        AppThis.PageLoad(true);


        const self=this;

            const formData = new FormData();

            formData.append('token', AppThis.getToken());

            formData.append('id_address', AppThis.getAddress());

            formData.append('_id', this.props.data._id);

            formData.append('data', base64_encode(response));


            try{
                var get_data=await fetch(AppThis.ConstApp.home_api+this.props.path+"/addpush", {
                    method: 'POST',
                    mode: 'cors',
                    body: formData
                });

                var get_data=await get_data.json();

                AppThis.PageLoad(false);

                if(get_data.status==='error'){
                    toast("Cập nhật dữ liệu thất bại");
                }
                else{
                    toast("Cập nhật dữ liệu thành công");

                    self.setState({
                        data:null,
                        page:0,
                    },function(){
                        self.getData(self.props.data._id);
                    });
                }
            }
            catch (e){
                toast("Cập nhật dữ liệu thất bại");
                AppThis.PageLoad(false);
            }

            this.ResetDataModal();
    }

    RenderHistoryPush(){
        const self=this;
        return(
            <div className={"col-12 RenderRenderHistoryPush"}>
                <div className={"row"}>
                    <div className={"col-12 col-sm-12 col-md-12 bg-light-bold font-weight-bold text-start font14 p-2"}>
                        NV MARKETING SỞ HỮU HIỆN TẠI
                    </div>
                </div>

                {typeof this.props.data.id_admin!=='undefined' && _isset_array(this.props.data.id_admin)?(
                    <>

                        <div className={"col-12 overflow-auto"}>

                            <table className={"mt-3 w-100 table border-0 mw800"}>
                                <thead>
                                <tr className={"border-bottom bg-cloud"}>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-code" /> Mã NV
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-user" /> Họ và tên
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-phone-alt" /> Số điện thoại
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="far fa-clock" />  Ngày giờ
                                    </td>

                                </tr>
                                </thead>
                                <tbody>

                                {this.props.data.id_admin.map(function(item,index){
                                    return(<tr>
                                        <td>
                                            <span>{item.code_user}</span>
                                        </td>
                                        <td>
                                            <span>{item.username}</span>
                                        </td>
                                        <td>
                                            <span>{item.phone_number}</span>
                                        </td>

                                        <td>
                                            <span>{timeConverter(item.time)}</span>
                                        </td>
                                    </tr>);
                                })}

                                </tbody>

                            </table>

                        </div>




                    </>

                ):(
                  <div className={"row"}>
                      <div className={"col-12 bg-light rounded-0 p-2"}>
                          Không có dữ liệu
                      </div>
                  </div>
                )}


                <div className={"row mt-3"}>
                    <div className={"col-12 col-sm-12 col-md-12 bg-light-bold font-weight-bold text-start font14 p-2"}>
                        NV CLUB SỞ HỮU HIỆN TẠI
                    </div>
                </div>

                {typeof this.props.data.id_admin_club!=='undefined' && _isset_array(this.props.data.id_admin_club)?(
                    <>
                        <div className={"col-12 overflow-auto"}>
                            <table className={"mt-3 w-100 table border-0 mw800"}>
                                <thead>
                                <tr className={"border-bottom bg-cloud"}>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-code" /> Mã NV
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-user" /> Họ và tên
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-phone-alt" /> Số điện thoại
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="far fa-clock" />  Ngày giờ
                                    </td>

                                </tr>
                                </thead>
                                <tbody>
                                {this.props.data.id_admin_club.map(function(item,index){
                                    return(
                                        <tr>
                                            <td>
                                                <span>{item.code_user}</span>
                                            </td>
                                            <td>
                                                <span>{item.username}</span>
                                            </td>
                                            <td>
                                                <span>{item.phone_number}</span>
                                            </td>
                                            <td><span>{timeConverter(item.time)}</span>
                                            </td>


                                        </tr>
                                    );
                                })}
                                </tbody>

                            </table>

                        </div>
                    </>

                ):(
                    <div className={"row"}>
                        <div className={"col-12 bg-light rounded-0 p-2"}>
                            Không có dữ liệu
                        </div>
                    </div>
                )}


                <div className={"row"}>
                    <div className={"col-12 col-sm-12 font14 col-md-12 bg-light-bold p-2 mt-2 text-uppercase font-weight-bold mb-2"}>
                        Dữ liệu chăm sóc khách hàng
                    </div>
                </div>


                {self.state.InfoProfile!==null && self.state.InfoProfile.type!==2 && self.state.DisableAddNew===false?(
                    <div className={"col-12 text-end mt-3 mb-3"}>
                        <a href={"javascript:void(0)"} onClick={()=>{
                            self.OpenNewPush();
                        }} className={"btn btn-primary rounded-0 btn-sm"}><i className="fas fa-plus" /> Thêm mới</a>
                    </div>
                ):(
                    <></>
                )}


                {this.state.data.data.length>0?(
                    <div className={"col-12 mt-3 overflow-auto"}>

                        <table className={"mt-3 w-100 table border-0 mw800"}>
                            <thead>
                            <tr className={"border-bottom bg-cloud"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-clock" /> Thời gian
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-user" /> Nhân viên
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-map-marker-alt" /> Cơ sở
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-bell" /> Trạng thái
                                </td>

                            </tr>
                            </thead>
                            <tbody>

                            {this.state.data.data.map(function(item,index){
                                return(
                                    <tr>
                                        <td>
                                            {timeConverter(item.time_create)}
                                        </td>
                                        <td>
                                            {item.id_admin.username +" - "+item.id_admin.code_user}

                                            {item.id_admin.type===2?(
                                                <span className={"ms-1 badge bg-success font10 rounded-0"}>Marketing</span>
                                            ):(<></>)}

                                            {item.id_admin.type===3?(
                                                <span className={"ms-1 badge bg-primary font10 rounded-0"}>Club</span>
                                            ):(<></>)}
                                            {item.id_admin.type===1?(
                                                <span className={"ms-1 badge bg-danger font10 rounded-0"}>Admin</span>
                                            ):(<></>)}
                                        </td>

                                        <td>
                                            {item.id_address.name}
                                        </td>
                                        <td>
                                            <a href={"#"} className={"text-primary"} onClick={()=>{
                                                self.getDetailPush(item._id);
                                            }}
                                            ><i className="fas fa-caret-right me-1" />
                                                {item.id_statics!==null?(
                                                    <span>{item.id_statics.name}</span>
                                                ):(
                                                    <span>Chưa rõ</span>
                                                )}

                                            </a>
                                        </td>

                                    </tr>
                                );
                            })}

                            </tbody>

                        </table>


                    </div>
                ):(
                    <div className={"row"}>
                        <div className={"col-12 alert alert-danger rounded-0 p-2"}>
                            Không có dữ liệu
                        </div>

                    </div>
                )}


                <div className={"col-12 mt-3"}>
                    <ul className="pagination">
                        {this.state.data.prev>=0?(
                            <li className="page-item" >
                                <a className="page-link" onClick={()=>{
                                    var page=this.state.page-1;
                                    this.setState({
                                        page:page
                                    },function(){
                                        self.getData(self.props.data._id,true);
                                    });
                                }}>
                                    Trước
                                </a>
                            </li>
                        ):(<></>)}

                        <li className="page-item">
                            <a className="page-link">
                                <span>{this.state.data.curent+1}</span>
                            </a>
                        </li>

                        {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                            <li className="page-item">
                                <a className="page-link" onClick={()=>{
                                    this.setState({
                                        page:this.state.data.next
                                    },function(){
                                        self.getData(self.props.data._id,true);
                                    });
                                }}>
                                    Kế tiếp
                                </a>
                            </li>
                        ):(<></>)}

                    </ul>
                </div>

            </div>
        );
    }

    render(){

        const self=this;
        if(this.props.data!==null){

            return(
                <>

                    <div className="modal d-block position-fixed modal-dialog-end">
                        <div className={"modal-dialog modal-dialog-scrollable modal-dialog-scrollable modal-lg container-fluid"}>
                            <div className="modal-content shadow border-0 rounded-0">
                                <div className="modal-header bg-header-user rounded-0">
                                    <strong className={"font16 text-uppercase text-white"}>
                                        <i className="far fa-user me-2" />
                                        Chi tiết chăm sóc khách hàng</strong>
                                </div>
                                <div className="modal-body">

                                    <div className={"row mb-2 mt-2 DetailFingerBox"}>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-3 left-info-user"}>

                                            <div className={"col-12 mb-4 text-center"}>

                                                {this.props.data.avatar!==null ?(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img src={this.props.AppThis.ConstApp.home_url +"/" +this.props.data.avatar} />
                                                    </div>

                                                ):(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img  src={no_avatar} />
                                                    </div>
                                                )}
                                            </div>

                                            <div className={"row mb-2 bg-light p-2"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="far fa-user" /> Họ và tên
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{this.props.data.username!==null?this.props.data.username:this.props.data.tmp_user}</span>
                                                </div>
                                            </div>


                                            <div className={"row mb-2 p-2"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-phone-alt" /> Số lần gọi
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{this.props.data.number_call?this.props.data.number_call:0}</span>
                                                </div>
                                            </div>


                                            <div className={"row mb-2 bg-light p-2"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-terminal" /> Mã KH
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{this.props.data.code_user}</span>

                                                </div>
                                            </div>


                                            {this.props.data.user_login?(

                                                <div className={"row mb-2 p-2"}>
                                                    <div className={"col-12 col-sm-12 col-md-5"}>
                                                        <i className="fas fa-phone-alt" /> Số ĐT/ID KH
                                                    </div>
                                                    <div className={"col-12 col-sm-12 col-md-7"}>
                                                        <span className={"font-weight-bold"}>{this.props.data.user_login}</span>
                                                    </div>
                                                </div>

                                            ):(
                                                <></>
                                            )}

                                                <div className={"row mb-2 p-2 bg-light"}>
                                                    <div className={"col-12 col-sm-12 col-md-5"}>
                                                        <i className="fas fa-phone-alt" /> TG gọi cuối
                                                    </div>
                                                    <div className={"col-12 col-sm-12 col-md-7"}>
                                                        {this.props.data['time_call']?timeConverter(this.props.data['time_call']):"___"}
                                                    </div>
                                                </div>

                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 right-info-user"}>

                                            {this.state.data!==null?(
                                                this.RenderHistoryPush()
                                            ):(
                                                <div className={"col-12 text-center d-flex align-items-center justify-content-center align-self-center h-100"}>
                                                    <ItemLoading size={"Large"} />
                                                </div>
                                            )}

                                        </div>



                                    </div>

                                </div>


                                <div className={"modal-footer bg-light"}>

                                    <div className={"col-12 col-sm-12 col-lg-12"}>

                                        <div className={"col-12 col-sm-12 col-lg-2 offset-lg-10"}>
                                            <a onClick={()=>{
                                                this.props.onClose();
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Đóng
                                            </a>
                                        </div>

                                    </div>


                                </div>



                            </div>
                        </div>
                        <div onClick={()=>{
                            this.props.onClose();
                        }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                    </div>

                    {this.state.DetailPush.data!==null?(
                       <>
                           <DetailPush submit={this.state.DetailPush.status} data={this.state.DetailPush.data} onClose={()=>{
                            this.setState({
                                DetailPush:{
                                    status:false,
                                    data:null
                                }
                            });
                        }} TemplateSubmit={()=>{
                        return this.TemplateSubmit();
                        }} AppThis={this.props.AppThis} />
                       </>
                    ):(<></>)}



                    {this.state.DataModal.status===true?(
                        <EditModal size={"small"} onClose={()=>{
                            this.ResetDataModal();
                        }} GetDataSubmit={(response,required)=>{

                            if(required===true){
                                self.UpdateModal(response);
                            }
                            else{
                                toast("Vui lòng nhập đầy đủ thông tin");
                            }
                        }} title={this.state.DataModal.title} data={this.state.DataModal.data} />
                    ):(<></>)}



                </>

        );
        }
        else{
            return(<></>);
        }

    }


    getDetailPush(id=null){

        var AppThis=this.props.AppThis;

        AppThis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        var status=false;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/detailpush", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                if(self.state.InfoProfile!==null && data.data.id_admin_push!==null){

                    if( self.state.InfoProfile._id===data.data.id_admin_push._id){
                        status=true;
                    }

                }

                    self.setState({
                    DetailPush:{
                        data:data.data,
                        status:status
                    }
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            AppThis.PageLoad(false);

        });



    }


    TemplateSubmit(){

        const self=this;

        if(this.state.DetailPush.data!==null && this.state.DisableUpdate===false){

            return(
                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-8"}>

                        {this.state.StaticsAll!==null?(
                            <div className={"row"}>

                                <div className={"col-12 col-sm-12 col-md-6"}>

                                    <Select menuPlacement={"top"} isSearchable={true} onChange={(selectedOption)=>{
                                        self.setState({
                                            selectedOption:selectedOption.value
                                        });
                                    }} options={array_to_select(this.state.StaticsAll,'name','_id')} />

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6"}>
                                    <a onClick={()=>{
                                        this.SubmitRequiredUpdate();
                                    }} className={"btn btn-success rounded-0 w-100"}>
                                        <i className="fas fa-pencil-alt me-1" /> Cập nhật
                                    </a>

                                </div>

                            </div>
                        ):(
                            <ItemLoading />
                        )}


                    </div>

                    <div className={"col-12 col-sm-12 col-md-4"}>

                        <a onClick={()=>{
                            this.CallNow(this.state.DetailPush.data.id_user._id);
                        }} className={"btn btn-primary rounded-0 w-100"}>
                            <i className="fas fa-layer-group me-1" /> Gọi ngay
                        </a>

                    </div>

                </div>
            );

        }
        else{
            return(<></>);
        }


    }

    CallNow(id){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('_id', id);

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/getphone", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Cập nhật không thành công");
            }
            else{

                if(typeof data.data!=='undefined'){
                    window.open("tel:"+data.data,"_blank");
                }

            }

            Appthis.PageLoad(false);

        }).catch((e)=>{
            toast("Cập nhật không thành công");
            Appthis.PageLoad(false);

        });


    }

    SubmitRequiredUpdate(){

        if(this.state.selectedOption===null){
            toast("Vui lòng chọn trạng thái");
            return;
        }

        this.EventUpdatePush();


    }

    async EventUpdatePush(){

        var Appthis=this.props.AppThis;

        const self=this;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_statics', this.state.selectedOption);

        formData.append('id_push', this.state.DetailPush.data._id);

        try{
            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updatepush", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });
            get_data=await get_data.json();

            if(get_data.status==='error'){
                toast("Cập nhật không thành công");
            }
            else{
                toast("Cập nhật thành công");
            }

            Appthis.PageLoad(false);

            this.setState({
                selectedOption:null,
                DetailPush:{
                    data:null,
                    status:false
                },
                data:null
            },function(){
                self.getData(self.props.data._id);
            });

        }
        catch (e){

            toast("Có lỗi trong quá trình xử lý xin vui lòng thử lại");

            this.setState({
                selectedOption:null,
                DetailPush:{
                    data:null,
                    status:false
                }
            });

            Appthis.PageLoad(false);

        }


    }

}
