/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import {
    array_to_select,
    base64_encode, defaultValueObject,
    get_item_in_array, getItemInArray, remove_empty_array,
} from "../../helper/array";
import Select from "react-select";
import InfoProfile from "../../global/profile/InfoProfile";
import no_avatar from "../../assets/images/_no_avatar.png";
export default class InfoPercentContract extends Component{
    constructor() {
        super();
        this.state={
            PercentContract:null,
            ProfileInfo:null,
            AllPercent:null,
            isLoading:true,
            ListAdminClub:null,
            DataSave:[
                {
                    id_admin:null,
                    id_percent:null,
                    type:true
                }
            ],
            EditPercentContract:false

        };

    }

    componentDidMount() {

        this.getPercent();

        this.getAdminCLub();

        const self=this;

        if (typeof this.props.data!=='undefined'){

            var data=this.props.data;

            this.setState({
                PercentContract:data
            },function(){
                self.UpdatePercentContract();
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getAdminCLub(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListAdminClub:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    getPercent(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/percent", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    AllPercent:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    UpdatePercentContract(){

        var DataPercentContract=this.state.PercentContract.data;

        var DataSave=[];

        if(DataPercentContract.length>0){

            DataPercentContract.map(function(value,key){
                DataSave.push({
                    id_admin:value.id_admin,
                    id_percent:value.id_percent['_id'],
                    type:true
                });
            });

            if (DataSave.lenth===0){
                DataSave.push({
                    id_admin:null,
                    id_percent:null,
                    type:true
                });
            }
        }
        else{
            DataSave.push({
                id_admin:null,
                id_percent:null,
                type:true
            });
        }

        this.setState({
            DataSave:DataSave,
            isLoading:false
        });

    }


    EventInputCode(index=null,event=null){
        const self=this;
        if(this.TimeOutEvent){
            clearTimeout(this.TimeOutEvent);
        }

        this.TimeOutEvent=setTimeout(function(){
            self.verifyAdmin(index,event.target.value,event);
        },1000);
    }

    verifyAdmin(index=null,code=null,event){

        if(index===null || code===null || code.length===0){
            return;
        }

        this.props.AppThis.PageLoad(true);

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('code_user', code);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"staffinfo", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Không tìm thấy thông tin tài khoản");
                self.UpdatePercentData(index,"id_admin",null);
                event.target.value='';

                self.setState({
                    ProfileInfo:null
                });

            }
            else{

                self.setState({
                    ProfileInfo:data.data
                });

                self.UpdatePercentData(index,"id_admin",data.data);

            }
            AppThis.PageLoad(false);

        }).catch((e)=>{

            self.UpdatePercentData(index,"id_admin",null);

            event.target.value='';

            toast("Lấy dữ liệu thất bại");

            AppThis.PageLoad(false);

            self.setState({
                ProfileInfo:null
            });

        });

    }

    AddPercentData(){

        var DataSave=this.state.DataSave;

        DataSave.push({
            id_admin:null,
            id_percent:null,
            type:true
        });

        this.setState({
            DataSave:DataSave
        });

    }

    DeletePercentData(number){

        var DataSave=this.state.DataSave;

        if (DataSave.length>1){
            delete DataSave[number];
        }
        DataSave=remove_empty_array(DataSave);

        this.setState({
            DataSave:DataSave
        });
    }

    SavePercentContract(){

        const self=this;

        var DataSave=this.state.DataSave;

        var AllPercent=this.state.AllPercent;

        var number_error=0;

        var total_price=0;

        if(DataSave.length===0){
            number_error+=1;
        }
        else{

            DataSave.map(function(item,index){

                if(item.id_admin===null || item.id_percent===null){
                    number_error+=1;
                }
                else{
                    var get_item=getItemInArray(item.id_percent,'_id',AllPercent);
                    total_price+=parseInt(get_item['number']);
                }
            });

        }

        if(total_price!==100){
            number_error+=1;
        }


        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var filter_percent_data=[];

            DataSave.map(function(item,index){

                var tmp={};

                if(typeof item['id_admin']['key']!=='undefined'){
                    tmp['id_admin']=item['id_admin']['key'];
                }

                if(typeof item['id_admin']['_id']!=='undefined'){
                    tmp['id_admin']=item['id_admin']['_id'];
                }

                tmp['id_percent']=item['id_percent'];

                filter_percent_data.push(tmp);

            });


            this.setState({
                Loading:true
            });

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_wallet', this.state.PercentContract.id_wallet._id);

            var base_64data=base64_encode(filter_percent_data);

            formData.append('data', base_64data);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/update", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{
                if(data.status==='ok'){
                    toast("Cập nhật thành công");
                }
                else{
                    toast("Có lỗi trong quá trình xử lý xin vui lòng thử lại sau");
                }
                Appthis.PageLoad(false);
                self.props.onClose();

            }).catch((e)=>{
                toast("Có lỗi trong quá trình xử lý xin vui lòng thử lại sau");
                Appthis.PageLoad(false);
                self.props.onClose();
            });


        }

    }

    UpdatePercentData(number,key,value){
        var DataSave=this.state.DataSave;
        if(DataSave[number]===null ||typeof DataSave[number]==='undefined'){
            DataSave[number]={};
        }
        DataSave[number][key]=value;

        this.setState({
            DataSave:DataSave
        });
    }

    render() {

        const self=this;

        var PercentContract=this.state.PercentContract;

        if(PercentContract!==null){
            return(
                <div className="modal d-block position-fixed modal-dialog-end">
                    <div className={"modal-dialog container-fluid modal-dialog-scrollable"}>
                        <div className="modal-content shadow border-0">
                            <div className="modal-header">

                                <div className={"container"}>

                                    <div className={"row"}>
                                        <div className={"col-8"}>
                                            <strong className={"font18 text-black text-uppercase"}>Chia % HD</strong>
                                        </div>

                                        <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.onClose();
                                    }} className={"text-black right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="modal-body">


                                <div className={"container p-0"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-3 border-light p-0 border-start border-end d-none d-xl-block"}>

                                            <div className={"col-12 p-2 alert alert-primary mb-4 font-weight-bold rounded-0 text-uppercase font16"}>
                                                <i className="far fa-user me-2" />  Chi tiết nhân viên
                                            </div>

                                            <div className={"col-12 mb-4 text-center"}>

                                                {PercentContract.id_admin.avatar!==null ?(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img src={this.props.AppThis.ConstApp.home_url +"/" +PercentContract.id_admin.avatar} />
                                                    </div>

                                                ):(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img  src={no_avatar} />
                                                    </div>
                                                )}
                                            </div>

                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="far fa-user" /> Họ và tên
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{PercentContract.id_admin.username}</span>
                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-terminal" /> Mã NV
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    {PercentContract.id_admin.code_user}

                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="far fa-envelope" />  Email
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {PercentContract.id_admin.email}
                                                </div>
                                            </div>

                                            <div className={"row m-0 mb-2 p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-phone-alt" />   Phone
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {PercentContract.id_admin.phone_number!==null?PercentContract.id_admin.phone_number:"Chưa rõ"}
                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-paper-plane" />  Chức vụ
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {PercentContract.id_admin && typeof PercentContract.id_admin.position!==null && PercentContract.id_admin.position!==null?PercentContract.id_admin.position.name:"Chưa rõ"}
                                                </div>
                                            </div>


                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9"}>

                                            <div className={"col-12"}>
                                                <div className={"col-12"}>

                                                    {this.state.isLoading===true?(
                                                        <div className={"col-12 text-center d-flex flex-row justify-content-center"}>
                                                            <ItemLoading size={"Large"} />
                                                        </div>
                                                    ):(
                                                        <>

                                                            {this.state.PercentContract.data.length!==0?(

                                                                <>
                                                                    <div className={"alert alert-primary rounded-0 mt-3 mb-4"}>
                                                                        <i className="fas fa-exclamation-triangle" /> Chi tiết % chia phiếu thu
                                                                    </div>

                                                                    <div className={"col-12 overflow-auto mb-5"}>

                                                                        <table className={"mt-2 w-100 table border-0 mw800 table-style-old"}>

                                                                            <thead>

                                                                            <tr className={"border-bottom bg-light"}>

                                                                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                    <i className="fas fa-list-ol me-1" /> Cơ sở
                                                                                </td>
                                                                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                    <i className="fas fa-list-ol me-1" /> Nhân viên
                                                                                </td>
                                                                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                    <i className="fas fa-list-ol me-1" /> SDT
                                                                                </td>
                                                                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                    <i className="fas fa-list-ol me-1" /> Tỷ lệ %
                                                                                </td>

                                                                            </tr>
                                                                            </thead>

                                                                            <tbody>

                                                                            {
                                                                                self.state.PercentContract.data.map(function(item,index){
                                                                                    return(
                                                                                        <tr>
                                                                                            <td>

                                                                                                {item.id_admin.address_id.map(function(name){
                                                                                                    return(
                                                                                                        <span className={"badge bg-dark rounded-0 m-1"}>{name}</span>
                                                                                                    );
                                                                                                })}

                                                                                            </td>
                                                                                            <td>
                                                                                                {item.id_admin.username+" - "+item.id_admin.code_user}
                                                                                            </td>

                                                                                            <td>
                                                                                                {item.id_admin.phone_number}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.id_percent.name}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            }

                                                                            </tbody>


                                                                        </table>

                                                                    </div>

                                                                </>

                                                            ):(
                                                                <>
                                                                    {this.RenderData()}
                                                                </>
                                                            )}


                                                            {this.FormUpdateAdmin()}



                                                        </>
                                                    )}


                                                </div>
                                            </div>



                                        </div>


                                    </div>

                                </div>



                            </div>


                        </div>
                    </div>
                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

                    {this.state.ProfileInfo!==null?(
                        <InfoProfile onClose={()=>{
                            this.setState({
                                ProfileInfo:null
                            });
                        }} data={this.state.ProfileInfo} />
                    ):(<></>)}

                </div>
            );
        }
        else{
            return(<></>);
        }
    }


    RenderData(){

        var current_path=window.location.pathname;
        const self=this;
        if(current_path==='/cmypercentcontract'){

                return(
                    <>
                        <div className={"alert alert-success rounded-0 mt-3 mb-4"}>
                            <i className="fas fa-exclamation-triangle" /> Chi tiết % chia phiếu thu
                        </div>

                        {
                            this.state.DataSave.map(function(item,index){
                                return(
                                    <div className={"row pb-3 pt-3 border-bottom"}>

                                        <div className={"col-12 col-md-2 d-flex flex-row flex-wrap align-items-center mb-2"}>

                                            <label>
                                                <input type={'checkbox'} className={"form-check-input"} onChange={()=>{
                                                    var type_update=!item.type;
                                                    self.UpdatePercentData(index,'type',type_update);
                                                    self.UpdatePercentData(index,'id_admin', null);
                                                }} /> Ngoài Club
                                            </label>

                                        </div>

                                        <div className={"col-12 col-md-12 col-lg-3 mb-2"}>
                                            {item.type===true?(
                                                <>
                                                    {self.state.ListAdminClub!==null?(
                                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                                            if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                                var get_admin_select=get_item_in_array("key",selectedOption.value,self.state.ListAdminClub);
                                                                self.UpdatePercentData(index,'id_admin',get_admin_select);
                                                            }
                                                            else {
                                                                self.UpdatePercentData(index,'id_admin',null);
                                                            }

                                                        }} defaultValue={item.id_admin!==null?defaultValueObject(getItemInArray(item.id_admin._id,"key",self.state.ListAdminClub),"value","key"):null} options={array_to_select(self.state.ListAdminClub,'value','key')} />

                                                    ):(
                                                        <ItemLoading />
                                                    )}
                                                </>
                                            ):(
                                                <input type={'text'} onKeyUp={(event)=>{
                                                    if(event.keyCode===8){
                                                        self.UpdatePercentData(index,'id_admin',null);
                                                        event.target.value='';
                                                    }
                                                    else{
                                                        self.EventInputCode(index,event);
                                                    }
                                                }} value={item.id_admin!==null?item.id_admin.code_user:null} defaultValue={item.id_admin!==null?item.id_admin.code_user:null}
                                                       className={"form-control w-100"} placeholder={"Mã nhân viên"} />
                                            )}

                                        </div>
                                        <div className={"col-12 col-md-12 col-lg-3 mb-2"}>
                                            {self.state.AllPercent!==null?(
                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                        self.UpdatePercentData(index,'id_percent',selectedOption.value);
                                                    }
                                                    else {
                                                        self.UpdatePercentData(index,'id_percent',null);
                                                    }
                                                }} defaultValue={item.id_percent!==null?defaultValueObject(getItemInArray(item.id_percent,"_id",self.state.AllPercent),"name","_id"):null} options={array_to_select(self.state.AllPercent,'name','_id')} />
                                            ):(
                                                <ItemLoading />
                                            )}
                                        </div>
                                        <div className={"col-12 col-md-12 col-lg-3 align-items-end d-flex flex-row mb-2"}>
                                            <a onClick={()=>{
                                                self.DeletePercentData(index);
                                            }} className={"text-black font16 me-3"}>
                                                <i className="fas fa-trash" />
                                            </a>

                                            <a onClick={()=>{
                                                self.AddPercentData();
                                            }} className={"text-black font16"}>
                                                <i className="text-black-50 fas fa-plus-circle" />
                                            </a>

                                        </div>

                                    </div>
                                );
                            })
                        }

                        <div className={"row mt-5"}>

                            <div className={"col-12 col-sm-12 col-md-4 offset-md-8"}>
                                <a onClick={()=>{
                                    this.SavePercentContract();
                                }} className={"btn btn-primary rounded-0 w-100"}>
                                    <i className="fas fa-save me-1" /> Cập nhật
                                </a>

                            </div>


                        </div>
                    </>
                );

            }
        else{
                return(
                    <>
                        <div className={"alert alert-danger rounded-0 mt-3 mb-4"}>
                            <i className="fas fa-exclamation-triangle" /> Phiếu thu chưa chia %
                        </div>

                    </>
                );
            }
    }



    FormUpdateAdmin(){

        var profile=this.props.AppThis.GetInfoProfile();

        const self=this;

        if(profile.type===1){

            return(
                <>
                    {this.state.EditPercentContract===false?(
                        <div className={"col-12 text-end mt-3 mb-3"}>
                            <a href={"#"} onClick={()=>{
                                this.setState({
                                    EditPercentContract:true,
                                    DataSave:[
                                        {
                                            id_admin:null,
                                            id_percent:null,
                                            type:true
                                        }
                                    ]
                                });
                            }} className={"btn btn-primary rounded-0 p-2 ps-3 pe-3"}>
                                <i className="far fa-edit me-1" /> Sửa % chia
                            </a>
                        </div>

                    ):(
                        <>

                            <div className={"alert rounded-0 mt-3 alert-danger"}>
                                <i className="fas fa-exclamation-triangle me-1"></i> Cập nhật % chia phiếu thu
                            </div>

                            {
                                this.state.DataSave.map(function(item,index){
                                    return(
                                        <div className={"row pt-3 pb-3 border-bottom"}>

                                            <div className={"col-12 col-md-12 col-lg-2 pb-2 d-flex flex-row flex-wrap align-items-center"}>

                                                <label>
                                                    <input type={'checkbox'} className={"form-check-input"} onChange={()=>{
                                                        var type_update=!item.type;
                                                        self.UpdatePercentData(index,'type',type_update);
                                                        self.UpdatePercentData(index,'id_admin', null);
                                                    }} /> Ngoài Club
                                                </label>

                                            </div>

                                            <div className={"col-12 col-md-12 col-lg-3 pb-2"}>
                                                {item.type===true?(
                                                    <>
                                                        {self.state.ListAdminClub!==null?(
                                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                                if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                                    var get_admin_select=get_item_in_array("key",selectedOption.value,self.state.ListAdminClub);
                                                                    self.UpdatePercentData(index,'id_admin',get_admin_select);
                                                                }
                                                                else {
                                                                    self.UpdatePercentData(index,'id_admin',null);
                                                                }
                                                            }} defaultValue={item.id_admin!==null?defaultValueObject(getItemInArray(item.id_admin._id,"key",self.state.ListAdminClub),"value","key"):null} options={array_to_select(self.state.ListAdminClub,'value','key')} />

                                                        ):(
                                                            <ItemLoading />
                                                        )}
                                                    </>
                                                ):(
                                                    <input type={'text'} onKeyUp={(event)=>{
                                                        if(event.keyCode===8){
                                                            self.UpdatePercentData(index,'id_admin',null);
                                                            event.target.value='';
                                                        }
                                                        else{
                                                            self.EventInputCode(index,event);
                                                        }
                                                    }} value={item.id_admin!==null?item.id_admin.code_user:null} defaultValue={item.id_admin!==null?item.id_admin.code_user:null}
                                                           className={"form-control w-100"} placeholder={"Mã nhân viên"} />
                                                )}

                                            </div>

                                            <div className={"col-12 col-md-12 col-lg-3 pb-2"}>

                                                {self.state.AllPercent!==null?(
                                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                                        if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                            self.UpdatePercentData(index,'id_percent',selectedOption.value);
                                                        }
                                                        else {
                                                            self.UpdatePercentData(index,'id_percent',null);
                                                        }
                                                    }} defaultValue={item.id_percent!==null?defaultValueObject(getItemInArray(item.id_percent,"_id",self.state.AllPercent),"name","_id"):null} options={array_to_select(self.state.AllPercent,'name','_id')} />
                                                ):(
                                                    <ItemLoading />
                                                )}

                                            </div>

                                            <div className={"col-12 col-md-12 col-lg-4 pb-2 align-items-end d-flex flex-row"}>
                                                <a onClick={()=>{
                                                    self.DeletePercentData(index);
                                                }} className={"text-black font16 me-3"}>
                                                    <i className="fas fa-trash" />
                                                </a>

                                                <a onClick={()=>{
                                                    self.AddPercentData();
                                                }} className={"text-black font16"}>
                                                    <i className="text-black-50 fas fa-plus-circle" />
                                                </a>

                                            </div>

                                        </div>
                                    );
                                })
                            }

                            <div className={"row mt-5"}>

                                <div className={"col-12 col-sm-12 col-md-6 offset-md-6"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-md-6 pb-3"}>
                                            <a onClick={()=>{
                                                this.SavePercentContract();
                                            }} className={"btn btn-primary rounded-0 w-100"}>
                                                <i className="fas fa-save me-1" /> Cập nhật
                                            </a>
                                        </div>

                                        <div className={"col-12 col-md-6 pb-3"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    EditPercentContract:false,
                                                    DataSave:[
                                                        {
                                                            id_admin:null,
                                                            id_percent:null,
                                                            type:true
                                                        }
                                                    ]
                                                });
                                            }} className={"btn btn-danger rounded-0 w-100"}>
                                                Hủy
                                            </a>
                                        </div>


                                    </div>

                                </div>


                            </div>
                        </>
                    )}


                </>
            );
        }
        else{
            return(<></>);
        }

    }

}
