/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_array,
    _isset_string, array_to_select, base64_encode,
    formatNumber, getItemInArray,
    timeConverterDate,
} from "../../helper/array";
import {get_name_card, status_contract, status_payment} from "../../helper/member";
import DetailContract from "../../global/contract/DetailContract";
import DatePicker from "react-datepicker";
import HistoryPush from "../../global/push/HistoryPush";
import Floater from 'react-floater';
import Select from "react-select";

const const_status_contract=[
    {
        label:"Tất cả",
        value:null
    },
    {
        label:"bảo lưu",
        value:0
    },
    {
        label:"Đang tập",
        value:1
    },
    {
        label:"Chưa kích hoạt",
        value:2
    },
    {
        label:"Huỷ",
        value:3
    },
    {
        label:"Hết hiệu lực",
        value:4
    },
];
const INIT_STATE={
    data:null,
    query:{
        start_time:null,
        end_time:null,
        number_contract:null,
        input_search:null,
        id_address:null,
        status_contract:null,
        id_admin:null
    },
    DataDetailContract:null,
    page:0,
    SearchAdvanced:false,
    Statics:null,
    HistoryPush:{
        status:false,
        data:null
    },
    ListAdmin:null
};

export default class teamcontract extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }


    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.GetStatics();

        this.getAddress();

        this.getListAdmin();
    }
    UpdateQuerySearch(key,value){
        var query=this.state.query;
        query[key]=value;
        this.setState({
            query:query
        });
    }

    getAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type', 3);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                var ListAddress=data.data;

                ListAddress.unshift({
                    _id:null,
                    name:"Tất cả"
                });

                self.setState({
                    ListAddress:ListAddress
                });
            }

        }).
        catch((e)=>{

        });


    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getData(load=false){

        var SearchAdvanced=this.getQuerySearch();

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }
        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"teamcontract", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    GetStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        const self=this;

        fetch(Appthis.ConstApp.home_api+"teamcontract/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Statics:data.data
                });
            }


        }).catch((e)=>{


        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }


    getListAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"teamcontract/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=[];

                ListAdmin=ListAdmin.concat(data.data);

                ListAdmin.unshift({
                    _id:null,
                    username:"Tất cả",
                    code_user:null
                });

                self.setState({
                    ListAdmin:ListAdmin
                });

            }


        }).catch((e)=>{

        });


    }

    thisRenderData(){

        const self=this;
        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"overflow-auto col-12"}>
                    <table className={"mt-3 w-100 table border-0 mw1600 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-map-marker me-2" />
                                Cơ sở
                            </td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-user me-2"/>
                            NV Marketing
                            </td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-user me-2"/> TÊN HỘI VIÊN</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i
                                    className="fas fa-mobile-alt me-2" /> SDT/ID</td>

                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-credit-card me-2" />
                                DỊCH VỤ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-file-excel me-2" />
                                SỐ HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-question-circle me-2" />
                                TRẠNG THÁI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-dollar-sign me-2" />
                                GIÁ TRỊ HD</td>

                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-dollar-sign me-2" />
                               NGÀY LÊN HD</td>

                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                <i className="fas fa-directions me-2" />
                                THAO TÁC</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        <span>{value.id_address.name}</span>
                                    </td>
                                    <td>
                                        {
                                            value.id_admin_marketing && _isset_array(value.id_admin_marketing)?(
                                                <>
                                                    {value.id_admin_marketing.map(function(item){
                                                        return(
                                                            <span className={"badge font11 bg-primary rounded-0 m-1"}>{item.code_user}</span>
                                                        );
                                                    })}
                                                </>
                                            ):(
                                                <span>Chưa rõ</span>
                                            )
                                        }
                                    </td>

                                    <td>
                                        <span>{value.id_user.username}</span>
                                    </td>
                                    <td>
                                        <span>{value.id_user.user_login}</span>
                                    </td>

                                    <td>
                                        <span className={"me-2"}>{value.id_card.name}</span>
                                        {get_name_card(value.id_card.type)}
                                    </td>

                                    <td>

                                        {value.group_member!==null?(
                                            <i className="fas fa-check-circle text-primary me-1" />
                                        ):(<></>)}


                                        <span className={"me-1"}>{value.number_contract}</span>
                                        {status_payment(value)}
                                    </td>

                                    <td>
                                        {status_contract(value)}
                                    </td>

                                    <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.info_contract_data.price_sale)} VNĐ
                                       </span>
                                    </td>

                                    <td>
                                       <span>
                                           {timeConverterDate(value.time_create)}
                                       </span>
                                    </td>


                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.setState({
                                                DataDetailContract:value
                                            });
                                        }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1 mt-1"}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết hợp đồng">
                                                <i className={"far fa-folder-open"}></i>
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.ShowHistoryPush(value.id_user);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-warning p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lịch sử chuyển số">
                                                <i className="fas fa-history" />
                                            </Floater>
                                        </a>


                                    </td>

                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    ShowHistoryPush(data){

        this.setState({
            HistoryPush:{
                status:true,
                data:data
            }
        });

    }


    RenderStatics(){

        var Statics=this.state.Statics;

        if(Statics!==null){
            return(
                <div className={"row m-0 mt-4"}>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-primary"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Doanh thu dự tính</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{formatNumber(Statics.total_price)} VNĐ</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-primary"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Doanh thu thực</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{formatNumber(Statics.actual_revenue)} VNĐ</p>
                            </div>
                        </div>
                    </div>


                    <div className={"col-12 mt-2"} />
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-warning"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD PT trong tháng</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_pt_month}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-tenge rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Member</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_member}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-money-check rounded-circle bg-primary"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Nhóm CD</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_statics_group}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">

                                <i className="d-flex justify-content-center text-white align-items-center fab fa-adversal rounded-circle bg-success"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Linh hoạt</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_flexible}</p>
                            </div>
                        </div>
                    </div>







                </div>
            );
        }
        else{
            return(
                <div className={"col-12 justify-content-center d-flex flex-row mb-4 pt-5"}>
                    <ItemLoading />
                </div>
            );
        }
    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"teamcontract/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-4 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                <h3 className="font18 mb-0 text-uppercase font-weight-bold font-weight-bold"> HD của team</h3>
                            </div>

                            <div className="col-12 col-sm-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>





                                        <input onChange={(event)=>{
                                            this.UpdateQuerySearch("input_search",event.target.value);

                                        }} autoComplete={"off"} type="text"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Họ và tên / SDT"/>

                                        <input defaultValue={this.state.query.number_contract} type={"text"} onChange={(event)=>{
                                            self.UpdateQuerySearch("number_contract",event.target.value);
                                        }} className={"font13 form-control d-inline-block w-auto rounded-0"} placeholder={"Số HD"} />


                                        <a onClick={()=>{
                                            self.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });

                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>


                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:true,
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                            <i className="fas fa-search-plus" /> Tìm kiếm nâng cao </a>


                                    </div>

                                </div>



                            </div>

                        </div>


                        {this.RenderStatics()}


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 p-3 mt-4"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>


                    </div>



                    {this.state.DataDetailContract!==null?(
                        <DetailContract OnClose={()=>{
                            this.setState({
                                DataDetailContract:null
                            });

                        }} EventDetail={(id)=>{
                            this.setState({
                                DataDetailContract:null
                            },function(){
                                self.getDetailContract(id);
                            });
                        }} data={this.state.DataDetailContract} />
                    ):(
                        <></>
                    )}




                    {this.state.SearchAdvanced===true?(
                        this.SearchAdvanced()
                    ):(<></>)}

                    {this.state.ModalSearchPublic===true?(
                        this.ModalSearchPublic()
                    ):(<></>)}


                    {this.state.HistoryPush.status===true?(

                        <HistoryPush DisableUpdate={true} DisableAddNew={true} path={"teamcontract"} AppThis={this.props.AppThis} data={this.state.HistoryPush.data} onClose={()=>{
                            this.setState({
                                HistoryPush:{
                                    status:false,
                                    data:null
                                }
                            });

                        }} />
                    ):(<></>)}



                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm nâng cao</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row box_input"}>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian bắt đầu"} />


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Tên khách hàng / SDT</p>
                                    <input defaultValue={this.state.query.input_search} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("input_search",event.target.value);
                                    }} className={"form-control"} placeholder={"Tên khách hàng / SDT"} />
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Trạng thái HD</p>

                                    <Select defaultValue={this.state.query.status_contract!==null?getItemInArray(this.state.query.status_contract,"value",const_status_contract):null} isSearchable={true} onChange={(selectedOption)=>{
                                        if(selectedOption!==null && typeof selectedOption!=='undefined'){
                                            self.UpdateQuerySearch("status_contract",selectedOption.value);
                                        }
                                        else{
                                            self.UpdateQuerySearch("status_contract",null);
                                        }

                                    }}  options={const_status_contract} />

                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Nhân viên</p>

                                    {this.state.ListAdmin!==null?(
                                        <Select defaultValue={this.state.query.id_admin!==null?getItemInArray(this.state.query.id_admin,"value",array_to_select(this.state.ListAdmin,"username","_id")):null} isSearchable={true} onChange={(selectedOption)=>{
                                            if(selectedOption!==null && typeof selectedOption!=='undefined'){
                                                self.UpdateQuerySearch("id_admin",selectedOption.value);
                                            }
                                            else{
                                                self.UpdateQuerySearch("id_admin",null);
                                            }
                                        }}  options={array_to_select(this.state.ListAdmin,"username","_id")} />
                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>

                                    <p className={"mb-1"}>Cơ sở</p>


                                    {this.state.ListAddress!==null?(
                                        <Select defaultValue={this.state.query.id_address!==null?getItemInArray(this.state.query.id_address,"value",array_to_select(this.state.ListAddress,"name","_id")):null} isSearchable={true} onChange={(selectedOption)=>{
                                            if(selectedOption!==null && typeof selectedOption!=='undefined'){
                                                self.UpdateQuerySearch("id_address",selectedOption.value);
                                            }
                                            else{
                                                self.UpdateQuerySearch("id_address",null);
                                            }

                                        }}  options={array_to_select(this.state.ListAddress,"name","_id")} />
                                    ):(
                                        <ItemLoading />
                                    )}


                                </div>






                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                self.setState({
                                                    Statics:null,
                                                    SearchAdvanced:false
                                                },function(){
                                                    self.getData(true);
                                                    self.GetStatics();
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }


}
