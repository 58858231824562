import React,{Component} from "react";
import Lottie from 'lottie-react-web';
import error_page from '../assets/file/error_page.json';
export default class ErrorPage extends Component{

    componentDidMount() {

    }
    render(){
        return(
            <div className={"AppLoading mh-100vh w-100 row justify-content-center align-items-center align-self-center"}>
                <div className={"col-12 w-100"}>

                    <div className={"Section-AppLoading m-auto"}>
                        <Lottie options={{
                            animationData:error_page,
                            loop:true,
                            autoplay:true
                        }}  />
                    </div>

                    <div className={"text-center mt-4 text-uppercase font16 font-weight-bold"}><p>Trang bạn tìm kiếm không hoạt động</p></div>
                </div>

            </div>
        );
    }


}
