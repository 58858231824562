import React,{Component} from "react";
import Lottie from 'lottie-react-web';
import hubit from '../assets/file/hubit.json';
import "../App.css";

export default class GroupLoading extends Component{
    render() {

        return(
            <div className={"AppLoading mh-100vh w-100 row justify-content-center align-items-center align-self-center"}>
                <div className={"col-12 w-100"}>
                    <div className={"Section-AppLoading m-auto"}>
                        <Lottie options={{
                            animationData:hubit,
                            loop:true,
                            autoplay:true
                        }}  />
                    </div>

                    <div className={"text-center font16"}><p>Đang tải dữ liệu vui lòng chờ ...</p></div>
                </div>

            </div>
        );


    }
}
