/* eslint-disable */
import React from "react";
import {ConvertStringToDate} from "./array";

export function number_did_contract(object){

    if(object.id_card.type===2){

        if(new Date().getTime()/1000<object.start_day){

            if(Math.ceil((object.end_day_contract-object.start_day)/86400)-1>=0){
                return(
                    <span>{Math.ceil((object.end_day_contract-object.start_day)/86400)-1}</span>
                );
            }
            else{
                return(
                    <span>0</span>
                );
            }
        }
        else{

            if(Math.ceil((object.end_day_contract-(new Date().getTime()/1000))/86400)-1>=0){
                return(
                    <span>
                    {Math.ceil((object.end_day_contract-(new Date().getTime()/1000))/86400)-1}
                </span>
                );
            }
            else{

                return(
                    <span>0</span>
                );
            }
        }
    }
    else{

        if(object.info_contract_data.number_card-object.info_contract_data.number_did_pt>=0){
            return(
                <span>{object.info_contract_data.number_card-object.info_contract_data.number_did_pt}</span>
            );
        }
        else{
            return(<span>0</span>);
        }

    }

}

export function status_contract(object){

    var response='';

    var current_time=new Date().getTime();

    current_time=Math.ceil(current_time/1000);

    if(object.status===1 || object.status===2){

        if(object.status_contract===true){
           response='Đang tập';
        }
        else{

            if(current_time<object.start_day){
                response='Chưa kích hoạt';
            }
            else{

                if(current_time>=object.end_day_contract){
                    response='Hết hạn';
                }
                else{

                    if(object.billing_information.total_price!==object.info_contract_data.price_sale){
                        response='Chưa thanh toán hết';
                    }
                    else{
                        response='Thanh toán hết';
                    }

                }

            }

        }

    }

    if (object.status===0){
        response='Bảo lưu';
    }
    if (object.status===3){
        response='Hủy';
    }
    if (object.is_pause!==0){
        response='HD bị phạt';
    }

    return response;
}


export function get_type_edit_contract(item){

    var response='';

    switch (item.action) {

        case 'edit_card':
            response='Cập nhật thẻ';
            break;
        case 'edit_price':
            response='Cập nhật giá tiền';
            break;
        case 'edit_discount':
            response='Cập nhật mã giảm giá';
            break;

        case 'protector':
            response='Cập nhật người liên hệ khẩn cấp';
            break;

        case 'contact_person':
            response='Cập nhật nguời đồng ký tên';
            break;

    }

    return response;
}
export function status_payment(object){

    if(object.billing_information.total_price!==object.info_contract_data.price_sale){

        if(object.billing_information.total_price===0){
            return(
                <span className={"badge bg-danger font9 rounded-0"}>Chưa thanh toán</span>
            );
        }
        else{
            return(
                <span className={"badge bg-success font9 rounded-0"}>Đặt cọc</span>
            );
        }

    }
    else{
        return(
            <span className={"badge bg-primary font9 rounded-0"}>Trả hết</span>
        );
    }

}

export function get_name_type_receipts(type){

    var response='Chưa rõ';

    switch (type) {

        case 0:
            response='Sau HD';
            break;

        case 1:
            response='Trước HD';
            break;

        case 2:
            response='Chuyển đổi PTTT';
            break;
    }

    return response;
}
export function type_wallet(object){

    var response='Chưa rõ';

    switch (object.type) {

        case 1:
            response='Nạp tiền';
            break;

        case 2:
            response='Refund';
            break;
        case 3:
            response='Bảo lưu HD';
            break;
        case 4:
            response='Chuyển nhượng HD';
            break;
        case 5:
            response='Upgrade';
            break;
    }

    return response;
}

export function get_name_card(type=0){

    var response=null;

    switch (type) {

        case 1:
            response=<span className={"badge p-1 bg-primary rounded-0"}>PT</span>;
            break;
        case 2:
            response=<span className={"badge p-1 bg-primary rounded-0"}>MB</span>;
            break;
        case 3:
            response=<span className={"badge p-1 bg-warning rounded-0"}>LH</span>;
            break;
        case 4:
            response=<span className={"badge p-1 bg-primary rounded-0"}>CD</span>;
            break;
    }

    return response;
}


export function is_new_contract_in_month(data=[],time_day=null,time_month=null){
    var response=false;
    var current_date=new Date();

    if(time_month===null){
        time_month=ConvertStringToDate("01/"+(current_date.getMonth()+1)+"/"+current_date.getFullYear());
    }
    if(time_day===null){
        time_day=Math.ceil(current_date.getTime()/1000);
    }

    if(data.length>0){

        if(data.time_create>=time_month && time_day>=time_month){
            response=true;
        }

    }

    return response;
}

export function get_name_type_card(type=null){

    var response='___';

    switch (type) {

        case 1:
            response='Thẻ PT';
            break;

        case 2:
            response='Thẻ Member';
            break;

        case 3:
            response='Thẻ nhóm LH';
            break;
        case 4:
            response='Thẻ nhóm CD';
            break;
    }

    return response;

}
