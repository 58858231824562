/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    base64_encode,
    array_to_select,
    getItemInArray,
    filter_args,
    get_data_in_args,
    get_name_day,
    _isset_array,
    convertArrayToSingle, _isset, defaultValueObject, ArrayInArray, get_id_in_object, formatNumber, between_two_dates
} from "../../helper/array";
import lodash from "lodash";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import Select, { components } from 'react-select';
import ModalUser from "../user/ModalUser";
import { confirmAlert } from 'react-confirm-alert';
import ConstApp from "../../ConstApp";
const INIT_STATE={
    detailContract:null,
    data:{
        _id:null,
        number_card:0,
        id_requires:[],
        id_subject:[],
        group_member_statics:null,
        group_member:null,
        id_card:null,
        id_user_sub:null,
        start_day:Math.ceil(new Date().getTime()/1000),
        end_day_contract:Math.ceil(new Date().getTime()/1000),
        end_day_stop:Math.ceil(new Date().getTime()/1000),
        id_admin_pt:null,
        source_contract:null,
        note_member:null,
        number_did_pt:null,
        number_coffer:null
    },
    id_address_move:null,
    DetailSubUser:null,
    page:0,
    AllRequired:null,
    Card:null,
    Group:null,
    AllSource:null,
    AllSubject:null,
    DetailCard:null,
    ListAllCard:null,
    GroupStatics:null,
    GroupMember:null,
    Loadding:true,
    InfoUser:{
        status:false,
        data:{}
    },
    ListAdmin:null,
    AllAddress:null,
    PriceTransfer:null,
    Page:1,
    TempNumberCard:0
};

export default class ChangeAddress extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
        this.RefComponent=React.createRef();
        this.AllTypeCard=[
            {
                label:"Tất cả",
                value:null
            },
            {
                label:"HD PT",
                value:1
            },
            {
                label:"HD Member",
                value:2
            },
            {
                label:"HD lớp nhóm Linh Hoạt",
                value:3
            },
            {
                label:"HD lớp nhóm cố định",
                value:4
            }
        ];


    }


    componentDidMount() {

        this.StartEditCard();

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    async StartEditCard(){

        this.getRequired();

        this.getSource();

        this.getAddress();

        this.getPriceTransfer();

        if(typeof this.props.data!=='undefined'){
            this.UpdateDataMember(this.props.data);
        }

    }
    getPriceTransfer(){

        var Appthis=this.props.AppThis;

        const self=this;

        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('name', 'fee_change');
        fetch(ConstApp.home_api+"options", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data['status']==='ok'){
                self.setState({
                    PriceTransfer:parseInt(data.data)
                });
            }
        });
    }

    UpdateDataMember(data){
        var update_data=this.state.data;

        var detailContract=lodash.cloneDeep(data);

        update_data['_id']=data['_id'];

        update_data=get_id_in_object(update_data,['source_contract','group_member'],data);

        ['id_admin_pt','group_member_statics','id_card'].map(function (item){
                update_data[item]=null;
        });

        update_data['id_subject']=[];

        ['id_user_sub','id_requires'].map(function(item){
            if(_isset_array(data[item])){
                update_data[item]=convertArrayToSingle(data[item]);
            }
        });

        ['start_day','end_day_contract'].map(function (item){
            if (typeof data[item]!=='undefined'){
                update_data[item]=parseInt(data[item]);
            }
        });

        ['number_did_pt','end_day_stop'].map(function (item){
            if (typeof data['info_contract_data'][item]!=='undefined'){
                update_data[item]=parseInt(data['info_contract_data'][item]);
            }
        });

        update_data['note_member']=data['note_member'];

        update_data['number_card']=0;

        var DetailSubUser=data.id_user_sub;

        this.setState({
            data:update_data,
            DetailSubUser:DetailSubUser,
            detailContract:detailContract
        });

    }


    getRequired(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"requires", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    AllRequired:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getSource(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type',2);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"source", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    AllSource:data.data
                });

            }


        }).catch((e)=>{

        });

    }

    async getAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        var data=await fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });
        data=await data.json();

        if(data.status==='ok'){

            this.setState({
                AllAddress:data.data,
                DetailCard:null
            },function(){

                if(self.RefComponent.current!==null && typeof self.RefComponent.current!=='undefined'){
                    self.RefComponent.current.clearValue();
                }

            });

        }


    }

    GetGroupStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address',this.state.id_address_move);

        fetch(Appthis.ConstApp.home_api+"groupstatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                this.setState({
                    GroupStatics:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    GetGroupMember(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address',this.state.id_address_move);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{


            if(data.status==='ok'){

                var GroupMember=[
                    {
                        _id:'none',
                        name:"Không"
                    }
                ];

                GroupMember=GroupMember.concat(data.data);

                self.setState({
                    GroupMember:GroupMember
                });

            }


        }).catch((e)=>{

        });


    }

    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address',this.state.id_address_move);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListAdmin:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', this.state.id_address_move);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    AllSubject:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    async getCard(type=null){

        const self=this;

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', this.state.id_address_move);

        if(type!==null){
            formData.append('type', type);
        }
        var data=await fetch(Appthis.ConstApp.home_api+"card", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });
        data=await data.json();

        if(data.status==='ok'){

            var data_update=self.state.data;

            data_update.id_card=null;

            this.setState({
                ListAllCard:data.data,
                DetailCard:null,
                data:data_update
            },function(){

                self.CalculatorNumberCard(self.state.data.number_card);

                if(self.RefComponent.current!==null && typeof self.RefComponent.current!=='undefined'){
                    self.RefComponent.current.clearValue();
                }

            });

        }


    }

    async fetchUser(phone){
        var Appthis=this.props.AppThis;

        const formData = new FormData();
        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/infouser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        data=await data.json();

        return data;

    }

    async UpdateSubUser(data){

        var number_error=0;

        ['username','birthday','address_contact','user_login'].map(function(item,index){
            if(data[item]===null || typeof data[item]==='undefined' || data[item].length<3){
                number_error+=1;
            }
        });


        if(number_error>0){
            toast("Số điện thoại không chính xác");
        }
        else{

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(data));

            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updateuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();


            if(get_data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{

                var get_data_user=await this.fetchUser(data.user_login);

                if(get_data_user.status==='ok' && Object.keys(get_data_user.data).length>0){

                    var DetailSubUser=[];

                    if(this.state.DetailSubUser!==null){
                        DetailSubUser=this.state.DetailSubUser;
                    }

                    DetailSubUser.push(get_data_user.data);

                    var get_current_data=this.state.data;

                    var id_user_sub=[];

                    if(get_current_data.id_user_sub!==null && get_current_data.id_user_sub.length>0){

                        id_user_sub=get_current_data.id_user_sub;
                    }

                    id_user_sub.push(get_data_user.data._id);

                    get_current_data['id_user_sub']=id_user_sub;

                    this.setState({
                        DetailSubUser:DetailSubUser,
                        data:get_current_data,
                        InfoUser:{
                            status:false,
                            data:{}
                        }
                    });

                }

            }

            Appthis.PageLoad(false);

        }

    }

    async DetailCard(id){

        if(id!==null){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', this.state.id_address_move);

            formData.append('id_card',id);
            const self=this;

            var get_data=await fetch(Appthis.ConstApp.home_api+"card/detail", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();

            if(get_data.status==='ok'){

                self.setState({
                    DetailCard:get_data.data
                },function(){

                    self.CalculatorNumberCard(self.state.data.number_card);

                });

            }

        }
    }
    CalculatorNumberCard(number){

        var data_update=this.state.data;

        var DetailCard=this.state.DetailCard;

        if(isNaN(number)===true || number===null){
            number=0;
        }
        else{
            number=parseInt(number)
        }

        var TempNumberCard=number;

        if(DetailCard!==null){

            if(DetailCard.type===3 || DetailCard.type===4){
                data_update['number_card']=number*DetailCard.number_day_in_month;
            }
            else{
                data_update['number_card']=number;
            }

            this.setState({
                data:data_update,
                TempNumberCard:TempNumberCard
            });
        }
    }

    OptionLayoutGroupMember(props,GroupStatics){
        const self=this;
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{get_data_item.name}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>

                    {get_data_item.id_user?(<>
                        <p className={"m-0"}>Đại diện : {get_data_item.id_user.username+" - "+get_data_item.id_user.code_user}</p>
                    </>):(<></>)}

                </components.Option>
            );
        }
        else{
            return(<></>);
        }
    }

    OptionLayout(props,GroupStatics){
        const self=this;
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{props.data.label}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>
                    <p className={"m-0"}>HLV : {get_data_item.id_admin_pt.username+" - "+get_data_item.id_admin_pt.code_user}</p>
                    <p className={"m-0"}>Ngày :
                        {Object.keys(get_data_item.days).length>0?(
                            Object.entries(get_data_item.days).map(function(item,index){
                                return(<span className={"m-1 badge font10 bg-black-50 p-1"}>{get_name_day(item[1])}</span>);
                            })
                        ):(<></>)}
                    </p>
                    <p className={"m-0"}>giờ : {get_data_item.time_start} - {get_data_item.time_end}</p>
                </components.Option>
            );
        }
        else{
            return(<></>);
        }

    }

    NumberPeople(){

        var DetailCard=this.state.DetailCard;

        const self=this;

        var DetailSubUser=this.state.DetailSubUser;

        if(this.state.detailContract.id_card.type!==5 && DetailCard.number_people>0){
            return(

                <div className={"row m-0"}>

                    <div className={"alert alert-primary mt-3 rounded-0"}>
                        <i className="fas fa-user-plus" />  Số người đi kèm tối đa : {DetailCard.number_people}
                    </div>

                    <div className="row m-0 border-bottom mb-2 bg-light pr-5">
                        <div className="col-12 text-uppercase font-weight-bold col-sm-4 p-2">
                            Họ và tên
                        </div>
                        <div className="col-12 text-uppercase font-weight-bold col-sm-4 p-2">
                            SDT/ID
                        </div>
                        <div className="col-12 text-uppercase font-weight-bold col-sm-4 p-2">
                            Mã số thẻ
                        </div>
                    </div>

                    {DetailSubUser!==null?(
                        DetailSubUser.map(function(item,index){
                            return(
                                <div className={"row m-0 border-bottom pr-5"+((index+1)%2===0?" bg-light":"")}>
                                    <div className="col-12 col-sm-4 p-2">
                                        {item.username}
                                    </div>
                                    <div className="col-12 col-sm-4 p-2">
                                        {item.user_login}
                                    </div>
                                    <div className="col-12 col-sm-4 p-2 position-relative">
                                        {item.card_user}

                                        <a className={"text-danger font12 mt-1 ms-3 position-absolute right-0 top-0"} onClick={()=>{

                                            var update_data=self.state.data;

                                            var id_user_sub=update_data.id_user_sub;

                                            delete id_user_sub[index];

                                            update_data.id_user_sub=filter_args(id_user_sub);

                                            var UpdateDetailSubUser=self.state.DetailSubUser;

                                            delete UpdateDetailSubUser[index];

                                            self.setState({
                                                data:update_data,
                                                DetailSubUser:filter_args(UpdateDetailSubUser)
                                            });

                                        }}>
                                            <i className="far font18 fa-trash-alt"></i>
                                        </a>
                                    </div>
                                </div>
                            );
                        })
                    ):(
                        <div className={"alert alert-danger"}>
                            <i className="fas fa-times-circle"/> Không có dữ liệu
                        </div>
                    )}

                    {this.state.DetailSubUser===null || this.state.DetailSubUser.length<DetailCard.number_people?(
                        <div className={"col-12 p-0 mt-2 mb-4"}>
                            <a onClick={()=>{
                                this.setState({
                                    InfoUser:{
                                        status:true,
                                        data:{}
                                    }
                                });
                            }} className={"btn btn-danger ps-3 pe-3 rounded-0"}>
                                <i className="far fa-plus-square me-2" /> Thêm mới
                            </a>
                        </div>
                    ):(
                        <></>
                    )}


                </div>
            );
        }
        else{
            return(<></>);
        }
    }

    RenderActionCard(){

        var DetailCard=this.state.DetailCard;

        const self=this;

        var type_card=DetailCard['type'];

        switch (type_card){
            case 1:
                return(
                    <div className={"row"}>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-3"}>
                            <p className={"mb-1"}>Số buổi tập <span className={"text-red"}>( * )</span></p>

                            <div className={"col-12 d-flex align-content-start position-relative"}>
                                <input onChange={(event)=>{

                                    if(isNaN(event.target.value)===true){
                                        self.CalculatorNumberCard(0);
                                    }
                                    else{
                                        self.CalculatorNumberCard(event.target.value);
                                    }

                                }} type={"number"} defaultValue={self.state.TempNumberCard} placeholder={"số buổi"} className={"form-control w-100 rounded-0"} />

                            </div>
                        </div>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-3"}>
                                <p className={"mb-1"}>Huấn Luyện viên <span className={"text-red"}>( * )</span></p>

                                {self.state.ListAdmin!==null?(
                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                        var data=self.state.data;
                                        data.id_admin_pt=selectedOption.value;
                                        self.setState({
                                            data:data
                                        });
                                    }} value={defaultValueObject(getItemInArray(this.state.data.id_admin_pt,"key",this.state.ListAdmin),"value","key")} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                ):(
                                    <ItemLoading />
                                )}

                            </div>


                        <div className={"col-12"}>
                            <div className={"alert bg-danger text-white"}>
                                Số buổi tập tối thiểu : {DetailCard.min_session}
                            </div>
                        </div>

                    </div>
                )
            case 3:
            case 4:
                return(
                    <div className={"row"}>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-3"}>
                            <p className={"mb-1"}>Số tháng tập <span className={"text-red"}>( * )</span></p>

                            <div className={"col-12 d-flex align-content-start position-relative"}>
                                <input type={"number"} onChange={(event)=>{
                                    self.CalculatorNumberCard(event.target.value);
                                }} value={self.state.TempNumberCard} placeholder={"số tháng"} className={"form-control w-100 rounded-0"} />

                            </div>

                        </div>


                        {type_card===4?(
                            <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                <p className={"mb-1"}>Lớp nhóm cố định <span className={"text-red"}>( * )</span></p>

                                {self.state.GroupStatics!==null?(
                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                        var data=self.state.data;
                                        data.group_member_statics=selectedOption.value;
                                        self.setState({
                                            data:data
                                        });
                                    }} value={defaultValueObject(getItemInArray(this.state.data.group_member_statics,"_id",this.state.GroupStatics),"name","_id")} options={array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                        Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                    }} />

                                ):(
                                    <ItemLoading />
                                )}

                            </div>
                        ):(<></>)}



                        <div className={"col-12"}>
                            <div className={"w-100 alert bg-danger text-white"}>
                                Số tháng tối thiểu : {DetailCard.min_session} tháng
                            </div>

                        </div>

                    </div>
                )
        }
    }

    async SaveContract() {

        var GetData = this.state.data;

        var Appthis = this.props.AppThis;

        var number_error = 0;

        var DetailCard = this.state.DetailCard;

        if(DetailCard!==null){
            switch (DetailCard.type) {
                case 1:

                    if (GetData['id_admin_pt'] === null) {
                        number_error += 1;
                    }
                    break;

                case 2:
                case 5:
                    var number_card_update=Math.ceil(between_two_dates(new Date(GetData.start_day*1000),new Date(GetData.end_day_contract*1000))/86400);
                    GetData['number_card']=number_card_update;

                    break;
                case 4:
                    if (GetData['group_member_statics'] === null) {
                        number_error += 1;

                        break;
                    }
            }
        }
        else{
            number_error += 1;
        }


        var detailContract=this.state.detailContract;

        if(detailContract.id_card.type!==5){

            ['id_subject', 'id_requires'].map(function (item, index) {
                if (GetData[item].length <= 0 || GetData[item].length <= 0) {
                    number_error += 1;
                }
            });

            ['source_contract'].map(function (item, index) {
                if (GetData[item] === null || typeof GetData[item] === 'undefined' || GetData[item].length < 5) {
                    number_error += 1;
                }
            });

        }
        else{
            ['number_coffer'].map(function (item, index) {
                if (GetData[item] === null || typeof GetData[item] === 'undefined' || GetData[item].length <=0) {
                    number_error += 1;
                }
            });

        }

        //


        ['id_card','end_day_contract','start_day'].map(function (item, index) {
            if (GetData[item] === null || typeof GetData[item] === 'undefined' || GetData[item].length < 5) {
                number_error += 1;
            }
        });

        ['number_card','number_did_pt'].map(function (item, index) {
            if (GetData[item] === null || typeof GetData[item] === 'undefined' || isNaN(GetData[item])===true || GetData[item]<0) {
                number_error += 1;
            }
            else{

                if(item==='number_card'){
                    if(GetData[item]<=0){
                        number_error += 1;
                    }
                }
            }


        });

        if (number_error > 0) {
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else {

            var update_data={};

            Object.entries(GetData).map(function(item){

                var [key,value]=item;

                if(value!==null){
                    update_data[key]=value;
                }
            });


            Appthis.PageLoad(true);

            var base64_data = base64_encode(update_data);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_address_move', this.state.id_address_move);

            formData.append('data', base64_data);

            var fetch_data = await fetch(Appthis.ConstApp.home_api + this.props.path + "/move", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            fetch_data = await fetch_data.json();

            if (fetch_data.status === 'ok') {
                toast("Cập nhật hợp đồng thành công.Vui lòng chờ xác nhận của quản lý");

            } else {
                toast("Cập nhật hợp đồng không thành công vui lòng kiểm tra lại");
            }

            Appthis.PageLoad(false);

            if(typeof this.props.onSuccess!=='undefined'){
                this.props.onSuccess();
            }


        }

    }

    RenderView(){

        const self=this;

        if(this.state.Page===1){


            return(
                <div className={"col-12"}>

                    <h2 className="d-inline-block mb-4 font18 mt-3 font-weight-bold text-uppercase text-dark border-primary border-bottom pb-2">
                        Bước 1 : Chọn cơ sở
                    </h2>

                    <div className={"row mb-5"}>
                        <div className="col-12 col-sm-12 col-md-12 offset-lg-4 col-lg-4 customCssSelect">
                                <p className={"mb-2"}>Cơ sở <span className={"text-red"}>( * )</span></p>
                            <div className={"alert alert-info"}>
                                <i className="fas fa-exclamation-triangle me-2" /> Vui lòng chọn cơ sở muốn chuyển
                            </div>

                                {this.state.AllAddress!==null?(
                                    <>
                                        <Select menuPosition={"bottom"} menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{
                                            if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                var data_update=self.state.data;
                                                data_update.group_member_statics=null;
                                                data_update.id_user_sub = null;
                                                data_update.id_admin_pt = null;
                                                data_update.number_card = 0;
                                                self.setState({
                                                    data:data_update,
                                                    DetailCard:null,
                                                    id_address_move:selectedOption.value
                                                });
                                            }
                                        }} options={array_to_select(this.state.AllAddress, "name","_id")} />

                                        <a href={"#"} onClick={()=>{
                                            self.setState({
                                                Page:2
                                            });

                                            if(self.state.detailContract.id_card.type!==5){
                                                self.getCard();
                                            }
                                            else{
                                                self.getCard(5);
                                            }
                                            self.getSubject();
                                            self.getAdmin();
                                            self.GetGroupStatics();
                                            self.GetGroupMember();

                                        }} className={"btn btn-primary rounded-0 ps-4 pe-4 pt-2 pb-2 w-100 mt-3"}>
                                            <i className="fas fa-check-circle me-2" />
                                            Tiếp theo</a>
                                    </>

                                ):(
                                    <div className={"col-12 text-center"}>
                                        <ItemLoading />
                                    </div>
                                )}

                        </div>
                    </div>


                </div>
            );


        }
        else{

            return(
                <div className={"col-12"}>

                    <h2 className="d-inline-block mb-4 font18 mt-3 font-weight-bold text-uppercase text-dark border-primary border-bottom pb-2">
                        Bước 2 : Chọn dịch vụ
                    </h2>

                    <div className={"row"}>

                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 pb-2 customCssSelect"}>

                            <div className={"row"}>


                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-3"}>

                                    {this.state.detailContract.id_card.type!==5?(
                                        <>

                                            <p className={"mb-1"}>Loại HD</p>
                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                var data=this.state.data;
                                                data.id_card=null;
                                                this.setState({
                                                    ListAllCard:null,
                                                    data:data
                                                },function(){
                                                    self.getCard(selectedOption.value);
                                                });
                                            }} options={this.AllTypeCard} />




                                            <div className={"row"}>

                                                <div className={"col-12 col-sm-12 col-md-6 mt-3"}>
                                                    <p className={"mb-1"}>Bộ môn <span className={"text-red"}>( * )</span></p>

                                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                                        var get_data=self.state.data;
                                                        get_data['id_subject']=get_data_in_args(selectedOption,"value");
                                                        self.setState({
                                                            data:get_data,
                                                        });
                                                    }} isMulti={true} value={this.state.AllSubject!==null?array_to_select(ArrayInArray(this.state.data['id_subject'],this.state.AllSubject,"_id"),"name","_id"):null} options={array_to_select(this.state.AllSubject,'name','_id')} />


                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-6 mt-3"}>
                                                    <p className={"mb-1"}>Mục tiêu tập luyện <span className={"text-red"}>( * )</span></p>

                                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                                        var get_data=self.state.data;
                                                        get_data.id_requires=get_data_in_args(selectedOption,"value");
                                                        self.setState({
                                                            data:get_data
                                                        });
                                                    }} isMulti={true} value={this.state.AllRequired!==null?array_to_select(ArrayInArray(this.state.data['id_requires'],this.state.AllRequired,"_id"),"name","_id"):null} options={array_to_select(this.state.AllRequired,'name','_id')} />


                                                </div>

                                            </div>

                                        </>
                                    ):(
                                        <></>
                                    )}

                                    <div className={"row box_input"}>

                                        <div className={"col-12 col-sm-12 col-md-6 mt-3"}>

                                            <p className={"mb-1"}>Ngày bắt đầu <span className={"text-red"}>( * )</span></p>

                                            <DatePicker dateFormat="dd/MM/yyyy" selected={new Date(this.state.data.start_day*1000)} onChange={(date) => {
                                                if(date!==null){
                                                    var data=self.state.data;
                                                    data.start_day=Math.ceil(date.getTime()/1000);
                                                    self.setState({
                                                        data:data
                                                    });
                                                }

                                            }} placeholderText={"Ngày bắt đầu"} />
                                        </div>
                                        <div className={"col-12 col-sm-12 col-md-6 mt-3"}>
                                            <p className={"mb-1"}>Ngày kết thúc <span className={"text-red"}>( * )</span></p>

                                            <DatePicker  dateFormat="dd/MM/yyyy" selected={new Date(this.state.data.end_day_contract*1000)} onChange={(date) => {
                                                if(date!==null){
                                                    var data=self.state.data;
                                                    data.end_day_contract=Math.ceil(date.getTime()/1000);
                                                    self.setState({
                                                        data:data
                                                    });
                                                }

                                            }} placeholderText={"Ngày kết thúc"} />
                                        </div>





                                    </div>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-6 box_input mt-3"}>
                                            <p className={"mb-1"}>Ngày tạm dừng HD <span className={"text-red"}>( * )</span></p>

                                            <DatePicker  dateFormat="dd/MM/yyyy" selected={new Date(this.state.data.end_day_stop*1000)} onChange={(date) => {
                                                if(date!==null){
                                                    var data=self.state.data;
                                                    data.end_day_stop=Math.ceil(date.getTime()/1000);
                                                    self.setState({
                                                        data:data
                                                    });
                                                }

                                            }} placeholderText={"Ngày tạm dừng hD"} />

                                        </div>



                                        {this.state.detailContract.id_card.type!==5?(
                                            <div className={"col-12 col-sm-12 col-md-6 customCssSelect mt-3"}>
                                                <p className={"mb-1"}>Nguồn HD <span className={"text-red"}>( * )</span></p>

                                                <Select isSearchable={true} onChange={(selectedOption)=>{

                                                    if(selectedOption!==null && typeof selectedOption.value!=='undefined'){
                                                        var data=this.state.data;
                                                        data['source_contract']=selectedOption.value;
                                                        this.setState({
                                                            data:data
                                                        });
                                                    }
                                                }} value={this.state.AllSource!==null?defaultValueObject(getItemInArray(this.state.data.source_contract,"_id",this.state.AllSource),"name","_id"):null} options={this.state.AllSource!==null?array_to_select(this.state.AllSource,'name','_id'):null} />

                                            </div>

                                        ):(

                                            <div className={"col-12 col-sm-12 col-md-6 customCssSelect mt-3"}>
                                                <p className={"mb-1"}>Số tủ <span className={"text-red"}>( * )</span></p>

                                                <input onChange={(event)=>{
                                                    var data=this.state.data;
                                                    data.number_coffer=event.target.value;
                                                    this.setState({
                                                        data:data
                                                    });
                                                }} type={"text"} value={_isset(this.state.data.number_coffer)?this.state.data.number_coffer:""} className={"form-control w-100 rounded-0"} placeholder={"Số tủ"} />

                                            </div>

                                        )}


                                    </div>


                                    <div className={"col-12 col-sm-12 col-md-12 mt-3"}>
                                        <p className={"mb-1"}>Ghi chú gói tập </p>
                                        <input onChange={(event)=>{
                                            var data=this.state.data;
                                            data.note_member=event.target.value;
                                            this.setState({
                                                data:data
                                            });

                                        }} type={"text"} value={_isset(this.state.data.note_member)?this.state.data.note_member:""} className={"form-control w-100 rounded-0"} placeholder={"Ghi chú gói tập"} />

                                    </div>


                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-3 Box-Select-Card position-relative"}>

                                    <p className={"mb-1"}>Gói thẻ <span className={"text-red"}>( * )</span></p>

                                    {this.state.ListAllCard!==null?(
                                        <Select menuPosition={"bottom"} menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{
                                            if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                var data_update=self.state.data;
                                                data_update.id_card=selectedOption.value;
                                                data_update.group_member_statics=null;
                                                data_update.id_user_sub = null;
                                                data_update.id_admin_pt = null;
                                                data_update.number_card = 0;
                                                self.setState({
                                                    data:data_update,
                                                    DetailCard:null,
                                                    DetailSubUser:null
                                                },function(){
                                                    self.DetailCard(selectedOption.value);
                                                });
                                            }
                                        }} ref={this.RefComponent} value={this.state.DetailCard!==null?(defaultValueObject(this.state.DetailCard,"name","_id")):null} options={this.state.ListAllCard!==null?array_to_select(this.state.ListAllCard, "name","_id"):null} />
                                    ):(
                                        <div className={"col-12 pt-2"}>
                                            <ItemLoading />
                                        </div>
                                    )}


                                    {this.state.detailContract.id_card.type!==5 && this.state.DetailCard!==null && typeof this.state.DetailCard.note!=='undefined' && this.state.DetailCard.note.length>0?(<>
                                        <div className={"alert alert-info rounded-0 mt-3"}>
                                            <i className="fas fa-exclamation-triangle" /> {this.state.DetailCard.note}
                                        </div>
                                    </>):(<></>)}


                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 mt-3"}>

                                        {this.state.DetailCard!==null?(
                                            <div className={"col-12 p-0"}>
                                                {self.RenderActionCard()}
                                                {self.NumberPeople()}
                                            </div>
                                        ):(
                                            <></>
                                        )}

                                    </div>



                                    {this.state.detailContract.id_card.type!==5?(
                                        <>
                                            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 mt-3"}>
                                                <p className={"mb-1 mt-2"}>Nhóm HD doanh nghiệp</p>

                                                {self.state.GroupMember!==null?(
                                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                                        var data=self.state.data;
                                                        data.group_member=selectedOption.value;
                                                        self.setState({
                                                            data:data
                                                        });
                                                    }} value={this.state.data.group_member!==null?defaultValueObject(getItemInArray(this.state.data.group_member,"_id",this.state.GroupMember),"name","_id"):null} options={array_to_select(self.state.GroupMember,"name","_id")}
                                                            components={{
                                                                Option:(props)=>self.OptionLayoutGroupMember(props,self.state.GroupMember)
                                                            }}
                                                    />

                                                ):(
                                                    <div className={"col-12 pt-2 ps-2"}>
                                                        <ItemLoading />
                                                    </div>
                                                )}


                                            </div>

                                        </>
                                    ):(
                                        <></>
                                    )}





                                </div>
                            </div>




                        </div>



                    </div>

                    <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6"></div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <a onClick={()=>{

                                    confirmAlert({
                                        customUI: ({ onClose }) => {
                                            return (
                                                <div className='custom-ui'>
                                                    <h1>Cảnh báo !</h1>
                                                    <p>Bạn có chắc chắn muốn chuyển HD này ?</p>
                                                    <button onClick={()=>{
                                                        onClose();
                                                    }}>Không</button>
                                                    <button
                                                        onClick={() => {
                                                            self.SaveContract();
                                                            onClose();
                                                        }}
                                                    >Chắc chắn
                                                    </button>
                                                </div>
                                            );
                                        }
                                    });


                                }}
                                   className="font16 btn btn-primary rounded-0 w-100 btn-sm pt-3 pb-3">
                                    <i className="fas fa-angle-right me-2" /> Hoàn tất
                                </a>
                            </div>
                        </div>
                    </div>


                    <div className={"col-12 mt-3"} />


                </div>
            );

        }

    }

    render() {

        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg modal-fullscreen"}>
                    <div className="modal-content bg-light border-0">

                        <div className="modal-header shadow">

                            <div className={"container"}>

                                <div className={"row"}>
                                    <div className={"col-8"}>
                                        <strong className={"font18 text-white text-black text-uppercase"}>Chuyển HD liên Club</strong>
                                    </div>

                                    <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.onClose();
                                    }} className={"text-white right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                    </div>

                                </div>
                            </div>


                        </div>


                        <div className="modal-body">
                            <div className={"container bg-white pt-3 pb-3"}>
                                <div className={"col-12 mt-3 mb-5 wizard-template d-none d-lg-block"}>
                                    <ul className="m-0 p-0 position-relative nav nav-pills nav-fill">
                                        <li className="nav-item">
                                            <span className="font-weight-bold text-uppercase d-block w-100 font16">Bước 1</span>
                                            <i className="position-relative font26 mt-3 mb-3 bg-primary m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fab fa-ups" />
                                            <div className="col-12 p-0">
                                                <span className="description-wizard"> Chọn cơ sở </span>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <span
                                                className="font-weight-bold text-uppercase d-block w-100 font16">Bước 2</span>
                                            <i className="position-relative font26 mt-3 mb-3 bg-danger m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-user-check" />
                                            <div className="col-12 p-0">
                                                <span className="description-wizard"> Chọn thẻ tập </span>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 3</span>
                                            <i className="position-relative m-auto mt-3 mb-3 font26 bg-warning rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-info" />
                                            <div className="col-12 p-0">
                                                <span className="description-wizard"> Xác nhận từ quản lý</span>
                                            </div>
                                        </li>


                                    </ul>
                                </div>



                                <div className="col-12">
                                    <div className={"col-12 mt-4 mb-3"}>

                                        <div className={"alert alert-danger w-100 rounded-0 align-items-center"}>
                                            <i className="far fa-money-bill-alt me-1" /> Bạn đang thực hiện chuyển nhượng HD cho khách hàng.
                                            Lệ phí chuyển nhượng :
                                            {this.state.PriceTransfer!==null?(
                                                <span className={"ms-1 font-weight-bold text-uppercase font18"}>{formatNumber(this.state.PriceTransfer)} VND</span>
                                            ):(
                                                <div className={"ms-1 d-inline-block overflow-hidden"}> <ItemLoading /></div>
                                            )}
                                        </div>

                                    </div>


                                    {this.RenderView()}

                                    {this.state.InfoUser.status===true?(
                                        <ModalUser onClose={()=>{
                                            self.setState({
                                                InfoUser:{
                                                    status:false,
                                                    data:{}
                                                }});
                                        }} path={this.props.path} data={this.state.InfoUser.data} SubmitUser={(response)=>{
                                            self.UpdateSubUser(response);
                                        }} AppThis={this.props.AppThis} />
                                    ):(
                                        <></>
                                    )}

                                </div>

                            </div>
                        </div>



                    </div>
                </div>
           </div>
        );


    }


}
