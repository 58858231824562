/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {add_span_to_string, base64_encode, formatNumber, timeConverter,
} from "../../helper/array";
import EventCheckin from "../../global/checkin/EventCheckin";
import TryCheckin from "../../global/checkin/TryCheckin";
import {get_name_card, number_did_contract, status_contract, status_payment} from "../../helper/member";
import DetailContract from "../../global/contract/DetailContract";
import {confirmAlert} from "react-confirm-alert";
import Lottie from 'lottie-react-web';
import cardscanning from '../../../src/assets/file/credit-card-scanning.json';
import parse from "html-react-parser";
import Floater from 'react-floater';

const INIT_STATE={
    page:0,
    CheckByUser:{
        status:false,
        id_user:null
    },
    DataCheckin:null,
    SocketConnect:null,
    DataModal:{
        status:false
    },
    methodCheckin:null
};

export default class clubcheckinpt extends Component {
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    componentDidMount() {

        this.props.AppThis.RegisterSocket(()=>{
            this.listenSocket();
        });

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{

        switch (event.key) {
            case "F2":
                this.OpenModalTry();
                break;
            case "F3":
                this.setState({
                    CheckByUser:{
                        status:true,
                        id_user:null
                    }
                });

                break;

            case "Escape":

                this.setState({
                    DataModal:{
                        status:false
                    },
                    CheckByUser:{
                        status:false,
                        id_user:null
                    }
                });

                break;
        }

    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }



    UNSAFE_componentWillMount() {
        this.listenSocket();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }


    listenSocket(){
        var socket=this.props.AppThis.getSocketConnect();
        this.setState({
            SocketConnect:socket
        });
        const self=this;
        if(socket!==null){
            socket.onmessage=function (e){
                if (typeof e.data != "undefined") {
                    var get_data_socket=JSON.parse(e.data);
                    if(get_data_socket.status==='ok' && typeof get_data_socket.action!=='undefined' && typeof get_data_socket.method!=='undefined' && ['machine','phone'].indexOf(get_data_socket['method'])!==-1){

                        switch (get_data_socket.action) {

                            case "checkin":
                                self.setState({
                                    DataCheckin:get_data_socket.message_server,
                                    methodCheckin:get_data_socket.method
                                });
                                break;

                            case "checkout":
                                toast("CheckOut thành công");
                                break;

                            case "notfound":
                                toast("Không tìm thấy HD");
                                break;

                            case "error":
                                toast("Trạng thái số HD "+get_data_socket.list_contract[0].number_contract+ " - " +status_contract(get_data_socket.list_contract[0]));                                break;

                        }

                    }
                }
            }
        }
    }

    CheckinByUser(user=null){
        var number_error=0;
        if(user===null){
            number_error+=1;
        }
        else{
            if(user.length<5){
                number_error+=1;
            }
        }
        if(number_error>0){
            toast("Vui lòng kiểm tra lại");
        }
        else{
            var AppThis=this.props.AppThis;
            AppThis.PageLoad(true);
            const formData = new FormData();
            formData.append('token', AppThis.getToken());
            formData.append('id_address', AppThis.getAddress());
            formData.append('id_user', user);
            const self=this;
            fetch(AppThis.ConstApp.home_api+"clubcheckinpt/checkuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{
                if(data.status==='error'){
                    toast("Không tìm thấy thông tin số điện thoại");
                }
                AppThis.PageLoad(false);
                self.setState({
                    CheckByUser:{
                        status:false,
                        id_user:null
                    }
                });
            }).catch((e)=>{
                toast("Lấy dữ liệu thất bại");
                AppThis.PageLoad(false);
            });
        }
    }
    BoxCheckinByUser(){

        var get_user=this.state.CheckByUser.id_user;

        this.CheckinByUser(get_user);

    }
    render(){

        const self=this;

        
            return(

                <div className={"col-12 position-relative"}>
                    <div className={"col-12 position-relative"} >
                        <div className={"container-fluid container-main-template mb-5"}>

                            <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                                <div className="row m-0">

                                    <div className="col-12 col-sm-12 col-md-12 d-flex align-items-center mb-4">
                                        <h3 className="font18 mt-3 mb-0 text-uppercase font-weight-bold font-weight-bold"> CheckIn PT</h3>
                                    </div>


                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 m-auto">

                                    <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6">
                                     <a onClick={()=>{
                                                    this.OpenModalTry();
                                                
                                                }} className={"btn font13 btn-warning w-100 p-3 rounded-0 me-1"}>
                                                    <i className="fas fa-dumbbell me-2" /> KH Tập thử
                                                </a>
                                    </div>

                                     <div className="col-12 col-sm-6 col-md-6">
                                       <a onClick={()=>{
                                                    this.setState({
                                                        CheckByUser:{
                                                            status:true,
                                                            id_user:null
                                                        }
                                                    });
                                                }} className={"btn font13 btn-outline-primary w-100 p-3 rounded-0"}>
                                                    <i className="fas fa-mobile-alt me-2" /> Checkin SDT/Mã KH
                                                </a>

                                     </div>


                            
                                    </div>

                              


                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>



                    {this.state.CheckByUser.status===true?(
                        <div className="modal d-block position-fixed">
                            <div className={"modal-dialog modal-md"}>
                                <div className="modal-content">
                                    <div className="modal-header bg-dark">
                                        <h5 className="modal-title font16 text-uppercase">Checkin SDT/ID</h5>
                                    </div>
                                    <div className="modal-body InputMask">

                                        <div className={"alert alert-warning rounded-0 w-100"}>
                                            <i className="fas fa-exclamation-triangle me-2" />
                                            Vui lòng nhập SDT/ID
                                        </div>

                                        <input type={"text"} onChange={(event)=>{
                                            var CheckByUser=this.state.CheckByUser;
                                            CheckByUser['id_user']=event.target.value;
                                            this.setState({
                                                CheckByUser:CheckByUser
                                            });
                                        }} placeholder={"Số ĐTMã KH"} className={"form-control w-100 rounded-0"} />

                                        <div className={"row mt-3"}>

                                            <div className={"col-12 col-sm-12 col-md-6"}>

                                                <button type="button" onClick={()=>{
                                                    this.BoxCheckinByUser();
                                                }} className="btn rounded-0 w-100 btn-primary">
                                                    Checkin
                                                </button>
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-6"}>

                                                <button onClick={()=>{
                                                    this.setState({
                                                        CheckByUser:{
                                                            status:false,
                                                            id_user:null
                                                        }
                                                    });

                                                }} type="button" className="btn rounded-0 w-100 btn-secondary">
                                                    Huỷ
                                                </button>
                                            </div>

                                        </div>

                                    </div>



                                </div>
                            </div>
                            <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                        </div>
                    ):(<></>)}


                    {this.state.DataCheckin!==null?(
                        <EventCheckin path={"clubcheckinpt"} AppThis={this.props.AppThis} data={this.state.DataCheckin} CloseModal={()=>{
                            this.setState({
                                DataCheckin:null
                            });

                        }} method={this.state.methodCheckin} />
                    ):(<></>)}

                    {this.state.DataModal.status===true?(
                        <TryCheckin path={"clubcheckinpt"} SuccessModal={()=>{
                            self.setState({
                                DataModal:{
                                    status:false
                                }});

                        }} data={this.state.DataModal.data} CloseModal={()=>{
                            self.setState({
                                DataModal:{
                                    status:false
                                }});
                        }} AppThis={this.props.AppThis} />
                    ):(
                        <></>
                    )}


                </div>
            );
       

    }

    OpenModalTry(){

        this.setState({
            DataModal:{
                status:true
            }
        });
    }
}
