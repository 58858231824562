/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    _isset,
    formatNumber, base64_encode, input_price, remove_input_price, _isset_array,
} from "../../helper/array";
import ConstApp from "../../ConstApp";
import {toast} from "react-toastify";
import Fancybox from "../Fancybox";
import {confirmAlert} from "react-confirm-alert";
import DetailWallet from "./DetailWallet";
import {get_name_type_receipts, type_wallet} from "../../helper/member";
import DetailContract from "../contract/DetailContract";

export default class InfoPayTransaction extends Component{
    constructor(props) {
        super(props);

        this.state={
            ActiveTab:1,
            InfoPay:null,
            price:null,
            description:null,
            loading:false,
            DataDetailContract:null,
            detailPercentWallet:null
        };

    }


    detailPertcentContract(id){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_wallet', id);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/percentwallet", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    detailPercentWallet:data.data
                });

            };


        }).catch((e)=>{
        });


    }

    componentDidMount() {

        if(_isset(this.props.data)){

            var get_data_props=this.props.data;


            const self=this;

            this.setState({
                InfoPay:get_data_props,
                price:typeof get_data_props.price!=='undefined'?get_data_props.price:0
            },function(){
                self.detailPertcentContract(get_data_props._id);
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.OnClose!=='undefined'){
                this.props.OnClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }
    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }


    render() {
        const self=this;

        if(this.state.InfoPay!==null){
            var data=this.state.InfoPay;

            if(this.state.DataDetailContract){
                return(
                    <DetailContract OnClose={()=>{
                        this.setState({
                            DataDetailContract:null
                        });

                    }} EventDetail={(id)=>{
                        this.setState({
                            DataDetailContract:null
                        },function(){
                            self.getDetailContract(id);
                        });
                    }} data={this.state.DataDetailContract} />
                );
            }
            else{
                return(
                    <div className="modal d-block position-fixed">
                        <div className={"modal-dialog modal-dialog-scrollable modal-lg container-fluid"}>
                            <div className="modal-content shadow border-0 mw800px m-auto">
                                <div className="modal-header">
                                    <div className={"container"}>

                                        <div className={"row"}>

                                            <div className={"col-8 p-0"}>
                                                <strong className={"font18 text-white text-black text-black text-uppercase"}>Cập nhật phiếu thu</strong>
                                            </div>

                                            <div className={"col-4 p-0 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.OnClose();
                                    }} className={"text-white right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-body">


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-12"}>
                                            <ul className={"nav-theme nav nav-tabs mt-3 mb-3 font13"}>
                                                <li className={"nav-item"}>
                                                    <a onClick={()=>{
                                                        this.setState({
                                                            ActiveTab:1
                                                        });
                                                    }}  className={this.state.ActiveTab===1?("nav-link active"):("nav-link")}>
                                                        Xác nhận giao dịch
                                                    </a>
                                                </li>

                                                <li className={"nav-item"}>
                                                    <a onClick={()=>{
                                                        this.setState({
                                                            ActiveTab:2
                                                        });
                                                    }}  className={this.state.ActiveTab===2?("nav-link active"):("nav-link")}>
                                                        Chi tiết giao dịch
                                                    </a>
                                                </li>

                                            </ul>

                                            <div className={"col-12 mt-4"}>

                                                {this.state.ActiveTab===1?(
                                                    <div className={"col-12"}>

                                                        {data.id_member!==null && typeof data.id_member_source!=='undefined' && data.id_member_source.length>0?(
                                                            <>
                                                                <div className={"col-12 p-2 alert-warning rounded-0 alert m-0 mb-3"}>
                                                                    Hợp đồng <strong>
                                                                    {data.id_member.number_contract}
                                                                </strong> {type_wallet(data)} từ HD
                                                                    {data.id_member_source.map(function(v){
                                                                        return(
                                                                            <span onClick={()=>{
                                                                                self.getDetailContract(v._id);
                                                                            }} className={"ms-1 me-1 badge font12 bg-primary font-weight-bold"}>{v.number_contract}</span>
                                                                        );
                                                                    })}

                                                                </div>


                                                            </>
                                                        ):(
                                                            <>
                                                            </>
                                                        )}

                                                        <div className="row m-0 p-2">
                                                            <div className="col-6 col-sm-4 col-md-4 align-items-center d-flex">
                                                                <i className="fas fa-sort-numeric-up me-2"></i>
                                                                <span className={'font-weight-bold'}>Số HD</span></div>
                                                            <div className="col-6 col-sm-8 col-md-8">
                                                                <strong>
                                                                    {data.id_member && data.id_member.number_contract?<>
                                                                        <a href={"#"} className={"text-primary"} onClick={()=>{
                                                                            this.setState({
                                                                                DataDetailContract:data.id_member
                                                                            });
                                                                        }}>
                                                                            {data.id_member.number_contract}
                                                                        </a>
                                                                    </>:(
                                                                        <> Chưa rõ</>
                                                                    )}
                                                                </strong>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 p-2 bg-light">
                                                            <div className="col-6 col-sm-4 col-md-4 p-0">
                                                                <i className="fas fa-tag me-1 text-red"></i> Hình thức thu
                                                            </div>
                                                            <div className="col-6 col-sm-8 col-md-8 p-0">
                                                                <strong> {type_wallet(data)}</strong>
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 p-2">
                                                            <div className="col-6 col-sm-4 col-md-4 align-items-center p-0 d-flex">
                                                                <i className="fas fa-tag me-1 text-red"></i> Loại phiếu
                                                            </div>
                                                            <div className="col-6 col-sm-8 col-md-8 p-0">
                                                                <strong> {get_name_type_receipts(data.type_receipts)}</strong>
                                                            </div>
                                                        </div>


                                                        <div className="row m-0 p-2 bg-light">
                                                            <div className="col-6 col-sm-4 col-md-4 p-0 align-items-center d-flex">
                                                                <i className="fas fa-battery-three-quarters me-2" />
                                                                <span>Trạng thái</span></div>
                                                            <div className="col-6 col-sm-8 col-md-8 p-0">

                                                                {data.status===0?(
                                                                    <span className={"badge bg-warning font12"}>Đang chờ</span>
                                                                ):(
                                                                    data.status===1?(
                                                                        <span className={"badge bg-primary font12"}>Đã xác nhận</span>
                                                                    ):(
                                                                        <span className={"badge bg-danger font12"}>Đã từ chối</span>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 p-2">
                                                            <div className="col-6 col-sm-4 col-md-4 align-items-center p-0 d-flex">
                                                                <i className="far fa-image me-2" />
                                                                <span className={"font-weight-bold"}>Hình ảnh</span></div>
                                                            <div className="col-6 col-sm-8 col-md-8 p-0">

                                                                {data.file_confirmation!==null?(
                                                                    <Fancybox>
                                                                        <a className={"font-weight-bold font13"} data-fancybox="gallery" href={ConstApp.home_url+data.file_confirmation}>
                                                                            <i className="far fa-image me-2" />
                                                                            Xem hình ảnh</a>
                                                                    </Fancybox>
                                                                ):(
                                                                    <span className="badge font13 bg-danger">
                                                                    Chưa rõ</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="row m-0 p-2 bg-light">
                                                            <div className="col-6 col-sm-4 col-md-4 align-items-center d-flex p-0">
                                                                <i className="far fa-image me-2" />
                                                                <span className={"font-weight-bold"}>Số tiền</span></div>
                                                            <div className="col-6 col-sm-8 col-md-8 p-0">

                                                                <strong className={"font-weight-bold text-red font16"}>
                                                                    <i className="far fa-money-bill-alt me-2" />
                                                                    <span>{formatNumber(data.price)}</span> VND</strong>

                                                            </div>
                                                        </div>

                                                        <div className="row m-0 p-2">
                                                            <div className="col-12 col-sm-4 col-md-4 align-items-center d-flex p-0">
                                                                <i className="fas fa-exclamation-triangle" />
                                                                <span className={"font-weight-bold"}>Ghi chú</span></div>
                                                            <div className="col-12 col-sm-8 col-md-8 p-0">

                                                                <span className={"font14"}>
                                                                   {data.note}</span>

                                                            </div>
                                                        </div>

                                                        {this.state.detailPercentWallet!==null?(
                                                            <>
                                                                {typeof this.state.detailPercentWallet.data!=='undefined' && this.state.detailPercentWallet['data'].length>0?(
                                                                    <>
                                                                            <div className={"alert alert-primary rounded-0 mt-3 mb-4"}>
                                                                                <i className="fas fa-exclamation-triangle" /> Chi tiết % chia phiếu thu
                                                                            </div>

                                                                            <div className={"col-12 overflow-auto mb-5"}>

                                                                                <table className={"mt-2 w-100 table border-0 mw800 table-style-old"}>

                                                                                    <thead>

                                                                                    <tr className={"border-bottom bg-light"}>

                                                                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                            <i className="fas fa-list-ol me-1" /> Cơ sở
                                                                                        </td>
                                                                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                            <i className="fas fa-list-ol me-1" /> Nhân viên
                                                                                        </td>
                                                                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                            <i className="fas fa-list-ol me-1" /> SDT
                                                                                        </td>
                                                                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                                                                            <i className="fas fa-list-ol me-1" /> Tỷ lệ %
                                                                                        </td>

                                                                                    </tr>
                                                                                    </thead>

                                                                                    <tbody>

                                                                                    {
                                                                                        self.state.detailPercentWallet['data'].map(function(item,index){
                                                                                            return(
                                                                                                <tr>
                                                                                                    <td>

                                                                                                        {item.id_admin.address_id.map(function(name){
                                                                                                            return(
                                                                                                                <span className={"badge bg-dark rounded-0 m-1"}>{name}</span>
                                                                                                            );
                                                                                                        })}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.id_admin.username+" - "+item.id_admin.code_user}
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        {item.id_admin.phone_number}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.id_percent.name}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            );
                                                                                        })
                                                                                    }

                                                                                    </tbody>


                                                                                </table>

                                                                            </div>

                                                                        </>
                                                                ):(
                                                                    <div className={"alert alert-danger rounded-0"}>
                                                                        Không có dữ liệu % chia
                                                                    </div>
                                                                )}
                                                            </>
                                                        ):(
                                                            <></>
                                                        )}


                                                        {this.state.loading===true?(
                                                            <div className={"text-center mt-3 mb-3 d-flex flex-row justify-content-center"}>
                                                                <ItemLoading size={"Large"}  />
                                                            </div>
                                                        ):(
                                                            <>
                                                                {data.status===0?(
                                                                    <div className={"col-12"}>

                                                                        {data.type===2 || data.type===5?(
                                                                            <div className="row m-0 p-2 mt-2">
                                                                                <div className="col-4 col-sm-4 col-md-4 align-items-center d-flex">
                                                                                    <i className="fas fa-hand-holding-usd me-2" />
                                                                                    <span className={"font-weight-bold"}>Nhập Số tiền hoàn trả</span>
                                                                                    <span className={"text-red ms-2"}>( * )</span>
                                                                                </div>
                                                                                <div className="col-8 col-sm-8 col-md-8">
                                                                                    {data.type===5?(
                                                                                        <>
                                                                                            <p className={"mb-1 text-red"}>Vui lòng nhập số tiền còn lại của HD cũ</p>
                                                                                            <input type={"text"} onChange={(event)=>{
                                                                                                try{
                                                                                                    var get_price=remove_input_price(event.target.value);
                                                                                                    self.setState({
                                                                                                        price:get_price
                                                                                                    });
                                                                                                }
                                                                                                catch (e){
                                                                                                }
                                                                                            }} placeholder={"Nhập số tiền còn lại của HD cũ"} value={input_price(this.state.price)} defaultValue={input_price(this.state.price)} className={"form-control w-100 rounded-0"} />
                                                                                        </>

                                                                                    ):(
                                                                                        <>
                                                                                            <p className={"mb-1 text-red"}>Vui lòng nhập số tiền Refund cho HD cũ</p>
                                                                                            <input type={"text"} onChange={(event)=>{
                                                                                                try{
                                                                                                    var get_price=remove_input_price(event.target.value);
                                                                                                    self.setState({
                                                                                                        price:get_price
                                                                                                    });
                                                                                                }
                                                                                                catch (e){

                                                                                                }

                                                                                            }} placeholder={"Nhập số tiền Refund cho HD cũ"} value={input_price(this.state.price)} defaultValue={input_price(this.state.price)} className={"form-control w-100 rounded-0"} />
                                                                                        </>

                                                                                    )}


                                                                                </div>

                                                                            </div>
                                                                        ):(<></>)}

                                                                        <div className="col-12 mt-4 col-sm-6 col-md-5">

                                                                            <div className={"row"}>
                                                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                                                    <a onClick={()=>{
                                                                                        self.AccessTransaction();
                                                                                    }} className={"btn btn-primary rounded-0 w-100 font14"}>
                                                                                        <i className="far fa-save me-1" />
                                                                                        Chấp nhận</a>
                                                                                </div>
                                                                                <div onClick={()=>{
                                                                                    self.DennyTransaction();
                                                                                }} className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                                                    <a className={"btn btn-danger rounded-0 w-100 font14"}><i className="fas fa-ban me-1" /> Từ chối</a>
                                                                                </div>


                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                ):(
                                                                    <div className={"alert alert-danger mt-3"}>
                                                                        <i className="fas fa-power-off me-2" /> Bạn đã cập nhật trạng thái của phiếu thu
                                                                    </div>
                                                                )}

                                                            </>
                                                        )}








                                                    </div>
                                                ):(

                                                    <DetailWallet data={data} />
                                                )}

                                            </div>






                                        </div>


                                    </div>


                                </div>


                            </div>
                        </div>
                        <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

                    </div>
                );
            }


        }
        else{
            return(
                <div className={"col-12 mt-3 mb-3 d-flex flex-row justify-content-center"}>
                    <ItemLoading size={"Large"}  />
                </div>
            );
        }


    }


    ReloadDataPay(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_wallet', this.state.InfoPay._id);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/get", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    InfoPay:data.data,
                    loading:false
                });
            }
            Appthis.PageLoad(false);

        }).catch((e)=>{
            Appthis.PageLoad(false);

        });

    }


    ActionTransaction(type){

        var Appthis=this.props.AppThis;

        this.setState({
            loading:true
        });

        const self=this;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_wallet', this.state.InfoPay._id);

        var data_update={
            status:type
        };

        if(typeof this.state.InfoPay.type!=='undefined' && (this.state.InfoPay.type===2 || this.state.InfoPay.type===5)){
            data_update['price']=this.state.price;
        }

        formData.append('data', base64_encode(data_update));

        fetch(Appthis.ConstApp.home_api+this.props.path+"/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác không thành công");

                self.setState({
                    loading:false
                });
            }
            else{
                toast("Thao tác thành công");
                this.ReloadDataPay();
            }

        }).catch((e)=>{

            self.setState({
                loading:false
            });
            toast("Thao tác không thành công");

        });

    }

    AccessTransaction(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ActionTransaction(1);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    DennyTransaction(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {

                                self.ActionTransaction(2);

                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

}
