/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import logo from "../../assets/images/logo.png";
import {_isset_string, timeConverterDate} from "../../helper/array";
import EditGroupMember from "../../global/group_member/EditGroupMember";
import DetailGroupMember from "../../global/group_member/DetailGroupMember";
import {confirmAlert} from "react-confirm-alert";

export default class groupmember extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            page: 0,
            query: {
                input_search: null
            },
            ListAdmin: null,
            EnableEditGroup:null,
            DetailGroupMember:null,
            Statics:null
        };

    }

    getQuerySearch(){

        var get_query=this.state.query;
        var query_send={};
        Object.entries(get_query).map(function(item){

            var [key,value]=item;

            if(value!==null){
                query_send[key]=value;
            }

        });

        return query_send;
    }

    componentDidMount() {

        this.getData();

        this.GetStatics();
    }
    GetStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"groupmember/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Statics:data.data
                });
            }


        }).catch((e)=>{


        });

    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }
        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"groupmember", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });

    }

    UpdateStatusItemGroup(id_group){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_group', id_group);

        Appthis.PageLoad(true);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"groupmember/status", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Thao tác thành công");

                self.getData(true);

            }
            else{
                toast("Thao tác thất bại");
            }

            Appthis.PageLoad(false);

        }).catch((e)=>{

            toast("Thao tác thất bại");

            Appthis.PageLoad(false);

        });


    }

    AlertEventItemGroup(id_group){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.UpdateStatusItemGroup(id_group);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }


    RenderListGroup(){

        const self=this;

        return this.state.data.data.map(function(item,index){
            return(
                <div className={"col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 ItemListGroup"}>

                    <div className={"col-12 position-relative shadow rounded overflow-hidden"}>

                        <div className={"col-12 mb-5 position-relative h100px d-flex flex-row justify-content-end w-100 flex-wrap HeaderItemGroup text-center"}>

                            <i className="fas fa-bolt position-absolute font30 text-white left0 top0 iconHeaderItemListGroup" />

                            <span className={"position-absolute text-white font12 CodeItemGroup font-weight-bold text-uppercase"}>{item.code_group}</span>

                            <img className={"box-avatar-100 m-auto position-absolute border-0"} src={logo} />

                        </div>


                        <p className={"text-uppercase text-center font-weight-bold font14 mt-3 mb-1"}>
                            {item.name}
                        </p>


                        <div className={"row m-0 border-bottom p-2 border-light"}>

                            <div className={"col-6"}>
                                <span>Người đứng tên</span>
                            </div>
                            <div className={"col-6"}>
                                <i className="fas fa-user-alt"></i> {item.id_user.code_user}
                            </div>
                        </div>

                        <div className={"row m-0 border-bottom p-2 border-light"}>

                            <div className={"col-6"}>
                                <span>Người tạo</span>
                            </div>
                            <div className={"col-6"}>
                                <i className="far fa-user" /> {item.id_admin_create.code_user}
                            </div>
                        </div>

                        <div className={"row m-0 bg-light border-bottom p-2 border-light"}>

                            <div className={"col-6"}>
                                <span>Ngày tạo</span>
                            </div>
                            <div className={"col-6"}>
                                <i className="far fa-clock" /> {timeConverterDate(item.time_create)}
                            </div>

                        </div>


                        <div className={"row m-0 bg-light border-bottom p-2 border-light"}>

                            <div className={"col-6"}>
                                <span>Trạng thái</span>
                            </div>
                            <div className={"col-6"}>
                                {item.status===1?(
                                    <span className={"badge bg-primary font11 rounded-0"}>
                                        <i className="far fa-check-circle me-1" />
                                        Hoạt động</span>
                                ):(
                                    <span className={"badge bg-danger font11 rounded-0"}>
                                        <i className="fas fa-minus me-1" />
                                        Ngừng hoạt động</span>
                                )}
                            </div>

                        </div>

                        <div className={"row m-0 align-items-center EventItemStaticsGroup"}>

                            <div className={"col-12 col-sm-6 p-0 pb-3 EventBgItemStaticGroup"}>
                                <a onClick={()=>{
                                    self.setState({
                                        DetailGroupMember:item
                                    });

                                }} className={"btn font12 btn-primary rounded-0 w-100"}>
                                    <i className="fas fa-info me-1" />
                                    Xem chi tiết
                                </a>
                            </div>
                            <div className={"col-12 col-sm-6 p-0 pb-3 EventBgItemStaticGroup"}>

                                {item.status===1?(
                                    <a onClick={()=>{
                                        self.AlertEventItemGroup(item._id);
                                    }} className={"btn font12 btn-dark rounded-0 w-100"}>
                                        <i className="fas fa-info me-1" />
                                        Ngừng kích hoạt
                                    </a>
                                ):(
                                    <a onClick={()=>{
                                        self.AlertEventItemGroup(item._id);
                                    }} className={"btn font12 btn-success rounded-0 w-100"}>
                                        <i className="far fa-check-circle me-1" />
                                        kích hoạt
                                    </a>
                                )}

                            </div>
                        </div>


                    </div>


                </div>
            );
        });
    }


    RenderStatics(){

        if(this.state.Statics.length>0){
            return(
                <div className={"row m-0 mt-4 mb-3"}>


                    {this.state.Statics.map(function(item){

                        return(
                            <div className="col-12 col-sm-6 d-none d-lg-block col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                                <div className="col-12 bg-white p-3 shadow box-item-dashboard">
                                        <span className="text-uppercase mb-0 pb-1 item-line-bottom text-dark font16">
                                            <i className="fas fa-caret-right me-2" />
                                            {item.name}</span>
                                        <p className="number-info-dashboard mt-3 m-0 font16 font-weight-bold">{item.count}</p>
                                </div>
                            </div>

                        );
                    })}

                </div>

            );
        }
        else{

            return(<></>);
        }


    }


    RenderPagination(){

        const self=this;

        if(this.state.DetailGroupMember!==null){
            return(
                <DetailGroupMember onClose={()=>{
                    this.setState({
                        DetailGroupMember:null
                    },function(){
                        self.getData(true);
                    });
                }} data={this.state.DetailGroupMember} AppThis={this.props.AppThis} path={"groupmember"} />
            );
        }
        else{
            return(
                <div className={"col-12 pt-2 pb-3 pt-3 shadow bg-white"}>

                    <div className="row m-0">

                        <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                            <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Nhóm HD doanh nghiệp</h3>
                        </div>

                        <div className="col-12 col-sm-12 text-right text-end">

                            <div className="col-12 p-0 mb-2">
                                Tổng bản ghi : {this.state.data.total_row}
                            </div>

                            <div className={"col-12"}>
                                <button onClick={()=>{
                                    this.setState({
                                        ModalSearchMobile:true
                                    });
                                }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                            </div>



                            <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                    <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                        <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                        <a onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                            <i className="fas fa-times"></i>
                                        </a>
                                    </div>


                                    <input onChange={(event)=>{
                                        var query=this.state.query;
                                        query.input_search=event.target.value;
                                        this.setState({
                                            query:query,
                                        });

                                    }} autoComplete={"off"} type="text" name="input_search"
                                           className="font13 form-control d-inline-block w-auto rounded-0"
                                           placeholder="Từ khóa tìm kiếm"/>

                                    <a onClick={()=>{
                                        this.getData(true);
                                        this.setState({
                                            ModalSearchMobile:false
                                        });
                                    }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                        <i className="fas fa-search" />Tìm kiếm </a>


                                    <a onClick={()=>{
                                        this.setState({
                                            EnableEditGroup:{},
                                            ModalSearchMobile:false
                                        });
                                    }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>



                                </div>

                            </div>



                        </div>

                    </div>



                    {this.state.Statics!==null?(
                        this.RenderStatics()
                    ):(
                        <div className={"col-12 d-flex flex-row justify-content-center"}>
                            <ItemLoading />
                        </div>
                    )}


                    {this.state.data.data.length===0?(
                        <div className={"col-12 ps-4 pe-4"}>
                            <div className={"row mt-4 mb-2"}>
                                <div className={"alert alert-danger rounded-0"}>
                                    <i className="fas fa-layer-group" /> Không có dữ liệu
                                </div>
                            </div>
                        </div>
                    ):(
                        <div className={"row m-0 mt-4 mb-5"}>
                            {this.RenderListGroup()}
                        </div>
                    )}


                    {this.state.data!==null?(
                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>
                    ):(<></>)}




                </div>
            );
        }

    }


    render(){

            const self=this;

            if(this.state.data!==null){
                return(
                    <div className={"container-fluid container-main-template mb-5"}>


                        {this.RenderPagination()}

                        {this.state.EnableEditGroup!==null?(
                            <EditGroupMember onClose={()=>{
                                this.setState({
                                    EnableEditGroup:null
                                },function(){
                                    self.getData(true);
                                });
                            }} AppThis={this.props.AppThis} path={"groupmember"} data={this.state.EnableEditGroup} />
                        ):(
                            <></>
                        )}



                    </div>
                );
            }
            else{
                return(
                    <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                        <ItemLoading size={"Large"} />
                    </div>
                );
            }



    }


}
