/* eslint-disable */
import React,{Component} from "react";
import {_isset_object, formatNumber, monthDiffByTime, timeConverterDate} from "../../helper/array";
import ItemLoading from "../ItemLoading";
import '../../Print.css';
import {toast} from "react-toastify";

export default class PrintContract extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
            loading:true
        };
    }

    componentDidMount() {

        if(this.props.data){
            const self=this;
            this.setState({
                data:this.props.data
            },function(){
                self.SetPrintContract(this.props.data);
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.Onclose!=='undefined'){
                this.props.Onclose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    SetPrintContract(data){

        var Appthis=this.props.AppThis;

        const self=this;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_member',data._id);

       fetch(Appthis.ConstApp.home_api+this.props.path+"/print", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(response=>response.json()).then(response=>{


            self.setState({
                loading:false
            });


       });

    }


    render(){

        if (this.state.data!==null && this.state.loading===false){
            var data=this.state.data;

            return (

                <div className={"col-12 p-0 w-100 Body-PrintContract"}>

                    <a className={"font26 "} onClick={()=>{
                        this.props.Onclose();
                    }}><i className="far fa-window-close"></i></a>

                    <div className="A4_print">
                        <div className="col-12 p-0">

                            <div className="offset-7 col-5">
            <span className="font-italic font-weight-bold">
            Hợp đồng Hội Viên số :  <span>{data.number_contract}</span> -

                <span className={"ms-1 text-uppercase"}>{data['id_address']['slug']}</span>
            </span>
                            </div>

                            <div className="col-12 item_border item_border_top p-0 bg_orange pt-1 pb-1 font-weight-bold text-uppercase text-center ">
                                Hợp đồng hội viên
                            </div>


                            <div className="row m-0">

                                <div className="col-7 p-0">

                <span className="font-weight-bold border-right-0 w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">
                    Thông tin cá nhân
                </span>

                                    <div className="col-12 p-0 item_border pl-2 border-right-0">
                                        <p className="m-0" >Tên hội viên:
                                            <b className="text-cap ms-1"> {data['id_user']['username']}</b>

                                        </p>
                                    </div>


                                    <div className="col-12 p-0 item_border pl-2 border-right-0">
                                        <p className="m-0">Số căn cước  /  hộ chiếu:<span className="ms-1">

                                            {data['id_user']['citizen_identification']?(
                                                <span>{data['id_user']['citizen_identification']}</span>
                                            ):(<></>)}</span>


                                        </p>
                                    </div>
                                    <div className="col-12 p-0 item_border pl-2 border-right-0">
                                        <p className="m-0">Địa chỉ:<span className="text-cap ms-1" >

                                            {data['id_user']['address_contact']?(
                                                <span>{data['id_user']['address_contact']}</span>
                                            ):(<></>)}
											</span>

                                        </p>
                                    </div>

                                    <div className="col-12 p-0 item_border pl-2 border-right-0">

                                        <div className="row m-0">

                                            <div className="col-6 p-0">
                                                Ngày sinh:
												<span className="ms-1">
                                                {data['id_user']['birthday']?(
                                                    <span>{timeConverterDate(data['id_user']['birthday'])}</span>
                                                ):(<></>)}</span>

                                            </div>
                                            <div className="col-6 p-0 item_border border-top-0 border-bottom-0 border-right-0">

                                                <div className="row m-0">

                                                    <div className="col-5 p-0 pl-2">
                                                        Giới tính :
                                                    </div>

                                                    <div className="col-7 p-0">
                                                        <div className="row m-0">
                                                            <div className="col-6 pl-4 position-relative d-flex align-items-center item_border border-right-0 border-top-0 border-bottom-0 p-0">

                                                                {data['id_user']['sex']===1?(
                                                                    <span className="position-absolute p18 item-type-contract">&#x2611;</span>
                                                                ):(
                                                                    <span className="position-absolute p18 item-type-contract">&#x2610;</span>
                                                                )}
                                                                Nam
                                                            </div>
                                                            <div className="col-6 pl-4 position-relative d-flex align-items-center item_border border-right-0 border-top-0 border-bottom-0 p-0">

                                                                {data['id_user']['sex']===1?(
                                                                    <span className="position-absolute p18 item-type-contract">&#x2610;</span>
                                                                ):(
                                                                    <span className="position-absolute p18 item-type-contract">&#x2611;</span>
                                                                )}
                                                                Nữ

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>


                                    <div className="col-12 p-0 item_border pl-2 border-right-0">
                                        <p className="m-0">Điện thoại:<span className="ms-1">

                                            {data['id_user']['user_login'] && data['type_target']===0?(
                                                <span>{data['id_user']['user_login']}</span>
                                            ):(<span>không có SDT</span>)}</span>

                                        </p>
                                    </div>

                                    <div className="col-12 p-0 item_border pl-2 border-right-0">
                                        <p className="m-0"> Email:<span className="ms-1">

                                            {data['id_user']['email']?(
                                                <span>{data['id_user']['email']}</span>
                                            ):(<></>)}</span>


                                        </p>
                                    </div>



                                    <span className="font-weight-bold border-right-0 w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">
                                        Liên lạc khẩn cấp
                </span>

                                    <div className="row m-0">
                                        <div className="col-12 p-0 item_border pl-2 border-right-0">
                                            <p className="m-0">Họ và tên:

                                                <b className="text-cap ms-1">

                                                    {data['protector']?(
                                                        <span>{data['protector']['username']}</span>
                                                    ):(<></>)}

                                                </b>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row m-0">

                                        <div className="col-7 p-0 item_border border-right-0 pl-2">
                                            <p className="m-0">Số điện thoại :
												<span className="ms-1">
                                                {data['protector']?(
                                                    <span>{data['protector']['user_login']}</span>
                                                ):(<></>)}</span>

                                            </p>
                                        </div>

                                        <div className="col-5 p-0 item_border pl-2 border-right-0">
                                            <p className="m-0"> Mối quan hệ:

                                                {data['protector']?(
                                                     <span className={"text-capitalize ms-1"}>{data['protector']['relationship']}</span>
                                                ):(<></>)}

                                            </p>
                                        </div>

                                    </div>


                                    <span className="font-weight-bold border-right-0 w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">Người đồng ký tên</span>

                                    <div className="row m-0">

                                        <div className="col-7 p-0 position-relative text_indent_20 item_border pl-2 pr-2 text-justify border-right-0">
                                            <span className="position-absolute p20 item-type-contract left_indent_20">&#x2611;</span>
                                            Cha mẹ/ Người giám hộ: Thay mặt con còn nhỏ, tôi đồng ý các điều khoản, điều kiện của hợp đồng này và thừa nhận nghĩa vụ tài chính đối với con còn nhỏ của tôi.
                                        </div>

                                        <div className="col-5 p-0 position-relative text_indent_20 item_border pl-2 pr-2 text-justify border-right-0">
                                            <span className="position-absolute p20 item-type-contract left_indent_20">&#x2611;</span>
                                           Người đồng ký tên: Tôi đồng ý các điều khoản, điều kiện cũng như nghĩa vụ tài chính mà tôi cần thực hiện của thỏa thuận này.
                                        </div>

                                    </div>


                                    <div className="col-12 pl-2 pr-2 item_border border-right-0 text-justify">
                                       Với tư cách là cha mẹ/người giám hộ/người đồng ký tên, tôi chịu trách nhiệm cho bất kỳ chấn thương hoặc sự tổn hại cơ thể xảy ra với người dưới 16 tuổi khi tập luyện tại trung tâm. Tôi hiểu rằng các nghĩa vụ của tôi theo Hợp đồng này chấm dứt chỉ khi Hội viên đã ký tên chấm dứt theo các điều khoản trong Hợp đồng này. Tôi đồng ý khấu trừ tự động tất cả các khoản phí của hội viên ký tên dưới đây từ tài khoản của tôi bằng thẻ thanh toán tự động khi các khoản đó đến hạn phải thanh toán.
                                    </div>

                                    <div className="row m-0">

                                        <div className="col-7 p-0">

                                            <div className="col-12 p-0 item_border pl-2 height29 d-flex flex-wrap align-items-center border-right-0">
                                                <p className="m-0">Họ và tên:
                                                    <b className="text-cap ms-1">

                                                        {data['contact_person']?(
                                                            <span>{data['contact_person']['username']}</span>
                                                        ):(<></>)}

                                                    </b>
                                                </p>
                                            </div>

                                            <div className="col-12 p-0 item_border pl-2 border-right-0">
                                                <p className="m-0">CCCD/ hộ chiếu:<span className="ms-1">

                                                    {data['contact_person'] && data['contact_person']['citizen_identification']?(
                                                        <span>{data['contact_person']['citizen_identification']}</span>
                                                    ):(<></>)}</span>



                                                </p>
                                            </div>

                                            <div className="col-12 p-0 item_border pl-2 border-right-0">
                                                <p className="m-0">Số điện thoại:

                                                    {data['contact_person'] && data['contact_person']['user_login']?(
                                                        <span className="ms-1">{data['contact_person']['user_login']}</span>
                                                    ):(<></>)}


                                                </p>
                                            </div>

                                            <div className="col-12 p-0 item_border pl-2 border-right-0">
                                                <p className="m-0">Mối quan hệ :
                                                    {data['contact_person']?(
                                                        <span>{data['contact_person']['relationship']}</span>
                                                    ):(<></>)}

                                                </p>
                                            </div>

                                            <div className="col-12 p-0 item_border pl-2 border-right-0">
                                                <p className="m-0 text-line">Email:

                                                    {data['contact_person'] && data['contact_person']['email']?(
                                                        <span className="ms-1">{data['contact_person']['email']}</span>
                                                    ):(<></>)}



                                                </p>
                                            </div>

                                            <div className="col-12 p-0 item_border pl-2 border-right-0">
                                                <p className="m-0 text-line">Địa chỉ :

                                                    {data['contact_person'] && data['contact_person']['address_contact']?(
                                                        <span className="ms-1">{data['contact_person']['address_contact']}</span>
                                                    ):(<></>)}


                                                </p>
                                            </div>


                                        </div>

                                        <div className="col-5 p-0 item_border pt-2 font-italic pl-2 pr-2 text-justify border-right-0">
                                            Chữ ký người đồng ký tên :
                                        </div>

                                    </div>

                                </div>

                                <div className="col-5 p-0">
                <span className="font-weight-bold w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">
                    Thông tin hội viên
                </span>

                                    <div className="col-12 p-0 item_border text-center">
                                        Loại hình

                                    </div>

                                    <div className="row m-0">


                                        <div className="col-4 p-0 pl-4 item_border d-flex align-items-center position-relative border-right-0">

                                            {data['group_member']?(
                                                <span className="position-absolute p18 item-type-contract">&#x2610;</span>
                                            ):(
                                                <span className="position-absolute p18 item-type-contract">&#x2611;</span>
                                            )}

                                            Thẻ đơn
                                        </div>


                                        <div className="col-4 p-0 pl-4 d-flex align-items-center position-relative item_border border-right-0">
                                            <span className="position-absolute p18 item-type-contract">&#x2610;</span> Thẻ đôi
                                        </div>


                                        <div className="col-4 p-0 pl-4 position-relative d-flex align-items-center item_border">

                                            {data['group_member']?(
                                                <span className="position-absolute p18 item-type-contract">&#x2611;</span>
                                            ):(
                                                <span className="position-absolute p18 item-type-contract">&#x2610;</span>
                                            )}

                                            Thẻ nhóm
                                        </div>


                                    </div>


                                    <div className="row m-0">


                                        <div className="col-4 p-0 pl-4 position-relative height42 d-flex align-items-center item_border border-right-0">

                                            {data['type_member']===1?(
                                                <span className="position-absolute p18 item-type-contract">&#x2611;</span>
                                            ):(
                                                <span className="position-absolute p18 item-type-contract">&#x2610;</span>
                                            )}
                                            Đăng ký mới
                                        </div>

                                        <div className="col-4 p-0 pl-4 position-relative height42 d-flex align-items-center item_border border-right-0">

                                            {data['type_member']===3?(
                                                <span className="position-absolute p18 item-type-contract">&#x2611;</span>
                                            ):(
                                                <span className="position-absolute p18 item-type-contract">&#x2610;</span>
                                            )}
                                            Nâng cấp
                                        </div>


                                        <div className="col-4 p-0 pl-4 position-relative height42 d-flex align-items-center item_border">

                                            {[2,4,5].indexOf(data['type_member'])!==-1?(
                                                <span className="position-absolute p18 item-type-contract">&#x2611;</span>
                                            ):(
                                                <span className="position-absolute p18 item-type-contract">&#x2610;</span>
                                            )}



                                            <p className="giahan">Gia hạn/ <br/> Phục hồi</p>
                                        </div>


                                    </div>


                                    <div className="row m-0">
                                        <div className="col-6 item_border border-right-0">
                                            Hợp đồng cũ:
                                            {typeof data['id_member_source']!=='undefined' && data['id_member_source'].length>0?(
                                                    <span className={"ms-1"}>{data['id_member_source'][data['id_member_source'].length-1]['number_contract']}</span>
                                            ):(
                                               <></>
                                            )}
                                        </div>

                                        <div className="col-6 item_border">
                                            Mã nhóm:

                                            {data['group_member']?(
                                                <span>
                                                    {data['group_member']['code']}
                                               </span>

                                            ):(
                                                <></>
                                            )}



                                        </div>

                                    </div>


                                    <div className="row m-0">
                                        <div className="col-12 item_border text-center">
                                            Loại hợp đồng
                                        </div>
                                    </div>


                                    <div className="row m-0">
                                        <div className="col-6 item_border pl-2 height29 position-relative d-flex align-items-center border-right-0">
                                            Loại thẻ: <span className={"ps-1 text-uppercase"}>{data['id_card']['name']}</span>

                                        </div>
                                        <div className="col-6 item_border pl-2 height29 position-relative d-flex align-items-center">
                                            Thời lượng: <span className={"ps-1"}>
                                            {data['id_card']['type']===2?(
                                                <>
                                                    {data['info_contract_data']['number_card']<=30?(
                                                        <span>1 Tháng</span>
                                                    ):(
                                                        <span>{monthDiffByTime(data['start_day'],data['end_day_contract'])} Tháng</span>
                                                    )}
                                                </>
                                            ):(
                                                <>
                                                    {data['id_card']['type']===1?(<>
                                                        {data['info_contract_data']['number_card']} Buổi
                                                            </>):(
                                                        <span>{data['info_contract_data']['number_card']/data['id_card']['number_day_in_month']} Tháng</span>
                                                    )}

                                                </>
                                            )}

                                        </span>
                                        </div>
                                    </div>



                                    <div className="row m-0">
                                        <div className="col-12 item_border pl-2">
                                            Tính từ ngày:

                                            {data['start_day']?(
                                                <span className="ms-1">
                                                    {timeConverterDate(data['start_day'])}
                                               </span>

                                            ):(
                                                <></>
                                            )}


                                        </div>
                                    </div>

                                    <div className="row m-0">
                                        <div className="col-12 item_border pl-2">
                                            Ngày kết thúc:
                                            {data['end_day_contract']?(
                                                <span className="ms-1">
                                                    {timeConverterDate(data['end_day_contract'])}
                                               </span>

                                            ):(
                                                <></>
                                            )}


                                        </div>
                                    </div>



                                    <div className="row m-0">
                                        <div className="col-6 item_border pl-2 pr-2 text-justify d-flex flex-wrap align-items-center height130 border-right-0">
                                            Hợp đồng Hội viên trả trước không thể hủy bỏ/trì hoãn và phí thanh toán trước sẽ không được hoàn trả.
                                        </div>
                                        <div className="col-6 item_border pl-2 pr-2 text-justify d-flex flex-wrap align-items-center height130 position-relative">
                                            Hợp đồng hội viên thanh toán hàng tháng không thể hủy bỏ/trì hoãn và phí hàng tháng phải tự động thực hiện trong thời hạn tối thiểu.
                                        </div>
                                    </div>



                                    <div className="row m-0">
                                        <div className="col-12 item_border pl-2 pr-2 height140 text-justify d-flex height141">
                                            <p className="m-0 font-italic">Chữ ký hội viên:</p>
                                        </div>

                                    </div>


                                    <div className="col-12 item_border p-0 bg_orange pt-1 pb-1 font-weight-bold text-uppercase text-center">
                                        kế toán
                                    </div>


                                    <div className="col-12 item_border pl-2">
                                        Phí thành viên:<span className="ms-1">

                                        { formatNumber(data['info_contract_data']['price_sale'] - (data['info_contract_data']['price_vat']+data['info_contract_data']['price_insurance'])) } VNĐ</span>

                                    </div>

                                    <div className="col-12 item_border pl-2">
                                        VAT:<span className="ms-1">

                                        { formatNumber(data['info_contract_data']['price_vat']) } VNĐ</span>


                                    </div>

                                    <div className="col-12 item_border pl-2 b">
                                        Bảo hiểm:<span className="ms-1">

                                        { formatNumber(data['info_contract_data']['price_insurance']) } VNĐ</span>

                                    </div>

                                    <div className="col-12 item_border pl-2">
                                        Phí quản lý và duy trì: 0
                                    </div>
                                    <div className="col-12 font-weight-bold item_border pl-2 height30">
                                        Tổng cộng:
                                        <span className="ms-1">{ formatNumber(data['info_contract_data']['price_sale']) } VNĐ</span>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="col-12 p-0">
                            <div className="col-12 font-weight-bold w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">
                                lưu ý quan trọng trước khi ký kết đối với hội viên
                            </div>
                            <div className="col-12 pl-2 pr-2 item_border text-justify">
                                <ul className="nav list-number dspb">
                                    <li className="nav-item mb-1">Hội viên của KICKFIT SPORTS được bảo đảm một phần cho các rủi ro thương tích xảy ra trong quá trình tập luyện tại trung tâm.</li>
                                    <li className="nav-item mb-1">Tất cả các điều khoản hợp đồng, nội quy và quy định của KICKFIT SPORTS đã được đọc, hiểu và đồng ý. Quyền hội viên được bắt đầu theo ngày trên hợp đồng (hoặc là khi trung tâm mở cửa trong trường hợp chào bán trước). Quan hệ pháp lý ràng buộc giữa hội viên và KICKFIT SPORTS được thiết lập trên thỏa thuận này. Tất cả quyền và nghĩa vụ được nêu trong Hợp đồng sẽ có hiệu lực kể từ ngày ký kết.</li>
                                    <li className="nav-item mb-1">Nếu hợp đồng này là loại hình thanh toán hàng tháng, KICKFIT SPORTS được chấp thuận thực hiện khấu trừ tự động các khoản phí hàng tháng qua tài khoản ngân hàng của hội viên đăng ký với KICKFIT SPORTS cho đến khi hợp đồng hội viên hết hạn như được nêu trong văn bản này. Số tiền ủy quyền khấu trừ tự động qua tài khoản ngân hàng phải bằng ít nhất hai tháng đăng ký để cho phép KICKFIT SPORTS tăng tiền định kỳ và/ hoặc thu phí trả chậm và các chi phí khác theo quy định tại đây mà không cần bất kỳ yêu cầu ủy quyền bổ sung nào từ hội viên. Khấu trừ tự động có thể được tính vào ngày đầu tiên hoặc ngày thứ mười lăm của tháng theo quy định riêng của KICKFIT SPORTS. Tỷ lệ phí sẽ được cộng thêm vào khoản khấu trừ tự động đầu tiên của hội viên dựa trên số ngày tính từ ngày gia nhập đến ngày của kỳ thanh toán đầu tiên được KICKFIT SPORTS quy định.</li>
                                    <li className="nav-item mb-1">Trong trường hợp một trong các trung tâm KICKFIT SPORTS phải đóng cửa vì bất cứ lý do nào, KICKFIT SPORTS có toàn quyền và trách nhiệm sắp xếp cho hội viên tập luyện tại một trong các trung tâm còn lại của KICKFIT SPORTS.</li>
                                    <li className="nav-item mb-1">Các quyền và nghĩa vụ tương ứng của hội viên được nêu ở phần trên của văn bản này và trong Điều khoản hợp đồng, Nội quy và Quy định. Hội viên cam kết hiểu rõ và đồng ý với các quy định, nghĩa vụ đó. Nội quy và Quy định sẽ được niêm yết bằng văn bản và/hoặc bằng cách khác có sẵn tại tất cả các địa điểm của KICKFIT SPORTS. KICKFIT SPORTS bảo lưu quyền sửa Nội quy và Quy định theo từng thời điểm thích hợp.</li>
                                </ul>
                            </div>

                        </div>

                        <div className="row p-0 m-0">

                            <div className="col-9 p-0">

                                <div className="row m-0">

                                    <div className="col-6 p-0">

                                        <div className="row m-0 item_border border-right-0 border-bottom-0 height40">
                                            <div className="col-6">
                                <span className="font-weight-bold">
                                Chữ ký hội viên:
                                </span>

                                            </div>


                                        </div>


                                        <div className="row m-0 item_border border-right-0 item_border_top height40">
                                            <div className="col-12">
                                <span className="font-weight-bold">
                                  <b>  Họ và tên:</b>
									<b className="text-cap ms-1">
                                      { data['id_user']['username'] }
                                  </b>
                                </span>

                                            </div>


                                        </div>

                                    </div>



                                    <div className="col-6 p-0">

                                        <div className="row m-0 item_border border-bottom-0 border-right-0 height40">
                                            <div className="col-12">
                                <span className="font-weight-bold me-1">
                                Nhân viên tư vấn:
                                </span>
                                             <span className={"text-capitalize"}>   {  data['id_admin_create']['code_user'] }</span>
                                            </div>


                                        </div>


                                        <div className="row m-0 item_border border-right-0 item_border_top height40">
                                            <div className="col-12">
                                <span className="font-weight-bold me-1">
                                Ngày hợp đồng:
                                </span> {  timeConverterDate(data['time_create']) }

                                            </div>


                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="col-3 item_border">

                                <p className="m-0 font-weight-bold d-block">Quản lý trung tâm :</p>

                            </div>

                        </div>


                    </div>


                    {data['id_card']['type']===1?(
                        <div className={"col-12 p-0"}>
                            <div className="next_page"></div>

                            <div className="A4_print A4_pt">

                                <div className="row m-0">

                                    <div className="col-8 position-relative">

                                        <div className="col-7 offset-5 text-center">

                                            <h6 className="font-weight-bold text-uppercase fz27">Hợp đồng <br/>
                                                Huấn luyện viên cá nhân
                                            </h6>
                                        </div>

                                        <div className="row">
                                            <div className="col-4 text-uppercase">
                                                &nbsp;
                                            </div>


                                            <div className="col-8 text-uppercase">

                                            </div>

                                            <div className="col-12 text-uppercase">
                       <b> Họ và tên: </b> <b className="text-cap ms-1">{  data['id_user']['username'] }</b>
                                    </div>


                                    <div className="col-12 ">
                                        <b className="text-uppercase">Địa Chỉ:</b><span className="text-cap ms-1">

                                        {data['id_user']['address_contact']?(
                                            <>
                                                {data['id_user']['address_contact']}
                                            </>
                                        ):(
                                            <></>
                                        )}</span>

                                    </div>




                                </div>

                            </div>

                                     <div className="col-4 position-relative fz20">

                                <div className="row m-0">

                                    <div className="col-12 p-0 text-uppercase">
                                        <b>Số hợp đồng:</b><span className="ms-1">
                                        {  data['number_contract'] }</span>
                                    </div>

                                    <div className="col-6">

                                    </div>



                                    <div className="col-12 p-0 text-uppercase">
                                        <b>  Loại hình:</b>

                                        {data['id_card']['type']===1?(
                                            <span className="ms-1">Thẻ PT</span>
                                        ):(<></>)}

                                        {data['id_card']['type']===2?(
                                            <span className="ms-1">Thẻ Member</span>
                                        ):(<></>)}

                                        {data['id_card']['type']===3?(
                                            <span className="ms-1">Thẻ nhóm LH</span>
                                        ):(<></>)}


                                        {data['id_card']['type']===4?(
                                            <span className="ms-1">Thẻ nhóm CD</span>
                                        ):(<></>)}

                                    </div>

                                    <div className="col-6">



                                    </div>




                                    <div className="col-12 p-0 text-uppercase">
                                        <b>Hợp đồng cũ:</b>
                                        {typeof data['id_member_source']!=='undefined' && data['id_member_source'].length>0?(
                                            <span className={"ms-1"}>{data['id_member_source'][data['id_member_source'].length-1]['number_contract']}</span>
                                        ):(
                                            <></>
                                        )}
                                    </div>

                                    <div className="col-6">

                                    </div>



                                    <div className="col-12 p-0 text-uppercase">
                                        <b> mã hội viên:</b><span className="ms-1">


                                        {data['id_user']?(
                                            <>
                                                {data['id_user']['code_user']}
                                            </>
                                        ):(
                                            <></>
                                        )}</span>
                                    </div>

                                    <div className="col-6">

                                    </div>

                                    <div className="col-12 p-0 text-uppercase">
                                        <b>ĐIỆN THOẠI:</b><span className="ms-1">

                                         {data['id_user']?(
                                             <>
                                                 {data['id_user']['user_login']}
                                             </>
                                         ):(
                                             <></>
                                         )}
                                        </span>

                                    </div>

                                    <div className="col-6">

                                    </div>


                                </div>


                            </div>


                                </div>
							<div className="row m-0 item_border_top">
            <div className="font-weight-bold w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">
               Chính sách và nội quy
            </div>

            <div className="col-12 p-0 pl-1 pr-1 item_border">

                <ul className="nav list-number">
                    <li className="nav-item">
                        Hội viên của KICKFIT SPORTS được bảo đảm một phần cho các rủi ro, thương tích xảy ra trong quá trình tập luyện tại trung tâm.
                    </li>


                    <li className="nav-item">
                        Hợp đồng huấn luyện viên cá nhân (HLV) là dịch vụ gia tăng chỉ dành cho hội viên của KICKFIT SPORTS và chỉ được bắt đầu khi đã thanh toán đầy đủ.
                    </li>


                    <li className="nav-item">
                        Tất cả các buổi tập đều phải được hoàn thành vào trước hoặc trong ngày hợp đồng hết hạn. Sau ngày hết hạn, hợp đồng HLV sẽ được xem là hội viên từ bỏ. Hiệu lực hợp đồng sẽ không được gia hạn trừ trường hợp được KICKFIT SPORTS đồng ý bằng văn bản. Thời hạn hợp đồng được tính kể từ ngày hợp đồng được thanh toán đầy đủ hoặc ngày của buổi tập đầu tiên tùy thời điểm nào đến trước. Thời gian hiệu lực của hợp đồng được tính bằng ba lần tổng số buổi tập. Ví dụ: hợp đồng 50 buổi có hiệu lực trong 150 ngày kể từ ngày được thanh toán đầy đủ hoặc ngày buổi tập đầu tiên, tùy thời điểm nào đến trước.
                    </li>


                    <li className="nav-item">
                        KICKFIT SPORTS bảo lưu quyền cung cấp dịch vụ trong trường hợp thay đổi HLV hoặc HLV theo kế hoạch không thể tiến hành tập luyện.
                    </li>


                    <li className="nav-item">
                        Trong trường hợp một trong các trung tâm KICKFIT SPORTS ngừng cung cấp dịch vụ vì bất cứ lý do nào, KICKFIT SPORTS có toàn quyền và trách nhiệm sắp xếp cho hội viên tập luyện tại một trong các trung tâm khác của hệ thống.
                    </li>


                    <li className="nav-item">
                        Các khoản đã thanh toán sẽ không được hoàn trả, không được chuyển nhượng và không được sử dụng để thanh toán các khoản khác của hội viên.
                    </li>


                    <li className="nav-item">
                        Hội viên nghỉ tập cần phải thông báo trước 24 tiếng, nếu không buổi tập đó vẫn được coi là đã hoàn thành. Hội viên có trách nhiệm ký xác nhận cho buổi tập đó tại quầy lễ tân giống như một buổi tập thông thường.
                    </li>
                    <li className="nav-item">
                        Hội viên cần phải KÝ XÁC NHẬN tại quầy lễ tân cho mỗi bài tập NGAY SAU thời điểm tập luyện.
                    </li>

                </ul>

            </div>


            <div className="font-weight-bold w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">
                Bảo đảm của hội viên
            </div>


            <div className="col-12 p-0 pl-1 pr-1 item_border">

                <ul className="nav list-number">

                    <li className="nav-item">
                        Điều kiện thể lực và tư vấn y tế: Hội viên cam đoan rằng thể lực đang ở trong điều kiện tốt, không có lý do nào khác khiến hội viên không sử dụng được tất cả các tiện nghi của KICKFIT SPORTS và dịch vụ HLV cá nhân. Đồng thời, hội viên thừa nhận rằng KICKFIT SPORTS không đưa ra bất kỳ tư vấn y tế nào trước, trong và sau khi ký hợp đồng này liên quan đến điều kiện thể lực và khả năng sử dụng các tiện nghi và dịch vụ HLV cá nhân. Nếu hội viên có vấn đề liên quan đến sức khỏe ở thời điểm hiện tại hoặc sau khi hội viên tham gia cần tham khảo ý kiến bác sỹ trước khi sử dụng các tiện nghi. Tất cả các tình trạng về sức khỏe và y tế sẽ được cung cấp bằng văn bản cho KICKFIT SPORTS. Trong trường hợp hội viên không cung cấp bất kỳ văn bản nào về tình trạng sức khỏe và y tế của bản thân, KICKFIT SPORTS và hội viên cùng hiểu và đồng thuận rằng điều kiện thể lực của hội viên là cho phép.
                    </li>

                    <li className="nav-item">
                        Hợp đồng và hiệu lực toàn bộ: Văn bản này cùng với Phiếu thu và Hợp đồng hội viên chứa đựng toàn bộ thỏa thuận giữa hội viên và KICKFIT SPORTS, liên quan đến dịch vụ Huấn luyện Cá nhân và thay thế tất cả các thỏa thuận bằng miệng hoặc bằng văn bản khác. Nếu KICKFIT SPORTS không thực hiện bất kỳ quyền nào của Thỏa thuận này vì bất cứ lý do gì, KICKFIT SPORTS sẽ không từ bỏ quyền đó cho lần thực hiện tiếp sau.
                    </li>

                    <li className="nav-item">
                        Giữ vô hại: Hội viên và người tập luyện cùng (trong trường hợp điều khoản hợp đồng có thỏa thuận) đồng ý rằng hội viên và người tập luyện cùng sẽ miễn trừ trách nhiệm cho KICKFIT SPORTS trước bất kỳ thiệt hại nào xảy ra do bất kỳ sản phẩm hoặc sự tư vấn của nhân viên KICKFIT SPORTS đã trình bày và cam kết mà không phải các dịch vụ và sản phẩm được KICKFIT SPORTS cung cấp.
                    </li>

                    <li className="nav-item">
                        Hội viên đồng ý tất cả các điều khoản trong Hợp đồng hội viên và cũng như trong Hợp đồng huấn luyện này có giá trị ràng buộc đồng thời.
                    </li>

                </ul>

            </div>


            <div className="font-weight-bold w-100 bg_orange d-block text-center pt-1 pb-1 text-uppercase item_border">
               Trường hợp giả định
            </div>


            <div className="col-12 p-0 pl-1 pr-1 item_border">

                <ul className="nav list-number">

                    <li className="nav-item">
                        Việc sử dụng các tiện nghi tại KICKFIT SPORTS có thể liên quan đến các rủi ro gây thương tích, bất kể do hội viên hay người khác gây ra. Vì vậy, hội viên hiểu và tự nguyện chấp nhận rủi ro này, đồng ý rằng KICKFIT SPORTS sẽ không phải chịu trách nhiệm đối với bất kỳ thương tích nào, bao gồm không giới hạn: thương tích cá nhân cả thể xác hoặc tinh thần; thiệt hại kinh tế hoặc bất kỳ thiệt hại cho hội viên, vợ hoặc chồng, con chưa sinh hoặc những người thân của hội viên do sơ suất hoặc các hành động khác của KICKFIT SPORTS hoặc bất kỳ ai khác khi sử dụng tiện nghi tại KICKFIT SPORTS.
                    </li>

                    <li className="nav-item">
                        Nếu có bất kỳ khiếu nại nào của bất kỳ ai dựa trên bất kỳ thương tích, mất mát hoặc thiệt hại nào được mô tả ở đây, liên quan đến hội viên, hội viên đồng ý bảo vệ KICKFIT SPORTS trước yêu cầu đó và miễn trừ cho KICKFIT SPORTS tất cả chi phí liên quan đến khiếu nại đó cũng như các khoản bồi hoàn cho tất cả các khoản chi phí phát sinh từ yêu cầu đó đối với bất kỳ ai.
                    </li>

                </ul>

            </div>

            <div className="row m-0 p-0 w-100">
                <div className="col-12 w-100 item_border font-italic">
                    <p className="mb-0 fz14" >Tôi xác nhận rằng tôi đã đọc, đã được giải thích, đã hiểu và đồng ý với tất cả nội dung trên.</p>
                    <p className="mb-0 fz14" >Hợp đồng này gồm 3 liên, liên 1 lưu văn phòng, liên 2 để thực hiện, liên 3 gửi khách hàng.</p>
                </div>

            </div>


            <div className="row w-100 m-0 p-0 ">

                <div className="col-4 p-0">

                    <div className="row m-0">

                        <div className="col-5 text-center p-0">
                            <p className="mb-0 text-uppercase footerpt">
                                Tổng giá trị
                                Hợp đồng:
                            </p>
                        </div>

                        <div className="col-7 pt-2">

                            <div className="col-12 pl-0 border-blank mt-5a">
								{ formatNumber(data['info_contract_data']['price_sale']) } VNĐ
                            </div>


                        </div>

                    </div>
                    <p className="font-weight-bold w-100 mb-0">Khách hàng chữ ký:</p>
                </div>


                <div className="col-4 p-0">

                    <div className="row m-0">

                        <div className="col-3 text-center p-0">
                            <p className="mb-0 text-uppercase footerpt">
                               Đơn giá bài tập:
                            </p>
                        </div>

                        <div className="col-9 pt-2">

                            <div className="col-12 pl-0 border-blank mt-5a">
								{ formatNumber(Math.ceil(data['info_contract_data']['price_sale']/data['info_contract_data']['number_card'])) } VNĐ
                            </div>


                        </div>

                    </div>

                    <p className="font-weight-bold w-100 mb-0">Ngày bắt đầu:</p>
                    <p className="mb-0">
						 {  timeConverterDate(data['time_create']) }
                    </p>

                    <p className="font-weight-bold w-100 mb-0">Nhân viên tư vấn:</p>
                    <p className="mb-0">
                       {  data['id_admin_create']['code_user'] }
                    </p>


                </div>


                <div className="col-4 p-0">

                    <div className="row m-0">

                        <div className="col-3 text-center p-0">
                            <p className="mb-0 text-uppercase footerpt">
                                Tổng số bài tập:
                            </p>
                        </div>

                        <div className="col-9 pt-2 pr-0">

                            <div className="col-12 pl-0 border-blank mt-5a">
								 {  data['info_contract_data']['number_card'] }
                            </div>

                        </div>

                    </div>

                    <p className="font-weight-bold w-100 mb-0" >Ngày hết hạn hợp đồng:</p>
                    <p className="mb-0">
						 {  timeConverterDate(data['end_day_contract']) }
                    </p>

                    <p className="font-weight-bold w-100 mb-0"  >Huấn luyện viên:</p>
                    <p className="mb-0">
                       {data.id_admin_pt!==null?(
					   <span>{data.id_admin_pt.code_user}</span>
                                            ):(
                                                <></>
                                            )}

                    </p>


                </div>


            </div>




        </div>



                            </div>

                        </div>
                    ):(<></>)}



                    {data.id_user_sub.length>0?(
                        <div className={"col-12 p-0 w-100 Body-PrintContract mt46"}>
                            <div className="A4_print A4_pt">
                                <div className="col-12 p-0">

                                    <div className="offset-7 col-5">
                    <span className="font-italic font-weight-bold">
                    Hợp đồng số :  <span>{data.number_contract}</span> -
                        <span className={"ms-1 text-uppercase"}>{data['id_address']['slug']}</span>
                    </span>
                                    </div>

                                    <div className="col-12 item_border item_border_top p-0 bg_orange pt-1 pb-1 font-weight-bold text-uppercase text-center ">
                                        Thông tin chi tiết người đi kèm cố định trong hợp đồng số {data.number_contract}
                                    </div>
                                </div>
                                <div className="header_print m-0 row w-100 p-2 mt46 bg_orange item_border item_border_top">
                                    <div className="col-4">
                                        <b>Họ và tên</b>
                                    </div>
                                    <div className="col-2">
                                        <b>Số điện thoại</b>
                                    </div>
                                    <div className="col-2">
                                        <b>Mã hội viên</b>
                                    </div>
                                    <div className="col-2">
                                        <b>Giới tính</b>
                                    </div>
                                    <div className="col-2">
                                        <b>Chữ ký xác nhận</b>
                                    </div>
                                </div>

                                {data.id_user_sub.map(function(item){

                                    return(
                                        <div className="m-0 row w-100 p-2 item_border">

                                            <div className="col-4 ">
                                                {item['username']}
                                            </div>
                                            <div className="col-2">
                                                {item['user_login']}
                                            </div>
                                            <div className="col-2">
                                                {item['code_user']}
                                            </div>
                                            <div className="col-2">
                                                {data['sex']===0?("Nữ"):(
                                                    "Nam"
                                                )}
                                            </div>
                                            <div className="col-2">

                                            </div>
                                        </div>
                                    );
                                })}


                                <p className={"text-center mt-3 text-uppercase font-weight-bold"}><h5>
                                    <strong>Quy định dành cho người đi kèm</strong>
                                </h5></p>

                                <ul className="nav list-number">
                                    <li className={"nav-item"}>
                                        <p>Người đi kèm cố định và người đi kèm linh hoạt:</p>
                                        <p>
                                            Người đi kèm cố định: là những người có tên trong danh sách được chủ thẻ chính đăng kí tại thời điểm kí kết hợp đồng.

                                            Người đi kèm linh hoạt: là người đi cùng chủ thẻ chính vào Câu lạc bộ để sử dụng dịch vụ và không cần phải đăng kí thông tin cá nhân tại thời điểm kí kết hợp đồng.

                                            Việc người đi kèm là cố định hay linh hoạt tuỳ thuộc vào quy định của KICKFIT SPORTS về quyền lợi đối với từng loại dịch vụ được cung cấp bởi KICKFIT SPORTS trong từng thời điểm khác nhau và được thể hiện trong hợp đồng.
                                        </p>
                                    </li>
                                    <li className={"nav-item"}>
                                        <p>
                                            Người đi kèm phải phải xuất trình CMT/CCCD/ thẻ học sinh/sinh viên tại quầy lễ tân để nhân viên có căn cứ xác định danh tính và độ tuổi của khách hàng.
                                        </p>
                                    </li>
                                    <li className={"nav-item"}> Người đi kèm phải đến và sử dụng dịch cùng thời điểm với chủ thẻ chính, được hưởng quyền lợi và phải tuân thủ các nội quy, quy định của KICKFIT SPORTS như chủ thẻ chính. Đồng nghĩa, khi hợp đồng chủ thẻ chính được bảo lưu, chuyển nhượng hoặc hết hiệu lực thì người đi kèm cố định cũng không được sử dụng dịch vụ.
                                    </li>
                                    <li className={"nav-item"}>Trường hợp không có đủ căn cứ để xác minh danh tính/độ tuổi của người đi kèm hoặc người đi kèm không đến và sử dụng dịch vụ cùng chủ thẻ chính, nhân viên có quyền từ chối cung cấp dịch vụ.
                                    </li>
                                    <li className={"nav-item"}>Trường hợp người đi kèm đã từng là nhân viên của KICKFIT SPORTS và đã từng vi phạm bất kì quy định nào của công ty hoặc đã từng là Hội viên vi phạm bất kì quy định nào của Hợp đồng hội viên/ Quy tắc ứng xử thi theo quyết định của KICKFIT SPORTS thì quyền vào Câu lạc bộ có thể bị hạn chế.
                                    </li>
                                    <li className={"nav-item"}>Trong trường hợp người đi kèm vi phạm bất kì quy định/ quy tắc ứng xử nào của KICKFIT SPORTS tại Câu lạc bộ, chủ thẻ chính và người đi kèm sẽ phải chịu trách nhiệm theo nội quy, quy định trong Hợp đồng và bản Định hướng và quyền lợi khách hàng (MOC).
                                    </li>
                                    <li className={"nav-item"}>Nếu trường hợp người đi kèm là trẻ em dưới 12 tuổi không được vào khu vực tập gym vì lý do an toàn và sẽ chỉ được vào các khu vực trẻ em có thể ra vào theo hướng dẫn của nhân viên tại Câu lạc bộ.
                                    </li>
                                    <li className={"nav-item"}>Người đi kèm không được hưởng các chế độ ưu đãi của chủ thẻ chính đã được ghi trên hợp đồng.
                                    </li>
                                </ul>










                                <div className="col-12 p-2 m-0">
                                    <p className="mb-0 fz14" >Hợp đồng này gồm {data.id_user_sub.length} người đi kèm cố định và tôi xác nhận tất cả thông tin người đi kèm cố định là chính xác.</p>
                                    <p className="mb-0 fz14" >Tôi xác nhận rằng tôi đã đọc, đã được giải thích, đã hiểu và đồng ý với tất cả những người đi kèm cố định trên.</p>

                                    <div className="height141 w-100 col-12 mt46">
                                        <p><i>Chữ ký chủ hợp đồng </i></p>

                                    </div>


                                </div>

                            </div>
                        </div>
                    ):(
                        <></>
                    )}




               </div>

            );
    }
        else{
            return(
                    <div className={"col-12 d-flex flex-row justify-content-center align-items-center pt-5 pb-5"}>
                        <ItemLoading size={"Large"} />
                    </div>
            );
        }
    }
}
