/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {_isset_string, array_to_select, base64_encode, timeConverterDate} from "../../helper/array";
import FingerAdmin from "../../global/finger/FingerAdmin";
import Floater from 'react-floater';
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        status:null
    },
    DataModal:{
        status:false,
        info:{},
    },
    page:0
};

export default class fingerprintadmin extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){

            var [key,value]=item;

            if(value!==null){
                query_send[key]=value;
            }

        });

        return query_send;
    }
    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"fingerprintadmin/getuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    GetFingerprint(data){

        this.setState({
            DataModal:{
                status:true,
                info:data
            }
        });

    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
    }



    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Họ và tên</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Email</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã NV</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Số vân tay</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Ngày cập nhật</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>{value['username']}</td>
                                    <td>{value['email']}</td>
                                    <td>
                                        <span>{value['code_user']}</span>
                                    </td>

                                    <td>
                                        {value['number_fingerprint']}
                                    </td>


                                    <td>
                                        {value['last_time']!==null?timeConverterDate(value['last_time']):(
                                            <span className={"badge font10 bg-primary"}>Chưa rõ</span>
                                        )}
                                    </td>


                                    <td>
                                        {value.visible===1?(
                                            <span>Kích hoạt</span>
                                        ):(
                                            <span>Không kích hoạt</span>
                                        )}
                                    </td>


                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.GetFingerprint(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 font13 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết Vân tay">
                                            <i className="fas fa-fingerprint" />
                                            </Floater>
                                        </a>

                                    </td>


                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>

                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Vân tay nhân viên</h3>
                            </div>

                            <div className="col-12 col-sm-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>





                                        <input onChange={(event)=>{
                                            var query=this.state.query;
                                            query.input_search=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} autoComplete={"off"} type="text"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Từ khóa tìm kiếm"/>

                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>




                                    </div>

                                </div>




                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>



                    </div>


                    {this.state.DataModal.status===true?(
                        <FingerAdmin onClose={()=>{
                            this.setState({
                                DataModal:{
                                    status:false,
                                    info:{},
                                }
                            });

                        }} AppThis={this.props.AppThis} path={"fingerprintadmin"} data={this.state.DataModal.info} />
                    ):(
                        <></>
                    )}




                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

}
