/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_array,
    array_to_select,
    base64_encode,
    in_module, timeConverter,
} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import EditProfileClub from "../../global/profile/EditProfileClub";
import InfoProfile from "../../global/profile/InfoProfile";
import EditModule from "../../global/EditModule";
import DetailTimeKeeping from "../../global/timekeeping/DetailTimeKeeping";
import Floater from 'react-floater';
import Select from "react-select";

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        status:null,
        position:null,
        address_id:null,
        group_id:null
    },
    DataModal:{
        status:false,
        data:{},
    },
    EditModule:{
        status:false,
        data:null
    },
    DataTimeKeeping:{
        status:false,
        data:null,
    },
    ProfileInfo:null,
    page:0,
    ListPosition:null,
    ListAddress:null,
    ListGroup:null
};

export default class mstaffclub extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;
        var query_send={};
        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mstaffclub", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    getPosition(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type', 2);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"position", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                var ListPosition=[
                    {
                        _id:null,
                        name:"Tất cả"
                    },
                    {
                        _id:'unknown',
                        name:"Chưa rõ"
                    }
                ];

                ListPosition=ListPosition.concat(data.data);

                self.setState({
                    ListPosition:ListPosition
                });
            }

        }).
        catch((e)=>{

        });


    }

    getAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type', 3);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                var ListAddress=data.data;

                ListAddress.unshift({
                    _id:null,
                    name:"Tất cả"
                });

                self.setState({
                    ListAddress:ListAddress
                });
            }

        }).
        catch((e)=>{

        });


    }

    getGroup(id_address){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', id_address);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='ok'){

                var ListGroup=[{
                    _id:null,
                    name:"Tất cả"
                },
                    {
                        _id:'unknown',
                        name:"Chưa rõ"
                    }
                ];

                ListGroup=ListGroup.concat(data.data);

                self.setState({
                    ListGroup:ListGroup
                });
            }

        }).
        catch((e)=>{

        });


    }

    async ExportDataAdmin(){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        var get_data=await fetch(Appthis.ConstApp.home_api+"mstaffclub/export", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác đã được gửi tới máy chủ");
        }

        Appthis.PageLoad(false);

    }


    AlertExport(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ExportDataAdmin();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getPosition();

        this.getAddress();
    }

    async DeleteId(id,type=1){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        formData.append('type',type);

        var get_status=await fetch(Appthis.ConstApp.home_api+"mstaffclub/delete", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Xoá dữ liệu thất bại");
        }
        else{
            toast("Xoá dữ liệu thành công");
        }

        this.setState({
            page:0
        });

        this.getData(true);

    }

    ActiveAdmin(id){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {

                                self.UpdateVisibleAdmin(id);

                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    async UpdateVisibleAdmin(id){
        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);


        Appthis.PageLoad(true);

        var get_status=await fetch(Appthis.ConstApp.home_api+"mstaffclub/active", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Cập nhật thất bại");
        }
        else{
            toast("Cập nhật thành công");
        }

        await this.getData(false);

        Appthis.PageLoad(false);

    }

    ResetPassword(id){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {

                                self.ActionResetPassWord(id);

                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    async ActionResetPassWord(id){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        Appthis.PageLoad(true);

        var get_status=await fetch(Appthis.ConstApp.home_api+"mstaffclub/resetpassword", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Cập nhật thất bại");
        }
        else{


            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Thông báo !</h1>
                            <p>Mật khẩu đã được cấp lại : {get_status['password']}</p>
                            <button onClick={onClose}>Đồng ý</button>
                        </div>
                    );
                }
            });

        }

        Appthis.PageLoad(false);


    }


    DeleteItem(id){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui contract-custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>

                        <div className={"row"}>
                            <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                <button onClick={onClose}>Hủy</button>
                            </div>

                            <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                <button
                                    onClick={() => {
                                        self.DeleteId(id,3);
                                        onClose();
                                    }}
                                > Cho Nghỉ việc
                                </button>
                            </div>

                            <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                <button
                                    onClick={() => {
                                        self.DeleteId(id,1);
                                        onClose();
                                    }}
                                > Ngừng kích hoạt
                                </button>

                            </div>

                        </div>






                    </div>
                );
            }
        });

    }

    OpenModal(data){

        var DataModal={
            status:true,
            data:data
        };
        this.setState({
            DataModal:DataModal
        });

    }

    ResetDataModal(){
        var Reset_DataModal={
            status:false,
            data:{},
        };

        this.setState({
            DataModal:Reset_DataModal
        })

    }

    SubmitStaff(data){

        const self=this;

        const Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());


        formData.append('data', data);

        fetch(Appthis.ConstApp.home_api+"mstaffclub/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{
            self.ResetDataModal();

            if(data.status==='error'){
                toast("Cập nhật dữ liệu thất bại");
                Appthis.PageLoad(false);
            }
            else{

                toast("Cập nhật tài khoản thành công");

                self.getData(true);
            }


        }).
        catch((e)=>{

            self.ResetDataModal();

            toast("Cập nhật dữ liệu thất bại");

            Appthis.PageLoad(false);


        });


    }

    SubmitModule(data){


        const self=this;

        const Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id', this.state.EditModule.data._id);

        formData.append('data', data);

        fetch(Appthis.ConstApp.home_api+"mstaffclub/setmodule", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            self.setState({
                EditModule:{
                    status:false,
                    data:null
                }
            });

            if(data.status==='error'){
                toast("Cập nhật dữ liệu thất bại");
                Appthis.PageLoad(false);
            }
            else{

                toast("Cập nhật tài khoản thành công");

                self.getData(true);
            }


        }).
        catch((e)=>{

            self.setState({
                EditModule:{
                    status:false,
                    data:null
                }
            });

            toast("Cập nhật dữ liệu thất bại");

            Appthis.PageLoad(false);


        });

    }



    async ConvertAccount(id){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('_id', id);

        var get_data=await fetch(Appthis.ConstApp.home_api+"mstaffclub/convert", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");

            await this.getData();
        }

        Appthis.PageLoad(false);


    }

    AlertConvertAccount(data){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ConvertAccount(data._id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }


    thisRenderData(){

        const self=this;

        var AppThis=this.props.AppThis;

        if(this.state.data.data.length>0){

            return(
              <div className={"col-12 box-table-fix box-table-w380"}>
                  <div className={"col-12 overflow-auto"}>
                      <table className={"mt-3 w-100 table mw1200 border-0 table-style-old"}>
                          <thead>
                          <tr className={"border-bottom bg-light"}>
                              <td className={"font-weight-bold text-uppercase font14 p-2"}>Họ và tên</td>
                              <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã NV</td>
                              <td className={"font-weight-bold text-uppercase font14 p-2"}>Số ĐT</td>
                              <td className={"font-weight-bold text-uppercase font14 p-2"}>Chức vụ</td>
                              <td className={"font-weight-bold text-uppercase font14 p-2 w200px"}>Team</td>
                              <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                              <td className={"font-weight-bold text-uppercase font14 p-2"}>Truy cập</td>
                              <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                          </tr>
                          </thead>

                          <tbody>

                          {this.state.data.data.map(function(value,index){
                              return(
                                  <tr key={value._id}>
                                      <td>{value['username']}</td>
                                      <td>
                                          <span>{value['code_user']}</span>
                                      </td>
                                      <td>{value['phone_number']}</td>

                                      <td>
                                          <span>{value['position']!=null?value['position'].name:'Chưa rõ'}</span>
                                      </td>
                                      <td>
                                          {value['group_id']!==null && value['group_id'].length>0 && _isset_array(value['group_id'])?(
                                              value['group_id'].map(function(item){
                                                  return(
                                                      <span className={"me-1"}>{item.name} , </span>
                                                  );
                                              })
                                          ):(
                                              <span className={"text-red font-weight-bold"}>Chưa rõ</span>
                                          )}

                                      </td>


                                      <td>
                                          {value.visible===1?(
                                              <span>Kích hoạt</span>
                                          ):(
                                              <span>
                                                {value.visible===0?("chưa kích hoạt"):("Đã nghỉ việc")}
                                            </span>
                                          )}


                                      </td>


                                      <td>
                                          {value['time_ping']!==null?timeConverter(value['time_ping']):""}
                                      </td>
                                      {value.visible===1?(
                                          <td className={"fix-item-table"}>

                                              <a onClick={()=>{
                                                  self.ResetPassword(value['_id'])
                                              }} className="rounded-0 d-inline-block me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cấp lại Mật khẩu">
                                                      <i className="fas fa-unlock-alt" />
                                                  </Floater>
                                              </a>


                                              <a onClick={()=>{

                                                  self.setState({
                                                      EditModule:{
                                                          status:true,
                                                          data:value
                                                      }
                                                  });

                                              }} className="rounded-0 d-inline-block me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật quyền quản trị">
                                                      <i className="fas fa-charging-station" />
                                                  </Floater>
                                              </a>


                                              <a onClick={()=>{

                                                  self.setState({
                                                      ProfileInfo:value
                                                  });
                                              }} className="rounded-0 d-inline-block me-1 btn btn-outline-secondary p-1 ps-2 pe-2">
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Thông tin tài khoản">
                                                      <i className="far fa-user" />
                                                  </Floater>
                                              </a>


                                              {in_module('employeetimekeeping',AppThis.GetInfoProfile().module)===true?(
                                                  <a onClick={()=>{
                                                      self.setState({
                                                          DataTimeKeeping:{
                                                              status:true,
                                                              data:value
                                                          }
                                                      });

                                                  }} className="rounded-0 d-inline-block me-1 btn btn-outline-secondary p-1 ps-2 pe-2">
                                                      <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lịch sử chấm công">
                                                          <i className="far fa-calendar-alt" />
                                                      </Floater>
                                                  </a>
                                              ):(<></>)}



                                              <a onClick={()=>{
                                                  self.OpenModal(value);
                                              }} className="rounded-0 d-inline-block me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Sửa tài khoản">
                                                      <i className="fas font12 fa-edit" />
                                                  </Floater>
                                              </a>


                                              <a onClick={()=>{
                                                  self.AlertConvertAccount(value);
                                              }} className="rounded-0 d-inline-block me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chuyển đổi loại tài khoản">
                                                      <i className="fas fa-exchange-alt" />
                                                  </Floater>
                                              </a>


                                              <a onClick={()=>{
                                                  self.DeleteItem(value['_id'])
                                              }} className="rounded-0 d-inline-block me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Ngừng kích hoạt tài khoản">
                                                      <i className="fas fa-minus" />
                                                  </Floater>
                                              </a>

                                          </td>
                                      ):(
                                          <td className={"fix-item-table"}>


                                              <a onClick={()=>{
                                                  self.ActiveAdmin(value['_id'])
                                              }} className="rounded-0 d-inline-block me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Kích hoạt tài khoản">
                                                      <i className="fas fa-check-circle" />
                                                  </Floater>
                                              </a>

                                          </td>
                                      )}


                                  </tr>
                              );
                          })}
                          </tbody>
                      </table>
                  </div>
              </div>

            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>

                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Tài khoản Club</h3>
                            </div>

                            <div className="col-12 mb-2 text-total-row text-end">
                                Tổng bản ghi : {this.state.data.total_row}

                            </div>


                            <div className={"col-12"}>
                                <button onClick={()=>{
                                    this.setState({
                                        ModalSearchMobile:true
                                    });
                                }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                            </div>

                           <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                   <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                      <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                          <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                          <a onClick={()=>{
                                              this.setState({
                                                  ModalSearchMobile:false
                                              });
                                          }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                              <i className="fas fa-times"></i>
                                          </a>
                                      </div>

                                       <input onChange={(event)=>{
                                           var query=this.state.query;
                                           query.input_search=event.target.value;
                                           this.setState({
                                               query:query
                                           });

                                       }} autoComplete={"off"} type="text"
                                              className="font13 form-control d-inline-block w-auto rounded-0"
                                              placeholder="Từ khóa tìm kiếm"/>

                                       <select onChange={(event)=>{
                                           var query=this.state.query;
                                           query.status=parseInt(event.target.value);
                                           this.setState({
                                               query:query
                                           });
                                       }} className="mr-2 font13 rounded-0 w-auto form-control">
                                           <option value="2">Kích hoạt</option>
                                           <option value="1">Chưa Kích hoạt</option>
                                           <option value="3">Đã nghỉ việc</option>
                                       </select>

                                       <div className={"Box-Select-Mutiple w200px text-start"}>
                                           {this.state.ListPosition!==null?(

                                               <Select isSearchable={true} placeholder={"Chức vụ"} onChange={(selectedOption)=>{

                                                   var query=self.state.query;

                                                   query['position']=selectedOption['value'];

                                                   self.setState({
                                                       query:query
                                                   });

                                               }} options={array_to_select(this.state.ListPosition,'name','_id')} />
                                           ):(
                                               <ItemLoading />
                                           )}
                                       </div>


                                       <div className={"Box-Select-Mutiple w200px text-start"}>
                                           {this.state.ListAddress!==null?(

                                               <Select isSearchable={true} placeholder={"Cơ sở"} onChange={(selectedOption)=>{

                                                   var query=self.state.query;

                                                   query['address_id']=selectedOption['value'];

                                                   self.setState({
                                                       query:query
                                                   },function(){
                                                       self.getGroup(selectedOption['value']);
                                                   });

                                               }} options={array_to_select(this.state.ListAddress,'name','_id')} />
                                           ):(
                                               <ItemLoading />
                                           )}
                                       </div>


                                       {this.state.ListGroup!==null?(
                                           <div className={"Box-Select-Mutiple w200px text-start"}>

                                                   <Select isSearchable={true} placeholder={"Team"} onChange={(selectedOption)=>{

                                                       var query=self.state.query;

                                                       query['group_id']=selectedOption['value'];

                                                       self.setState({
                                                           query:query
                                                       });

                                                   }} options={array_to_select(this.state.ListGroup,'name','_id')} />

                                           </div>

                                       ):(
                                           <></>
                                       )}


                                       <a onClick={()=>{
                                           this.getData(true);
                                           this.setState({
                                               ModalSearchMobile:false
                                           });
                                       }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                           <i className="fas fa-search" />Tìm kiếm </a>

                                       <a onClick={()=>{
                                           this.setState({
                                               DataModal:{
                                                   status:true,
                                                   data:{}
                                               }
                                           });
                                       }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>


                                       <a onClick={()=>{
                                           this.AlertExport();
                                           this.setState({
                                               ModalSearchMobile:false
                                           });
                                       }} className="ibtn btn rounded-0 btn-outline-primary font13 font-weight-600">
                                           <i className="fas fa-cloud-download-alt" />
                                           Xuất dữ liệu
                                       </a>

                                   </div>

                           </div>


                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>



                    </div>

                    {this.state.DataModal.status===true?(
                        <EditProfileClub onClose={()=>{
                            this.setState({
                                DataModal:{
                                    status:false,
                                    data:{},
                                }
                            });

                        }} AppThis={this.props.AppThis} GetDataSubmit={(data)=>{
                            var encode_data=base64_encode(data);
                            this.SubmitStaff(encode_data);
                        }} data={this.state.DataModal.data} />
                    ):(<></>)}


                    {this.state.ProfileInfo!==null?(
                        <InfoProfile onClose={()=>{
                            this.setState({
                                ProfileInfo:null
                            });
                        }} data={this.state.ProfileInfo} />
                    ):(<></>)}


                    {this.state.EditModule.status!==false?(
                        <EditModule path={"mstaffclub/module"} AppThis={this.props.AppThis} onClose={()=>{
                            this.setState({
                                EditModule:{
                                    status:false,
                                    data:null
                                }
                            });
                        }} GetDataSubmit={(data)=>{
                            var encode_data=base64_encode(data);
                            this.SubmitModule(encode_data);

                        }} data={this.state.EditModule.data} />
                    ):(<></>)}



                    {this.state.DataTimeKeeping.status===true?(
                        <DetailTimeKeeping onClose={()=>{
                            this.setState({
                                DataTimeKeeping:{
                                    status:false,
                                    data:null,
                                }
                            });
                        }} AppThis={this.props.AppThis} path={"employeetimekeeping"} data={this.state.DataTimeKeeping.data}  />
                    ):(<></>)}


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

}
