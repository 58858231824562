/* eslint-disable */
import React,{Component} from "react";
import logo from "../assets/images/logo.png";
import ItemLoading from "./ItemLoading";
import {_isset_string} from "../helper/array";
import parse from 'html-react-parser';
import no_avatar from "../assets/images/_no_avatar.png";
import ModuleRegister from "../ModuleRegister";

export default class Sidebar extends Component{


    verifyModule(path){

        var response=false;

        Object.entries(ModuleRegister).map(function(item){
            var [key,value]=item;

            value.map(function(val){
                if(val.name===path){
                    response=true;
                }
            });


        });

        return response;
    }
    LoadSubmodule(item){

        const AppThis=this.props.AppThis;
        const self=this;
        return item.map(function(value,key){
            if(self.verifyModule(value.path)===true){
                return(
                    <div key={value.path+key} className={"item-action-menu"}>
                        <a onClick={()=>{
                            AppThis.SetUrlRedirect(value.path);
                        }} className={"/"+value.path===window.location.pathname?"text-red font-weight-bold":""} href={"javascript:void(0)"}>
                            {parse(value['title'])}
                        </a>
                    </div>
                );
            }

        });
    }

    LoadModule(module){

        const self=this;

        return Object.entries(module).map(function(item,index){
            return(
                <div key={index+"module"} className={"col-12 list-item-module"}>
                    <div className={"w-100 header-item-menu mb-2 text-uppercase font-weight-bold font15"}>
                        {parse(item[0])}
                    </div>
                    {self.LoadSubmodule(item[1])}
                </div>
            );
        });
    }
    render() {
        var AppThis=this.props.AppThis;

        if(AppThis.state.OpenMenu===false){
            return(<></>);
        }
        else{
            return(
                <div className={'Menu-Action position-fixed shadow bg-white h-100'}>

                    <div className={"box-content-menu"}>

                        <div className={"col-12 ps-2 pe-2"}>
                            <a href={"/dashboard"} className={"d-block text-center logo-sidebar"}>
                                <img src={logo} />
                            </a>

                            {AppThis.GetInfoProfile()!==null?(
                                <div className={"col-12 mt-4 d-flex info-user-admin"}>

                                    <div onClick={()=>{
                                        AppThis.SetUrlRedirect("/profile");
                                    }} className={"left me-4"}>

                                        {_isset_string(AppThis.GetInfoProfile().avatar)?(
                                            <div className={"box-avatar-50"}>
                                                <img src={AppThis.ConstApp.home_url+AppThis.GetInfoProfile().avatar} />
                                            </div>
                                        ):(
                                            <div className={"box-avatar-50"}>
                                                <img src={no_avatar} />
                                            </div>
                                        )}

                                    </div>

                                    <div onClick={()=>{
                                            AppThis.SetUrlRedirect("/profile");
                                    }} className={"right"}>
                                        <p className={"mb-0 font-weight-bold"}>Xin Chào : {AppThis.GetInfoProfile().username}</p>
                                        <p className="mb-0"><i className="icon-online d-inline-block me-2 rounded-circle" /> Online</p>
                                        <div className="alert alert-primary p-1 ps-3 font13 pe-3 mt-2">Mã nhân viên : <p className={"d-inline-block m-0"}>
                                            {AppThis.GetInfoProfile().code_user}
                                        </p></div>
                                    </div>

                                </div>
                            ):(
                                <div className={"d-flex align-content-center justify-content-center mt-3 mb-3"}>
                                    <ItemLoading />
                                </div>
                            )}
                        </div>

                        {AppThis.GetInfoProfile()!==null?(
                            <div className="col-12 p-0 pt-3">
                                {this.LoadModule(AppThis.GetInfoProfile().module)}
                            </div>
                        ):(
                            <></>
                        )}


                        <div key={"action-profile-module"} className={"col-12 list-item-module"}>
                            <div className={"w-100 header-item-menu mb-2 text-uppercase font-weight-bold font15"}>
                               Tài khoản
                            </div>

                            <div key={"action-profile-module-account"} className={"item-action-menu"}>
                                <a onClick={()=>{
                                    AppThis.SetUrlRedirect("/profile");
                                }} className={"/profile"===window.location.pathname?"text-red font-weight-bold":""} href={"javascript:void(0)"}>
                                   <i className={"fas fa-user-edit"}></i> Tài khoản
                                </a>
                            </div>


                            <div key={"action-profile-module-account-logout"} className={"item-action-menu"}>
                                <a onClick={()=>{
                                    AppThis.SetUrlRedirect("/login");
                                }} href={"javascript:void(0)"}>
                                    <i className={"fas fa-sign-out-alt"}></i>  Đăng xuất
                                </a>
                            </div>



                        </div>



                    </div>


                    <div onClick={()=>{
                        AppThis.setState({
                            OpenMenu:false
                        });
                    }} className={"bg-menu-action"}></div>
                </div>
            );
        }



    }
}
