/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {_isset_string, array_to_select, base64_encode} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import EditModal from "../../global/EditModal";
import Floater from 'react-floater';
import Select from "react-select";
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
    },
    DataModal:{
        status:false,
        title:null,
        data:null,
        _id:null
    },
    DataPayment:{
      status:false,
      data:null,
        info:null
    },
    OpenModalBank:{
        status:false,
        title:null,
        data:null,
        _id:null
    },
    page:0,
};

export default class payment extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getBank(data){

      this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('_id', data._id);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"payment/detail", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).
        then(response=>response.json()).
        then(response=>{

            if(response.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    DataPayment:{
                        status:true,
                        data:response.data,
                        info:data
                    }
                });
            }
                Appthis.PageLoad(false);


        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");

          Appthis.PageLoad(false);

        });

    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"payment", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).
        then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
    }


    OpenModal(data=null){

        var DataModal={
            status:true,
            title:'Quản lý phương thức TT',
            data:[
                {
                    title:"Tên phương thức TT",
                    value:null,
                    name:"name",
                    type:"input"
                },
                {
                    title:"Phân loại",
                    value:1,
                    name:"type",
                    type:"select",
                    data:[
                        {
                            value:"Offline",
                            key:1
                        },
                        {
                            value:"Online",
                            key:2
                        }
                    ]
                },
            ],
            _id:null
        };

        if(data!==null){
             DataModal={
                status:true,
                title:'Quản lý phương thức TT',
                data:[
                    {
                        title:"Tên phương thức TT",
                        value:data.name,
                        name:"name",
                        type:"input"
                    },
                    {
                        title:"Phân loại",
                        value:parseInt(data.type),
                        name:"type",
                        type:"select",
                        data:[
                            {
                                value:"Offline",
                                key:1
                            },
                            {
                                value:"Online",
                                key:2
                            }
                        ]
                    },
                ],
                _id:data._id
            }
        }

        this.setState({
            DataModal:DataModal
        });

    }

    ResetDataModal(){
        var Reset_DataModal={
            status:false,
            title:null,
            data:null,
            _id:null
        };

        this.setState({
            DataModal:Reset_DataModal
        })

    }

    async UpdateModal(response){

        var DataModal=this.state.DataModal;

        var Appthis= this.props.AppThis;

        Appthis.PageLoad(true);


        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var url_post=Appthis.ConstApp.home_api+"payment/update";

        var data_submit={};

        if(this.state.DataModal._id!==null){
            data_submit._id=this.state.DataModal._id;
        }

        data_submit.name=response.name;

        data_submit.type=parseInt(response.type);


        formData.append("data",base64_encode(data_submit));

        var get_data=await fetch(url_post, {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Cập nhật dữ liệu thất bại");
        }
        else{
            toast("Cập nhật dữ liệu thành công");
        }


        this.ResetDataModal();

        await this.getData(false);

        Appthis.PageLoad(false);



    }

    InActive(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn ngừng kích hoạt</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ItemEvent(id,'inactive');
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }


    Active(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn kích hoạt lại ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ItemEvent(id,'active');
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    async ItemEvent(id,status){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        var get_status=await fetch(Appthis.ConstApp.home_api+"payment/"+status, {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");
        }

        this.getData(true);
    }




    InActiveBank(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn ngừng kích hoạt</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ItemEventBank(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }


    ActiveBank(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn kích hoạt lại ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ItemEventBank(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    async ItemEventBank(id){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        var get_status=await fetch(Appthis.ConstApp.home_api+"payment/bank", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");
        }

        this.getBank(this.state.DataPayment.info);
    }



    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>STT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên phương thức</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Phân loại</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Số TK</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        {index+1}
                                    </td>
                                    <td>{value['name']}</td>

                                    <td>{value['type']===2?(
                                        <p className={"badge bg-primary font12 p-1 m-0"}>Online</p>
                                    ):(
                                        <p className={"badge bg-dark font12 p-1 m-0"}>Offline</p>
                                    )}</td>
                                    <td>
                                        {value['visible']===1?(
                                            <span className={"badge bg-primary p-1"}>Active</span>
                                        ):(
                                            <span className={"badge bg-danger p-1"}>Inactive</span>
                                        )}
                                    </td>

                                    <td>
                                        {value['count_bank']}
                                    </td>
                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.OpenModal(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật thanh toán">
                                            <i className="fas font12 fa-edit" />
                                            </Floater>
                                        </a>


                                        {value.visible===1?(
                                            <a onClick={()=>{
                                                self.InActive(value._id);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Ngừng kích hoạt">
                                                <i className="fas fa-minus" />
                                                </Floater>
                                            </a>
                                        ):(
                                            <a onClick={()=>{
                                                self.Active(value._id);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Kích hoạt">
                                                <i className="far fa-check-circle" />
                                                </Floater>
                                            </a>
                                        )}


                                        {value['type']===2?(
                                            <a onClick={()=>{
                                                self.getBank(value)
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Tài khoản thanh toán">
                                                <i className="fas fa-money-check-alt" />
                                                </Floater>
                                            </a>
                                        ):(<></>)}


                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    RenderPayment(){
        const self=this;
        return(
            <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                <div className="row m-0 pt-3">

                    <div className="col-12 col-sm-12 d-flex align-items-center">
                        <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý phương thức TT</h3>
                    </div>

                    <div className="col-12 col-sm-12 text-right text-end">

                        <div className="col-12 p-0 mb-2">
                            Tổng bản ghi : {this.state.data.total_row}
                        </div>


                        <div className={"col-12"}>
                            <button onClick={()=>{
                                this.setState({
                                    ModalSearchMobile:true
                                });
                            }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                        </div>


                        <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                            <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                    <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                    <a onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:false
                                        });
                                    }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                        <i className="fas fa-times"></i>
                                    </a>
                                </div>




                                <input onChange={(event)=>{
                                    var query=this.state.query;
                                    query.input_search=event.target.value;
                                    this.setState({
                                        query:query
                                    });

                                }} autoComplete={"off"} type="text" name="input_search"
                                       className="font13 form-control d-inline-block w-auto rounded-0"
                                       placeholder="Từ khóa tìm kiếm"/>

                                <a onClick={()=>{
                                    this.getData(true);
                                    this.setState({
                                        ModalSearchMobile:false
                                    });
                                }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                    <i className="fas fa-search" />Tìm kiếm </a>

                                <a onClick={()=>{
                                    this.OpenModal(null);
                                    this.setState({
                                        ModalSearchMobile:false
                                    });
                                }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>



                            </div>

                        </div>





                    </div>

                </div>


                <div className={"col-12 ps-3 pe-3"}>
                    {this.thisRenderData()}
                </div>


                <div className={"col-12 ps-3 pe-3"}>
                    <ul className="pagination">
                        {this.state.data.prev>=0?(
                            <li className="page-item" >
                                <a className="page-link" onClick={()=>{
                                    var page=this.state.page-1;
                                    this.setState({
                                        page:page
                                    },function(){
                                        self.getData(true);
                                    });
                                }}>
                                    Trước
                                </a>
                            </li>
                        ):(<></>)}

                        <li className="page-item">
                            <a className="page-link">
                                <span>{this.state.data.curent+1}</span>
                            </a></li>

                        {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                            <li className="page-item">
                                <a className="page-link" onClick={()=>{
                                    this.setState({
                                        page:this.state.data.next
                                    },function(){
                                        self.getData(true);
                                    });
                                }}>
                                    Kế tiếp
                                </a>
                            </li>
                        ):(<></>)}

                    </ul>
                </div>


                {this.state.DataModal.status===true?(
                    <EditModal size={"small"} onClose={()=>{
                        this.ResetDataModal();
                    }} GetDataSubmit={(response,required)=>{

                        if(required===true){
                            self.UpdateModal(response);
                        }
                        else{
                            toast("Vui lòng nhập đầy đủ thông tin");
                        }
                    }} title={this.state.DataModal.title} data={this.state.DataModal.data} />
                ):(<></>)}





            </div>
        );
    }

    render(){
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    {this.state.DataPayment.status===false?(
                        this.RenderPayment()
                    ):(
                        this.RenderBank()
                    )}

                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    RenderBank(){

        const self=this;


        return(
            <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                <div className="row m-0">

                    <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                        <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý tài khoản ngân hàng :

                            {this.state.DataPayment.info.name}
                        </h3>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 text-right text-end">

                        <div className="col-12 p-0 mb-2">
                            Tổng bản ghi : {this.state.DataPayment.data.total_row}
                        </div>

                        <div className="d-inline-flex flex-row">

                            <a onClick={()=>{
                               this.setState({
                                   DataPayment:{
                                       status:false,
                                       data:null,
                                       info:null
                                   }
                               });

                            }} className="me-2 ibtn btn rounded-0 btn-outline-primary font13 font-weight-600">
                                <i className="fas fa-angle-double-left" />
                                Trở về</a>

                            <a onClick={()=>{
                                this.EventOpenModalBank(null);
                            }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>


                        </div>

                    </div>

                </div>

                <div className={"col-12 ps-3 pe-3"}>

                <table className={"mt-3 w-100 table border-0"}>
                    <thead>
                    <tr className={"border-bottom bg-light"}>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên Ngân hàng</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>Chủ Tk</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>Số TK</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>Thao tác</td>
                    </tr>
                    </thead>

                    <tbody>

                    {this.state.DataPayment.data.data.map(function(value,index){
                        return(
                            <tr>

                                <td>{value['name']}</td>
                                <td>{value['account']}</td>
                                <td>{value['user_account']}</td>

                                <td>
                                    {value['visible']===1?(
                                        <span className={"badge bg-primary p-1"}>Active</span>
                                    ):(
                                        <span className={"badge bg-danger p-1"}>Inactive</span>
                                    )}
                                </td>

                                <td>


                                    <a onClick={()=>{
                                        self.EventOpenModalBank(value);
                                    }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                        <i className="fas font12 fa-edit" />
                                    </a>


                                    {value.visible===1?(
                                        <a onClick={()=>{
                                            self.InActiveBank(value._id);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                            <i className="fas fa-minus" />
                                        </a>
                                    ):(
                                        <a onClick={()=>{
                                            self.ActiveBank(value._id);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <i className="far fa-check-circle" />
                                        </a>
                                    )}



                                </td>


                            </tr>
                        );
                    })}
                    </tbody>
                </table>

                </div>


                {this.state.OpenModalBank.status===true?(
                    <EditModal size={"small"} onClose={()=>{

                        this.setState({
                            OpenModalBank:{
                                status:false,
                                title:null,
                                data:null,
                                _id:null
                            }
                        });

                    }} GetDataSubmit={(response,required)=>{

                        if(required===true){
                            self.UpdateModalBank(response);
                        }
                        else{
                            toast("Vui lòng nhập đầy đủ thông tin");
                        }
                    }} title={this.state.OpenModalBank.title} data={this.state.OpenModalBank.data} />
                ):(<></>)}


            </div>
    );

    }


    EventOpenModalBank(data){

        if(data!==null){
            var OpenModalBank={
                status:true,
                title:'Quản lý tài khoản ngân hàng',
                data:[
                    {
                        title:"Tên tài khoản",
                        value:data.name,
                        name:"name",
                        type:"input"
                    },
                    {
                        title:"Chủ Tài khoản",
                        value:data.account,
                        name:"account",
                        type:"input"
                    },
                    {
                        title:"Số tài khoản",
                        value:data.user_account,
                        name:"user_account",
                        type:"input"
                    },
                ],
                _id:data._id
            }
        }
        else{
            var OpenModalBank={
                status:true,
                title:'Quản lý tài khoản ngân hàng',
                data:[
                    {
                        title:"Tên tài khoản",
                        value:null,
                        name:"name",
                        type:"input"
                    },
                    {
                        title:"Chủ Tài khoản",
                        value:null,
                        name:"account",
                        type:"input"
                    },
                    {
                        title:"Số tài khoản",
                        value:null,
                        name:"user_account",
                        type:"input"
                    },
                ],
                _id:null
            };
        }

        this.setState({
            OpenModalBank:OpenModalBank
        });

    }

    async UpdateModalBank(response){

        var Appthis= this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var url_post=Appthis.ConstApp.home_api+"payment/bankupdate";

        var data_submit={};

        if(this.state.OpenModalBank._id!==null){
            data_submit._id=this.state.OpenModalBank._id;
        }

        data_submit.name=response.name;
        data_submit.account=response.account;
        data_submit.user_account=response.user_account;
        data_submit.id_payment=this.state.DataPayment.info._id;

        formData.append("data",base64_encode(data_submit));

        var get_data=await fetch(url_post, {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Cập nhật dữ liệu thất bại");
        }
        else{
            toast("Cập nhật dữ liệu thành công");
        }


        this.setState({
            OpenModalBank:{
                status:false,
                title:null,
                data:null,
                _id:null
            }
        });

        await this.getBank(this.state.DataPayment.info);

        Appthis.PageLoad(false);



    }


}
