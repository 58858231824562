/* eslint-disable */
import React,{Component} from "react";
import "../App.css";

export default class ItemLoading extends Component{

    render() {


        return(
            <div className={this.props.size?"Item_Loading_"+this.props.size:"Item_Loading"} />
        );


    }
}
