/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../ItemLoading";
import {
    base64_encode, timeConverterDate,
} from "../../../helper/array";
import lodash from "lodash";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";

const INIT_STATE={
    protection:{
        _id:null,
        user_login:null,
        relationship:null
    },
    ContractData:null,
    Loadding:true,
    ListRelationship:null,
    StatusEdit:false
};

export default class EditProtection extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
        this.RefComponent=React.createRef();
    }
    componentDidMount() {
        this.GetRelationship();

        if(typeof this.props.data!=='undefined'){
            this.UpdateDataMember(this.props.data);
        }

    }

    GetRelationship(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"gtypegroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            var ListRelationship=[];

            if(data.status==='ok'){
                ListRelationship=data.data;
            }

            self.setState({
                ListRelationship:ListRelationship
            });


        }).catch((e)=>{

        });
    }

    UpdateDataMember(data){

        const self=this;

        this.setState({
            ContractData:data
        },function(){

            if(data.protector!==null){
                self.getInfoUser(data.protector.user_login);
            }
            else{
                self.setState({
                    Loadding:false,
                    protection:{
                        _id:null,
                        user_login:null,
                        relationship:null
                    }
                });
            }
        });

    }

    async getInfoUser(phone){

        var get_data=await this.fetchUser(phone);

        const self=this;

        if(Object.keys(get_data.data).length>0 && get_data.status==='ok'){

            var protection=get_data.data;

            this.setState({
                protection:protection
            },function(){
                self.getRelationshipUser(protection._id);
            });
        }
        else{

            self.setState({
                Loadding:false,
                protection:{
                    _id:null,
                    user_login:null,
                    relationship:null
                }
            });

        }

    }

    async fetchUser(phone){

        var Appthis=this.props.AppThis;

        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/infouser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        data=await data.json();

        return data;

    }

    async getRelationshipUser(id){

        var Appthis=this.props.AppThis;

        var users=[id,this.state.ContractData.id_user._id];

        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('users', base64_encode(users));

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/getrelationship", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        var getRelationship=await data.json();

        Appthis.PageLoad(false);

        var protection=this.state.protection;

        if(getRelationship['status']==='ok' && Object.keys(getRelationship['data']).length>0){

            protection['relationship']=getRelationship['data']['_id'];

            this.setState({
                protection:protection,
                Loadding:false
            });

        }
        else{
            protection['relationship']=null;
            this.setState({
                protection:protection,
                Loadding:false
            });
        }

    }


    UpdateItemData(key,value){

        var user=this.state.protection;
        user[key]=value;

        this.setState({
            protection:user
        });

    }

    async UpdateProtection(){

        var get_user=this.state.protection;

        var number_error=0;

        ['user_login','username','relationship'].map(function(item,index){

            if(typeof get_user[item]==='undefined' || get_user[item]===null || get_user[item].length<1){
                number_error+=1;
            }
            else{
                if(item==='user_login'){
                    if (get_user[item].length!==10){
                        number_error+=1;
                    }

                }
            }
        });


        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var update_protection={};

            ['user_login','email','sex','username','birthday','address_contact','citizen_identification','relationship'].map(function(item){
                if(typeof get_user[item]!=='undefined'){
                    update_protection[item]=get_user[item];
                }
            });

            const DataSubmit= {
                protection:update_protection
            };

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_user', this.state.ContractData.id_user._id);

            formData.append('data',base64_encode(DataSubmit));

            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/setrelationship", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();

            if(get_data.status==='error'){
                toast("Thao tác thất bại");

                Appthis.PageLoad(false);

                this.setState({
                    StatusEdit:false
                });
            }
            else{

                if (typeof get_data.data.protection!=='undefined'){
                    var id_user_protection=get_data.data.protection;
                    this.UpdateProtectionContract(id_user_protection);
                }
            }
        }

    }

    async UpdateUserProtectionInput(input){

        var Appthis=this.props.AppThis;

        var protectionUpdate=this.state.protection!==null?this.state.protection:{};

        protectionUpdate.user_login=input;

        this.setState({
            protection:protectionUpdate
        });


        if(input.length===10){

            Appthis.PageLoad(true);

            var get_info=await this.fetchUser(input);

            if(get_info['status']==='ok' && Object.keys(get_info['data']).length>0){

                var protection=get_info['data'];

                protection['relationship']=null;

                this.setState({
                    protection:protection
                });

                await this.getRelationshipUser(protection._id);


            }
            else{
                this.setState({
                    protection:{
                        _id:null,
                        user_login:input,
                        relationship:null
                    }
                });

                Appthis.PageLoad(false);

            }

        }

    }

    async UpdateProtectionContract(id_user=null){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        var data_update={
            _id:this.state.ContractData._id,
            id_user:this.state.ContractData.id_user._id,
            id_protection:id_user
        };

        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('data', base64_encode(data_update));
        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/protection", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        var getStatus=await data.json();

        if(getStatus['status']==='ok'){
            toast("Cập nhật thành công");
        }
        else{
            toast("Có lỗi xin vui lòng thử lại sau");
            Appthis.PageLoad(false);
            this.setState({
                StatusEdit:false,
                Loadding:false
            });
        }

        if(typeof this.props.onClose!=='undefined'){
            this.props.onClose();
        }

    }

    render() {

        const self=this;

        if(this.state.Loadding===true){

            return(
                <div className={"col-12 mt-5 d-flex flex-row justify-content-center align-items-center"}>
                    <ItemLoading />
                </div>
            );
        }
        else{

            return(
                <div className={"col-12 col-sm-12"}>

                    <h4 className="d-inline-block mb-3 font16 mt-3 text-uppercase font-weight-bold text-dark border-primary border-bottom pb-2">
                        Người liên lạc khẩn cấp
                    </h4>

                    {this.state.StatusEdit===true?(
                        <div className={"row mt-4"}>

                            <div className={"col-12 col-sm-12 col-md-6 mb-3 box_input"}>
                                <p className={"m-0"}>SDT/ID : <span className={"text-red"}>( * )</span></p>

                                <InputMask mask="9999999999" onChange={event=>{
                                    var input=event.target.value;
                                    input=input.replaceAll("_","");
                                    this.UpdateUserProtectionInput(input);

                                }} value={this.state.protection.user_login!==null?this.state.protection.user_login:''} placeholder={"Số điện thoại"} />

                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                                <p className={"m-0"}>Họ và tên : <span className={"text-red"}>( * )</span></p>
                                <input type={"text"} onChange={(text)=>{
                                    this.UpdateItemData("username",text.target.value);
                                }} value={typeof this.state.protection.username!=='undefined'?this.state.protection.username:''} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                                <p className={"m-0"}>Email : </p>
                                <input onChange={(text)=>{
                                    this.UpdateItemData("email",text.target.value);
                                }} type={"email"} value={typeof this.state.protection.email!=='undefined'?this.state.protection.email:''} className={"form-control rounded-0 w-100"} placeholder={"Email"} />
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 mb-3 box_input"}>
                                <p className={"m-0"}>Sinh nhật </p>

                                {typeof this.state.protection.birthday!=='undefined'?(
                                    <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat="dd/MM/yyyy" selected={new Date(this.state.protection.birthday*1000)} onChange={(date) => {
                                        if(date!==null){
                                            this.UpdateItemData('birthday',date.getTime()/1000);
                                        }

                                    }} placeholderText={"Sinh nhật"} />
                                ):(
                                    <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        if(date!==null){
                                            this.UpdateItemData('birthday',date.getTime()/1000);
                                        }
                                    }} placeholderText={"Sinh nhật"} />
                                )}

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                                <p className={"m-0"}>Địa chỉ </p>
                                <input onChange={(text)=>{
                                    this.UpdateItemData("address_contact",text.target.value);
                                }} type={"text"} value={typeof this.state.protection.address_contact!=='undefined'?this.state.protection.address_contact:""} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                                <p className={"m-0"}>Số căn cước :
                                </p>
                                <input onChange={(text)=>{
                                    this.UpdateItemData("citizen_identification",text.target.value);
                                }} type={"text"} value={typeof this.state.protection.citizen_identification!=='undefined'?this.state.protection.citizen_identification:""} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                                <p>Giới tính </p>

                                {typeof this.state.protection.sex!=='undefined' && this.state.protection.sex===1?(
                                    <div className={"d-flex flex-row flex-wrap"}>
                                        <label className={"me-2"}><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",1);
                                        }} checked={true} type={"radio"} /> Nam</label>
                                        <label><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",0);
                                        }} type={"radio"} /> Nữ</label>
                                    </div>
                                ):(
                                    <div className={"d-flex flex-row flex-wrap"}>
                                        <label className={"me-2"}><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",1);
                                        }} type={"radio"} /> Nam</label>
                                        <label><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",0);
                                        }} type={"radio"} checked={true} /> Nữ</label>
                                    </div>
                                )}

                            </div>



                            <div className={"col-12 col-sm-6 col-md-6 mb-3 Box-Select-Mutiple"}>

                                <p className={"mb-1"}>Mối quan hệ <span className={"text-red"}>( * )</span></p>
                                {this.state.ListRelationship!==null?(
                                    Object.keys(this.state.ListRelationship).length>0?(
                                        <select onChange={(event)=>{

                                            var protection=this.state.protection;

                                            protection.relationship=event.target.value;

                                            this.setState({
                                                protection:protection
                                            });

                                        }} className={"form-control font14 w-100 rounded-0"}>
                                            <option value={null}>Vui lòng chọn</option>
                                            {this.state.ListRelationship.map(function(value,key){
                                                if(self.state.protection.relationship!==null){
                                                    if(self.state.protection.relationship===value['_id']){
                                                        return(
                                                            <option selected={true} value={value['_id']}>{value['name']}</option>
                                                        );
                                                    }
                                                    else{
                                                        return(
                                                            <option value={value['_id']}>{value['name']}</option>
                                                        );
                                                    }
                                                }
                                                else{
                                                    return(
                                                        <option value={value['_id']}>{value['name']}</option>
                                                    );
                                                }

                                            })}

                                        </select>

                                    ):(
                                        <></>
                                    )
                                ):(
                                    <ItemLoading />
                                )}
                            </div>


                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-4 mt-4 text-end">

                                <div className={"row"}>

                                    <div className={"col-12 col-sm-4 pb-3"}>
                                        <a onClick={()=>{
                                            this.setState({
                                                StatusEdit:false
                                            });
                                        }}
                                           className="font14 w-100 p-2 me-2 text-uppercase btn btn-dark rounded-0 btn-sm">
                                            Hủy
                                        </a>
                                    </div>
                                    <div className={"col-12 col-sm-4 pb-3"}>
                                        <a onClick={()=>{
                                            this.UpdateProtection();
                                        }}
                                           className="font14 p-2 text-uppercase btn btn-primary rounded-0 w-100 btn-sm">
                                            Cập nhật
                                        </a>
                                    </div>

                                    <div className={"col-12 col-sm-4 pb-3"}>
                                        <a onClick={()=>{
                                            this.UpdateProtectionContract(null);
                                        }}
                                           className="font14 p-2 text-uppercase btn btn-danger rounded-0 w-100 btn-sm">
                                            Xóa bỏ
                                        </a>
                                    </div>


                                </div>


                            </div>

                        </div>
                    ):(
                        <>

                            <div className={"alert alert-primary"}>
                                <i className="fas fa-info-circle me-1"></i> Chi tiết người liên lạc khẩn cấp
                            </div>

                            {this.state.ContractData.protector!==null?(
                                <>
                                    <div className={"row m-0 p-2 mb-1"}>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>
                                            <p className={"m-0"}>
                                                <i className="fas fa-phone-alt me-1"></i>  <strong>SDT/ID : </strong>
                                                {this.state.ContractData.protector.user_login!==null?this.state.ContractData.protector.user_login:(
                                                    <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                )}
                                            </p>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>
                                            <p className={"m-0"}>
                                                <i className="fas fa-user me-1"></i> <strong>Họ và tên : </strong>
                                                {
                                                    typeof this.state.ContractData.protector.username!=='undefined'?this.state.ContractData.protector.username:(
                                                        <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                    )
                                                }

                                            </p>


                                        </div>

                                    </div>

                                    <div className={"row m-0 p-2 mb-1 bg-light"}>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>
                                            <p className={"m-0"}>

                                                <i className="far fa-envelope me-1"></i> <strong>Email : </strong> {
                                                typeof this.state.ContractData.protector.email!=='undefined'?this.state.ContractData.protector.email:(
                                                    <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                )}</p>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>
                                            <p className={"m-0"}>
                                                <i className="fas fa-birthday-cake me-1"></i> <strong>Sinh nhật : </strong>
                                                {typeof this.state.ContractData.protector.birthday!=='undefined'?(
                                                    <>
                                                        {timeConverterDate(this.state.ContractData.protector.birthday)}
                                                    </>

                                                ):(
                                                    <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                )}

                                            </p>

                                        </div>

                                    </div>

                                    <div className={"row m-0 p-2 mb-1"}>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>
                                            <p className={"m-0"}>
                                                <i className="fas fa-map-marker me-1"></i> <strong>
                                                Địa chỉ : </strong>
                                                {typeof this.state.ContractData.protector.address_contact!=='undefined'?(
                                                    <>
                                                        {this.state.ContractData.protector.address_contact}
                                                    </>
                                                ):(
                                                    <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                )}
                                            </p>

                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>
                                            <p className={"m-0"}>
                                                <i className="far fa-id-card me-1"></i> <strong>Số căn cước : </strong>
                                                {typeof this.state.ContractData.protector.citizen_identification!=='undefined'?(
                                                    <>
                                                        {this.state.ContractData.protector.citizen_identification}
                                                    </>
                                                ):(
                                                    <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                )}

                                            </p>
                                        </div>
                                    </div>

                                    <div className={"row m-0 p-2 mb-1 bg-light"}>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>
                                            <p className={"mb-0"}>
                                                <i className="fas fa-genderless me-1"></i> <strong>Giới tính :</strong>

                                                {typeof this.state.ContractData.protector.sex!=='undefined'?(
                                                    <>
                                                        {this.state.ContractData.protector.sex===0?(
                                                            "Nữ"
                                                        ):(
                                                            "Nam"
                                                        )}
                                                    </>
                                                ):(
                                                    <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                )}
                                            </p>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 p-0 pe-3"}>

                                            <p className={"mb-0"}>
                                                <i className="fas fa-sync-alt me-1"></i> <strong>Mối quan hệ :</strong>

                                                {typeof this.state.ContractData.protector.relationship!=='undefined'?(
                                                    <>
                                                        {this.state.ContractData.protector.relationship}
                                                    </>
                                                ):(
                                                    <span className={"badge bg-danger m-1"}>Chưa rõ</span>
                                                )}

                                            </p>

                                        </div>
                                    </div>

                                    <div className={"col-12 text-end mt-3"}>
                                        <a href={"#"} onClick={()=>{
                                            this.setState({
                                                StatusEdit:true
                                            });
                                        }} className={"btn btn-primary rounded-0 font14"}><i className="far fa-edit me-1"></i> Chỉnh sửa</a>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className={"alert alert-danger"}>
                                        <i className="fas fa-info-circle me-1"></i> HD không có người liên lạc khẩn cấp
                                    </div>

                                    <div className={"col-12 text-end mt-3"}>
                                        <a href={"#"} onClick={()=>{
                                            this.setState({
                                                StatusEdit:true
                                            });

                                        }} className={"btn btn-primary rounded-0 font14"}><i className="far fa-edit me-1"></i> Chỉnh sửa</a>
                                    </div>

                                </>
                            )}


                        </>
                    )}


                </div>
            );
        }

    }

}
