/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import { array_to_select,
    base64_encode,
} from "../../helper/array";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import CanvasJSReact from "../../helper/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const INIT_STATE={
    AllAdmin:null,
    query:{
        id_admin:null,
        start_time:Math.ceil(new Date().getTime()/1000),
        end_time:Math.ceil(new Date().getTime()/1000)
    },
    statics:{
        push:null,
        static:null
    }
};

export default class staticsmarketing extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getUser(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"staticsmarketing/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    AllAdmin:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getUser();
    }


    SubmitAction(){

            var Appthis=this.props.AppThis;

            var number_error=0;

            var query=this.state.query;

            ['id_admin','start_time','end_time'].map(function(item){

                if(query[item]===null || query[item]==='' || typeof query[item]==='undefined'){
                    number_error+=1;
                }
            });

            if(number_error>0){

                toast("Vui lòng nhập đầy đủ thông tin");
                return;
            }

        Appthis.PageLoad(true);

            const formData = new FormData();

            var data=this.getQuerySearch();

            formData.append('token', Appthis.getToken());

            if(Object.keys(data).length>0){
                formData.append('query',base64_encode(data));
            }

            fetch(Appthis.ConstApp.home_api+"staticsmarketing", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    this.ReportData(data.data);
                }
                else{
                    toast("Thao tác không thành công.Xin vui lòng thử lại");
                }

                Appthis.PageLoad(false);

            }).catch((e)=>{

                Appthis.PageLoad(false);
            });
    }

    ReportData(data){
        var statics=this.state.statics;

        var report_statics=data.report_statics.data;

        var dataPoints=[];

        report_statics.map(function (item){
            dataPoints.push({y:item.number, label: item.label});
        });

        statics['static']={
            animationEnabled: true,
            data: [{
                type: "pie",
                startAngle: 40,
                toolTipContent: "<b>{label}</b>",
                showInLegend: "false",
                legendText: "{label} {y}",
                indexLabelFontSize: 16,
                indexLabel: "{label}",
                dataPoints: dataPoints
            }]
        };

        var report_user=[];

        var report_user_push=[];


        Object.entries(data.report_user).forEach(entry => {
            const [key, value] = entry;

            report_user_push.push({
                x:new Date(key*1000),
                y:value.push
            });

            report_user.push({
                x:new Date(key*1000),
                y:value.user
            });

        });



        statics['push']={
            animationEnabled: true,
            theme: "light2",
            axisX:{
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip:{
                shared:true
            },
            legend:{
                cursor:"pointer",
                dockInsidePlotArea: true
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Khách hàng",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: report_user
                },
                {
                    type: "line",
                    showInLegend: true,
                    name: "Chuyển số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: report_user_push
                }
            ]
        };

        this.setState({
            statics:statics
        });


    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-4 pb-5 mt-2 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-12 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Thống kê theo nhân viên marketing</h3>
                            </div>


                        </div>


                        <div className={"col-12 ps-3 mt-3 pe-3"}>

                            <div className={"row"}>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 customCssSelect"}>
                                    <label className={"font15 mb-1"}>Nhân viên marketing
                                        <span className={"text-red"}>( * )</span>
                                    </label>

                                    {this.state.AllAdmin!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var query=self.state.query;
                                            query['id_admin']=selectedOption.value;
                                            self.setState({
                                                query:query
                                            });

                                        }}  options={array_to_select(this.state.AllAdmin,'username','_id')} />



                                    ):(
                                       <div className={"col-12 mt-3"}>
                                           <ItemLoading />
                                       </div>
                                    )}

                                </div>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 box_input"}>
                                    <label className={"font15 mb-1"}>Thời gian bắt đầu
                                    <span className={"text-red"}>( * )</span>
                                    </label>


                                    {self.state.query.start_time!==null?(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date(this.state.query.start_time*1000)} onChange={(date) => {
                                            var query=self.state.query;
                                            var tmp_time=null;
                                            if(date!==null){
                                                 tmp_time=Math.ceil(date.getTime()/1000);
                                            };
                                            query['start_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />

                                    ):(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date()} onChange={(date) => {
                                            var query=self.state.query;
                                            var tmp_time=null;
                                            if(date!==null){
                                                 tmp_time=Math.ceil(date.getTime()/1000);
                                            }
                                            query['start_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />
                                    )}


                                </div>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 box_input"}>

                                    <label className={"font15 mb-1"}>Thời gian kết thúc
                                        <span className={"text-red"}>( * )</span>
                                    </label>


                                    {self.state.query.end_time!==null?(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date(this.state.query.end_time*1000)} onChange={(date) => {
                                            var query=self.state.query;

                                            var tmp_time=null;

                                            if(date!==null){
                                                 tmp_time=Math.ceil(date.getTime()/1000);
                                            }
                                            query['end_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />

                                    ):(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date()} onChange={(date) => {
                                            var query=self.state.query;
                                            var tmp_time=null;


                                            if(date!==null){
                                                 tmp_time=Math.ceil(date.getTime()/1000);
                                            }
                                            query['end_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />
                                    )}




                                </div>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 align-items-end d-flex align-self-end"}>

                                    <a onClick={()=>{
                                        this.SubmitAction();
                                    }} className={"btn w-100 d-block btn-outline-primary rounded-0"}>
                                        Thống kê
                                    </a>

                                </div>

                            </div>

                        </div>




                        <div className={"col-12 ps-3 pe-3"}>

                            {this.state.statics.push!==null?(
                                <div className={"col-12 mb-4 mt-5"}>

                                    <h3 className="font16 mb-2 text-uppercase font-weight-bold font-weight-bold">
                                        Khách hàng và chuyển số
                                    </h3>


                                    <CanvasJSChart options={this.state.statics.push} />
                                </div>
                            ):(<></>)}


                            {this.state.statics.static!==null?(
                                <div className={"col-12 mb-4"}>
                                    <h3 className="font16 mb-2 text-uppercase font-weight-bold font-weight-bold">
                                        trạng thái khách hàng
                                    </h3>

                                    <CanvasJSChart options={this.state.statics.static} />
                                </div>
                            ):(<></>)}


                        </div>



                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
