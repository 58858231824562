/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_array, array_to_select, getItemInArray,
    timeConverter,
} from "../../helper/array";
import DetailPush from "../../global/push/DetailPush";
import Select from 'react-select';
import CanvasJSReact from '../../helper/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const INIT_STATE={
    data:null,
    DetailPush:{
        status:false,
        data:null
    },
    page:0,
    item_select:null,
    ListAdmin:null,
    statics:{
        static:null,
        user:null,
    },
    ListStatics:null
};

export default class mcgroup extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcgroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getListAdmin();

        this.getStaticGroupPush();

        this.GetStaticpush();

        this.GetListStatics();
    }

    getListAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcgroup/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListAdmin:data.data
                });
            }


        }).catch((e)=>{



        });


    }

    getStaticGroupPush(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcgroup/staticgroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.UpdateStateUser(data.data);

            }


        }).catch((e)=>{


        });

    }

    UpdateStateUser(data){

        var dataPoints=[];

        if(data.length>0){

            data.map(function (value,key){
                dataPoints.push({
                    label: value.code_user, y: value.total_send_month
                });
            });
        }


        const options={
            animationEnabled: true,
            theme: "light2",
            title: {
            },
            axisY: {
                title: "Số"
            },
            axisX: {
                title: ""
            },
            data: [{
                type: "column",
                dataPoints: dataPoints
            }]
        };

        var statics=this.state.statics;

        statics.user=options;

        this.setState({
            statics:statics
        });



    }

    GetStaticpush(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"mcgroup/staticpush", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.UpdateStatic(data.data);
            }


        }).catch((e)=>{


        });

    }


    UpdateStatic(data){

        const dataPoints=[];

        data['data'].map(function(item,key){
            dataPoints.push({
                label:item.label,
                y:item.percent,
                color:item.color
            });
        });


        const options={
            animationEnabled: true,
            title: {
                text: ""
            },
            data: [{
                type: "pie",
                startAngle: 240,
                indexLabel: "{label} {y}",
                dataPoints: dataPoints
            }]
        };


        var statics=this.state.statics;

        statics.static=options;

        this.setState({
            statics:statics
        });



    }

    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Người gửi</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Team MKT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Bộ môn</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Thời gian</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Nguồn</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Giới tính</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td> {value['id_admin']['code_user']}</td>
                                    <td> {value['id_admin']['group_id']!==null && _isset_array(value['id_admin']['group_id'])?(
                                        <>
                                            {value['id_admin']['group_id'].map(function(it){
                                                return(
                                                    <span className={"badge bg-dark font10 rounded-0"}>
                                                        {it.name}
                                                    </span>
                                                );
                                            })}
                                        </>
                                    ):(
                                        <>Chưa rõ</>
                                    )}</td>
                                    <td>
                                        {value.id_subject && value.id_subject.length>0?(
                                            value.id_subject.map(function(i,t){
                                                return(
                                                    <span className={"badge p-1 font10 m-1 bg-primary"}>{i.name}</span>
                                                );
                                            })
                                        ):(
                                            <span className={"badge p-1 font10 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </td>

                                    <td>{timeConverter(value['time_create'])}</td>
                                    <td>{value.id_user && value.id_user.source && value.id_user.source.length>0?(
                                        value.id_user.source.map(function(i,k){
                                            return(
                                                <span className={"badge p-1 font10 m-1 bg-success"}>{i.name}</span>
                                            );
                                        })
                                    ):(
                                        <span className={"badge p-1 font10 bg-danger"}>Chưa rõ</span>
                                    )}</td>
                                    <td>{value.id_user && value.id_user.sex===1?(
                                        <span>Nam</span>
                                    ):(
                                        <span>Nữ</span>
                                    )}</td>


                                    <td className={"fix-item-table"}>
                                        <a onClick={()=>{
                                            self.setState({
                                                DetailPush:{
                                                    status:true,
                                                    data:value
                                                }
                                            });
                                        }} className={"btn btn-outline-primary rounded-0 mt-1 font12"}>
                                            <i className="fas fa-file-import" /> Đẩy số
                                        </a>
                                    </td>




                                </tr>
                            );
                        })}
                        </tbody>
                    </table>


                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    async GetListStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());


        var get_data=await fetch(Appthis.ConstApp.home_api+"statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        if(get_data.status==='ok'){

            var ListStatics=get_data.data;

            this.setState({
                ListStatics:ListStatics
            });

        }



    }


    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-4 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold">
                                    Nhận số của Team
                                </h3>
                            </div>

                            <div className="col-12 col-sm-12 col-md-6 text-right text-end">

                                <div className="col-12 p-0">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>


                            </div>

                        </div>



                        <div className={"row m-0 mt-4"}>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-6"}>

                                <h3 className="font16 mb-3 text-uppercase font-weight-bold font-weight-bold">
                                    Trạng thái khách hàng marketing
                                </h3>

                                {this.state.statics.static!==null?(
                                    <CanvasJSChart options={this.state.statics.static} />
                                ):(
                                    <ItemLoading/>
                                )}

                            </div>



                            <div className={"col-12 col-sm-12 col-md-12 col-lg-6"}>

                                <h3 className="font16 mb-3 text-uppercase font-weight-bold font-weight-bold">
                                    Nhận số Marketing
                                </h3>

                                {this.state.statics.user!==null?(
                                    <CanvasJSChart options={this.state.statics.user} />
                                ):(
                                    <ItemLoading/>
                                )}

                            </div>

                        </div>



                        <div className={"col-12 ps-3 pe-3 mt-4"}>
                            {this.thisRenderData()}
                        </div>



                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>

                        {this.state.DetailPush.status===true?(
                            <DetailPush submit={true} TemplateSubmit={()=>{
                                return this.TemplateSubmit();
                            }} data={this.state.DetailPush.data} onClose={()=>{
                                this.setState({
                                    DetailPush:{
                                        status:false,
                                        data:null
                                    }
                                });

                            }} AppThis={this.props.AppThis} />
                        ):(<></>)}




                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    TemplateSubmit(){

        if(this.state.ListAdmin===null){
            return(<ItemLoading />);
        }
        else{

            return(
                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-4"}>

                        <Select menuPlacement={"top"} isSearchable={true} onChange={(selectedOption)=>{
                            this.setState({
                                item_select:selectedOption.value
                            });

                        }} options={array_to_select(this.state.ListAdmin,'username','_id')} />


                    </div>
                    <div className={"col-12 col-sm-12 col-md-4"}>
                        <a onClick={()=>{
                            this.SubmitPush('push_item_event');
                        }} className={"btn btn-primary rounded-0 w-100"}>
                            <i className="fas fa-layer-group" /> Chuyển số
                        </a>
                    </div>

                    <div className={"col-12 col-sm-12 col-md-4"}>
                        <a onClick={()=>{
                            this.SubmitPush('deny_item_event');
                        }} className={"btn btn-warning rounded-0 w-100"}>
                            <i className="fas fa-ban" /> Từ chối
                        </a>
                    </div>


                </div>
            );
        }

    }


    SubmitPush(action){

        if(action!=='deny_item_event' && this.state.item_select===null && this.state.DetailPush.data!==null){

            toast("Vui lòng chọn nhân viên");
        }
        else{

            this.props.AppThis.PageLoad(true);

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('action', action);

            formData.append('id_push', this.state.DetailPush.data._id);

            if(action!=='deny_item_event'){
                formData.append('id_event', this.state.item_select);
            }

            const self=this;
            fetch(Appthis.ConstApp.home_api+"mcgroup/update", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='error'){
                    toast("Cập nhật không thành công");
                    Appthis.PageLoad(false);
                }
                else{
                    toast("Cập nhật thành công");

                    self.getData(true);

                }

                this.setState({
                    DetailPush:{
                        status:false,
                        data:null
                    }
                });

            }).catch((e)=>{
                toast("Cập nhật không thành công");
                Appthis.PageLoad(false);

                this.setState({
                    DetailPush:{
                        status:false,
                        data:null
                    }
                });

            });


        }
    }


}
