/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {base64_encode,
    formatNumber,
    timeConverterDate
} from "../../helper/array";
import {toast} from "react-toastify";

export default class Warehouse extends Component {

    constructor(props) {
        super(props);

        this.state={
            data:null,
            EventNew:false,
            DataNewWarehouse:{
                label:null,
                number:null,
                price_in:0,
                _id:null
            }
        };

    }

    componentDidMount() {

        if(this.props.data!==null){

            this.GetWarehouse(this.props.data._id);
        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    GetWarehouse(id,page=0){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());
        formData.append('id_address', AppThis.getAddress());
        formData.append('id_product', id);
        formData.append('page', page);
        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/warehouse", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");

        });

    }

    AddWarehouse(){

        if(this.props.data===null){
            return;
        }

        const data_props=this.props.data;

        var DataNewWarehouse=this.state.DataNewWarehouse;

        var number_error=0;

        if(DataNewWarehouse.label===null){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{


            var AppThis=this.props.AppThis;

            const formData = new FormData();

            if(DataNewWarehouse._id===null){
                delete DataNewWarehouse['_id'];
            }

            formData.append('token', AppThis.getToken());
            formData.append('id_address', AppThis.getAddress());
            formData.append('id_product', data_props._id);
            var data_submit=base64_encode(DataNewWarehouse);
            formData.append('data', data_submit);

            const self=this;
            fetch(AppThis.ConstApp.home_api+this.props.path+"/addware", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='error'){
                    toast("Thêm dữ liệu thất bại");
                }
                else{
                    self.setState({
                        data:null,
                        EventNew:false,
                        DataNewWarehouse:{
                            label:null,
                            number:null,
                            price_in:null,
                            _id:null
                        }
                    },function(){
                        self.GetWarehouse(data_props._id);
                    });

                }

            }).
            catch((e)=>{
                toast("Thêm dữ liệu thất bại");
            });


        }
    }

    RenderGetWareHouse(){

        const self=this;

        return(
            <div className={"col-12 RenderGetWareHouse"}>


                <div className={"col-12 text-end mb-2"}>

                    <a onClick={()=>{
                        this.setState({
                            EventNew:true,
                            DataNewWarehouse:{
                                label:null,
                                number:null,
                                price_in:0,
                                _id:null
                            }
                        });
                    }} className={"btn btn-outline-primary rounded-0 font13"}>
                        <i className="far fa-plus-square me-2" />
                        Thêm mới</a>

                </div>


                {this.state.EventNew===true?(
                    <div className={"row mb-3 mt-3"}>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-4 item-new-row pb-3"}>
                            <label>Mã sản phẩm <span className={"text-red"}>( * )</span></label>
                            <input onChange={(event)=>{
                                var DataNewWarehouse=this.state.DataNewWarehouse;
                                DataNewWarehouse.label=event.target.value;
                                this.setState({
                                    DataNewWarehouse:DataNewWarehouse
                                });

                            }} defaultValue={this.state.DataNewWarehouse.label} placeholder={"Mã sản phẩm"} type={"text"} className={"form-control w-100 rounded-0"} />
                        </div>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-4 item-new-row pb-3"}>
                            <label>Số lượng <span className={"text-red"}>( * )</span></label>
                            <input onChange={(event)=>{
                                var DataNewWarehouse=this.state.DataNewWarehouse;
                                DataNewWarehouse.number=parseInt(event.target.value);
                                this.setState({
                                    DataNewWarehouse:DataNewWarehouse
                                });

                            }} defaultValue={this.state.DataNewWarehouse.number} placeholder={"Số lượng"} type={"number"} className={"form-control w-100 rounded-0"} />
                        </div>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-4 item-new-row"}>
                            <label>Tổng tiền <span className={"text-red"}>( * )</span></label>
                            <input onChange={(event)=>{
                                var DataNewWarehouse=this.state.DataNewWarehouse;
                                DataNewWarehouse.price_in=parseInt(event.target.value);
                                this.setState({
                                    DataNewWarehouse:DataNewWarehouse
                                });
                            }} key={"add_price_new"} defaultValue={this.state.DataNewWarehouse.price_in} placeholder={"Tổng tiền"} type={"number"} className={"form-control w-100 rounded-0"} />

                        </div>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-4 item-new-row pb-3"}>
                            <div className={"row h-100 d-flex flex-row align-items-end"}>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <a onClick={()=>{
                                        this.AddWarehouse();
                                    }} className={"btn btn-primary rounded-0 w-100"}>Cập nhật</a>
                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <a className={"btn btn-danger rounded-0 w-100"} onClick={()=>{
                                        this.setState({
                                            EventNew:false,
                                            DataNewWarehouse:{
                                                label:null,
                                                number:null,
                                                price_in:0,
                                                _id:null
                                            }
                                        });
                                    }}>Huỷ</a>
                                </div>
                            </div>
                        </div>

                    </div>

                ):(<></>)}


                {this.state.data.data.length>0?(
                    <div className={"col-12 overflow-auto"}>

                        <table className={"table w-100 mw700"}>
                            <thead>
                            <tr>
                                <td>
                                    <strong>Mã Nhập kho</strong>
                                </td>

                                <td>
                                    <strong>Số lượng</strong>
                                </td>

                                <td>
                                    <strong>Số tiền</strong>
                                </td>
                                <td>
                                    <strong>Trạng thái</strong>
                                </td>

                                <td>
                                    <strong>Thao tác</strong>
                                </td>
                            </tr>
                            </thead>

                            <tbody>
                            {this.state.data.data.map(function(item,index){
                                return(
                                    <tr>
                                        <td>
                                            {item.label}
                                        </td>
                                        <td>
                                            {formatNumber(item.number)}
                                        </td>
                                        <td>
                                            <span className={"text-red"}>{formatNumber(item.price_in)}</span> VNĐ
                                        </td>

                                        <td>
                                            {item.status===0?(
                                                <span className={"p-1 font10 bg-dark text-white"}>Đang chờ</span>
                                            ):(
                                                <span className={"p-1 font10 bg-primary text-white"}>Hoàn tất</span>
                                            )}
                                        </td>

                                        <td>

                                            {item.status===0?(
                                                <a onClick={(event)=>{
                                                    event.preventDefault();
                                                    self.setState({
                                                        EventNew:false,
                                                        DataNewWarehouse:{
                                                            label:null,
                                                            number:null,
                                                            price_in:null,
                                                            _id:null
                                                        }
                                                    },function(){
                                                        self.setState({
                                                            EventNew:true,
                                                            DataNewWarehouse:{
                                                                label:item.label,
                                                                number:item.number,
                                                                price_in:item.price_in,
                                                                _id:item._id
                                                            }
                                                        });

                                                        self.forceUpdate();

                                                    });

                                                }} className={"btn btn-outline-primary p-2"}>
                                                    <i className="font12 far fa-edit" />
                                                </a>
                                            ):(<></>)}


                                        </td>


                                    </tr>
                                );
                            })}


                            </tbody>
                        </table>






                    </div>
                ):(
                    <div className={"alert alert-danger rounded-0"}>
                        Không có dữ liệu
                    </div>
                )}


                <div className={"col-12 mt-3 pb-3"}>
                    <ul className="pagination">
                        {this.state.data.prev>=0?(
                            <li className="page-item" >
                                <a className="page-link" onClick={()=>{
                                    var page=this.state.data.page-1;
                                    self.GetWarehouse(this.props.data._id,page);
                                }}>
                                    Trước
                                </a>
                            </li>
                        ):(<></>)}

                        <li className="page-item">
                            <a className="page-link">
                                <span>{parseInt(this.state.data.curent)+1}</span>
                            </a></li>

                        {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                            <li className="page-item">
                                <a className="page-link" onClick={()=>{
                                        var page=this.state.data.next;
                                        self.GetWarehouse(this.props.data._id,page);

                                }} >
                                    Kế tiếp
                                </a>
                            </li>
                        ):(<></>)}

                    </ul>
                </div>


            </div>
        );
    }

    render(){
        if(this.props.data!==null){
            return(
                <div className="modal d-block position-fixed modal-dialog-end">
                    <div className={"modal-dialog modal-fullscreen modal-dialog-scrollable modal-dialog-scrollable modal-lg container-fluid"}>
                        <div className="modal-content shadow border-0 rounded-0">
                            <div className="modal-header bg-light rounded-0">
                                <strong className={"font16 text-uppercase text-black"}>
                                    <i className="fas fa-industry me-2" />
                                   Quản lý nhập hàng</strong>
                            </div>
                            <div className="modal-body">

                                <div className={"row mb-2 mt-2"}>

                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-3 left-info-user"}>


                                        <div className={"row mb-2 bg-light p-2"}>
                                            <div className={"col-6 col-md-5"}>
                                                <i className="fab fa-product-hunt" /> Tên sản phẩm
                                            </div>
                                            <div className={"col-6 col-md-7"}>
                                                {this.props.data.name}
                                            </div>
                                        </div>

                                        <div className={"row mb-2 p-2"}>
                                            <div className={"col-6 col-md-5"}>
                                                <i className="fas fa-hand-holding-usd" /> Giá
                                            </div>
                                            <div className={"col-6 col-md-7"}>
                                                <span className={"text-line text-red font-weight-bold"}>
                                                    {formatNumber(this.props.data.price)} VNĐ
                                                </span>
                                            </div>
                                        </div>


                                        <div className={"row mb-2 bg-light p-2"}>
                                            <div className={"col-6 col-md-5"}>
                                                Màu sắc
                                            </div>
                                            <div className={"col-6 col-md-7"}>

                                                {this.props.data['color']?(
                                                    <span className={"boxcolor d-inline-block"} style={{
                                                        backgroundColor:this.props.data['color']
                                                    }}>
                                              </span>
                                                ):(
                                                    <p className={"m-0"}>Chưa rõ</p>
                                                )}


                                            </div>
                                        </div>

                                        <div className={"row mb-2 p-2"}>
                                            <div className={"col-6 col-md-5"}>
                                                Trạng thái
                                            </div>
                                            <div className={"col-6 col-md-7"}>

                                                {this.props.data['visible']===1?(
                                                    <span className={"p-1 bg-primary text-white font10 rounded-2"}>Active</span>
                                                ):(
                                                    <span className={"p-1 bg-danger text-white font10 rounded-2"}>Inactive</span>
                                                )}

                                            </div>
                                        </div>


                                        <div className={"row mb-2 bg-light p-2"}>
                                            <div className={"col-6 col-md-5"}>
                                                <i className="far fa-clock" /> Ngày tạo
                                            </div>
                                            <div className={"col-6 col-md-7"}>
                                                {timeConverterDate(this.props.data.time_create)}
                                            </div>
                                        </div>

                                        <div className={"row mb-2 p-2"}>
                                            <div className={"col-6 col-md-5"}>
                                                <i className="far fa-user" />  Người tạo
                                            </div>
                                            <div className={"col-6 col-md-7"}>
                                                {this.props.data.id_admin?this.props.data.id_admin.code_user:'Chưa rõ'}
                                            </div>
                                        </div>


                                        {this.state.data!==null?(
                                            <div className={"row mb-3 mt-3"}>
                                                <div className="col-12 mb-2 item-admin-dashboard">
                                                    <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                                                        <div
                                                            className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                                            <i className="d-flex justify-content-center align-items-center far fa-handshake rounded-circle bg-warning" />
                                                        </div>
                                                        <div className="right-item">
                                                            <p className="text-uppercase mt-2 mb-0 text-dark font14">SỐ LẦN NHẬP</p>
                                                            <p className="number-info-dashboard m-0 font14 font-weight-bold">{this.state.data.statics.total_count}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2 item-admin-dashboard">
                                                    <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                                                        <div
                                                            className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                                            <i className="d-flex text-white justify-content-center align-items-center fas fa-file-import rounded-circle bg-primary" />
                                                        </div>
                                                        <div className="right-item">
                                                            <p className="text-uppercase mt-2 mb-0 text-dark font14">ĐÃ NHẬP</p>

                                                            <p className="number-info-dashboard m-0 font14 font-weight-bold">
                                                                {formatNumber(this.state.data.statics.number)}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12  mb-2 item-admin-dashboard">
                                                    <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                                                        <div
                                                            className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                                            <i className="d-flex text-white justify-content-center align-items-center fas fa-hand-holding-usd rounded-circle bg-info" />
                                                        </div>
                                                        <div className="right-item">
                                                            <p className="text-uppercase mt-2 mb-0 text-dark font14">ĐÃ LẤY</p>
                                                            <p className="number-info-dashboard m-0 font14 font-weight-bold">{this.state.data.statics.took}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2 item-admin-dashboard">
                                                    <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                                                        <div
                                                            className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                                            <i className="d-flex text-white justify-content-center align-items-center fas fa-file-import rounded-circle bg-danger" />
                                                        </div>
                                                        <div className="right-item">
                                                            <p className="text-uppercase mt-2 mb-0 text-dark font14">TỔNG TIỀN</p>

                                                            <p className="number-info-dashboard m-0 font14 font-weight-bold">
                                                                {formatNumber(this.state.data.statics.price_in)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ):(<></>)}




                                    </div>

                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-9 right-info-user"}>

                                        {this.state.data!==null?(
                                            this.RenderGetWareHouse()
                                        ):(
                                            <div className={"col-12 text-center d-flex align-items-center justify-content-center align-self-center h-100"}>
                                                <ItemLoading size={"Large"} />
                                            </div>
                                        )}

                                    </div>



                                </div>

                            </div>


                            <div className={"modal-footer bg-light"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"col-12 col-sm-12 col-lg-2 offset-lg-10"}>
                                        <a onClick={()=>{
                                            this.props.onClose();
                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                            Đóng
                                        </a>
                                    </div>

                                </div>


                            </div>



                        </div>
                    </div>
                    <div onClick={()=>{
                        this.props.onClose();
                    }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );
        }
        else{
            return(<></>);
        }

    }
}
