/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {_isset, timeConverter, secondsToHms, _isset_object, _isset_array, getSourcePush} from "../../helper/array";
import no_avatar from "../../assets/images/_no_avatar.png";
export default class DetailPush extends Component{

    constructor() {
        super();
    }
    componentDidMount() {

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getSource(args){
       if(args.length>0){
          return args.map(function(item){
               return(
                   <span className={"badge p-1 bg-primary font10 m-1"}>{item.name}</span>
               );
           });
       }
       else{
           return(
               <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
           );
       }
    }

    getNameSubject(arg){
        if(arg.length>0){
            return arg.map(function(item){
                return(
                    <span className={"badge p-1 bg-primary font10 m-1"}>{item.name}</span>
                );
            });
        }
        else{
            return(
                <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
            );
        }
    }




    renderData(data){

        return(
            <div className={"col-12 col-sm-12 DetailPush"}>

                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-5"}>

                        <div className={"text-center mb-3"}>
                            <img src={no_avatar} className={"mw150px rounded-circle"} />
                        </div>

                        <div className={"row p-2 mb-1 bg-light"}>

                            <div className={"col-5 col-lg-5"}>
                                <i className="fas fa-user me-1" /> Họ và tên
                            </div>

                            <div className={"col-7 col-lg-7"}>
                                <span className={"badge font10 bg-info rounded-0 p-1"}> {data.id_user['username']?data.id_user['username']:data.id_user['tmp_user']}</span>
                            </div>

                        </div>

                        <div className={"row p-2 mb-1"}>

                            <div className={"col-5 col-sm-5 col-lg-5"}>
                                <i className="fas fa-stopwatch-20 me-1" /> Ngày sinh
                            </div>

                            <div className={"col-7 col-sm-7 col-lg-7"}>
                                {data.id_user['birthday']?(
                                    <span>{timeConverter(data.id_user['birthday'])}</span>
                                ):(
                                    <span className={"badge p-1 bg-danger font10"}>Chưa rõ</span>
                                )}
                            </div>

                        </div>

                        <div className={"row p-2 mb-1 bg-light"}>

                            <div className={"col-5 col-sm-5 col-lg-5"}>
                                <i className="fas fa-genderless me-1" /> Giới tính
                            </div>

                            <div className={"col-7 col-lg-7"}>
                                {data.id_user['sex']===1?(
                                    <span>Nam</span>
                                ):(
                                    <span>Nữ</span>
                                )}
                            </div>

                        </div>


                        {data.type===1?(
                            <>
                                <div className={"row p-2 mb-1"}>

                                    <div className={"col-5 col-lg-5"}>
                                        <i className="fas fa-stopwatch-20 me-1" /> Người gửi
                                    </div>

                                    <div className={"col-7 col-lg-7"}>
                                        <strong className={"text-red"}> {data['id_admin']['code_user']}</strong>
                                    </div>

                                </div>
                                <div className={"row p-2 mb-1 bg-light"}>

                                    <div className={"col-5 col-lg-5"}>
                                        <i className="fas fa-stopwatch-20 me-1" /> Team gửi
                                    </div>

                                    <div className={"col-7 col-lg-7"}>

                                        {data['id_admin']['group_id']!==null && _isset_array(data['id_admin']['group_id'])?(
                                            <>
                                                {data['id_admin']['group_id'].map(function(it){
                                                    return(
                                                        <span className={"badge bg-dark font10 rounded-0"}>
                                                        {it.name}
                                                    </span>
                                                    );
                                                })}
                                            </>
                                        ):(
                                            <>Chưa rõ</>
                                        )}
                                    </div>

                                </div>
                            </>
                        ):(
                          <></>
                        )}


                        <div className={"row p-2 mb-1"}>

                            <div className={"col-5 col-lg-5"}>
                                <i className="fab fa-facebook-messenger" /> Nguồn
                            </div>

                            <div className={"col-7 col-lg-7"}>
                                {data.id_user['source']?(
                                    this.getSource(data.id_user['source'])
                                ):(
                                    <span className={"badge p-1 bg-danger font10"}>Chưa rõ</span>
                                )}
                            </div>

                        </div>



                    </div>


                    <div className={"col-12 col-sm-12 col-md-12 col-lg-7"}>

                        <div className="nav nav-tabs">
                            <a data-toggle="tab" href="#tab1" className="rounded-0 bg-dark font14 text-white nav-link active">
                            Xem chi tiết </a>
                        </div>




                        {data.type===1?(<>


                                <div className="row w-100 m-0 pb-2 pt-2">
                                    <div className="col-5 col-md-5">
                                        Nguồi gửi
                                    </div>
                                    <div className="col-7 col-md-7">

                                        {typeof data.source_push!=='undefined'?(
                                            <>
                                                {getSourcePush(data)}
                                            </>
                                        ):(
                                            <span className={"badge bg-dark rounded-0 font11"}>
                                        Chưa rõ
                                    </span>
                                        )}

                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2 bg-light">
                                    <div className="col-12 text-break">
                                        {data['note']?(
                                            <>{data['note']}</>
                                        ):(
                                            <span className={"text-center col-12"}>___</span>
                                        )}

                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2">
                                    <div className="col-5 col-md-5">
                                        Thời gian gửi
                                    </div>
                                    <div className="col-7 col-md-7">
                                        {timeConverter(data.time_create)}
                                    </div>
                                </div>

                                {typeof data.auto_send!=='undefined' && data.auto_send===1?(
                                        <>
                                            <div className="row w-100 m-0">
                                                <div className="col-12">
                                                    <div className={"alert mb-0 alert-success rounded-0 w-100"}>
                                                        Hệ thống tự động gửi
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                ):(

                                    <>
                                        <div className="row w-100 m-0 pb-2 bg-light pt-2">
                                            <div className="col-5 col-md-5">
                                                TG Phản hồi CM , SM , FM
                                            </div>
                                            <div className="col-7 col-md-7">
                                                {data.time_club?(
                                                    <span>{secondsToHms(data.time_club-data.time_create)}</span>
                                                ):(
                                                    <span>___</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row w-100 m-0 pb-2 pt-2">
                                            <div className="col-5 col-md-5">
                                                TG Phản hồi Team
                                            </div>
                                            <div className="col-7 col-md-7">

                                                {data.time_team && data.time_club?(
                                                    <span>{secondsToHms(data.time_team-data.time_club)}</span>
                                                ):(
                                                    <span>___</span>
                                                )}


                                            </div>
                                        </div>


                                        <div className="row w-100 m-0 pb-2 pt-2 bg-light">
                                            <div className="col-5 col-md-5">
                                                TG cập nhật trạng thái
                                            </div>
                                            <div className="col-7 col-md-7">

                                                {data.time_update?(
                                                    <span>{secondsToHms(data.time_update-data.time_create)}</span>
                                                ):(
                                                    <span>___</span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}


                                <div className="row w-100 m-0 pb-2 pt-2">
                                    <div className="col-5 col-lg-5">
                                        Trạng thái
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data['id_statics']?(
                                            <span>{data['id_statics']['name']}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2 bg-light">
                                    <div className="col-5 col-lg-5">
                                        Tên cơ sở
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data['id_address']?(
                                            <span>{data['id_address']['name']}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2">
                                    <div className="col-5 col-lg-5">
                                        Tên bộ môn
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data['id_subject']?(
                                            <span>{this.getNameSubject(data.id_subject)}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2 bg-light">
                                    <div className="col-5 col-lg-5">
                                        Nhóm Club
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {_isset_object(data.id_group_push)?(
                                            <span>{data.id_group_push.name}</span>
                                        ):(
                                            <span className={"font10 p-1 badge bg-warning"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2">
                                    <div className="col-5 col-lg-5">
                                        Người nhận
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data.id_admin_push?(
                                            <span>{data.id_admin_push['username']+" - "+data.id_admin_push['code_user']}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-primary"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>
                            </>
                            ):(
                            <>

                                <div className="row w-100 m-0 pb-2 pt-2 bg-light">
                                    <div className="col-5 col-lg-5">
                                        Cơ sở
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data['id_address']?(
                                            <span>{data['id_address']['name']}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2">
                                    <div className="col-5 col-lg-5">
                                        NV chăm sóc
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data.id_admin_push?(
                                            <span>{data.id_admin_push['code_user']}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-primary"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2 bg-light">
                                    <div className="col-5 col-lg-5">
                                        Trạng thái
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data['id_statics']?(
                                            <span>{data['id_statics']['name']}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </div>
                                </div>


                                <div className="row w-100 m-0 pb-2 pt-2">
                                    <div className="col-5 col-lg-5">
                                        Ghi chú
                                    </div>
                                    <div className="col-7 col-lg-7">
                                        {data['note']?(
                                            <>{data['note']}</>
                                        ):(
                                            <span className={"text-center col-12"}>___</span>
                                        )}
                                    </div>
                                </div>

                                <div className="row w-100 m-0 pb-2 pt-2 bg-light">
                                    <div className="col-5 col-md-5">
                                        Thời gian
                                    </div>
                                    <div className="col-7 col-md-7">
                                        {timeConverter(data.time_create)}
                                    </div>
                                </div>



                            </>
                        )}



                    </div>


                </div>

            </div>
        );
    }

    render() {

        if(_isset(this.props.data)){
            return(
                <div className="modal d-block position-fixed modal-dialog-end">
                    <div className={"modal-dialog modal-lg modal-dialog-scrollable"}>
                        <div className="modal-content shadow border-0">
                            <div className="modal-header bg-header-user text-white">
                                <strong className={"font16 text-uppercase"}>CHI TIẾT CHĂM SÓC KH</strong>
                            </div>
                            <div className="modal-body">

                                <div className={"row mb-2 mt-2"}>

                                    {Object.keys(this.props.data).length>0?(this.renderData(this.props.data)):(
                                        <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                            <ItemLoading />
                                        </div>
                                    )}

                                </div>



                            </div>

                            <div className={"modal-footer bg-light"}>

                                <div className={"w-100 row mt-3 align-items-end"}>


                                    <div className={"col-12 col-sm-12 col-lg-9"}>

                                        {this.props.submit && this.props.submit===true?(
                                            this.props.TemplateSubmit()
                                        ):(<></>)}

                                    </div>



                                    <div className={"col-12 col-sm-12 col-lg-3"}>

                                        <a onClick={()=>{
                                            this.props.onClose()
                                        }} className={"btn btn-danger w-100 text-white"} >
                                            Đóng
                                        </a>

                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );
        }
        else{
           return(
               <></>
           );
        }


    }

}
