/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import lodash from "lodash";
import {
    array_to_select,
    get_data_in_args,
    _isset_object,
    _isset_string, randomNumber
} from "../../helper/array";
import InputMask from "react-input-mask";
import Select from 'react-select';
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import Webcam from "react-webcam";
import {confirmAlert} from "react-confirm-alert";

const hiddenFileInput=React.createRef();
const ListTypeUser=[
    {
        key:0,
        value:"Có SDT"
    },
    {
        key:1,
        value:"Không có SDT"
    }
];
export default class ModalUser extends Component{

    constructor(props) {
        super(props);
        this.state= {
            Source:null,
            DataUser:{
                source:[],
                user_login:null,
                username:null,
                email:null,
                tmp_user: null,
                birthday:null,
                sex:0,
                address_contact:null,
                citizen_identification:null,
                card_user:null,
            },
            DataView:null,
            DisableChangeType:false,
            TypeUser:0,
            EnableWebcam:false
        };

        this.webcamRef=React.createRef();

    }

    componentDidMount() {
        this.getSource();
        if(typeof this.props.data!=='undefined'){

            if(_isset_object(this.props.data)){
                this.UdateDataProfile(this.props.data);
            }
            else{
                var DataUser=this.state.DataUser;
                var DataView=lodash.cloneDeep(DataUser);
                this.setState({
                    DataUser:DataUser,
                    DataView:DataView
                })
            }
        }

        if(typeof this.props.disable!=='undefined' && (this.props.disable===true || this.props.disable===false)){
            this.setState({
                DisableChangeType:this.props.disable
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getSource(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('type',1);

        fetch(AppThis.ConstApp.home_api+"source", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{

            this.setState({
                Source:data.data
            });

        });

    }

    UdateDataProfile(data=null){

        const self=this;

        var DataView=lodash.cloneDeep(data);

        var DataUser={};

        ['tmp_user','source','user_login','username','email','tmp_user','birthday','sex','address_contact','citizen_identification','card_user'].map(function(item){
            if(typeof DataView[item]!=='undefined'){
                DataUser[item]=DataView[item];
            }
            else{
                DataUser[item]=null;
            }
        });

        if(_isset_object(DataUser.source)){
            DataUser['source']=get_data_in_args(DataUser.source,'_id');
        }
        else{
            DataUser['source']=[];
        }

        if(DataUser['username']===null){
            DataUser['username']=DataUser['tmp_user'];
        }

        this.setState({
            DataUser: null,
            DataView:null
        },function(){
            self.setState({
                DataUser: DataUser,
                DataView:DataView,
                DisableChangeType:true
            });
        });

    }

    getInfoUser(phone) {

        const self=this;

        const formData = new FormData();

        var AppThis = this.props.AppThis;

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('phone', phone);

        fetch(AppThis.ConstApp.home_api + this.props.path + "/user", {
            method: 'POST',
            body: formData
        }).then(data => data.json()).then(data => {

            if(data['status']==='ok'){
                if(_isset_object(data.data)){
                    self.UdateDataProfile(data.data);
                }
                else{

                    var DataView={
                        source:[],
                        user_login:phone,
                        username:null,
                        email:null,
                        tmp_user: null,
                        birthday:null,
                        sex:0,
                        address_contact:null,
                        citizen_identification:null,
                        card_user:null,};

                    self.setState({
                        DataUser:lodash.cloneDeep(DataView),
                        DataView:lodash.cloneDeep(DataView)
                    });
                }

            }
            AppThis.PageLoad(false);

        }).catch((e) => {
            AppThis.PageLoad(false);
        });

    }

    UpdateItemData(name,value){

        var DataUser=this.state.DataUser;

        DataUser[name]=value;

        this.setState({
            DataUser:DataUser
        });

        if(name==='user_login' && value.length===10){

            var phone=value.replace("_","");

            if(phone.length===10){
                this.getInfoUser(phone);
            }

        }

    }

    UploadAvatarNow(event){

        var fileUploaded = event.target.files[0];

        const self=this;

        if((fileUploaded.size/1024)<3000){

            if(fileUploaded.type==='image/png' || fileUploaded.type==='image/jpg' || fileUploaded.type==='image/jpeg'){
                let reader = new FileReader();

                reader.readAsDataURL(fileUploaded);

                var DataUser=this.state.DataUser;

                reader.onload = (e) => {

                    DataUser['avatar']= e.target.result;

                    self.setState({
                        DataUser: DataUser,
                    });


                }
            }
            else{
                toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
            }


        }
        else{
            toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
        }

    }

    SetTypeUser(number){

        var DataUser={};

        if(number===0){

            DataUser={
                source:[],
                user_login:null,
                username:null,
                email:null,
                tmp_user: null,
                birthday:null,
                sex:0,
                address_contact:null,
                citizen_identification:null,
                card_user:null,
            };

        }
        else{
            DataUser={
                source:[],
                user_login:randomNumber(),
                username:null,
                email:null,
                tmp_user: null,
                birthday:null,
                sex:0,
                address_contact:null,
                citizen_identification:null,
                card_user:null,
            };
        }

        var DataView=lodash.cloneDeep(DataUser);


        this.setState({
            TypeUser:number,
            DataUser:DataUser,
            DataView:DataView
        });

    }

    renderData(){

        const self=this;

        const AppThis=this.props.AppThis;

        const profile=this.props.AppThis.GetInfoProfile();

        if(this.state.DataUser!==null){
            return(
                <div className={"col-12 ModalUser Edit-Profile"}>
                    <div className={"row"}>

                        <div className={"col-12 col-sm-12 col-md-6 pb-3 position-relative"}>

                            <label className="font-weight-400 mb-1">SĐT/ID Tài Khoản <span className="text-red me-3">( * )</span>

                                {this.state.DisableChangeType===false?(
                                    <>
                                        {ListTypeUser.map(function(item){
                                            return(
                                                <label onClick={()=>{
                                                    self.SetTypeUser(item.key);
                                                }} className={"position-relative ps-3 me-4 d-inline-block"}>
                                                    <input type={'radio'} checked={self.state.TypeUser === item.key} className={"position-absolute start-0 w-auto mt-1 top-0"} name={'type_user'} />
                                                    {item.value}
                                                </label>
                                            );
                                        })}
                                    </>
                                ):(
                                    <></>
                                )}
                            </label>


                            {this.state.TypeUser===0?(
                                <div className={"col-12 position-relative"}>


                                    {typeof this.props.DisableEditPhone!=='undefined' && this.props.DisableEditPhone===true?(
                                        <>

                                            {<InputMask disabled mask="9999999999" onChange={event=>{
                                                this.UpdateItemData('user_login',event.target.value);
                                            }} key={"user_login_modal"} defaultValue={this.state.DataUser.user_login} />}
                                        </>
                                    ):(
                                        <>

                                            {<InputMask mask="9999999999" onChange={event=>{
                                                this.UpdateItemData('user_login',event.target.value);
                                            }} key={"user_login_modal"} defaultValue={this.state.DataUser.user_login} />}
                                        </>
                                    )}

                                </div>
                            ):(
                                <div className={"col-12 position-relative"}>
                                   <input type={'text'} className={"form-control w-100 rounded-0 bg-light"} disabled={true} />
                                </div>
                            )}



                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Họ và tên <span className="text-red">( * )</span></label>
                          
                                <input type="text" onChange={(event)=>{
                                    this.UpdateItemData('username',event.target.value);
                                }} key={'username'} defaultValue={this.state.DataUser.username?this.state.DataUser.username:this.state.DataUser.tmp_user} placeholder="Họ và tên" required="required" className="form-control w-100" />
                           
                        </div>



                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Email</label>
                         
                                <input type="email" onChange={(event)=>{
                                    this.UpdateItemData('email',event.target.value);
                                }} key={'email_modal'} defaultValue={this.state.DataUser.email} placeholder="Email" required="required" className="form-control w-100" />
                          

                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Sinh nhật</label>
                            <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat="dd/MM/yyyy" selected={this.state.DataUser.birthday?new Date(this.state.DataUser.birthday*1000):new Date()} onChange={(date) => {
                                this.UpdateItemData('birthday',Math.ceil(date.getTime()/1000));
                            }} />

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Giới tính <span className="text-red">( * )</span></label>
                            {typeof this.state.DataUser.sex!=='undefined' && this.state.DataUser.sex===1?(
                                <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" checked="checked"  /> Nam</span>
                                    <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" /> Nữ</span>
                                </div>
                            ):(
                                <></>
                            )}

                            {typeof this.state.DataUser.sex!=='undefined' && this.state.DataUser.sex===0?(
                                <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" /> Nam</span>
                                    <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" /> Nữ</span>
                                </div>
                            ):(
                                <></>
                            )}

                            {typeof this.state.DataUser.sex==='undefined'?(
                                <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" /> Nam</span>
                                    <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" /> Nữ</span>
                                </div>
                            ):(
                                <></>
                            )}


                        </div>

                        {profile.type!==3?(
                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Nguồn</label>

                                <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                    {this.state.Source!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var get_source_select=get_data_in_args(selectedOption,"value");
                                            var DataUser=self.state.DataUser;
                                            DataUser['source']=get_source_select;
                                            self.setState({
                                                DataUser:DataUser
                                            });
                                        }} defaultValue={array_to_select(this.state.DataView.source,'name','_id')} isMulti options={array_to_select(this.state.Source,'name','_id')} />
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>
                            </div>
                        ):(<></>)}

                        {profile.type===1?(
                            <>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <label className="font-weight-400 mb-1">Mã số thẻ</label>
                                    <input onChange={event=>{
                                        this.UpdateItemData('card_user',event.target.value);
                                    }} type={"text"} key={"card_user_modal"} defaultValue={this.state.DataUser.card_user} />
                                </div>
                            </>
                        ):(
                            <></>
                        )}

                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Địa chỉ liên hệ
                            </label>

                           
                                <input type="text" onChange={(event)=>{
                                    this.UpdateItemData('address_contact',event.target.value);
                                }} key={'address_contact_modal'} defaultValue={this.state.DataUser.address_contact} placeholder="Địa chỉ liên hệ" required="required" className="form-control w-100" />
                          

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">CMTND/CCCD
                            </label>

                                <input type="text" onChange={(event)=>{
                                    this.UpdateItemData('citizen_identification',event.target.value);
                                }} key={'citizen_identification_modal'} defaultValue={this.state.DataUser.citizen_identification} placeholder="CNND/CCCD" required="required" className="form-control w-100" />
                          

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="d-block font-weight-400 mb-3">Ảnh đại diện</label>
                            <div className={"row"}>
                                <div className={"col-6 position-relative d-flex flex-row justify-content-center"}>

                                    {this.state.DataUser.avatar!==null && typeof this.state.DataUser.avatar!=='undefined'?(
                                        <div className={"col-12 p-0"}>
                                        <div className={"box-avatar-100 d-flex justify-content-center align-self-center align-items-center"}>
                                            <img src={this.state.DataUser.avatar} />
                                        </div>
                                        </div>
                                    ):(
                                        <div className={"col-12 p-0"}>

                                            {_isset_string(this.state.DataView.avatar)?(
                                                <div className={"box-avatar-100 d-flex justify-content-center align-self-center align-items-center"}>
                                                    <img src={AppThis.ConstApp.home_url+this.state.DataView.avatar} />
                                                </div>
                                            ):(
                                                <div className={"box-avatar-100 d-flex justify-content-center align-self-center align-items-center"}>
                                                    <img src={AppThis.ConstApp.home_url+'/assets/images/004-boy-1.svg'} />
                                                </div>
                                            )}
                                        </div>

                                    )}

                                </div>

                                <div className={"col-6 position-relative"}>

                                    <a onClick={()=>{
                                        this.AlertUploadAvatar();
                                    }} href={"#"} className={"font26 upload-images-avatar text-dark"}>
                                        <i className="fas fa-camera-retro" />
                                        <input onChange={(event)=>{
                                            this.UploadAvatarNow(event);
                                        }} ref={hiddenFileInput} accept="image/*"  type={"file"} className={"d-none"} />
                                    </a>


                                </div>


                            </div>


                        </div>

                    </div>
                </div>
            );
        }
        else{
           return(
               <div className={"col-12 justify-content-center align-content-center d-flex"}>
                   <ItemLoading />
               </div>
           );
        }

    }

    SubmitModal(){

        var number_error=0;
        var DataUser=this.state.DataUser;

        ['username','user_login'].map(function(item){
            if(DataUser[item]===null || typeof DataUser[item]==='undefined' || DataUser[item].length<3){
                number_error+=1;
            }
        });

        if(DataUser['user_login']!==null && DataUser['user_login'].length<10){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng kiểm tra lại");
        }
        else{
           this.props.SubmitUser(DataUser);
        }

    }

    AlertUploadAvatar(){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Thông báo !</h1>
                        <p>Tải lên hình ảnh cho khách hàng ?</p>
                        <button onClick={()=>{
                            hiddenFileInput.current.click();
                            onClose();
                        }}>
                            Từ máy tính
                        </button>
                        <button
                            onClick={() => {
                                self.setState({
                                    EnableWebcam:true
                                });

                                onClose();
                            }}
                        > Sử dụng Camera
                        </button>
                    </div>
                );
            }
        });

    }


    CaptureCamera(){

        var image=this.webcamRef.current.getScreenshot();

        if(image!==null){

            var DataUser=this.state.DataUser;

            DataUser.avatar=image;

            this.setState({
                DataUser:DataUser
            });

        }

        this.setState({
            EnableWebcam:false
        });

    }

    render() {

        return(
            <>
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg modal-dialog-scrollable"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tài khoản khách hàng</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                {this.state.DataView!==null?(
                                    this.renderData()
                                ):(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>



                        </div>

                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.SubmitModal();
                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                Cập nhật
                                            </a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>


                {this.state.EnableWebcam===true?(

                    <div className="modal position-fixed d-flex w-100 h-100 flex-row align-items-end justify-content-end">

                        <div className={"modal-dialog m-0"}>

                            <div className="modal-content shadow bg-white border-0 rounded-0">
                                <div className="modal-header bg-header-user text-white rounded-0">
                                    <strong className={"font16 text-uppercase"}>
                                        <i className="fas fa-camera me-2" />
                                        CAMERA CHỤP ẢNH
                                    </strong>
                                </div>

                                <div className={"modal-body"}>

                                    <div className={"box-WebCam"}>
                                        <Webcam
                                            audio={false}
                                            height={320}
                                            ref={this.webcamRef}
                                            screenshotFormat="image/png"
                                            width={320}
                                            videoConstraints={{
                                                width: 320,
                                                height: 320,
                                                facingMode: "environment"
                                            }}
                                        />
                                    </div>

                                    <div className={"row mt-3"}>

                                        <div className={"col-6"}>
                                            <a href={"#"} onClick={()=>{
                                                this.setState({
                                                    EnableWebcam:false
                                                });
                                            }} className={"btn btn-dark rounded-0 w-100"}>
                                                <i className="fas fa-window-close me-1" />
                                                Hủy</a>
                                        </div>
                                        <div className={"col-6"}>
                                            <a href={"#"} onClick={()=>{
                                                this.CaptureCamera();
                                            }} className={"btn btn-primary rounded-0 w-100"}>
                                                <i className="fas fa-camera me-1" />
                                                Chụp ảnh</a>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div onClick={()=>{

                            this.setState({
                                EnableWebcam:false
                            });
                        }} className={"bg-modal position-absolute w-100 vh-100 top0 left0"} />

                    </div>

                ):(
                    <></>
                )}


            </>
        );

    }

}
