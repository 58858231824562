/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    _isset, _isset_array, array_to_select, base64_encode,
    get_id_in_object, get_item_in_array, getItemInArray, timeConverter
} from "../../helper/array";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {toast} from "react-toastify";
import DetailWallet from "./DetailWallet";
import {get_type_edit_contract, type_wallet} from "../../helper/member";
import DetailContract from "../contract/DetailContract";
import lodash from "lodash";


const INIT_STATE={
    ActiveTab:1,
    InfoPay:null,
    PaymentMethod:null,
    InfoPayment:null,
    data:{
        _id:null,
        id_payment:null,
        id_bank:null,
        price:0,
        time:Math.ceil(new Date().getTime()/1000),
        status:0,
        time_update:Math.ceil(new Date().getTime()/1000),
        type_of_paid:1,
        number_votes:null
    },
    DataDetailContract:null,
    history:null
};

export default class EditTransaction extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    componentDidMount() {
        this.StartRun();
        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.OnClose!=='undefined'){
                this.props.OnClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    async StartRun(){

       await this.getPaymentMethod();

        if(_isset(this.props.data)){

            var get_data_props=this.props.data;

            this.UpdateData(get_data_props);

            this.GetHistoryTransaction(get_data_props._id);
        }

    }

    GetHistoryTransaction(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        var data={id_history:id};

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('data', base64_encode(data));

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/historyedit", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    history:data.data
                });
            }

        }).catch((e)=>{

        });

    }

    async getPaymentMethod(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;
        fetch(AppThis.ConstApp.home_api+"getpayment", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    PaymentMethod:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    UpdateData(data){

        var data_update={};

        ["_id","price","time","status","time_update","type_of_paid",'number_votes'].map(function(item){
            if(typeof data[item]!=='undefined'){
                data_update[item]=data[item];
            }
            else{
                data_update[item]=null;
            }
        });

        data_update=get_id_in_object(data_update,["id_payment","id_bank"],data);

        var InfoPayment=null;

        if(this.state.PaymentMethod!==null){
            InfoPayment=getItemInArray(data_update['id_payment'],"_id",this.state.PaymentMethod);
        }

        this.setState({
            InfoPay:data,
            data:data_update,
            InfoPayment:InfoPayment
        });

    }

    UpdateItemData(key=null,value=null){

        var data=this.state.data;

        data[key]=value;

        this.setState({
            data:data
        });

    }

    async SaveTransaction(){

        var data=this.state.data;

        var number_error=0;

        ['_id','time','status','time_update','type_of_paid','price'].map(function (item){
            if (data[item]===null){
                number_error+=1;
            }
        });

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var user_update={};

            ['_id','time','status','time_update','type_of_paid','price','id_payment','number_votes'].map(function (item){
                user_update[item]=data[item];
            });

            ['id_bank'].map(function(item){
                if (data[item]!==null){
                    user_update[item]=data[item]['_id'];
                }
                else{
                    user_update[item]=null;
                }
            });


            var AppThis=this.props.AppThis;

            AppThis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', AppThis.getToken());

            formData.append('id_address', AppThis.getAddress());

            formData.append('data',base64_encode(user_update));

            var get_data=await fetch(AppThis.ConstApp.home_api+this.props.path+"/save", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();

            if(get_data.status==='ok'){

                toast("Thao tác thành công");
            }
            else{

                toast("Thao tác không thành công");
            }

            AppThis.PageLoad(false);

            this.props.onSuccess();

        }

    }

    RenderEdit(){

        const self=this;

        if(this.state.DataDetailContract!==null){
            return(
                <DetailContract OnClose={()=>{
                    this.setState({
                        DataDetailContract:null
                    });

                }} EventDetail={(id)=>{
                    this.setState({
                        DataDetailContract:null
                    },function(){
                        self.getDetailContract(id);
                    });
                }} data={this.state.DataDetailContract} />
            );
        }
        else{

            return(
                <div className={"col-12"}>

                    <ul className={"nav-theme nav nav-tabs mt-3 mb-3"}>

                        <li className={"nav-item"}>
                            <a onClick={()=>{
                                this.setState({
                                    ActiveTab:1
                                });
                            }}  className={this.state.ActiveTab===1?("nav-link active"):("nav-link")}>
                                Chỉnh sửa phiếu thu
                            </a>
                        </li>

                        <li className={"nav-item"}>
                            <a onClick={()=>{
                                this.setState({
                                    ActiveTab:2
                                });
                            }}  className={this.state.ActiveTab===2?("nav-link active"):("nav-link")}>
                                Chi tiết giao dịch
                            </a>
                        </li>

                        <li className={"nav-item"}>
                            <a onClick={()=>{
                                this.setState({
                                    ActiveTab:3
                                });
                            }}  className={this.state.ActiveTab===3?("nav-link active"):("nav-link")}>
                                Lịch sử
                            </a>
                        </li>

                    </ul>


                    {this.state.ActiveTab===1?(
                        <>
                            <div className={"alert alert-warning"}>
                                <i className="fas fa-exclamation-triangle me-2"></i>
                                Loại Phiếu thu :   <strong> {type_wallet(self.state.InfoPay)}</strong>
                            </div>

                            {self.state.InfoPay.id_member!==null && typeof self.state.InfoPay.id_member.id_member_source!=='undefined' && _isset_array(self.state.InfoPay.id_member.id_member_source)?(
                                <>
                                    <div className={"col-12 p-2 alert-warning rounded-0 alert m-0 mb-3"}>
                                        Hợp đồng <strong>
                                        <a href={"#"} className={"text-primary"} onClick={()=>{
                                            var DataDetailContract=self.state.InfoPay;
                                            this.setState({
                                                DataDetailContract:DataDetailContract
                                            });
                                        }}>
                                            {self.state.InfoPay.id_member.number_contract}
                                        </a>
                                    </strong> {type_wallet(self.state.InfoPay)} từ HD

                                        {self.state.InfoPay.id_member.id_member_source.map(function(v){
                                            return(
                                                <span className={"ms-1 me-1 badge font12 bg-primary font-weight-bold"}>{v.number_contract}</span>
                                            );
                                        })}

                                    </div>
                                </>
                            ):(
                                <>
                                </>
                            )}


                            <div className={"row"}>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                                    <p className={"mb-1"}>Mã PT <span className={"text-red"}>( * )</span></p>
                                    <input onChange={(event)=>{
                                        this.UpdateItemData("number_votes",event.target.value);
                                    }} type={"text"} defaultValue={self.state.data.number_votes} placeholder={"Mã PT"} className={"form-control w-100 rounded-0"} />
                                </div>


                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                                    <p className={"mb-1"}>Giá tiền <span className={"text-red"}>( * )</span></p>
                                    <input onChange={(event)=>{
                                        this.UpdateItemData("price",event.target.value);
                                    }} type={"number"} defaultValue={self.state.data.price} placeholder={"Giá tiền"} className={"form-control w-100 rounded-0"} />
                                </div>



                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-3"}>
                                    <p className={"mb-1"}>Thời gian lên PT <span className={"text-red"}>( * )</span></p>
                                    <DatePicker  selected={new Date(this.state.data.time*1000)} onChange={(date) => {
                                        if(date!==null){
                                            this.UpdateItemData("time",Math.ceil(date.getTime()/1000));
                                        }
                                    }} timeInputLabel="Thời gian :" dateFormat="dd/MM/yyyy h:mm aa" showTimeInput placeholderText={"Thời gian lên PT"} />

                                </div>


                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-3"}>
                                    <p className={"mb-1"}>Thời cập nhật PT <span className={"text-red"}>( * )</span></p>
                                    <DatePicker selected={new Date(this.state.data.time_update*1000)} onChange={(date) => {
                                        if(date!==null){
                                            this.UpdateItemData("time_update",Math.ceil(date.getTime()/1000));
                                        }

                                    }} timeInputLabel="Thời gian :" dateFormat="dd/MM/yyyy h:mm aa" showTimeInput placeholderText={"Thời gian cập nhật PT"} />

                                </div>


                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-3"}>
                                    <p className={"mb-1"}>Trạng thái phiếu thu  <span className={"text-red"}>( * )</span></p>

                                    <select onChange={(event)=>{
                                        this.UpdateItemData("status",event.target.value);
                                    }} className={"form-control rounded-0 w-100"}>
                                        {[
                                            {
                                                key:0,
                                                value:"Đang chờ"
                                            },
                                            {
                                                key:1,
                                                value:"Xác nhận"
                                            },
                                            {
                                                key:2,
                                                value:"Từ chối"
                                            }
                                        ].map(function (item){

                                            if(self.state.data.status===item.key){
                                                return(
                                                    <option selected={true} value={item.key}>{item.value}</option>
                                                );
                                            }
                                            else{
                                                return(
                                                    <option value={item.key}>{item.value}</option>
                                                );
                                            }

                                        })}
                                    </select>
                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-3"}>
                                    <p className={"mb-1"}>Loại thanh toán  <span className={"text-red"}>( * )</span></p>

                                    <select onChange={(event)=>{
                                        this.UpdateItemData("type_of_paid",event.target.value);
                                    }} className={"form-control rounded-0 w-100"}>
                                        {[
                                            {
                                                key:1,
                                                value:"Trả hết"
                                            },
                                            {
                                                key:2,
                                                value:"Đặt cọc"
                                            },
                                            {
                                                key:3,
                                                value:"Trả nốt"
                                            }
                                        ].map(function (item){

                                            if(self.state.data.type_of_paid===item.key){
                                                return(
                                                    <option selected={true} value={item.key}>{item.value}</option>
                                                );
                                            }
                                            else{
                                                return(
                                                    <option value={item.key}>{item.value}</option>
                                                );
                                            }
                                        })}
                                    </select>
                                </div>


                                {this.state.InfoPay.id_payment!==null?(
                                    <>
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-3"}>
                                            <p className={"d-block w-100 mb-2"}> Phương thức thanh toán <span className={"text-red"}>( * )</span></p>

                                            {this.state.PaymentMethod!==null?(
                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                        var data=self.state.data;
                                                        data['id_payment']=selectedOption.value;
                                                        var InfoPayment=getItemInArray(selectedOption.value,"_id",this.state.PaymentMethod);
                                                        self.setState({
                                                            InfoPayment:InfoPayment,
                                                            data:data
                                                        });

                                                    }
                                                    else {
                                                        var data=self.state.data;
                                                        data['id_payment']=null;
                                                        self.setState({
                                                            InfoPayment:null,
                                                            data:data
                                                        });
                                                    }
                                                }} defaultValue={get_item_in_array("value",self.state.data.id_payment,array_to_select(this.state.PaymentMethod,'name','_id'))} options={array_to_select(this.state.PaymentMethod,'name','_id')} />
                                            ):(
                                                <div className={"col-12 d-flex flex-row mt-2 justify-content-center"}>
                                                    <ItemLoading />
                                                </div>
                                            )}

                                        </div>
                                    </>

                                ):(
                                    <></>
                                )}

                                {this.state.InfoPayment!==null && this.state.InfoPayment.bank.length>0?(
                                    <div className={"d-flex flex-wrap flex-row"}>
                                        {this.state.InfoPayment.bank.map(function(item,index){
                                            return(
                                                <div className={"col-12 col-sm-6 col-md-4 col-lg-4 mb-3 p-2"}>

                                                    <div onClick={()=>{
                                                        self.UpdateItemData("id_bank",item._id);

                                                    }} className={self.state.data.id_bank===item._id?("col-12 border position-relative active_tab_box"):("col-12 border position-relative")}>

                                                        <div className={"col-12 p-2 mb-3 font-weight-bold border-bottom text-center font15 text-uppercase left-box-info-bank"}>
                                                            <p className={"m-0"}><i
                                                                className="fab fa-cc-amazon-pay" /> {item.name}</p>
                                                        </div>

                                                        <div className={"col-12 p-3 right-box-info-bank"}>

                                                            <p className={"m-0"}>
                                                                <i
                                                                    className="fas fa-user"/> Chủ tài khoản :
                                                                {item.user_account}
                                                            </p>
                                                            <p className={"m-0"}>
                                                                <i
                                                                    className="fab fa-cc-paypal" /> Số Tài khoản :
                                                                {item.account}
                                                            </p>

                                                        </div>

                                                    </div>


                                                </div>
                                            );
                                        })}
                                    </div>
                                ):(<></>)}

                                <div className={"col-12 mt-4 text-end"}>
                                    <a href={"#"} onClick={()=>{
                                        self.SaveTransaction();
                                    }} className={"btn btn-primary rounded-0 p-2 ps-4 pe-4"}>
                                        <i className="fas fa-save me-2"></i> Cập nhật
                                    </a>

                                </div>



                            </div>

                        </>
                    ):(
                        <></>
                    )}

                    {this.state.ActiveTab===2?(
                        <DetailWallet data={this.state.InfoPay} />
                    ):(
                        <></>
                    )}


                    {this.state.ActiveTab===3?(
                        <>
                            {this.HistoryTransaction()}
                        </>
                    ):(
                        <></>
                    )}

                </div>
            );

        }

    }

    HistoryTransaction(){

        if(this.state.history!==null && this.state.history.length>0){
            return(
                <>
                    <div className={"col-12 mt-4"}>

                        <h4 className="d-inline-block mb-3 font16 mt-3 text-uppercase font-weight-bold text-dark border-primary border-bottom pb-2">
                            Lịch sử chỉnh sửa phiếu thu
                        </h4>

                        <div className={"col-12 overflow-auto"}>

                            <table className={"mt-3 w-100 table border-0 mw800 table-style-old"}>
                                <thead>
                                <tr className={"border-bottom"}>
                                    <td className={"font-weight-bold text-uppercase font14 p-2 text-black bg-light-bold"}>
                                        <i className="far fa-user" /> Người thay đổi
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2 text-black bg-light-bold"}>
                                        <i className="far fa-clock" /> Thời gian
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2 text-black bg-light-bold"}>
                                        <i className="fas fa-plus" />  Hành động
                                    </td>

                                </tr>
                                </thead>
                                <tbody>
                                {this.state.history.map(function(item,index){
                                    return(<tr>
                                        <td>
                                            <span>{item.id_admin.username}</span>
                                        </td>
                                        <td>
                                            <span>{timeConverter(item.time_create)}</span>
                                        </td>
                                        <td>
                                            Sửa phiếu thu
                                        </td>

                                    </tr>);
                                })}

                                </tbody>

                            </table>

                        </div>



                    </div>
                </>
            );
        }
        else{
            return(
                <div className={"alert alert-warning"}>
                    Chưa có lịch sử nào
                </div>
            );
        }
    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }

    render() {
        const self=this;

        if(this.state.InfoPay!==null){
            var data=this.state.InfoPay;

            return(
                <div className="modal d-block position-fixed">
                    <div className={"modal-dialog modal-lg container-fluid"}>
                        <div className="modal-content shadow border-0 mw800px m-auto">
                            <div className="modal-header">

                                    <div className={"row w-100 m-0"}>

                                        <div className={"col-8 p-0"}>
                                            <strong className={"font18 text-white text-black text-black text-uppercase"}>Chỉnh sửa phiếu thu</strong>
                                        </div>

                                        <div className={"col-4 p-0 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.OnClose();
                                    }} className={"text-white right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                        </div>
                                    </div>

                            </div>
                            <div className="modal-body">


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-12"}>

                                            {this.RenderEdit()}

                                        </div>


                                    </div>




                            </div>


                        </div>
                    </div>
                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );

        }
        else{
            return(
                <div className={"col-12 mt-3 mb-3 d-flex flex-row w-100 align-items-center justify-content-center"}>
                    <ItemLoading size={"Large"}  />
                </div>
            );
        }

    }

}
