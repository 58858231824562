/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import lodash from "lodash";
import {
    array_to_select,
    get_data_in_args,
    _isset_object, get_item_in_array,
} from "../../helper/array";
import Select from 'react-select';
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";

export default class EditSale extends Component{

    constructor(props) {
        super(props);

        this.state= {
            DataModal:null,
            AllCard:null,
            data:null
        };

    }

    componentDidMount() {

        this.getCard();

        if(typeof this.props.data!=='undefined'){

            var data=this.props.data;

            var DataModal=lodash.cloneDeep(data);

            if(_isset_object(DataModal.id_card)){
                DataModal['id_card']=DataModal.id_card['_id'];
            }
            else{
                DataModal['id_card']=null;
            }

            this.setState({
                DataModal:DataModal,
                data:data
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getCard(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address',AppThis.getAddress());

        fetch(AppThis.ConstApp.home_api+"card", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{
            this.setState({
                AllCard:data.data
            });

        });

    }

    UpdateItemData(name,value){

        var DataModal=this.state.DataModal;

        DataModal[name]=value;

        this.setState({
            DataModal:DataModal
        });

    }

    renderData(){

        const self=this;

        var DataModal=this.state.DataModal;

            return(
                <div className={"row m-0"}>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Tên KM <span className="text-red">( * )</span></label>
                        <input type="text" onChange={(event)=>{
                            this.UpdateItemData('name',event.target.value);
                        }} defaultValue={DataModal.name} placeholder="Tên KM" required="required" className="form-control w-100" />
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Giá bán <span className="text-red">( * )</span></label>
                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('price',parseInt(event.target.value));
                        }} defaultValue={DataModal.price} placeholder="Giá niêm yết" required="required" className="form-control w-100" />

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Thẻ tập <span className="text-red">( * )</span></label>


                        <div className={"col-12 p-0 Box-Select-Mutiple"}>
                            {this.state.AllCard!==null?(

                                <Select isSearchable={true} onChange={(selectedOption)=>{

                                    var GetDataModal=self.state.DataModal;

                                    GetDataModal['id_card']=selectedOption['value'];

                                    self.setState({
                                        DataModal:GetDataModal
                                    });

                                }} defaultValue={get_item_in_array('value',DataModal.id_card,array_to_select(this.state.AllCard,'name','_id'))} options={array_to_select(this.state.AllCard,'name','_id')} />
                            ):(
                                <ItemLoading />
                            )}
                        </div>

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                        <label className="font-weight-400 mb-1">Thời gian bắt đầu <span className="text-red">( * )</span></label>

                        <DatePicker dateFormat="dd/MM/yyyy" onChange={(date) => {
                            if(date!==null){
                                this.UpdateItemData('time_start',date.getTime()/1000);
                            }
                        }} selected={DataModal.time_start?new Date(DataModal.time_start*1000):""} placeholderText={"Thời gian bắt đầu"} />

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                        <label className="font-weight-400 mb-1">Thời gian kết thúc <span className="text-red">( * )</span></label>

                        <DatePicker dateFormat="dd/MM/yyyy" onChange={(date) => {
                            if(date!==null){
                                this.UpdateItemData('time_end',date.getTime()/1000);
                            }
                        }} selected={DataModal.time_end?new Date(DataModal.time_end*1000):""} placeholderText={"Thời gian kết thúc"} />

                    </div>




                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Ghi chú KM</label>
                        <input type="text" onChange={(event)=>{
                            this.UpdateItemData('note',event.target.value);
                        }} defaultValue={this.state.DataModal.note} placeholder="Ghi chú KM" required="required" className="form-control w-100" />

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Số buổi tập <span className="text-red">( * )</span></label>
                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('number_card',parseInt(event.target.value));
                        }} defaultValue={DataModal.number_card} placeholder="Số buổi tập" required="required" className="form-control w-100" />
                    </div>


                </div>
            );

    }

    SubmitModal(){

        var DataModal=this.state.DataModal;

        var number_error=0;

        ['name','price','time_start','time_end','id_card','number_card'].map(function(value,key){
            if(typeof DataModal[value]==="undefined" || DataModal[value]===null){
                number_error+=1;
            }
        });

        if(number_error===0 && typeof this.props.EventSubmit!=='undefined'){
            this.props.EventSubmit(DataModal);
        }
        else{
            toast("Vui lòng nhập đầy đủ thông tin");
        }
    }
    render() {
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Cập nhật chương trình KM</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                {this.state.DataModal===null?(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                ):(
                                    <>
                                        {this.renderData()}
                                    </>
                                )}

                            </div>

                        </div>

                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>

                                            {this.state.DataModal!==null?(
                                                <a onClick={()=>{
                                                    this.SubmitModal();
                                                }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                    Cập nhật
                                                </a>
                                            ):(
                                                <></>
                                            )}

                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
