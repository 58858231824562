/* eslint-disable */
import React,{Component} from "react";
import no_avatar from "../../assets/images/_no_avatar.png";
import ItemLoading from "../ItemLoading";
import { getDayOfWeek,
    range, timeConverter,
    timeConverterDate,
    GetTimesheet, TimeInSheet,
    randomColor
} from "../../helper/array";
import {toast} from "react-toastify";


export default class DetailTimeKeeping extends Component {

    constructor(props) {
        super(props);

        this.state={
            data:null,
            id_admin:null,
            page:0,
            statics:null
        };

    }

    componentDidMount() {

        if(this.props.data!==null && this.state.id_admin===null){

            const self=this;

            this.setState({
                id_admin:this.props.data._id
            },function (){

                self.getDetailTimeKeeping();
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }
    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getDetailTimeKeeping(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_admin',this.state.id_admin);

        formData.append('page',this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/detail", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");


        });

    }

    RenderGetTimeKeeping(){

        const self=this;

        return(
            <div className={"col-12 RenderGetTimeKeeping"}>

                {this.state.data['data'].length>0?(
                    <div className={"col-12 ListRenderGetFingerprint"}>


                        <table className={"w-100 table-responsive w-100 table-time-sheet"}>
                           <thead>
                           <tr>
                               <td className={"p-2 text-center font-weight-bold text-uppercase"}>ngày</td>
                               <td className={"p-2 text-center font-weight-bold text-uppercase"}>thứ</td>
                               {range(1,23).map(function(hours){
                                   return(
                                       <td className={"p-2 text-center font-weight-bold text-uppercase"}>{hours}</td>
                                   );
                               })}
                           </tr>
                           </thead>
                            <tbody>
                            {this.state.data['data'].map(function(item,index){
                                var get_range_time=GetTimesheet(item.data);

                                var color=randomColor();
                                return(
                                    <tr>
                                        <td className={"border-end"}>{timeConverterDate(item._id)}</td>
                                        <td className={"border-end"}>{getDayOfWeek(item._id)}</td>


                                        {range(1,23).map(function (value,key){

                                            var get_range=TimeInSheet(value,get_range_time);
                                            if(get_range!==false){
                                                return(
                                                    <td className={"parrent-active-hours"}>

                                                        <div style={{
                                                            backgroundColor:color
                                                        }} className={"active-hours"} />

                                                        <div className={"position-relative"}>
                                                            <div className={"opacity-0 content-tooltips shadow bg-white"}>
                                                                <div className={"header-tooltips p-2 text-uppercase font-weight-bold bg-light"}>Chi tiết</div>
                                                                <div className={"body-tooltips p-2"}>
                                                                    <p className={"m-0"}>Bắt đầu: {timeConverter(get_range['detail']['start'])}</p>
                                                                    <p className={"m-0"}>Kết thúc: {timeConverter(get_range['detail']['end'])}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            }
                                            else{
                                                return(<td>&nbsp;</td>);
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>




                        <div className={"col-12 mt-3 mb-2"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page,
                                                data:null
                                            },function(){
                                                self.getDetailTimeKeeping();
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next,
                                                data:null
                                            },function(){
                                                self.getDetailTimeKeeping();
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>




                    </div>
                ):(
                    <div className={"alert alert-danger rounded-0"}>
                        Không có dữ liệu
                    </div>
                )}

            </div>
        );
    }

    render(){

        if(this.props.data!==null){
            return(
                <div className="modal d-block position-fixed modal-dialog-end">
                    <div className={"modal-dialog modal-dialog-scrollable modal-dialog-scrollable modal-lg container-fluid"}>
                        <div className="modal-content shadow border-0 rounded-0">
                            <div className="modal-header bg-light rounded-0">
                                <strong className={"font16 text-uppercase text-black"}>
                                    <i className="far fa-user me-2" />
                                    Chi tiết chấm công nhân viên</strong>
                            </div>
                            <div className="modal-body">

                                <div className={"row mb-2 mt-2"}>

                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-3 d-none d-lg-block left-info-user"}>

                                        <div className={"col-12 mb-4 text-center"}>

                                            {this.props.data.avatar!==null ?(
                                                <div className={"box-avatar-150 m-auto"}>
                                                    <img src={this.props.AppThis.ConstApp.home_url +"/" +this.props.data.avatar} />
                                                </div>

                                            ):(
                                                <div className={"box-avatar-150 m-auto"}>
                                                    <img  src={no_avatar} />
                                                </div>
                                            )}

                                        </div>

                                        <div className={"row mb-2 bg-light p-2"}>
                                            <div className={"col-5 col-md-5"}>
                                                <i className="far fa-user" /> Họ và tên
                                            </div>
                                            <div className={"col-7 col-md-7"}>
                                                <span className={"font-weight-bold"}>{this.props.data.username}</span>
                                            </div>
                                        </div>

                                        <div className={"row mb-2 p-2"}>
                                            <div className={"col-5 col-md-5"}>
                                                <i className="fas fa-phone-alt" /> Số điện thoại
                                            </div>
                                            <div className={"col-7 col-md-7"}>
                                                <span>{this.props.data.phone_number}</span>
                                            </div>
                                        </div>


                                        {this.state.statics!==null && Object.keys(this.state.statics).length>0?(
                                          <div className={"col-12 StaticsTimeKeeping"}>

                                              <div className={"row p-2 border-bottom bg-light"}>

                                                  <div className={"col-6 col-md-6"}>
                                                      Đi muộn
                                                  </div>
                                                  <div className={"col-6 col-md-6"}>
                                                      <span>
                                                          {this.state.statics.time_late} Phút
                                                      </span>
                                                  </div>


                                              </div>

                                              <div className={"row p-2 border-bottom"}>

                                                  <div className={"col-6 col-md-6"}>
                                                      Về sớm
                                                  </div>
                                                  <div className={"col-6 col-md-6"}>
                                                      <span>{this.state.statics.time_soon} Phút</span>
                                                  </div>


                                              </div>

                                              <div className={"row p-2 border-bottom bg-light"}>

                                                  <div className={"col-6 col-md-6"}>
                                                      Tăng ca
                                                  </div>
                                                  <div className={"col-6 col-md-6"}>
                                                      <span>{this.state.statics.overtime} Phút</span>
                                                  </div>


                                              </div>

                                              <div className={"row p-2 border-bottom"}>

                                                  <div className={"col-6 col-md-6"}>
                                                      Số buổi đi muộn
                                                  </div>
                                                  <div className={"col-6 col-md-6"}>
                                                      <span>{this.state.statics.number_day_late}</span>
                                                  </div>


                                              </div>

                                              <div className={"row p-2 border-bottom bg-light"}>

                                                  <div className={"col-6 col-md-6"}>
                                                      Về sớm
                                                  </div>
                                                  <div className={"col-6 col-md-6"}>
                                                      <span>{this.state.statics.number_day_soon}</span>
                                                  </div>


                                              </div>

                                              <div className={"row p-2 border-bottom"}>

                                                  <div className={"col-6 col-md-6"}>
                                                      Không chấm công
                                                  </div>
                                                  <div className={"col-6 col-md-6"}>
                                                      <span>{this.state.statics.not_checkin}</span>
                                                  </div>


                                              </div>


                                          </div>
                                        ):(<></>)}

                                    </div>

                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-9 right-info-user"}>

                                        <div className={"col-12 overflow-auto"}>
                                            {this.state.data!==null?(
                                                this.RenderGetTimeKeeping()
                                            ):(
                                                <div className={"col-12 text-center d-flex align-items-center justify-content-center align-self-center h-100"}>
                                                    <ItemLoading size={"Large"} />
                                                </div>
                                            )}

                                        </div>


                                    </div>



                                </div>

                            </div>


                            <div className={"modal-footer bg-light"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"col-12 col-sm-12 col-lg-2 offset-lg-10"}>
                                        <a onClick={()=>{
                                            this.props.onClose();
                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                            Đóng
                                        </a>
                                    </div>

                                </div>


                            </div>



                        </div>
                    </div>
                    <div onClick={()=>{
                        this.props.onClose();
                    }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );
        }
        else{
            return(<></>);
        }

    }
}
