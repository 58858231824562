/* eslint-disable */
import React,{Component} from "react";
import lodash from "lodash";
import {toast} from "react-toastify";
import { array_to_select,
    base64_encode,
} from "../../helper/array";
import DatePicker from "react-datepicker";

const INIT_STATE={
    TYPE_REPORT:[
        {
            key:'BCDTBDV',
            value:"Báo cáo doanh thu bán dịch vụ"
        },
        {
            key:'BCDTBL',
            value:"Báo cáo doanh thu bán lẻ"
        },
        {
            key:'BCDTBHTNV',
            value:"Báo cáo chi tiết doanh thu bán hàng theo nhân viên"
        },
        {
            key:'BCDTBLTNV',
            value:"Báo cáo chi tiết doanh thu bán lẻ theo nhân viên"
        },
        {
            key:'BCTTTK',
            value:"Báo cáo thu tiền tài khoản"
        },
        {
            key:'BCTTM',
            value:"Báo cáo thu tiền mặt"
        },
        {
            key:'BCCTSPTB',
            value:"Báo cáo chi tiết show PT bán"
        },
        {
            key:'BCSPTD',
            value:"Báo cáo chi tiết show PT dạy"
        },
        {
            key:'BCCTSLNCD',
            value:"Báo cáo chi tiết show LNCĐ"
        },
        {
            key:'BCTHCNPT',
            value:"Báo cáo tổng hợp công nợ phải thu"
        },
        {
            key:'BCCTSDPTTNV',
            value:"Báo cáo chi tiết show PT dạy theo nhân viên"
        },
        {
            key:'BCCTSPTBTNV',
            value:"Báo cáo chi tiết show PT bán theo nhân viên"
        },
        {
            key:'BCRVHV',
            value:"Báo cáo ra vào hội viên"
        },
        {
            key:'BCTTHD',
            value:"Báo cáo tình trạng HD"
        },
        {
            key:'BCTDTDT',
            value:"Báo cáo theo dõi tủ đồ thuê"
        },
        /*
        {
            key:'BCTKC',
            value:"Báo cáo tổng kết ca"
        }
         */
        {
            key:'BCN',
            value:"Báo cáo ngày"
        },
        {
            key:'BCKHTT',
            value:"Báo cáo khách hàng tập thử"
        },
        {
            key:'BCKHM',
            value:"Báo cáo khách hàng mới"
        },
        {
            key:'BCCSR',
            value:"Báo cáo CSR"
        }
    ],
    query:{
        type:null,
        start_time:Math.ceil(new Date().getTime()/1000),
        end_time:Math.ceil(new Date().getTime()/1000),
        id_address:null
    },
    AllAddress:null
};

export default class reportaccountant extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    GetAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('type', 3);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var address=[{'name':"Tất cả","_id":"none"}];

                var AllAddress=address.concat(data.data);
                self.setState({
                    AllAddress:AllAddress
                });
            }

        }).catch((e)=>{



        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.GetAddress();
    }


    SubmitAction(){

        var Appthis=this.props.AppThis;

        var number_error=0;

        var query=this.state.query;

        ['type','start_time','end_time'].map(function(item){

            if(query[item]===null || query[item]==='' || typeof query[item]==='undefined'){
                number_error+=1;
            }
        });

        if(number_error>0){

            toast("Vui lòng nhập đầy đủ thông tin");
            return;
        }

        Appthis.PageLoad(true);

        const formData = new FormData();

        var data=this.getQuerySearch();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('query',base64_encode(data));

        fetch(Appthis.ConstApp.home_api+"reportaccountant", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Dữ liệu đã gửi tới máy chủ");
            }
            else{
                toast("Thao tác không thành công.Xin vui lòng thử lại");
            }

            Appthis.PageLoad(false);

        }).catch((e)=>{

            Appthis.PageLoad(false);
        });
    }


    render(){
        const self=this;

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-4 pb-5 mt-2 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-12 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> BÁO CÁO KẾ TOÁN</h3>
                            </div>


                        </div>


                        <div className={"col-12 ps-3 mt-3 pe-3"}>

                            <div className={"row"}>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 customCssSelect"}>
                                    <label className={"font15 mb-1"}>Chọn loại báo cáo
                                        <span className={"text-red"}>( * )</span>
                                    </label>


                                    <select className={"form-control rounded-0 w-100"} onChange={(e)=>{
                                        var query=self.state.query;
                                        query['type']=e.target.value;
                                        self.setState({
                                            query:query
                                        });

                                    }}>
                                        <option value={null}>Chọn loại báo cáo</option>
                                        {this.state.TYPE_REPORT.map(function(item,index){
                                            return(
                                                <option value={item.key}>{item.value}</option>
                                            );
                                        })}
                                    </select>



                                </div>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 customCssSelect"}>
                                    <label className={"font15 mb-1"}>Chọn cơ sở</label>

                                    <select className={"form-control rounded-0 w-100"} onChange={(e)=>{
                                        var query=self.state.query;
                                        query['id_address']=e.target.value;
                                        self.setState({
                                            query:query
                                        });

                                    }}>

                                        {this.state.AllAddress!==null?(
                                            <>
                                                {this.state.AllAddress.map(function(item,index){
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                })}
                                            </>
                                        ):(
                                            <></>
                                        )}

                                    </select>



                                </div>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 box_input"}>
                                    <label className={"font15 mb-1"}>Thời gian bắt đầu
                                        <span className={"text-red"}>( * )</span>
                                    </label>

                                    {self.state.query.start_time!==null?(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date(this.state.query.start_time*1000)} onChange={(date) => {
                                            var query=self.state.query;
                                            var tmp_time=null;
                                            if(date!==null){
                                                tmp_time=Math.ceil(date.getTime()/1000);
                                            };
                                            query['start_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />

                                    ):(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date()} onChange={(date) => {
                                            var query=self.state.query;
                                            var tmp_time=null;
                                            if(date!==null){
                                                tmp_time=Math.ceil(date.getTime()/1000);
                                            }
                                            query['start_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />
                                    )}


                                </div>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 box_input"}>

                                    <label className={"font15 mb-1"}>Thời gian kết thúc
                                        <span className={"text-red"}>( * )</span>
                                    </label>


                                    {self.state.query.end_time!==null?(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date(this.state.query.end_time*1000)} onChange={(date) => {
                                            var query=self.state.query;

                                            var tmp_time=null;
                                            if(date!==null){
                                                tmp_time=Math.ceil(date.getTime()/1000);
                                            }
                                            query['end_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />

                                    ):(
                                        <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date()} onChange={(date) => {
                                            var query=self.state.query;
                                            var tmp_time=null;


                                            if(date!==null){
                                                tmp_time=Math.ceil(date.getTime()/1000);
                                            }
                                            query['end_time']=tmp_time;
                                            self.setState({
                                                query:query
                                            });
                                        }} />
                                    )}




                                </div>

                                <div className={"col-12 col-sm-12 mb-2 col-md-6 col-lg-3 align-items-end d-flex align-self-end"}>

                                    <a onClick={()=>{
                                        this.SubmitAction();
                                    }} className={"btn w-100 d-block btn-primary rounded-0 mt-3"}>
                                        Tạo báo cáo
                                    </a>

                                </div>

                            </div>

                        </div>






                    </div>


                </div>
            );


    }


}
