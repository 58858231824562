/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {getDayOfWeek,
    range, timeConverter,
    timeConverterDate,
    GetTimesheet, TimeInSheet,
    randomColor
} from "../../helper/array";
import {toast} from "react-toastify";

export default class mymarketingtimekeeping extends Component {

    constructor(props) {
        super(props);

        this.state={
            data:null,
            id_admin:null,
            page:0,
            statics:null
        };

    }

    componentDidMount() {
        this.getData();
    }

    getData(load=false){

        var Appthis=this.props.AppThis;

        if(load===true){
            Appthis.PageLoad(true);
        }

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page',this.state.page);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"timekeeping", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });

            }

            if(load===true){
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");

            if(load===true){
                Appthis.PageLoad(false);
            }


        });

    }

    RenderGetTimeKeeping(){

        const self=this;

        return(
            <div className={"col-12 RenderGetTimeKeeping"}>

                {this.state.data['data'].length>0?(
                    <div className={"col-12 overflow-auto ListRenderGetFingerprint"}>

                        <table className={"w-100 table-responsive w-100 table-time-sheet"}>
                            <thead>
                            <tr>
                                <td className={"p-2 text-center font-weight-bold text-uppercase"}>ngày</td>
                                <td className={"p-2 text-center font-weight-bold text-uppercase"}>thứ</td>
                                {range(1,23).map(function(hours){
                                    return(
                                        <td className={"p-2 text-center font-weight-bold text-uppercase"}>{hours}</td>
                                    );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.data['data'].map(function(item,index){
                                var get_range_time=GetTimesheet(item.data);
                                var color=randomColor();
                                return(
                                    <tr>
                                        <td className={"border-end"}>{timeConverterDate(item._id)}</td>
                                        <td className={"border-end"}>{getDayOfWeek(item._id)}</td>

                                        {range(1,23).map(function (value,key){

                                            var get_range=TimeInSheet(value,get_range_time);
                                            if(get_range!==false){
                                                return(
                                                    <td className={"parrent-active-hours"}>

                                                        <div style={{
                                                            backgroundColor:color
                                                        }} className={"active-hours"} />

                                                        <div className={"position-relative"}>
                                                            <div className={"opacity-0 content-tooltips shadow bg-white"}>
                                                                <div className={"header-tooltips p-2 text-uppercase font-weight-bold bg-light"}>Chi tiết</div>
                                                                <div className={"body-tooltips p-2"}>
                                                                    <p className={"m-0"}>Bắt đầu: {timeConverter(get_range['detail']['start'])}</p>
                                                                    <p className={"m-0"}>Kết thúc: {timeConverter(get_range['detail']['end'])}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            }
                                            else{
                                                return(<td>&nbsp;</td>);
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>




                        <div className={"col-12 mt-3 mb-2"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page,
                                                data:null
                                            },function(){
                                                self.getData();
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next,
                                                data:null
                                            },function(){
                                                self.getData();
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>




                    </div>
                ):(
                    <div className={"alert alert-danger rounded-0"}>
                        Không có dữ liệu
                    </div>
                )}

            </div>
        );
    }

    render(){
            return(
                <div className={"container-fluid container-main-template mb-5"}>

                    <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>
                       <div className={"container"}>


                           <div className="row m-0">

                               <div className="col-12 col-sm-12 col-md-4 d-flex align-items-center">
                                   <h3 className="font18 pt-2 mb-0 text-uppercase font-weight-bold font-weight-bold"> Chấm công của tôi</h3>
                               </div>

                               <div className="col-12 col-sm-12 col-md-8 text-right text-end">

                                   <div className="col-12 p-0 mb-2">
                                       Tổng bản ghi : {this.state.data?this.state.data.total_row:0}
                                   </div>





                               </div>


                           </div>



                           <div className={"row mb-2 mt-2"}>

                               <div className={"col-12 col-sm-12 col-md-12 col-lg-12 right-info-user"}>

                                   {this.state.data!==null?(
                                       this.RenderGetTimeKeeping()
                                   ):(
                                       <div className={"col-12 text-center d-flex align-items-center justify-content-center align-self-center h-100"}>
                                           <ItemLoading size={"Large"} />
                                       </div>
                                   )}

                               </div>



                           </div>
                       </div>

                    </div>
                </div>
            );


    }
}
