/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {_isset_array, _isset_string, array_to_select, base64_encode, timeConverter,} from "../../helper/array";
import InfoUser from "../../global/user/InfoUser";
import Floater from 'react-floater';
import Select from "react-select";
import HistoryPush from "../../global/push/HistoryPush";
import CanvasJSReact from "../../helper/canvasjs.react";
import ContractUser from "../../global/contract/ContractUser";
import NewPush from "../../global/push/NewPush";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        id_admin:null
    },
    InfoUser:null,
    page:0,
    HistoryPush:{
        status:false,
        data:null
    },
    ListAdmin:null,
    statics:null,
    ContractUser:null,
    NewPush:{
        status:false
    }
};

export default class mycustomerteam extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }
    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mycustomerteam", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    getListAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mycustomerteam/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=[];

                ListAdmin=ListAdmin.concat(data.data);

                ListAdmin.unshift({
                    _id:null,
                    username:"Tất cả",
                    code_user:null
                });


                self.setState({
                    ListAdmin:ListAdmin
                });

            }



        }).catch((e)=>{

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getListAdmin();

        this.getStatics();
    }

    getStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mycustomerteam/reportpush", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.SetUpStaticsMonthPush(data.data);
            }

        }).catch((e)=>{

        });

    }

    SetUpStaticsMonthPush(data) {

        var data_report_total_user = [];

        Object.entries(data.total_user).forEach(entry => {
            const [key, value] = entry;
            data_report_total_user.push({
                x:new Date(key * 1000),
                y:value
            });

        });


        var data_report_total_push = [];

        Object.entries(data.total_push).forEach(entry => {
            const [key, value] = entry;
            data_report_total_push.push({
                x:new Date(key * 1000),
                y:value
            });

        });


        const options = {
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Khách hàng",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_total_user
                },
                {
                    type: "line",
                    showInLegend: true,
                    name: "Chuyển số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_total_push
                }

            ]
        };

        this.setState({
            statics:options
        });


    }

    ShowHistoryPush(data){

        this.setState({
            HistoryPush:{
                status:true,
                data:data
            }
        });

    }

    getContractUser(data){

        this.setState({
            ContractUser:data
        });
    }


    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên khách hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã khách hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Số lượng HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 mw150px"}>NV Trùng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>

                                    <td>{value['username']?value['username']:value['tmp_user']}</td>
                                    <td>{value['user_login']}
                                        {value.is_phone!==null?(
                                            <>
                                                <span className={"text-danger font-weight-bold font10 ms-2"}>{value.is_phone}</span>
                                            </>
                                        ):(<></>)}

                                    </td>
                                    <td>{value['code_user']}</td>
                                    <td>{value['count_contract']}</td>
                                    <td className={"mw150px"}>
                                        {typeof value['id_admin']!=='undefined' &&_isset_array(value['id_admin'])?(
                                            <>
                                                {value['id_admin'].map(function(item){
                                                   return(<span className={"badge bg-primary m-1"}>{item.code_user}</span>);
                                                })}
                                            </>
                                        ):(<></>)}
                                    </td>
                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.setState({
                                                InfoUser:value
                                            });
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Thông tin khách hàng">
                                            <i className="fas fa-info" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.getContractUser(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Danh sách HD của khách hàng">
                                                <i className="fas fa-file-contract" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.ShowHistoryPush(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-warning p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lịch sử chuyển số">
                                                <i className="fas fa-history" />
                                            </Floater>
                                        </a>






                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    render(){
        const self=this;

        if(this.state.ContractUser!==null){
            return(
                <ContractUser onClose={()=>{
                    self.setState({
                        ContractUser:null
                    });
                }} path={"mycustomerteam"} AppThis={this.props.AppThis} data={this.state.ContractUser} />
            );
        }
        else{
            if(this.state.data!==null){

                return(
                    <div className={"container-fluid container-main-template mb-5"}>


                        <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 col-md-12 d-flex align-items-center">
                                    <h3 className="font18 mb-0 text-uppercase font-weight-bold font-weight-bold"> Khách hàng của team</h3>
                                </div>

                                <div className="col-12 col-sm-12 text-right text-end">
                                    <div className="col-12 p-0 mb-2">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>

                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>



                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>




                                            <input onChange={(event)=>{
                                                this.UpdateQuerySearch("input_search",event.target.value);

                                            }} autoComplete={"off"} type="text" name="input_search"
                                                   className="font13 form-control d-inline-block w-auto rounded-0"
                                                   placeholder="Số điện thoại"/>

                                            {this.state.ListAdmin!==null?(
                                                <div className={"customCssSelect w200px text-start me-1"}>
                                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                                        this.UpdateQuerySearch("id_admin",selectedOption.value);
                                                    }} options={array_to_select(this.state.ListAdmin,'username','_id')} />
                                                </div>

                                            ):(<></>)}

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>

                                            <a onClick={()=>{
                                                this.setState({
                                                    NewPush:{
                                                        status:true
                                                    },
                                                    ModalSearchMobile:false
                                                });

                                            }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>



                                        </div>

                                    </div>





                                </div>

                            </div>



                            <div className="col-12 pt-3 ps-3 pe-3 text-uppercase font-weight-bold pb-3 font16">
                                Khách hàng trong tháng
                            </div>

                            <div className="col-12 pt-3 ps-3 pe-3 mb-5">
                                {this.state.statics!==null?(
                                    <div className={"col-12 w-100 position-relative"}>
                                        <CanvasJSChart options={this.state.statics} />
                                    </div>
                                ):(
                                    <div className={"col-12 text-center d-flex justify-content-center"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>



                            <div className={"col-12 ps-3 pe-3"}>
                                {this.thisRenderData()}
                            </div>




                            <div className={"col-12 ps-3 pe-3"}>
                                <ul className="pagination">
                                    {this.state.data.prev>=0?(
                                        <li className="page-item" >
                                            <a className="page-link" onClick={()=>{
                                                var page=this.state.page-1;
                                                this.setState({
                                                    page:page
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Trước
                                            </a>
                                        </li>
                                    ):(<></>)}

                                    <li className="page-item">
                                        <a className="page-link">
                                            <span>{this.state.data.curent+1}</span>
                                        </a></li>

                                    {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                        <li className="page-item">
                                            <a className="page-link" onClick={()=>{
                                                this.setState({
                                                    page:this.state.data.next
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Kế tiếp
                                            </a>
                                        </li>
                                    ):(<></>)}

                                </ul>
                            </div>



                        </div>


                        {this.state.InfoUser!==null?(

                            <InfoUser data={this.state.InfoUser} onClose={()=>{
                                this.setState({
                                    InfoUser:null
                                });
                            }} />
                        ):(<></>)}



                        {this.state.HistoryPush.status===true?(

                            <HistoryPush path={"mycustomerteam"} AppThis={this.props.AppThis} data={this.state.HistoryPush.data} onClose={()=>{
                                this.setState({
                                    HistoryPush:{
                                        status:false,
                                        data:null
                                    }
                                });

                            }} />
                        ):(<></>)}


                        {this.state.NewPush.status===true?(
                            <NewPush onClose={()=>{
                                this.setState({
                                    NewPush:{
                                        status:false,
                                    }
                                });

                            }} path={"mycustomerteam"} AppThis={this.props.AppThis} />
                        ):(
                            <></>
                        )}

                    </div>
                );
            }
            else{

                return(
                    <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                        <ItemLoading size={"Large"} />
                    </div>
                );
            }

        }

    }


}
