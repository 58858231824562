/* eslint-disable */
import React,{Component} from "react";
import {
    _isset,
    timeConverterDate,
    formatNumber
} from "../../helper/array";
import no_avatar from "../../assets/images/_no_avatar.png";
import ConstApp from "../../ConstApp";


export default class SidebarContract extends Component{

    constructor(props) {
        super(props);
    }

    render() {

        const self=this;

        if(_isset(this.props.data)){
            var data=this.props.data;
            return(
            <>
                <div className={"alert alert-primary m-0 rounded-0 font14 text-uppercase font-weight-bold"}>
                    <i className="fas fa-exclamation-triangle" /> Thông tin hội viên
                </div>

                <div className={"col-12 border-start border-end"}>
                    <div className={"col-12 d-flex mb-3 justify-content-center align-items-center"}>
                        {data.id_user.avatar!==null?(
                                <img src={ConstApp.home_url+data.id_user.avatar} className={"w-100"} />
                        ):(
                            <img src={no_avatar} className={"w-100"}/>
                        )}


                    </div>

                    <div className={"row m-0 p-2 bg-light border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-user me-1" /> Họ và tên
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            <span>{data.id_user.username}</span>
                        </div>

                    </div>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="fas fa-phone-alt me-1" /> SDT / Tài khoản
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            <span>{data.id_user.user_login}</span>
                        </div>

                    </div>
                    <div className={"row m-0 p-2 bg-light border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="fas fa-genderless me-1" /> Giới tính
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            {data.id_user.sex===1?(
                                <span>Nam</span>
                            ):(
                                <span>Nữ</span>
                            )}
                        </div>

                    </div>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="fas fa-shield-alt me-1" /> mã hội viên
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            {data.id_user.code_user}
                        </div>

                    </div>

                </div>

                <div className={"alert alert-success m-0 rounded-0 font14 text-uppercase font-weight-bold"}>
                    <i className="fas fa-exclamation-triangle" /> Thông tin thẻ tập
                </div>

                <div className={"col-12 border-start bg-light border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="fas fa-map-marker-alt me-1" /> Cơ sở
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            <span>{data.id_address.name}</span>
                        </div>

                    </div>

                </div>
                <div className={"col-12 border-start border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="fas fa-sort-numeric-down me-1" />
                            Số HD
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            <span className={"font-weight-bold"}>{data.number_contract}</span>
                        </div>

                    </div>

                </div>
                <div className={"col-12 border-start bg-light border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-credit-card me-1" />
                            Gói thẻ
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            <span>{data.id_card.name}</span>
                        </div>

                    </div>

                </div>

                <div className={"col-12 border-start border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-clock me-1" /> Ngày bắt đầu
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            {timeConverterDate(data.start_day)}
                        </div>

                    </div>

                </div>

                <div className={"col-12 border-start bg-light border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-clock me-1" /> Ngày kết thúc
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            {timeConverterDate(data.end_day_contract)}
                        </div>

                    </div>

                </div>

                <div className={"alert alert-danger m-0 rounded-0 font-weight-bold font14 text-uppercase"}>
                    <i className="fab fa-amazon-pay" /> Thông tin thanh toán
                </div>

                <div className={"col-12 border-start border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-clock me-1" /> Giá niêm yết
                        </div>
                        <div className={"col-6 col-sm-6 p-0 font-weight-bold text-red"}>
                            {formatNumber(data.info_contract_data.price_sale)} VNĐ
                        </div>

                    </div>

                </div>
                <div className={"col-12 border-start bg-light border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-clock me-1" /> Phí thành viên
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            {formatNumber(data.info_contract_data.price_manager_card)} VNĐ
                            <span className={"font10 d-block"}>( Đã bao gồm )</span>
                        </div>

                    </div>

                </div>
                <div className={"col-12 border-start border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-clock me-1" /> VAT
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            {formatNumber(data.info_contract_data.price_vat)} VNĐ

                            <span className={"font10 d-block"}>( Đã bao gồm )</span>
                        </div>

                    </div>

                </div>
                <div className={"col-12 border-start bg-light border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-clock me-1" /> Phí bảo hiểm
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                            {formatNumber(data.info_contract_data.price_insurance)} VNĐ

                            <span className={"font10 d-block"}>( Đã bao gồm )</span>
                        </div>

                    </div>

                </div>
                <div className={"col-12 border-start border-end"}>

                    <div className={"row m-0 p-2 border-bottom"}>

                        <div className={"col-6 col-sm-6 p-0"}>
                            <i className="far fa-clock me-1" /> Đã thanh toán
                        </div>
                        <div className={"col-6 col-sm-6 p-0"}>
                                                     <span className="text-red font-weight-bold">
                                                        {formatNumber(data.billing_information.total_price)}
                                                    </span> VNĐ
                        </div>

                    </div>

                </div>
            </>
            );
        }
        else{
            return(
                <></>
            );
        }

    }


}
