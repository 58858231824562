/* eslint-disable */
import React,{Component} from "react";
import no_avatar from "../../assets/images/_no_avatar.png";
import ItemLoading from "../ItemLoading";
import {object_to_select, timeConverterDate} from "../../helper/array";
import {toast} from "react-toastify";
import Select from "react-select";
import {confirmAlert} from "react-confirm-alert";
export default class FingerAdmin extends Component {

    constructor(props) {
        super(props);

        this.state={
            data:null,
            machine:null,
            select_machine:null,
        };

    }

    componentDidMount() {

        if(this.props.data!==null){

            var get_id=this.props.data._id;

            this.GetFingerprint(get_id);

        }

        this.GetMachine();

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    GetFingerprint(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('id_user', id);

        const self=this;
        fetch(AppThis.ConstApp.home_api+this.props.path, {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");

        });

    }


    AlertAdd(id){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn lấy vân tay mới không ? </p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.AddFingerprint(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    AlertDelete(id){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn xoá vân tay ? </p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DeleteFingerprint(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    AddFingerprint(id){

        if(this.state.select_machine===null){
            return;
        }

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_user', id);

        formData.append('id_machine', this.state.select_machine);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/add", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thêm vân tay thất bại");
            }
            else{
                toast("Thêm vân tay thành công");

                self.setState({
                    data:null,
                },function(){
                    var get_id=this.props.data._id;
                    self.GetFingerprint(get_id);
                });
            }
        }).
        catch((e)=>{
            toast("Thêm vân tay thất bại");
        });



    }

    DeleteFingerprint(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_fingerprint', id);

        const self=this;
        fetch(AppThis.ConstApp.home_api+this.props.path+"/delete", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Xoá dữ liệu thất bại");
            }
            else{
                toast("Xoá dữ liệu thành công");

                self.setState({
                    data:null,
                },function(){

                    var get_id=this.props.data._id;

                    self.GetFingerprint(get_id);

                });



            }

        }).
        catch((e)=>{

            toast("Xoá dữ liệu thất bại");

        });



    }


    GetMachine(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        const self=this;
        fetch(AppThis.ConstApp.home_api+this.props.path+"/machine", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    machine:data.data
                });
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");

        });

    }

    RenderGetFingerprint(){
        const self=this;
        return(
            <div className={"col-12 RenderGetFingerprint"}>

                {this.state.machine!==null?(
                    <div className={"col-12 d-flex justify-content-end mb-3 flex-wrap flex-row"}>

                        <div className={"customCssSelect w200px me-1"}>
                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                var select_machine=selectedOption['value'];
                                self.setState({
                                    select_machine:select_machine
                                });
                            }} options={object_to_select(this.state.machine,'name','_id')} />
                        </div>

                        <a onClick={()=>{
                            if(this.props.data!==null){
                                self.AlertAdd(this.props.data._id);
                            }
                        }} className={"btn btn-outline-danger rounded-0 font13 btnActionFinger"}><i className="fas fa-fingerprint me-1" /> Lấy vân tay</a>
                    </div>

                ):( <div className={"col-12 text-center d-flex justify-content-center align-items-center mb-3"}><ItemLoading /></div>)}

                {this.state.data.length>0?(
                    <div className={"col-12 overflow-auto"}>


                        <table className={"mt-3 w-100 table border-0 mw1000"}>
                            <thead>
                            <tr className={"border-bottom bg-light"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Tên máy
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Thời gian
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    ID vân tay
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Địa chỉ
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Trạng thái
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Thao tác
                                </td>

                            </tr>
                            </thead>

                            <tbody>


                            {this.state.data.map(function(item,index){
                                return(
                                    <tr>

                                        <td>
                                            {item.id_machine?item.id_machine.name:"chưa rõ"}
                                        </td>

                                        <td>
                                            {timeConverterDate(item.time)}
                                        </td>

                                        <td>
                                            {item.number_index}
                                        </td>

                                        <td>
                                            {item.id_address.name}
                                        </td>

                                        <td>
                                            {item.encode_finger===true?(
                                                <i className="font15 text-primary far fa-check-circle" />
                                            ):(
                                                <i className="font15 text-danger fas fa-exclamation-circle" />
                                            )}
                                        </td>

                                        <td>
                                            <a onClick={()=>{
                                                self.AlertDelete(item._id);
                                            }}>
                                                <i className="far text-danger fa-trash-alt font18" />
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}


                            </tbody>
                        </table>




                    </div>
                ):(
                    <div className={"alert alert-danger rounded-0"}>
                        Không có dữ liệu
                    </div>
                )}

            </div>
        );
    }

    render(){

        if(this.props.data!==null){
            return(
                <div className="modal d-block position-fixed modal-dialog-end">
                    <div className={"modal-dialog modal-dialog-scrollable modal-dialog-scrollable modal-lg container-fluid"}>
                        <div className="modal-content shadow border-0 rounded-0">
                            <div className="modal-header bg-light rounded-0">
                                <strong className={"font16 text-uppercase text-black"}>
                                    <i className="far fa-user me-2" />
                                    Chi tiết vân tay nhân viên</strong>
                            </div>
                            <div className="modal-body">

                                <div className={"row mb-2 mt-2 DetailFingerBox"}>

                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-3 left-info-user"}>

                                        <div className={"col-12 mb-4 text-center"}>

                                            {this.props.data.avatar!==null ?(
                                                <div className={"box-avatar-150"}>
                                                    <img src={this.props.AppThis.ConstApp.home_url +"/" +this.props.data.avatar} />
                                                </div>

                                            ):(
                                                <div className={"box-avatar-150"}>
                                                    <img  src={no_avatar} />
                                                </div>
                                            )}
                                        </div>

                                        <div className={"row mb-2 bg-light p-2"}>
                                            <div className={"col-12 col-sm-12 col-md-5"}>
                                                <i className="far fa-user" /> Họ và tên
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-7"}>
                                                <span className={"font-weight-bold"}>{this.props.data.username}</span>
                                            </div>
                                        </div>

                                        <div className={"row mb-2 p-2"}>
                                            <div className={"col-12 col-sm-12 col-md-5"}>
                                                <i className="far fa-envelope" /> Email
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-7"}>
                                                <span className={"text-line"}>{this.props.data.email}</span>
                                            </div>
                                        </div>


                                        <div className={"row mb-2 bg-light p-2"}>
                                            <div className={"col-12 col-sm-12 col-md-5"}>
                                                <i className="fas fa-terminal" /> Mã NV
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-7"}>
                                                <span className={"font-weight-bold"}>{this.props.data.code_user}</span>

                                            </div>
                                        </div>

                                        <div className={"row mb-2 p-2"}>
                                            <div className={"col-12 col-sm-12 col-md-5"}>
                                                <i className="fas fa-phone-alt" /> Số điện thoại
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-7"}>
                                                <span>{this.props.data.phone_number}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 right-info-user"}>

                                        {this.state.data!==null?(
                                            this.RenderGetFingerprint()
                                        ):(
                                            <div className={"col-12 text-center d-flex align-items-center justify-content-center align-self-center h-100"}>
                                                <ItemLoading size={"Large"} />
                                            </div>
                                        )}

                                    </div>



                                </div>

                            </div>


                            <div className={"modal-footer bg-light"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"col-12 col-sm-12 col-lg-2 offset-lg-10"}>
                                        <a onClick={()=>{
                                            this.props.onClose();
                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                            Đóng
                                        </a>
                                    </div>

                                </div>


                            </div>



                        </div>
                    </div>
                    <div onClick={()=>{
                        this.props.onClose();
                    }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );
        }
        else{
            return(<></>);
        }

    }
}
