/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    timeConverter,
    base64_encode,
    array_to_select
} from "../../helper/array";
import ConstApp from "../../ConstApp";
import no_avatar from "../../assets/images/_no_avatar.png";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import Select from "react-select";
import lodash from "lodash";

export default class UpdateAdminUserClub extends Component{

    constructor() {
        super();
        this.state={
            data:null,
            AddNewAdmin:{
                status:false,
                _id:null
            },
            ListAdmin:null
        };

    }
    componentDidMount() {

        if(typeof this.props.data!=='undefined'){

            const self=this;

            this.setState({
                data:{
                    user:lodash.cloneDeep(this.props.data),
                    data:null
                }
            },function(){
                self.UpdateStateData();
            });

            this.getAdmin();
        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListAdmin:data.data
                });
            }


        }).catch((e)=>{

        });
    }

    UpdateStateData(){

        var user=this.state.data.user;

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('_id', user._id);

        const self=this;

        var DataUpdate=this.state.data;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/adminuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                DataUpdate.data=data.data;

                self.setState({
                    data:DataUpdate
                });

            }

        }).catch((e)=>{
            toast("Lấy dữ liệu thất bại");
        });


    }

    AlertDeleteAdminInUser(id_admin){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn xóa NV khỏi Khách hàng này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DeleteAdminInUser(id_admin);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }


    DeleteAdminInUser(id_admin){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        var data_update=[id_admin];

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('_id', this.state.data.user._id);

        formData.append('data', base64_encode(data_update));

        formData.append('action', "delete");

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/updateadminuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công");
            }

            var getData=self.state.data;

            getData.data=null;

            self.setState({
                data:getData
            },function(){
                self.UpdateStateData();
            });


            Appthis.PageLoad(false);


        }).catch((e)=>{

            toast("Thao tác thất bại");
            Appthis.PageLoad(false);
        });



    }

    AddAdminInUser(){

        if(this.state.AddNewAdmin._id===null){
            toast("Vui lòng chọn nhân viên");
            return;
        }

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        var data_update=[this.state.AddNewAdmin._id];

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('_id', this.state.data.user._id);

        formData.append('data', base64_encode(data_update));

        formData.append('action', "add");

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/updateadminuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công");
            }

            Appthis.PageLoad(false);

            self.setState({
                AddNewAdmin:{
                    status:false,
                    _id:null
                }
            },function(){
                self.UpdateStateData();
            });


        }).catch((e)=>{

            toast("Thao tác thất bại");
            Appthis.PageLoad(false);
        });



    }

    renderData(){

        var data=this.state.data;

        const self=this;

        if(data.data!==null){
            if(data.data.length>0){
                return(
                    <>
                        <div className={"col-12 overflow-auto"}>
                            <table className={"mt-3 w-100 table border-0 mw800"}>
                                <thead>
                                <tr className={"border-bottom bg-cloud"}>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-code" /> Mã NV
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-user" /> Họ và tên
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="far fa-clock" /> Ngày sở hữu
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        Thao tác
                                    </td>

                                </tr>
                                </thead>
                                <tbody>

                                {data.data.map(function(item,index){
                                    return(<tr>
                                        <td>
                                            <span>{item.code_user}</span>
                                        </td>
                                        <td>
                                            <span>{item.username}</span>
                                        </td>

                                        <td>
                                            {timeConverter(item.time)}
                                        </td>

                                        <td>
                                            <a href={"#"} onClick={()=>{
                                                self.AlertDeleteAdminInUser(item._id);
                                            }} className="btn btn-outline-danger rounded-0 btn-sm"><i
                                                className="fas fa-trash me-1" /> Xóa</a>
                                        </td>

                                    </tr>);
                                })}

                                </tbody>

                            </table>
                        </div>

                    </>
                );
            }
            else{
                return(
                  <>
                      <div className={"row m-0"}>
                          <div className={"col-12 alert alert-warning rounded-0"}>
                              Không có dữ liệu
                          </div>
                      </div>

                  </>
                );
            }

        }
        else{
            return(
                <div className={"col-12 justify-content-center align-content-center d-flex"}>
                    <ItemLoading />
                </div>
            );
        }

    }

    render() {

        if(this.state.data!==null){

            var data=this.state.data;

            return(
                <>
                    <div className="modal d-block position-fixed modal-dialog-end">
                        <div className={"modal-dialog modal-dialog-scrollable modal-lg container-fluid"}>
                            <div className="modal-content shadow border-0 rounded-0">
                                <div className="modal-header bg-cloud rounded-0">
                                    <strong className={"font16 text-uppercase text-white"}>
                                        <i className="far fa-user me-2" />Cập nhật nhân viên chăm sóc KH</strong>
                                </div>
                                <div className="modal-body">

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-12 d-none d-lg-block col-lg-3 col-md-3"}>
                                            <div className={"text-center mb-3"}>

                                                <div className={"box-avatar-150 m-auto"}>
                                                    {typeof data.user.avatar!=='undefined'?(
                                                        <>
                                                            {data.user.avatar===null?(
                                                                <img src={no_avatar} className={"rounded-circle"} style={{
                                                                    width:150
                                                                }} />
                                                            ):(
                                                                <img src={ConstApp.home_url+data.user.avatar} className={"rounded-circle"} style={{
                                                                    width:150
                                                                }} />
                                                            )}
                                                        </>
                                                    ):(
                                                        <img src={no_avatar} className={"rounded-circle"} style={{
                                                            width:150
                                                        }} />
                                                    )}
                                                </div>


                                            </div>

                                            <div className={"row p-2 mb-1 bg-light"}>

                                                <div className={"col-5 col-lg-5"}>
                                                    <i className="fas fa-user me-1" /> Họ và tên
                                                </div>

                                                <div className={"col-7 col-lg-7"}>
                                                    <span className={"badge font10 bg-info rounded-0 p-1"}> {data.user['username']?data.user['username']:data.user['tmp_user']}</span>
                                                </div>

                                            </div>

                                            <div className={"row p-2 mb-1"}>

                                                <div className={"col-5 col-sm-5 col-lg-5"}>
                                                    <i className="fas fa-phone me-1" /> Số điện thoại
                                                </div>

                                                <div className={"col-7 col-sm-7 col-lg-7"}>
                                                    <strong>{data.user.user_login}</strong>
                                                </div>

                                            </div>


                                            <div className={"row p-2 mb-1 bg-light"}>

                                                <div className={"col-5 col-sm-5 col-lg-5"}>
                                                    <i className="fas fa-stopwatch-20 me-1" /> Ngày sinh
                                                </div>

                                                <div className={"col-7 col-sm-7 col-lg-7"}>
                                                    {data.user['birthday']?(
                                                        <span>{timeConverter(data.user['birthday'])}</span>
                                                    ):(
                                                        <span className={"badge p-1 bg-danger font10"}>Chưa rõ</span>
                                                    )}
                                                </div>

                                            </div>

                                            <div className={"row p-2 mb-1"}>

                                                <div className={"col-5 col-sm-5 col-lg-5"}>
                                                    <i className="fas fa-genderless me-1" /> Giới tính
                                                </div>

                                                <div className={"col-7 col-lg-7"}>
                                                    {data.user['sex']===1?(
                                                        <span>Nam</span>
                                                    ):(
                                                        <span>Nữ</span>
                                                    )}
                                                </div>

                                            </div>


                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-9 col-md-12"}>

                                            <div className={"col-12 text-end mb-3 pb-2 border-bottom"}>
                                                <a href={"#"} onClick={()=>{
                                                    this.setState({
                                                        AddNewAdmin:{
                                                            status:true,
                                                            _id:null
                                                        }
                                                    })
                                                }} className={"btn btn-primary rounded-0"}><i className="fas fa-plus"/> Thêm mới</a>
                                            </div>

                                            {this.renderData()}
                                        </div>
                                    </div>

                                </div>


                                <div className={"modal-footer bg-light"}>

                                    <div className={"col-12 col-sm-12 col-lg-12"}>

                                        <div className={"col-12 col-sm-12 col-lg-2 offset-lg-10"}>
                                            <a onClick={()=>{
                                                this.props.onClose();
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Đóng
                                            </a>
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>


                        <div onClick={()=>{
                            this.props.onClose();
                        }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                    </div>

                    {this.state.AddNewAdmin.status===true?(
                        <>
                                <div className="modal d-block position-fixed modal-dialog-end">

                                    <div className={"modal-dialog modal-md modal-dialog-centered d-flex flex-row flex-wrap align-items-center justify-content-center"}>
                                        <div className="modal-content shadow border-0 rounded-0">
                                            <div className="modal-header bg-header-user text-white rounded-0 position-relative">
                                                <strong className={"font16 text-uppercase"}>
                                                    <i className="far fa-user me-2" />CẬP NHẬT NHÂN VIÊN CHĂM SÓC KH</strong>
                                                <a href={"#"} onClick={()=>{
                                                this.setState({
                                                    AddNewAdmin:{
                                                        status:false,
                                                        _id:null
                                                    }
                                                });
                                                }} className={"text-white"}><i className="fas fa-times"></i></a>

                                            </div>

                                            <div className="modal-body bg-white">
                                                <div className={"row m-0"}>
                                                    <div className={"col-12 col-sm-12 col-md-7 p-0"}>

                                                        <>
                                                            {this.state.ListAdmin!==null?(
                                                            <Select menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{

                                                                var AddNewAdmin=this.state.AddNewAdmin;

                                                                AddNewAdmin._id=selectedOption.value;

                                                                this.setState({
                                                                    AddNewAdmin:AddNewAdmin
                                                                });

                                                            }} options={array_to_select(this.state.ListAdmin,'username','_id')} />
                                                        ):(
                                                            <ItemLoading />
                                                        )}</>


                                                    </div>
                                                    <div className={"col-12 col-sm-12 col-md-5 p-0"}>
                                                        <a href={"#"} onClick={()=>{
                                                        this.AddAdminInUser();
                                                        }} className={"btn btn-danger rounded-0 w-100 d-block"}>
                                                            <i className="fas fa-plus me-2" /> Thêm</a>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>


                                    </div>

                                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

                                </div>
                        </>
                    ):(
                        <>

                        </>
                    )}


                </>
            );
        }
        else{
            return(<></>);
        }

    }

}
