/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "./ItemLoading";
import {_isset, get_data_in_args} from "../helper/array";
import { SketchPicker } from 'react-color';
import lodash from "lodash";
import DateTimePicker from 'react-datetime-picker';
import Select from "react-select";

export default class EditModal extends Component{

    constructor(props) {
        super(props);
        this.state={
            DataModal:{},
            data:null
        };

    }

    componentDidMount() {

        if(typeof this.props.data!=='undefined'){

            var data=lodash.cloneDeep(this.props.data);

            var update_data={};

            data.map(function(item){
                update_data[item.name]=item.value;
            });

            this.setState({
                DataModal:update_data,
                data:data
            })


        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{

        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }

    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    setDataObject(value,key){

        var GetData=this.state.DataModal;

        GetData[value]=key;

        this.setState({
            DataModal:GetData
        });

    }


    renderData(data){
        const self=this;
        if(data.length>0){
            return data.map(function(item,index){
                return(
                    <div className={typeof item.fullscreen!=='undefined' && item.fullscreen===true?"col-12 col-sm-12 col-md-12 col-lg-12 mb-2 item-render-data-event":"col-12 col-sm-12 col-md-12 col-lg-6 mb-2 item-render-data-event"}>
                        <label className={"d-block w-100 mb-1"}>
                            {item.title} <span className={"text-red"}>( * )</span>
                        </label>
                        <div className={"col-12 p-0 mb-2"}>

                            {item.type==='textarea'?(
                                <textarea onChange={(event)=>{
                                    self.setDataObject(item.name,event.target.value);
                                }} required={true} defaultValue={item.value} className={"form-control shadow-none font13 w-100 rounded-0 h100px"} placeholder={item.title}></textarea>
                            ):(<></>)}

                            {item.type==='input'?(
                                <input type={'text'} onChange={(event)=>{
                                      self.setDataObject(item.name,event.target.value);
                                }} required={true} defaultValue={item.value} className={"form-control w-100 rounded-0"} placeholder={item.title} />
                            ):(<></>)}

                            {item.type==='password'?(
                                <input type={'password'} onChange={(event)=>{
                                    self.setDataObject(item.name,event.target.value);
                                }} required={true} defaultValue={item.value} className={"form-control w-100 rounded-0"} placeholder={item.title} />
                            ):(<></>)}


                            {item.type==='number'?(
                                <input type={'number'} onChange={(event)=>{
                                    self.setDataObject(item.name,event.target.value);
                                }} required={true} defaultValue={item.value} className={"form-control w-100 rounded-0"} placeholder={item.title} />
                            ):(<></>)}


                            {item.type==='color'?(
                                <div className={"box-color-picker position-relative"}>
                                    <input defaultValue={item.value} value={self.state.DataModal[item.name]} type={"text"} placeholder={item.title} className={"form-control w-100 rounded-0"} />

                                    <span className={"view-color d-inline-block position-absolute top-0 right-0"} style={{
                                        backgroundColor:self.state.DataModal[item.name]
                                    }} />
                                   <div className={"position-absolute picker-color"}>
                                       <SketchPicker color={item.value} onChange={(event)=>{
                                           self.setDataObject(item.name,event.hex);
                                       }} />
                                   </div>

                                </div>
                            ):(<></>)}

                            {item.type==='select'?(
                                <>
                                    {typeof item.multiple!=='undefined' && item.multiple===true?(<>
                                        <Select
                                            isMulti
                                            options={item.data}
                                            classNamePrefix="select"
                                            onChange={(selectedOption)=>{
                                                var select_data=get_data_in_args(selectedOption,"value");
                                                self.setDataObject(item.name,select_data);

                                            }}
                                        />
                                    </>):(
                                        <>

                                            <select onChange={(event)=>{
                                                self.setDataObject(item.name,event.target.value);
                                            }} required={true} className={"font13 w-100 form-control rounded-0"}>
                                                <option value={''}>Vui lòng chọn</option>
                                                {item.data.map(function(value,key){
                                                    if(item.value===value.key){
                                                        return(
                                                            <option selected={true} value={value.key}>{value.value}</option>
                                                        )
                                                    }
                                                    else{
                                                        return(
                                                            <option value={value.key}>{value.value}</option>
                                                        )
                                                    }
                                                })}
                                            </select>
                                        </>
                                    )}
                                </>
                            ):(<></>)}

                            {item.type==='DateTimePicker'?(
                                      <DateTimePicker onChange={(value)=>{
                                          var timer=Math.ceil(value.getTime()/1000);
                                          self.setDataObject(item.name,timer);
                                      }} value={new Date(Math.ceil(parseInt(self.state.DataModal[item.name])*1000))} />
                            ):(<></>)}

                        </div>
                    </div>
                );
            });
        }
        else{
            return(<></>);
        }
    }

    SubmitModal(){

        var required=true;
        var response=this.state.DataModal;

        var number_error=0;

        if(Object.keys(this.state.DataModal).length>0){

            if(this.props.data.length>0){

                if(this.props.data.length!==Object.keys(this.state.DataModal).length){

                    number_error+=1;
                }
            }
            else{
                number_error+=1;
            }

        }
        else{
            number_error+=1;
        }


        if(Object.entries(response).length>0){

            Object.entries(response).map(function(item,index){

                item.map(function (value,key){

                    if(value===null || value==='' || value.length===0){

                        number_error+=1;

                    }

                });

            });
        }
        else{
            number_error+=1;
        }


        if(number_error!==0){
            required=false;
        }

        this.props.GetDataSubmit(response,required);
    }

    render() {

            if(_isset(this.props.title) && _isset(this.props.data)){
                return(
                    <div className="modal d-block position-fixed">
                        <div className={"modal-dialog "+(this.props.size==='small'?'modal-md':'modal-lg')}>
                            <div className="modal-content shadow border-0">
                                <div className="modal-header">
                                    <strong className={"font18 text-uppercase"}>{this.props.title}</strong>
                                </div>

                                {this.state.data!==null?(<>
                                        <div className="modal-body">

                                            <div className={"row mb-2 mt-2"}>
                                                {this.renderData(this.state.data)}

                                            </div>



                                        </div>
                                        <div className={"modal-footer bg-light"}>

                                            <div className={"w-100 row"}>

                                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                                    <div className={"row"}>

                                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                                            <a onClick={()=>{
                                                                this.SubmitModal();
                                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                                Cập nhật
                                                            </a>
                                                        </div>
                                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                                            <a onClick={()=>{
                                                                this.props.onClose()
                                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                                Huỷ
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </>
                                    ):(
                                    <>
                                      <div className={"col-12 text-center mt-4 mb-4 d-flex justify-content-center align-items-center"}>
                                          <ItemLoading />
                                      </div>
                                    </>
                                )}


                            </div>
                        </div>
                        <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                    </div>
                );
            }
            else{
                return(
                    <div className={"col-12 p-0 align-items-center text-center d-flex justify-content-center align-content-center"}>
                        <ItemLoading size={"Large"}  />
                    </div>
                );
            }


    }

}
