/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {timeConverterDate, _isset, timeConverter, _isset_string} from "../../helper/array";
import ConstApp from "../../ConstApp";
import logo from "../../assets/images/logo.png";
import no_avatar from "../../assets/images/_no_avatar.png";
const hiddenFileInput=React.createRef();

export default class InfoUser extends Component{

    componentDidMount() {

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    renderData(data=null){
        if(data!==null){
            return(
                <div className={"row Edit-Profile"}>

                    <div className="row border-bottom-0 m-0 mb-3">
                        <div className="col-12 background-red col-sm-12 pt-3 col-md-6 d-flex align-items-center justify-content-end text-uppercase font20 font-weight-bold text-white text-end">
                            KickFit <br /> Sport
                        </div>
                        <div className="col-12 col-sm-12 pt-3 pb-3 col-md-6 border-top border-bottom border-end">
                            <div className="row">
                                <div className="col-4 text-center d-flex justify-content-center align-items-center">
                                    <img src={logo} style={{
                                        width:80
                                    }} />
                                </div>

                                <div className="col-4 font40 d-flex align-items-center font-weight-bold text-center justify-content-center text-red"> VS</div>

                                <div className="col-4 text-center d-flex align-items-center justify-content-center right-avatar-user">

                                    <div className={"box-avatar-50"}>
                                    {typeof data.avatar!=='undefined'?(
                                        <>
                                            {data.avatar===null?(
                                                <img src={no_avatar} style={{
                                                    width:80
                                                }} />
                                            ):(
                                                <img src={ConstApp.home_url+data.avatar} className={"rounded-circle"} style={{
                                                    width:80
                                                }} />
                                            )}
                                        </>
                                    ):(
                                        <img src={no_avatar} style={{
                                            width:80
                                        }} />
                                    )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="far fa-user"/> Họ và tên </label>
                        <span className={"m-0 p-0 ms-3"}>{data.username?data.username:data.tmp_user}</span>
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="far fa-user"/> Mã khách hàng </label>
                        <span className={"m-0 p-0 ms-3"}>{data.code_user?data.code_user:'Chưa rõ'}</span>
                    </div>


                    {typeof data.count_contract!=='undefined'?(
                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                            <label className="font-weight-400 mb-1 font-weight-bold">
                                <i className="fas fa-sort-numeric-up-alt"></i> Số hợp đồng </label>
                            <span className={"m-0 p-0 ms-3"}>{data.count_contract}</span>
                        </div>

                    ):(
                        <></>
                    )}


                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="far fa-envelope" /> Email</label>

                        {data.email?(
                            <span className={"m-0 p-0 ms-3"}>{data.email}</span>
                        ):(
                            <span className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                        )}

                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                        <label className="font-weight-400 mb-1 font-weight-bold"><i
                            className="fas fa-birthday-cake me-1" />Sinh nhật</label>

                        {_isset(data.birthday)?(
                            <span className={"m-0 p-0 ms-3"}>{timeConverterDate(data.birthday)}</span>
                        ):(
                            <span  className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                        )}

                    </div>


                    {data.user_login?(
                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                            <label className="font-weight-400 mb-1 font-weight-bold"><i className="fas fa-phone-alt" />Điện thoại/ID tài khoản</label>

                            <span className={"m-0 p-0 ms-3"}>{data.user_login}</span>

                            {data.is_phone!==null?(
                                <>
                                    <span className={"badge bg-primary rounded-0 ms-2"}>{data.is_phone}</span>
                                </>
                            ):(<></>)}

                        </div>
                    ):(
                        <></>
                    )}



                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="fas fa-map-marker me-1"/> Địa chỉ thường trú</label>

                        {data.current_address?(
                            <span className={"ms-3"}>{data.current_address}</span>
                        ):(
                            <span  className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                        )}


                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="fas fa-user-shield" /> Địa chỉ liên hệ</label>

                        {data.address_contact?(
                            <span className={"m-0 p-0 ms-3"}>{data.address_contact}</span>
                        ):(
                            <span  className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                        )}


                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>


                                <label className="font-weight-400 mb-1 font-weight-bold"><i className="fas fa-mobile-alt" /> CMTND/CCCD</label>

                                {data.citizen_identification?(
                                    <span className={"m-0 p-0 ms-3"}>{data.citizen_identification}</span>
                                ):(
                                    <span  className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                                )}


                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold me-3"><i className="fas fa-genderless" /> Giới tính</label>

                        {data.sex?(
                            <span className={"d-inline-block ms-3 m-0 p-0"}>Nam</span>
                        ):(
                            <span className={"d-inline-block ms-3 m-0 p-0"}>Nữ</span>
                        )}

                    </div>



                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold me-3">
                            <i className="fab fa-facebook-f me-1"></i>
                        Facebook
                        </label>
                        {data.facebook?(
                            <span className={"m-0 p-0 ms-3"}>{data.facebook}</span>
                        ):(
                            <span className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                        )}

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold me-3">
                            <i className="fab fa-google-plus me-1"></i>
                            Google
                        </label>
                        {data.google?(
                            <span className={"m-0 p-0 ms-3"}>{data.google}</span>
                        ):(
                            <span className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                        )}

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold me-3">
                            <i className="far fa-id-card me-1"></i>
                            Mã số thẻ
                        </label>
                        {data.card_user?(
                            <span className={"m-0 p-0 ms-3"}>{data.card_user}</span>
                        ):(
                            <span className={"m-0 p-0 ms-3"}>Chưa rõ</span>
                        )}

                    </div>



                    {typeof data.time_create!=='undefined' && data.time_create!==null?(
                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                            <label className="font-weight-400 mb-1 font-weight-bold me-3">
                                <i className="far fa-clock"></i>
                                Thời gian khởi tạo
                            </label>
                            <span className={"m-0 p-0 ms-3"}>{timeConverter(data.time_create)}</span>
                        </div>
                    ):(
                        <></>
                    )}



                    {typeof data.source!=='undefined' && data.source.length>0?(
                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                            <label className="font-weight-400 mb-1 font-weight-bold me-3">
                                <i className="fas fa-share-alt"></i>
                                Nguồn
                            </label>
                            {data.source.map(function(item){
                                return(
                                    <span className={"badge bg-success m-1"}>{item.name}</span>
                                );
                            })}

                        </div>
                    ):(
                        <></>
                    )}

                    {typeof data.id_admin!=='undefined' && data.id_admin.length>0?(
                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                            <label className="font-weight-400 mb-1 font-weight-bold me-3">
                                <i className="fas fa-user-plus me-1"></i>
                                NV Marketing
                            </label>
                            {data.id_admin.map(function(item){
                                return(
                                    <span className={"badge bg-light text-black rounded-0 font12 m-1"}>{item.username} - {item.code_user}</span>
                                );
                            })}

                        </div>
                    ):(
                        <></>
                    )}

                    {typeof data.id_admin_club!=='undefined' && data.id_admin_club.length>0?(
                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                            <label className="font-weight-400 mb-1 font-weight-bold me-3">
                                <i className="fas fa-user-plus me-1"></i>
                                NV Club
                            </label>
                            {data.id_admin_club.map(function(item){
                                return(
                                    <span className={"badge bg-primary text-white rounded-0 font12 m-1"}>{item.username} - {item.code_user}</span>
                                );
                            })}

                        </div>
                    ):(
                        <></>
                    )}


                </div>
            );
        }
        else{
           return(
               <div className={"col-12 justify-content-center align-content-center d-flex"}>
                   <ItemLoading />
               </div>
           );
        }

    }

    render() {

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-dialog-scrollable modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Thông tin khách hàng</strong>
                        </div>
                        <div className="modal-body pt-0">

                            <div className={"col-12 mb-2 mt-2"}>
                                {this.props.data!==null?(this.renderData(this.props.data)):(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>

                        </div>


                        <div className={"modal-footer bg-light border-top-0"}>


                            <div className={"col-12 col-sm-12 col-lg-12"}>

                                <div className={"col-12 col-sm-12 col-lg-4 offset-lg-8"}>
                                    <a onClick={()=>{
                                        this.props.onClose()
                                    }} className={"btn btn-danger w-100 text-white mt-3"} >
                                        Đóng
                                    </a>
                                </div>

                            </div>


                        </div>



                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
