/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    _isset,
    timeConverterDate,
    formatNumber, input_price, remove_input_price, base64_encode, getItemInArray, array_to_select,
} from "../../helper/array";
import Select, {components} from 'react-select';
import lodash from "lodash";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import CSRContract from "./CSRContract";
import ConstApp from "../../ConstApp";
import no_avatar from "../../assets/images/_no_avatar.png";
import SidebarContract from "./SidebarContract";

const hiddenFileInput=React.createRef();

export default class UpdateDataContract extends Component{

    constructor(props) {
        super(props);

        this.state={
            description:null,
            PaymentMethod:null,
            InfoPayment:null,
             ActiveTab:1,
            GroupMember:null,
            GroupMemberStatics:null,
            DataUpdate:{
                time_start_temporary:Math.ceil(new Date().getTime()/1000),
                time_stop_temporary:Math.ceil(new Date().getTime()/1000),
                note:null,
                price:0,
                id_payment:null,
                id_bank:null
            },
            InfoContract:null,
            FileUpload:null,
            Tmp_Select_Group:{
                _id:null,
                type:null
            },
            Time_end_contract:null,
            Time_start_contract:null,
            number_did_pt:null,
            NumberIdentification:null,
            unit_price:null
        };

    }

    componentDidMount() {

        this.GetGroupMember();

        this.GetGroupMemberStatics();

        if(this.props.data && typeof this.props.data!=='undefined'){

            var InfoContract=lodash.cloneDeep(this.props.data);

            this.setState({
                InfoContract:InfoContract,
                Time_end_contract:InfoContract.end_day_contract,
                Time_start_contract:InfoContract.start_day,
                number_did_pt:InfoContract.info_contract_data.number_did_pt,
            });

        }

        this.getPaymentMethod();

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.OnClose!=='undefined'){
                this.props.OnClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getPaymentMethod(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"getpayment", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    PaymentMethod:data.data
                });
            }

        }).catch((e)=>{

        });


    }


    GetGroupMember(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            var GroupMember=[
                {
                    _id:null,
                    name:"Không"
                }
            ];

            GroupMember=GroupMember.concat(data.data);

            if(data.status==='ok'){
                self.setState({
                    GroupMember:GroupMember
                });
            }


        }).catch((e)=>{

        });



    }

    GetGroupMemberStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/groupstatic", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    GroupMemberStatics:data.data
                });
            }


        }).catch((e)=>{

        });



    }

    UploadFileImage(event){

        var fileUploaded = event.target.files[0];

        if((fileUploaded.size/1024)<3000){

            if(fileUploaded.type==='image/png' || fileUploaded.type==='image/jpg' || fileUploaded.type==='image/jpeg'){
                this.setState({
                    FileUpload:fileUploaded.name
                });

                let reader = new FileReader();

                reader.readAsDataURL(fileUploaded);

                const self=this;

                reader.onload = (e) => {

                    var DataUpdate=this.state.DataUpdate;

                    DataUpdate['file_confirmation']=e.target.result;

                    self.setState({
                        DataUpdate: DataUpdate,
                    });


                }

            }
            else{
                toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
            }


        }
        else{
            toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
        }

        //file_confirmation
    }

    ActionRefund(){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_member', this.state.InfoContract._id);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/refund", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Yêu cầu đã được gửi thành công");
            }
            else{
                toast("Gửi yêu cầu thất bại");
            }

            Appthis.PageLoad(false);

            self.setState({
                FileUpload:null
            });

            self.props.onSuccess();

        }).catch((e)=>{
            toast("Gửi yêu cầu thất bại");
            Appthis.PageLoad(false);

        });

    }


    AlertActionRefund(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn yêu cầu refund hợp đồng này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ActionRefund();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    StopDataContract(){

        var number_error=0;

        var DataUpdate=this.state.DataUpdate;

        ['time_start_temporary','time_stop_temporary','id_payment'].map(function(item){

            if(DataUpdate[item]===null || typeof DataUpdate[item]==='undefined'){
                number_error+=1;
            }

        });

        if(DataUpdate['price']===null || DataUpdate['price']===0 || DataUpdate['price'].length===0){
            number_error+=1;
        }


        if(this.state.InfoPayment && this.state.InfoPayment.bank.length>0){

            if(DataUpdate['id_bank']===null){
                number_error+=1;
            }
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_member', this.state.InfoContract._id);

            var data_submit=base64_encode(this.state.DataUpdate);

            formData.append('data', data_submit);

            const self=this;

            Appthis.PageLoad(true);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/stop", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){
                    toast("Thao tác thành công");
                }
                else{
                    toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");
                }

                Appthis.PageLoad(false);

                self.props.onSuccess();

            }).catch((e)=>{
                Appthis.PageLoad(false);
                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");
            });





        }

    }

    AlertCancelStopContract(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn dừng bảo lưu HD này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.CancelStopContract();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    CancelStopContract(){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_member', this.state.InfoContract._id);

            const self=this;

            Appthis.PageLoad(true);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/cancel", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){
                    toast("Thao tác thành công");
                }
                else{
                    toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");
                }

                Appthis.PageLoad(false);

                self.props.onSuccess();

            }).catch((e)=>{
                Appthis.PageLoad(false);
                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");
            });


    }

    UpdateUnitPrice(){

        var tmp_=this.state.unit_price;

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_member',this.state.InfoContract._id);

        formData.append('data',base64_encode({
            unit_price:tmp_
        }));

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/unitprice", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Cập nhật thành công");
            }
            else{
                toast("Cập nhật không thành công");
            }

            Appthis.PageLoad(false);

            self.props.onSuccess();

        }).catch((e)=>{
            Appthis.PageLoad(false);
        });

    }
    UpdateNumberIdentification(){

        var tmp_=this.state.NumberIdentification;

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_member',this.state.InfoContract._id);

        formData.append('data',base64_encode({
            NumberIdentification:tmp_
        }));

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/identification", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Cập nhật thành công");
            }
            else{
                toast("Cập nhật không thành công");
            }

            Appthis.PageLoad(false);

            self.props.onSuccess();

        }).catch((e)=>{
            Appthis.PageLoad(false);
        });



    }

    MoveToGroup(){

        var Tmp_Select_Group=this.state.Tmp_Select_Group;

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_member',this.state.InfoContract._id);

            formData.append('data',base64_encode(Tmp_Select_Group));

            const self=this;

            fetch(Appthis.ConstApp.home_api+this.props.path+"/togroup", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){
                   toast("Cập nhật thành công");
                }
                else{
                    toast("Cập nhật không thành công");
                }

                Appthis.PageLoad(false);

                self.props.onSuccess();

            }).catch((e)=>{
                Appthis.PageLoad(false);
            });



    }

    OptionLayoutGroupMember(props,GroupStatics){

        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{get_data_item.name}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>

                    {get_data_item.id_user?(<>
                        <p className={"m-0"}>Đại diện : {get_data_item.id_user.username+" - "+get_data_item.id_user.code_user}</p>
                    </>):(<></>)}

                </components.Option>
            );
        }
        else{
            return(<></>);
        }
    }


    RenderViewDataContract(){
        var data=this.state.InfoContract;
        const self=this;


        if(data!==null){
            switch (this.state.ActiveTab) {

                case 1:
                    return(
                        <div className={"row m-0"}>

                            {this.state.InfoContract.count_proccess===0?(
                                <>

                                {self.state.InfoContract.status!==0 && self.state.InfoContract.status!==3?(
                                    <div className={"col-12 p-0"}>

                                        <div className={"alert alert-info rounded-0"}>
                                            <i className="fas fa-exclamation-circle me-2" />
                                            Vui lòng chọn thời gian tạm dừng và thời gian tiếp tục của hợp đồng
                                        </div>


                                        <div className={"row"}>


                                            <div className="col-12 col-sm-12 col-md-6 box_input pb-3">
                                                <p className="mb-2">Tạm dừng từ ngày <span className="text-red">( * )</span>
                                                </p>

                                                <DatePicker dateFormat="dd/MM/yyyy" selected={new Date(this.state.DataUpdate.time_start_temporary*1000)} onChange={(date) => {

                                                    var DataUpdate=self.state.DataUpdate;

                                                    DataUpdate['time_start_temporary']=Math.ceil(date.getTime()/1000);

                                                    self.setState({
                                                        DataUpdate:DataUpdate
                                                    });

                                                }} />

                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 box_input pb-3">
                                                <p className="mb-2">Thời gian tiếp tục <span
                                                    className="text-red">( * )</span></p>


                                                <DatePicker dateFormat="dd/MM/yyyy" selected={new Date(this.state.DataUpdate.time_stop_temporary*1000)} onChange={(date) => {

                                                    var DataUpdate=self.state.DataUpdate;

                                                    DataUpdate['time_stop_temporary']=Math.ceil(date.getTime()/1000);

                                                    self.setState({
                                                        DataUpdate:DataUpdate
                                                    });

                                                }} />

                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 box_input pb-3">
                                                <p className="mb-2">Số tiền <span className="text-red">( * )</span></p>

                                                <input value={input_price(self.state.DataUpdate.price)} type={"text"} onChange={(event)=>{

                                                    try{
                                                        var DataUpdate=self.state.DataUpdate;
                                                        DataUpdate['price']=remove_input_price(event.target.value);
                                                        this.setState({
                                                            DataUpdate:DataUpdate
                                                        });
                                                    }
                                                    catch (e){

                                                    }

                                                }} placeholder={"Số tiền"} className={"form-control w-100 rounded-0"} />

                                            </div>

                                            <div className="col-12 col-sm-12 col-md-6 box_input pb-3">
                                                <p className="mb-2">Ghi chú :</p>
                                                <input type="text" id="note" name="note" className="form-control" onChange={(event)=>{

                                                    var DataUpdate=self.state.DataUpdate;
                                                    DataUpdate['note']=event.target.value;
                                                    self.setState({
                                                        DataUpdate:DataUpdate
                                                    });

                                                }} placeholder="Ghi chú"/>
                                            </div>




                                            <div className={"col-12 col-sm-12 col-md-6"}>
                                                <p className={"d-block w-100 mb-2"}> Phương thức thanh toán <span className={"text-red"}>( * )</span></p>

                                                {this.state.PaymentMethod!==null?(
                                                    <Select isSearchable={true} onChange={(selectedOption)=>{

                                                        var DataUpdate=self.state.DataUpdate;

                                                        if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                            DataUpdate['id_payment']=selectedOption.value;

                                                            var InfoPayment=getItemInArray(selectedOption.value,"_id",this.state.PaymentMethod);
                                                            self.setState({
                                                                InfoPayment:InfoPayment,
                                                                DataUpdate:DataUpdate
                                                            });

                                                        }
                                                        else {
                                                            DataUpdate['id_payment']=null;
                                                            self.setState({
                                                                InfoPayment:null,
                                                                DataUpdate:DataUpdate
                                                            });
                                                        }

                                                    }} options={array_to_select(this.state.PaymentMethod,'name','_id')} />
                                                ):(
                                                    <ItemLoading />
                                                )}


                                            </div>


                                            {this.state.InfoPayment!==null && this.state.InfoPayment.bank.length>0?(
                                                <div className={"d-flex flex-wrap flex-row"}>
                                                    {this.state.InfoPayment.bank.map(function(item,index){
                                                        return(
                                                            <div className={"col-12 col-sm-6 col-md-4 col-lg-4 mb-3 p-2"}>

                                                                <div onClick={()=>{
                                                                    var DataUpdate=self.state.DataUpdate;
                                                                    DataUpdate['id_bank']=item._id;

                                                                    self.setState({
                                                                        DataUpdate:DataUpdate
                                                                    });

                                                                }} className={self.state.DataUpdate.id_bank===item._id?("col-12 border position-relative active_tab_box"):("col-12 border position-relative")}>

                                                                    <div className={"col-12 p-2 mb-3 font-weight-bold border-bottom text-center font15 text-uppercase left-box-info-bank"}>
                                                                        <p className={"m-0"}><i
                                                                            className="fab fa-cc-amazon-pay" /> {item.name}</p>
                                                                    </div>

                                                                    <div className={"col-12 p-3 right-box-info-bank"}>

                                                                        <p className={"m-0"}>
                                                                            <i
                                                                                className="fas fa-user"/> Chủ tài khoản :
                                                                            {item.user_account}
                                                                        </p>
                                                                        <p className={"m-0"}>
                                                                            <i
                                                                                className="fab fa-cc-paypal" /> Số Tài khoản :
                                                                            {item.account}
                                                                        </p>

                                                                    </div>

                                                                </div>


                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ):(<></>)}



                                            <div className={"col-12 pt-3"} />

                                            <div className="col-12 col-sm-12 col-md-4 offset-md-8 pb-3">
                                                <a onClick={()=>{
                                                    self.StopDataContract();
                                                }} className={"btn btn-outline-primary font14 rounded-0 w-100"}>
                                                    <i className="far fa-save me-2" /> Cập nhật
                                                </a>
                                            </div>



                                        </div>


                                    </div>
                                ):(
                                    <>
                                        {self.state.InfoContract.status===0?(
                                            <div className={"col-12"}>

                                                <p className={"font16 text-uppercase mb-3 text-danger font-weight-bold"}>HD đang bảo lưu</p>

                                                <div className={"row"}>


                                                    {self.state.InfoContract.time_start_temporary!==null?(
                                                        <div className={"col-12 col-sm-12 col-md-6"}>
                                                            <div className={"col-12 p-3 d-flex rounded flex-row align-items-center position-relative bg-light-bold"}>

                                                                <i className="far fa-clock font30 position-absolute text-primary" />

                                                                <div className={"col-12 ps-5"}>
                                                                    <p className={"m-0 mb-1 text-uppercase font-weight-bold"}>Thời gian bắt đầu :</p>
                                                                    <p className={"m-0"}>
                                                                        {timeConverterDate(self.state.InfoContract.time_start_temporary)}
                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    ):(<></>)}

                                                    {self.state.InfoContract.time_stop_temporary!==null?(
                                                        <div className={"col-12 col-sm-12 col-md-6"}>

                                                            <div className={"col-12 p-3 d-flex flex-row align-items-center rounded position-relative bg-light-bold"}>

                                                                <i className="fas fa-stopwatch font30 position-absolute text-danger" />
                                                                <div className={"col-12 ps-5"}>
                                                                    <p className={"m-0 mb-1 text-uppercase font-weight-bold"}>Thời gian kết thúc :</p>
                                                                    <p className={"m-0"}>
                                                                        {timeConverterDate(self.state.InfoContract.time_stop_temporary)}
                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    ):(<></>)}


                                                    <div className={"col-12 mt-3"} />


                                                    {self.state.InfoContract.time_start_temporary!==null && self.state.InfoContract.time_stop_temporary!==null?(
                                                        <div className={"col-12 col-sm-12 col-md-4"}>

                                                            <a onClick={()=>{
                                                                self.AlertCancelStopContract();
                                                            }} className={"btn btn-outline-success rounded-0 w-100 font14"}>
                                                                <i className="fas fa-power-off me-1" /> Huỷ bảo lưu
                                                            </a>

                                                        </div>
                                                    ):(
                                                        <></>
                                                    )}




                                                </div>

                                            </div>
                                        ):(
                                            <>
                                            <div className={"alert alert-danger"}>
                                                <i className="fas fa-exclamation-triangle"></i> HD không thể bảo lưu
                                            </div>
                                            </>
                                        )}

                                    </>

                                )}
                                </>):(
                                <>
                                    <div className={"alert alert-danger w-100"}>
                                        <i className="fas fa-exclamation-triangle me-1" />
                                        Bạn có 1 phiếu thu chưa được xử lý
                                    </div>
                                </>
                            )}

                        </div>
                    );
                case 2:
                    return(
                        <div className={"row"}>

                            {this.props.AppThis.GetInfoProfile().type===3 && self.state.InfoContract.info_contract_data.price_sale>0 && (self.state.InfoContract.status===1 ||self.state.InfoContract.status===2)?(
                                <div className={"row m-0"}>


                                    {data.billing_information.total_price - data.info_contract_data.money_used>0?(<>
                                        <div className={"alert alert-danger rounded-0"}>
                                            <i className="fas fa-exclamation-triangle me-1" /> bạn chắc chắn muốn chấm dứt hợp đồng
                                        </div>


                                        <p>Số tiền hoàn trả tạm tính :
                                            <strong className={"ms-2 text-red"}> {formatNumber(data.billing_information.total_price - data.info_contract_data.money_used)} VND</strong>
                                        </p>

                                        <p className={"font-weight-bold m-0"}>ảnh xác nhận thanh toán</p>


                                        <div className={"col-12 mt-2 mb-2 p-4 border_uploads_file text-red font14 text-center"}>

                                            <a onClick={()=>{
                                                hiddenFileInput.current.click();
                                            }} className={"font22 upload-images-avatar font16"}>

                                                <i className={"fas fa-cloud-upload-alt me-2"} />
                                                <span className={"font14 text-red"}>Click để tải lên tệp</span>

                                                <input onChange={(event)=>{
                                                    this.UploadFileImage(event);
                                                }} ref={hiddenFileInput} accept="image/*"  type={"file"} className={"d-none"} />
                                            </a>


                                            {this.state.FileUpload!==null?(
                                                <p className={"m-0 text-black p-0 font12"}>{this.state.FileUpload}</p>
                                            ):(<></>)}


                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-4 p-0"}>

                                            <a onClick={()=>{
                                                self.AlertActionRefund()
                                            }} className={"btn w-100 btn-outline-primary font14 rounded-0"}>
                                                <i className="far fa-envelope me-2" /> Gửi yêu cầu
                                            </a>
                                        </div>


                                    </>):(
                                        <div className={"alert alert-danger rounded-0"}>
                                            <i className="fas fa-exclamation-circle me-2" />
                                            HD không thể refund
                                        </div>
                                    )}


                                </div>
                            ):(
                                <div className={"alert alert-danger rounded-0"}>
                                    <i className="fas fa-exclamation-circle me-2" />
                                    HD không thể refund
                                </div>
                            )}


                        </div>
                    );
                case 3:
                    return(
                        <>
                            <div className={"col-12 col-sm-12 col-md-12 col-lg-12"}>

                                <ul className={"nav nav-tabs border-0 border-bottom mb-4"}>

                                    <li className={"nav-item border-0"}><a onClick={()=>{

                                        var Tmp_Select_Group={
                                            _id:null,
                                            type:"enterprise"
                                        };

                                        self.setState({
                                            Tmp_Select_Group:Tmp_Select_Group
                                        });

                                    }} className={self.state.Tmp_Select_Group.type ==="enterprise"?"nav-link bg-primary text-white border-0 text-black p-2 ps-3 pe-3":"nav-link border-0 text-black p-2 ps-3 pe-3"} href={"#"}>
                                        <i className="fas fa-layer-group me-1" /> Nhóm doanh nghiệp
                                    </a>
                                    </li>

                                    <li className={"nav-item border-0"}><a onClick={()=>{
                                        var Tmp_Select_Group={
                                            _id:null,
                                            type:"statics"
                                        };
                                        self.setState({
                                            Tmp_Select_Group:Tmp_Select_Group
                                        });
                                    }} className={self.state.Tmp_Select_Group.type ==="statics"?"nav-link bg-primary text-white border-0 text-black p-2 ps-3 pe-3":"nav-link border-0 text-black p-2 ps-3 pe-3"} href={"#"}>
                                        <i className="fas fa-users-cog me-1" /> Nhóm cố định
                                    </a></li>

                                </ul>


                                {this.state.Tmp_Select_Group.type==='enterprise'?(
                                    <>
                                       <div className={"col-12 col-sm-12 col-md-12 col-lg-7"}>

                                           <label className={"d-block w-100 pb-2 font14 font-weight-bold mb-2"}>CHUYỂN ĐỔI NHÓM DOANH NGHIỆP</label>

                                           <div className={"row m-0"}>
                                               <div className={"col-12 col-sm-12 col-md-6 p-0"}>

                                                   {this.state.GroupMember!==null?(

                                                       <Select isSearchable={true} onChange={(selectedOption)=>{

                                                           if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){

                                                               var Tmp_Select_Group={
                                                                   _id:selectedOption.value,
                                                                   type:"enterprise"
                                                               };

                                                               self.setState({
                                                                   Tmp_Select_Group:Tmp_Select_Group
                                                               });

                                                           }
                                                           else {

                                                               self.setState({
                                                                   Tmp_Select_Group:{
                                                                       _id:null,
                                                                       type:null
                                                                   }
                                                               });

                                                           }


                                                       }} options={array_to_select(self.state.GroupMember,"name","_id")}
                                                               components={{
                                                                   Option:(props)=>self.OptionLayoutGroupMember(props,self.state.GroupMember)
                                                               }}
                                                       />
                                                   ):(
                                                       <ItemLoading />
                                                   )}

                                               </div>
                                               <div className={"col-12 col-sm-12 col-md-6 p-0 d-flex align-items-center"}>

                                                   <a onClick={()=>{
                                                       self.MoveToGroup();
                                                   }} className={"btn btn-danger rounded-0 w-100"}>
                                                       <i className="far fa-save me-1" />  Cập nhật
                                                   </a>

                                               </div>
                                           </div>
                                       </div>
                                    </>
                                ):(
                                    <></>
                                )}


                                {this.state.Tmp_Select_Group.type==='statics'?(
                                    <>

                                        {data.id_card.is_convert_to_statics_group===2?(
                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-7"}>

                                                    <div className={"row m-0"}>

                                                        <label className={"d-block w-100 pb-2 font14 font-weight-bold mb-2"}>CHUYỂN ĐỔI NHÓM CỐ ĐỊNH</label>

                                                        <div className={"col-12 col-sm-12 col-md-6 p-0"}>
                                                            {this.state.GroupMemberStatics!==null?(
                                                                <Select key={"group_member_statics"} isSearchable={true} onChange={(selectedOption)=>{
                                                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){

                                                                        var Tmp_Select_Group={
                                                                            _id:selectedOption.value,
                                                                            type:"statics"
                                                                        };

                                                                        self.setState({
                                                                            Tmp_Select_Group:Tmp_Select_Group
                                                                        })

                                                                    }
                                                                    else {
                                                                        self.setState({
                                                                            Tmp_Select_Group:{
                                                                                _id:null,
                                                                                type:null
                                                                            }
                                                                        })
                                                                    }

                                                                }} options={array_to_select(self.state.GroupMemberStatics,'value','key')} />
                                                            ):(
                                                                <ItemLoading />
                                                            )}

                                                        </div>

                                                        <div className={"col-12 col-sm-12 col-md-6 p-0 d-flex align-items-center"}>

                                                            <a onClick={()=>{
                                                                self.MoveToGroup();
                                                            }} className={"btn btn-danger rounded-0 w-100"}>
                                                                <i className="far fa-save me-1" />  Cập nhật
                                                            </a>

                                                        </div>

                                                    </div>
                                                </div>

                                        ):(
                                            <div className={"col-12"}>
                                                <div className={"alert alert-danger rounded-0 m-0"}>
                                                    <i className="fas fa-exclamation-triangle" /> Thẻ tập không được phép chuyển đổi
                                                </div>
                                            </div>
                                        )}

                                    </>
                                ):(
                                    <></>
                                )}




                            </div>







                        </>
                    );
                case 4:
                    return(
                        <>


                            <div className={"col-12"}>
                                <label className={"d-block w-100 pb-2 font14 border-bottom font-weight-bold mb-4"}>SỐ THẺ ĐỊNH DANH HD</label>
                            </div>


                            <div className={"alert alert-warning"}>
                                <i className="fas fa-id-card me-1" />  Nhập số thẻ cho HD tại đây.(Dùng để checkin)
                            </div>

                           <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>
                               <div className={"row"}>
                                   <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>
                                       <input type="text" onChange={(event)=>{
                                           self.setState({
                                               NumberIdentification:event.target.value
                                           });
                                       }}  defaultValue={self.state.NumberIdentification!==null?self.state.NumberIdentification:self.state.InfoContract.number_identification} className="form-control" placeholder="Mã số thẻ cho HD"/>
                                   </div>

                                   <div className={"col-12 col-sm-12 col-md-12 col-lg-4"}>
                                       <a onClick={()=>{
                                           self.UpdateNumberIdentification();
                                       }} className={"btn btn-primary rounded-0 w-100"}>
                                           <i className="far fa-save me-1" />  Cập nhật
                                       </a>

                                   </div>
                               </div>

                           </div>

                        </>
                    );

                case 5:
                    return(<>

                        {self.props.AppThis.GetInfoProfile().type===3?(
                            <div className={"col-12"}>

                                <div className={"alert alert-danger rounded-0 mt-2 mb-2"}>
                                    <i className="fas fa-exclamation-triangle" /> vui lòng nhập lý do bạn tạm ngừng hoặc kích hoạt lại hợp đồng
                                </div>

                                <textarea onChange={(event)=>{
                                    self.setState({
                                        description:event.target.value
                                    });
                                }} className={"form-control w-100 rounded-0"} placeholder={"Nội dung"}></textarea>



                                <div className="col-12 mt-4 col-sm-12 col-md-7">

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                            <a onClick={()=>{
                                                self.ActionMember(1);
                                            }} className={"btn btn-primary rounded-0 w-100 font14"}>
                                                <i className="far fa-save me-1" />
                                                Kích hoạt</a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                            <a onClick={()=>{
                                                self.ActionMember(2);
                                            }} className={"btn btn-danger rounded-0 w-100 font14"}><i className="fas fa-ban me-1" />
                                                Ngừng kích hoạt
                                            </a>
                                        </div>


                                    </div>

                                </div>




                            </div>
                        ):(<></>)}

                    </>);

                case 6:
                    return(<>


                            <div className={"col-12 box_input"}>

                                <div className={"alert alert-danger rounded-0 mt-2 mb-2"}>
                                    <i className="fas fa-exclamation-triangle" /> Vui lòng chọn ngày kết thúc
                                </div>



                                <div className={"row"}>

                                    <div className={"col-12 col-sm-12 col-md-4"}>

                                        <label className={"mb-2 mt-1"}>Thời gian bắt đầu HD</label>

                                        <DatePicker selected={this.state.Time_start_contract===null?new Date(this.state.InfoContract.start_day*1000):new Date(this.state.Time_start_contract*1000)}  dateFormat="dd/MM/yyyy" onChange={(date) => {
                                            this.setState({
                                                Time_start_contract:Math.ceil(date.getTime()/1000)
                                            });
                                        }} placeholder={"Thời gian bắt đầu"} />
                                    </div>


                                    <div className={"col-12 col-sm-12 col-md-4"}>
                                        <label className={"mb-2 mt-1"}>Thời gian kết thúc HD</label>

                                        <DatePicker  selected={this.state.Time_end_contract===null?new Date(this.state.InfoContract.end_day_contract*1000):new Date(this.state.Time_end_contract*1000)}  dateFormat="dd/MM/yyyy" onChange={(date) => {
                                            this.setState({
                                                Time_end_contract:Math.ceil(date.getTime()/1000)
                                            });
                                        }} placeholder={"Thời gian kết thúc"} />
                                    </div>

                                    {this.state.InfoContract.type_card!==2?(
                                        <div className={"col-12 col-sm-12 col-md-4"}>
                                            <label className={"mb-2 mt-1"}>Số buổi PT đã tập</label>
                                            <input onChange={(event)=>{

                                                this.setState({
                                                    number_did_pt:Math.ceil(event.target.value)
                                                });

                                            }} defaultValue={this.state.InfoContract.info_contract_data.number_did_pt} type={"number"} className={"form-control rounded-0"} placeholder={"Số buổi PT đã tập"} />

                                        </div>
                                    ):(<></>)}




                                </div>



                                <div className="col-12 mt-4 col-sm-6 col-md-5">

                                    <div className={"row"}>


                                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                            <a onClick={()=>{
                                                self.AlertUpdateEndDayContract();
                                            }} className={"btn btn-primary rounded-0 w-100 font14"}>
                                                <i className="far fa-save me-1" />
                                                Cập nhật</a>
                                        </div>


                                    </div>

                                </div>




                            </div>


                    </>);

                case 7:
                    return(<CSRContract path={this.props.path} data={this.state.InfoContract} AppThis={this.props.AppThis} />);

                case 8:

                    return(<>

                        <div className={"col-12"}>

                                <>

                                    <div className={"alert alert-success rounded-0 mt-2 mb-2"}>
                                        <i className="fas fa-exclamation-triangle" /> Vui lòng nhập đơn giá dậy PT
                                    </div>


                                    {typeof self.state.InfoContract.unit_price!=='undefined' && self.state.InfoContract.unit_price!==null?(
                                        <>
                                        <div className={"col-12 font-weight-bold text-red mb-3 font16"}>
                                            Đơn giá dậy / 1 buổi tập : <strong className={"ms-2"}>{formatNumber(self.state.InfoContract.unit_price)} vnd</strong>
                                        </div>
                                        </>
                                    ):""}

                                    <div className={"row"}>
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>
                                            <input type="text" onChange={(event)=>{
                                                self.setState({
                                                    unit_price:remove_input_price(event.target.value)
                                                });
                                            }} value={input_price(this.state.unit_price)}  defaultValue={typeof self.state.InfoContract.unit_price!=='undefined' && self.state.InfoContract.unit_price!==null?self.state.InfoContract.unit_price:''} className="form-control" placeholder="Đơn giá dậy"/>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-4"}>
                                            <a onClick={()=>{
                                                self.UpdateUnitPrice();
                                            }} className={"btn btn-primary rounded-0 w-100"}>
                                                <i className="far fa-save me-1" />  Cập nhật
                                            </a>

                                        </div>
                                    </div>
                                </>

                        </div>
                    </>);


            }
        }
        else{

            return(
                <ItemLoading />
            );
        }


    }


    ActionMember(type){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var data_submit={
            id_member:this.state.InfoContract._id,
            description:this.state.description,
        };

        if(type===1){
            data_submit['action']='active';
        }
        else{
            data_submit['action']='inactive';
        }

        formData.append('data', base64_encode(data_submit));

        Appthis.PageLoad(true);

        fetch(Appthis.ConstApp.home_api+this.props.path+"/pause", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác không thành công");
            }
            else{
                toast("Thao tác thành công");
            }

            Appthis.PageLoad(false);
            this.props.onSuccess();

        }).catch((e)=>{

            toast("Thao tác không thành công");
            Appthis.PageLoad(false);

            this.props.onSuccess();

        });

    }


    AlertUpdateEndDayContract(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn cập nhật ngày kết thúc HD này không ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.UpdateEndDayContract();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    UpdateEndDayContract(){

        if(this.state.number_did_pt===null || this.state.Time_end_contract===null || this.state.InfoContract===null){

            toast("Vui lòng nhập đầy đủ thông tin");
            return;
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_member', this.state.InfoContract._id);

        var data={
            number_did_pt:this.state.number_did_pt,
            end_day_contract:this.state.Time_end_contract,
            time_start_contract:this.state.Time_start_contract
        }
        formData.append('data', base64_encode(data));


        Appthis.PageLoad(true);

        fetch(Appthis.ConstApp.home_api+this.props.path+"/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác không thành công");
            }
            else{
                toast("Thao tác thành công");
            }

            Appthis.PageLoad(false);
            this.props.onSuccess();

        }).catch((e)=>{

            toast("Thao tác không thành công");
            Appthis.PageLoad(false);
            this.props.onSuccess();
        });



    }

    ChangeActiveTab(number){
        this.setState({
            ActiveTab:number,
            Tmp_Select_Group:{
                _id:null,
                type:null
            }
        });
    }

    render() {

        if(_isset(this.state.InfoContract) && this.state.InfoContract!==null){
            var data=this.state.InfoContract;
            const self=this;

            return(
                <div className="modal d-block position-fixed">
                    <div className={"modal-dialog modal-fullscreen"}>
                        <div className="modal-content shadow border-0">
                            <div className="modal-header">

                             <div className={"container"}>

                                 <div className={"row"}>

                                     <div className={"col-8 p-0"}>
                                         <strong className={"font18 text-white text-black text-black text-uppercase"}>CẬP NHẬT HỢP ĐỒNG</strong>
                                     </div>

                                     <div className={"col-4 p-0 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.OnClose();
                                    }} className={"text-white right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                     </div>
                                 </div>

                             </div>


                            </div>
                            <div className="modal-body">

                                <div className={"container"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-3 p-0"}>
                                            {this.state.InfoContract!==null?(
                                              <SidebarContract data={this.state.InfoContract} />
                                            ):(
                                                <></>
                                            )}
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-9 pt-3"}>

                                            <ul className={"nav-theme nav nav-tabs border-0"}>

                                                <li className={"nav-item"}>
                                                    <a onClick={()=>{
                                                        this.ChangeActiveTab(1);
                                                    }}  className={this.state.ActiveTab===1?("nav-link active"):("nav-link text-black")}><i
                                                        className="far fa-clock me-1"></i> Bảo lưu</a>
                                                </li>

                                                {this.props.AppThis.GetInfoProfile().type===3?(
                                                    <li className={"nav-item"}>
                                                        <a onClick={()=>{
                                                            this.ChangeActiveTab(2);
                                                        }}  className={this.state.ActiveTab===2?("nav-link active"):("nav-link text-black")}>
                                                            <i className="far fa-money-bill-alt me-1"></i> Refund
                                                        </a>
                                                    </li>
                                                ):(
                                                    <></>
                                                )}

                                                {this.state.InfoContract.id_card.type!==5?(
                                                    <>
                                                        <li className={"nav-item"}>
                                                            <a onClick={()=>{
                                                                this.ChangeActiveTab(3);
                                                                var Tmp_Select_Group={
                                                                    _id:null,
                                                                    type:"enterprise"
                                                                };

                                                                self.setState({
                                                                    Tmp_Select_Group:Tmp_Select_Group
                                                                });
                                                            }}  className={this.state.ActiveTab===3?("nav-link active"):("nav-link text-black")}>
                                                                <i className="far fa-building me-1"></i>Chuyển đổi nhóm HD</a>
                                                        </li>

                                                        <li className={"nav-item"}>
                                                            <a onClick={()=>{
                                                                this.ChangeActiveTab(4);
                                                            }} className={this.state.ActiveTab===4?("nav-link active"):("nav-link text-black")}>
                                                                <i className="fas fa-id-card me-1" />
                                                                Số thẻ HD</a>
                                                        </li>

                                                        {this.props.AppThis.GetInfoProfile().type===3?(
                                                            <>
                                                                <li className={"nav-item"}>
                                                                    <a onClick={()=>{
                                                                        this.ChangeActiveTab(5);
                                                                    }} className={this.state.ActiveTab===5?("nav-link active"):("nav-link text-black")}>
                                                                        <i className="far fa-stop-circle me-1"></i> Ngừng Checkin
                                                                    </a>
                                                                </li>
                                                            </>
                                                        ):(<></>)}


                                                        <li className={"nav-item"}>
                                                            <a onClick={()=>{
                                                                this.ChangeActiveTab(8);
                                                            }} className={this.state.ActiveTab===8?("nav-link active"):("nav-link text-black")}>
                                                                <i className="fas fa-list-ol me-1"></i> Đơn giá dậy PT
                                                            </a>
                                                        </li>

                                                    </>
                                                ):(
                                                    <></>
                                                )}


                                                {this.props.AppThis.GetInfoProfile().type===3 && this.state.InfoContract.count_proccess===0?(
                                                    <li className={"nav-item"}>
                                                        <a onClick={()=>{
                                                            this.ChangeActiveTab(6);
                                                        }} className={this.state.ActiveTab===6?("nav-link active"):("nav-link text-black")}>
                                                            <i className="fas fa-sync-alt me-1"></i> Gia hạn
                                                        </a>
                                                    </li>
                                                ):(<></>)}




                                                <li className={"nav-item"}>
                                                    <a onClick={()=>{
                                                        this.ChangeActiveTab(7);
                                                    }} className={this.state.ActiveTab===7?("nav-link active"):("nav-link text-black")}>
                                                        <i className="fas fa-list-ol me-1"></i> CSR
                                                    </a>
                                                </li>



                                            </ul>


                                            <div className={"col-12 mt-4 pb-4"}>

                                                {this.RenderViewDataContract()}


                                            </div>


                                        </div>


                                    </div>

                                </div>




                            </div>


                        </div>
                    </div>
                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );
        }
        else{
            return(
                <></>
            );
        }


    }

}
