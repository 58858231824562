/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {array_to_select, base64_encode, get_item_in_array} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import ModalUser from "../../global/user/ModalUser";
import InfoUser from "../../global/user/InfoUser";
import CanvasJSReact from '../../helper/canvasjs.react';
import Floater from 'react-floater';
import HistoryPush from "../../global/push/HistoryPush";
import DatePicker from "react-datepicker";
import ContractUser from "../../global/contract/ContractUser";
import Select from "react-select";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        start_time:null,
        end_time:null,
        id_group:null,
        id_address:null,
        id_admin:null,
        duplicate:null
    },
    DataModal:{
        status:false,
        data:{}
    },
    InfoUser:null,
    page:0,
    statics:null,
    HistoryPush:{
        status:false,
        data:null
    },
    SearchAdvanced:false,
    ListAddress:null,
    ListTeamMkt:null,
    ContractUser:null,
    ListAdmin:null,
    DisableEditPhone:false
};
export default class mcustomer extends Component {
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }
    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){

            var [key,value]=item;

            if(value!==null && value!=='none'){
                query_send[key]=value;
            }

        });

        return query_send;
    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcustomer", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getStatics();

        this.getListAddress();

        this.GetListTeamMkt();

        this.getListAdmin();

    }


    getListAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcustomer/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{


            var ListAdmin=lodash.cloneDeep(data.data);

            ListAdmin.unshift({
                _id:"none",
                username:"Tất cả"
            });

            if(data.status==='ok'){
                self.setState({
                    ListAdmin:ListAdmin
                });
            }


        }).catch((e)=>{



        });


    }


    async GetListTeamMkt(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var get_data=await fetch(Appthis.ConstApp.home_api+"mcustomer/teammkt", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        if(get_data.status==='ok'){

            var ListTeamMkt=get_data.data;
            ListTeamMkt.unshift({
                _id:'',
                name:"Tất cả"
            });
            this.setState({
                ListTeamMkt:ListTeamMkt
            });
        }


    }

    async getListAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('type',3);


        var get_data=await fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        if(get_data.status==='ok'){
            var ListAddress=get_data.data;
            ListAddress.unshift({
                _id:null,
                name:"Tất cả"
            });
            this.setState({
                ListAddress:ListAddress
            });
        }


    }

    getStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }


        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcustomer/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.SetUpStaticsMonthPush(data.data);
            }

        }).catch((e)=>{

        });

    }

    SetUpStaticsMonthPush(data) {

        var data_report_total_user = [];

        Object.entries(data.total_user).forEach(entry => {
            const [key, value] = entry;
            data_report_total_user.push({
                x:new Date(key * 1000),
                y:value.user
            });

        });


        var data_report_user_marketing = [];

        Object.entries(data.total_marketing).forEach(entry => {
            const [key, value] = entry;
            data_report_user_marketing.push({
                x:new Date(key * 1000),
                y:value.user
            });

        });


        const options = {
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Khách hàng",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_total_user
                },
                {
                    type: "line",
                    showInLegend: true,
                    name: "marketing",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_user_marketing
                }

            ]
        };

        this.setState({
            statics:options
        });


    }

    OpenModal(data,DisableEditPhone=false){

        var DataModal={
            status:true,
            data:data
        };

        this.setState({
            DataModal:DataModal,
            DisableEditPhone:DisableEditPhone
        });

    }

    SubmitUser(response){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('data', base64_encode(response));

        this.setState({
            DataModal:{
                status:false,
                data:{}
            },
        });

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mcustomer/add", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thêm dữ liệu thất bại");


                Appthis.PageLoad(false);
            }
            else{
                toast("Cập nhật thành công");
                self.getData(true);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");

              Appthis.PageLoad(false);

        });


    }

    AlertResetPassword(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn cấp lại mật khẩu cho người dùng này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ResetPassword(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }


    ResetPassword(id){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id', id);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"mcustomer/reset", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công");

                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1>Thông báo !</h1>
                                <p>Mật khẩu đã đặt lại : {data.data}</p>
                                <button
                                    onClick={() => {
                                        onClose();
                                    }}
                                > Đồng ý
                                </button>
                            </div>
                        );
                    }
                });


            }
            Appthis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
        });


    }

    ActionVisible(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {

                                self.UpdateVisible(id);

                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    UpdateVisible(id){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id', id);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"mcustomer/status", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){

                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công");

                self.getData();
            }
            Appthis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
        });



    }

    ShowHistoryPush(data){

        this.setState({
            HistoryPush:{
                status:true,
                data:data
            }
        });

    }

    getContractUser(data){

        this.setState({
            ContractUser:data
        });
    }

    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix box-table-w250"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên khách hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã khách hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Số HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Giới tính</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>

                                    <td>{value['username']?value['username']:value['tmp_user']}</td>
                                    <td>{value['user_login']}

                                        {value.is_phone!==null?(
                                            <>
                                                <span className={"text-danger font-weight-bold font10 ms-2"}>{value.is_phone}</span>
                                            </>
                                        ):(<></>)}

                                    </td>
                                    <td>{value['code_user']}</td>
                                    <td>{value['count_contract']}</td>
                                    <td>{value['sex']===1?(
                                        <span className={"badge bg-success p-1 font11"}>Nam</span>
                                    ):(
                                        <span className={"badge bg-danger p-1 font11"}>Nữ</span>
                                    )}</td>

                                    <td className={"fix-item-table"}>
                                        <a onClick={()=>{
                                            self.OpenModal(value,true);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật tài khoản khách hàng">
                                            <i className="fas font12 fa-edit" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.setState({
                                                InfoUser:value
                                            });
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết tài khoản khách hàng">
                                            <i className="fas fa-info" />
                                            </Floater>
                                        </a>

                                        <a onClick={()=>{
                                            self.getContractUser(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Danh sách HD của khách hàng">
                                                <i className="fas fa-file-contract" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.ShowHistoryPush(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-warning p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lịch sử chuyển số">
                                                <i className="fas fa-history" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.AlertResetPassword(value._id);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cấp lại mật khẩu">
                                            <i className="fas fa-unlock-alt" />
                                            </Floater>
                                        </a>

                                        {value.visible===1?(
                                            <a onClick={()=>{
                                                self.ActionVisible(value['_id']);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Ngừng kích hoạt">
                                                <i className="fas fa-minus" />
                                                </Floater>
                                            </a>
                                        ):(
                                            <a onClick={()=>{
                                                self.ActionVisible(value['_id']);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="kích hoạt">
                                                <i className="fas fa-check-circle" />
                                                </Floater>
                                            </a>
                                        )}

                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    async ExportDataUser(){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }



        var get_data=await fetch(Appthis.ConstApp.home_api+"mcustomer/export", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác đã được gửi tới máy chủ");
        }

        Appthis.PageLoad(false);

    }


    AlertExport(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ExportDataUser();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    render(){
        const self=this;
        if(this.state.ContractUser!==null){
            return(
                <ContractUser onClose={()=>{
                    self.setState({
                        ContractUser:null
                    });
                }} path={"mcustomer"} AppThis={this.props.AppThis} data={this.state.ContractUser} />
            );
        }
        else{
            if(this.state.data!==null){

                return(
                    <div className={"container-fluid container-main-template mb-5"}>


                        <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 d-flex align-items-center">
                                    <h3 className="font18 mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý khách hàng</h3>
                                </div>

                                <div className="col-12 col-sm-12 text-right text-end">

                                    <div className="col-12 p-0 mb-2">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>

                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>


                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>




                                            <input onChange={(event)=>{
                                                var query=this.state.query;
                                                query.input_search=event.target.value;
                                                this.setState({
                                                    query:query
                                                });

                                            }} autoComplete={"off"} type="text" name="input_search"
                                                   className="font13 form-control d-inline-block w-auto rounded-0"
                                                   placeholder="Số điện thoại/Họ và tên"/>

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>

                                            <a onClick={()=>{
                                                this.OpenModal({});
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>


                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:true,
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-dark rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm theo tiêu chí</a>


                                        </div>

                                    </div>

                                </div>

                            </div>


                            <div className="col-12 pt-3 ps-3 pe-3 text-uppercase font-weight-bold pb-3 font16">
                                Khách hàng 30 ngày gần nhất
                            </div>

                            <div className="col-12 pt-3 ps-3 pe-3 mb-5">
                                {this.state.statics!==null?(
                                    <CanvasJSChart options={this.state.statics} />
                                ):(
                                    <div className={"col-12 text-center d-flex justify-content-center"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>




                            <div className={"col-12 ps-3 pe-3"}>
                                {this.thisRenderData()}
                            </div>


                            {this.state.SearchAdvanced===true?(
                                this.SearchAdvanced()
                            ):(<></>)}


                            <div className={"col-12 ps-3 pe-3"}>
                                <ul className="pagination">
                                    {this.state.data.prev>=0?(
                                        <li className="page-item" >
                                            <a className="page-link" onClick={()=>{
                                                var page=this.state.page-1;
                                                this.setState({
                                                    page:page
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Trước
                                            </a>
                                        </li>
                                    ):(<></>)}

                                    <li className="page-item">
                                        <a className="page-link">
                                            <span>{this.state.data.curent+1}</span>
                                        </a></li>

                                    {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                        <li className="page-item">
                                            <a className="page-link" onClick={()=>{
                                                this.setState({
                                                    page:this.state.data.next
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Kế tiếp
                                            </a>
                                        </li>
                                    ):(<></>)}

                                </ul>
                            </div>



                        </div>

                        {this.state.DataModal.status===true?(
                            <ModalUser onClose={()=>{
                                self.setState({
                                    DataModal:{
                                        status:false,
                                        data:{}
                                    }});
                            }} path={"mcustomer"} DisableEditPhone={this.state.DisableEditPhone} data={this.state.DataModal.data} SubmitUser={(response)=>{
                                this.SubmitUser(response);
                            }} AppThis={this.props.AppThis} />
                        ):(
                            <></>
                        )}


                        {this.state.InfoUser!==null?(

                            <InfoUser data={this.state.InfoUser} onClose={()=>{
                                this.setState({
                                    InfoUser:null
                                });
                            }} />
                        ):(<></>)}



                        {this.state.HistoryPush.status===true?(

                            <HistoryPush path={"mcustomer"} AppThis={this.props.AppThis} data={this.state.HistoryPush.data} onClose={()=>{
                                this.setState({
                                    HistoryPush:{
                                        status:false,
                                        data:null
                                    }
                                });

                            }} />
                        ):(<></>)}



                    </div>
                );
            }
            else{

                return(
                    <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                        <ItemLoading size={"Large"} />
                    </div>
                );
            }
        }


    }



    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm theo tiêu chí</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row"}>

                                <div className={"col-12 col-sm-12 col-md-12 pb-3 box_input"}>
                                    <p className={"mb-1"}>Tên khách hàng/SDT</p>
                                    <input defaultValue={this.state.query.input_search} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("input_search",event.target.value);
                                    }} className={"form-control"} placeholder={"Tên khách hàng/SDT"} />
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>
                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian bắt đầu"} />


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                    <p className={"mb-1"}>Theo Team Marketing</p>

                                    {this.state.ListTeamMkt!==null?(
                                        <select onChange={(event)=>{
                                            var query=self.state.query;
                                            query.id_group=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} className={"form-control w-100 rounded-0"}>
                                            {this.state.ListTeamMkt.map(function(item){
                                                if(item._id===self.state.query.id_group){
                                                    return(
                                                        <option selected={true} value={item._id}>{item.name}</option>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                }

                                            })}
                                        </select>
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                    <p className={"mb-1"}>Theo cơ sở</p>

                                    {this.state.ListAddress!==null?(
                                        <select onChange={(event)=>{
                                            var query=self.state.query;
                                            query.id_address=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} className={"form-control w-100 rounded-0"}>
                                            {this.state.ListAddress.map(function(item){
                                                if(item._id===self.state.query.id_address){
                                                    return(
                                                        <option selected={true} value={item._id}>{item.name}</option>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                }

                                            })}
                                        </select>
                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 customCssSelect"}>
                                    <p className={"mb-1"}>Theo NV Marketing</p>


                                    {this.state.ListAdmin!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            this.UpdateQuerySearch("id_admin",selectedOption.value);
                                        }} defaultValue={get_item_in_array("value",this.state.query.id_admin,array_to_select(this.state.ListAdmin,'username','_id'))}  options={array_to_select(this.state.ListAdmin,'username','_id')} />

                                    ):(
                                        <div className={"col-12 mt-3"}>
                                            <ItemLoading />
                                        </div>
                                    )}

                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                    <p className={"mb-1"}>Trùng số</p>

                                    <select onChange={(event)=>{
                                        this.UpdateQuerySearch('duplicate',event.target.value);
                                    }} className="mr-2 font13 rounded-0 w-100 form-control">
                                        {[
                                            {
                                                label:"Tất cả",
                                                value:"none"
                                            },
                                            {
                                                label:"Trùng số",
                                                value:1
                                            },
                                            {
                                                label:"Không Trùng",
                                                value:2
                                            }
                                        ].map(function(item,index){
                                            if(self.state.query.duplicate===item.value){
                                                return(
                                                    <option value={item.value}>{item.label}</option>
                                                );
                                            }
                                            else{
                                                return(
                                                    <option value={item.value}>{item.label}</option>
                                                );
                                            }
                                        })}


                                    </select>

                                </div>


                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                self.setState({
                                                    SearchAdvanced:false,
                                                    statics:null
                                                },function(){
                                                    self.getData(true);
                                                    self.getStatics();
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                this.AlertExport();
                                            }} className={"btn btn-success w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-download me-1" />  Xuất Dữ liệu
                                            </a>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-danger w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }


}
