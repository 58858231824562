/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_string,
    array_to_select_basic,
    base64_encode,
} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import EditModal from "../../global/EditModal";
import EditModule from "../../global/EditModule";
import Floater from 'react-floater';

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        type:null
    },
    DataModal:{
        status:false,
        title:null,
        data:null,
        _id:null
    },
    page:0,
    Position:null,
    EditModule:{
        status:false,
        data:null
    },
};

export default class groupmodule extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;
        var query_send={};
        Object.entries(get_query).map(function(item){

            var [key,value]=item;

            if(value!==null){
                query_send[key]=value;
            }

        });

        return query_send;
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"groupmodule", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getPosition();

    }

    getPosition(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        fetch(AppThis.ConstApp.home_api+"position", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{

            this.setState({
                Position:data.data
            });

        });

    }

    OpenModalModule(data){
        this.setState({
            EditModule:{
                status:true,
                data:data
            }
        });

    }
    OpenModal(data=null){

        if(this.state.Position===null){
            return;
        }


        var DataModal={
            status:true,
            title:'Quản lý chức năng',
            data:[
                {
                    title:"Tên chức năng",
                    value:null,
                    name:"name",
                    type:"input"
                },
                {
                    title:"Chức vụ",
                    value:null,
                    name:"id_position",
                    type:"select",
                    data:array_to_select_basic(this.state.Position,'_id','name')
                },
                {
                    title:"Phân loại",
                    value:3,
                    name:"type",
                    type:"select",
                    data:[
                        {
                            value:"Admin",
                            key:1
                        },
                        {
                            value:"Marketing",
                            key:2
                        },
                        {
                            value:"Club",
                            key:3
                        }
                    ]
                },
                {
                    title:"Công khai",
                    value:0,
                    name:"type_public",
                    type:"select",
                    data:[
                        {
                            value:"Active",
                            key:1
                        },
                        {
                            value:"InActive",
                            key:0
                        }
                    ]
                }
            ],
            _id:null
        };
        if(data!==null){
             DataModal={
                status:true,
                title:'Quản lý chức năng',
                data:[
                    {
                        title:"Tên chức năng",
                        value:data.name,
                        name:"name",
                        type:"input"
                    },
                    {
                        title:"Chức vụ",
                        value:data['id_position']?data['id_position']['_id']:null,
                        name:"id_position",
                        type:"select",
                        data:array_to_select_basic(this.state.Position,'_id','name')
                    },
                    {
                        title:"Phân loại",
                        value:parseInt(data.type),
                        name:"type",
                        type:"select",
                        data:[
                            {
                                value:"Admin",
                                key:1
                            },
                            {
                                value:"Marketing",
                                key:2
                            },
                            {
                                value:"Club",
                                key:3
                            }
                        ]
                    },
                    {
                        title:"Công khai",
                        value:parseInt(data.type_public),
                        name:"type_public",
                        type:"select",
                        data:[
                            {
                                value:"Active",
                                key:1
                            },
                            {
                                value:"InActive",
                                key:0
                            }
                        ]
                    }
                ],
                _id:data._id
            };
        }

        this.setState({
            DataModal:DataModal
        });

    }

    async UpdateModal(response){


        var Appthis= this.props.AppThis;

        Appthis.PageLoad(true);


        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var url_post=Appthis.ConstApp.home_api+"groupmodule/update";

        if(this.state.DataModal._id!==null){
            response._id=this.state.DataModal._id;
        }

        formData.append("data",base64_encode(response));

        var get_data=await fetch(url_post, {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Cập nhật dữ liệu thất bại");
        }
        else{
            toast("Cập nhật dữ liệu thành công");
        }

        this.ResetDataModal();

        await this.getData(true);

        Appthis.PageLoad(false);
    }

    ResetDataModal(){
        var Reset_DataModal={
            status:false,
            title:null,
            data:null,
            _id:null
        };

        this.setState({
            DataModal:Reset_DataModal
        })

    }


    AlertSync(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn đồng bộ nhóm chức năng này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.SyncModule(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    async SyncModule(id){

        var Appthis=this.props.AppThis;
        Appthis.PageLoad(true);
        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('_id',id);
        var get_status=await fetch(Appthis.ConstApp.home_api+"groupmodule/sync", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });
         get_status=await get_status.json();
        if(get_status.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");
        }
        Appthis.PageLoad(false);

    }

    UpdateGroupModule(data){

        const self=this;

        const Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id', this.state.EditModule.data._id);

        formData.append('data', base64_encode(data));

        fetch(Appthis.ConstApp.home_api+"groupmodule/edit", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            self.setState({
                EditModule:{
                    status:false,
                    data:null
                }
            });

            if(data.status==='error'){
                toast("Cập nhật dữ liệu thất bại");
                Appthis.PageLoad(false);
            }
            else{

                toast("Cập nhật tài khoản thành công");

                self.getData(true);
            }


        }).
        catch((e)=>{

            self.setState({
                EditModule:{
                    status:false,
                    data:null
                }
            });

            toast("Cập nhật dữ liệu thất bại");

            Appthis.PageLoad(false);


        });




    }

    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>STT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên chức năng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Phân loại</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Chức vụ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        {index+1}
                                    </td>
                                    <td>{value['name']}</td>
                                    <td>
                                        {value['type_public']===1?(
                                            <span className={"badge bg-primary p-1"}>Active</span>
                                        ):(
                                            <span className={"badge bg-danger p-1"}>Inactive</span>
                                        )}
                                    </td>
                                    <td>
                                        {value['type']===1?(
                                            <span>Admin</span>
                                        ):(<></>)}
                                        {value['type']===2?(
                                            <span>Marketing</span>
                                        ):(<></>)}
                                        {value['type']===3?(
                                            <span>Club</span>
                                        ):(<></>)}
                                    </td>

                                    <td>
                                        {value['id_position']?(value['id_position']['name']):(<></>)}
                                    </td>
                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.OpenModal(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật nhóm chức năng">
                                            <i className="fas font12 fa-edit" />
                                            </Floater>
                                        </a>

                                        <a onClick={()=>{
                                            self.OpenModalModule(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật chức năng">
                                            <i className="fas fa-hammer" />
                                            </Floater>
                                        </a>


                                        {value.type_public===1?(
                                            <a onClick={()=>{
                                                self.AlertSync(value._id);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">

                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Đồng bộ toàn bộ">
                                                <i className="fas fa-sync" />
                                                </Floater>
                                            </a>
                                        ):(<></>)}



                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý chức năng</h3>
                            </div>

                            <div className="col-12 col-sm-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>




                                        <input onChange={(event)=>{
                                            var query=this.state.query;
                                            query.input_search=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} autoComplete={"off"} type="text" name="input_search"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Từ khóa tìm kiếm"/>


                                        <select onChange={(event)=>{
                                            var query=this.state.query;
                                            query.type=event.target.value;
                                            this.setState({
                                                query:query
                                            });
                                        }} className="mr-2 font13 rounded-0 w-auto form-control">
                                            <option value="none">Tất cả</option>
                                            <option value="1">Admin</option>
                                            <option value="2">Marketing</option>
                                            <option value="3">Club</option>
                                        </select>


                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>

                                        <a onClick={()=>{
                                            this.OpenModal(null);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>




                                    </div>

                                </div>



                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>



                        {this.state.DataModal.status===true?(
                            <EditModal size={"small"} onClose={()=>{
                                this.ResetDataModal();
                            }} GetDataSubmit={(response,required)=>{

                                if(required===true){
                                    self.UpdateModal(response);
                                }
                                else{
                                    toast("Vui lòng nhập đầy đủ thông tin");
                                }
                            }} title={this.state.DataModal.title} data={this.state.DataModal.data} />
                        ):(<></>)}


                        {this.state.EditModule.status!==false?(
                            <EditModule AppThis={this.props.AppThis} path={"groupmodule/module"} onClose={()=>{
                                this.setState({
                                    EditModule:{
                                        status:false,
                                        data:null
                                    }
                                });
                            }} GetDataSubmit={(data)=>{
                                this.UpdateGroupModule(data);
                            }} data={this.state.EditModule.data} />
                        ):(<></>)}


                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
