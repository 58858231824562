import React,{Component} from "react";
import ItemLoading from "./ItemLoading";
import { toast } from 'react-toastify';
import Breadcrumb from "./Breadcrumb";

import CanvasJSReact from '../helper/canvasjs.react';
import {timeConverter} from "../helper/array";
import {carryValue} from "@testing-library/user-event/dist/keyboard/shared";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Dashboard extends Component{

    constructor(props) {
        super(props);

        this.state={
            data:null,
            statics:{
                push:null,
                static:null
            },
            device:null,
            system:null,
            timeStart:new Date().getTime()
        };
    }

    getData(load=false){

        if(this.props.AppThis.state.current_address===null){
            return;
        }
        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.state.token);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"dashboard", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                Appthis.DestroyToken();
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data,
                    device:data.device
                });

                self.RenderStatics(data.data);
            }

        }).catch((e)=>{
            toast("Lấy dữ liệu thất bại");
            Appthis.DestroyToken();
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    getSystem(){

        if(this.props.AppThis.state.current_address===null){
            return;
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.state.token);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"dashboard/system", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var systemUpdate=data.data;

                systemUpdate.time=Date.now();

                self.setState({
                    system:systemUpdate
                });

            }

        }).catch((e)=>{


        });
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }



    RenderStatics(data){

        var statics=this.state.statics;

        var data_report_push = [];

        var data_report_user = [];

        var pagination_data_report = data.report_user_by_day;

        Object.entries(pagination_data_report).forEach(entry => {
            const [key, value] = entry;

            if(typeof value.push!=='undefined'){

                data_report_push.push({
                    x:new Date(key * 1000),
                    y:value.push
                });

            }

            if(typeof value.user!=='undefined'){

                data_report_user.push({
                    x:new Date(key * 1000),
                    y:value.user
                });

            }

        });

        const options1 = {
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Đẩy số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_push
                },
                {
                    type: "line",
                    showInLegend: true,
                    name: "Khách hàng",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_user
                }

            ]
        };

        statics.push=options1;


        var options_share = {
            animationEnabled: true,
            data: [{
                type: "pie",
                startAngle: 40,
                toolTipContent: "<b>{label}</b>: {y}%",
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 12,
                indexLabel: "{label} - {y}%",
                dataPoints: data.data_statics
            }]
        };

        statics.static=options_share;

        this.setState({
            statics:statics
        });



        }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getSystem();

    }

    render() {

        if(this.props.AppThis.state.current_address!==null){
            if(this.state.data===null){
                return(
                    <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                        <ItemLoading size={"Large"} />
                    </div>
                );
            }
            else{
                return(this.ViewDashboard());
            }
        }
        else{
            return(<></>);
        }


    }


    StaticsMemory(){
        var system=this.state.system;
        var percent_free=Math.round((system.memory.free/system.memory.total)*100);
        var percent_use=100-percent_free;

        var statics_memory=[
            {
                label:"Ram còn trống",
                y:percent_free,
                text:parseInt(system.memory.free/Math.pow(1024,3))+"GB"
            },
            {
                label:"Ram đã sử dụng",
                y:percent_use,
                text:parseInt((system.memory.total-system.memory.free)/Math.pow(1024,3))+"GB"
            }
        ];

        var response = {
            animationEnabled: true,
            data: [{
                type: "pie",
                startAngle: 40,
                toolTipContent: "<b>{label}</b>: {text}",
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 12,
                indexLabel: "{label} - {y}%",
                dataPoints: statics_memory
            }]
        };

        return response;
    }

    StaticsDisk(){
        var system=this.state.system;
        var percent_free=Math.round((system.disk.free/system.disk.total)*100);
        var percent_use=100-percent_free;

        var statics_disk=[
            {
                label:"Ổ cứng còn trống",
                y:percent_free,
                text:parseInt(system.disk.free/Math.pow(1024,3))+"GB"
            },
            {
                label:"Ổ cứng Đã dùng",
                y:percent_use,
                text:parseInt((system.disk.total-system.disk.free)/Math.pow(1024,3))+"GB"
            }
        ];

        var response = {
            animationEnabled: true,
            data: [{
                type: "pie",
                startAngle: 40,
                toolTipContent: "<b>{label}</b>: {text}",
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 12,
                indexLabel: "{label} - {y}%",
                dataPoints: statics_disk
            }]
        };

        return response;

    }

    getNameQueue(name){

        var response='';

        switch (name) {

            case "phongmkt":
                response='Hệ thống';
                break;
            case "timekeeping":
                response='Chấm công';
                break;
            case "export":
                response='Xuất dữ liệu';
                break;
            case "push":
                response='Chuyển số';
                break;
            case "import":
                response='Nhập dữ liệu';
                break;
        }

        return response;
    }

    ViewDashboard(){

        const self=this;

        var system=this.state.system;
        return(
            <div className={"container-fluid container-main-template mb-5"}>
           <Breadcrumb title={"Bảng điều khiển"} />

                <div className={"row"}>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">

                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">

                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center align-items-center far fa-handshake rounded-circle bg-warning" />
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tài khoản Maketing</p>
                                <p className="number-info-dashboard m-0 font30 font-weight-bold">{this.state.data.count_total_marketing}</p>
                            </div>

                        </div>

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">

                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">

                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="fas fa-kaaba d-flex justify-content-center align-items-center text-white rounded-circle bg-info" />
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tài khoản Club</p>
                                <p className="number-info-dashboard m-0 font30 font-weight-bold">{this.state.data.count_total_club}</p>
                            </div>

                        </div>

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">

                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">

                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center align-items-center fas fa-user-shield text-white rounded-circle bg-primary" />
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tài khoản Admin</p>
                                <p className="number-info-dashboard m-0 font30 font-weight-bold">{this.state.data.count_total_admin}</p>
                            </div>

                        </div>

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">

                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">

                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center align-items-center fas fa-cloud-download-alt text-white rounded-circle bg-danger"/>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Khách hàng</p>
                                <p className="number-info-dashboard m-0 font30 font-weight-bold">{this.state.data.count_customer}</p>
                            </div>

                        </div>

                    </div>

                </div>


                <div className={"row mt-4 mb-5"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-9"}>



                        {system!==null?(
                            <div className={"col-12 bg-white ps-3 pe-3 pb-5 shadow mt-4"}>

                                <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                    Thông tin hệ thống
                                </div>

                                <div className={"row"}>

                                    <div className={"col-12 col-sm-12 col-md-6"}>


                                            <div className="toast d-block w-100 bg-primary" role="alert" aria-live="assertive" aria-atomic="true">
                                                <div className="toast-header">
                                                    <strong className="me-auto text-uppercase">Ổ cứng</strong>
                                                    <small>Dung lượng ổ cứng</small>
                                                </div>
                                                <div className="toast-body text-white">
                                                    Tổng dung lượng ổ đĩa : <span className={"text-uppercase font-weight-bold font16"}>{parseInt(system.disk.total/Math.pow(1024,3))} GB</span>
                                                </div>
                                            </div>

                                            {this.state.system!==null?(
                                                <CanvasJSChart options={this.StaticsDisk()} />
                                            ):(
                                                <div className={"col-12 text-center d-flex justify-content-center"}>
                                                    <ItemLoading />
                                                </div>
                                            )}


                                    </div>


                                    <div className={"col-12 col-sm-12 col-md-6"}>

                                        <div className="toast d-block w-100 bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
                                            <div className="toast-header">
                                                <strong className="me-auto text-uppercase">Bộ nhớ</strong>
                                                <small>Bộ nhớ Ram</small>
                                            </div>
                                            <div className="toast-body text-white">
                                                Bộ nhớ Ram : <span className={"text-uppercase font-weight-bold font16"}>
                                                 {Math.round(system.memory.total/Math.pow(1024,3))} GB</span>
                                            </div>
                                        </div>


                                        {this.state.system!==null?(
                                            <CanvasJSChart options={this.StaticsMemory()} />
                                        ):(
                                            <div className={"col-12 text-center d-flex justify-content-center"}>
                                                <ItemLoading />
                                            </div>
                                        )}

                                    </div>



                                </div>





                                <label className={"d-block w-100 text-uppercase font18 mb-4 pb-2 border-bottom font-weight-bold mt-4"}>Xử lý dữ liệu</label>

                               <div className={"col-12 overflow-auto"}>
                                   <table className="table mw800">
                                       <thead>
                                       <tr className={"bg-warning text-uppercase"}>
                                           <th scope="col">#</th>
                                           <th scope="col">Ưu tiên</th>
                                           <th scope="col">Sẵn sàng</th>
                                           <th scope="col">Xử lý</th>
                                           <th scope="col">Trì hoãn</th>
                                           <th scope="col">Đã thực hiện</th>
                                       </tr>
                                       </thead>
                                       <tbody>

                                       {Object.entries(system.queue).map(function(item,index){
                                           var [key,data]=item;
                                           return(
                                               <tr>
                                                   <td>{self.getNameQueue(data['name'])}</td>
                                                   <td>{data['current-jobs-urgent']}</td>
                                                   <td>{data['current-jobs-ready']}</td>
                                                   <td>{data['current-jobs-reserved']}</td>
                                                   <td>{data['current-jobs-delayed']}</td>
                                                   <td>{data['total-jobs']}</td>
                                               </tr>
                                           );
                                       })}

                                       </tbody>
                                   </table>
                               </div>



                            </div>

                        ):(
                            <></>
                        )}



                        <div className={"col-12 bg-white ps-3 pe-3 pb-5 shadow"}>

                            <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                30 ngày gần nhất
                            </div>

                            {this.state.statics.push!==null?(
                                <CanvasJSChart options={this.state.statics.push} />
                            ):(
                                <div className={"col-12 text-center d-flex justify-content-center"}>
                                    <ItemLoading />
                                </div>
                            )}

                            <div className="col-12 mt-4 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                Trạng thái khách hàng
                            </div>

                            {this.state.statics.static!==null?(
                                <CanvasJSChart options={this.state.statics.static} />
                            ):(
                                <div className={"col-12 text-center d-flex justify-content-center"}>
                                    <ItemLoading />
                                </div>
                            )}



                        </div>



                    </div>

                    <div className={"col-12 col-sm-12 col-lg-3 d-none d-lg-block"}>

                        <div className={"col-12 ps-3 bg-white shadow pt-3 pe-3 pb-5"}>
                            {this.state.device!==null?(
                                <>
                                    <p className={"mb-2 text-danger font16 font-weight-bold text-uppercase"}>
                                        Thiết bị hoạt động
                                    </p>
                                    {this.state.device.map(function(item,index){
                                        return(
                                            <div className={"alert alert-primary font12"}>

                                             <p className={"mb-0"}>
                                                 <strong>{item.os}</strong> :
                                                 <>
                                                     {item.os==='browser'?(
                                                         <span>{item.device_info}</span>
                                                     ):(
                                                         <span className={"text-uppercase"}> {item.device_info.getSystemName} - {item.device_info.getDeviceName}</span>
                                                     )}
                                                 </>
                                             </p>
                                                <p className={"mb-0"}>Cập nhật : {timeConverter(item.time)}</p>

                                            </div>
                                        );
                                    })}
                                </>

                            ):(
                                <></>
                            )}


                        </div>



                    </div>

                </div>

            </div>
        );
    }
}
