/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {timeConverterDate, _isset, _isset_object} from "../../helper/array";

export default class InfoProfile extends Component{

    renderData(data=null){
        const self=this;

        if(data!==null){
            return(
                <div className={"row Edit-Profile"}>
                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1">
                            <span className={"font-weight-bold"}>
                                <i className="far fa-user"/> Họ và tên
                            </span>
                            <span className={"m-0 p-0 ms-2"}>{data.username}</span>
                        </label>

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="mb-1 font-weight-bold"><i className="far fa-envelope" /> Email
                            <span className={"m-0 p-0 ms-2 font-weight-400"}>{data.email}</span>
                        </label>
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                        <label className="font-weight-400 mb-1 font-weight-bold"><i
                            className="fas fa-birthday-cake me-1" />Sinh nhật</label>

                        {_isset(data.birthday)?(
                            <span className={"m-0 p-0 ms-3"}>{timeConverterDate(data.birthday)}</span>
                        ):(
                            <span className={"m-0 p-1 ms-3 badge bg-danger"}>Chưa rõ</span>
                        )}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                        <label className="font-weight-400 mb-1 font-weight-bold"><i
                            className="fab fa-acquisitions-incorporated" /> Cơ sở</label>
                        {data.address_id!==null?(
                            <>
                                {data.address_id.map(function(item){
                                    return(
                                        <span className={"badge bg-dark rounded-0 m-1"}>{item.name}</span>
                                    );
                                })}
                            </>
                        ):(
                            <span className={"m-0 p-1 badge bg-danger ms-2"}>Chưa rõ</span>
                        )}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                        <label className="font-weight-400 mb-1 font-weight-bold"><i
                            className="fab fa-acquisitions-incorporated" /> Chức vụ</label>

                        {typeof data.position!='undefined' && data.position!==null?(
                            <span className={"m-0 p-0 ms-2"}>{data.position.name}</span>
                        ):(
                            <span className={"m-0 p-1 badge bg-danger ms-2"}>Chưa rõ</span>
                        )}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="fas fa-phone-alt" /> Điện thoại</label>

                        {data.phone_number?(
                            <span className={"m-0 p-0 ms-3"}>{data.phone_number}</span>
                        ):(
                            <span className={"m-0 p-1 badge bg-danger span"}>Chưa rõ</span>
                        )}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold">
                            <i className="fas fa-layer-group" /> Nhóm : </label>

                        {_isset_object(data.group_id)?(

                                data.group_id.map(function(value,index){
                                    return(<span className={"badge bg-primary p-1 ms-1 me-1"}>{value.name}</span>);
                                })
                        ): (
                            <span className={"m-0 p-1 badge bg-danger ms-2"}>Chưa rõ</span>
                        )}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold">
                            <i className="fas fa-layer-group" /> Bộ môn : </label>

                        {_isset_object(data.subject_id)?(

                            data.subject_id.map(function(value,index){
                                return(<span className={"badge bg-success p-1 ms-1 me-1"}>{value.name}</span>);
                            })
                        ): (
                            <span className={"m-0 p-1 badge bg-danger ms-2"}>Chưa rõ</span>
                        )}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                        <label className="font-weight-400 mb-1 font-weight-bold me-3"><i className="fas fa-genderless" /> Giới tính</label>

                        {data.sex?(
                            <span className={"d-inline-block m-0 p-0"}>Nam</span>
                        ):(
                            <span className={"d-inline-block m-0 p-0"}>Nữ</span>
                        )}

                    </div>


                    {typeof data.data_profile!=='undefined' && data.data_profile!==null?(
                        <>
                            <div className={"col-12 mt-2 mb-2 bg-dark p-2 ps-4"}>
                                <p className={"p-0 m-0 text-white font-weight-bold"}>HỒ SƠ NHÂN SỰ</p>
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Địa chỉ thường trú</label>

                                {typeof data.data_profile.current_address!=='undefined'?(
                                    <span className={"m-0 p-0 ms-2"}>{data.data_profile.current_address}</span>
                                ):(
                                    <span className={"m-0 p-1 badge bg-danger ms-2"}>Chưa rõ</span>
                                )}
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Trình độ</label>
                                {data.data_profile.degree?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.degree}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Trường học</label>

                                {data.data_profile.schools?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.schools}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Bằng cấp
                                </label>

                                {data.data_profile.certificate?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.certificate}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Căn cước công dân</label>

                                {data.data_profile.citizen_identification?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.citizen_identification}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Ngày cấp</label>

                                {data.data_profile.day_citizen_identification?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.day_citizen_identification}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Nơi cấp
                                </label>
                                {data.data_profile.address_citizen_identification?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.address_citizen_identification}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Hộ khẩu thường trú</label>
                                {data.data_profile.permanent_residence?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.permanent_residence}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Dân tộc
                                </label>

                                {data.data_profile.ethnic?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.ethnic}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>

                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Tôn giáo
                                </label>

                                {typeof data.data_profile.religion!=='undefined'?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.religion}</span>
                                ):(
                                    <span className={"m-0 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    Người liên hệ khẩn cấp
                                </label>
                                {data.data_profile.name_protector?(
                                    <span className={"m-1 p-0 ms-2"}>{data.data_profile.name_protector}</span>
                                ):(
                                    <span className={"m-1 p-1 ms-2 badge bg-danger"}>Chưa rõ</span>
                                )}

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                <label className="font-weight-400 mb-1 font-weight-bold">
                                    SDT liên hệ khẩn cấp
                                </label>

                                {data.data_profile.phone_protector?(
                                    <span className={"m-1 ms-2 p-0"}>{data.data_profile.phone_protector}</span>
                                ):(
                                    <span className={"m-1 ms-2 p-1 badge bg-danger"}>Chưa rõ</span>
                                )}

                            </div>

                        </>
                    ):(
                        <></>
                    )}






                </div>
            );
        }
        else{
          return(
              <div className={"col-12 justify-content-center align-content-center d-flex"}>
                  <ItemLoading />
              </div>
          );
        }

    }

    render() {

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-dialog-scrollable modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-featured">
                            <strong className={"font18 text-uppercase"}>Chi tiết nhân viên</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                {this.props.data!==null?(this.renderData(this.props.data)):(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>


                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                        <div className={"col-12 col-sm-12 col-lg-4 offset-lg-8"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Đóng
                                            </a>
                                        </div>
                                </div>
                        </div>



                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
