/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    timeConverterDate,
    formatNumber, _isset_object, timeConverter
} from "../../helper/array";
import logo from "../../assets/images/logo.png";
import ConstApp from "../../ConstApp";
import {status_contract, type_wallet} from "../../helper/member";

export default class SidebarWallet extends Component{

    constructor(props) {
        super(props);
    }



    render() {
        const self=this;

        if(typeof this.props.data!=='undefined'){
            var data=this.props.data;

            return(
                <>

                    <div className={"alert alert-primary m-0 rounded-0 font14 text-uppercase font-weight-bold"}>
                        <i className="fas fa-exclamation-triangle" /> Thông tin hội viên
                    </div>

                    <div className={"col-12 border-start pt-3 border-end"}>
                        <div className={"col-12 d-flex mb-3 justify-content-center align-items-center"}>
                            {data.id_user.avatar!==null?(
                                <div className={"box-avatar-100"}>
                                    <img src={ConstApp.home_url+data.id_user.avatar} />
                                </div>
                            ):(
                                <img src={logo} width={150}/>
                            )}


                        </div>

                        <div className={"row m-0 p-2 bg-light border-bottom"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="far fa-user me-1" /> Họ và tên
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                <span>{data.id_user.username}</span>
                            </div>

                        </div>

                        <div className={"row m-0 p-2 border-bottom"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="fas fa-phone-alt me-1" /> SDT / Tài khoản
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                <span>{data.id_user.user_login}</span>
                            </div>

                        </div>
                        <div className={"row m-0 p-2 bg-light border-bottom"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="fas fa-genderless me-1" /> Giới tính
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                {data.id_user.sex===1?(
                                    <span>Nam</span>
                                ):(
                                    <span>Nữ</span>
                                )}
                            </div>

                        </div>

                        <div className={"row m-0 p-2 border-bottom"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="fas fa-shield-alt me-1" /> mã hội viên
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                {data.id_user.code_user}
                            </div>

                        </div>

                    </div>


                    {_isset_object(data.id_member)?(
                        <>
                            <div className={"alert alert-success m-0 font14 text-uppercase rounded-0 font-weight-bold"}>
                                <i className="fas fa-exclamation-triangle" /> Thông tin thẻ tập
                            </div>


                            <div className={"col-12 border-start border-end"}>

                                <div className={"row m-0 p-2 border-bottom"}>

                                    <div className={"col-6 col-sm-6 p-0"}>
                                        <i className="fas fa-sort-numeric-down me-1" />
                                        Số HD
                                    </div>
                                    <div className={"col-6 col-sm-6 p-0"}>
                                        <span className={"font-weight-bold"}>{data.id_member.number_contract}</span>
                                    </div>

                                </div>

                            </div>

                            <div className={"col-12 border-start border-end"}>

                                <div className={"row m-0 p-2 border-bottom"}>

                                    <div className={"col-6 col-sm-6 p-0"}>
                                        <i className="fas fa-sort-numeric-down me-1" />
                                        Giá trị HD
                                    </div>
                                    <div className={"col-6 col-sm-6 p-0 font-weight-bold text-red"}>
                                        <span className={"font-weight-bold me-1"}>{formatNumber(data.id_member.info_contract_data.price_sale)}</span>
                                        VNĐ
                                    </div>

                                </div>

                            </div>


                            <div className={"col-12 border-start border-end bg-light"}>

                                <div className={"row m-0 p-2 border-bottom"}>

                                    <div className={"col-6 col-sm-6 p-0"}>
                                        <i className="fas fa-sort-numeric-down me-1" />
                                        Đã thanh toán
                                    </div>
                                    <div className={"col-6 col-sm-6 p-0 font-weight-bold text-red"}>
                                        {formatNumber(data.id_member.billing_information.total_price)} VNĐ
                                    </div>

                                </div>

                            </div>



                            <div className={"col-12 border-start bg-light border-end"}>

                                <div className={"row m-0 p-2 border-bottom"}>

                                    <div className={"col-6 col-sm-6 p-0"}>
                                        <i className="far fa-credit-card me-1" />
                                        Gói thẻ
                                    </div>
                                    <div className={"col-6 col-sm-6 p-0"}>
                                        <span>{data.id_member.id_card.name}</span>
                                    </div>

                                </div>

                            </div>


                        </>
                    ):(
                        <div className={"alert alert-warning round-0 w-100"}>
                            Phiếu thu chưa có HD
                        </div>
                    )}


                    <div className={"alert alert-danger m-0 font-weight-bold text-uppercase font14 rounded-0 font-weight-bold"}>
                        <i className="fab fa-amazon-pay" /> Thông tin phiếu thu
                    </div>

                    <div className={"col-12 border-start border-end"}>

                        <div className={"row m-0 p-2 border-bottom"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="fas fa-wrench me-1" /> Loại giao dịch
                            </div>
                            <div className={"col-6 col-sm-6 p-0 font-weight-bold text-uppercase text-red"}>
                                {type_wallet(data)}
                            </div>

                        </div>

                        <div className={"row m-0 p-2 border-bottom bg-light"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="fab fa-paypal me-1" /> Phương thức thanh toán
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>

                                {data.id_payment!==null && typeof data.id_payment==='object'?(
                                    <span className="badge bg-primary font12 rounded-0">
                                                                {data.id_payment.name}
                                                            </span>
                                ):(
                                    <span className="badge bg-danger font12 rounded-0">Chưa rõ</span>
                                )}

                            </div>

                        </div>


                        {data.id_bank!==null && typeof data.id_bank==='object'?(
                            <div className={"row m-0 p-2 border-bottom"}>

                                <div className={"col-12 col-sm-6 p-0"}>
                                    <i className="far fa-credit-card me-1" />  TK ngân hàng
                                </div>
                                <div className={"col-12 col-sm-6 p-0"}>
                                                           <span className="badge bg-primary font12 rounded-0">
                                                                {data.id_bank.name}
                                                            </span>
                                </div>

                            </div>

                        ):(<></>)}


                        <div className={"row m-0 p-2 border-bottom"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="far fa-user-circle me-1" />
                                Người gửi
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                {data.id_admin.username+" - "+data.id_admin.code_user}
                            </div>

                        </div>


                        <div className={"row m-0 p-2 border-bottom  bg-light"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="far fa-user-circle me-1" />
                                Người xác nhận
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                {_isset_object(data.id_admin_update)?(
                                    <span>{data.id_admin_update.username+" - "+data.id_admin_update.code_user}</span>
                                ):(
                                    "Chưa rõ"
                                )}

                            </div>

                        </div>


                        <div className={"row m-0 p-2 border-bottom"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="far fa-address-card me-1" /> Số giao dịch
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                {data.number_votes}
                            </div>

                        </div>


                        <div className={"row m-0 p-2 border-bottom bg-light"}>

                            <div className={"col-6 col-sm-6 p-0"}>
                                <i className="far fa-clock me-1" /> Thời gian
                            </div>
                            <div className={"col-6 col-sm-6 p-0"}>
                                {timeConverter(data.time,true)}
                            </div>

                        </div>





                    </div>

                </>
            );
        }
        else{
            return(
                <div className={"text-center mt-3 mb-3 d-flex flex-row justify-content-center"}>
                    <ItemLoading />
                </div>
            );
        }


    }



}
