/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import lodash from "lodash";
import {
    array_to_select,
    get_data_in_args,
    defaultValueObject,
    _isset_object,
} from "../../helper/array";
import InputMask from "react-input-mask";
import Select from 'react-select';
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";

export default class EditProfileClub extends Component{

    constructor(props) {
        super(props);

        this.state= {
            DataModal: null,
            Source:null,
            Position:null,
            Subject:null,
            AddressData:null,
            GroupData:null
        };

    }

    componentDidMount() {

        this.getSource();

        this.getPosition();

        this.getAddress();

    }


    async getGroupData(args=[]){

        const self=this;
        args.map(function(item){
            self.getGroup(item);
        });


    }

    async getGroup(id_address){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address',id_address);

        var get_data=await fetch(AppThis.ConstApp.home_api+"group", {
            method: 'POST',
            body: formData
        });

         get_data=await get_data.json();

        var  GroupData=[];

        if(this.state.GroupData!==null){

             GroupData=this.state.GroupData;
        }

         GroupData= GroupData.concat(get_data.data);

        this.setState({
            GroupData:GroupData
        });


    }

    async getSubjectData(args=[]){

        const self=this;
        args.map(function(item){
            self.getSubject(item);
        });


    }

    async getSubject(id_address){

        const self=this;

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address',id_address);

        var get_data=await fetch(AppThis.ConstApp.home_api+"subject", {
            method: 'POST',
            body: formData
        });

         get_data=await get_data.json();

        var  Subject=[];

        if(this.state.Subject!==null){

             Subject=this.state.Subject;
        }

         Subject= Subject.concat(get_data.data);

        this.setState({
            Subject:Subject
        });


    }

    getSource(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());
        formData.append('type',1);

        fetch(AppThis.ConstApp.home_api+"source", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{

            this.setState({
                Source:data.data
            });

        });

    }

    getPosition(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());
        formData.append('type',2);

        fetch(AppThis.ConstApp.home_api+"position", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{
            this.setState({
                Position:data.data
            });

        });

    }

    getAddress(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());
        formData.append('type',3);

        fetch(AppThis.ConstApp.home_api+"address", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{
            this.setState({
                AddressData:data.data
            });

        });

    }

    UpdateItemData(name,value){

        var DataModal=this.state.DataModal;

        DataModal[name]=value;


        this.setState({
            DataModal:DataModal
        });

    }

    UpdateItemDataProfile(name,value){
        var DataModal=this.state.DataModal;
        DataModal['data_profile'][name]=value;

        this.setState({
            DataModal:DataModal
        });

    }

    renderData(item){

        const self=this;

        let data=item;

        if(this.state.DataModal===null){

            var DataModal=lodash.cloneDeep(data);

            if(_isset_object(DataModal.position)){
                DataModal['position']=DataModal.position._id;
            }
            else{
                DataModal['position']=null;
            }


            if(_isset_object(DataModal.subject_id)){
                DataModal['subject_id']=get_data_in_args(DataModal.subject_id,'_id');
            }
            else{
                DataModal['subject_id']=[];
            }


            if(_isset_object(DataModal.group_id)){
                DataModal['group_id']=get_data_in_args(DataModal.group_id,'_id');
            }
            else{
                DataModal['group_id']=[];
            }


            if(_isset_object(DataModal.address_id)){
                DataModal['address_id']=get_data_in_args(DataModal.address_id,'_id');
            }
            else{
                DataModal['address_id']=[];
            }

            if(this.state.GroupData===null){
                this.getGroupData(DataModal['address_id']);
            }


            if(this.state.Subject===null){
                this.getSubjectData(DataModal['address_id']);
            }


            this.setState({
                DataModal:DataModal
            });
        }

        if(this.state.DataModal!==null){
            return(
                <div className={"row Edit-Profile"}>
                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Họ và tên <span className="text-red">( * )</span></label>
                        <input type="text" onChange={(event)=>{
                            this.UpdateItemData('username',event.target.value);
                        }} key={'username'} defaultValue={this.state.DataModal.username} placeholder="Họ và tên" required="required" className="form-control w-100" />
                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Email <span className="text-red">( * )</span></label>
                        <input type="email" onChange={(event)=>{
                            this.UpdateItemData('email',event.target.value);
                        }} key={"email"} defaultValue={this.state.DataModal.email} placeholder="Email" required="required" className="form-control w-100" />
                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Sinh nhật <span className="text-red">( * )</span></label>

                        {this.state.DataModal.birthday?(
                            <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat="dd/MM/yyyy" selected={new Date(this.state.DataModal.birthday*1000)} onChange={(date) => {
                                this.UpdateItemData('birthday',date.getTime()/1000);
                            }} placeholderText={"Sinh nhật"} />
                        ):(
                            <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat="dd/MM/yyyy" selected={new Date()} onChange={(date) => {
                                this.UpdateItemData('birthday',date.getTime()/1000);
                            }} />
                        )}

                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Chức vụ <span className="text-red">( * )</span></label>


                        <div className={"col-12 p-0 Box-Select-Mutiple"}>
                            {this.state.Position!==null?(
                                <Select isSearchable={true} onChange={(selectedOption)=>{

                                    var DataModal=self.state.DataModal;

                                    DataModal.position=selectedOption['value'];

                                    self.setState({
                                        DataModal:DataModal
                                    });
                                }} defaultValue={defaultValueObject(data.position,'name','_id')} options={array_to_select(this.state.Position,'name','_id')} />
                            ):(
                                <ItemLoading />
                            )}
                        </div>

                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Điện thoại <span className="text-red">( * )</span></label>
                        {<InputMask mask="9999999999" onChange={event=>{
                            this.UpdateItemData('phone_number',event.target.value);
                        }} key={"phone_number"} defaultValue={this.state.DataModal.phone_number}  />}
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Mã NV</label>

                        <input type="text" onChange={(event)=>{
                            this.UpdateItemData('code_user',event.target.value);
                        }} key={"code_user"} defaultValue={this.state.DataModal.code_user} placeholder="Mã nhân viên" required="required" className="form-control w-100" />

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Giới tính <span className="text-red">( * )</span></label>

                        {typeof this.state.DataModal.sex!=='undefined' && this.state.DataModal.sex===1?(
                            <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" checked="checked"  /> Nam</span>
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" /> Nữ</span>
                            </div>
                        ):(
                            <></>
                        )}

                        {typeof this.state.DataModal.sex!=='undefined' && this.state.DataModal.sex===0?(
                            <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" /> Nam</span>
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" /> Nữ</span>
                            </div>
                        ):(
                            <></>
                        )}

                        {typeof this.state.DataModal.sex==='undefined'?(
                            <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" /> Nam</span>
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateItemData("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" /> Nữ</span>
                            </div>
                        ):(
                            <></>
                        )}


                    </div>







                    <div className={"col-12"} />

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <div className={"col-12 col-sm-12 col-md-12"}>
                            <label className="font-weight-400 mb-1">Cơ sở
                                <span className={"text-red"}> ( * )</span>
                            </label>

                            <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                {this.state.AddressData!==null?(
                                    <Select isSearchable={true} onChange={(selectedOption)=>{

                                        var get_address_select=get_data_in_args(selectedOption,"value");

                                        var DataModal=self.state.DataModal;

                                        DataModal['address_id']=get_address_select;

                                        self.setState({
                                            DataModal:DataModal,
                                            GroupData:null,
                                            Subject:null
                                        },function(){
                                            self.getGroupData(DataModal['address_id']);
                                            self.getSubjectData(DataModal['address_id']);
                                        });

                                    }} defaultValue={array_to_select(data.address_id,'name','_id')} isMulti options={array_to_select(this.state.AddressData,'name','_id')} />
                                ):(
                                    <ItemLoading />
                                )}
                            </div>

                        </div>

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <div className={"col-12 col-sm-12 col-md-12"}>
                            <label className="font-weight-400 mb-1">Nhóm (Team)</label>

                            <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                {this.state.GroupData!==null?(
                                    <Select isSearchable={true} onChange={(selectedOption)=>{

                                        var get_group_select=get_data_in_args(selectedOption,"value");

                                        var DataModal=self.state.DataModal;

                                        DataModal['group_id']=get_group_select;

                                        self.setState({
                                            DataModal:DataModal
                                        });

                                    }} defaultValue={array_to_select(data.group_id,'name','_id')} isMulti options={array_to_select(this.state.GroupData,'name','_id')} />
                                ):(
                                    <></>
                                )}
                            </div>

                        </div>

                    </div>




                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <div className={"col-12 col-sm-12 col-md-12"}>
                            <label className="font-weight-400 mb-1">Bộ môn</label>

                            <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                {this.state.Subject!==null?(
                                    <Select isSearchable={true} onChange={(selectedOption)=>{

                                        var get_subject_select=get_data_in_args(selectedOption,"value");

                                        var DataModal=self.state.DataModal;

                                        DataModal['subject_id']=get_subject_select;

                                        self.setState({
                                            DataModal:DataModal
                                        });

                                    }} defaultValue={array_to_select(data.subject_id,'name','_id')} isMulti options={array_to_select(this.state.Subject,'name','_id')} />
                                ):(
                                   <></>
                                )}
                            </div>

                        </div>

                    </div>



                    <div className="col-12 mt-2 mb-3">
                        <div className="col-12 w-100 bg-header-user p-2 font15 font-weight-bold ps-3 text-white"><i
                            className="far fa-user font13 me-2"></i> HỒ SƠ NHÂN SỰ
                        </div>
                    </div>



                    {this.RenderProfileRequired()}

                    {this.RenderProfileNotRequired()}





                </div>
            );
        }
        else{
            return(
                <div className={"col-12 justify-content-center align-content-center d-flex"}>
                    <ItemLoading />
                </div>
            );
        }
    }

    RenderProfileRequired(){

        if(this.state.DataModal!==null){

            var data=this.state.DataModal;

            var args_required={
                current_address:"Địa chỉ thường trú",
                degree:"Trình độ",
                schools:"Trường học",
                certificate:"Bằng cấp",
                citizen_identification:"Căn cước công dân",
                day_citizen_identification:"Ngày cấp",
                address_citizen_identification:"Nơi cấp",
                permanent_residence:"Hộ khẩu thường trú",
                ethnic:"Dân tộc",
                religion:"Tôn giáo",
                name_protector:"Người liên hệ khẩn cấp",
                phone_protector:"Số điện thoại khẩn cấp",
            };

            const self=this;

            return Object.keys(args_required).map(function(value,key){

                return(
                    <div className="col-12 col-sm-6 mb-2">
                        <label className={"d-block w-100 my-2"}>{args_required[value]} <span
                            className="text-red">( * )</span></label>
                        <input type="text" defaultValue={typeof data['data_profile']!=='undefined' && typeof data['data_profile'][value]!=='undefined'?data['data_profile'][value]:""} onChange={event => {
                            self.UpdateItemDataProfile(value,event.target.value);
                        }} key={value} placeholder={args_required[value]} autoComplete="off" required="required" className="form-control"/>
                    </div>
                );


            });

        }

    }

    RenderProfileNotRequired(){

        if(this.state.DataModal!==null){

            var data=this.state.DataModal;

            var args_not_required={
                specialized:"Chuyên ngành",
                tax_code:"Mã số thuế",
                insurance_code:"Mã số bảo hiểm",
                bank_account_number:"TK Ngân hàng VietTinBank",
            };

            const self=this;

            return Object.keys(args_not_required).map(function(value,key){

                return(
                    <div className="col-12 col-sm-6 mb-2">
                        <label className={"d-block w-100 my-2"}>{args_not_required[value]}
                        </label>
                        <input type="text" defaultValue={typeof data['data_profile']!=='undefined' && typeof data['data_profile'][value]!=='undefined'?data['data_profile'][value]:""} onChange={event => {
                            self.UpdateItemDataProfile(value,event.target.value);
                        }} key={value} placeholder={args_not_required[value]} autoComplete="off"className="form-control"/>
                    </div>
                );


            });

        }

    }


    SubmitModal(){

        var DataModal=this.state.DataModal;

        var number_error=0;

        if(Object.keys(DataModal)<=0){
            number_error+=1;
        }

        ['username','email','phone_number','birthday','position'].map(function (value,index){

            if(DataModal[value]===null || typeof DataModal[value]==='undefined' || DataModal[value]===null){
                number_error+=1;
            }
            else{
                if(DataModal[value].length<5){
                    number_error+=1;
                }
            }


        });


        if(DataModal["address_id"]===null || typeof DataModal['address_id']==='undefined' || DataModal['address_id']===null || DataModal["address_id"].length===0){
            number_error+=1;
        }

        ['phone_number'].map(function (value,index){

            if(DataModal[value]===null || typeof DataModal[value]==='undefined'){
                number_error+=1;
            }
            else{
                if(DataModal[value].length!==10){
                    number_error+=1;
                }
            }

        });

        if(number_error===0){
            this.props.GetDataSubmit(DataModal);
        }
        else{
            toast("Vui lòng nhập đầy đủ thông tin");
        }
    }

    render() {

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Cập nhật tài khoản nhân viên Club</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                {this.props.data!==null?(this.renderData(this.props.data)):(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>



                        </div>

                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.SubmitModal();
                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                Cập nhật
                                            </a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
