/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {array_to_select, base64_encode,
    formatNumber, getItemInArray,
    timeConverterDate,
} from "../../helper/array";
import {get_name_card, number_did_contract, status_contract, status_payment} from "../../helper/member";
import NewContract from "../../global/contract/NewContract";
import DetailContract from "../../global/contract/DetailContract";
import UpdateDataContract from "../../global/contract/UpdateDataContract";
import FingerPrintUser from "../../global/finger/FingerPrintUser";
import Receipts from "../../global/contract/Receipts";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Transfer from "../../global/contract/Transfer";
import UpgradeContract from "../../global/contract/UpgradeContract";
import SessionHistory from "../../global/history/SessionHistory";
import PrintContract from "../../global/contract/PrintContract";
import Floater from 'react-floater';
import { confirmAlert } from 'react-confirm-alert';
import EditContract from "../../global/contract/EditContract";
import ChangeAddress from "../../global/contract/ChangeAddress";

const const_status_contract=[
    {
        label:"Tất cả",
        value:null
    },
    {
        label:"bảo lưu",
        value:0
    },
    {
        label:"Đang tập",
        value:1
    },
    {
        label:"Chưa kích hoạt",
        value:2
    },
    {
        label:"Huỷ",
        value:3
    },
    {
        label:"Hết hiệu lực",
        value:4
    },
];

const INIT_STATE={
    data:null,
    query:{
        start_time:null,
        end_time:null,
        status_contract:null,
        number_contract:null,
        id_admin_create:null,
        id_admin_pt:null,
        id_card:null,
        input_search:null,
        id_group:null,
        id_group_statics:null,
        id_saleprogram:null,
        time_remaining:null,
    },
    newContract:false,
    DataDetailContract:null,
    UserFingerprint:null,
    page:0,
    UpdateDataContract:null,
    MemberAction:{
        IdMemmber:null,
        action:null
    },
    SearchAdvanced:false,
    ListAdmin:null,
    IsRenew:null,
    Statics:null,
    DataPrintContract:null,
    ModalStatusImport:false,
    ListCard:null,
    ListGroupStatics:null,
    ListGroupCompany:null,
    TmpName:null,
    ChangeAddress:{
        status:false,
        data:null
    },
    ListSaleprogram:null,
    reportsDayContract:{
        status:false,
        startTime:null,
        endTime:null
    }
};

export default class personal extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);

        this.InputFileUpload=null;
    }

    SetModalImport(status){
        this.setState({
            ModalStatusImport:status
        });
    }

    UploadFileImportContract(event){

        var fileUploaded = event.target.files[0];

        let reader = new FileReader();

        reader.readAsDataURL(fileUploaded);

        this.setState({
            TmpName:event.target.files[0].name
        });
    }

    SubmitImportContract(){

        var InputFileUpload=this.InputFileUpload;

        if (typeof InputFileUpload.files[0]!=='undefined'){

            var getFile=InputFileUpload.files[0];

            if (getFile.type==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){

                var AppThis=this.props.AppThis;

                const formData = new FormData();

                AppThis.PageLoad(true);

                formData.append('token', AppThis.getToken());

                formData.append('file', getFile);
                const self=this;

                fetch(AppThis.ConstApp.home_api+"personal/import", {
                    method: 'POST',
                    mode: 'cors',
                    body: formData
                }).then(data=>data.json()).then(data=>{

                    if(data.status==='error'){
                        toast("Thao tác thất bại");
                    }
                    else{
                        toast("Thao tác thành công");
                    }
                    AppThis.PageLoad(false);

                    self.setState({
                        ModalStatusImport:false
                    });

                }).catch((e)=>{
                    toast("Thao tác thất bại");
                    AppThis.PageLoad(false);

                });


            }
            else{
                toast("Tệp tin không hợp lệ");
            }

        }
    }

    getQuerySearch(){
        var get_query=this.state.query;
        var query_send={};
        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"personal/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=data.data;

                ListAdmin.unshift({
                    key:null,
                    value:"Tất cả"
                });
                self.setState({
                    ListAdmin:ListAdmin
                });

            }


        }).catch((e)=>{

        });


    }

    getGroupStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"personal/groupstatic", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListGroupStatics=data.data;
                ListGroupStatics.unshift({
                    value:"Tất cả",
                    key:null
                });

                self.setState({
                    ListGroupStatics:ListGroupStatics
                });

            }


        }).catch((e)=>{

        });


    }

    getGroupCompany(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"personal/group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListGroupCompany=data.data;
                ListGroupCompany.unshift({
                    name:"Tất cả",
                    _id:null
                });

                self.setState({
                    ListGroupCompany:ListGroupCompany
                });

            }


        }).catch((e)=>{

        });


    }

    GetListSaleprogram(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"getsale", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListSaleprogram=data.data;
                ListSaleprogram.unshift({
                    name:"Tất cả",
                    _id:null
                },{
                    name:"Không có chương trình",
                    _id:"no"
                },{
                    name:"Có chương trình",
                    _id:"yes"
                });

                self.setState({
                    ListSaleprogram:ListSaleprogram
                });

            }


        }).catch((e)=>{

        });
    }

    getCard(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"card", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListCard=data.data;
                ListCard.unshift({
                    name:"Tất cả",
                    _id:null
                });

                self.setState({
                    ListCard:ListCard
                });

            }


        }).catch((e)=>{

        });


    }

    ExportContract(){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        fetch(Appthis.ConstApp.home_api+"personal/export", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác đã được gửi tới máy chủ");
            }

          Appthis.PageLoad(false);

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");

            Appthis.PageLoad(false);

        });


    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }
        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"personal", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    GetStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"personal/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Statics:data.data
                });
            }


        }).catch((e)=>{


        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getAdmin();

        this.getCard();

        this.GetStatics();

        this.getGroupStatics();

        this.getGroupCompany();

        this.GetListSaleprogram();
    }

    OpenModal(){

        this.setState({
            newContract:true
        });
    }

    AlertInactive(data){
        const self=this;
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui w-100'>
                        <h1>Thông báo !</h1>
                        <p>Bạn có chắc chắn muốn hủy HD ?</p>
                            <div className={"row w-100"}>
                                <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                    <button onClick={() => {
                                        onClose();
                                    }} className={"d-block w-100"}><i className="far fa-times-circle"/> Không hủy
                                    </button>
                                </div>

                                <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                    <button onClick={() => {
                                        self.Inactive(data);
                                        onClose();
                                    }} className={"d-block w-100"}><i className="fas fa-file-invoice-dollar"/> Hủy HD & PT
                                    </button>
                                </div>

                            </div>



                    </div>
                );
            },
        });
    }

    AlertTransfer(data){
        const self=this;
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui w-100'>
                        <h1>Thông báo !</h1>
                        <p>Vui lòng chọn loại chuyển nhượng.</p>
                        <div className={"row w-100"}>
                            <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                <button onClick={() => {

                                    self.setState({
                                        ChangeAddress:{
                                            status:true,
                                            data:data
                                        }
                                    });

                                    onClose();
                                }} className={"d-block w-100"}>
                                    <i className="fas fa-exchange-alt me-2" />
                                    Liên Club
                                </button>
                            </div>

                            <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                <button onClick={() => {

                                    self.setState({
                                        MemberAction:{
                                            IdMemmber:data,
                                            action:'transfer'
                                        }
                                    });

                                    onClose();
                                }} className={"d-block w-100"}>
                                    <i className="far fa-user me-2" />
                                    Trong Club
                                </button>
                            </div>

                        </div>


                    </div>
                );
            },
        });
    }

    async Inactive(data){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var data_query={
            id_member: data._id,

        };

        const self=this;

        formData.append('query', base64_encode(data_query));

        var get_data=await fetch(Appthis.ConstApp.home_api+"personal/destroy", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");
        }

        self.getData(true);

    }


    thisRenderData(){

        const self=this;
        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix box-table-w380"}>
                <div className={"overflow-auto col-12"}>
                    <table className={"mt-3 w-100 table border-0 mw2700 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-user me-2"/> TÊN HỘI VIÊN</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i
                                className="fas fa-mobile-alt me-2" /> SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-shield-alt me-2" />
                                MÃ KH</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-credit-card me-2" />
                                DỊCH VỤ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-file-excel me-2" />
                                SỐ HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-question-circle me-2" />
                                TRẠNG THÁI HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-dollar-sign me-2" />
                                GIÁ TRỊ HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-clock me-2" />
                                NGÀY BẮT ĐẦU</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-history me-2" />
                                NGÀY KẾT THÚC</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-calendar-times me-2" />
                                TỔNG SỐ BUỔI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-calendar-times me-2" />
                                TỔNG SỐ BUỔI CÒN LẠI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-user-edit me-2" />
                                NHÂN VIÊN BÁN HÀNG</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-info me-2" />
                                PT PHỤ TRÁCH</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                <i className="fas fa-directions me-2" />
                                THAO TÁC</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                               <tr>
                                   <td>
                                       <span>{value.id_user.username}</span>
                                   </td>
                                   <td>
                                       <span>{value.id_user.user_login}</span>

                                       {value.id_user.is_phone!==null?(
                                           <>
                                               <span className={"text-danger font-weight-bold font10 ms-2"}>{value.id_user.is_phone}</span>
                                           </>
                                       ):(<></>)}

                                   </td>
                                   <td>
                                       <span>{value.id_user.code_user}</span>
                                   </td>
                                   <td>
                                       <span className={"me-2"}>{value.id_card.name}</span>
                                       {get_name_card(value.id_card.type)}
                                   </td>

                                   <td>
                                       {value.group_member!==null?(
                                           <i className="fas fa-check-circle text-primary me-1" />
                                       ):(<></>)}

                                       <span className={"me-1"}>{value.number_contract}</span>
                                       {status_payment(value)}
                                   </td>
                                   <td>
                                       {status_contract(value)}
                                       {typeof value.status_print!=='undefined' && value.status_print!==0?(
                                           <i className="fas fa-print ms-2 text-success" />
                                       ):(
                                           <></>
                                       )}
                                   </td>

                                   <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.info_contract_data.price_sale)} VNĐ
                                       </span>
                                   </td>

                                   <td>
                                       <span>{timeConverterDate(value.start_day)}</span>
                                   </td>

                                   <td>
                                       <span>{timeConverterDate(value.end_day_contract)}</span>
                                   </td>

                                   <td>
                                       {value.id_card.type!==2?(
                                           <span>{value.info_contract_data.number_card}</span>
                                       ):(<></>)}

                                   </td>

                                   <td>

                                       {value.id_card.type!==2?(
                                           <>
                                               {number_did_contract(value)}
                                           </>
                                       ):(<></>)}

                                   </td>

                                   <td>
                                       <span>{value.id_admin_create.username+ " - "+value.id_admin_create.code_user}</span>
                                   </td>

                                   <td>
                                       <span>{value.id_admin_pt!==null?(
                                           value.id_admin_pt.username+" - "+value.id_admin_pt.code_user
                                       ):(<></>)}</span>
                                   </td>


                                   <td className={"fix-item-table"}>
                                       <a onClick={()=>{
                                           self.setState({
                                               DataDetailContract:value
                                           });
                                       }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>
                                           <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết hợp đồng">
                                           <i className={"far fa-folder-open"}></i>
                                       </Floater>
                                       </a>

                                       {((value.status===2 || value.status===1) && value.count_proccess===0)?(
                                          <>
                                              <a onClick={()=>{
                                                  self.AlertTransfer(value);
                                              }} className={"rounded-0 font12 d-inline-block btn btn-outline-dark p-2 me-1"}>
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chuyển nhượng HD">
                                                      <i className="fas fa-exchange-alt" />
                                                  </Floater>
                                              </a>
                                              <a onClick={()=>{
                                                  self.setState({
                                                      IsRenew:value
                                                  });
                                              }} className={"rounded-0 font12 d-inline-block btn btn-outline-success p-2 me-1"}>
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Renew and Upgrade">
                                                      <i className="fas fa-sync-alt" />
                                                  </Floater>
                                              </a>
                                          </>
                                       ):(
                                           <></>
                                       )}


                                       <a onClick={()=>{
                                           self.setState({
                                               UserFingerprint:value.id_user
                                           });
                                       }} className={"rounded-0 font12 d-inline-block btn btn-outline-warning p-2 me-1"}>
                                           <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Vân tay KH">
                                           <i className="fas fa-fingerprint" />
                                           </Floater>
                                       </a>


                                           <a onClick={()=>{
                                               self.setState({
                                                   UpdateDataContract:value
                                               });
                                           }} className={"rounded-0 font12 d-inline-block btn btn-outline-danger p-2 me-1"}>
                                               <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật HD">
                                               <i className={"far fa-clock"} />
                                               </Floater>
                                           </a>


                                       <a onClick={()=>{
                                           self.setState({
                                               MemberAction:{
                                                   IdMemmber:value,
                                                   action:'history'
                                               }
                                           });
                                       }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                           <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lịch sử tập luyện">
                                           <i className={"fas fa-history"} />
                                           </Floater>
                                       </a>



                                       {value.status===1 && value.count_proccess===0?(
                                           <a onClick={()=>{
                                               self.setState({
                                                   MemberAction:{
                                                       IdMemmber:value,
                                                       action:'receipts'
                                                   }
                                               });
                                           }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                               <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lên phiếu thu">
                                               <i className={"fas fa-money-bill"} />
                                               </Floater>
                                           </a>

                                       ):(<></>)}


                                       {value.status===1 || value.status===2?(
                                           <a onClick={()=>{
                                               self.props.AppThis.SetHiddenHeader();
                                               self.setState({
                                                   DataPrintContract:value
                                               });
                                           }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                               <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="In hợp đồng">
                                                   <i className="fas fa-print" />
                                               </Floater>
                                           </a>

                                       ):(<></>)}



                                             <a onClick={()=>{
                                                 self.setState({
                                                     MemberAction:{
                                                         IdMemmber:value,
                                                         action:'edit'
                                                     }
                                                 });
                                             }} className={"rounded-0 font12 d-inline-block btn btn-outline-danger p-2 me-1"}>
                                                 <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Sửa hợp đồng">
                                                     <i className="far fa-edit" />
                                                 </Floater>
                                             </a>


                                       {value.status!==3?(
                                           <a onClick={()=>{
                                               self.AlertInactive(value);
                                           }} className={"rounded-0 font12 d-inline-block btn btn-outline-dark p-2 me-1"}>
                                               <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Hủy hợp đồng">
                                                   <i className="fas fa-minus" />
                                               </Floater>
                                           </a>

                                       ):(
                                           <></>
                                       )}

                                   </td>

                               </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    RenderStatics(){

        var Statics=this.state.Statics;

        if(Statics!==null){
            return(
                <div className={"row m-0 mt-4"}>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-warning"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD PT trong tháng</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_pt_month}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-tenge rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Member</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_pt_member}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-money-check rounded-circle bg-primary"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Nhóm CD</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_statics_group}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">

                                <i className="d-flex justify-content-center text-white align-items-center fab fa-adversal rounded-circle bg-success"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Linh hoạt</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_flexible}</p>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-none d-xl-block mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-external-link-alt rounded-circle bg-dark"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD mới</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_new}</p>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-none d-xl-block mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-exchange-alt rounded-circle bg-primary"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD chuyển nhượng</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_transfer}</p>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-none d-xl-block mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center align-items-center fab fa-wpressr rounded-circle bg-light"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Renew</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_renew}</p>
                            </div>
                        </div>
                    </div>




                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-none d-xl-block mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-edit rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD Upgrade</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.total_upgrade}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-none d-xl-block mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-edit rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">HD NewSale</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.news_sale}</p>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
        else{
            return(
                <div className={"col-12 justify-content-center d-flex flex-row mb-4 pt-5"}>
                    <ItemLoading />
                </div>
            );
        }
    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"personal/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }



    submitReportDay(){

        var reportsDayContract=this.state.reportsDayContract;

        var numberError=0;

        if(reportsDayContract.startTime===null || reportsDayContract.endTime===null){
            numberError++;
        }

        var AppThis=this.props.AppThis;

        if(numberError===0){

            this.setState({
                reportsDayContract:{
                    status:false,
                    startTime:null,
                    endTime:null
                }
            });

            const formData = new FormData();

            AppThis.PageLoad(true);

            formData.append('token', AppThis.getToken());

            formData.append('id_address', AppThis.getAddress());

            formData.append('data', base64_encode({
                timeStart:reportsDayContract.startTime,
                timeEnd:reportsDayContract.endTime
            }));

            fetch(AppThis.ConstApp.home_api+"personal/reportday", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='error'){
                    toast("Thao tác thất bại");
                }
                else{
                    toast("Thao tác đã được gửi đến máy chủ");
                }

                AppThis.PageLoad(false);

            }).catch((e)=>{
                toast("Thao tác thất bại");
                AppThis.PageLoad(false);
            });

        }
        else{
            toast("Vui lòng nhập đầy đủ thông tin");
        }


    }


    render(){
        const self=this;

        var Appthis=this.props.AppThis;

        if(this.state.DataPrintContract!==null){
            return(
                <PrintContract Onclose={()=>{
                    self.props.AppThis.SetShowHeader();
                    self.setState({
                        DataPrintContract:null
                    });
                }} AppThis={this.props.AppThis} path={"personal"} data={this.state.DataPrintContract}  />
            );

        }
        else if (this.state.newContract===true){
            return (
                <NewContract path={"personal"} AppThis={this.props.AppThis} onClose={()=>{
                    this.setState({
                        newContract:false
                    });
                    this.getData(true);
                }} />
            );
        }
        else if (this.state.IsRenew!==null){

            return(
                <UpgradeContract onClose={()=>{
                    this.setState({
                        IsRenew:null
                    });
                }} onSuccess={()=>{
                    this.setState({
                        IsRenew:null
                    });
                    this.getData(true);
                }} AppThis={this.props.AppThis} path={"personal"} InfoContract={this.state.IsRenew} />
            );
        }
        else{

                if(this.state.data!==null){
                    return(
                        <div className={"container-fluid container-main-template mb-5"}>

                            <>
                                {this.state.UpdateDataContract!==null?(
                                    <>
                                        <UpdateDataContract OnClose={()=>{
                                            this.setState({
                                                UpdateDataContract:null
                                            });
                                        }} onSuccess={()=>{
                                            this.setState({
                                                UpdateDataContract:null
                                            },function(){
                                                self.getData(true);
                                            });
                                        }} AppThis={this.props.AppThis} path={"personal"} data={this.state.UpdateDataContract} />
                                    </>
                                ):(
                                    <></>
                                )}

                            </>
                            <>
                                {this.state.MemberAction.IdMemmber!==null?(
                                    this.MemberAction()
                                ):(
                                    <></>
                                )}
                            </>
                            <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                                <div className="row m-0">

                                    <div className="col-12 col-sm-12 d-flex align-items-center">
                                        <h3 className="font18 mb-0 text-uppercase font-weight-bold font-weight-bold"> Tất cả HD</h3>
                                    </div>

                                    <div className="col-12 col-sm-12 text-right text-end">

                                        <div className="col-12 p-0 mb-2">
                                            Tổng bản ghi : {this.state.data.total_row}
                                        </div>

                                        <div className={"col-12"}>
                                            <button onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:true
                                                });
                                            }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                        </div>


                                        <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                            <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                                <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                    <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                    <a onClick={()=>{
                                                        this.setState({
                                                            ModalSearchMobile:false
                                                        });
                                                    }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                        <i className="fas fa-times"></i>
                                                    </a>
                                                </div>


                                                <input onChange={(event)=>{

                                                    var query=this.state.query;

                                                    query.input_search=event.target.value;

                                                    this.setState({
                                                        query:query
                                                    });

                                                }} autoComplete={"off"} defaultValue={this.state.query.input_search} type="text" name="input_search"
                                                       className="font13 form-control d-inline-block w-auto rounded-0"
                                                       placeholder="Tên khách hàng/SDT"/>

                                                <a onClick={()=>{
                                                    this.getData(true);
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                    <i className="fas fa-search" />Tìm kiếm </a>


                                                <a onClick={()=>{
                                                    this.setState({
                                                        SearchAdvanced:true,
                                                        ModalSearchMobile:false
                                                    });
                                                }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                                    <i className="fas fa-search-plus" /> Tìm kiếm nâng cao </a>


                                                <a onClick={()=>{
                                                    this.OpenModal();
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>



                                                <a onClick={()=>{
                                                    this.SetModalImport(true);
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} className="me-2 ibtn btn rounded-0 btn-outline-dark font13 font-weight-600">
                                                    <i className="fas fa-file-excel"></i>
                                                    Nhập hợp đồng
                                                </a>


                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false,
                                                        reportsDayContract:{
                                                            status:true,
                                                            startTime:null,
                                                            endTime:null
                                                        }
                                                    });
                                                }} className="me-2 ibtn btn rounded-0 btn-outline-primary font13 font-weight-600">
                                                    <i className="fas fa-file-excel"></i>
                                                    Báo cáo ca
                                                </a>



                                            </div>

                                        </div>




                                    </div>

                                </div>


                                {this.RenderStatics()}

                                <div className={"col-12 ps-3 pe-3"}>

                                    {this.thisRenderData()}
                                </div>

                                <div className={"col-12 p-3 mt-4"}>
                                    <ul className="pagination">
                                        {this.state.data.prev>=0?(
                                            <li className="page-item" >
                                                <a className="page-link" onClick={()=>{
                                                    var page=this.state.page-1;
                                                    this.setState({
                                                        page:page
                                                    },function(){
                                                        self.getData(true);
                                                    });
                                                }}>
                                                    Trước
                                                </a>
                                            </li>
                                        ):(<></>)}

                                        <li className="page-item">
                                            <a className="page-link">
                                                <span>{this.state.data.curent+1}</span>
                                            </a></li>

                                        {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                            <li className="page-item">
                                                <a className="page-link" onClick={()=>{
                                                    this.setState({
                                                        page:this.state.data.next
                                                    },function(){
                                                        self.getData(true);
                                                    });
                                                }}>
                                                    Kế tiếp
                                                </a>
                                            </li>
                                        ):(<></>)}

                                    </ul>
                                </div>


                            </div>


                            {this.state.DataDetailContract!==null?(
                                <DetailContract OnClose={()=>{
                                    this.setState({
                                        DataDetailContract:null
                                    });

                                }} EventDetail={(id)=>{
                                    this.setState({
                                        DataDetailContract:null
                                    },function(){
                                        self.getDetailContract(id);
                                    });
                                }} data={this.state.DataDetailContract} />
                            ):(
                                <></>
                            )}


                            {this.state.UserFingerprint!==null?(
                                <FingerPrintUser onClose={()=>{
                                    this.setState({
                                        UserFingerprint:null
                                    });
                                }}  AppThis={this.props.AppThis} path={"personal"} data={this.state.UserFingerprint} />
                            ):(
                                <></>
                            )}


                            {this.state.SearchAdvanced===true?(
                                this.SearchAdvanced()
                            ):(<></>)}


                            {this.state.ModalStatusImport===true?(
                                <div className="modal d-block position-fixed">
                                    <div className={"modal-dialog modal-md justify-content-center align-items-center flex-row d-flex h-100"}>
                                        <div className="modal-content">
                                            <div className="modal-header bg-dark position-relative font18">
                                                <p className="modal-title font-weight-bold text-uppercase">Import Dữ liệu HD</p>
                                                <a  onClick={()=>{
                                                    this.SetModalImport(false);
                                                }} href="#" className={"text-white"}><i className="fas fa-times"></i></a>
                                            </div>
                                            <div className="modal-body">

                                                <div className={"alert alert-danger rounded-0"}>
                                                    <i className="fas fa-exclamation-triangle"></i> Vui lòng tải tệp dữ liệu hợp đồng lên đúng theo mẫu
                                                    <span>( Định dạng dữ liệu dạng text )</span>
                                                </div>

                                                <p>Download dữ liệu mẫu <a href={Appthis.ConstApp.home_url+"/examples/contract.xlsx"}><strong><i className="fas fa-download"></i> Tải xuống</strong></a></p>

                                                <div onClick={()=>{
                                                    this.InputFileUpload.click();
                                                }} className={"col-12 mt-3 mb-3 text-center pt-3 pb-3 box-upload-file"}>
                                                    <input type={"file"} ref={rel=>{
                                                        this.InputFileUpload=rel;
                                                    }} onChange={(event)=>{
                                                        this.UploadFileImportContract(event);
                                                    }} className={'d-none'} />
                                                    Tải lên tệp tin
                                                    {this.state.TmpName!==null?(
                                                        <p className={"m-0 text-red p-0 text-center font12"}>{this.state.TmpName}</p>
                                                    ):(<></>)}

                                                </div>

                                                <div className={"row mt-3"}>

                                                    <div className={"col-12 col-sm-6"}>
                                                        <a href="#" onClick={()=>{
                                                            this.SetModalImport(false);
                                                        }} className={"btn btn-dark p-2 rounded-0 w-100"}>Hủy</a>
                                                    </div>
                                                    <div className={"col-12 col-sm-6"}>
                                                        <a href="#" onClick={()=>{
                                                            this.SubmitImportContract();
                                                        }} className={"btn btn-primary p-2 rounded-0 w-100"}>Gửi đi</a>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>


                                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

                                </div>
                            ):(<></>)}

                            {this.state.ChangeAddress.status===true?(
                                <ChangeAddress onClose={()=>{
                                    self.setState({
                                        ChangeAddress:{
                                            status:false,
                                            data:null
                                        }
                                    });

                                }} onSuccess={()=>{
                                    self.setState({
                                        ChangeAddress:{
                                            status:false,
                                            data:null
                                        }
                                    },function(){
                                        self.getData(true);
                                    });

                                }} AppThis={this.props.AppThis} path={"personal"} data={this.state.ChangeAddress.data} />

                            ):(
                                <></>
                            )}





                            {this.state.reportsDayContract.status===true?(
                                <div className="modal d-block position-fixed">
                                    <div className={"modal-dialog modal-md justify-content-center align-items-center flex-row d-flex h-100"}>
                                        <div className="modal-content">
                                            <div className="modal-header bg-dark position-relative font18">
                                                <p className="modal-title font-weight-bold text-uppercase">BÁO CÁO CA LÀM VIỆC</p>
                                                <a  onClick={()=>{

                                                    this.setState({
                                                        reportsDayContract:{
                                                            status:false,
                                                            startTime:null,
                                                            endTime:null
                                                        }
                                                    });

                                                }} href="#" className={"text-white"}><i className="fas fa-times"></i></a>
                                            </div>

                                            <div className="modal-body">

                                                <div className={"alert alert-primary rounded-0"}>
                                                    <i className="fas fa-exclamation-triangle"></i> Vui lòng nhập thời gian bắt đầu và kết thúc của ca
                                                </div>


                                                <div className={"row mt-3 mb-3"}>

                                                    <div className={"col-12 col-sm-6 box_input"}>
                                                        <DatePicker
                                                            selected={this.state.reportsDayContract.startTime?new Date(this.state.reportsDayContract.startTime*1000):null}
                                                            onChange={(date) => {

                                                                if(date!==null){

                                                                    var reportsDayContract=self.state.reportsDayContract;
                                                                    reportsDayContract['startTime']=Math.ceil(date.getTime()/1000);

                                                                    self.setState({
                                                                        reportsDayContract:reportsDayContract
                                                                    });

                                                                }


                                                            }}
                                                            showTimeInput
                                                            dateFormat="dd/MM/yyyy h:mm aa"
                                                            placeholderText={"Thời gian bắt đầu"}
                                                        />
                                                    </div>
                                                    <div className={"col-12 col-sm-6 box_input"}>
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy h:mm aa"
                                                            selected={this.state.reportsDayContract.endTime?new Date(this.state.reportsDayContract.endTime*1000):null}
                                                            onChange={(date) => {

                                                                if(date!==null) {
                                                                    var reportsDayContract=self.state.reportsDayContract;
                                                                    reportsDayContract['endTime']=Math.ceil(date.getTime()/1000);

                                                                    self.setState({
                                                                        reportsDayContract:reportsDayContract
                                                                    });

                                                                }


                                                            }}
                                                            showTimeInput
                                                            placeholderText={"Thời gian kết thúc"}
                                                        />
                                                    </div>

                                                </div>


                                                <div className={"row mt-3"}>

                                                    <div className={"col-12 col-sm-6"}>
                                                        <a href="#" onClick={()=>{

                                                            this.setState({
                                                                reportsDayContract:{
                                                                    status:false,
                                                                    startTime:null,
                                                                    endTime:null
                                                                }
                                                            });

                                                        }} className={"btn btn-danger p-2 rounded-0 w-100"}>Hủy</a>
                                                    </div>
                                                    <div className={"col-12 col-sm-6"}>
                                                        <a href="#" onClick={()=>{
                                                            this.submitReportDay();
                                                        }} className={"btn btn-primary p-2 rounded-0 w-100"}>Gửi đi</a>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </div>


                                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

                                </div>
                            ):(<></>)}



                        </div>
                    );
                }
                else{

                    return(
                        <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                            <ItemLoading size={"Large"} />
                        </div>
                    );
                }

        }


    }

    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm nâng cao</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row box_input"}>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian bắt đầu"} />


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Tên khách hàng/SDT</p>
                                    <input defaultValue={this.state.query.input_search} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("input_search",event.target.value);
                                    }} className={"form-control"} placeholder={"Tên khách hàng/SDT"} />
                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số HD</p>
                                    <input defaultValue={this.state.query.number_contract} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("number_contract",event.target.value);
                                    }} className={"form-control"} placeholder={"Số HD"} />
                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Trạng thái HD</p>

                                    <Select defaultValue={this.state.query.status_contract!==null?getItemInArray(this.state.query.status_contract,"value",const_status_contract):null} isSearchable={true} onChange={(selectedOption)=>{
                                        if(selectedOption!==null && typeof selectedOption!=='undefined'){
                                            self.UpdateQuerySearch("status_contract",selectedOption.value);
                                        }
                                        else{
                                            self.UpdateQuerySearch("status_contract",null);
                                        }

                                    }}  options={const_status_contract} />


                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Người lên HD</p>

                                    {self.state.ListAdmin!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                           var id_admin_create=selectedOption.value;
                                           self.UpdateQuerySearch("id_admin_create",id_admin_create);
                                        }} defaultValue={this.state.query.id_admin_create!==null?getItemInArray(this.state.query.id_admin_create,"value",array_to_select(this.state.ListAdmin,"value","key")):null} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                    ):(
                                        <ItemLoading />
                                    )}


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>PT phụ trách</p>

                                    {self.state.ListAdmin!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_admin_pt=selectedOption.value;
                                            self.UpdateQuerySearch("id_admin_pt",id_admin_pt);

                                        }}  defaultValue={this.state.query.id_admin_pt!==null?getItemInArray(this.state.query.id_admin_pt,"value",array_to_select(this.state.ListAdmin,"value","key")):null} options={array_to_select(this.state.ListAdmin,"value","key")} />
                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Loại dịch vụ</p>

                                    {this.state.ListCard!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_card=selectedOption.value;
                                            self.UpdateQuerySearch("id_card",id_card);
                                        }} options={array_to_select(this.state.ListCard,"name","_id")}
                                                defaultValue={this.state.query.id_card!==null?getItemInArray(this.state.query.id_card,"value",array_to_select(this.state.ListCard,"name","_id")):null}
                                        />
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Nhóm HD cố định</p>
                                    {this.state.ListGroupStatics!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_group_statics=selectedOption.value;
                                            self.UpdateQuerySearch("id_group_statics",id_group_statics);
                                        }} options={array_to_select(this.state.ListGroupStatics,"value","key")}
                                                defaultValue={this.state.query.id_group_statics!==null?getItemInArray(this.state.query.id_group_statics,"value",array_to_select(this.state.ListGroupStatics,"value","key")):null}
                                        />
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Nhóm Doanh nghiệp</p>
                                    {this.state.ListGroupCompany!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_group=selectedOption.value;
                                            self.UpdateQuerySearch("id_group",id_group);
                                        }} options={array_to_select(this.state.ListGroupCompany,"name","_id")}
                                                defaultValue={this.state.query.id_group!==null?getItemInArray(this.state.query.id_group,"value",array_to_select(this.state.ListGroupCompany,"name","_id")):null}
                                        />
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Chương trình bán hàng</p>
                                    {this.state.ListSaleprogram!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_saleprogram=selectedOption.value;
                                            self.UpdateQuerySearch("id_saleprogram",id_saleprogram);
                                        }} options={array_to_select(this.state.ListSaleprogram,"name","_id")}
                                                defaultValue={this.state.query.id_saleprogram!==null?getItemInArray(this.state.query.id_saleprogram,"value",array_to_select(this.state.ListSaleprogram,"name","_id")):null}
                                        />
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số ngày còn lại</p>
                                    <input type={'number'} onChange={(e)=>{
                                        this.UpdateQuerySearch("time_remaining",e.target.value);
                                    }} value={this.state.query.time_remaining!==null?this.state.query.time_remaining:null} className={"form-control w-100 rounded-0"} />
                                </div>


                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"row"}>


                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                self.getData(true);

                                                self.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{

                                                confirmAlert({
                                                    customUI: ({ onClose }) => {
                                                        return (
                                                            <div className='custom-ui'>
                                                                <h1>Cảnh báo !</h1>
                                                                <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                                                                <button onClick={onClose}>Không</button>
                                                                <button
                                                                    onClick={() => {
                                                                        self.ExportContract();
                                                                        self.setState({
                                                                            SearchAdvanced:false
                                                                        });
                                                                        onClose();
                                                                    }}
                                                                > Vâng
                                                                </button>
                                                            </div>
                                                        );
                                                    }
                                                });


                                            }} className={"btn btn-success w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-download me-1" />  Xuất HD
                                            </a>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-danger w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }

    MemberAction(){

        const self=this;

        if(this.state.MemberAction.IdMemmber!==null){

            switch (this.state.MemberAction.action) {

                case "receipts":
                    return(<Receipts onClose={()=>{
                        self.setState({
                            MemberAction:{
                                IdMemmber:null,
                                action:null
                            }
                        });
                    }} onSuccess={()=>{
                        self.setState({
                            MemberAction:{
                                IdMemmber:null,
                                action:null
                            }
                        },function(){
                            self.getData(true);
                        });
                    }} AppThis={this.props.AppThis} path={"personal"} data={this.state.MemberAction.IdMemmber}  />);

                case "history":
                    return(<SessionHistory onClose={()=>{
                        self.setState({
                            MemberAction:{
                                IdMemmber:null,
                                action:null
                            }
                        });

                    }} AppThis={this.props.AppThis} path={"personal"}  data={this.state.MemberAction.IdMemmber} />);

                case "edit":
                    return(<EditContract onClose={()=>{
                        self.setState({
                            MemberAction:{
                                IdMemmber:null,
                                action:null
                            }
                        },function(){
                            self.getData(true);
                        });

                    }} onSuccess={()=>{
                        self.setState({
                            MemberAction:{
                                IdMemmber:null,
                                action:null
                            }
                        },function(){
                            self.getData(true);
                        });
                    }} AppThis={this.props.AppThis} path={"personal"} data={this.state.MemberAction.IdMemmber} />);

                case "transfer":
                    return(
                        <Transfer onClose={()=>{
                            self.setState({
                                MemberAction:{
                                    IdMemmber:null,
                                    action:null
                                }
                            });
                        }} onSuccess={()=>{
                            self.setState({
                                MemberAction:{
                                    IdMemmber:null,
                                    action:null
                                }
                            },function (){
                                self.getData(true);
                            });
                        }} AppThis={this.props.AppThis} path={"personal"} data={this.state.MemberAction.IdMemmber} />
                    );

            }

        }
        else{
            return(<></>);
        }
    }

}
