/* eslint-disable */
import React,{Component} from "react";
import logo from "../assets/images/logo.png";
import { toast } from 'react-toastify';
import {_isset_string, base64_encode, validateEmail} from "../helper/array";
import Cookies from "js-cookie";
import ItemLoading from "./ItemLoading";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login';
import InputMask from "react-input-mask";

var lodash=require("lodash");

const INIT_STATE={
        username:null,
        password:null,
    isAction:0,
    DataRegister:{
            email:null,
        username:null,
        password:null,
        address:null,
        code_user:null,
        phone_number:null
    },
    ListAddress:null,
    EmailForgot:null,
    NumberError:0
};

export default class Login extends Component{
    constructor(props) {
        super(props);

        this.state=lodash.cloneDeep(INIT_STATE);
        Cookies.remove('token_app');
        Cookies.remove('current_address');
    }

    ResetState(){
        this.setState(lodash.cloneDeep(INIT_STATE));
    }

    UpdateDataRegister(item,value){

        var DataRegister=this.state.DataRegister;

        DataRegister[item]=value;

        this.setState({
            DataRegister:DataRegister
        });

    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    SubmitRegister(){

        var number_error=0;

        var DataRegister=this.state.DataRegister;

        ['email','username','password','address','code_user','phone_number'].map(function(item){

            if(DataRegister[item]===null || DataRegister[item].length<5){
                number_error+=1;
            }
        });

        if(validateEmail(DataRegister['email'])===false){
            number_error+=1;
        }

        if(DataRegister['phone_number']===null || DataRegister['phone_number'].length!==10){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
            return false;
        }

        this.props.AppThis.PageLoad(true);

        const formData = new FormData();

        formData.append('data', base64_encode(DataRegister));

        fetch(this.props.AppThis.ConstApp.home_api+"register", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(response=>{

            if(response.status==='ok'){
                toast("Đăng ký thành công");
            }
            else{
                toast("Đăng ký không thành công.Địa chỉ Email hoặc số điện thoại của bạn đã tồn tại");
            }

            this.props.AppThis.PageLoad(false);

            this.ResetState();


        }).catch((e)=>{
            this.props.AppThis.PageLoad(false);
        });
    }

    async SubmitLogin(){

        if(_isset_string(this.state.username) && _isset_string(this.state.password)){

            if(validateEmail(this.state.username)===false){
                toast("Địa chỉ Email không hợp lệ");
                return;
            }
            var AppThis=this.props.AppThis;
            AppThis.PageLoad(true);
            const formData = new FormData();
            formData.append('username', this.state.username);
            formData.append('password', this.state.password);
            formData.append('os', "browser");
            try{

                var get_login=await fetch(AppThis.ConstApp.home_api+"gettoken", {
                    method: 'POST',
                    mode: 'cors',
                    body: formData
                });

                var get_data=await get_login.json();

                AppThis.PageLoad(false);

                if(get_data.status==='error'){
                    toast("Đăng nhập không thành công.Vui lòng thử lại");

                    var NumberError=this.state.NumberError;
                    NumberError+=1;

                    if(NumberError>=5){
                        Cookies.set('DisableLogin', true,{
                            expires:1/48
                        });
                    }

                    this.setState({
                        NumberError:NumberError
                    })
                }
                else{
                    await AppThis.SaveToken(get_data.token);

                    setTimeout(function(){
                        AppThis.SetUrlRedirect("/");
                    },200);

                }
            }
            catch (e) {
                toast("Vui lòng kiểm tra lại đường truyền");
            }



        }
        else{
            toast("Vui lòng kiểm tra lại");
        }
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);
        this.props.AppThis.DestroyToken(false);
        Cookies.remove('token_app');
        Cookies.remove('current_address');
        this.getDisableCookie();
    }

    async getDisableCookie(){

        var DisableLogin=await Cookies.get('DisableLogin');

        if(DisableLogin!==null && typeof DisableLogin!=='undefined'){

            this.setState({
                NumberError:6
            });

        }

    }


    SubmitForgot(){

        var EmailForgot=this.state.EmailForgot;

        var number_error=0;

        if(validateEmail(EmailForgot)===false){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng nhập địa chỉ Email");
            return false;
        }
        else{


            this.props.AppThis.PageLoad(true);

            const formData = new FormData();

            formData.append('email', EmailForgot);

            fetch(this.props.AppThis.ConstApp.home_api+"forgot", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(response=>{

                if(response.status==='ok'){
                    toast("Mật khẩu của bạn đã được gửi");
                }
                else{
                    toast("Không tìm thấy địa chỉ email.Vui lòng thử lại");
                }
                this.props.AppThis.PageLoad(false);

            }).catch((e)=>{
                toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
                this.props.AppThis.PageLoad(false);
            });



        }

    }

    RegisterListAddress(){

        try{
            const formData = new FormData();

           fetch(this.props.AppThis.ConstApp.home_api+"register/address", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(response=>{

                if(response.status==='ok') {

                    var ListAddress=[{
                        _id:null,
                        name:"Chọn địa chỉ"
                    }];

                    ListAddress=ListAddress.concat(response.data);

                    this.setState({
                        ListAddress: ListAddress
                    });

                }
                else{

                }

           });
        }
        catch (e){

        }

    }


    async LoginSocial(name,token){

        var AppThis=this.props.AppThis;

        AppThis.PageLoad(true);

        const formData = new FormData();

        formData.append('action', name);

        formData.append('accessToken', token);

        formData.append('os', 'browser');

        var fetch_data=await fetch(AppThis.ConstApp.home_api+"gettoken/social", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });
        fetch_data=await fetch_data.json();

        AppThis.PageLoad(false);

        if(fetch_data.status==='ok'){

            await AppThis.SaveToken(fetch_data.token);

            toast("Đăng nhập thành công");

            setTimeout(function(){
                AppThis.SetUrlRedirect("/");
            },200);

        }
        else{
            toast("Đăng nhập không thành công");
        }
    }

    async responseFacebook(response){


        if(response!==null && typeof response==='object'){

            if(typeof response['accessToken']!=='undefined'){

                try{
                    await this.LoginSocial('facebook',response['accessToken']);
                }
                catch (e){
                    this.props.AppThis.PageLoad(true);

                    toast("Đăng nhập không thành công");
                }
            }
            else{
                this.props.AppThis.PageLoad(true);
                toast("Đăng nhập không thành công");
            }

        }
        else{
            toast("Đăng nhập không thành công");
        }
    }


    async responseGoogle(response){

           var google_token=response.getAuthResponse().access_token;

           await this.LoginSocial('google',google_token);

    }
    ViewLogin(){

        const self=this;

        return(
            <>
                <h2 className={"font-weight-bolder text-center text-danger display5"}>Đăng nhập</h2>
                <p className={"text-muted font15 mt-3 text-center"}>Vui lòng nhập đầy đủ thông tin</p>

                <div className={"alert alert-danger mt-2 mb-3"}>
                    <i className="fas fa-exclamation-triangle" /> Nhập sai tài khoản quá 5 lần sẽ bị hệ thống khóa trong 30 phút.

                    {this.state.NumberError>0 && this.state.NumberError<=5?(
                        <>
                            Còn lại :
                            <span className={"font-weight-bold"}>{5-this.state.NumberError}</span>
                        </>
                    ):(<></>)}

                </div>

                <div className={"col-12 p-0 input-group"}>
                    <label>Email</label>
                    <input type="email" onChange={event => {
                        this.setState({
                            username:event.target.value
                        });

                    }} name="email" placeholder="Email" autoComplete="off" required="required" className="App-Input-Login w-100" />
                </div>

                <div className={"col-12 p-0 mt-2 input-group"}>
                    <label>Mật khẩu</label>
                    <input type="password" onChange={text=>{
                        this.setState({
                            password:text.target.value
                        });
                    }} name="password" placeholder="Mật khẩu" autoComplete="off" required="required" className="App-Input-Login w-100" />
                </div>

                <div className="row mt-4">
                    <div className="col-12 col-lg-12 col-xl-6 text-left">
                        <a href={"#"} onClick={()=>{
                            this.SubmitLogin();
                        }} className={"Btn-App-Login text-white d-inline-block"}>Đăng nhập</a>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-6 text-end">

                        <a className={"me-2 text-danger"} href={"#"} onClick={(e)=>{
                            this.setState({
                                isAction:2
                            });
                        }}>
                            Quên mật khẩu</a>

                    <a href={"#"} onClick={(e)=>{
                        this.setState({
                            isAction:1
                        },function(){
                            self.RegisterListAddress();
                        });

                    }} className="text-primary">Đăng ký</a>
                </div>

                </div>
                <div className="col-12 mt-4 text-center line-text"><span>Hoặc đăng nhập bằng</span></div>

                <div className="col-12 col-sm-12 col-md-12 mt-3">
                    <div className="row mb-3">
                        <div className="box-login-social col-6 mt-3 text-center">


                            <FacebookLogin
                                appId="485095840052509"
                                callback={(response)=>{
                                    self.responseFacebook(response);
                                }}
                                render={renderProps => (
                                    <button className="btn btn-outline-primary w-100 p-2 rounded-0 font13" onClick={renderProps.onClick}>
                                        Đăng nhập Facebook
                                    </button>
                                )}
                            />

                        </div>
                        <div className="box-login-social col-6 mt-3 text-center">


                            <GoogleLogin
                                clientId="276132282254-1713rlvkgvpqphhav1tnq31fdtjer6fn.apps.googleusercontent.com"
                                render={renderProps => (
                                    <button className="btn btn-outline-danger w-100 p-2 rounded-0 font13" onClick={renderProps.onClick} >
                                        Đăng nhập Google
                                    </button>
                                )}
                                autoLoad={false}
                                buttonText="Login"
                                onSuccess={(response)=>{
                                    self.responseGoogle(response);
                                }}
                                onFailure={(response)=>{

                                }}
                                cookiePolicy={'single_host_origin'}
                            />

                        </div>
                    </div>
                </div>

            </>
        );
    }

    ViewRegister(){

        return(
            <>

                <h2 className={"font-weight-bolder text-center text-danger display5"}>Đăng ký</h2>
                <p className={"text-muted font15 mt-3 text-center"}>Vui lòng nhập đầy đủ thông tin</p>

                <div className={"col-12 p-0 input-group"}>
                    <label>Cơ sở <span className={"text-red"}>( * )</span></label>
                </div>

                <div className={"col-12 p-0 mt-3 mb-3"}>
                    {this.state.ListAddress!==null?(
                        <select onChange={(event)=>{
                            this.UpdateDataRegister("address",event.target.value);
                        }} className={"form-control w-100 App-Input-Login rounded-0"}>
                            {this.state.ListAddress.map(function(item){
                                return(
                                    <option value={item._id}>{item.name}</option>
                                );
                            })}
                        </select>
                    ):(
                        <ItemLoading />
                    )}
                </div>




                <div className={"col-12 p-0 input-group InputMask"}>
                    <label>SDT/ID <span className={"text-red"}>( * )</span></label>

                    <InputMask mask="9999999999" onChange={event=>{

                        this.UpdateDataRegister("phone_number",event.target.value);

                    }} placeholder={"Số điện thoại"} className={"App-Input-Login w-100"} />

                </div>


                <div className={"col-12 p-0 input-group"}>
                    <label>Email <span className={"text-red"}>( * )</span></label>
                    <input type="email" onChange={event => {
                        this.UpdateDataRegister("email",event.target.value);
                    }} name="email" placeholder="Email" autoComplete="off" required="required" className="App-Input-Login w-100" />
                </div>


                <div className={"col-12 p-0 input-group"}>
                    <label>Mật khẩu <span className={"text-red"}>( * )</span></label>
                    <input type="password" onChange={event => {
                        this.UpdateDataRegister("password",event.target.value);
                    }} name="password" placeholder="Mật khẩu" autoComplete="off" required="required" className="App-Input-Login w-100" />
                </div>


                <div className={"col-12 p-0 input-group"}>
                    <label>Họ và tên <span className={"text-red"}>( * )</span></label>
                    <input type="text" onChange={event => {
                        this.UpdateDataRegister("username",event.target.value);
                    }} name="username" placeholder="Họ và tên" autoComplete="off" required="required" className="App-Input-Login w-100" />
                </div>



                <div className={"col-12 p-0 input-group"}>
                    <label>Mã nhân viên <span className={"text-red"}>( * )</span></label>
                    <input type="text" onChange={event => {
                        this.UpdateDataRegister("code_user",event.target.value);
                    }} name="username" placeholder="Mã nhân viên" autoComplete="off" required="required" className="App-Input-Login w-100" />
                </div>



                <div className="row mt-4">
                    <div className="col-12 col-lg-12 col-xl-6 text-left">
                        <a href={"#"} onClick={()=>{
                            this.SubmitRegister();
                        }} className={"Btn-App-Login text-white w-100 text-center d-block"}>Đăng ký</a>
                    </div>

                    <div className="col-12 col-lg-12 col-xl-6 text-end d-flex align-items-end flex-row flex-wrap justify-content-center">

                        <a className={"me-2 text-danger"} href={"#"} onClick={(e)=>{
                            this.setState({
                                isAction:2
                            });
                        }}>
                            Quên mật khẩu</a>

                        <a href={"#"} onClick={(e)=>{
                            this.setState({
                                isAction:0
                            });
                        }} className="text-primary">Đăng nhập</a>
                    </div>

                </div>




            </>
        );
    }

    ViewForgot(){
        return(
            <>

                <h2 className={"font-weight-bolder text-center text-danger display5"}>Quên mật khẩu</h2>
                <p className={"text-muted font15 mt-3 text-center"}>Vui lòng nhập đầy đủ thông tin</p>


                <div className={"col-12 p-0 input-group"}>
                    <label>Email <span className={"text-red"}>( * )</span></label>
                    <input type="email" onChange={event => {
                        this.setState({
                            EmailForgot:event.target.value
                        });
                    }} name="email" placeholder="Email" autoComplete="off" required="required" className="App-Input-Login w-100" />
                </div>



                <div className="row mt-4">
                    <div className="col-12 col-lg-12 col-xl-6 text-left">
                        <a href={"#"} onClick={()=>{
                            this.SubmitForgot();
                        }} className={"Btn-App-Login text-white w-100 text-center d-block"}>Gửi</a>
                    </div>

                    <div className="col-12 col-lg-12 col-xl-6 text-end d-flex align-items-end flex-row flex-wrap justify-content-center">

                        <a className={"me-2 text-danger"} href={"#"} onClick={(e)=>{
                            this.setState({
                                isAction:0
                            });
                        }}>
                            Đăng nhập</a>

                        <a href={"#"} onClick={(e)=>{
                            this.setState({
                                isAction:1
                            });
                        }} className="text-primary">Đăng ký</a>
                    </div>

                </div>




            </>
        );
    }

    render() {
        return(
            <div className="col-12 Login-App bg-white">
                <div className={"row Row-Login-App mh-100vh"}>
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 Left-Login d-flex flex-row justify-content-center align-self-center align-items-center flex-wrap"}>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 content-login"}>
                            <div className={"text-center py-3"}>
                                <img className={"Logo-Login"} src={logo} />
                            </div>

                            {this.state.NumberError>5?(
                                <>

                                <h2 className={"font-weight-bolder text-center text-danger display5"}>Đăng nhập</h2>

                                <div className={"alert alert-danger mt-3 text-center"}>
                                    <i className="fas fa-exclamation-triangle me-2" /> Thiết bị của bạn đã bị khóa đăng nhập vui lòng thử lại sau
                                </div>

                                   <div className={"col-12 text-center mt-3 mb-3 font50 text-red"}>
                                       <i className="fas fa-ban"></i>
                                   </div>
                                </>
                            ):(
                                <>
                                    {this.state.isAction===0?(
                                        this.ViewLogin()
                                    ):(
                                        <>
                                            {this.state.isAction===1?(
                                                this.ViewRegister()
                                            ):(
                                                this.ViewForgot()
                                            )}
                                        </>
                                    )}
                                </>
                            )}




                        </div>
                    </div>
                    <div style={{
                        backgroundColor:"#B1DCED",
                        backgroundImage:"url("+require("../assets/images/bg-login.png")+")",
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"bottom center"
                    }} className={"col-12 col-sm-12 d-none d-lg-block col-md-6 col-lg-7 col-xl-8 text-center justify-content-center align-items-center Right-Login"}>

                    </div>

                </div>
            </div>
        );


    }
}
