/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {_isset_array, _isset_string, array_to_select, base64_encode, in_module} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import InfoProfile from "../../global/profile/InfoProfile";
import DetailTimeKeeping from "../../global/timekeeping/DetailTimeKeeping";
import Floater from 'react-floater';
import Select from "react-select";
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
    },
    DataTimeKeeping:{
        status:false,
        data:null,
    },
    ProfileInfo:null,
    page:0,
    ListPosition:null,
    ListGroup:null
};

export default class userclub extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    getPosition(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type', 2);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"position", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                var ListPosition=[
                    {
                        _id:null,
                        name:"Tất cả"
                    },
                    {
                        _id:'unknown',
                        name:"Chưa rõ"
                    }
                ];

                ListPosition=ListPosition.concat(data.data);

                self.setState({
                    ListPosition:ListPosition
                });
            }

        }).
        catch((e)=>{

        });


    }

    getGroup(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='ok'){

                var ListGroup=[{
                    _id:null,
                    name:"Tất cả"
                },
                    {
                        _id:'unknown',
                        name:"Chưa rõ"
                    }
                ];

                ListGroup=ListGroup.concat(data.data);

                self.setState({
                    ListGroup:ListGroup
                });
            }

        }).
        catch((e)=>{

        });


    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"userclub", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getPosition();
        this.getGroup();
    }


    ResetPassword(id){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {

                                self.ActionResetPassWord(id);

                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    async ActionResetPassWord(id){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        Appthis.PageLoad(true);

        var get_status=await fetch(Appthis.ConstApp.home_api+"userclub/resetpassword", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Cập nhật thất bại");
        }
        else{


            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Thông báo !</h1>
                            <p>Mật khẩu đã được cấp lại : {get_status['password']}</p>
                            <button onClick={onClose}>Đồng ý</button>
                        </div>
                    );
                }
            });

        }

        Appthis.PageLoad(false);


    }


    thisRenderData(){

        const self=this;

        var AppThis=this.props.AppThis;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Họ và tên</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Email</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã NV</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Chức vụ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Team</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr key={value._id}>
                                    <td>{value['username']}</td>
                                    <td>{value['email']}</td>
                                    <td>
                                        <span>{value['code_user']}</span>
                                    </td>

                                    <td>
                                        <span>{value['position']!=null?value['position'].name:'Chưa rõ'}</span>
                                    </td>

                                    <td>
                                        {value['group_id']!==null && value['group_id'].length>0 && _isset_array(value['group_id'])?(
                                            value['group_id'].map(function(item){
                                                return(
                                                    <span className={"me-1"}>{item.name} , </span>
                                                );
                                            })
                                        ):(
                                            <span className={"text-red font-weight-bold"}>Chưa rõ</span>
                                        )}

                                    </td>


                                    {value.visible===1?(
                                        <td className={"fix-item-table"}>
                                            <a onClick={()=>{
                                                self.ResetPassword(value['_id'])
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật mật khẩu">
                                                <i className="fas fa-unlock-alt" />
                                                </Floater>
                                            </a>


                                            <a onClick={()=>{

                                                self.setState({
                                                    ProfileInfo:value
                                                });
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-secondary p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Thông tin tài khoản">
                                                <i className="far fa-user" />
                                                </Floater>
                                            </a>


                                            {in_module('clubtimekeeping',AppThis.GetInfoProfile().module)===true?(
                                                <a onClick={()=>{
                                                    self.setState({
                                                        DataTimeKeeping:{
                                                            status:true,
                                                            data:value
                                                        }
                                                    });

                                                }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-secondary p-1 ps-2 pe-2">
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết chấm công">
                                                    <i className="far fa-calendar-alt" />
                                                    </Floater>
                                                </a>
                                            ):(<></>)}




                                        </td>
                                    ):(
                                        <td className={"fix-item-table"}>

                                            <a onClick={()=>{

                                                self.setState({
                                                    ProfileInfo:value
                                                });
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-secondary p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Thông tin tài khoản">
                                                    <i className="far fa-user" />
                                                </Floater>
                                            </a>

                                        </td>
                                    )}


                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>

                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Tài khoản nhân viên</h3>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>




                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>

                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>

                                        <input onChange={(event)=>{
                                            this.UpdateQuerySearch("input_search",event.target.value);

                                        }} autoComplete={"off"} type="text"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Từ khóa tìm kiếm"/>


                                        <div className={"Box-Select-Mutiple w200px text-start"}>
                                            {this.state.ListPosition!==null?(

                                                <Select isSearchable={true} placeholder={"Chức vụ"} onChange={(selectedOption)=>{
                                                    var query=self.state.query;
                                                    query['position']=selectedOption['value'];

                                                    self.setState({
                                                        query:query
                                                    });

                                                }} options={array_to_select(this.state.ListPosition,'name','_id')} />
                                            ):(
                                                <ItemLoading />
                                            )}
                                        </div>


                                        {this.state.ListGroup!==null?(
                                            <div className={"Box-Select-Mutiple w200px text-start"}>

                                                <Select isSearchable={true} placeholder={"Team"} onChange={(selectedOption)=>{
                                                    var query=self.state.query;
                                                    query['group_id']=selectedOption['value'];
                                                    self.setState({
                                                        query:query
                                                    });

                                                }} options={array_to_select(this.state.ListGroup,'name','_id')} />

                                            </div>

                                        ):(
                                            <></>
                                        )}


                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>


                                    </div>

                                </div>





                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>



                    </div>


                    {this.state.ProfileInfo!==null?(
                        <InfoProfile onClose={()=>{
                            this.setState({
                                ProfileInfo:null
                            });
                        }} data={this.state.ProfileInfo} />
                    ):(<></>)}




                    {this.state.DataTimeKeeping.status===true?(
                        <DetailTimeKeeping onClose={()=>{
                            this.setState({
                                DataTimeKeeping:{
                                    status:false,
                                    data:null,
                                }
                            });
                        }} AppThis={this.props.AppThis} path={"clubtimekeeping"} data={this.state.DataTimeKeeping.data}  />
                    ):(<></>)}


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

}
