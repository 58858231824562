/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    formatNumber,
    base64_encode, getItemInArray, array_to_select, _isset_string
} from "../../helper/array";
import ConstApp from "../../ConstApp";
import {toast} from "react-toastify";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import Select from "react-select";
import lodash from "lodash";
const hiddenFileInput=React.createRef();

const INIT_STATE={
    data:null,
    user_login:null,
    InfoUser:null,
    SaveUser:{},
    paged:1,
    InfoFile:null,
    DataTransfer:{
        note:null,
        id_user:null,
        protector:null,
        contact_person:null,
        id_payment:null
    },
    PriceTransfer:null,
    Protection:{
        _id:null,
        user_login:null,
        relationship:null,
        sex:0,
        username:null
    },
    Relationship:null,
    PaymentMethod:null,
    InfoPayment:null,
    TmpUser:null
};
export default class Transfer extends Component{
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    componentDidMount() {

        if(this.props.data!==null && typeof this.props.data!=='undefined'){

            this.setState({
                data:this.props.data
            });

        }

        this.getPriceTransfer();

        this.GetRelationship();

        this.getPaymentMethod();

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getPaymentMethod(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"getpayment", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{


            if(data.status==='ok'){
                self.setState({
                    PaymentMethod:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    GetRelationship(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"gtypegroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            var Relationship=[];

            if(data.status==='ok'){
                Relationship=data.data;
            }

            self.setState({
                Relationship:Relationship
            });


        }).catch((e)=>{

        });
    }

    getPriceTransfer(){

        var Appthis=this.props.AppThis;

        const self=this;

        const formData = new FormData();
         formData.append('token', Appthis.getToken());
         formData.append('name', 'price_transfer');
         fetch(ConstApp.home_api+"options", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data['status']==='ok'){
                self.setState({
                    PriceTransfer:parseInt(data.data)
                });
            }
        });
    }

    async UpdateProtection(input){

        if(input.length===10){

            if(input!==this.state.data.id_user){

                var Protection=this.state.Protection!==null?this.state.Protection:{};


                var Appthis=this.props.AppThis;

                Appthis.PageLoad(true);

                var get_info=await this.fetchUser(input);

                if(get_info.status==='ok' && Object.keys(get_info.data).length>0){

                     Protection=get_info.data;

                    Protection['relationship']=null;

                    this.setState({
                        Protection:lodash.cloneDeep(Protection),
                        TmpUser:lodash.cloneDeep(Protection)
                    });

                }
                else{

                    this.setState({
                        Protection:{
                            _id:null,
                            relationship:null,
                            user_login:input,
                            sex:0
                        },
                        TmpUser:null
                    });

                }

                if(this.state.Protection._id!==null){

                    var getRelationship=await this.getRelationshipUser(this.state.Protection._id);

                    if(getRelationship['status']==='ok' && Object.keys(getRelationship['data']).length>0){

                        var GetProtection=this.state.Protection;
                        GetProtection['relationship']=getRelationship['data'];
                        this.setState({
                            Protection:GetProtection
                        });

                    }

                }

                Appthis.PageLoad(false);

            }
            else{
                toast("Vui lòng kiểm tra lại");
            }

        }

    }

    async getRelationshipUser(id){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        var users=[id,this.state.DataTransfer.id_user];

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('users', base64_encode(users));

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/getrelationship", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        var data=await data.json();

        return data;

    }

    async VerifyPhone(){

        var get_phone=this.state.user_login;

        if(get_phone===null){
            toast("Vui lòng nhập số điện thoại");
            return;
        }

        get_phone=get_phone.replaceAll("_",'');

        if(get_phone.length !== 10){
            toast("Vui lòng nhập số điện thoại");
        }
        else{

            var data=await this.fetchUser(get_phone,true);

            if(data['status']==='ok'){

                var get_data=data.data;

                var SaveUser={
                    username:null,
                    birthday:null,
                    user_login:get_phone,
                    sex:0,
                    email:null,
                    citizen_identification:null,
                    address_contact:null
                };


                if(Object.keys(get_data).length>0){

                    var beasts = ['_id','username', 'birthday', 'user_login', 'sex', 'email','citizen_identification','address_contact'];

                    Object.entries(get_data).map(function(item){

                        var [key,value]=item;

                        if(beasts.indexOf(key)!==-1){
                            SaveUser[key]=value;
                        }

                    });

                    this.setState({
                        InfoUser:lodash.cloneDeep(get_data),
                        SaveUser:SaveUser,
                        paged:2,
                        InfoFile:null
                    });

                }
                else{
                    this.setState({
                        InfoUser:lodash.cloneDeep(SaveUser),
                        SaveUser:SaveUser,
                        paged:2,
                        InfoFile:null
                    });
                }

            }
            else{
                toast("Có lỗi trong quá trình xử lý.Vui lòng tải lại trang");
            }

        }

    }

    async fetchUser(phone,loading=false){

        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }
        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/infouser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });
        var data=await data.json();
        if(loading===true){
            Appthis.PageLoad(false);
        }
        return data;
    }

    async SubmitSaveUser(){

        var SaveUser=this.state.SaveUser;

        var number_error=0;

        if(SaveUser===null || Object.keys(SaveUser).length===0){
            number_error+=1;
        }
        else{

            var bet_info = ['username', 'user_login','citizen_identification','address_contact'];

            bet_info.map(function (item,index){

                if(SaveUser[item]===null || typeof SaveUser[item]==='undefined' || SaveUser[item].length<=1){
                    number_error+=1;
                }

            });


        }

        if(number_error>0){

            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var data_save=base64_encode(SaveUser);

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();
            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('data',data_save);

            var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updateuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });
            var data=await data.json();

            if(data['status']==='ok'){

                var DataTransfer=this.state.DataTransfer;

                DataTransfer['id_user']=data['data'];

                this.setState({
                    paged:3,
                    InfoFile:null,
                    DataTransfer:DataTransfer
                });
            }
            else{
                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");
            }


             Appthis.PageLoad(false);

        }




    }

    UploadAvatarNow(event){

        var fileUploaded = event.target.files[0];

        const self=this;

        if((fileUploaded.size/1024)<3000){

            if(fileUploaded.type==='image/png' || fileUploaded.type==='image/jpg' || fileUploaded.type==='image/jpeg'){
                let reader = new FileReader();

                reader.readAsDataURL(fileUploaded);

                var SaveUser=this.state.SaveUser;

                reader.onload = (e) => {
                    SaveUser['avatar']= e.target.result;
                    self.setState({
                        SaveUser: SaveUser,
                        InfoFile:fileUploaded
                    });
                }
            }
            else{
                toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
            }


        }
        else{
            toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
        }



    }

    UpdateUser(key,value){

        var SaveUser=this.state.SaveUser;

        if(key==='user_login'){
            value=value.replaceAll("_",'');
        }

        SaveUser[key]=value;

        this.setState({
            SaveUser:SaveUser
        });

    }

    async SubmitTransfer(){

        var DataTransfer=this.state.DataTransfer;

        var number_error=0;

        if(DataTransfer['id_user']===null ||  DataTransfer['id_payment']===null){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng kiểm tra lại");
        }
        else{

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            var data_save=base64_encode(DataTransfer);

            const formData = new FormData();
            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('id_member', this.state.data._id);
            formData.append('data',data_save);

            var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/transfer", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });
             data=await data.json();

            if(data['status']==='ok'){
                toast("Phiếu thu đã được gửi.Vui lòng chờ xác nhận");
            }
            else{
                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");
            }

            Appthis.PageLoad(false);

            this.props.onClose();

        }
    }

    async VerifyProtectorCoSigner(){

        const DataSubmit={
            protection:{},
        };

        var Protection=this.state.Protection;

        var number_error=0;

         ['username','user_login','relationship'].map(function(val){

                if(Protection[val]===null || typeof Protection[val]==='undefined' || Protection[val].length<3){
                    number_error+=1;
                }
                else{

                    if(val==='relationship'){
                        DataSubmit['protection'][val]=Protection[val]['_id'];
                    }
                    else{
                        DataSubmit['protection'][val]=Protection[val];
                    }

                }
            });

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

          DataSubmit['protection']['sex']=Protection['sex'];

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(DataSubmit));

            formData.append('id_user',this.state.DataTransfer.id_user);

            const self=this;

            fetch(Appthis.ConstApp.home_api+this.props.path+"/setrelationship", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    if(Object.keys(data.data).length>0){

                        var get_data=data.data;

                        var DataTransfer=self.state.DataTransfer;

                        if(typeof get_data['protection']!=='undefined'){
                            DataTransfer['protector']=get_data['protection'];
                        }

                        self.setState({
                            DataTransfer:DataTransfer,
                            paged:4,
                            InfoFile:null
                        });

                    }

                }
                else{
                    toast("Thao tác không thành công.Vui lòng thử lại");
                }

                Appthis.PageLoad(false);

            }).catch((e)=>{
                Appthis.PageLoad(false);
            });


        }
    }

    HeaderContract(){

        const data=this.state.data;

        return(
            <>
                <div className={"col-12 mt-3 mb-5 wizard-template d-none d-lg-block"}>
                    <ul className="m-0 p-0 position-relative nav nav-pills nav-fill">
                        <li className="nav-item">
                            <span className="font-weight-bold text-uppercase d-block w-100 font16">Bước 1</span>
                            <i className="position-relative font26 mt-3 mb-3 bg-primary m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fab fa-ups" />
                            <div className="col-12 p-0">
                                <span className="description-wizard"> Nhập SDT </span>
                            </div>
                        </li>
                        <li className="nav-item">
                            <span className="font-weight-bold text-uppercase d-block w-100 font16">Bước 2</span>
                            <i className="position-relative font26 mt-3 mb-3 bg-danger m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-user-check" />
                            <div className="col-12 p-0">
                                <span className="description-wizard"> Thông tin khách hàng </span>
                            </div>
                        </li>

                        <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 3</span>
                            <i className="position-relative m-auto mt-3 mb-3 font26 bg-warning rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-info" />
                            <div className="col-12 p-0">
                                <span className="description-wizard"> Người liên hệ</span>
                            </div>
                        </li>
                        <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 4</span>
                            <i className="position-relative m-auto mt-3 mb-3 font26 bg-info rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard far fa-check-circle" />
                            <div className="col-12 p-0">
                                <span className="description-wizard">
                                    Phương thức thanh toán
                                </span>
                            </div>
                        </li>

                    </ul>
                </div>



                <div className={"col-12 mt-4"}>

                    <div className={"alert alert-warning w-100 rounded-0 align-items-center"}>
                        <i className="far fa-money-bill-alt me-1" /> Bạn đang thực hiện chuyển nhượng HD cho khách hàng.
                        Lệ phí chuyển nhượng :
                        {this.state.PriceTransfer!==null?(
                            <span className={"ms-1 font-weight-bold text-uppercase font18"}>{formatNumber(this.state.PriceTransfer)} VND</span>
                        ):(
                           <div className={"ms-1 d-inline-block overflow-hidden"}> <ItemLoading /></div>
                        )}
                    </div>

                </div>

            </>
        );
    }

    InfoContract(){

        const AppThis=this.props.AppThis;

        const self=this;

        var profile=self.props.AppThis.GetInfoProfile();

        if(this.state.paged===1){
            return(
                <div className={"row m-0 mb-5"}>

                    {this.HeaderContract()}

                    <div className={"col-12 mt-3 mb-3"}>
                        <p className={"text-uppercase font-weight-bold"}>
                        <span className={"font18 pb-2 border-bottom border-danger"}>
                           <i className="fas fa-mobile-alt me-1" />  SDT/ID khách hàng
                        </span>

                        </p>
                    </div>

                    <div className={"col-12 col-sm-12 col-md-4 offset-md-4 InputMask"}>

                        <InputMask mask="9999999999" onChange={event=>{
                            this.setState({
                                user_login:event.target.value
                            });
                        }} placeholder={"Số điện thoại"} />

                        <a onClick={()=>{
                            this.VerifyPhone();
                        }} className={"btn btn-primary rounded-0 w-100 mt-3 p-3 d-flex align-items-center"}>
                            Tiếp theo <i className="fas fa-caret-right ms-2" />
                        </a>

                    </div>

                </div>
            );
        }
        else if(this.state.paged===2){
            return(
                <div className={"col-12 pb-5"}>
                    <div className={"row Edit-Profile"}>


                        {this.HeaderContract()}


                        <div className={"col-12 mt-3 mb-3"}>
                            <p className={"text-uppercase font-weight-bold"}>
                        <span className={"font18 pb-2 border-bottom border-danger"}>
                           <i className="fas fa-mobile-alt me-1" />  Thông tin khách hàng
                        </span>

                            </p>
                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Họ và tên <span className="text-red">( * )</span></label>

                            {_isset_string(this.state.InfoUser.username) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                    <input type="text"  key={'username'} defaultValue={this.state.InfoUser.username?this.state.InfoUser.username:this.state.InfoUser.tmp_user} placeholder="Họ và tên" required="required" className="form-control w-100" />
                                </div>
                            ):(
                                <input type="text" onChange={(event)=>{
                                    this.UpdateUser('username',event.target.value);
                                }} key={'username'} defaultValue={this.state.InfoUser.username?this.state.InfoUser.username:this.state.InfoUser.tmp_user} placeholder="Họ và tên" required="required" className="form-control w-100" />
                            )}

                        </div>



                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Email</label>

                            {_isset_string(this.state.InfoUser.email) && profile.type!==1?(
                               <div className={"is-verify-data"}>
                                   <input type="email" key={'email_modal'} defaultValue={this.state.InfoUser.email} placeholder="Email" required="required" className="form-control w-100" />
                               </div>
                            ):(
                                <input type="email" onChange={(event)=>{
                                    this.UpdateUser('email',event.target.value);
                                }} key={'email_modal'} defaultValue={this.state.InfoUser.email} placeholder="Email" required="required" className="form-control w-100" />
                            )}
                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Sinh nhật</label>
                                    <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} onChange={(date) => {
                                        this.UpdateUser('birthday',date.getTime()/1000);
                                    }}  dateFormat="dd/MM/yyyy" selected={this.state.SaveUser.birthday?new Date(this.state.SaveUser.birthday*1000):new Date()} />
                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                            <label className="font-weight-400 mb-1">Giới tính <span className="text-red">( * )</span></label>

                            {typeof this.state.SaveUser.sex!=='undefined' && this.state.SaveUser.sex===1?(
                                <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateUser("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" checked="checked"  /> Nam</span>
                                    <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateUser("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" /> Nữ</span>
                                </div>
                            ):(
                                <></>
                            )}

                            {typeof this.state.SaveUser.sex!=='undefined' && this.state.SaveUser.sex===0?(
                                <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateUser("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" /> Nam</span>
                                    <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateUser("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" /> Nữ</span>
                                </div>
                            ):(
                                <></>
                            )}

                            {typeof this.state.SaveUser.sex==='undefined'?(
                                <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateUser("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" /> Nam</span>
                                    <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateUser("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" /> Nữ</span>
                                </div>
                            ):(
                                <></>
                            )}


                        </div>







                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">Địa chỉ liên hệ
                                <span className={"text-red"}>( * )</span>
                            </label>

                            {_isset_string(this.state.InfoUser.address_contact) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                    <input type="text" key={'address_contact_modal'} defaultValue={this.state.InfoUser.address_contact} placeholder="Địa chỉ liên hệ" required="required" className="form-control w-100" />
                                </div>
                            ):(
                                <input type="text" onChange={(event)=>{
                                    this.UpdateUser('address_contact',event.target.value);
                                }} key={'address_contact_modal'} defaultValue={this.state.InfoUser.address_contact} placeholder="Địa chỉ liên hệ" required="required" className="form-control w-100" />
                            )}

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="font-weight-400 mb-1">CMTND/CCCD
                                <span className={"text-red"}>( * )</span>
                            </label>
                            {_isset_string(this.state.InfoUser.citizen_identification) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                    <input type="text"  key={'citizen_identification_modal'} defaultValue={this.state.InfoUser.citizen_identification} placeholder="CNND/CCCD" required="required" className="form-control w-100" />
                                </div>
                            ):(
                                <input type="text" onChange={(event)=>{
                                    this.UpdateUser('citizen_identification',event.target.value);
                                }} key={'citizen_identification_modal'} defaultValue={this.state.InfoUser.citizen_identification} placeholder="CNND/CCCD" required="required" className="form-control w-100" />
                            )}

                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                            <label className="d-block font-weight-400 mb-2">Ảnh đại diện</label>

                            <div className={"row"}>
                                <div className={"col-6"}>

                                    {this.state.InfoUser.avatar!==null && typeof this.state.InfoUser.avatar!=='undefined'?(
                                        <div className={"box-avatar-100 d-flex justify-content-center align-self-center align-items-center"}>
                                            <img src={AppThis.ConstApp.home_url+this.state.InfoUser.avatar} />
                                        </div>
                                    ):(

                                            <div className={"box-avatar-100 d-flex justify-content-center align-self-center align-items-center"}>
                                                <img src={AppThis.ConstApp.home_url+'/assets/images/004-boy-1.svg'} />
                                            </div>
                                    )}

                                </div>

                                <div className={"col-6 position-relative d-flex flex-row align-items-center flex-wrap"}>

                                    <a onClick={()=>{
                                        hiddenFileInput.current.click();
                                    }} className={"w-100 upload-images-avatar rounded text-red text-dark border p-3 d-flex flex-row justify-content-center"}>
                                        <i className="fas fa-camera-retro" />
                                       <span className={"font12 ms-1"}> Tải lên tệp tin</span>
                                        <input onChange={(event)=>{
                                            this.UploadAvatarNow(event);
                                        }} key={"avatar_user"} ref={hiddenFileInput} accept="image/*"  type={"file"} className={"d-none"} />
                                    </a>


                                    {this.state.InfoFile!==null?(
                                           <p className={"font12 mt-1 mb-1"}>
                                               {this.state.InfoFile.name}
                                           </p>
                                    ):(<></>)}

                                </div>


                            </div>


                        </div>



                        <div className={"col-12 col-sm-12 col-md-4 offset-md-8 d-flex flex-row justify-content-end"}>
                            <a onClick={()=>{
                                this.SubmitSaveUser();
                            }} className={"btn btn-primary rounded-0 w-100 p-3"}>
                                <i className="fas fa-save me-1" /> Tiếp theo
                            </a>
                        </div>



                    </div>
                </div>
            );
        }
        else if(this.state.paged===3){
            return(
                <div className={"col-12 mb-5"}>

                    <div className={"row"}>

                        {this.HeaderContract()}


                        <div className={"col-12 mt-3 mb-3"}>
                            <p className={"text-uppercase font-weight-bold"}>
                        <span className={"font18 pb-2 border-bottom border-danger"}>
                         <i className="fas fa-tag me-1" /> Người liên lạc khẩn cấp
                        </span>

                            </p>
                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                            <div className={"row"}>
                                <div className={"col-12 col-sm-6 col-md-6 mb-3 box_input"}>

                                    <p className={"m-0 mb-1"}>SDT/ID : <span className={"text-red"}>( * )</span></p>

                                    <InputMask key={"protection_user"} mask="9999999999" onChange={event=>{
                                        var input=event.target.value;
                                         input=input.replaceAll("_","");
                                        self.UpdateProtection(input);
                                    }} placeholder={"Số điện thoại"} />

                                </div>
                                <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                    <p className={"m-0 mb-1"}>Họ và tên : <span className={"text-red"}>( * )</span></p>


                                    {self.state.TmpUser!==null && _isset_string(self.state.TmpUser.username) && profile.type!==1?(
                                        <div className={"is-verify-data"}>
                                            <input key={"username_protector"} type={"text"}  defaultValue={self.state.Protection?self.state.Protection.username:''} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                                        </div>
                                    ):(

                                        <input key={"username_protector"} type={"text"} onChange={(text)=>{
                                            var Protection=self.state.Protection;
                                            Protection.username=text.target.value;
                                            this.setState({
                                                Protection:Protection
                                            });
                                        }} defaultValue={self.state.TmpUser?self.state.TmpUser.username:''} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />

                                    )}
                                </div>


                                <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                    <p className={"m-0"}>Địa chỉ : </p>

                                    {self.state.TmpUser!==null && _isset_string(self.state.TmpUser.address_contact) && profile.type!==1?(
                                        <div className={"is-verify-data"}>
                                            <input key={"protector_address_contact"} type={"text"} defaultValue={self.state.Protection?self.state.Protection.address_contact:''} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />

                                        </div>
                                    ):(
                                        <input key={"protector_address_contact"} type={"text"} onChange={(text)=>{
                                            var Protection=self.state.Protection;
                                            Protection.address_contact=text.target.value;
                                            this.setState({
                                                Protection:Protection
                                            });
                                        }} defaultValue={self.state.TmpUser?self.state.TmpUser.address_contact:''} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                                    )}

                                </div>


                                <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                    <p className={"m-0"}>Số căn cước : </p>
                                    {self.state.TmpUser!==null && _isset_string(self.state.TmpUser.citizen_identification) && profile.type!==1?(
                                        <div className={"is-verify-data"}>
                                            <input key={"protector_citizen_identification"} type={"text"} defaultValue={self.state.Protection?self.state.Protection.citizen_identification:''} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />
                                        </div>
                                    ):(
                                        <input key={"protector_citizen_identification"} type={"text"} onChange={(text)=>{
                                            var Protection=self.state.Protection;
                                            Protection.citizen_identification=text.target.value;
                                            this.setState({
                                                Protection:Protection
                                            });
                                        }} defaultValue={self.state.TmpUser?self.state.TmpUser.citizen_identification:''} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />

                                    )}


                                </div>


                                <div className={"col-12 col-sm-6 col-md-6 mb-3"}>
                                    <p className={"m-0 mb-1"}>Giới tính</p>
                                    {this.state.TmpUser!==null && this.state.TmpUser.sex===1?(
                                        <div className={"d-flex flex-row flex-wrap"}>
                                            <label className={"me-2"}><input name={"protector_sex"} onChange={()=>{
                                                var Protection=self.state.Protection;
                                                Protection.sex=1;
                                                this.setState({
                                                    Protection:Protection
                                                });
                                            }} checked={true} type={"radio"} /> Nam</label>
                                            <label><input name={"protector_sex"} onChange={()=>{
                                                var Protection=self.state.Protection;
                                                Protection.sex=0;
                                                this.setState({
                                                    Protection:Protection
                                                });
                                            }} type={"radio"} /> Nữ</label>
                                        </div>
                                    ):(
                                        <div className={"d-flex flex-row flex-wrap"}>
                                            <label className={"me-2"}><input name={"protector_sex"} onChange={()=>{

                                                var Protection=self.state.Protection;
                                                Protection.sex=1;
                                                this.setState({
                                                    Protection:Protection
                                                });


                                            }} type={"radio"} /> Nam</label>
                                            <label><input name={"protector_sex"} onChange={()=>{
                                                var Protection=self.state.Protection;
                                                Protection.sex=0;
                                                this.setState({
                                                    Protection:Protection
                                                });

                                            }} type={"radio"} checked={true} /> Nữ</label>
                                        </div>
                                    )}

                                </div>


                                <div className={"col-12 col-sm-6 col-md-6 mb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Mối quan hệ <span className={"text-red"}>( * )</span></p>
                                    {this.state.Relationship!==null?(
                                        Object.keys(self.state.Relationship).length>0?(
                                            <select onChange={(event)=>{

                                                var Protection=this.state.Protection;

                                                Protection.relationship={
                                                    _id:event.target.value,
                                                    name:event.target.innerText
                                                };

                                                this.setState({
                                                    Protection:Protection
                                                });


                                            }} className={"form-control font14 w-100 rounded-0"}>
                                                <option value={null}>Vui lòng chọn</option>

                                                {self.state.Relationship.map(function(value,key){
                                                    if(self.state.Protection.relationship!==null){
                                                        if(typeof self.state.Protection.relationship['_id']!=='undefined' && self.state.Protection.relationship['_id']===value['_id']){
                                                            return(
                                                                <option selected={true} value={value['_id']}>{value['name']}</option>
                                                            );
                                                        }
                                                        else{
                                                            return(
                                                                <option value={value['_id']}>{value['name']}</option>
                                                            );
                                                        }
                                                    }
                                                    else{
                                                        return(
                                                            <option value={value['_id']}>{value['name']}</option>
                                                        );
                                                    }


                                                })}

                                            </select>

                                        ):(
                                            <></>
                                        )
                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>
                            </div>

                       </div>


                        <div className={"col-12 col-sm-12 col-md-6 pb-3 align-items-end d-flex flex-row"}>

                            <div className={"col-12 col-md-6 offset-md-6 mt-4"}>
                                <a onClick={()=>{
                                    this.VerifyProtectorCoSigner();
                                }} className={"btn btn-outline-primary rounded-0 p-3 w-100"}>
                                    <i className="fas fa-caret-right me-1" /> Tiếp theo
                                </a>
                            </div>



                        </div>




                    </div>

                </div>
            );
        }
        else{

            return(
                <div className={"col-12 mb-5"}>
                    <div className={"row"}>

                        {this.HeaderContract()}

                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                            <label className="font-weight-400 mb-2">Phương thức thanh toán
                            <span className={"text-red"}>( * )</span>
                            </label>

                            {this.state.PaymentMethod!==null?(
                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){

                                        var DataTransfer=self.state.DataTransfer;

                                        DataTransfer['id_payment']=selectedOption.value;
                                        DataTransfer['id_bank']=null;

                                        var InfoPayment=getItemInArray(selectedOption.value,"_id",self.state.PaymentMethod);

                                        self.setState({
                                            DataTransfer:DataTransfer,
                                            InfoPayment:InfoPayment,
                                        });

                                    }
                                    else {

                                        var DataTransfer=self.state.DataTransfer;

                                        DataTransfer['id_payment']=null;
                                        DataTransfer['id_bank']=null;

                                        self.setState({
                                            InfoPayment:null,
                                            DataTransfer:DataTransfer
                                        });
                                    }

                                }} options={array_to_select(this.state.PaymentMethod,'name','_id')} />
                            ):(
                                <ItemLoading />
                            )}



                            {this.state.InfoPayment!==null && this.state.InfoPayment.bank.length>0?(
                                <div className={"row m-0 mt-4 mb-3"}>
                                    {this.state.InfoPayment.bank.map(function(item,index){
                                        return(
                                            <div className={"col-12 col-sm-12 col-md-6 col-lg-6 mb-3"}>

                                                <div onClick={()=>{
                                                    var DataTransfer=self.state.DataTransfer;
                                                    DataTransfer['id_bank']=item._id;

                                                    self.setState({
                                                        DataTransfer:DataTransfer
                                                    });

                                                }} className={self.state.DataTransfer.id_bank===item._id?("col-12 border position-relative active_tab_box"):("col-12 border position-relative")}>

                                                    <div className={"col-12 p-2 font-weight-bold border-bottom text-center font15 text-uppercase left-box-info-bank"}>
                                                        <p className={"m-0"}><i
                                                            className="fab fa-cc-amazon-pay" /> {item.name}</p>
                                                    </div>

                                                    <div className={"col-12 p-3 right-box-info-bank"}>

                                                        <p className={"m-0"}>
                                                            <i
                                                                className="fas fa-user"/> Chủ tài khoản :
                                                            {item.user_account}
                                                        </p>
                                                        <p className={"m-0"}>
                                                            <i
                                                                className="fab fa-cc-paypal" /> Số Tài khoản :
                                                            {item.account}
                                                        </p>

                                                    </div>

                                                </div>


                                            </div>
                                        );
                                    })}
                                </div>
                            ):(<></>)}



                        </div>



                        <div className={"col-12 col-sm-12 col-md-6 pb-3 d-flex flex-wrap flex-row align-items-end"}>

                            <div className={"col-12"}>
                                <label className="font-weight-400 mb-1">Ghi chú</label>

                                <input type="text" onChange={(event)=>{
                                    var DataTransfer=this.state.DataTransfer;
                                    DataTransfer.note=event.target.value;
                                    this.setState({
                                        DataTransfer:DataTransfer
                                    });
                                }} placeholder="Ghi chú" className="form-control w-100" />


                                <div className={"col-12 col-md-4 offset-md-8 mt-4"}>
                                    <a onClick={()=>{
                                        this.SubmitTransfer();
                                    }} className={"btn btn-outline-primary rounded-0 w-100 p-3"}>
                                        <i className="far fa-save me-2" />  Gửi phiếu thu
                                    </a>


                                </div>

                            </div>

                        </div>






                    </div>
                </div>
            );
        }

    }

    RenderTransfer(){
        return(
            <div className={"row mt-4 mb-4"}>
                {this.InfoContract()}
            </div>
        );
    }

    render(){
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg modal-fullscreen modal-dialog-scrollable"}>
                    <div className="modal-content bg-light border-0">
                        <div className="modal-header shadow">

                            <div className={"container"}>

                                <div className={"row"}>
                                    <div className={"col-8"}>
                                        <strong className={"font18 text-white text-black text-uppercase"}>Chuyển nhượng HD</strong>
                                    </div>

                                    <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.onClose();
                                    }} className={"text-white right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                    </div>

                                </div>
                            </div>


                        </div>


                        <div className="modal-body">

                            <div className={"container bg-white"}>
                                {this.state.data!==null?(
                                    this.RenderTransfer()
                                ):(
                                    <div className={"col-12 pt-4 pb-4 d-flex flex-row justify-content-center align-items-center"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
