/* eslint-disable */
import React,{Component} from "react";
import {_isset_array, formatNumber, timeConverter, timeConverterDate} from "../../helper/array";
import ItemLoading from "../ItemLoading";
import {get_name_type_card} from "../../helper/member";
export default class InfoCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
        };

    }

    componentDidMount() {

        if(typeof this.props.data!=='undefined'){

            this.setState({
                data:this.props.data
            });

        }
        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{

        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    render(){

        var data=this.state.data;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-info-discount">
                            <strong className={"font18 text-uppercase"}>Chi tiết thẻ tập</strong>
                        </div>

                        {data!==null?(
                            <>
                                <div className="modal-body">

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1 font-weight-bold">
                                                <i className="fas fa-info-circle me-1" />
                                                Tên thẻ :
                                            </label>
                                            <span className={"m-0 font12 ms-3 font-weight-bold badge bg-primary rounded-0"}>{data.name}</span>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fab fa-playstation me-1" />  Loại thẻ tập :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{get_name_type_card(data.type)}</span>
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-money-bill-alt me-1" />
                                                Giá niêm yết :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{formatNumber(data.price)} VND / 1 Buổi</span>
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-money-check-alt me-1" />
                                                Giá min :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{formatNumber(data.min_price)} VND / 1 Buổi</span>
                                        </div>



                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fab fa-servicestack me-1" />
                                                Dịch vụ :
                                            </label>

                                            {data.id_service && _isset_array(data.id_service)?(
                                                <>
                                                    {data.id_service.map(function(item){
                                                        return(
                                                            <span className={"m-1 badge bg-danger"}>
                                                                {item.name}
                                                            </span>
                                                        );
                                                    })}
                                                </>
                                            ):(
                                                <span className={"m-0 badge bg-warning"}>không có</span>
                                            )}

                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-tags me-1" />
                                                Sản phẩm :
                                            </label>

                                            {data.id_product && _isset_array(data.id_product)?(
                                                <>
                                                    {data.id_product.map(function(item){
                                                        return(
                                                            <span className={"m-1 badge bg-primary"}>
                                                                {item.name}
                                                            </span>
                                                        );
                                                    })}
                                                </>
                                            ):(
                                                <span className={"m-0 badge bg-warning"}>không có</span>
                                            )}

                                        </div>




                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-bell me-1" />
                                                Ghi chú thẻ :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{data.note}</span>
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-list-ol me-1" />
                                                Số người đi kèm cố định :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{data.number_people}</span>
                                        </div>



                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="far fa-minus-square me-1" />

                                                {data.type===3 || data.type===4?(
                                                    <>Số tháng tối thiểu</>
                                                ):(
                                                    <>Số buổi tập hoặc số ngày tập tối thiểu</>
                                                )}


                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{data.min_session}</span>
                                        </div>



                                        {data.type===3 || data.type===4?(
                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="far fa-clock me-1" />
                                                    Số buổi tập trong tháng
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>{data.number_day_in_month}</span>
                                            </div>
                                        ):(
                                            <></>
                                        )}


                                        {data.type===3 || data.type===4?(
                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="far fa-clock me-1" />
                                                    Thẻ được phép chuyển đổi sang nhóm cố định
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>{data.is_convert_to_statics_group===2?"Có":"Không"}</span>
                                            </div>
                                        ):(
                                            <></>
                                        )}

                                        {data.type===1?(
                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="far fa-clock me-1" />
                                                    Hệ số nhân buổi tập
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>{data.multiplier?data.multiplier:"___"}</span>
                                            </div>
                                        ):(
                                            <></>
                                        )}

                                    </div>

                                </div>

                                <div className={"modal-footer bg-light"}>

                                    <div className={"w-100 row"}>

                                        <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                            <div className={"row"}>

                                                <div className={"col-12 col-sm-12 col-lg-6"}>

                                                </div>
                                                <div className={"col-12 col-sm-12 col-lg-6"}>
                                                    <a onClick={()=>{
                                                        if(typeof this.props.onClose!=='undefined'){
                                                            this.props.onClose()
                                                        };

                                                    }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                        Đóng
                                                    </a>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </>
                        ):(
                            <div className={"col-12 d-flex flex-row justify-content-center align-items-center mt-3 mb-3"}>
                                <ItemLoading />
                            </div>
                        )}

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }


}
