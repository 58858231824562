/* eslint-disable */
import React,{Component} from "react";
import {formatNumber, getItemInArray, timeConverter, timeConverterDate} from "../../helper/array";
import ConstApp from "../../ConstApp";
import no_avatar from "../../assets/images/_no_avatar.png";
import {status_contract} from "../../helper/member";
export default class DetailOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null
        };
    }

    componentDidMount() {

        if(this.props.data){
            this.setState({
                data:this.props.data
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    render(){
        if (this.state.data!==null){
            var data=this.state.data;
            return (
                <>
                    <div className="modal d-block position-fixed modal-dialog-end">
                        <div className={"modal-dialog modal-lg modal-dialog-scrollable container-fluid"}>
                            <div className="modal-content shadow border-0 rounded-0">

                                <div className="modal-header active_tab rounded-0">
                                    <strong className={"font16 text-uppercase text-white"}>
                                        <i className="far fa-user me-2" />
                                        Chi tiết đơn hàng
                                    </strong>
                                </div>
                                <div className="modal-body">

                                    <div className={"row mb-3"}>

                                        <div className={"col-12 col-sm-12 col-md-6 pt-3"}>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 text-end mt-3"}>
                                            Mã đơn hàng : <span className={"badge bg-danger rounded-0 font10"}>{data.id_order}</span>
                                            <i className="far fa-clock ms-2" />
                                            <span className={"ms-2"}> {timeConverter(data.time_create)}</span>
                                        </div>

                                    </div>


                                    <div className={"row mb-2 mt-2"}>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-3"}>

                                            <div className={"col-12 mb-4 text-center"}>


                                                <div className={"position-relative d-inline-block"}>
                                                    {data.id_user.avatar!==null ?(
                                                        <div className={"box-avatar-150 m-auto"}>
                                                            <img src={ConstApp.home_url +"/" +data.id_user.avatar} />
                                                        </div>

                                                    ):(
                                                        <div className={"box-avatar-150 m-auto"}>
                                                            <img  src={no_avatar} />
                                                        </div>
                                                    )}

                                                </div>


                                            </div>

                                            <div className={"row mb-1 bg-light p-2"}>
                                                <div className={"col-6 col-sm-6 col-md-5"}>
                                                    <i className="far fa-user" /> Họ và tên
                                                </div>
                                                <div className={"col-6 col-sm-6 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{data.id_user.username}</span>
                                                </div>
                                            </div>

                                            <div className={"row mb-1 p-2"}>
                                                <div className={"col-6 col-sm-6 col-md-5"}>
                                                    <i className="far fa-envelope" /> Số HD
                                                </div>
                                                <div className={"col-6 col-sm-6 col-md-7"}>
                                                    <span className={"text-line"}>{data.id_member.number_contract}</span>
                                                </div>
                                            </div>


                                            <div className={"row mb-1 bg-light p-2"}>
                                                <div className={"col-6 col-sm-6 col-md-5"}>
                                                    Mã khách hàng
                                                </div>
                                                <div className={"col-6 col-sm-6 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{data.id_user.code_user}</span>

                                                </div>
                                            </div>

                                            <div className={"row mb-1 p-2"}>
                                                <div className={"col-6 col-sm-6 col-md-5"}>
                                                    <i className="fas fa-phone-alt" /> SDT/ID
                                                </div>
                                                <div className={"col-6 col-sm-6 col-md-7"}>
                                                    <span>{data.id_user.user_login}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-9 "}>

                                            <div className={"row"}>
                                                <div className={"alert alert-danger rounded-0 mt-2 text-uppercase font-weight-bold mb-2"}>
                                                    CHI TIẾT BUỔI TẬP
                                                </div>
                                            </div>

                                            <div className={"row m-0 mb-3"}>

                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="far fa-clock me-1" />Số HD
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>
                                                    {data.id_member.number_contract}
                                                </span>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="far fa-clock me-1" />Thẻ tập
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>
                                                    {data.id_member.id_card.name}
                                                </span>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="far fa-clock me-1" />Ngày bắt đầu
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>
                                                    {timeConverterDate(data.id_member.start_day)}
                                                </span>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="far fa-clock me-1" />Ngày kết thúc
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>
                                                    {timeConverterDate(data.id_member.end_day_contract)}
                                                </span>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="far fa-clock me-1" />Trạng thái tập luyện
                                                    </label>
                                                    <span className={"m-0 ms-3 font-weight-bold"}>
                                                    {status_contract(data.id_member)}
                                                </span>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="far fa-clock me-1" />Giá trị HD
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3 text-uppercase font-weight-bold font14 text-red"}>
                                                    {formatNumber(data.id_member.info_contract_data.price_sale)} vnđ
                                                </span>
                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="far fa-clock me-1" />Thời gian vào
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>
                                                    {timeConverter(data.id_checkin.time)}
                                                </span>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="far fa-clock me-1" />Thời gian ra
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>

                                                    {typeof data.id_checkin.time_update!=='undefined'?timeConverter(data.id_checkin.time_update):(
                                                        <span>___</span>
                                                    )}

                                                </span>
                                                </div>


                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="far fa-user me-1" />PT dạy
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>

                                                    {data.id_checkin.admin_pt_session!==null?(
                                                        <span>{data.id_checkin.admin_pt_session.username+" - "+data.id_checkin.admin_pt_session.code_user}</span>
                                                    ):(
                                                        <span>___</span>
                                                    )}

                                                </span>
                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="fas fa-headset me-1" />PT Trợ giảng
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>

                                                    {data.id_checkin.sub_admin_pt.length>0?(
                                                        <>
                                                            {data.id_checkin.sub_admin_pt.map(function(item){
                                                                return(
                                                                    <span className={"badge bg-primary font10 rounded-0 m-1"}>{item.code_user}</span>
                                                                );
                                                            })}
                                                        </>
                                                    ):(
                                                        <span>___</span>
                                                    )}
                                                </span>
                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="fas fa-layer-group me-1" />Lớp nhóm
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>
                                                        {data.id_checkin.statics_member_group!==null?(
                                                            <span>{data.id_checkin.statics_member_group.name+" - "+data.id_checkin.statics_member_group.code_group}</span>
                                                        ):(
                                                            <span>___</span>
                                                        )}
                                                </span>
                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-400 mb-1">
                                                        <i className="fas fa-dumbbell me-1" /> Bộ môn
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>

                                                     {data.id_checkin.current_subject.length>0?(
                                                         <>
                                                             {data.id_checkin.current_subject.map(function(item){
                                                                 return(
                                                                     <span className={"badge bg-primary font10 m-1"}>{item.name}</span>
                                                                 );
                                                             })}
                                                         </>
                                                     ):(
                                                         <span>___</span>
                                                     )}
                                                </span>
                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="fas fa-user-cog me-1" />
                                                        NV Lễ tân
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>
                                                    {data.id_checkin.id_admin.username+" - "+data.id_checkin.id_admin.code_user}
                                                </span>
                                                </div>



                                            </div>


                                            <div className={"row"}>
                                                <div className={"alert alert-primary rounded-0 mt-2 text-uppercase font-weight-bold mb-2"}>
                                                    CHI TIẾT SẢN PHẨM
                                                </div>
                                            </div>


                                            <div className={"row mt-2"}>

                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-5"}>
                                                    <label className={"w-100 text-uppercase font-weight-bold font16 mb-2"}>
                                                        <i className="fab fa-free-code-camp me-2" />
                                                        Sản phẩm đi kèm HD</label>

                                                    {data.product_in_card.length>0?(
                                                        <table className={"table mw-100 min-width-auto"}>
                                                            <thead>
                                                            <tr>
                                                                <th className={"bg-light-bold"}>Tên SP</th>
                                                                <th className={"bg-light-bold"}>Số Lượng</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>

                                                            {data.product_in_card.map(function(item){
                                                                return(
                                                                    <tr>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.number}</td>
                                                                    </tr>
                                                                );
                                                            })}


                                                            </tbody>
                                                        </table>
                                                    ):(
                                                        <div className={"alert alert-danger rounded-0"}>
                                                            Không có SP nào
                                                        </div>
                                                    )}


                                                </div>


                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-7"}>
                                                    <label className={"text-uppercase font-weight-bold font16 mb-2"}>
                                                        <i className="fas fa-plus me-2" />
                                                        Sản phẩm mua thêm</label>


                                                    {data.product_buy_session.length>0?(

                                                        <>

                                                            <table className={"table mw-100 min-width-auto"}>
                                                                <thead>
                                                                <tr>
                                                                    <th className={"bg-light-bold"}>Tên SP</th>
                                                                    <th className={"bg-light-bold"}>Số Lượng</th>
                                                                    <th className={"bg-light-bold"}>Kho</th>
                                                                    <th className={"bg-light-bold"}>Số tiền</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {data.product_buy_session.map(function(item){
                                                                    return(
                                                                        <tr>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.number}</td>
                                                                            <td>
                                                                                {data.warehouse!==null ?(
                                                                                    <>
                                                                                    {
                                                                                        getItemInArray(item._id,'product_id',data.warehouse,true).map(function(it){
                                                                                            return (
                                                                                                <span
                                                                                                    className={"badge bg-primary rounded-0 m-1 font10"}>{it.label}</span>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    </>
                                                                                ):(
                                                                                    <></>
                                                                                )
                                                                            }</td>
                                                                            <td>
                                                                                <span className={"font-weight-bold text-red"}>{formatNumber(item.price)} VNĐ</span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}

                                                                </tbody>
                                                            </table>

                                                            <p className={"text-end font-weight-bold font16"}>Tổng tiền :
                                                                <span className={"ms-2 text-red"}>{formatNumber(data.price)} VNĐ</span>
                                                            </p>

                                                        </>

                                                    ):(
                                                        <div className={"alert alert-danger rounded-0"}>
                                                            Không có sp nào
                                                        </div>
                                                    )}


                                                </div>




                                            </div>




                                        </div>

                                    </div>





                            </div>

                                <div className={"modal-footer bg-light"}>

                                    <div className={"col-12 col-sm-12 col-lg-12"}>

                                        <div className={"col-12 col-sm-12 col-lg-2 offset-lg-10"}>
                                            <a onClick={()=>{
                                                this.props.onClose();
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Đóng
                                            </a>
                                        </div>

                                    </div>


                                </div>


                            </div>

                    </div>
                        <div onClick={()=>{
                            this.props.eventClose();
                        }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />


                    </div>

                </>
           );
        }
        else{
            return(<></>);
        }
    }
}
