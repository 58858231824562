/* eslint-disable */
import React from "react";

export function _isset(item) {
    if (typeof item !== 'undefined') {
        return true;
    } else {
        return false;
    }
}

export function _isset_string(item) {
    if (typeof item === 'string' && item.length > 0) {
        return true;
    } else {
        return false;
    }
}


export function _isset_array(item) {
    if (item!==null && typeof item === 'object') {
        if (typeof item === 'object') {
            if (item.length > 0) {
                return true;
            } else {
                return false;
            }

        } else {
            return false;
        }
    }
    else{
        return false;
    }
}

export function _isset_object(item) {
    if (item!==null && typeof item === 'object') {
        if(Object.keys(item).length > 0){
            return true;
        }
        else{
            return false;
        }

    } else {
        return false;
    }
}

export function ConverFormData(object) {
    var formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function getYear(date){
    try{
        return date.getFullYear();
    }
    catch (e){
       return null;
    }

}

export function getMonth(date){
   try{
       return date.getMonth();
   }
   catch (e){
       return null;
   }
}

export function timeConverterDate(UNIX_timestamp){

    if(UNIX_timestamp===null || typeof UNIX_timestamp==="undefined"){
        return '';
    }
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();

    if(eval(date)<10){
        date="0"+date;
    }
    var time = date + '/' + month + '/' + year;
    return time;
}

export function timeConverter(UNIX_timestamp,full=true){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    if(date<10){
        date="0"+date;
    }
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();

    if(full===true){
        var time =hour + ':' + min + ':' + sec +" - "+ date + '/' + month + '/' + year ;
    }
    else{
        var time =hour + ':' + min + ':' + sec;
    }

    return time;
}

export function secondsToHms(time) {
    time = Number(time);
    var h = Math.floor(time / 3600);
    var m = Math.floor(time % 3600 / 60);
    var s = Math.floor(time % 3600 % 60);
    var d=0;
    var hday='';

    if(h>24){
        d=Math.floor(h/24);
        h=h-(d*24);
        hday=d+ " Ngày ";
    }
    var hDisplay = h > 0 ? h + (h === 1 ? " Giờ " : " Giờ ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " Phút " : " Phút ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " Giây" : " Giây") : "";
    return hday + hDisplay + mDisplay+sDisplay;
}

export function get_timestamp_now(){
    return parseInt(Math.ceil(new Date().getTime()/1000));
}

export function ConvertStringToDate(string,slip='/'){
    var dateParts = string.split(slip);
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

export function NextDay(time,day){
    var dateObject=new Date(time*1000);
    dateObject.setDate(dateObject.getDate()+day);
    return dateObject;
}

export function NextMonth(time,month){
    var dateObject=new Date(time*1000);
    dateObject.setMonth(dateObject.getMonth()+month);
    return dateObject;
}

export function between_two_dates(date1,date2){
    var Difference_In_Time = date2.getTime() - date1.getTime();
    return Difference_In_Time / 1000;
}

export function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
export function monthDiffByTime(d1, d2) {
    var months;
    d1=new Date(d1*1000);
    d2=new Date(d2*1000);
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export function range(start, end) {
    var ans = [];
    for (let i = start; i <= end; i++) {
        ans.push(i);
    }
    return ans;
}

export function array_to_select_basic(data,name,value){

    var response=[];

    if(_isset_object(data) && data.length>0){

        data.map(function(item,index){

            response.push({
                key:item[name],
                value:item[value]
            });

        });

    }

    return response;
}

export function array_to_select(data,name,value){

    var response=[];

    if(_isset_object(data) && data.length>0){

        data.map(function(item,index){

            response.push({
                label:item[name],
                value:item[value]
            });

        });

    }

    return response;
}

export function object_to_select(data,name){

    var response=[];

    if(_isset_object(data)){

        var get_key=Object.keys(data);

        get_key.map(function(item,index){

            response.push({
                label:data[item][name],
                value:item
            });

        });

    }

    return response;
}

export function get_data_in_args(args=[],name=null){
    var response=[];

    if(args.length>0 && name!==null){

        args.map(function (item,index){

            if(typeof item[name]!=='undefined' && item[name]!==null){
                response.push(item[name]);
            }

        });

    }
    return response;
}

export function defaultValueObject(args,name,value,mega=null){

    if(_isset_object(args)){

        if (mega!==null){
            return {value:args[value],label:args[name]+" - "+args[mega]};
        }
        else{
            return {value:args[value],label:args[name]};
        }

    }
    else{
        return {};
    }
}

export function base64_encode(string){
   return window.btoa(encodeURIComponent(JSON.stringify(string)));
}

export function convert_object_to_array(data){

    var response=[];

    if(data!==null && typeof data==='object'){

        Object.entries(data).map(function(index,value){

            response.push({
                key:index[0],
                value:index[1]
            });

        });


    }

    return response;

}

export function uniq_array(a) {
    return Array.from(new Set(a));
}

export function formatNumber(num){
    if(typeof num!="undefined"){
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }
    else{
        return "0";
    }
}

export function getDayOfWeek(time){
    var d = new Date(time*1000);
    var day = d.getDay();

    var name='';

    switch (day) {

        case 0:
            name="Chủ nhật";
            break;
        case 1:
            name="Thứ hai";
            break;
        case 2:
            name="Thứ ba";
            break;
        case 3:
            name="Thứ tư";
            break;
        case 4:
            name="Thứ năm";
            break;
        case 5:
            name="Thứ sáu";
            break;
        case 6:
            name="Thứ bảy";
            break;
    }

    return name;

}

export function getEndDay(time){
    var end = new Date(time*1000);
    return end.setHours(23,59,59,0);
}

export function GetTimesheet(arg){
    var response=[];

    if(arg.length>0){

        for(var i=0;i<arg.length;i+=2){

            var tmp_start=arg[i]['time'];

            if(typeof arg[i+1]!=='undefined'){

                var j=i+1;

                var tmp_end=arg[j]['time'];
            }
            else{
                var tmp_end=getEndDay(arg[i]['day'])/1000;
            }


            var get_start=new Date(tmp_start*1000).getHours();
            var get_end=new Date(tmp_end*1000).getHours();

            response.push({
                start:get_start,
                end:get_end,
                length:get_end-get_start,
                detail:{
                    start:tmp_start,
                    end:tmp_end
                }
            });


        }


    }
    return response;
}

export function TimeInSheet(number,timesheet){

    var status=false;

    if(timesheet.length>0){

        timesheet.map(function (value,key){

            if(number>=value['start'] && number<=value['end']){
                status=timesheet[key];
            }

        });

    }
    return status;
}
export function randomColor(){
    return "#"+Math.floor(Math.random()*16777215).toString(16);
}

export function randomNumber(){
    return "111"+Math.random().toString().slice(2,9);
}


export function in_module(name,object){
    var status=false;

    Object.entries(object).map(function (value,index){

        value[1].map(function (item,key){

            if(item.path===name){
                status=true;
            }
        });
    });
    return status;
}

export function itemExitObject(value,name,object){

    var response=false;

    if(_isset_object(object)){
        object.map(function (item){
            if(item[name]===value){
                 response=true;
            }
        });
    }
    return response;
}

export function ArrayInArray(args1,args2,name="value"){
    var response=[];
    if(_isset_array(args1)){
        args1.map(function(item){
            args2.map(function (value){
                if(value[name]===item){
                    response.push(value);
                }
            });
        });
    }
    return response;
}

export function getItemInArray(value=null,name=null,args=[],mutiple=false){

    var response = null;

    if(mutiple===true) {
         response = [];
    }


    if(args.length>0){

        args.map(function (item,index){

            if(item[name]===value){

                if(mutiple===true){
                    response.push(item);
                }
                else{
                    response=item;
                }

            }

        })

    }



    return response;

}


export function get_id_in_object(response=[],list=[],data={}){

    list.map(function(item){
        if(typeof data[item]!=='undefined' && _isset_object(data[item])){
            response[item]=data[item]['_id'];
        }
        else{
            response[item]=null;
        }
    });

    return response;
}

export function convertArrayToSingle(data=[]){

    var response=[];

    if(data.length>0){

        data.map(function(value){

            response.push(value['_id']);

        });
    }

    return response;
}

export function filter_args(args=[]){

    var response=[];

    if(args.length>0){

        args.map(function(item,index){
            if(item!==null && typeof item!=='undefined'){
                response.push(item);
            }
        });

    }

    return response;
}

export function input_price(string){
    if(typeof string==='string'){
        var get_value=string.replace(/[^0-9]/g, '');
        return formatNumber(get_value.replaceAll(".", ''));
    }
    else{

        if(typeof string ==='number'){
            return formatNumber(string);
        }
        else{
            return '';
        }

    }
}

export function remove_input_price(string){

    if(typeof string==='string') {
        return string.replace(/[^0-9]/g, '');
    }
    else{
        return '';
    }
}

export function remove_empty_array(args){

    var response=[];

    args.map(function(item,index){

        if(item!==null && typeof item!=='undefined'){

            response.push(item);
        }
    });

    return response;
}

export function get_name_day(number){

    switch (number) {
        case 1:
            return "thứ 2";
        case 2:
            return "Thứ 3";

        case 3:
            return "Thứ 4";
        case 4:
            return "Thứ 5";
        case 5:
            return "Thứ 6";
        case 6:
            return "Thứ 7";
        case 7:
            return "Chủ nhật";
    }

}

export function add_to_item_array(args=[],name,value){

    var response=[];

    if(args.length>0){

        args.map(function(item,index){
            var tmp=item;
            tmp[name]=value;

            response.push(tmp);
        });


    }

    return response;
}

export function add_span_to_string(string=null){

    var myArray = string.split("");

    var response='';

    if(myArray.length>0){

        myArray.map(function(item,index){

            response+="<span>"+item+"</span>";
        })

    }

    return response;

}


export function get_admin_flip_chat(data=[],id_current=null){

    var response=[];

    if(typeof data.id_admin!=='undefined' && Object.keys(data.id_admin).length>0){

        if(data.id_admin._id===id_current){
            response=data.id_admin_send;
        }

    }
    if(typeof data.id_admin_send!=='undefined' && Object.keys(data.id_admin_send).length>0){

        if(data.id_admin_send._id===id_current){
            response=data.id_admin;
        }

    }

    return response;
}

export function update_item_array(key=null,value=null,name=null,update=null,data=[]){

    var response=[];

    if (data.length>0){

        data.map(function (item,index){

            if (typeof item[key]!=='undefined' && item[key]===value){
                item[name]=update;
            }

            response.push(item);

        });

    }


    return response;
}

export function get_item_in_array(key=null,value=null, data=[]){

    var response=null;

    if (data.length>0){

        data.map(function (item,index){

            if (typeof item[key]!=='undefined' && item[key]===value){
                response=item;
            }

        });

    }
    return response;
}

export function getSourcePush(data){

    var number=null;

    if(typeof data.source_push!=='undefined'){
        number=data.source_push;
    }
    switch (number){
        case 0:
            return(<span className={"badge bg-primary font11"}>Web</span>);
        case 1:
            return(<span className={"badge bg-danger font11"}>Google Sheet</span>);
        case 2:
            return(<span className={"badge bg-danger font11"}>App Mobile</span>);
        default:
            return(<span className={"badge bg-warning font11"}>Chưa rõ</span>);
    }

}
