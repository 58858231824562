/* eslint-disable */
import React,{Component} from "react";
import lodash from "lodash";
import {toast} from "react-toastify";
import CanvasJSReact from '../../helper/canvasjs.react';
import DatePicker from "react-datepicker";
import {base64_encode} from "../../helper/array";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const INIT_STATE={
    query:{
        start_time:null,
        end_time:null
    },
    loading:false,
    statics:{
        push:null,
        static:null
    }
};

export default class marketingreport extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    UpdateQuerySearch(key,value){
        var query=this.state.query;
        query[key]=value;
        this.setState({
            query:query
        });
    }


    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);
    }

    async SubmitAction(){

        if(this.state.query.start_time===null || this.state.query.end_time===null){
            toast("Vui lòng nhập đầy đủ thông tin");
            return;
        }

        this.props.AppThis.PageLoad(true);


        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var get_query=this.getQuerySearch();

        if(Object.keys(get_query).length>0){
            formData.append('query', base64_encode(get_query));
        }

        var get_data=await fetch(Appthis.ConstApp.home_api+"marketingreport", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Lấy dữ liệu thất bại");
            this.props.AppThis.PageLoad(false);
        }
        else{

            this.ReportData(get_data.data);
            this.props.AppThis.PageLoad(false);

        }

    }

    ReportData(data){
        var statics=this.state.statics;

        var report_statics=data.report_statics.data;

        var dataPoints=[];

        report_statics.map(function (item){
            dataPoints.push({y:item.percent, label: item.label});
        });

        statics['static']={
            animationEnabled: true,
            data: [{
                type: "pie",
                startAngle: 40,
                toolTipContent: "<b>{label}</b>",
                showInLegend: "false",
                legendText: "{label}",
                indexLabelFontSize: 16,
                indexLabel: "{label}",
                dataPoints: dataPoints
            }]
        };

        var report_user=[];

        var report_user_push=[];


        Object.entries(data.report_user).forEach(entry => {
            const [key, value] = entry;

            report_user_push.push({
                x:new Date(key*1000),
                y:value.push
            });

            report_user.push({
                x:new Date(key*1000),
                y:value.user
            });

        });



        statics['push']={
            animationEnabled: true,
            theme: "light2",
            axisX:{
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip:{
                shared:true
            },
            legend:{
                cursor:"pointer",
                dockInsidePlotArea: true
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Khách hàng",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: report_user
                },
                {
                    type: "line",
                    showInLegend: true,
                    name: "Chuyển số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: report_user_push
                }
            ]
        };

        this.setState({
            statics:statics
        });


    }

    render(){

        const self=this;
        return(
            <div className={"container-fluid container-main-template mb-5"}>


                <div className={"col-12 pt-4 pb-5 mt-1 shadow bg-white"}>

                    <div className="row m-0">

                        <div className="col-12 col-sm-12 col-md-12 d-flex align-items-center">
                            <h3 className="font18 mb-0 text-uppercase font-weight-bold font-weight-bold">
                                THỐNG KÊ CHUYỂN NHẬN SỐ
                            </h3>
                        </div>


                    </div>





                    <div className={"col-12 ps-3 mt-3 pe-3"}>

                        <div className={"row"}>


                            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 pb-3 box_input"}>
                                <label className={"font15 mb-1"}>Thời gian bắt đầu
                                    <span className={"text-red"}>( * )</span>
                                </label>

                                {self.state.query.start_time!==null?(
                                    <DatePicker  isClearable dateFormat="dd/MM/yyyy" selected={new Date(this.state.query.start_time*1000)} onChange={(date) => {
                                        var tmp_time=null;
                                        if(date!==null){
                                             tmp_time=Math.ceil(date.getTime()/1000);
                                        }
                                        this.UpdateQuerySearch('start_time',tmp_time);
                                    }} />

                                ):(
                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        var tmp_time=null;
                                        if(date!==null){
                                            tmp_time=Math.ceil(date.getTime()/1000);
                                        }
                                        this.UpdateQuerySearch('start_time',tmp_time);

                                    }} />
                                )}

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 pb-3 box_input"}>

                                <label className={"font15 mb-1"}>Thời gian kết thúc
                                    <span className={"text-red"}>( * )</span>
                                </label>


                                {self.state.query.end_time!==null?(
                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" selected={new Date(this.state.query.end_time*1000)} onChange={(date) => {
                                        var tmp_time=null;
                                        if(date!==null){
                                            tmp_time=Math.ceil(date.getTime()/1000);
                                        }
                                        this.UpdateQuerySearch('end_time',tmp_time);

                                    }} />

                                ):(
                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        var tmp_time=null;
                                        if(date!==null){
                                            tmp_time=Math.ceil(date.getTime()/1000);
                                        }
                                        this.UpdateQuerySearch('end_time',tmp_time);

                                    }} />
                                )}




                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 col-lg-3"}>
                                <label className={"font15 mb-1"}>&nbsp;
                                    <span className={"text-red"}></span>
                                </label>

                                <a onClick={()=>{
                                    this.SubmitAction();
                                }} className={"btn w-100 d-block btn-outline-primary rounded-0"}>
                                    Thống kê
                                </a>

                            </div>

                        </div>

                    </div>


                    <div className={"col-12 ps-3 pe-3"}>

                        {this.state.statics.push!==null?(
                           <div className={"col-12 mb-4 mt-5"}>

                               <h3 className="font16 mb-2 text-uppercase font-weight-bold font-weight-bold">
                                   Khách hàng và chuyển số
                               </h3>


                               <CanvasJSChart options={this.state.statics.push} />
                           </div>
                        ):(<></>)}


                        {this.state.statics.static!==null?(
                            <div className={"col-12 mb-4"}>
                                <h3 className="font16 mb-2 text-uppercase font-weight-bold font-weight-bold">
                                    trạng thái khách hàng
                                </h3>

                               <CanvasJSChart options={this.state.statics.static} />
                            </div>
                        ):(<></>)}


                    </div>




                </div>


            </div>
        );

    }



}
