/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {add_span_to_string, base64_encode, formatNumber, timeConverter,
} from "../../helper/array";

import EventCheckin from "../../global/checkin/EventCheckin";
import EditCheckin from "../../global/checkin/EditCheckin";
import {get_name_card, number_did_contract, status_contract, status_payment} from "../../helper/member";
import DetailContract from "../../global/contract/DetailContract";
import {confirmAlert} from "react-confirm-alert";
import UpdateGroupStatics from "../../global/checkin/UpdateGroupStatics";
import Lottie from 'lottie-react-web';
import cardscanning from '../../../src/assets/file/credit-card-scanning.json';
import parse from "html-react-parser";
import Floater from 'react-floater';
import TryCheckin from "../../global/checkin/TryCheckin";

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
    },
    page:0,
    CheckByUser:{
        status:false,
        id_user:null
    },
    DataCheckin:null,
    SocketConnect:null,
    DataDetailContract:null,
    EditCheckin:null,
    UpdateGroupStatics:false,
    CheckByCard:{
        status:false,
        number_card:''
    },
    DataStatics:null,
    DataModal:{
        status:false
    },
    methodCheckin:null
};

export default class clubcheckin extends Component {
    constructor(props) {

        super(props);

        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;
        var query_send={};
        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });
        return query_send;
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    componentDidMount() {

        this.getData();

        this.props.AppThis.RegisterSocket(()=>{
            this.listenSocket();
        });

        this.getStatics();

        document.addEventListener("keydown", this.KeyEventFunction);

    }


    KeyEventFunction=(event)=>{

        switch (event.key) {
            case "F2":
                this.OpenModalTry();

                this.setState({
                    ModalSearchMobile:false
                });
                break;
            case "F3":
                this.setState({
                    CheckByUser:{
                        status:true,
                        id_user:null
                    },
                    ModalSearchMobile:false
                });

                break;
            case "F4":
                this.setState({
                    CheckByCard:{
                        status:true,
                        number_card:'',
                    },
                    ModalSearchMobile:false
                });
                break;

            case "Escape":

                this.setState({
                    DataModal:{
                        status:false
                    },
                    CheckByUser:{
                        status:false,
                        id_user:null
                    },
                    CheckByCard:{
                        status:false,
                        number_card:'',
                    },
                    ModalSearchMobile:false
                });


                break;
        }


    }

    getData(load=false){

        var AppThis=this.props.AppThis;

        if(load===true){
            AppThis.PageLoad(true);
        }

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        var data_search=this.getQuerySearch();

        if(Object.keys(data_search).length>0){
            formData.append('query',base64_encode(data_search));
        }

        formData.append('page', this.state.page);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubcheckin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                AppThis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                AppThis.PageLoad(false);
            }

        });


    }

    getStatics(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());


        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubcheckin/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    DataStatics:data.data
                });
            }



        }).catch((e)=>{


        });


    }


    UNSAFE_componentWillMount() {
        this.listenSocket();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }

    AlertDeleteSession(data){
        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn hủy lượt checkin này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DeleteSession(data._id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    DeleteSession(id=null){

        var AppThis=this.props.AppThis;

        AppThis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('id_checkin', id);

        fetch(AppThis.ConstApp.home_api+"clubcheckin/delete", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Hủy thất bại");
                AppThis.PageLoad(false);
            }
            else{
                this.getData(true);
            }

        }).catch((e)=>{

            toast("Hủy thất bại");

            AppThis.PageLoad(false);
        });


    }

    listenSocket(){

        var socket=this.props.AppThis.getSocketConnect();

        this.setState({
            SocketConnect:socket
        });

        const self=this;

        if(socket!==null){

            socket.onmessage=function (e){

                if (typeof e.data != "undefined") {

                    var get_data_socket=JSON.parse(e.data);

                    if(get_data_socket.status==='ok' && typeof get_data_socket.action!=='undefined'){

                        switch (get_data_socket.action) {

                            case "checkin":
                                self.setState({
                                    DataCheckin:get_data_socket.message_server,
                                    methodCheckin:get_data_socket.method
                                });
                                break;

                            case "checkout":
                                toast("CheckOut thành công");
                                self.getData(true);
                                break;

                            case "notfound":
                                toast("Không tìm thấy HD");
                                break;

                            case "error":
                                toast("Trạng thái số HD "+get_data_socket.list_contract[0].number_contract+ " - " +status_contract(get_data_socket.list_contract[0]));
                                break;

                        }

                    }

                }
            }
        }


    }

    CheckinByUser(user=null){

        var number_error=0;

        if(user===null){
            number_error+=1;
        }
        else{
            if(user.length<5){
                number_error+=1;
            }
        }

        if(number_error>0){
            toast("Vui lòng kiểm tra lại");
        }
        else{

            var AppThis=this.props.AppThis;

            AppThis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', AppThis.getToken());

            formData.append('id_address', AppThis.getAddress());

            formData.append('id_user', user);

            const self=this;
            fetch(AppThis.ConstApp.home_api+"clubcheckin/checkuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='error'){
                    toast("Không tìm thấy thông tin số điện thoại");
                }

                AppThis.PageLoad(false);

                self.setState({
                    CheckByUser:{
                        status:false,
                        id_user:null
                    }
                });

            }).catch((e)=>{

                toast("Lấy dữ liệu thất bại");

                AppThis.PageLoad(false);
            });


        }

    }


    BoxCheckinByUser(){

        var get_user=this.state.CheckByUser.id_user;

        this.CheckinByUser(get_user);

    }

    CheckinByCard(string=null){

        var AppThis=this.props.AppThis;

        AppThis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('card', string);

        fetch(AppThis.ConstApp.home_api+"clubcheckin/checkincard", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Không tìm thấy thông tin thẻ tập");
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");

            AppThis.PageLoad(false);
        });


    }



    listenNumberCard(event){

        var list_number = ['0','1','2','3','4','5','6','7','8','9'];

        var CheckByCard=this.state.CheckByCard;

        var get_card_scan=CheckByCard.number_card;

        var get_key=event.key.toString();

        if(list_number.indexOf(get_key)!==-1){

            get_card_scan=get_card_scan+get_key;

            CheckByCard['number_card']=get_card_scan;

            this.setState({
                CheckByCard:CheckByCard
            });

        }


        if(get_key==='Enter' || event.keyCode===13){

            var get_number_card=this.state.CheckByCard['number_card'];

            this.setState({
                CheckByCard:{
                    status:false,
                    number_card:'',
                }
            });


            this.CheckinByCard(get_number_card);
        }

    }


    RenderData(){

        const self=this;

        return(
            <div className={"row mt-4 mb-4 m-0"}>

                <div className={"col-12"}>
                    <div className={"col-12 box-table-fix"}>
                    <div className={"overflow-auto"}>

                        <table className={"mt-3 w-100 table border-0 mw1800 table-style-old"}>
                            <thead>
                            <tr className={"border-bottom bg-light"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-user me-2"/> TÊN HỘI VIÊN</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i
                                        className="fas fa-mobile-alt me-2" /> SDT/ID</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-credit-card me-2" />
                                    DỊCH VỤ</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-file-excel me-2" />
                                    SỐ HD</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-dollar-sign me-2" />
                                    GIÁ TRỊ HD</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-clock me-2" />Cập nhật</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-calendar-times me-2" />
                                    TỔNG SỐ BUỔI</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-calendar-times me-2" />
                                    SỐ BUỔI CÒN LẠI</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-info me-2" />
                                    PT DẠY
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-info me-2" />
                                    LỚP NHÓM
                                </td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fa-solid fa-table-list me-2"></i>
                                    SỐ TỦ
                                </td>



                                <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                    <i className="fas fa-directions me-2" />
                                    THAO TÁC</td>
                            </tr>
                            </thead>

                            <tbody>

                            {this.state.data.data.map(function(value,index){
                                return(
                                    <tr>
                                        <td>
                                            <span>{value.id_user.username}</span>
                                        </td>
                                        <td>
                                            <span>{value.id_user.user_login}</span>
                                        </td>
                                        <td>

                                            {value.id_member!==null?(
                                                <>
                                                    <span className={"me-2"}>{value.id_member.id_card.name}</span>
                                                    {get_name_card(value.id_member.id_card.type)}
                                                </>
                                            ):(
                                                <>
                                                    <span className={"me-2"}>{value.try_card.name}</span>
                                                    {get_name_card(value.try_card.type)}
                                                </>
                                            )}

                                        </td>

                                        <td>

                                            {value.id_member!==null && value.id_member.group_member!==null?(
                                                <i className="fas fa-check-circle text-primary me-1" />
                                            ):(<></>)}

                                            {value.id_member!==null?(
                                                <>
                                                    <span className={"me-1"}>{value.id_member.number_contract}</span>
                                                    {status_payment(value.id_member)}
                                                </>
                                            ):(

                                                <span className={"badge bg-danger"}>Tập thử</span>

                                            )}


                                        </td>

                                        <td>

                                            {value.id_member!==null?(
                                                <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.id_member.info_contract_data.price_sale)} VNĐ
                                       </span>
                                            ):(
                                                <span className={"text-red font-weight-bold"}>0 VNĐ</span>
                                            )}
                                        </td>

                                        <td>
                                            <span>{timeConverter(value.time)}</span>
                                        </td>


                                        <td>
                                            {value.id_member!==null && value.id_member.id_card.type!==2?(
                                                <span>{value.id_member.info_contract_data.number_card}</span>
                                            ):(<>___</>)}

                                        </td>


                                        <td>

                                            {value.id_member!==null && value.id_member.id_card.type!==2?(
                                                <>
                                                    {number_did_contract(value.id_member)}
                                                </>
                                            ):(<>___</>)}

                                        </td>

                                        <td>
                                            {value.admin_pt_session!==null && Object.keys(value.admin_pt_session).length>0?(
                                                <span>{value.admin_pt_session.username+" - "+value.admin_pt_session.code_user}</span>
                                            ):(
                                                <span>___</span>
                                            )}
                                        </td>

                                        <td>

                                            {value.statics_member_group!==null?(
                                                <span>{value.statics_member_group.name+ " - "+value.statics_member_group.code_group}</span>
                                            ):(
                                                <span>___</span>
                                            )}

                                        </td>
                                        <td>

                                            {typeof value.number_coffer!=='undefined' && value.number_coffer!==null?(
                                                <span>{value.number_coffer}</span>
                                            ):(
                                                <span>___</span>
                                            )}

                                        </td>


                                        <td className={"fix-item-table"}>


                                            {value.id_member!==null?(
                                                <>
                                                    <a onClick={()=>{
                                                        self.setState({
                                                            DataDetailContract:value.id_member
                                                        });
                                                    }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>
                                                        <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xem chi tiết HD">
                                                            <i className={"far fa-folder-open"}></i>
                                                        </Floater>
                                                    </a>


                                                    <a onClick={()=>{

                                                        self.setState({
                                                            EditCheckin:value
                                                        });

                                                    }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                                        <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật buổi tập">
                                                            <i className="far fa-edit" />
                                                        </Floater>
                                                    </a>
                                                </>
                                            ):(
                                                <></>
                                            )}



                                            <a onClick={()=>{
                                                self.AlertDeleteSession(value);
                                            }} className={"rounded-0 font12 d-inline-block btn btn-outline-danger p-2 me-1"}>
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Hủy checkin">
                                                    <i className="fas fa-trash" />
                                                </Floater>
                                            </a>


                                        </td>

                                    </tr>
                                );
                            })}


                            </tbody>

                        </table>
                    </div>
                    </div>

                    <div className={"col-12 ps-3 pe-3 mt-3"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>




                </div>

            </div>
        );
    }


    RenderDataStatics(){

        if(this.state.DataStatics!==null){

            return(
                <div className={"row m-0 mt-4"}>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-warning"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tập PT trong ngày</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.DataStatics.total_pt}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-tenge rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tập nhóm CD</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.DataStatics.total_statics_group}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-tenge rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tập member</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.DataStatics.total_member}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-tenge rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">Tập linh hoạt</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.DataStatics.total_flexible}</p>
                            </div>
                        </div>
                    </div>



                </div>
            );

        }
        else{
            return(
                <div className={"col-12 pt-3 pb-3 d-flex flex-row justify-content-center"}>
                    <ItemLoading />
                </div>
            );
        }
    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubcheckin/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }


    render(){

        const self=this;

        if(this.state.data!==null){
            return(

                <div className={"col-12 position-relative"}>
                    <div className={"col-12 position-relative"} tabIndex={0} onKeyDown={(event)=>{
                        self.listenNumberCard(event);
                    }} >
                    <div className={"container-fluid container-main-template mb-5"}>

                        <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 col-md-12 d-flex align-items-center">
                                    <h3 className="font18 mt-3 mb-0 text-uppercase font-weight-bold font-weight-bold"> Ra vào hội viên</h3>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 text-right text-end">

                                    <div className="col-12 p-0 mb-2">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>

                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>


                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>

                                            <a onClick={()=>{
                                                this.OpenModalTry();
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className={"btn font13 btn-warning rounded-0 me-1"}>
                                                <i className="fas fa-dumbbell me-2" /> KH Tập thử
                                            </a>


                                            <a onClick={()=>{
                                                this.setState({
                                                    CheckByUser:{
                                                        status:true,
                                                        id_user:null
                                                    },
                                                    ModalSearchMobile:false
                                                });
                                            }} className={"btn font13 btn-outline-primary rounded-0"}>
                                                <i className="fas fa-mobile-alt me-2" /> Checkin SDT/Mã kH
                                            </a>


                                            <a onClick={()=>{
                                                this.setState({
                                                    CheckByCard:{
                                                        status:true,
                                                        number_card:'',
                                                    },
                                                    ModalSearchMobile:false
                                                });
                                            }} className={"btn font13 btn-outline-dark rounded-0 ms-1"}>

                                                <i className="far fa-credit-card me-2" /> Checkin bằng thẻ
                                            </a>


                                            <a onClick={()=>{

                                                this.setState({
                                                    UpdateGroupStatics:true,
                                                    ModalSearchMobile:false
                                                });

                                            }} className={"btn font13 btn-outline-danger ms-1 rounded-0"}>
                                                <i className="fas fa-layer-group me-2" />  Cập nhật PT nhóm CD
                                            </a>




                                        </div>

                                    </div>


                                </div>

                            </div>


                            {this.RenderDataStatics()}


                            {this.state.data.data.length>0?(
                                this.RenderData()
                            ):(
                                <div className={"col-12 mt-4 mb-4 ps-3 pe-3"}>
                                    <div className={"alert alert-danger rounded-0"}>
                                        <i className="fas fa-exclamation-triangle me-2" />  Không có dữ liệu
                                    </div>
                                </div>
                            )}



                        </div>

                    </div>
                    </div>



                    {this.state.CheckByUser.status===true?(
                        <div className="modal d-block position-fixed">
                            <div className={"modal-dialog modal-md"}>
                                <div className="modal-content">
                                    <div className="modal-header bg-dark">
                                        <h5 className="modal-title font16 text-uppercase">Checkin SDT/ID</h5>
                                    </div>
                                    <div className="modal-body InputMask">

                                        <div className={"alert alert-warning rounded-0 w-100"}>
                                            <i className="fas fa-exclamation-triangle me-2" />
                                            Vui lòng nhập SDT/ID
                                        </div>

                                        <input type={"text"} onChange={(event)=>{
                                            var CheckByUser=this.state.CheckByUser;
                                            CheckByUser['id_user']=event.target.value;
                                            this.setState({
                                                CheckByUser:CheckByUser
                                            });
                                        }} placeholder={"Số điện thoại"} className={"form-control w-100 rounded-0"}  />


                                        <div className={"row mt-3"}>

                                            <div className={"col-12 col-sm-12 col-md-6"}>

                                                <button type="button" onClick={()=>{
                                                    this.BoxCheckinByUser();
                                                }} className="btn rounded-0 w-100 btn-primary">
                                                    Tìm kiếm
                                                </button>
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-6"}>

                                                <button onClick={()=>{
                                                    this.setState({
                                                        CheckByUser:{
                                                            status:false,
                                                            id_user:null
                                                        }
                                                    });

                                                }} type="button" className="btn rounded-0 w-100 btn-secondary">
                                                    Huỷ
                                                </button>
                                            </div>

                                        </div>

                                    </div>



                                </div>
                            </div>
                            <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                        </div>
                    ):(<></>)}


                    {this.state.DataCheckin!==null?(
                        <EventCheckin path={"clubcheckin"} AppThis={this.props.AppThis} data={this.state.DataCheckin} CloseModal={()=>{
                            this.setState({
                                DataCheckin:null
                            },function(){
                                self.getData(true);
                            });

                        }} method={this.state.methodCheckin} />
                    ):(<></>)}



                    {this.state.DataDetailContract!==null?(
                        <DetailContract OnClose={()=>{
                            this.setState({
                                DataDetailContract:null
                            });

                        }} EventDetail={(id)=>{
                            this.setState({
                                DataDetailContract:null
                            },function(){
                                self.getDetailContract(id);
                            });
                        }} data={this.state.DataDetailContract} />
                    ):(
                        <></>
                    )}



                    {this.state.EditCheckin!==null?(
                        <EditCheckin OnClose={()=>{
                            this.setState({
                                EditCheckin:null
                            });

                        }} CloseModal={()=>{
                            this.setState({
                                EditCheckin:null
                            },function(){
                                self.getData(true);
                            });
                        }} AppThis={this.props.AppThis} path={"clubcheckin"} data={this.state.EditCheckin} />
                    ):(
                        <></>
                    )}



                    {this.state.UpdateGroupStatics===true?(
                        <UpdateGroupStatics onClose={()=>{
                            this.setState({
                                UpdateGroupStatics:false
                            });

                            this.getData(true);
                        }} path={"clubcheckin"} AppThis={this.props.AppThis} />
                    ):(<></>)}


                    {this.state.CheckByCard.status===true?(
                        <div className="modal d-block position-fixed">


                            <div className={"modal-dialog modal-fullscreen position-relative"}>

                                <div onKeyDown={(event)=>{
                                    this.listenNumberCard(event);
                                }} tabIndex="0" id={"ModalCheckByCard"}  className="modal-content bg-transparent col-12 position-relative h-100 d-flex flex-row flex-wrap flex-wrap justify-content-center align-items-center">

                                    <a onClick={()=>{
                                        this.setState({
                                            CheckByCard:{
                                                status:false,
                                                number_card:'',
                                            }
                                        })
                                    }} className={"position-absolute top15 right30"}><i className="far text-white fa-times-circle font30" /></a>




                                    <div className={"col-12"}>
                                        <Lottie options={{
                                            animationData:cardscanning,
                                            loop:true,
                                            autoplay:true
                                        }} width={300} height={200}  />

                                        <p className={"text-center font-weight-bold mt-2 text-white"}>
                                            <em>
                                                (  Vui lòng quẹt thẻ vào thiết bị và giữ màn hình này )
                                            </em>
                                        </p>

                                        <div className={"col-12 text-center text-white font20 mt-4 ScanCardStyle"}>
                                            {parse(add_span_to_string(this.state.CheckByCard.number_card))}
                                        </div>

                                    </div>



                                </div>




                            </div>
                            <div className={"bg-modal bg-scan-card position-fixed w-100 vh-100 top0 left0"} />
                        </div>
                    ):(
                        <></>
                    )}


                    {this.state.DataModal.status===true?(
                        <TryCheckin path={"clubcheckin"} SuccessModal={()=>{
                            self.setState({
                                DataModal:{
                                    status:false
                                }});

                            this.getData(true);
                        }} data={this.state.DataModal.data} CloseModal={()=>{
                            self.setState({
                                DataModal:{
                                    status:false
                                }});
                        }} AppThis={this.props.AppThis} />
                    ):(
                        <></>
                    )}



                </div>
            );
        }
        else{
            return(
                <div className={"col-12 pt-5 pb-5 mt-5 d-flex justify-content-center flex-row"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    OpenModalTry(){

        this.setState({
            DataModal:{
                status:true
            }
        });
    }
}
