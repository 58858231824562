/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    _isset,
    timeConverterDate,
    array_to_select,
    getItemInArray,
    formatNumber,
    input_price,
    remove_input_price,
    base64_encode,
    remove_empty_array,
    get_item_in_array
} from "../../helper/array";
import Select from 'react-select';
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import InfoProfile from "../profile/InfoProfile";
const hiddenFileInput=React.createRef();

export default class Receipts extends Component{

    constructor(props) {
        super(props);

        this.state={
            PaymentMethod:null,
            data:{
                id_payment:null,
                id_bank:null,
                type:1,
                id_member:null,
                file_confirmation:null,
                note:null,
                price:0
            },
            percent:{
                _id:null,
                data:[
                    {
                        id_admin:null,
                        id_percent:null,
                        type:true
                    }
                ]
            },
            InfoPayment:null,
            page:1,
            Loading:false,
            ListAdminClub:null,
            ListPercent:null,
            CalculationPercent:0,
            ListWhiteReceipt:null,
            ProfileInfo:null,
            TmpName:null
        };

        this.TimeOutEvent=null;

    }
    UpdatePercentData(number,key,value){
        var percent=this.state.percent;

        if(percent['data'][number]===null ||typeof percent['data'][number]==='undefined'){
            percent['data'][number]={};
        }
        percent['data'][number][key]=value;

        this.setState({
            percent:percent
        });
    }


    DeletePercentData(number){

        var percent=this.state.percent;

        var data_percent=percent.data;

        if (data_percent.length>1){
            delete data_percent[number];
        }

        data_percent=remove_empty_array(data_percent);

        percent.data=data_percent;

        this.setState({
            percent:percent
        });
    }

    UpdateData(name,value){

        var data=this.state.data;

        data[name]=value;

        this.setState({
            data:data
        });

    }

    componentDidMount() {

        if(this.props.data!==null && typeof this.props.data!=='undefined'){
            this.UpdateData("id_member",this.props.data._id);
            this.GetWhiteReceipts(this.props.data);
        }

        this.getPaymentMethod();

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    GetWhiteReceipts(data){

        if(typeof data.id_user._id!=='undefined'){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());
            formData.append('id_user', data.id_user._id);

            const self=this;
            fetch(Appthis.ConstApp.home_api+this.props.path+"/whitereceipt", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok' && data.data.length>0){
                    self.setState({
                        ListWhiteReceipt:data.data
                    });
                }

            }).catch((e)=>{

            });
        }

    }

    getPaymentMethod(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"getpayment", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{


            if(data.status==='ok'){
                self.setState({
                    PaymentMethod:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    getAdminCLub(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListAdminClub:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    getPercent(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/percent", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListPercent:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    render() {

        const self=this;
        if(_isset(this.props.data)){

            var data=this.props.data;

            return(
                <div className="modal d-block position-fixed">
                    <div className={"modal-dialog modal-lg modal-lg modal-fullscreen-lg-down"}>
                        <div className="modal-content shadow border-0">
                            <div className="modal-header shadow">

                                <div className={"container"}>

                                    <div className={"row"}>
                                        <div className={"col-8"}>
                                            <strong className={"font18 text-white text-black text-uppercase"}>Lập phiếu thu</strong>
                                        </div>

                                        <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.onClose();
                                    }} className={"text-white right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="modal-body">

                                <div className={"container p-0"}>
                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12"}>

                                            {self.state.Loading===true?(
                                                <div className={"col-12 mt-2 pt-5 mb-2 d-flex justify-content-center"}>
                                                    <ItemLoading size={"Large"} />
                                                </div>
                                            ):(
                                                <div className={"col-12"}>
                                                    {this.state.page===1?(
                                                        this.Page1()
                                                    ):(
                                                        this.Page2()
                                                    )}

                                                </div>
                                            )}


                                        </div>


                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

                    {this.state.ProfileInfo!==null?(
                        <InfoProfile onClose={()=>{
                            this.setState({
                                ProfileInfo:null
                            });
                        }} data={this.state.ProfileInfo} />
                    ):(<></>)}


                </div>
            );
        }
        else{
            return(
                <></>
            );
        }


    }

    UploadFileContract(event){

        var fileUploaded = event.target.files[0];

        const self=this;

        if((fileUploaded.size/1024)<3000){

            if(fileUploaded.type==='image/png' || fileUploaded.type==='image/jpg' || fileUploaded.type==='image/jpeg'){

                let reader = new FileReader();

                reader.readAsDataURL(fileUploaded);

                this.setState({
                    TmpName:event.target.files[0].name
                });


                var data=this.state.data;

                reader.onload = (e) => {

                    data['file_confirmation']= e.target.result;

                    self.setState({
                        data: data
                    });


                }


            }
            else{
                toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
            }

        }
        else{
            toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
        }

    }

    AlertuseWhiteReceipts(receipt){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn sử dụng phiếu thu này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.useWhiteReceipts(receipt);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    useWhiteReceipts(receipt){


        if(this.props.data!==null && typeof this.props.data!=='undefined'){

           var current_member=this.props.data;

           var number_error=0;

            var paid=current_member.billing_information.total_price;

            var total_price=current_member.info_contract_data.price_sale;

            var remaining=total_price-paid;

            if(receipt.price>remaining || remaining<0){
                number_error+=1;
            }

           if(number_error>0){
               toast("Số tiền của phiếu thu phải nhỏ hơn hoặc bằng số tiền HD");
           }
           else{

               this.UpdateReceipts(current_member._id,receipt._id);
           }

        }

    }

    UpdateReceipts(id_member,id_wallet){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        var data_query={
            id_member:id_member,
            id_wallet:id_wallet
        };

        formData.append('data',base64_encode(data_query));

        AppThis.PageLoad(true);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/updatereceipt", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Cập nhật thành công");
            }
            else{
                toast("Cập nhật không thành công");
            }

            AppThis.PageLoad(false);

            self.props.onSuccess();

        }).catch((e)=>{

        });

    }

    Page1(){

        const self=this;

        var data=this.props.data;

        return(
            <div className={"col-12"}>

                {data.billing_information['data'].length>0?(
                   <div className={"col-12 History-Billing-Receipts"}>

                       <h4 className={"text-uppercase font16 font-weight-bold mt-4 mb-3 text-danger"}>
                           <i className="fab fa-amazon-pay me-2" />
                           Lịch sử thanh toán</h4>

                       <table className={"mw-auto table mb-3 mt-2 border-0"}>
                           <thead>
                           <tr className={"bg-light"}>
                               <th><i className="fas fa-clock" /> Ngày thanh toán</th>
                               <th><i className="fas fa-money-bill-alt" /> Số tiền</th>
                               <th><i className="fab fa-amazon-pay" /> Loại thanh toán</th>
                               <th><i className="fas fa-coins" /> Phương thức thanh toán</th>
                           </tr>
                           </thead>

                           <tbody>

                           {data.billing_information.data.map(function(item,index){

                               return(
                                   <tr>
                                       <td>{timeConverterDate(item.time)}</td>
                                       <td>
                                           <span className={"font-weight-bold text-red me-2"}>{formatNumber(item.price)}</span>
                                           VND</td>
                                       <td>
                                           {item.type_of_paid===1?(
                                               <span className={"badge font12 rounded-0 bg-primary"}>Trả hết</span>
                                           ):(
                                               item.type_of_paid===2?(
                                                   <span className={"badge bg-danger font12 rounded-0"}>Đặt cọc</span>
                                               ):(
                                                   <span className={"badge bg-success font12 rounded-0"}>Trả hết</span>
                                               )
                                           )}
                                       </td>
                                       <td>{item.id_payment?item.id_payment.name:null}</td>
                                   </tr>
                               );
                           })}

                           </tbody>
                       </table>
                   </div>
                ):(
                    <div className={"alert alert-danger rounded-0 mb-4"}>
                        <i className="fas fa-exclamation-triangle" /> Chưa có lịch sử thanh toán
                    </div>
                )}


                {this.state.ListWhiteReceipt!==null?(
                    <div className={"col-12 w-100 mb-4"}>
                        <div className={"alert alert-warning rounded-0"}>
                            <i className="fas fa-exclamation-triangle" /> Hệ thống tìm thấy khách hàng có phiếu thu trắng
                        </div>

                        <div className={"col-12 overflow-auto"}>
                            <table className={"mt-3 w-100 mw700 table border-0"}>
                                <thead>
                                <tr className={"border-bottom bg-light"}>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        Số phiếu thu
                                    </td>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        Ngày thu
                                    </td>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        Số tiền
                                    </td>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        Thao tác
                                    </td>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.ListWhiteReceipt.map(function(list,index){
                                    return(
                                        <tr>
                                            <td>
                                                {list.number_votes}
                                            </td>
                                            <td>
                                                {timeConverterDate(list.time)}
                                            </td>
                                            <td>
                                                <p className={"m-0 text-line"}>
                                                    <strong> {formatNumber(list.price)} VNĐ</strong>
                                                </p>
                                            </td>
                                            <td>
                                                <button onClick={()=>{
                                                    self.AlertuseWhiteReceipts(list);
                                                }} className={"btn btn-outline-primary font13 w-100 rounded-0"}>
                                                    <i className="far fa-hand-point-up me-1" />
                                                    Sử dụng
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>




                    </div>
                ):(
                    <></>
                )}



                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-6 d-flex flex-row align-items-center mt-3 mb-3"}>
                        <p className={"font-weight-bold m-0"}> Phương thức thanh toán <span className={"text-red"}>( * )</span></p>
                    </div>


                    <div className={"col-12 col-sm-12 col-md-6"}>

                        {this.state.PaymentMethod!==null?(
                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                    self.UpdateData('id_payment',selectedOption.value);
                                    self.UpdateData('id_bank',null);

                                    var InfoPayment=getItemInArray(selectedOption.value,"_id",this.state.PaymentMethod);
                                    self.setState({
                                        InfoPayment:InfoPayment
                                    });

                                }
                                else {
                                    self.UpdateData('id_payment',null);
                                    self.UpdateData('id_bank',null);
                                    self.setState({
                                        InfoPayment:null
                                    });
                                }

                            }} options={array_to_select(this.state.PaymentMethod,'name','_id')} />
                        ):(
                            <ItemLoading />
                        )}

                    </div>


                    {this.state.InfoPayment!==null && this.state.InfoPayment.bank.length>0?(
                        <div className={"row m-0 mt-4 mb-3"}>
                            {this.state.InfoPayment.bank.map(function(item,index){
                                return(
                                    <div className={"col-6 col-sm-6 col-md-4 col-lg-4 mb-3"}>

                                        <div onClick={()=>{
                                            self.UpdateData("id_bank",item._id);
                                        }} className={self.state.data.id_bank===item._id?("col-12 border position-relative active_tab_box"):("col-12 border position-relative")}>

                                            <div className={"col-12 p-2 font-weight-bold border-bottom text-center font15 text-uppercase left-box-info-bank"}>
                                                <p className={"m-0"}><i
                                                    className="fab fa-cc-amazon-pay" /> {item.name}</p>
                                            </div>

                                            <div className={"col-12 p-3 right-box-info-bank"}>

                                                <p className={"m-0"}>
                                                    <i
                                                        className="fas fa-user"/> Chủ tài khoản :
                                                    {item.user_account}
                                                </p>
                                                <p className={"m-0"}>
                                                    <i
                                                        className="fab fa-cc-paypal" /> Số Tài khoản :
                                                    {item.account}
                                                </p>

                                            </div>

                                        </div>


                                    </div>
                                );
                            })}
                        </div>
                    ):(<></>)}


                    <div className={"col-12 col-sm-12 col-md-6 mt-3"}>
                        <p className={"font-weight-bold mb-1"}>Ghi chú</p>
                        <input type={"text"} onChange={(event)=>{
                            this.UpdateData("note",event.target.value);
                        }} placeholder={"Ghi chú"} className={"form-control"} />
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 mt-3"}>
                        <p className={"font-weight-bold mb-1"}> Số tiền <span className={"text-red"}>( * )</span></p>

                        <input value={input_price(this.state.data.price)} type={"text"} onChange={(event)=>{

                            try{
                                var get_price=remove_input_price(event.target.value);
                                self.UpdateData("price",get_price);
                            }
                            catch (e){

                            }

                        }} placeholder={"Số tiền"} className={"form-control w-100 rounded-0"} />

                    </div>

                </div>




                <div className={"col-12 col-sm-12 col-md-12 d-flex flex-row align-items-center mt-4"}>
                    <p className={"font-weight-bold mb-0"}> Ảnh xác nhận thanh toán</p>
                </div>

                <div className={"col-12 mt-2 mb-3 p-4 border_uploads_file text-red font14 text-center"}>

                    <a onClick={()=>{
                        hiddenFileInput.current.click();
                    }} className={"font22 upload-images-avatar font16"}>

                        <i className={"fas fa-cloud-upload-alt me-2"} />
                        <span className={"font14"}>Click để tải lên tệp</span>

                        <input onChange={(event)=>{
                            this.UploadFileContract(event);
                        }} ref={hiddenFileInput} accept="image/*"  type={"file"} className={"d-none"} />
                    </a>

                    {this.state.TmpName!==null?(
                        <p className={"m-0 text-red p-0 font12"}>{this.state.TmpName}</p>
                    ):(<></>)}


                </div>




                <div className={"col-12 col-sm-12 col-md-8 offset-md-4 p-0"}>
                    <div className={"row mt-4"}>

                        <div className={"col-12 col-sm-4 mb-3"}>
                            <a onClick={()=>{
                                this.props.onSuccess();
                            }} className={"btn font14 btn-outline-danger w-100 rounded-0"}>
                                <i className="fas fa-times me-1" /> Đóng
                            </a>
                        </div>

                        <div className={"col-12 col-sm-4 mb-3"}>
                            <a onClick={()=>{
                                this.AddWallet();
                            }} className={"btn font14 btn-outline-primary w-100 rounded-0"}>
                                <i className="fas fa-cloud-upload-alt me-1" /> Gửi đi
                            </a>
                        </div>

                        <div className={"col-12 col-sm-4 mb-3"}>
                            <a onClick={()=>{
                              this.AddWallet(2);
                            }} className={"btn font14 btn-outline-success w-100 rounded-0"}>
                                <i className="fas fa-percent me-1" /> Gửi và chia %
                            </a>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

    AddWallet(type_action=1){

        var Appthis=this.props.AppThis;

        var data_member=this.props.data;

        const self=this;

        var number_error=0;

        if(this.state.InfoPayment!==null && this.state.InfoPayment.bank.length>0){

            if(this.state.data.id_bank===null){
                number_error+=1;
            }

        }
        ['id_payment','id_member'].map(function(item,index){

            if(self.state.data[item]===null){
                number_error+=1;
            }

        });

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{


            if(this.state.data.price>data_member.info_contract_data.price_sale){
                toast("Số tiền vượt quá ngưỡng thanh toán");
                return;
            }

            this.setState({
                Loading:true
            });

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            var data_send=base64_encode(this.state.data);

            formData.append('data',data_send);

            const self=this;
            fetch(Appthis.ConstApp.home_api+this.props.path+"/addwallet", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    toast("Lập phiếu thu thành công");

                    if(type_action===1){
                        self.props.onSuccess();
                    }
                    else{

                        self.getAdminCLub();

                        self.getPercent();

                        var percent=self.state.percent;

                        percent._id=data.data;

                        self.setState({
                            page:2,
                            percent:percent
                        });
                    }

                }
                else{
                    self.props.onClose();
                    toast("Thao tác không chính xác");
                }

                self.setState({
                    Loading:false
                });

            }).catch((e)=>{

                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại sau");
                self.setState({
                    Loading:false
                });


            });




        }

    }

    SavePercentContract(){

        var percent=this.state.percent;

        var percent_data=percent.data;

        var ListPercent=this.state.ListPercent;

        var number_error=0;

        var total_price=0;

        if(percent_data.length===0){
            number_error+=1;
        }
        else{

            percent_data.map(function(item,index){

                if(item.id_admin===null || item.id_percent===null){
                    number_error+=1;
                }
                else{
                    var get_item=getItemInArray(item.id_percent,'_id',ListPercent);

                    total_price+=parseInt(get_item['number']);

                }


            });

        }

        if(total_price!==100){
            number_error+=1;
        }


        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var filter_percent_data=[];

            percent_data.map(function(item,index){

                var tmp={};

                if(typeof item['id_admin']['key']!=='undefined'){
                    tmp['id_admin']=item['id_admin']['key'];
                }

                if(typeof item['id_admin']['_id']!=='undefined'){
                    tmp['id_admin']=item['id_admin']['_id'];
                }

                tmp['id_percent']=item['id_percent'];

                filter_percent_data.push(tmp);

            });


            this.setState({
                Loading:true
            });

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_wallet', this.state.percent._id);

            var base_64data=base64_encode(filter_percent_data);

            formData.append('data', base_64data);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/updatepercent", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){
                    toast("Cập nhật thành công");
                }
                else{
                    toast("Có lỗi trong quá trình xử lý xin vui lòng thử lại sau");
                }

                this.props.onSuccess();

                this.setState({
                    Loading:false
                });

            }).catch((e)=>{
                toast("Có lỗi trong quá trình xử lý xin vui lòng thử lại sau");
                this.setState({
                    Loading:false
                });

            });



        }




    }

    AddPercentData(){

        var get_percent=this.state.percent;

        var get_percent_data=get_percent.data;

        get_percent_data.push({
            id_admin:null,
            id_percent:null,
            type:true
        });

        get_percent.data=get_percent_data;

        this.setState({
            percent:get_percent
        });

    }

    EventInputCode(index=null,event=null){
        const self=this;
        if(this.TimeOutEvent){
            clearTimeout(this.TimeOutEvent);
        }

        this.TimeOutEvent=setTimeout(function(){
            self.verifyAdmin(index,event.target.value,event);
        },1000);
    }


    verifyAdmin(index=null,code=null,event){

        if(index===null || code===null || code.length===0){
            return;
        }

        this.props.AppThis.PageLoad(true);

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('code_user', code);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"staffinfo", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Không tìm thấy thông tin tài khoản");
                self.UpdatePercentData(index,"id_admin",null);
                event.target.value='';

                self.setState({
                    ProfileInfo:null
                });
            }
            else{

                self.setState({
                    ProfileInfo:data.data
                });

                self.UpdatePercentData(index,"id_admin",data.data);

            }
            AppThis.PageLoad(false);

        }).catch((e)=>{

            self.UpdatePercentData(index,"id_admin",null);

            event.target.value='';

            toast("Lấy dữ liệu thất bại");

            AppThis.PageLoad(false);

            self.setState({
                ProfileInfo:null
            });

        });

    }


    Page2(){

        const self=this;
        return(

            <div className={"col-12"}>

                <div className={"alert alert-danger rounded-0 mb-4"}>
                    <i className="fas fa-exclamation-triangle" /> Vui lòng nhập đầy đủ thông tin
                </div>

                    {this.state.percent.data.map(function(item,index){
                        return(
                            <div className={"row pb-3 pt-3 border-bottom"}>

                                <div className={"col-12 col-md-12 col-lg-3 d-flex flex-row flex-wrap align-items-center mb-2"}>
                                    <label>
                                        <input type={'checkbox'} className={"form-check-input"} onChange={()=>{
                                            var type_update=!item.type;
                                            self.UpdatePercentData(index,'type',type_update);
                                            self.UpdatePercentData(index,'id_admin', null);
                                        }} /> Ngoài Club
                                    </label>

                                </div>

                                <div className={"col-12 col-md-6 col-lg-3 mb-2"}>

                                    {item.type===true?(
                                        <>
                                            {self.state.ListAdminClub!==null?(
                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){

                                                        var get_admin_select=get_item_in_array("key",selectedOption.value,self.state.ListAdminClub);

                                                        self.UpdatePercentData(index,'id_admin',get_admin_select);
                                                    }
                                                    else {
                                                        self.UpdatePercentData(index,'id_admin',null);
                                                    }

                                                }} options={array_to_select(self.state.ListAdminClub,'value','key')} />
                                            ):(
                                                <ItemLoading />
                                            )}

                                        </>
                                    ):(
                                        <>
                                            <input type={'text'} onKeyUp={(event)=>{
                                                if(event.keyCode===8){
                                                    self.UpdatePercentData(index,'id_admin',null);
                                                    event.target.value='';
                                                }
                                                else{
                                                    self.EventInputCode(index,event);
                                                }
                                            }} value={item.id_admin!==null?item.id_admin.code_user:null} defaultValue={item.id_admin!==null?item.id_admin.code_user:null}
                                                   className={"form-control w-100"} placeholder={"Mã nhân viên"} />

                                        </>
                                    )}

                                </div>

                                <div className={"col-12 col-md-6 col-lg-3 mb-2"}>

                                    {self.state.ListPercent!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                self.UpdatePercentData(index,'id_percent',selectedOption.value);
                                            }
                                            else {
                                                self.UpdatePercentData(index,'id_percent',null);

                                            }

                                        }} options={array_to_select(self.state.ListPercent,'name','_id')} />
                                    ):(
                                        <ItemLoading />
                                    )}


                                </div>

                                <div className={"col-12 col-md-6 col-lg-3 align-items-end d-flex flex-row mb-2"}>
                                    <a onClick={()=>{
                                        self.DeletePercentData(index);
                                    }} className={"text-black font16 me-3"}>
                                        <i className="fas fa-trash" />
                                    </a>

                                    <a onClick={()=>{
                                        self.AddPercentData();
                                    }} className={"text-black font16"}>
                                        <i className="text-black-50 fas fa-plus-circle" />
                                    </a>

                                </div>

                            </div>
                        );
                    })}


                    <div className={"row mt-5"}>

                        <div className={"col-12 col-sm-12 col-md-4 offset-md-8"}>
                            <a onClick={()=>{
                                this.SavePercentContract();
                            }} className={"btn btn-primary rounded-0 w-100"}>
                                <i className="fas fa-save me-1" /> Cập nhật
                            </a>

                        </div>

                    </div>



            </div>
        );
    }

}
