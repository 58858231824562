/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import DetailContract from "../../global/contract/DetailContract";
import Floater from "react-floater";
import lodash from "lodash";
import {formatNumber, timeConverterDate} from "../../helper/array";
import {number_did_contract, status_contract, status_payment} from "../../helper/member";
import EditGroupMember from "../../global/group_member/EditGroupMember";
import SessionHistory from "../history/SessionHistory";
import FingerPrintUser from "../finger/FingerPrintUser";
export default class DetailGroupMember extends Component {
    constructor(props) {
        super(props);

        this.state={
            data:null,
            DataDetailContract:null,
            page:0,
            query:{
                text_search:null
            },
            DataGroupMember:null,
            EnableEditGroup:null,
            MemberAction:{
                IdMemmber:null,
                action:null
            },
            UserFingerprint:null
        };

    }

    componentDidMount() {

        if(this.props.data){

            var DataGroupMember=lodash.cloneDeep(this.props.data);

            this.setState({
                DataGroupMember:DataGroupMember
            },function(){
                this.getData();
            });
        }

    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }
        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        formData.append('id_group', this.state.DataGroupMember._id);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/detail", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });

    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }


    RenderData(){
        const self=this;
        if(this.state.data.data.length>0){

            return(
              <div className={"row m-0"}>
                  <div className={"col-12"}>

                      <div className={"col-12 box-table-fix"}>

                      <div className={"overflow-auto"}>
                          <table className={"mt-3 w-100 table border-0 mw2700 table-style-old"}>
                              <thead>
                              <tr className={"border-bottom bg-light"}>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="far fa-user me-2"/> TÊN HỘI VIÊN</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i
                                          className="fas fa-mobile-alt me-2" /> SDT/ID</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="fas fa-shield-alt me-2" />
                                      MÃ KH</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="far fa-credit-card me-2" />
                                      DỊCH VỤ</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="far fa-file-excel me-2" />
                                      SỐ HD</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="far fa-question-circle me-2" />
                                      TRẠNG THÁI HD</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="fas fa-dollar-sign me-2" />
                                      GIÁ TRỊ HD</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="far fa-clock me-2" />
                                      NGÀY BẮT ĐẦU</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="fas fa-history me-2" />
                                      NGÀY KẾT THÚC</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="far fa-calendar-times me-2" />
                                      TỔNG SỐ BUỔI</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="far fa-calendar-times me-2" />
                                      TỔNG SỐ BUỔI CÒN LẠI</td>
                                  <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                      <i className="fas fa-user-edit me-2" />
                                      NHÂN VIÊN BÁN HÀNG</td>

                                  <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                      <i className="fas fa-directions me-2" />
                                      THAO TÁC</td>
                              </tr>
                              </thead>

                              <tbody>

                              {this.state.data.data.map(function(value,index){
                                  return(
                                      <tr>
                                          <td>
                                              <span>{value.id_user.username}</span>
                                          </td>
                                          <td>
                                              <span>{value.id_user.user_login}</span>
                                              {value.id_user.is_phone!==null?(
                                                  <>
                                                      <span className={"text-danger font-weight-bold font10 ms-2"}>{value.id_user.is_phone}</span>
                                                  </>
                                              ):(<></>)}


                                          </td>
                                          <td>
                                              <span>{value.id_user.code_user}</span>
                                          </td>
                                          <td>
                                              <span className={"me-2"}>{value.id_card.name}</span>
                                              {value.id_card.type===1?(<span className={"badge p-1 bg-primary"}>PT</span>):(
                                                  <></>
                                              )}

                                              {value.id_card.type===2?(<span className={"badge p-1 bg-primary"}>MB</span>):(
                                                  <></>
                                              )}
                                              {value.id_card.type===3?(<span className={"badge p-1 bg-warning"}>LH</span>):(
                                                  <></>
                                              )} {value.id_card.type===4?(<span className={"badge p-1 bg-primary"}>CD</span>):(
                                              <></>
                                          )}
                                          </td>

                                          <td>

                                              {value.group_member!==null?(
                                                  <i className="fas fa-check-circle text-primary me-1" />
                                              ):(<></>)}

                                              <span className={"me-1"}>{value.number_contract}</span>
                                              {status_payment(value)}
                                          </td>
                                          <td>
                                              {status_contract(value)}
                                              {typeof value.status_print!=='undefined' && value.status_print!==0?(
                                                  <i className="fas fa-print ms-2 text-success" />
                                              ):(
                                                  <></>
                                              )}
                                          </td>

                                          <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.info_contract_data.price_sale)} VNĐ
                                       </span>
                                          </td>

                                          <td>
                                              <span>{timeConverterDate(value.start_day)}</span>
                                          </td>

                                          <td>
                                              <span>{timeConverterDate(value.end_day_contract)}</span>
                                          </td>

                                          <td>
                                              {value.id_card.type!==2?(
                                                  <span>{value.info_contract_data.number_card}</span>
                                              ):(<></>)}

                                          </td>

                                          <td>

                                              {value.id_card.type!==2?(
                                                  <>
                                                      {number_did_contract(value)}
                                                  </>
                                              ):(<></>)}
                                          </td>

                                          <td>
                                              <span>{value.id_admin_create.username+ " - "+value.id_admin_create.code_user}</span>
                                          </td>

                                          <td className={"fix-item-table"}>
                                              <a onClick={()=>{
                                                  self.setState({
                                                      DataDetailContract:value
                                                  });
                                              }}  className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết hợp đồng">
                                                      <i className={"far fa-folder-open"}></i>
                                                  </Floater>
                                              </a>


                                              <a onClick={()=>{
                                                  self.setState({
                                                      MemberAction:{
                                                          IdMemmber:value,
                                                          action:'history'
                                                      }
                                                  });
                                              }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lịch sử tập luyện">
                                                      <i className={"fas fa-history"} />
                                                  </Floater>
                                              </a>


                                              <a onClick={()=>{
                                                  self.setState({
                                                      UserFingerprint:value.id_user
                                                  });
                                              }} className={"rounded-0 font12 d-inline-block btn btn-outline-warning p-2 me-1"}>
                                                  <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Vân tay KH">
                                                      <i className="fas fa-fingerprint" />
                                                  </Floater>
                                              </a>



                                          </td>

                                      </tr>
                                  );
                              })}
                              </tbody>
                          </table>

                      </div>

                      </div>



                      {this.state.DataDetailContract!==null?(
                          <DetailContract OnClose={()=>{
                              this.setState({
                                  DataDetailContract:null
                              });

                          }}  EventDetail={(id)=>{
                              this.setState({
                                  DataDetailContract:null
                              },function(){
                                  self.getDetailContract(id);
                              });
                          }} data={this.state.DataDetailContract} />
                      ):(
                          <></>
                      )}



                      <div className={"col-12 p-3 mt-3 mb-3"}>
                          <ul className="pagination">
                              {this.state.data.prev>=0?(
                                  <li className="page-item" >
                                      <a className="page-link" onClick={()=>{
                                          var page=this.state.page-1;
                                          this.setState({
                                              page:page
                                          },function(){
                                              self.getData(true);
                                          });
                                      }}>
                                          Trước
                                      </a>
                                  </li>
                              ):(<></>)}

                              <li className="page-item">
                                  <a className="page-link">
                                      <span>{this.state.data.curent+1}</span>
                                  </a></li>

                              {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                  <li className="page-item">
                                      <a className="page-link" onClick={()=>{
                                          this.setState({
                                              page:this.state.data.next
                                          },function(){
                                              self.getData(true);
                                          });
                                      }}>
                                          Kế tiếp
                                      </a>
                                  </li>
                              ):(<></>)}

                          </ul>
                      </div>




                  </div>
              </div>
            );
        }
        else{
            return(
                <div className={"col-12 p-3"}>
                    <div className={"alert mt-3 rounded-0 alert-danger"}>
                        Không có dữ liệu
                    </div>
                </div>
            );
        }
    }

    render(){
        const self=this;

        if(this.state.MemberAction.IdMemmber!==null){

            switch (this.state.MemberAction.action) {
                case "history":
                    return(<SessionHistory onClose={()=>{

                        self.setState({
                            MemberAction:{
                                IdMemmber:null,
                                action:null
                            }
                        });

                    }} AppThis={this.props.AppThis} path={this.props.path} data={this.state.MemberAction.IdMemmber} />);


            }

        }
        else{

            return(
                <>
                    <div className="modal d-block position-fixed">
                        <div className={"modal-dialog modal-lg modal-fullscreen"}>

                            <div className="modal-content border-0 bg-light">

                                <div className="modal-header shadow">

                                    <div className={"container"}>

                                        <div className={"row"}>
                                            <div className={"col-8"}>
                                                <strong className={"font18 text-white text-black text-uppercase"}>
                                                    CHI TIẾT HD DOANH NGHIỆP
                                                </strong>
                                            </div>

                                            <div className={"col-4 d-flex justify-content-end align-items-center"}>

                                                {this.state.DataGroupMember!==null?(
                                                    <a onClick={()=>{
                                                        var EnableEditGroup=lodash.cloneDeep(this.state.DataGroupMember);

                                                        this.setState({
                                                            EnableEditGroup:EnableEditGroup
                                                        });

                                                    }}>
                                                        <i className="far text-white fa-edit me-1 me-3 font26" />
                                                    </a>
                                                ):(<></>)}


                                                <span onClick={()=>{
                                                    self.props.onClose();
                                                }} className={"text-white right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <div className={"col-12 container"}>

                                    <div className={"col-12 pt-2 pb-3 pt-4 shadow bg-white"}>



                                        {this.state.DataGroupMember!==null?(
                                            <div className={"row m-0 mt-4 mb-3"}>

                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 mb-3"}>

                                                    <div className={"col-12 d-flex flex-row ps-3 pe-3 pb-3 bg-white pt-2 shadow box-item-dashboard"}>

                                                        <div className={"left-item me-3 text-center d-flex justify-content-center align-items-center font13"}>
                                                            <i className={"fas font26 fa-user d-flex justify-content-center align-items-center text-white rounded-circle bg-danger"} />
                                                        </div>

                                                        <div className={"right-item"}>

                                                            <p className={"text-uppercase mt-2 mb-0 text-dark font14 font-weight-bold"}>Người đứng tên</p>
                                                            <p className={"m-0 font13"}>
                                                                {this.state.DataGroupMember.id_user.username+ " - "+this.state.DataGroupMember.id_user.code_user}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 mb-3"}>

                                                    <div className={"col-12 d-flex flex-row ps-3 pe-3 pb-3 bg-white pt-2 shadow box-item-dashboard"}>

                                                        <div className={"left-item me-3 text-center d-flex justify-content-center align-items-center font13"}>
                                                            <i className={" font26 far fa-clock d-flex justify-content-center align-items-center text-white rounded-circle bg-success"} />
                                                        </div>

                                                        <div className={"right-item"}>

                                                            <p className={"text-uppercase mt-2 mb-0 text-dark font14 font-weight-bold"}>Ngày tạo</p>
                                                            <p className={"m-0 font13"}>
                                                                {timeConverterDate(this.state.DataGroupMember.time_create)}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 mb-3"}>

                                                    <div className={"col-12 d-flex flex-row ps-3 pe-3 pb-3 bg-white pt-2 shadow box-item-dashboard"}>

                                                        <div className={"left-item me-3 text-center d-flex justify-content-center align-items-center font13"}>
                                                            <i className={"font26 fas fa-lock d-flex justify-content-center align-items-center text-white rounded-circle bg-primary"} />
                                                        </div>

                                                        <div className={"right-item"}>

                                                            <p className={"text-uppercase mt-2 mb-0 text-dark font14 font-weight-bold"}>Người tạo</p>
                                                            <p className={"m-0 font13"}>
                                                                {this.state.DataGroupMember.id_admin_create.username+ " - "+this.state.DataGroupMember.id_admin_create.code_user}
                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        ):(<></>)}





                                        {this.state.data!==null?(this.RenderData()):(
                                            <div className={"col-12 text-center d-flex flex-row justify-content-center mt-5 mb-5"}>
                                                <ItemLoading />
                                            </div>
                                        )}



                                    </div>

                                    {this.state.EnableEditGroup!==null?(
                                        <EditGroupMember onClose={()=>{
                                            this.setState({
                                                EnableEditGroup:null
                                            },function(){

                                            });
                                        }} AppThis={this.props.AppThis} path={this.props.path} data={this.state.EnableEditGroup} />
                                    ):(
                                        <></>
                                    )}



                                </div>

                            </div>


                        </div>
                    </div>
                    {this.state.UserFingerprint!==null?(
                        <FingerPrintUser onClose={()=>{
                            this.setState({
                                UserFingerprint:null
                            },function(){
                                self.getData(true);
                            });
                        }}  AppThis={this.props.AppThis} path={this.props.path} data={this.state.UserFingerprint} />
                    ):(
                        <></>
                    )}
                </>
            );
        }



    }



}
