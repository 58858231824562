/* eslint-disable */
import React,{Component} from "react";
export default class Breadcrumb extends Component {

    render(){
        return(
            <div className="col-12 p-0">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)"><i className="fas fa-home"/></a></li>
                        <li aria-current="page" className="breadcrumb-item active">
                            {this.props.title}
                        </li>
                    </ol>
                </nav>

                <div className="col-12 mb-4 bg-white mt-3 ps-3 pt-3 text-uppercase font-weight-bold pb-3 border-bottom font17">
                    {this.props.title}
                </div>

            </div>
        );
    }


}
