/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    array_to_select,
    get_data_in_args, ArrayInArray, base64_encode
} from "../../helper/array";
import InputMask from "react-input-mask";
import Select from 'react-select';
import {toast} from "react-toastify";
import DateTimePicker from 'react-datetime-picker';
import {confirmAlert} from "react-confirm-alert";
import lodash from "lodash";
export default class NewPush extends Component{

    constructor(props) {
        super(props);

        this.state= {
            user:null,
            Source:null,
            Address:null,
            Subject:null,
            ModalLoading:false,
            DataUser:{
                user_login:null,
                source:[],
                sex:0,
                username:null,
                code_user:null,
                id_admin:null
            },
            DataPush:{
                id_address:null,
                id_subject:null,
                note:null,
                id_admin:null
            }
        };

    }

    componentDidMount() {

        this.getSource();

        this.getAddress();

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getSource(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('type',1);

        fetch(AppThis.ConstApp.home_api+"source", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{

            this.setState({
                Source:data.data
            });

        });

    }

    getAddress(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('type',3);

        fetch(AppThis.ConstApp.home_api+"address", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{

            this.setState({
                Address:data.data
            });

        });

    }

    getSubject(){

        this.setState({
            Subject:null
        });

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address',this.state.DataPush.id_address);

        fetch(AppThis.ConstApp.home_api+"subject", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{

            this.setState({
                Subject:data.data
            });

        });

    }

    UpdateNameDataUser(name,value){

        var DataUser=this.state.DataUser;

        DataUser[name]=value;

        this.setState({
            DataUser:DataUser
        });

    }
    UpdateNameDataPush(name,value){

        var DataPush=this.state.DataPush;

        DataPush[name]=value;

        this.setState({
            DataPush:DataPush
        });

    }

   async VerifyPhoneNumber(name,value){

        const item=value.replaceAll("_","");

        this.UpdateNameDataUser(name,item);

        if(typeof this.state.DataUser.user_login!=='undefined' && this.state.DataUser.user_login.length===10){

            this.setState({
                ModalLoading:true
            });

            const formData = new FormData();

            var AppThis=this.props.AppThis;

            formData.append('token',AppThis.getToken());

            formData.append('phone',this.state.DataUser.user_login);

            var get_data=await fetch(AppThis.ConstApp.home_api+this.props.path+"/getuser", {
                method: 'POST',
                body: formData
            });

             get_data=await get_data.json();

            this.setState({
                ModalLoading:false
            });


            if(get_data.status==='ok'){
                var data_current=this.state.DataUser;

                if(Object.keys(get_data['data']).length>0){
                    data_current.username=get_data.data['username']?get_data.data['username']:get_data.data['tmp_user'];
                    data_current.sex=get_data.data['sex'];
                    data_current.source=get_data_in_args(get_data.data['source'],'_id');

                    this.setState({
                        DataUser:data_current,
                        user:lodash.cloneDeep(get_data.data)
                    });
                }
                else{
                    data_current.username=null;
                    data_current.sex=0;
                    data_current.source=[];
                    this.setState({
                        DataUser:data_current,
                        user:lodash.cloneDeep(get_data.data)
                    });

                    this.setState({
                        DataUser:data_current,
                        user:null
                    });
                }

            }
        }

    }

    UpdateUserData(){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('data', base64_encode(this.state.DataUser));

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/add", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{
            if(data.status==='error'){
                toast("Thao tác thất bại");
                Appthis.PageLoad(false);
                self.props.onClose();
            }
            else{
                this.UpdatePushData(data.data);
            }

        }).catch((e)=>{
            toast("Thao tác thất bại");
            Appthis.PageLoad(false);
        });

    }

    UpdatePushData(data_user){

        const self=this;

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('data', base64_encode(this.state.DataPush));

        formData.append('id_user', data_user._id);

        fetch(Appthis.ConstApp.home_api+this.props.path+"/push", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{
            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công.Dữ liệu đã gửi tới máy chủ");
            }

            Appthis.PageLoad(false);

            self.props.onClose();

        }).catch((e)=>{
            toast("Thao tác thất bại");
            Appthis.PageLoad(false);
            self.props.onClose();
        });

    }


    SubmitModal(){

        try{

            var DataUser=this.state.DataUser;

            var DataPush=this.state.DataPush;

            var number_error=0;

            if(typeof DataUser.user_login==='undefined' || DataUser.user_login===null || DataUser.user_login.length!==10){
                number_error+=1;
            }

            if(typeof DataUser.username==='undefined' || DataUser.username===null || DataUser.username.length<3){
                number_error+=1;
            }

            if(typeof DataUser['source']==='undefined' || DataUser['source']===null || DataUser['source'].length===0){
                number_error+=1;
            }

            ['id_subject'].map(function (value){
                if(typeof DataPush[value]==='undefined' || DataPush[value]===null || DataPush[value].length===0){
                    number_error+=1;
                }
            });

            if(typeof DataPush['id_address']==='undefined' || DataPush['id_address']===null){
                number_error+=1;
            }

            if(number_error>0){
                toast("Vui lòng kiểm tra lại");
            }
            else{

                const self=this;

                if(this.state.user!==null && this.state.user.is_marketing===true){

                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className='custom-ui'>
                                    <h1>Cảnh báo !</h1>
                                    <p>Một thành viên đã sở hữu SDT này bạn có chắc chắn muốn chuyển ?</p>
                                    <button onClick={()=>{
                                        onClose();
                                    }}>Không</button>
                                    <button
                                        onClick={() => {
                                            self.UpdateUserData();
                                            onClose();
                                        }}
                                    > Có.Tôi chắc chắn
                                    </button>
                                </div>
                            );
                        }
                    });

                }
                else{
                    self.UpdateUserData();

                }

            }
        }
        catch ($e){

        }
    }


    render() {
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Thông báo đẩy</strong>
                        </div>

                        {this.state.ModalLoading===true?(
                            <div className={"ModalLoading left0 top-0 position-absolute h-100 w-100 d-flex justify-content-center align-items-center"}>
                                <ItemLoading />
                            </div>
                        ):(<></>)}

                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2 InputMask"}>
                                    <label className={"mb-2"}>SDT/ID <span className={"text-red"}>( * )</span>
                                    </label>

                                    {<InputMask mask="9999999999" onChange={event=>{
                                        this.VerifyPhoneNumber('user_login',event.target.value);
                                    }} key={"user_login"} />}

                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    <label className={"mb-2"}>Họ và tên  <span className={"text-red"}>( * )</span></label>

                                    {this.state.user!==null && this.state.user.username!==null?(
                                          <div className={"is-verify-data"}>
                                              <input type={"text"} className={"form-control"} defaultValue={this.state.DataUser.username} placeholder={"Họ và tên"} />
                                          </div>

                                    ):(
                                        <input onChange={(event)=>{
                                            this.UpdateNameDataUser("username",event.target.value);
                                        }}  type={"text"} className={"form-control"} placeholder={"Họ và tên"} />
                                    )}
                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    <label className={"mb-2"}>Nguồn  <span className={"text-red"}>( * )</span></label>
                                    <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                        {this.state.Source!==null?(

                                                this.state.DataUser.source.length===0?(
                                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                                            var update_select=[];
                                                            selectedOption.map(function(item){

                                                                    update_select.push(item.value);

                                                            });
                                                            var DataUser=this.state.DataUser;
                                                            DataUser.source=update_select;
                                                            self.setState({
                                                                DataUser:DataUser
                                                            });
                                                        }}  isMulti key={"source1"} options={array_to_select(this.state.Source,'name','_id')} />

                                                    ):(
                                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                                            var update_select=[];
                                                            selectedOption.map(function(item){
                                                                    update_select.push(item.value);
                                                            });
                                                            var DataUser=this.state.DataUser;
                                                            DataUser.source=update_select;
                                                            self.setState({
                                                                DataUser:DataUser
                                                            });
                                                        }}  isMulti key={"source2"} defaultValue={ArrayInArray(this.state.DataUser.source,array_to_select(this.state.Source,'name','_id'))} options={array_to_select(this.state.Source,'name','_id')} />
                                                    )

                                        ):(
                                            <ItemLoading />
                                        )}
                                    </div>
                                </div>


                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    <label className={"mb-2"}>Giới tính</label>


                                        {this.state.DataUser.sex===1?(
                                            <div className="col-12 p-0 mt-2 d-flex flex-row">
                                        <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateNameDataUser("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" className={"me-1"} checked="checked" /> Nam
                                      </span>


                                     <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateNameDataUser("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0"  className={"me-1"}  /> Nữ
                                        </span>

                                            </div>
                                        ):(
                                            <div className="col-12 p-0 mt-2 d-flex flex-row">
                                        <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateNameDataUser("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" className={"me-1"} /> Nam
                                      </span>


                                     <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateNameDataUser("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" className={"me-1"}  /> Nữ
                                        </span>

                                            </div>

                                        )}


                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    <label className={"mb-2"}>Địa chỉ  <span className={"text-red"}>( * )</span></label>
                                    <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                        {this.state.Address!==null?(
                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                var DataPush=this.state.DataPush;
                                                DataPush["id_address"]=selectedOption.value;
                                                DataPush["id_subject"]=[];
                                                this.setState({
                                                    DataPush:DataPush
                                                },function(){
                                                    self.getSubject();
                                                });
                                            }}   options={array_to_select(this.state.Address,'name','_id')} />
                                        ):(
                                            <ItemLoading />
                                        )}
                                    </div>
                                </div>



                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    <label className={"mb-2"}>Bộ môn  <span className={"text-red"}>( * )</span></label>
                                    <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                        {this.state.Subject!==null?(
                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                var update_select=[];
                                                selectedOption.map(function(item){
                                                    update_select.push(item.value);
                                                });
                                                var DataPush=this.state.DataPush;
                                                DataPush.id_subject=update_select;
                                                self.setState({
                                                    DataPush:DataPush
                                                });


                                            }} isMulti={true}  options={array_to_select(this.state.Subject,'name','_id')} />
                                        ):(
                                            <select className={"form-control w-100 rounded-0 shadow-none"}></select>
                                        )}
                                    </div>
                                </div>


                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    <label className={"mb-2"}>Nhân viên</label>
                                    <input type={"text"} className={"form-control"} onChange={(event)=>{
                                        this.UpdateNameDataPush("id_admin",event.target.value);
                                        this.UpdateNameDataUser("id_admin",event.target.value);
                                    }} placeholder={"Mã nhân viên"} />
                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    <label className={"mb-2"}>Ghi chú </label>
                                    <input type={"text"} className={"form-control"} onChange={(event)=>{
                                        this.UpdateNameDataPush("note",event.target.value);
                                    }} placeholder={"Ghi chú"} />
                                </div>

                            </div>



                        </div>

                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.SubmitModal();
                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                Cập nhật
                                            </a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
