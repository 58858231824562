/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {array_to_select, base64_encode, formatNumber, getItemInArray, timeConverterDate} from "../../helper/array";
import DetailOrder from "../../global/order/DetailOrder";
import Floater from 'react-floater';
import DetailContract from "../../global/contract/DetailContract";
import DatePicker from "react-datepicker";
import Select from "react-select";

const INIT_STATE={
    data:null,
    query:{
        id_order:null,
        input_search:null,
        number_contract:null,
        id_admin:null,
        start_time:null,
        end_time:null,
    },
    page:0,
    DataDetailOrder:null,
    DataDetailContract:null,
    SearchAdvanced:false
};

export default class corders extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"corders/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=data.data;

                ListAdmin.unshift({
                    key:null,
                    value:"Tất cả"
                });
                self.setState({
                    ListAdmin:ListAdmin
                });

            }


        }).catch((e)=>{

        });


    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"corders", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{
            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {
        this.props.AppThis.SetActionModule(this);
        this.getData();
        this.getAdmin();
    }

    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-clock me-1" />
                                Thời gian</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-user" />
                                Nhân viên</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-file-contract me-1" />
                                Số HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-code me-1" />
                                Mã Đơn hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-user me-1" />
                                Khách hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-money-bill-alt me-1" />
                                Tổng tiền</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                <i className="fab fa-artstation me-1" />
                                Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>

                                    <td>{timeConverterDate(value['time_create'])}</td>
                                    <td>{value['id_admin']['username']+" - "+value['id_admin']['code_user']}</td>
                                    <td>{value['id_member']['number_contract']}</td>
                                    <td>{value['id_order']}</td>
                                    <td>{value['id_user']['user_login']}</td>
                                    <td>
                                        <span className={"text-red font-weight-bold"}>{formatNumber(value['price'])} VNĐ</span>
                                    </td>
                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.setState({
                                                DataDetailContract:value.id_member
                                            });
                                        }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết hợp đồng">
                                                <i className={"far fa-folder-open"}></i>
                                            </Floater>
                                        </a>

                                        <a onClick={()=>{
                                            self.setState({
                                                DataDetailOrder:value
                                            });
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xem chi tiết đơn hàng">
                                                <i className="far fa-eye" />
                                            </Floater>
                                        </a>

                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                </div>
                </div>

            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;
        if(this.state.data!==null){
            return(
                <div className={"container-fluid container-main-template mb-5"}>

                    <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0 pt-3">

                            <div className="col-12 col-sm-12 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý đơn hàng</h3>
                            </div>

                            <div className="col-12 col-sm-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>




                                        <input onChange={(event)=>{
                                            var query=this.state.query;
                                            query.id_order=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} autoComplete={"off"} type="text" name="input_search"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="ID đơn hàng"/>

                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>


                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:true,
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                            <i className="fas fa-search-plus" /> Tìm kiếm nâng cao </a>





                                    </div>

                                </div>




                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>


                    </div>


                    {this.state.DataDetailOrder!==null?(
                        <DetailOrder onClose={()=>{
                            this.setState({
                                DataDetailOrder:null
                            });
                        }} data={this.state.DataDetailOrder} />
                    ):(
                        <></>
                    )}


                    {this.state.DataDetailContract!==null?(
                        <DetailContract OnClose={()=>{
                            this.setState({
                                DataDetailContract:null
                            });

                        }} EventDetail={(id)=>{
                            this.setState({
                                DataDetailContract:null
                            });
                        }} data={this.state.DataDetailContract} />
                    ):(
                        <></>
                    )}


                    {this.state.SearchAdvanced===true?(
                        this.SearchAdvanced()
                    ):(<></>)}


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }


    SearchAdvanced(){

        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm nâng cao</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row box_input"}>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian bắt đầu"} />

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Tên khách hàng/SDT</p>
                                    <input defaultValue={this.state.query.input_search} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("input_search",event.target.value);
                                    }} className={"form-control"} placeholder={"Tên khách hàng/SDT"} />
                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số HD</p>
                                    <input defaultValue={this.state.query.number_contract} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("number_contract",event.target.value);
                                    }} className={"form-control"} placeholder={"Số HD"} />
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>NV bán hàng</p>

                                    {self.state.ListAdmin!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_admin=selectedOption.value;
                                            self.UpdateQuerySearch("id_admin",id_admin);
                                        }} defaultValue={this.state.query.id_admin!==null?getItemInArray(this.state.query.id_admin,"value",array_to_select(this.state.ListAdmin,"value","key")):null} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                    ):(
                                        <ItemLoading />
                                    )}


                                </div>


                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"row"}>


                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                self.getData(true);

                                                self.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-danger w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }
}
