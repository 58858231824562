/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "./ItemLoading";
import {
    convert_object_to_array, uniq_array
} from "../helper/array";
import {toast} from "react-toastify";
import parse from "html-react-parser";

export default class EditModule extends Component{

    constructor(props) {
        super(props);

        this.state= {
            DataModal: null,
            AllModule:null,
            DataResponse:null
        };

    }

    componentDidMount() {
        this.getModule(this.props.data._id);
        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }
    getModule(_id){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('_id',_id);

        fetch(AppThis.ConstApp.home_api+this.props.path, {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{

            this.setState({
                DataResponse:data.data.current,
                AllModule:data.data
            });

        });

    }

    UpdateDataResponse(event){

        var DataResponse=this.state.DataResponse;

        if(event.target.checked===true){
            DataResponse.push(event.target.value);
        }
        else{
            DataResponse = DataResponse.filter(item => item !== event.target.value)
        }

        DataResponse=uniq_array(DataResponse);


        this.setState({
            DataResponse:DataResponse
        });


    }

    renderData(item=null){

        const self=this;

        if(item!==null && this.state.AllModule!==null){

            return(
                <div className={"col-12 Edit-Module"}>

                    {convert_object_to_array(this.state.AllModule['total']).map(function(item,index){
                        return(
                            <div className="row mt-3 list-modules">
                                <div className="col-12 group-module">
                                    <div className="alert alert-dark rounded-0 font16 font-weight-bold">
                                        <span>{parse(item.key)}</span>
                                    </div>

                                    <div className="row">

                                        {item['value'].map(function (value,key){
                                            return(
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <div className="col-12 border ps-2 mb-3 pt-3 pb-2">
                                                        {self.state.AllModule.current.indexOf(value._id)!==-1?(
                                                            <label>
                                                                <input defaultChecked={true} type="checkbox" onChange={(event)=>{
                                                                    self.UpdateDataResponse(event);
                                                                }} value={value._id} />
                                                                <span className={"ms-1"}>{parse(value.title)}</span>
                                                            </label>
                                                        ):(
                                                            <label>
                                                                <input type="checkbox" onChange={(event)=>{
                                                                    self.UpdateDataResponse(event);
                                                                }} value={value._id} />
                                                                <span className={"ms-1"}>{parse(value.title)}</span>
                                                            </label>
                                                        )}

                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            </div>
                        );
                    })}



                </div>
            );
        }
        else{
            return(
                <div className={"col-12 justify-content-center align-content-center d-flex"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }



    }

    SubmitModal(){

        if(this.state.DataResponse!==null){
            this.props.GetDataSubmit(this.state.DataResponse);
        }
        else{
            toast("Vui lòng chờ");
        }
    }

    render() {

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-dialog-scrollable modal-fullscreen"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                          <div className={"container"}>
                              <strong className={"font18 text-uppercase"}>Cập nhật quyền hạn thành viên</strong>
                          </div>
                        </div>
                        <div className="modal-body">

                            <div className={"col-12 mb-2 mt-2"}>

                                <div className={"container"}>
                                    {this.props.data!==null?(this.renderData(this.props.data)):(
                                        <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                            <ItemLoading size={"Large"} />
                                        </div>
                                    )}
                                </div>

                            </div>


                        </div>

                        <div className={"modal-footer w-100 bg-light"}>

                            <div className={"container m-auto"}>

                                <div className={"row"}>
                                    <div className={"col-12 col-sm-12 col-lg-6 offset-lg-6"}>


                                        <div className={"row"}>

                                            <div className={"col-12 col-sm-12 col-lg-6"}>
                                                <a onClick={()=>{
                                                    this.SubmitModal();
                                                }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                    Cập nhật
                                                </a>
                                            </div>
                                            <div className={"col-12 col-sm-12 col-lg-6"}>
                                                <a onClick={()=>{
                                                    this.props.onClose()
                                                }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                    Huỷ
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
