/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {base64_encode, getSourcePush, timeConverter} from "../../helper/array";
import DetailPush from "../../global/push/DetailPush";
import Floater from 'react-floater';
import CanvasJSReact from '../../helper/canvasjs.react';
import {confirmAlert} from "react-confirm-alert";
import DatePicker from "react-datepicker";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SOURCE_PUSH=[
    {
        _id:null,
        name:"Tất cả"
    },
    {
        _id:0,
        name:"Web"
    },
    {
        _id:1,
        name:"Google Sheet"
    },
    {
        _id:2,
        name:"App Mobile"
    }
];

const INIT_STATE={
    data:null,
    query:{
        start_time:null,
        end_time:null,
        id_statics:null,
        id_address:null,
        input_search:null,
        source_push:null
    },
    DetailPush:{
        status:false,
        data:null
    },
    statics:{
        push:null,
        static:null
    },
    page:0,
    SearchAdvanced:false,
    ListStatics:null,
    ListAddress:null
};
export default class mystoragenotification extends Component {
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }
    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    UpdateQuerySearch(key,value){
        var query=this.state.query;
        query[key]=value;
        this.setState({
            query:query
        });
    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        const self=this;

        fetch(Appthis.ConstApp.home_api+"mystoragenotification", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getStatics();

        this.GetListStatics();

        this.getListAddress();

    }

    async GetListStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        var get_data=await fetch(Appthis.ConstApp.home_api+"statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        if(get_data.status==='ok'){


            var ListStatics=get_data.data;

            ListStatics.unshift({
                _id:'none',
                name:"Tất cả"
            });

            this.setState({
                ListStatics:ListStatics
            });

        }



    }

    async getListAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type',3);


        var get_data=await fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        if(get_data.status==='ok'){


            var ListAddress=get_data.data;

            ListAddress.unshift({
                _id:'none',
                name:"Tất cả"
            });

            this.setState({
                ListAddress:ListAddress
            });

        }
    }
    getStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        var data_query=this.getQuerySearch();

        if(Object.keys(data_query).length>0){
            formData.append('query', base64_encode(data_query));
        }
        fetch(Appthis.ConstApp.home_api+"mystoragenotification/mystatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            self.SetUpStaticsMonthPush(data.data);

            self.SetUpStaticsMonth(data.data);

        }).catch((e)=>{


        });


    }

    SetUpStaticsMonth(data){

        var options_share = {
            animationEnabled: true,
            data: [{
                type: "pie",
                startAngle: 20,
                toolTipContent: "<b>{label}</b>: {y}%",
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 12,
                indexLabel: "{label} - {y}%",
                dataPoints: data.data_statics
            }]
        };

        var statics=this.state.statics;

        statics.static=options_share;

        this.setState({
            statics:statics
        });


    }

    SetUpStaticsMonthPush(data) {
        var data_report_push = [];


        var pagination_data_report = data.report_user_by_day;

        Object.entries(pagination_data_report).forEach(entry => {
            const [key, value] = entry;

            data_report_push.push({
                x:new Date(key * 1000),
                y:value.push
            });


        });

        const options = {
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Đẩy số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_push
                }

            ]
        };

        var statics=this.state.statics;

        statics.push=options;

        this.setState({
            statics:statics
        });


    }

    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 mw1600 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Họ tên</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Nguồn gửi</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 mw150px"}>Tên địa chỉ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Bộ môn</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Thời gian</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Người nhận</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Số lần gọi</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>

                                    <td>
                                        {value.id_user['username']?value.id_user['username']:value.id_user['tmp_user']}
                                    </td>
                                    <td>{getSourcePush(value)}</td>
                                    <td> {value['id_user']['user_login']}

                                        {value['id_user']['is_phone']!==null?(
                                            <>
                                                <span className={"text-danger font-weight-bold font10 ms-2"}>{value['id_user']['is_phone']}</span>
                                            </>
                                        ):(<></>)}

                                    </td>

                                    <td>
                                        {value['id_address']['name']}
                                    </td>
                                    <td>
                                        {value['id_subject']?(
                                            <span>{self.getNameSubject(value.id_subject)}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </td>


                                    <td>{timeConverter(value['time_create'])}</td>

                                    <td>{value['id_statics']!==null?value['id_statics']['name']:(
                                        <span className={"badge p-1 font10 bg-warning"}>Chưa rõ</span>
                                    )}</td>


                                    <td>
                                        {value.id_admin_push!==null?(
                                            <span>
                                                {value.id_admin_push.username+" - "+value.id_admin_push.code_user}
                                            </span>
                                        ):(<span className={"badge bg-danger"}>Chưa rõ</span>)}
                                    </td>
                                    <td>
                                        {value.number_call}
                                    </td>

                                    <td className={"fix-item-table"}>

                                        <div className={"col-12 pt-1"}>
                                            <a onClick={()=>{

                                                self.setState({
                                                    DetailPush:{
                                                        status:true,
                                                        data:value
                                                    }
                                                });

                                            }} className={"p-2 btn btn-primary rounded-0"}>
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xem chi tiết">
                                                    <i className="fas fa-info-circle" />
                                                </Floater>
                                            </a>

                                            <a onClick={()=>{
                                                self.AlertRemoveItem(value);

                                            }} className={"p-2 btn btn-danger ms-1 rounded-0"}>
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Thu hồi">
                                                    <i className="fas fa-minus" />
                                                </Floater>
                                            </a>
                                        </div>


                                    </td>


                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div className={"col-12 ps-3 pe-3"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    render(){

        const self=this;

        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold">
                                   Tình trạng chuyển số
                                </h3>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>


                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>



                                        <input onChange={(event)=>{
                                            var query=this.state.query;
                                            query.input_search=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} autoComplete={"off"} type="text" name="input_search"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Số điện thoại"/>

                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>


                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:true,
                                                ModalSearchMobile:false
                                            })
                                        }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm nâng cao</a>



                                    </div>
                                </div>


                            </div>

                        </div>


                        <div className={"row m-0 mb-4"}>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>

                                <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                    30 ngày gần nhất
                                </div>

                                {this.state.statics.push!==null?(
                                    <CanvasJSChart options={this.state.statics.push} />
                                ):(
                                    <div className={"col-12 text-center d-flex justify-content-center"}>
                                        <ItemLoading />
                                    </div>
                                )}
                            </div>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-4"}>
                                <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                    TÌNH TRẠNG KHÁCH HÀNG
                                </div>

                                {this.state.statics.static!==null?(
                                    <CanvasJSChart options={this.state.statics.static} />
                                ):(
                                    <div className={"col-12 text-center d-flex justify-content-center"}>
                                        <ItemLoading />
                                    </div>
                                )}



                            </div>


                        </div>


                        <div className={"col-12 ps-3 pe-3 mt-5"}>
                            {this.thisRenderData()}
                        </div>

                        {this.state.DetailPush.status===true?(
                            <DetailPush data={this.state.DetailPush.data} onClose={()=>{
                                this.setState({
                                    DetailPush:{
                                        status:false,
                                        data:null
                                    }
                                });

                            }} AppThis={this.props.AppThis} />
                        ):(<></>)}


                    </div>



                    {this.state.SearchAdvanced===true?(
                        this.SearchAdvanced()
                    ):(
                        <></>
                    )}




                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


    SearchAdvanced(){

        const self=this;

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2 box_input"}>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Trạng thái</p>


                                    {this.state.ListStatics!==null?(
                                        <select onChange={(event)=>{
                                            this.UpdateQuerySearch("id_statics",event.target.value);

                                        }} className={"form-control w-100 rounded-0"}>
                                            {this.state.ListStatics.map(function(item){

                                                if(item._id===self.state.query.id_statics){
                                                    return(
                                                        <option selected={true} value={item._id}>{item.name}</option>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                }

                                            })}
                                        </select>
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Địa chỉ</p>

                                    {this.state.ListAddress!==null?(
                                        <select onChange={(event)=>{

                                            this.UpdateQuerySearch("id_address",event.target.value);


                                        }} className={"form-control w-100 rounded-0"}>
                                            {this.state.ListAddress.map(function(item){

                                                if(item._id===self.state.query.id_address){
                                                    return(
                                                        <option selected={true} value={item._id}>{item.name}</option>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                }

                                            })}
                                        </select>
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Ngày bắt đầu</p>

                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        var tmp_time=null;
                                        if(date!==null){
                                            tmp_time=date.getTime()/1000;
                                        }
                                        this.UpdateQuerySearch('start_time',tmp_time);
                                    }} selected={this.state.query.start_time!==null?new Date(this.state.query.start_time*1000):null} placeholderText={"Ngày bắt đầu"} />


                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Ngày kết thúc</p>


                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        var tmp_time=null;
                                        if(date!==null){
                                            tmp_time=date.getTime()/1000;
                                        }
                                        this.UpdateQuerySearch('end_time',tmp_time);
                                    }} selected={this.state.query.end_time!==null?new Date(this.state.query.end_time*1000):null} placeholderText={"Ngày kết thúc"} />

                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Nguồn gửi</p>
                                    <select className={"form-control rounded-0"} onChange={(event)=>{
                                        this.UpdateQuerySearch("source_push",parseInt(event.target.value));
                                    }}>
                                        {SOURCE_PUSH.map(function(item){
                                            if(self.state.query.source_push===item._id){
                                                return(
                                                    <option selected={true} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            }
                                            else{
                                                return(
                                                    <option value={item._id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            }

                                        })}

                                    </select>
                                </div>





                            </div>

                        </div>


                        <div className={"modal-footer w-100 bg-light"}>


                            <div className={"col-12 col-sm-12 col-md-6 offset-md-8"}>

                                <div className={"row w-100"}>

                                    <div className={"col-12 col-sm-12 col-lg-4"}>
                                        <a onClick={()=>{

                                            this.setState({
                                                SearchAdvanced:false,
                                                statics:{
                                                    push:null,
                                                    static:null
                                                }
                                            },function(){
                                                self.getData(true);
                                                self.getStatics();
                                            });


                                        }} className={"btn btn-primary w-100 text-white mt-3"} >
                                            Tìm kiếm
                                        </a>
                                    </div>

                                    <div className={"col-12 col-sm-12 col-lg-4"}>
                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:false,
                                                query:{
                                                    start_time:null,
                                                    end_time:null,
                                                    id_statics:null,
                                                    id_address:null,
                                                    input_search:null
                                                }
                                            },function(){
                                                this.getData(true);
                                            });

                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                            Xoá phiên
                                        </a>
                                    </div>

                                    <div className={"col-12 col-sm-12 col-lg-4"}>
                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:false
                                            });

                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                            Đóng
                                        </a>
                                    </div>

                                </div>
                            </div>


                        </div>



                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }


    getNameSubject(arg){
        if(arg.length>0){
            return arg.map(function(item){
                return(
                    <span className={"badge p-1 bg-primary font10 m-1"}>{item.name}</span>
                );
            });
        }
        else{
            return(
                <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
            );
        }
    }


    AlertRemoveItem(value){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.RemoveItemPush(value);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    async RemoveItemPush(value){

        this.props.AppThis.PageLoad(true);

        const self=this;

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id', value._id);

        var get_data=await fetch(Appthis.ConstApp.home_api+"mystoragenotification/delete", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");

            self.getData(true);
        }

        Appthis.PageLoad(false);
    }

}
