/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import lodash from "lodash";
import {
    _isset_object, array_to_select, getItemInArray,
} from "../../helper/array";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import Select from "react-select";

export default class EditDiscount extends Component{

    constructor(props) {
        super(props);

        this.state= {
            DataModal:null,
            ListCard:null
        };

    }

    componentDidMount() {
        this.getCard();

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{

        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getCard(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"card", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListCard=data.data;
                ListCard.unshift({
                    name:"Tất cả",
                    _id:null
                });

                self.setState({
                    ListCard:ListCard
                });

            }


        }).catch((e)=>{

        });


    }


    UpdateItemData(name,value){

        var DataModal=this.state.DataModal;

        DataModal[name]=value;

        this.setState({
            DataModal:DataModal
        });

    }

    renderData(item){

        let data=item;

        if(this.state.DataModal===null){

            var DataModal=lodash.cloneDeep(data);


            this.setState({
                DataModal:DataModal
            });
        }


        if(this.state.DataModal!==null){
            return(
                <div className={"row m-0 Edit-Profile"}>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Mã KM <span className="text-red">( * )</span></label>
                        <input type="text" onChange={(event)=>{
                            this.UpdateItemData('code',event.target.value);
                        }} defaultValue={this.state.DataModal.code} placeholder="Mã KM" required="required" className="form-control w-100" />
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Tên Mã KM <span className="text-red">( * )</span></label>
                        <input type="text" onChange={(event)=>{
                            this.UpdateItemData('name',event.target.value);
                        }} defaultValue={this.state.DataModal.name} placeholder="Tên Mã KM" required="required" className="form-control w-100" />
                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Thời gian bắt đầu <span className="text-red">( * )</span></label>

                        {this.state.DataModal.start_time?(
                            <DatePicker  dateFormat="dd/MM/yyyy" selected={new Date(this.state.DataModal.start_time*1000)} onChange={(date) => {
                                this.UpdateItemData('start_time',date.getTime()/1000);
                            }} />
                        ):(
                            <DatePicker  dateFormat="dd/MM/yyyy" onChange={(date) => {
                                this.UpdateItemData('start_time',date.getTime()/1000);
                            }} />
                        )}


                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Thời gian kết thúc <span className="text-red">( * )</span></label>


                        {this.state.DataModal.end_time?(
                            <DatePicker  dateFormat="dd/MM/yyyy" selected={new Date(this.state.DataModal.end_time*1000)} onChange={(date) => {
                                this.UpdateItemData('end_time',date.getTime()/1000);
                            }} />
                        ):(
                            <DatePicker  dateFormat="dd/MM/yyyy"  onChange={(date) => {
                                this.UpdateItemData('end_time',date.getTime()/1000);
                            }} />
                        )}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>

                        <label className="font-weight-400 mb-1">Thẻ tập áp dụng KM</label>
                        {this.state.ListCard!==null?(
                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                var id_card=selectedOption.value;
                                this.UpdateItemData("id_card",id_card);
                            }} options={array_to_select(this.state.ListCard,"name","_id")}
                                    defaultValue={typeof this.state.DataModal.id_card!=='undefined' && this.state.DataModal.id_card!==null?getItemInArray(this.state.DataModal.id_card._id,"value",array_to_select(this.state.ListCard,"name","_id")):null}
                            />
                        ):(
                            <div className={"col-12 w-100 mt-2"}>
                                <ItemLoading />
                            </div>
                        )}

                    </div>



                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Giới hạn</label>

                        <input min={0} type="number" onChange={(event)=>{
                            this.UpdateItemData('number_discount',parseInt(event.target.value));
                        }} defaultValue={this.state.DataModal.number_discount} placeholder="Giới hạn" required="required" className="form-control w-100" />

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Số tiền KM</label>

                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('price_sale',parseInt(event.target.value));
                        }} defaultValue={this.state.DataModal.price_sale} placeholder="Số tiền KM" required="required" className="form-control w-100" />

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Chiết khấu %</label>

                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('percent_sale',parseInt(event.target.value));
                        }} defaultValue={this.state.DataModal.percent_sale} placeholder="Chiết khấu %" required="required" className="form-control w-100" />

                    </div>




                    <div className={"col-12 pt-3 pb-3"}>
                        <div className={"alert alert-danger"}>
                            <i className="fas fa-exclamation-triangle me-1"></i> Buổi tập tặng kèm sẽ được cộng vào HD
                        </div>
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Số buổi tập (member)</label>

                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('number_card_sale',parseInt(event.target.value));
                        }} defaultValue={this.state.DataModal.number_card_sale} placeholder="Số buổi tập" required="required" className="form-control w-100" />

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Số buổi tập PT</label>

                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('number_pt_sale',parseInt(event.target.value));
                        }} defaultValue={this.state.DataModal.number_pt_sale} placeholder="Số buổi tập PT" required="required" className="form-control w-100" />

                    </div>


                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Số tháng KM (Linh hoạt ,Cố định)</label>

                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('number_month_sale',parseInt(event.target.value));
                        }} defaultValue={typeof this.state.DataModal.number_month_sale!=='undefined'?this.state.DataModal.number_month_sale:0} placeholder="Số tháng tập" required="required" className="form-control w-100" />

                    </div>

                </div>
            );
        }
        else{
          return(
              <div className={"col-12 justify-content-center align-content-center d-flex"}>
                  <ItemLoading />
              </div>
          );
        }

    }

    SubmitModal(){

        var DataModal=this.state.DataModal;

        var number_error=0;

        ['name','code','start_time','end_time'].map(function(value,key){

            if(typeof DataModal[value]==="undefined" || DataModal[value].length<1){
                number_error+=1;
            }

        });

        if(number_error===0){
            this.props.GetDataSubmit(DataModal);

        }
        else{
            toast("Vui lòng nhập đầy đủ thông tin");
        }

    }

    render() {

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Cập nhật mã khuyến mãi</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                {this.props.data!==null && this.state.ListCard!==null?(this.renderData(this.props.data)):(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>



                        </div>

                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.SubmitModal();
                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                Cập nhật
                                            </a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
