/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import lodash from "lodash";
import EditCoSigner from "./edit/EditCoSigner";
import EditProtection from "./edit/EditProtection";
import EditCardContract from "./edit/EditCardContract";
import EditPriceContract from "./edit/EditPriceContract";
import EditDiscountContract from "./edit/EditDiscountContract";
import {base64_encode, timeConverter} from "../../helper/array";
import {get_type_edit_contract} from "../../helper/member";

const INIT_STATE={
   page:1,
    data:null,
    history:null,
};

export default class EditContract extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
        this.RefComponent=React.createRef();

    }


    componentDidMount() {

        if(typeof this.props.data!=='undefined'){

            var get_data=this.props.data;

            const self=this;

            this.setState({
                data:get_data
            },function(){

                self.getHistory();
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getHistory(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        var data={id_history:this.state.data._id};

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('data', base64_encode(data));

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/historyedit", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    history:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }


    ViewHistoryChange(){

        if(this.state.history!==null && this.state.history.length>0){

            return(
                <>
                    <div className={"col-12 mt-4"}>

                        <h4 className="d-inline-block mb-3 font16 mt-3 text-uppercase font-weight-bold text-dark border-primary border-bottom pb-2">
                            Lịch sử chỉnh sửa HD
                        </h4>

                        <div className={"col-12 overflow-auto"}>

                            <table className={"mt-3 w-100 table border-0 mw800 table-style-old"}>
                                <thead>
                                <tr className={"border-bottom"}>
                                    <td className={"font-weight-bold text-uppercase font14 p-2 text-black bg-light-bold"}>
                                        <i className="far fa-user" /> Người thay đổi
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2 text-black bg-light-bold"}>
                                        <i className="far fa-clock" /> Thời gian
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2 text-black bg-light-bold"}>
                                        <i className="fas fa-plus" />  Hành động
                                    </td>

                                </tr>
                                </thead>
                                <tbody>

                                {this.state.history.map(function(item,index){
                                    return(<tr>
                                        <td>
                                            <span>{item.id_admin.username}</span>
                                        </td>
                                        <td>
                                            <span>{timeConverter(item.time_create)}</span>
                                        </td>
                                        <td>
                                            {get_type_edit_contract(item)}
                                        </td>

                                    </tr>);
                                })}

                                </tbody>

                            </table>

                        </div>



                    </div>
                </>
            );
        }
        else{
            return(<>
            <div className={"alert alert-danger mt-3 mb-3"}>
                Chưa có lịch sử chỉnh sửa
            </div>
            </>);
        }

    }

    RenderView(){

        switch (this.state.page) {

            case 1:
                return this.Step1();
            case 2:
                return this.Step2();
            case 3:
                return this.Step3();
            case 4:
                return this.Step4();
            case 5:
                return this.Step5();
            case 6:
                return this.ViewHistoryChange();

        }

    }

    async UpdateDetailContract(data=null,page=1){

        this.setState({
            data:data,
            page:page
        });

    }

    render() {

        return(
            <div className="modal d-block position-fixed">

                <div className={"modal-dialog modal-lg modal-fullscreen-lg-down modal-dialog-scrollable"}>

                    <div className="modal-content bg-white shadow border-0">
                        <div className="modal-header p-2 pb-2">
                            <div className={"container position-relative"}>
                                <strong className={"font18 text-black text-uppercase text-white"}>
                                    <i className="far fa-edit me-2" />
                                    Sửa Hợp Đồng</strong>

                                <a onClick={()=>{
                                    this.props.onClose();
                                }} className={"font-weight-bold text-white position-absolute top-0 right15 font15"}>
                                    <i className="far fa-times-circle text-white" /> Đóng
                                </a>

                            </div>
                        </div>
                        <div className="modal-body">


                            <div className={"container"}>


                                {this.state.data!==null?(

                                    <div className={"row mb-2 mt-2"}>
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12"}>

                                            <div className={"col-12 mt-2 mb-3"}>
                                                <ul className="nav-theme nav nav-tabs border-0">
                                                    <li className="nav-item">
                                                        <a onClick={()=>{
                                                            this.UpdateDetailContract(this.state.data,1);
                                                        }} className={this.state.page===1?("nav-link active"):("nav-link text-black")}
                                                           href="#">Đồng ký tên</a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a onClick={()=>{
                                                            this.UpdateDetailContract(this.state.data,2);
                                                        }} className={this.state.page===2?("nav-link active"):("nav-link text-black")} href="#">
                                                            LH khẩn cấp
                                                        </a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a onClick={()=>{
                                                            this.UpdateDetailContract(this.state.data,3);
                                                        }} className={this.state.page===3?("nav-link active"):("nav-link text-black")} href="#">
                                                            Thẻ tập
                                                        </a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a onClick={()=>{
                                                            this.UpdateDetailContract(this.state.data,4);
                                                        }} className={this.state.page===4?("nav-link active"):("nav-link text-black")} href="#">
                                                           Giá tiền & phí
                                                        </a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a onClick={()=>{
                                                            this.UpdateDetailContract(this.state.data,5);
                                                        }} className={this.state.page===5?("nav-link active"):("nav-link text-black")} href="#">
                                                           Mã giảm giá
                                                        </a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a onClick={()=>{
                                                            this.UpdateDetailContract(this.state.data,6);
                                                        }} className={this.state.page===6?("nav-link active"):("nav-link text-black")} href="#">
                                                            Lịch sử
                                                        </a>
                                                    </li>


                                                </ul>
                                            </div>

                                            {this.RenderView()}



                                        </div>



                                    </div>

                                ):(
                                    <div className={"col-12 d-flex flex-row justify-content-center mt-4 align-items-center"}>
                                        <ItemLoading size={"Large"} />
                                    </div>
                                )}

                            </div>



                        </div>


                    </div>

                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );


    }


    Step1(){
        const self=this;
        return(
            <EditCoSigner onClose={()=>{
                self.props.onClose();
            }} data={this.state.data} AppThis={this.props.AppThis} path={this.props.path} />
        );
    }

    Step2(){
        const self=this;
        return(
            <EditProtection onClose={()=>{
                self.props.onClose();
            }} data={this.state.data} AppThis={this.props.AppThis} path={this.props.path} />
        );

    }

    Step3(){
        const self=this;
       return(
           <EditCardContract onClose={()=>{
               self.props.onClose();
           }} data={this.state.data} AppThis={this.props.AppThis} path={this.props.path} />
       );
    }

    Step4(){
        const self=this;
        return(
            <EditPriceContract onClose={()=>{
                self.props.onClose();
            }} data={this.state.data} AppThis={this.props.AppThis} path={this.props.path} />
        );

    }
    Step5(){
        const self=this;
        return(
          <EditDiscountContract onClose={()=>{
              self.props.onClose();
          }} data={this.state.data} AppThis={this.props.AppThis} path={this.props.path} />
        );

    }

}
