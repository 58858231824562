/* eslint-disable */
import React, {Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_object,
    base64_encode,
    timeConverter,
    get_data_in_args, array_to_select, ArrayInArray, get_item_in_array, getSourcePush
} from "../../helper/array";
import DetailPush from "../../global/push/DetailPush";
import NewPush from "../../global/push/NewPush";
import Floater from 'react-floater';
import CanvasJSReact from '../../helper/canvasjs.react';
import {confirmAlert} from "react-confirm-alert";
import DatePicker from "react-datepicker";
import Select from "react-select";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SOURCE_PUSH=[
    {
        _id:null,
        name:"Tất cả"
    },
    {
        _id:0,
        name:"Web"
    },
    {
        _id:1,
        name:"Google Sheet"
    },
    {
        _id:2,
        name:"App Mobile"
    }
];

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        start_time:null,
        end_time:null,
        id_statics:null,
        id_address:null,
        id_subject:[],
        id_team_marketing:null,
        id_admin:null,
        source_push:null,
        type:0
    },
    DetailPush:{
      status:false,
      data:null
    },
    NewPush:{
      status:false,
    },
    statics:{
        push:null,
        user:null,
        static:null,
        marketing:{
            push:null,
            statics:null,
            user:null
        },
        team:{
            push:null,
            user:null
        }
    },
    page:0,
    spreadsheetId:null,
    SearchAdvanced:false,
    ListStatics:null,
    ListAddress:null,
    GetSubject:null,
    ListTeamMkt:null,
    ListAdminMkt:null,
    TimeUpdate:null
};

export default class mnotification extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    AlertExportDataPush(){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ExportDataPush();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    ExportDataPush(){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        fetch(Appthis.ConstApp.home_api+"mnotification/export", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Dữ liệu đã được gửi tới máy chủ thành công");

            }
            Appthis.PageLoad(false);

        }).catch((e)=>{

            toast("Thao tác thất bại");

            Appthis.PageLoad(false);

        });



    }

    getSubject(){

        if(this.state.query.id_address!==null){
            var Appthis=this.props.AppThis;
            const formData = new FormData();
            formData.append('token', Appthis.getToken());
            formData.append('id_address', this.state.query.id_address);
            const self=this;
            fetch(Appthis.ConstApp.home_api+"subject", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{
                if(data.status==='ok'){
                    self.setState({
                        GetSubject:data.data
                    });
                }
            }).catch((e)=>{

            });
        }
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }
    getTeamMarketing(){

            var Appthis=this.props.AppThis;
            const formData = new FormData();
            formData.append('token', Appthis.getToken());
            const self=this;
            fetch(Appthis.ConstApp.home_api+"mnotification/teammkt", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{
                if(data.status==='ok'){

                    var ListTeamMkt=data.data;

                    ListTeamMkt.unshift({
                        _id:null,
                        name:"Chọn Team"
                    });

                    self.setState({
                        ListTeamMkt:ListTeamMkt
                    });
                }
            }).catch((e)=>{

            });
    }

    getAdminMkt(){

        if(this.state.query.id_team_marketing===null){
            return;
        }

        var Appthis=this.props.AppThis;
         const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_group', this.state.query.id_team_marketing);
            const self=this;
            fetch(Appthis.ConstApp.home_api+"mnotification/getadmin", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{
                if(data.status==='ok'){

                    var ListAdminMkt=data.data;

                    ListAdminMkt.unshift({
                        key:null,
                        value:"Chọn nhân viên"
                    });

                    self.setState({
                        ListAdminMkt:ListAdminMkt
                    });
                }
            }).catch((e)=>{

            });
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mnotification", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    Getspreadsheet(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mnotification/spreadsheet", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    spreadsheetId:data.data.UrlSpreadsheet
                });
            }


        }).catch((e)=>{


        });


    }


    AlertRegisterSpreadsheetId(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.RegisterSpreadsheetId();
                                self.setState({
                                    ModalSearchMobile:false
                                });

                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });


    }

    RegisterSpreadsheetId(){

       this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        fetch(Appthis.ConstApp.home_api+"mnotification/register", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");

                Appthis.PageLoad(false);

            }
            else{
                window.location.href=data.data;
            }



        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");

             Appthis.PageLoad(false);


        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getTimeUpdate();

        this.getStatics();

        this.GetListStatics();

        this.getListAddress();

        this.Getspreadsheet();

        this.getStaticsGroup();

        this.getTeamMarketing();

    }

    async getStaticsGroup(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;

        var get_data=await fetch(Appthis.ConstApp.home_api+"mnotification/marketing", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

      await self.SetUpStaticsMonthTeamMKT(get_data.data);


    }

    async SetUpStaticsMonthTeamMKT(data) {

        var marketing={
            push:null,
            statics:null,
            user:null
        };

        var team={
            push:null,
            user:null
        };

        var statics=this.state.statics;

        var tmp_push = [];

        var tmp_static=[];

        var tmp_user=[];

        var _tmp_team_push=[];

        var _tmp_team_user=[];

        var total_team_push=0;

        var total_team_user=0;

        if(typeof data!=='undefined' && Object.keys(data).length>0){
            Object.entries(data).map(function(item){

                const [key,value]=item;

                var _tmp_push=[];

                var _tmp_user=[];

                var _total_user=0;

                if(Object.keys(value.days).length>0){

                    Object.entries(value.days).map(function(id){

                        var [k,v]=id;

                        _tmp_push.push({
                            x:new Date(k * 1000),
                            y:v
                        });
                    });
                }

                if(Object.keys(value.users).length>0){

                    Object.entries(value.users).map(function(id){

                        var [k,v]=id;

                        _tmp_user.push({
                            x:new Date(k * 1000),
                            y:v
                        });

                        _total_user+=v;

                    });
                }

                tmp_push.push({
                    type: "line",
                    showInLegend: true,
                    name: value.statics.name,
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: _tmp_push
                });

                tmp_user.push({
                    type: "line",
                    showInLegend: true,
                    name: value.statics.name,
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: _tmp_user
                });


                var _tmp_statics=lodash.cloneDeep(value.statics.data.data);

                var update_tmp_statics=[];

                _tmp_statics.map(function(id){
                    id.y=id.percent;
                    update_tmp_statics.push(id);
                });

                tmp_static.push({
                    name:value.statics.name,
                    data:{
                        animationEnabled: true,
                        data: [{
                            type: "pie",
                            startAngle: 20,
                            toolTipContent: "<b>{label}</b>: {number}/"+value.statics.data.total_count+" Lần chuyển",
                            showInLegend: "true",
                            legendText: "{label}",
                            indexLabelFontSize: 12,
                            indexLabel: "{label} - {y}%",
                            dataPoints:update_tmp_statics
                        }]
                    }
                });


                total_team_push+=parseInt(value.statics.total_count);

                total_team_user+=parseInt(_total_user);

                _tmp_team_push.push({
                    label:key,
                    number:value.statics.total_count,
                    y:0
                });

                _tmp_team_user.push({
                    label:key,
                    number:_total_user,
                    y:0
                });

            });
        }


        var data_team_push=[];

        if(_tmp_team_push.length>0){

            _tmp_team_push.map(function(value,index){

                if(total_team_push!==0){
                    value.y=(value.number/total_team_push)*100;
                }

                data_team_push.push(value);
            });
        }

        var data_team_user=[];

        if(_tmp_team_user.length>0){
            _tmp_team_user.map(function(value,index){
                if(total_team_user!==0){
                    value.y=(value.number/total_team_user)*100;
                }
                data_team_user.push(value);
            });
        }


        marketing.push={
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: tmp_push
        };
        marketing.user={
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: "KH",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: tmp_user
        };
        marketing.statics=tmp_static;
        statics.marketing=marketing;



        team.push={
                animationEnabled: true,
                data: [{
                    type: "pie",
                    startAngle: 20,
                    toolTipContent: "<b>{label} - {number}/"+total_team_push+" lần chuyển</b>",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 12,
                    indexLabel: "{label} - {y}%",
                    dataPoints:data_team_push
                }]
        };

        team.user={
                animationEnabled: true,
                data: [{
                    type: "pie",
                    startAngle: 20,
                    toolTipContent: "<b>{label} - {number}/"+total_team_user+" Khách hàng</b>",
                    showInLegend: "true",
                    legendText: "{label}",
                    indexLabelFontSize: 12,
                    indexLabel: "{label} - {y}%",
                    dataPoints:data_team_user
                }]
        };

        statics.team=team;

        this.setState({
            statics:statics
        });


    }
    getTimeUpdate(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"mnotification/timeupdate", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then((response=>response.json())).then((response)=>{

            self.setState({
                TimeUpdate:response.data
            });


        });

    }

    async getStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;

        var get_data=await fetch(Appthis.ConstApp.home_api+"mnotification/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

        var statics=this.state.statics;

        var update_state=await self.SetUpStaticsMonth(get_data.data,statics);

        this.setState({
            statics:update_state
        });

    }

    async GetListStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());


        var get_data=await fetch(Appthis.ConstApp.home_api+"statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        if(get_data.status==='ok'){

            var ListStatics=get_data.data;

            ListStatics.unshift({
                _id:0,
                name:"Chưa rõ"
            });

            ListStatics.unshift({
                _id:'none',
                name:"Tất cả"
            });

            this.setState({
                ListStatics:ListStatics
            });

        }



    }

    async getListAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type',3);

        const self=this;

        var get_data=await fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        if(get_data.status==='ok'){


            var ListAddress=get_data.data;

            ListAddress.unshift({
                _id:'none',
                name:"Tất cả"
            });

            this.setState({
                ListAddress:ListAddress
            });

        }


    }


    async SetUpStaticsMonth(data,statics) {

        var data_report_user = [];

        var data_report_push = [];

        var pagination_data_report = data.report_user_by_day;

        Object.entries(pagination_data_report).forEach(entry => {
            const [key, value] = entry;

            data_report_push.push({
                x:new Date(key * 1000),
                y:value.push
            });

            data_report_user.push({
                x:new Date(key * 1000),
                y:value.user
            });

        });

        statics.push={
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Đẩy số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_push
                }

            ]
        };

        statics.user={
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Khách hàng",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_user
                }

            ]
        };

        statics.static={
            animationEnabled: true,
            data: [{
                type: "pie",
                startAngle: 20,
                toolTipContent: "<b>{label}</b>: {number}",
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 12,
                indexLabel: "{label} - {y}%",
                dataPoints:data.data_statics
            }]
        };

        return statics;


    }


    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old mw1500"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Người gửi</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Nguồn gửi</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Team</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên địa chỉ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Bộ môn</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Thời gian</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){

                            return(
                                <tr>

                                    <td> {value['id_admin']['username']+ " - " +value['id_admin']['code_user']}</td>

                                    <td>{getSourcePush(value)}</td>

                                    <td> {_isset_object(value['id_admin']['group_id'])?(
                                        <>
                                            {value['id_admin']['group_id'].map(function(item){
                                                return(
                                                    <span className={"badge bg-dark rounded-0 p12 m-1"}>{item.name}</span>
                                                );
                                            })}
                                        </>
                                    ):(
                                        <span>Chưa rõ</span>
                                    )}</td>

                                    <td> {value['id_user']['user_login']}
                                    </td>


                                    <td>
                                        {value['id_address']['name']}
                                    </td>
                                    <td>
                                        {value['id_subject']?(
                                            <span>{self.getNameSubject(value.id_subject)}</span>
                                        ):(
                                            <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
                                        )}
                                    </td>


                                    <td>{timeConverter(value['time_create'])}</td>

                                    <td>{value['id_statics']!==null?value['id_statics']['name']:(
                                        <span className={"badge p-1 font10 bg-warning"}>Chưa rõ</span>
                                    )}</td>

                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{

                                            self.setState({
                                                DetailPush:{
                                                    status:true,
                                                    data:value
                                                }
                                            });

                                        }} className={"p-2 mt-2 btn btn-primary rounded-0"}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xem chi tiết">
                                            <i className="fas fa-info-circle" />
                                            </Floater>
                                        </a>




                                            <a onClick={()=>{
                                                self.AlertRemoveItem(value);

                                            }} className={"p-2 mt-2 btn btn-danger ms-1 rounded-0"}>
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Thu hồi">
                                                    <i className="fas fa-minus" />
                                                </Floater>
                                            </a>


                                    </td>


                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <div className={"col-12 ps-3 pe-3"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    render(){

        const self=this;

        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold">
                                    CHUYỂN KHÁCH HÀNG MARKETING
                                </h3>
                            </div>

                            <div className="col-12 col-sm-12  text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}


                                    {this.state.TimeUpdate!==null?(
                                        <span className="ms-3">
                                        Thời gian cập nhật : <strong className={"font18"}>{timeConverter(this.state.TimeUpdate)}</strong>
                                        </span>
                                    ):(<></>)}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>


                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:true,
                                                ModalSearchMobile:false
                                            })
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>


                                        <a onClick={()=>{
                                            this.setState({
                                                NewPush:{
                                                    status:true
                                                },
                                                ModalSearchMobile:false
                                            });

                                        }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>


                                        {this.state.spreadsheetId!==null?(
                                            <a onClick={()=>{
                                                window.open(this.state.spreadsheetId,"_blank");
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="me-2 ibtn btn rounded-0 btn-outline-warning font13 font-weight-600">
                                                <i className="fab fa-google-drive"></i>
                                                Tới File Kết nối Google Sheet
                                            </a>
                                        ):(<></>)}


                                        <a onClick={()=>{
                                            this.AlertRegisterSpreadsheetId();
                                        }} className="me-2 ibtn btn rounded-0 btn-outline-primary font13 font-weight-600">
                                            <i className="fab fa-google-drive"></i>
                                        Kết nối Google Sheet
                                        </a>

                                    </div>

                                </div>





                            </div>

                        </div>


                        <div className={"row m-0 mb-4"}>



                            {this.state.statics.user!==null?(
                                <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>
                                    <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                        Khách hàng mới
                                    </div>
                                    <CanvasJSChart options={this.state.statics.user} />
                                </div>

                            ):(
                                <></>
                            )}


                                {this.state.statics.static!==null?(
                                <div className={"col-12 col-sm-12 col-md-12 col-lg-4"}>
                                        <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                            TÌNH TRẠNG KHÁCH HÀNG
                                        </div>
                                    <CanvasJSChart options={this.state.statics.static} />
                                 </div>

                                ):(
                                   <></>
                                )}



                            <div className={"col-12 mt-3"}>

                                {this.state.statics.marketing.push===null || this.state.statics.marketing.user===null || this.state.statics.marketing.statics===null?(
                                        <div className={"col-12 text-center mt-4 d-flex justify-content-center"}>
                                            <ItemLoading size={"Large"} />
                                        </div>

                                ):(
                                    <div className={"col-12"}>

                                        <div className={"row"}>
                                            <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>
                                                <div className={"col-12"}>
                                                    <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                                        Chuyển số theo team
                                                    </div>

                                                    <CanvasJSChart options={this.state.statics.marketing.push} />
                                                </div>
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-12 col-lg-4"}>

                                                {this.state.statics.team.push!==null?(
                                                       <>
                                                           <CanvasJSChart options={this.state.statics.team.push} />
                                                       </>
                                                ):(
                                                    <></>
                                                )}

                                            </div>


                                        </div>


                                        <div className={"row"}>

                                            <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>

                                                <div className="col-12 pt-3 text-uppercase font-weight-bold pb-3 font16">
                                                    Khách hàng theo team
                                                </div>

                                                <div className={"col-12"}>

                                                    <CanvasJSChart options={this.state.statics.marketing.user} />
                                                </div>
                                            </div>


                                            <div className={"col-12 col-sm-12 col-md-12 col-lg-4"}>

                                                {this.state.statics.team.user!==null?(
                                                    <>
                                                        <CanvasJSChart options={this.state.statics.team.user} />
                                                    </>
                                                ):(
                                                    <></>
                                                )}

                                            </div>


                                        </div>






                                        <div className={"row mt-4 mb-3"}>

                                            {this.state.statics.marketing.statics.map(function(item){
                                                return(
                                                    <div className={"col-12 col-sm-6 col-md-4 mt-5"}>
                                                        <p className={"mb-3 font18 text-uppercase font-weight-bold"}>{item.name}</p>
                                                        <CanvasJSChart options={item.data} />
                                                    </div>
                                                );
                                            })}

                                        </div>





                                    </div>
                                )}





                            </div>


                        </div>


                        <div className={"col-12 ps-3 pe-3 mt-5"}>
                            {this.thisRenderData()}
                        </div>


                        {this.state.DetailPush.status===true?(
                            <DetailPush data={this.state.DetailPush.data} onClose={()=>{
                                this.setState({
                                    DetailPush:{
                                        status:false,
                                        data:null
                                    }
                                });

                            }} AppThis={this.props.AppThis} />
                        ):(<></>)}


                        {this.state.NewPush.status===true?(
                            <NewPush onClose={()=>{
                                this.setState({
                                    NewPush:{
                                        status:false,
                                    }
                                });

                            }} path={"mnotification"} AppThis={this.props.AppThis} />
                        ):(
                            <></>
                        )}




                    </div>



                    {this.state.SearchAdvanced===true?(
                        this.SearchAdvanced()
                    ):(
                        <></>
                    )}



                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2 box_input"}>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Họ và tên/SDT</p>
                                    <input type={"text"} onChange={(event)=>{
                                        this.UpdateQuerySearch('input_search',event.target.value);

                                    }} className={"form-control"} placeholder={"Họ và tên/SDT"} />
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Trạng thái</p>


                                    {this.state.ListStatics!==null?(
                                        <select onChange={(event)=>{
                                            this.UpdateQuerySearch('id_statics',event.target.value);
                                        }} className={"form-control w-100 rounded-0"}>

                                            {this.state.ListStatics.map(function(item){

                                                if(item._id===self.state.query.id_statics){
                                                    return(
                                                        <option selected={true} value={item._id}>{item.name}</option>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                }

                                            })}
                                        </select>
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Địa chỉ</p>
                                    {this.state.ListAddress!==null?(
                                        <select onChange={(event)=>{
                                            var query=self.state.query;
                                            query.id_address=event.target.value;
                                            this.setState({
                                                query:query,
                                                GetSubject:null
                                            },function(){
                                                self.getSubject();
                                            });

                                        }} className={"form-control w-100 rounded-0"}>
                                            {this.state.ListAddress.map(function(item){
                                                if(item._id===self.state.query.id_address){
                                                    return(
                                                        <option selected={true} value={item._id}>{item.name}</option>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                }

                                            })}
                                        </select>
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Bộ môn</p>

                                    {this.state.GetSubject!==null?(
                                        <div className={"Box-Select-Mutiple customCssSelect"}>
                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                var get_subject=get_data_in_args(selectedOption,"value");
                                                this.UpdateQuerySearch('id_subject',get_subject);
                                            }} isMulti={true} defaultValue={array_to_select(ArrayInArray(this.state.query.id_subject,this.state.GetSubject,"_id"),"name","_id")} options={array_to_select(this.state.GetSubject,'name','_id')} />
                                        </div>
                                    ):(
                                        <select className={"form-control rounded-0"}></select>
                                    )}


                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Team Marketing</p>

                                    {this.state.ListTeamMkt!==null?(
                                        <select onChange={(event)=>{
                                            var query=self.state.query;
                                            query.id_team_marketing=event.target.value;
                                            this.setState({
                                                query:query,
                                                ListAdminMkt:null
                                            },function(){
                                                if(event.target.value!==null){
                                                    self.getAdminMkt();
                                                }
                                            });

                                        }} className={"form-control w-100 rounded-0"}>
                                            {this.state.ListTeamMkt.map(function(item){
                                                if(item._id===self.state.query.id_team_marketing){
                                                    return(
                                                        <option selected={true} value={item._id}>{item.name}</option>
                                                    );
                                                }
                                                else{
                                                    return(
                                                        <option value={item._id}>{item.name}</option>
                                                    );
                                                }

                                            })}
                                        </select>
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Nhân viên</p>
                                    {this.state.ListAdminMkt!==null?(
                                        <div className={"Box-Select-Mutiple customCssSelect"}>
                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                this.UpdateQuerySearch('id_admin',selectedOption.value);
                                            }} defaultValue={this.state.query.id_admin!==null?get_item_in_array("value",self.state.query.id_admin,array_to_select(self.state.ListAdminMkt,'value','key')):null} options={array_to_select(this.state.ListAdminMkt,'value','key')} />
                                        </div>
                                    ):(
                                        <select className={"form-control rounded-0"}></select>
                                    )}


                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Ngày bắt đầu</p>

                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {

                                        var tmp_time=null;

                                        if(date!==null){
                                            tmp_time=date.getTime()/1000;
                                        }

                                        this.UpdateQuerySearch('start_time',tmp_time);


                                    }} selected={this.state.query.start_time!==null?new Date(this.state.query.start_time*1000):null} placeholderText={"Ngày bắt đầu"} />


                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Ngày kết thúc</p>

                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {

                                        var tmp_time=null;

                                        if(date!==null){
                                            tmp_time=date.getTime()/1000;
                                        }

                                        this.UpdateQuerySearch('end_time',tmp_time);

                                    }} selected={this.state.query.end_time!==null?new Date(this.state.query.end_time*1000):null} placeholderText={"Ngày kết thúc"} />

                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Nguồn gửi</p>
                                    <select className={"form-control rounded-0"} onChange={(event)=>{
                                        this.UpdateQuerySearch("source_push",parseInt(event.target.value));
                                    }}>
                                        {SOURCE_PUSH.map(function(item){
                                            if(self.state.query.source_push===item._id){
                                                return(
                                                    <option selected={true} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            }
                                            else{
                                                return(
                                                    <option value={item._id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            }

                                        })}

                                    </select>
                                </div>



                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Loại bỏ trùng số</p>

                                    {[
                                        {
                                            _id:0,
                                            name:"Tất cả"
                                        },
                                        {
                                            _id:1,
                                            name:"Loại bỏ SDT trùng"
                                        }
                                    ].map(function(ie,ix){

                                        if(self.state.query.type===ie._id){
                                            return(
                                               <label onClick={()=>{
                                                   self.UpdateQuerySearch("type",ie._id);
                                               }} className={"text-center"}>
                                                   <input type="radio" checked={true} value={ie._id}  />
                                                   <span className={"me-2"}>{ie.name}</span>
                                               </label>
                                            );
                                        }
                                        else{
                                            return(
                                               <label onClick={()=>{
                                                   self.UpdateQuerySearch("type",ie._id);
                                               }} className={"text-center"}>
                                                   <input type="radio" checked={false} value={ie._id}  />
                                                   <span className={"me-2"}>{ie.name}</span>
                                               </label>
                                            );
                                        }

                                    })}

                                </div>




                            </div>

                        </div>


                        <div className={"modal-footer w-100 bg-light"}>


                            <div className={"col-12 col-sm-12 col-md-10 offset-md-2"}>

                                <div className={"row w-100"}>


                                    <div className={"col-12 col-sm-12 col-lg-3"}>
                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:false,
                                                page:0,
                                                statics:{
                                                    push:null,
                                                    user:null,
                                                    static:null,
                                                    marketing:{
                                                        push:null,
                                                        statics:null,
                                                        user:null
                                                    },
                                                },
                                            },function(){
                                                self.getData(true);
                                                self.getStatics();
                                                self.getStaticsGroup();
                                            });

                                        }} className={"btn btn-primary w-100 text-white mt-3"} >
                                            Tìm kiếm
                                        </a>
                                    </div>

                                    <div className={"col-12 col-sm-12 col-lg-3"}>
                                        <a onClick={()=>{
                                            this.AlertExportDataPush();
                                        }} className={"btn btn-dark w-100 text-white mt-3"}>
                                            <i className="fas fa-file-export me-1"></i>
                                            Xuất dữ liệu
                                        </a>

                                    </div>


                                    <div className={"col-12 col-sm-12 col-lg-3"}>
                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:false,
                                                query:{
                                                    input_search:null,
                                                    start_time:null,
                                                    end_time:null,
                                                    id_statics:null,
                                                    id_address:null
                                                }
                                            },function(){
                                                this.getData(true);
                                            });

                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                            Xoá phiên
                                        </a>
                                    </div>

                                    <div className={"col-12 col-sm-12 col-lg-3"}>
                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:false
                                            });

                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                            Đóng
                                        </a>
                                    </div>

                                </div>
                            </div>


                        </div>



                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }



    getNameSubject(arg){
        if(arg.length>0){
            return arg.map(function(item){
                return(
                    <span className={"badge p-1 bg-primary font10 m-1"}>{item.name}</span>
                );
            });
        }
        else{
            return(
                <span className={"badge font10 p-1 bg-danger"}>Chưa rõ</span>
            );
        }
    }

    AlertRemoveItem(value){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.RemoveItemPush(value);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

   async RemoveItemPush(value){

        this.props.AppThis.PageLoad(true);

        const self=this;

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

       formData.append('_id', value._id);

        var get_data=await fetch(Appthis.ConstApp.home_api+"mnotification/delete", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");

            self.getData(true);
        }

        Appthis.PageLoad(false);
    }
}
