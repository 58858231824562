/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {base64_encode} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
const INIT_STATE={
    data:null,
    current_tab:0,
    data_submit:[]
};

export default class moptions extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    async getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

       var get_data=await fetch(Appthis.ConstApp.home_api+"moptions", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

        self.setState({
            data:get_data.data
        });

        get_data=Object.entries(get_data.data);

        var data_submit={};

        get_data.map(function(item,index){

            item[1].map(function(value,key){
                data_submit[value['_id']]=value['value'];
            });
        });


        this.setState({
            data_submit:data_submit
        });


        if(load===true){
            this.props.AppThis.PageLoad(false);
        }

    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
    }

    UpdateDataSubmit(id,value){
        var data_submit=this.state.data_submit;
        data_submit[id]=value;
        this.setState({
            data_submit:data_submit
        });
    }

    SubmitOption(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn cập nhật</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.UpdateData();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

   async UpdateData(){

        var data_submit=base64_encode(this.state.data_submit);

        var Appthis=this.props.AppThis;

        this.setState({
            data:null
        });


        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('data',data_submit);

        var get_status=await fetch(Appthis.ConstApp.home_api+"moptions/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");
        }


       this.getData(false);

   }


    thisRenderData(){

        var data=Object.entries(this.state.data);

        const self=this;

        return(
            <div className={"col-12 box-options"}>

                <div className={"d-flex mt-3 mb-3 tab-header-options flex-row flex-wrap"}>
                    {data.map(function(item,index){

                        var class_header="";
                        if(index===self.state.current_tab){
                            class_header=" active";
                        }
                        return(
                            <div onClick={()=>{
                                self.setState({
                                    current_tab:index
                                });

                            }} id={"tab_options"+index} className={"p-2 ps-3 pe-3 header-item-options font14"+class_header}>
                                {item[0]}
                            </div>
                        );
                    })}

                </div>

                <div className={"col-12 mt-3 body-options"}>
                    {data.map(function(item,index){
                        var class_body=" d-none";
                        if(index===self.state.current_tab){
                            class_body="";
                        }

                        return(
                            <div className={"row box-item-options"+class_body} id={"NameOptions"+index}>
                                {item[1].map(function(value,key){
                                    return(
                                        <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                            <label className={"d-block w-100 font14 mb-1 font-weight-600"}>{value.label}</label>
                                            <input onChange={(event)=>{
                                                self.UpdateDataSubmit(value['_id'],event.target.value);
                                            }} defaultValue={value.value} type={"text"} className={"form-control"} />
                                        </div>
                                    );
                                })}
                            </div>
                        );

                    })}

                </div>

                <div className={"row mt-3 mb-3"}>

                    <div className={"col-12 col-sm-12 col-md-4"}>
                        <a onClick={()=>{
                            self.SubmitOption();
                        }} className={"btn btn-outline-primary rounded-0"}>
                            Cập nhật
                        </a>
                    </div>


                </div>


            </div>
        );
    }


    render(){

        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>


                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý hệ thống</h3>
                            </div>
                        </div>

                            {this.state.data!==null?(
                            <div className={"col-12 ps-3 pe-3"}>
                                {this.thisRenderData()}
                            </div>
                        ):(
                            <div className={"col-12 ps-3 pe-3 d-flex justify-content-center"}>
                              <ItemLoading />
                            </div>
                        )}




                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
