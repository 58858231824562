/* eslint-disable */
import React,{Component} from "react";
import logo from "../../assets/images/logo.png";
import {_isset, _isset_object, formatNumber, timeConverterDate} from "../../helper/array";
import ItemLoading from "../ItemLoading";
import '../../Print.css';

export default class PrintTransaction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
        };
    }
    componentDidMount() {

        if(this.props.data){

            this.setState({
                data:this.props.data
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }
    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.Onclose!=='undefined'){
                this.props.Onclose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }
    render(){
        if (this.state.data!==null){
            var data=this.state.data.data;
            var percent=this.state.data.percent;
            return (
                <div className={"col-12 p-0 w-100 body_print_wallet"}>

                    <a className={"font26 button_close position-absolute right30 top15"} onClick={()=>{
                        this.props.Onclose();
                    }}><i className="far fa-window-close"></i></a>

                    <div className="container-print mb46 pt30 pb15">

                        <div className="A4">

                            <div className="header_print m-0 row w-100">
                                <div className="col-4 p-0">
                                    <img alt={"logo"} src={logo} width="210px" />
                                    <i className="d-block mt-2 text-uppercase">Chi nhánh :
                                        {data.id_address.name}</i>
                                </div>
                                <div className="col-8 p-0">

                                    <div className="row h-100">

                                        <div className="col-8 d-flex align-items-end pb-2">
                                            <div className="font-weight-bold">
                                                <h1 className="p26 font-weight-bold">BIÊN NHẬN <br /></h1><h1
                                                className="p26 font-weight-bold"> OFFICIAL RECEIPT </h1>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <div className="d-inline-block">
                                                <p className="mb-0">Ngày/Date: <b>  {timeConverterDate(data.time)} </b></p>
                                                <p className="mb-0">Số/No: <b>{data.number_votes}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                                <div className="row w-100">
                                    <div className="col-6">
                                        <p className="mb-print-1">Số hợp đồng/ Agreement
                                            No: <b> {_isset_object(data.id_member)?data.id_member.number_contract:""}</b></p>
                                        <p className="mb-print-1">Tên hội viên/ Member's name: <b className={"text-uppercase"}> {data.id_user.username}</b>
                                        </p>
                                        <p className="mb-print-1">Loại dịch vụ/ Type of service: <b  className={"text-uppercase"}> {_isset_object(data.id_member)?data.id_member.id_card.name:''}</b>
                                        </p>
                                        <p className="mb-print-1">Số dịch vụ/ Service no: <b>
                                        </b></p>
                                        <p className="mb-print-1">Số tiền/ Amount: <b> {formatNumber(data.price)} vnđ</b></p>
                                        <p className="mb-print-1">Nhân viên bán/Seller:
                                            {_isset_object(data.id_admin)?(
                                                <b> {data.id_admin.code_user}</b>
                                            ):("")}
                                           </p>
                                        <p className="mb-print-1">Nhân viên lễ tân/
                                            Receptionist:
                                            {_isset_object(data.id_admin_update)?(
                                                <b> {data.id_admin_update.code_user}</b>
                                            ):("")}
                                            </p>
                                        <p className="text-justify">Ghi chú/ Note:</p>
                                        <div style={{
                                            height:50
                                        }} className="w-100 d-block"></div>
                                        <p><i>Nếu không có thỏa thuận hoặc thông báo bằng văn bản, hiệu lực hợp đồng
                                                và quyền sử dụng dịch vụ tiện ích của quý hội viên sẽ được tự động ghi
                                                nhận và kích hoạt kể từ ngày diễn ra buổi tập đầu tiên hoặc không muộn
                                                hơn 15 ngày kể từ thời điểm hoàn thành các khoản thanh toán liên quan
                                                (tùy thời điểm nào đến trước).
                                            </i></p>
                                    </div>
                                    <div className="col-6">
                                        <p className="mb-print-1">Mã hội viên/ Member's code: <b>  {data.id_user.code_user}</b></p>
                                        <p className="mb-print-1">Loại thanh toán/ Type of paid: <b>
                                            {data.type_of_paid===1?(
                                                <span>Trả hết</span>
                                            ):(
                                                <>
                                                    {data.type_of_paid===2?(
                                                        <span>Đặt cọc</span>
                                                    ):(
                                                        <span>Trả nốt</span>
                                                    )}
                                                </>
                                            )}
                                        </b></p>
                                        <p className="mb-print-1">Hình thức thanh toán/Paid by: <b className={"text-capitalize"}>{data.id_payment?data.id_payment.name:''}</b></p>
                                        <p className="mb-print-1">Khuyến mại/ Promotion: <b>

                                            {data.id_member && data.id_member.id_discount.length>0?(
                                                data.id_member.id_discount.map(function(item,index){
                                                        return(<span>{item.code}  </span>);
                                                })
                                            ):(
                                              <></>
                                            )}

                                        </b></p>
                                        <p className="mb-print-1">Chữ ký khách hàng/ Customer signature: <b> </b></p>
                                        <div style={{
                                            height:100
                                        }} className="w-100 d-block"></div>
                                        <p className="font-weight-bold text-uppercase" > {data.id_user.username}</p>
                                    </div>
                                </div>
                            <div className="col-12 p-0 text-justify">
                                    <p className="mb-0"><i>Khoản đặt cọc có giá trị trong vòng 15 ngày.
                                        Các khoản thanh toán sẽ không được hoàn trả hoặc chuyển nhượng.<br />
                                        Valid period of deposit amount is 15 days.
                                        All payments made are non-refunable and non-transferable.
                                    </i></p>
                                </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                    <div className="container-print mt46 mb46 pt30 pb15">
                        <div className="A4">
                            <div className="header_print m-0 row w-100">
                                <div className="col-4 p-0">
                                    <img alt={"logo"} src={logo} width="210px" /> <br />
                                    <i className="d-block mt-2 text-uppercase">Chi nhánh :  {data.id_address.name} </i>
                                </div>
                                <div className="col-8 p-0">
                                    <div className="row h-100">
                                        <div className="col-8 d-flex align-items-end pb-2">
                                            <div className="font-weight-bold">
                                                <h1 className="p26 font-weight-bold">BIÊN NHẬN <br /></h1><h1 className="p26 font-weight-bold"> OFFICIAL RECEIPT </h1>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end">
                                            <div className="d-inline-block">
                                                <p className="mb-0">Ngày/Date: <b> {timeConverterDate(data.time)} </b></p>
                                                <p className="mb-0">Số/No: <b>{data.number_votes} </b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row w-100"><div className="col-6">
                                        <p className="mb-print-1">Số hợp đồng/ Agreement No: <b> {_isset_object(data.id_member)?data.id_member.number_contract:""}</b></p>
                                        <p className="mb-print-1">Tên hội viên/ Member's name: <b className={"text-uppercase"}> {data.id_user.username}</b>
                                        </p>
                                        <p className="mb-print-1">Loại dịch vụ/ Type of service: <b className={"text-uppercase"}> {_isset_object(data.id_member)?data.id_member.id_card.name:''}</b></p>
                                        <p className="mb-print-1">Số dịch vụ/ Service no: <b></b></p>
                                        <p className="mb-print-1">Số tiền/ Amount: <b> {formatNumber(data.price)} vnđ</b></p>
                                        <p className="mb-print-1">Nhân viên bán/Seller:
                                            {_isset_object(data.id_admin)?(
                                                <b> {data.id_admin.code_user}</b>
                                            ):("")}
                                            </p>
                                        <p className="mb-print-1">Nhân viên lễ tân/Receptionist:
                                            {_isset_object(data.id_admin_update)?(
                                                <b> {data.id_admin_update.code_user}</b>
                                            ):("")}
                                            </p>
                                        <p className="text-justify">Ghi chú/ Note:</p>
                                        <div style={{
                                            height:50
                                        }} className="w-100 d-block"></div>
                                        <p><i>Nếu không có thỏa thuận hoặc thông báo bằng văn bản, hiệu lực hợp đồng
                                                và quyền sử dụng dịch vụ tiện ích của quý hội viên sẽ được tự động ghi
                                                nhận và kích hoạt kể từ ngày diễn ra buổi tập đầu tiên hoặc không muộn
                                                hơn 15 ngày kể từ thời điểm hoàn thành các khoản thanh toán liên quan
                                                (tùy thời điểm nào đến trước).</i>
                                        </p>
                                    </div>
                                    <div className="col-6"><p className="mb-print-1">Mã hội viên/ Member's
                                             code: <b> {data.id_user.code_user}</b></p>
                                        <p className="mb-print-1">Loại thanh toán/ Type of paid: <b>
                                            {data.type_of_paid===1?(
                                                <span>Trả hết</span>
                                            ):(
                                                <>
                                                    {data.type_of_paid===2?(
                                                        <span>Đặt cọc</span>
                                                    ):(
                                                        <span>Trả nốt</span>
                                                    )}
                                                </>
                                            )}
                                        </b></p>
                                        <p className="mb-print-1">Hình thức thanh toán/Paid by: <b className={"text-capitalize"}>
                                            {data.id_payment?data.id_payment.name:''}
                                        </b></p>
                                        <p className="mb-print-1">Khuyến mại/ Promotion: <b>

                                            {data.id_member && data.id_member.id_discount.length>0?(
                                                data.id_member.id_discount.map(function(item,index){
                                                    return(<span>{item.code} </span>);
                                                })
                                            ):(
                                              <></>
                                            )}

                                        </b></p>
                                        <p className="mb-print-1">Chữ ký khách hàng/ Customer signature: <b> </b></p><div style={{
                                            height:100
                                        }} className="w-100 d-block"></div>
                                        <p className="font-weight-bold text-uppercase">{data.id_user.username}</p>
                                    </div>
                                </div>
                            <div className="col-12 p-0 text-justify">
                                    <p className="mb-0">
                                        <i>
                                        Khoản đặt cọc có giá trị trong vòng 15 ngày.
                                        Các khoản thanh toán sẽ không được hoàn trả hoặc chuyển nhượng.<br />
                                            Valid period of deposit amount is 15 days.
                                            All payments made are non-refunable and non-transferable.
                                    </i>
                                    </p>
                                </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                    {data.type===1 && data.type_receipts!==2?(<>
                            <div className="container-print mt46 pt30 pb15">
                                <div className="A4">
                                    <div className="col-8 d-flex align-items-end pb-2">
                                        <div className="font-weight-bold">
                                            <h1 className="p26 font-weight-bold">TỶ LỆ CHIA % DOANH SỐ <br /></h1>
                                            <h1 className="p26 font-weight-bold mt-3"> Lưu hành nội bộ </h1>
                                        </div>
                                    </div>

                                    <div className="w-100">
                                        <p className="mb-print-1">Số hợp đồng/ Agreement No: <b> {_isset_object(data.id_member)?data.id_member.number_contract:""}</b></p>
                                        <p className="mb-print-1">Tên hội viên/ Member's name: <b className={"text-uppercase"}> {data.id_user.username}</b>
                                        </p>
                                        <p className="mb-print-1">Loại dịch vụ/ Type of service: <b className={"text-uppercase"}> {_isset_object(data.id_member)?data.id_member.id_card.name:''}</b></p>
                                        <p className="mb-print-1">Số Phiếu thu: <b>{data.number_votes} </b> </p>
                                        <p className="mb-print-1">Ngày thu tiền: <b>{timeConverterDate(data.time)}</b> </p>
                                        <p className="mb-print-1">Số tiền/ Amount: <b> {formatNumber(data.price)} vnđ</b></p>
                                        <p className="mb-print-1">Nhân viên bán/Seller:
                                            {_isset_object(data.id_admin)?(
                                                <b> {data.id_admin.code_user}</b>
                                            ):("")}
                                        </p>
                                        <p className="mb-print-1">Nhân viên lễ tân/Receptionist:
                                            {_isset_object(data.id_admin_update)?(
                                                <b> {data.id_admin_update.code_user}</b>
                                            ):("")}
                                        </p>

                                    </div>
                                    <div className="w-100 mt-4 border-top mb-4 pt-3 text-uppercase">
                                        <h4 className={"font-weight-bold font22"}>Thông tin chi tiết chia doanh số</h4>
                                    </div>

                                    <div className="header_print m-0 row w-100 text-uppercase font-weight-400 font16 bg-light">
                                        <div className="col-2 p-2 border">
                                            <b>Tên club</b>
                                        </div>
                                        <div className="col-2 p-2 border border-start-0">
                                            <b>Mã Nhân viên</b>
                                        </div>
                                        <div className="col-2 p-2 border border-start-0">
                                            <b>% được chia</b>
                                        </div>
                                        <div className="col-2 p-2 border border-start-0">
                                            <b>Thành tiền</b>
                                        </div>
                                        <div className="col-4 p-2 border border-start-0">
                                            <b>Chữ ký xác nhận</b>
                                        </div>

                                    </div>

                                    {percent!==null && percent.length>0 ?(
                                        <>

                                            {percent.map(function (item){

                                                return(
                                                    <div className="m-0 row w-100">
                                                        <div className="col-2 p-2 border">
                                                            {item.id_admin.address_id[0]}
                                                        </div>
                                                        <div className="col-2 p-2 border">
                                                            {item.id_admin.code_user}
                                                        </div>
                                                        <div className="col-2 p-2 border">
                                                            {item.id_percent.number}
                                                        </div>
                                                        <div className="col-2 p-2 border">
                                                            {formatNumber(item.id_percent.price)} vnđ
                                                        </div>
                                                        <div className="col-4 p-2 border">

                                                        </div>

                                                    </div>
                                                );

                                            })}

                                        </>
                                    ):(
                                        <></>
                                    )}


                                    <div className={"col-12 pt-5 pb-5"} />


                                </div>
                            </div>
                        </>
                        ):(
                        <></>
                    )}



                </div>
            );
        }
        else{
            return(
                <ItemLoading />
            );
        }
    }
}
