/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_object, array_to_select,
    base64_encode,
    formatNumber,
    getItemInArray,
    timeConverterDate,
} from "../../helper/array";
import {get_name_type_card, get_name_type_receipts, type_wallet} from "../../helper/member";
import InfoPayTransaction from "../../global/transaction/InfoPayTransaction";
import Floater from 'react-floater';
import FingerPrintUser from "../../global/finger/FingerPrintUser";
import Select from "react-select";
import DatePicker from "react-datepicker";
import PrintTransaction from "../../global/transaction/PrintTransaction";
import NewsTransaction from "../../global/transaction/NewsTransaction";
import {confirmAlert} from "react-confirm-alert";
import EditTransaction from "../../global/transaction/EditTransaction";
const ListStatus=[
    {
        label:"Tất cả",
        value:null
    },
    {
        label:"Đang chờ",
        value:0
    },
    {
        label:"Kích hoạt",
        value:1
    },
    {
        label:"Hủy",
        value:2
    },
];

const ListTypeOfPaid=[
    {
        label:"Tất cả",
        value:null
    },
    {
        label:"Trả hết",
        value:1
    },
    {
        label:"Đặt cọc",
        value:2
    },
    {
        label:"Trả nốt",
        value:3
    },
];

const INIT_STATE={
    data:null,
    query:{
        start_time:null,
        end_time:null,
        number_votes:null,
        id_admin:null,
        id_admin_update:null,
        status:null,
        type_of_paid:null,
        input_search:null,
        type_receipts:null,
        number_contract:null,
    },
    page:0,
    DataDetailContract:null,
    InfoPay:{
        data:null,
        type: null
    },
    Statics:null,
    UserFingerprint:null,
    PrintWallet:{
        data:null,
        percent:null
    },
    SearchAdvanced:false,
    ListAdmin:null,
    ListCard:null,
    NewsTransaction:false
};

export default class transaction extends Component {
    constructor(props) {
        super(props);
        this.state = lodash.cloneDeep(INIT_STATE);
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getStatics();

        this.getListAdmin();

        this.getCard();
    }

    getCard(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"card", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListCard=data.data;
                ListCard.unshift({
                    name:"Tất cả",
                    _id:null
                });

                self.setState({
                    ListCard:ListCard
                });

            }


        }).catch((e)=>{

        });


    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    async PercentContract(id){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_wallet', id);

       var get_data=await fetch(Appthis.ConstApp.home_api+"transaction/percentsale", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=get_data.json();

        this.props.AppThis.PageLoad(false);

        return get_data;

    }

    async ExportWallet(){

     this.props.AppThis.PageLoad(true);

       var Appthis=this.props.AppThis;

       const formData = new FormData();

       formData.append('token', Appthis.getToken());

       formData.append('id_address', Appthis.getAddress());

       var query_send=this.getQuerySearch();

       formData.append('page', this.state.page);

       if(Object.keys(query_send).length>0){
           formData.append('query', base64_encode(query_send));
       }

       fetch(Appthis.ConstApp.home_api+"transaction/exports", {
           method: 'POST',
           mode: 'cors',
           body: formData
       }).then(data=>data.json()).then(data=>{

           if(data.status==='error'){
               toast("Thao tác thất bại");
           }
           else{
               toast("Thao tác đã được gửi tới máy chủ");
           }

               Appthis.PageLoad(false);


       }).catch((e)=>{
           toast("Thao tác thất bại");
       });


   }

    AlertExportWallet(){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ExportWallet();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    getListAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"transaction/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=data.data;

                ListAdmin.unshift({
                    key:null,
                    value:"Tất cả"
                });
                self.setState({
                    ListAdmin:ListAdmin
                });

            }


        }).catch((e)=>{

        });

    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        formData.append('page', this.state.page);

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;
        fetch(Appthis.ConstApp.home_api+"transaction", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    getStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"transaction/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Statics:data.data
                });

            }


        }).catch((e)=>{



        });


    }

    StaticsTransaction(){
        if(this.state.Statics!==null){
            return(
                <div className={"row  m-0  mt-4 mb-3"}>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mt-2 mb-2 item-admin-dashboard">

                        <div className="col-12 d-flex bg-white p-3 shadow box-item-dashboard">

                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <p className="text-uppercase mb-0 text-dark font14">Trong ngày</p>
                            </div>
                            <div className="right-item">
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.Statics.number_day}</p>
                            </div>

                        </div>

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3  mt-2 mb-2 item-admin-dashboard">

                        <div className="col-12 d-flex bg-white p-3 shadow box-item-dashboard">

                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <p className="text-uppercase mb-0 text-dark font14">Tiền trong ngày</p>
                            </div>
                            <div className="right-item">
                                <p className="number-info-dashboard m-0 font16 text-red font-weight-bold">{formatNumber(this.state.Statics.price_day)}</p>
                            </div>

                        </div>

                    </div>


                    {this.state.Statics.payment.map(function(item){

                        return(
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 d-none d-md-block mb-2 item-admin-dashboard mt-2 mb-2">

                                <div className="col-12 d-flex bg-white p-3 shadow box-item-dashboard">

                                    <div
                                        className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                        <p className="text-uppercase mb-0 text-dark font14">{item.name}</p>
                                    </div>
                                    <div className="right-item">

                                        <p className="number-info-dashboard m-0 text-red font16 font-weight-bold">
                                            {formatNumber(item.price)}
                                        </p>
                                    </div>

                                </div>

                            </div>
                        );
                    })}


                </div>
            );
        }
        else{
            return(
                <div className={"col-12 justify-content-center pt-4 d-flex flex-row"}>
                    <ItemLoading />
                </div>
            );
        }
    }

    async ActionPrintTransaction(item){

        var get_percent=await this.PercentContract(item._id);

        this.setState({
            PrintWallet:{
                data:item,
                percent:get_percent.data
            }
        });

    }

    thisRenderData(){
        const self=this;
        if(this.state.data.data.length>0){

            var data=this.state.data.data;
            return(
                <div className={"row m-0"}>

               {this.StaticsTransaction()}

                <div className={"col-12 box-table-fix"}>
                    <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 mw2500 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-glass-martini me-2" /> SỐ PHIẾU THU</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-money-check me-2"/> LOẠI PHIẾU THU</td>

                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-clock me-2" /> NGÀY THU TIỀN</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-user-alt me-2" />
                                TÊN HỘI VIÊN</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-credit-card me-2" />
                                LOẠI THANH TOÁN</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fab fa-google-pay me-2" />
                                PHƯƠNG THỨC TT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fab fa-salesforce me-2" />
                                MÃ KHUYẾN MẠI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-list-ol me-2" /> SỐ HD</td>

                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fal fa-address-card me-2" /> DỊCH VỤ</td>

                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-dollar-sign me-2" />
                                SỐ TIỀN THU</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-history me-2" />
                                NHÂN VIÊN BÁN HÀNG</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-calendar-times me-2" />
                                TRẠNG THÁI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-user-edit me-2" />
                                PHÂN LOẠI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                <i className="fas fa-info me-2" />
                                THAO TÁC
                            </td>
                        </tr>
                        </thead>
                        <tbody>


                        {data.map(function(item,index){
                            return(
                                <tr>
                                    <td>
                                        {item.status!==1?(
                                            <span className={'me-2 text-danger'}>
                                                        <i className="fas fa-minus-circle"></i>
                                                    </span>
                                        ):(
                                            <span className={'me-2 text-primary'}>
                                                      <i className="far fa-check-circle"></i>
                                                  </span>
                                        )}
                                        {item.number_votes}
                                    </td>

                                    <td>
                                        {get_name_type_receipts(item.type_receipts)}
                                    </td>
                                    <td>
                                        {timeConverterDate(item.time)}
                                    </td>
                                    <td>
                                        {item.id_user.username+" - " +item.id_user.code_user}
                                    </td>
                                    <td>
                                        {item.type_of_paid===1?(
                                            <span className={"badge bg-primary font12"}>Trả hết</span>
                                        ):(<></>)}
                                        {item.type_of_paid===2?(
                                            <span className={"badge bg-danger font12"}>Đặt cọc</span>
                                        ):(<></>)}
                                        {item.type_of_paid===3?(
                                            <span className={"badge bg-success font12"}>Trả nốt</span>
                                        ):(<></>)}
                                    </td>
                                    <td>
                                        {item.id_payment?item.id_payment.name:'___'}
                                    </td>
                                    <td>
                                        {item.id_member && item.id_member.id_discount.length>0?(
                                            item.id_member.id_discount.map(function(value,index){
                                                return(
                                                    <span className="badge bg-primary font13 mr-2">{value.code}</span>
                                                );
                                            })
                                        ):(
                                            <span>___</span>
                                        )}
                                    </td>
                                    <td>
                                        {item.id_member!==null && item.id_member.group_member!==null?(
                                            <i className="fas fa-check-circle text-primary me-1" />
                                        ):(<></>)}

                                        {item.id_member!==null && item.id_member.number_contract?item.id_member.number_contract:(
                                            <span>___</span>
                                        )}
                                    </td>

                                    <td>

                                        {item.id_member!==null?(
                                            <span>{get_name_type_card(item.id_member.id_card.type)}</span>
                                        ):(<>---</>)}

                                    </td>

                                    <td>
                                       <strong className={"text-red"}>{formatNumber(item.price)}</strong> VND
                                    </td>
                                    <td>
                                        {_isset_object(item.id_admin)?(
                                            <>
                                                {item.id_admin.username+" - "+item.id_admin.code_user}
                                            </>
                                        ):(
                                            <span className={"badge bg-warning font12"}>___</span>
                                        )}

                                    </td>
                                    <td>
                                        {item.status===1?(
                                            <span className="badge bg-primary font12">Thành công</span>
                                        ):(
                                            <></>
                                        )}

                                        {item.status===2?(
                                            <span className="badge bg-danger font12">Hủy</span>
                                        ):(
                                            <></>
                                        )}

                                        {item.status===0?(
                                            <span className="badge bg-warning font12">Đang chờ</span>
                                        ):(
                                            <></>
                                        )}

                                    </td>
                                    <td>
                                        {type_wallet(item)}
                                    </td>
                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.setState({
                                                InfoPay: {
                                                    data:item,
                                                    type:1
                                                }
                                            });
                                        }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết thanh toán">
                                                <i className={"fab fa-amazon-pay"}></i>
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.setState({
                                                InfoPay: {
                                                    data:item,
                                                    type:2
                                                }
                                            });
                                        }} className={"rounded-0 font12 d-inline-block btn btn-outline-dark p-2 me-1"}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chỉnh sửa phiếu thu">
                                                <i className="far fa-edit"></i>
                                            </Floater>
                                        </a>

                                        {item.status===1 && item.type_receipts!==2?(
                                            <a onClick={()=>{
                                                self.props.AppThis.SetHiddenHeader();
                                                self.ActionPrintTransaction(item);
                                            }} className={"rounded-0 font12 d-inline-block btn btn-outline-danger p-2 me-1"}>
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="In phiếu thu">
                                                <i className="fas fa-print" />
                                                </Floater>
                                            </a>
                                        ):(<></>)}

                                        {item.id_member!==null && typeof item.id_member!=='undefined'?(
                                            <>

                                                <a onClick={()=>{
                                                    self.setState({
                                                        UserFingerprint:item.id_user
                                                    });
                                                }} className={"rounded-0 font12 d-inline-block btn btn-outline-warning p-2 me-1"}>
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Quản lý vân tay tài khoản">
                                                        <i className="fas fa-fingerprint" />
                                                    </Floater>
                                                </a>

                                            </>
                                        ):(
                                            <></>
                                        )}



                                    </td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </table>
                    </div>
                </div>



                    <div className={"col-12 ps-3 mt-3 pe-3"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>


                </div>
            );
        }
        else{
            return(
              <div className={"row m-0"}>
                  <div className={"col-12"}>
                      <div className={"alert mt-3 alert-danger"}>
                          Không có dữ liệu
                      </div>
                  </div>
              </div>
            );
        }
    }

    render() {

        const self=this;

        if(this.state.data!==null){

            if(this.state.PrintWallet.data!==null){
               return(
                   <PrintTransaction data={this.state.PrintWallet} Onclose={()=>{
                       this.setState({
                           PrintWallet:{
                               data:null,
                               percent:null
                           }
                       });
                       self.props.AppThis.SetShowHeader();
                   }} AppThis={this.props.AppThis} />
               );
            }
            else{

                return(
                    <div className={"container-fluid container-main-template mb-5"}>


                        <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                    <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Danh sách phiếu thu</h3>
                                </div>

                                <div className="col-12 col-sm-12 text-right text-end">

                                    <div className="col-12 p-0 mb-2">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>

                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>



                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>



                                            <input onChange={(event)=>{
                                                var query=this.state.query;
                                                query.number_votes=event.target.value;
                                                this.setState({
                                                    query:query
                                                });

                                            }} autoComplete={"off"} type="text" name="input_search"
                                                   className="font13 form-control d-inline-block w-auto rounded-0"
                                                   placeholder="Số phiếu thu"/>

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>


                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:true,
                                                    ModalSearchMobile:false
                                                })
                                            }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                                <i className="fas fa-search-plus" /> Tìm kiếm nâng cao </a>

                                            <a onClick={()=>{

                                                this.setState({
                                                    NewsTransaction:true,
                                                    ModalSearchMobile:false
                                                });

                                            }} className="font13 btn me-1 btn-outline-danger rounded-0 btn-event-search">
                                                <i className="far fa-plus-square" /> Thêm phiếu thu </a>



                                        </div>

                                    </div>





                                </div>

                            </div>



                            {this.thisRenderData()}



                        </div>


                        {this.state.InfoPay.data!==null?(
                            <>

                                {this.state.InfoPay.type===1?(
                                    <InfoPayTransaction OnClose={()=>{
                                        this.setState({
                                            InfoPay:{
                                                data:null,
                                                type:null
                                            }
                                        });
                                        this.getData(true);

                                    }} path={"transaction"} AppThis={this.props.AppThis} data={this.state.InfoPay.data} />
                                ):(<></>)}


                                {this.state.InfoPay.type===2?(
                                    <>
                                        <EditTransaction OnClose={()=>{
                                            this.setState({
                                                InfoPay:{
                                                    data:null,
                                                    type:null
                                                }
                                            });
                                        }} onSuccess={()=>{
                                            this.setState({
                                                InfoPay:{
                                                    data:null,
                                                    type:null
                                                }
                                            });
                                            this.getData(true);
                                        }} path={"transaction"} AppThis={this.props.AppThis} data={this.state.InfoPay.data} />
                                    </>
                                ):(<></>)}

                            </>
                        ):(
                            <></>
                        )}


                        {this.state.UserFingerprint!==null?(
                            <FingerPrintUser onClose={()=>{
                                this.setState({
                                    UserFingerprint:null
                                },function(){
                                    self.getData(true);
                                });
                            }}  AppThis={this.props.AppThis} path={"transaction"} data={this.state.UserFingerprint} />
                        ):(
                            <></>
                        )}



                        {this.state.SearchAdvanced===true?(
                            this.SearchAdvanced()
                        ):(<></>)}


                        {this.state.NewsTransaction===true?(

                            <NewsTransaction path={"transaction"} AppThis={this.props.AppThis} onClose={()=>{
                                this.setState({
                                    NewsTransaction:false
                                },function(){
                                    self.getData(true);
                                })
                            }} />
                        ):(
                            <></>
                        )}




                    </div>
                );

            }
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );

        }
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm nâng cao</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row box_input"}>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {

                                        if(date!==null && typeof date!=='undefined'){
                                            this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                        }
                                        else{
                                            this.UpdateQuerySearch("start_time",null);
                                        }

                                    }} placeholder={"Thời gian bắt đầu"} />


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {

                                        if (date!==null && typeof date!=='undefined'){
                                            this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                        }
                                        else{
                                            this.UpdateQuerySearch("end_time",null);
                                        }

                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Tên khách hàng</p>
                                    <input defaultValue={this.state.query.input_search} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("input_search",event.target.value);
                                    }} className={"form-control"} placeholder={"Tên khách hàng"} />
                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số phiếu</p>
                                    <input defaultValue={this.state.query.number_votes} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("number_votes",event.target.value);
                                    }} className={"form-control"} placeholder={"Số phiếu"} />
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số Hợp đồng</p>
                                    <input defaultValue={this.state.query.number_contract} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("number_contract",event.target.value);
                                    }} className={"form-control"} placeholder={"Số hợp đồng"} />
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>NV bán hàng</p>

                                    {self.state.ListAdmin!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_admin=selectedOption.value;
                                            self.UpdateQuerySearch("id_admin",id_admin);
                                        }} defaultValue={this.state.query.id_admin!==null?getItemInArray(this.state.query.id_admin,"value",array_to_select(this.state.ListAdmin,"value","key")):null} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                    ):(
                                        <ItemLoading />
                                    )}


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>NV lễ tân</p>

                                    {self.state.ListAdmin!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_admin_update=selectedOption.value;
                                            self.UpdateQuerySearch("id_admin_update",id_admin_update);

                                        }}  defaultValue={this.state.query.id_admin_update!==null?getItemInArray(this.state.query.id_admin_update,"value",array_to_select(this.state.ListAdmin,"value","key")):null} options={array_to_select(this.state.ListAdmin,"value","key")} />
                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Trạng thái cập nhật PT</p>

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var status=selectedOption.value;
                                            self.UpdateQuerySearch("status",status);
                                        }} options={ListStatus}
                                                defaultValue={this.state.query.status!==null?getItemInArray(this.state.query.status,"value",ListStatus):null}
                                        />


                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Loại thanh toán PT</p>

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var status=selectedOption.value;
                                            self.UpdateQuerySearch("type_of_paid",status);
                                        }} options={ListTypeOfPaid}
                                                defaultValue={this.state.query.type_of_paid!==null?getItemInArray(this.state.query.type_of_paid,"value",ListTypeOfPaid):null}
                                        />




                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Loại dịch vụ</p>

                                    {this.state.ListCard!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var id_card=selectedOption.value;
                                            self.UpdateQuerySearch("id_card",id_card);
                                        }} options={array_to_select(this.state.ListCard,"name","_id")}
                                                defaultValue={this.state.query.id_card!==null?getItemInArray(this.state.query.id_card,"value",array_to_select(this.state.ListCard,"name","_id")):null}
                                        />
                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>Loại phiếu thu</p>
                                    <select onChange={(event)=>{
                                        self.UpdateQuerySearch("type_receipts",event.target.value);
                                    }} value={self.state.query.type_receipts} className={"form-control w-100"}>
                                        <option value={null}>Tất cả</option>
                                        <option value={1}>Phiếu thu đã có HD</option>
                                        <option value={2}>Phiếu thu trước HD</option>
                                        <option value={3}>Chuyển đổi PTTT</option>
                                    </select>

                                </div>



                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-10 offset-lg-2"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-4"}>
                                            <a onClick={()=>{
                                                self.getData(true);

                                                self.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-4"}>
                                            <a onClick={()=>{
                                                this.AlertExportWallet();
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                <i className="fas fa-search me-1" />  Xuất dữ liệu
                                            </a>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-lg-4"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-warning w-100 text-white mt-3"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }

}
