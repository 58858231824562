/* eslint-disable */
import React,{Component} from "react";
import {_isset_array, formatNumber, timeConverter, timeConverterDate} from "../../helper/array";
import ItemLoading from "../ItemLoading";
import {get_name_type_card} from "../../helper/member";
export default class InfoSale extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
        };

    }

    componentDidMount() {

        if(typeof this.props.data!=='undefined'){

            this.setState({
                data:this.props.data
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    render(){

        var data=this.state.data;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-info-discount">
                            <strong className={"font18 text-uppercase"}>Chi tiết chương trình KM</strong>
                        </div>

                        {data!==null?(
                            <>
                                <div className="modal-body">

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1 font-weight-bold">
                                                <i className="fas fa-info-circle me-1" />
                                                Tên KM :
                                            </label>
                                            <span className={"m-0 font12 ms-3 font-weight-bold badge bg-primary rounded-0"}>{data.name}</span>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fab fa-playstation me-1" />  Thẻ tập :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{data.id_card.name}</span>
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-money-bill-alt me-1" />
                                                Giá bán :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{formatNumber(data.price)} VND</span>
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-money-check-alt me-1" />
                                                Số buổi tập :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{data.number_card} Buổi</span>
                                        </div>



                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="far fa-clock me-1" />
                                               Ngày bắt đầu :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{timeConverterDate(data.time_start)} </span>
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="far fa-clock me-1" />
                                                Ngày kết thúc :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{timeConverterDate(data.time_end)}</span>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                            <label className="font-weight-bold mb-1">
                                                <i className="fas fa-bell me-1" />
                                                Ghi chú KM :
                                            </label>
                                            <span className={"m-0 p-0 ms-3"}>{data.note}</span>
                                        </div>


                                    </div>

                                </div>

                                <div className={"modal-footer bg-light"}>

                                    <div className={"w-100 row"}>

                                        <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                            <div className={"row"}>

                                                <div className={"col-12 col-sm-12 col-lg-6"}>

                                                </div>
                                                <div className={"col-12 col-sm-12 col-lg-6"}>
                                                    <a onClick={()=>{
                                                        if(typeof this.props.onClose!=='undefined'){
                                                            this.props.onClose()
                                                        };

                                                    }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                        Đóng
                                                    </a>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </>
                        ):(
                            <div className={"col-12 d-flex flex-row justify-content-center align-items-center mt-3 mb-3"}>
                                <ItemLoading />
                            </div>
                        )}

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }


}
