/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_array,
    array_to_select,
    base64_encode, defaultValueObject, getItemInArray, in_module, timeConverter,
} from "../../helper/array";
import CanvasJSReact from '../../helper/canvasjs.react';
import Floater from "react-floater";
import InfoUser from "../../global/user/InfoUser";
import HistoryPush from "../../global/push/HistoryPush";
import UpdateAdminUserClub from "../../global/user/UpdateAdminUserClub";
import Select from "react-select";
import {confirmAlert} from "react-confirm-alert";
import DatePicker from "react-datepicker";
import NewContract from "../../global/contract/NewContract";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const home_network=['viettel','vinaphone','mobifone','vietnamobile','gmobile','itelecom'];

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        id_admin:null,
        is_phone:null,
        start_time:null,
        end_time:null,
        id_static:null,
        number_call:null,
        id_group:null,
        id_subject:null,
        source:null
    },
    page:0,
    statics_marketing:{
        push:null,
        pushstatic:null,
        group:null
    },
    SearchAdvanced:false,
    InfoUser:null,
    HistoryPush:{
        status:false,
        data:null
    },
    UpdateAdminUserClub:null,
    ListAdmin:null,
    ListStatics:null,
    newContract:{
        status:false,
        phone:null
    },
    AllGroups:null,
    ListSubject:null
};


export default class subjectuser extends Component {
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    ShowHistoryPush(data){

        this.setState({
            HistoryPush:{
                status:true,
                data:data
            }
        });

    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subjectuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getReportPush();

        this.getStaticPush();

        this.getStaticGroup();

        this.getAdmin();

        this.GetAllStatics();

        this.getGroups();

        this.getListSubject();

    }

    getListSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListSubject=lodash.cloneDeep(data.data);

                ListSubject.unshift({
                    _id:"none",
                    name:"Tất cả"
                });

                self.setState({
                    ListSubject:ListSubject
                });

            }


        }).catch((e)=>{

        });

    }

    getGroups(){


        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('required_push', 1);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var AllGroups=data.data;

                AllGroups.unshift({
                    _id:null,
                    name:"Tất cả"
                });

                self.setState({
                    AllGroups:AllGroups
                });

            }


        }).catch((e)=>{



        });


    }
    GetAllStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){


                var ListStatics=lodash.cloneDeep(data.data);

                ListStatics.unshift({
                    _id:"none",
                    name:"Tất cả"
                });

                self.setState({
                    ListStatics:ListStatics
                });

            }


        }).catch((e)=>{

        });

    }


    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    getStaticGroup(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;
        fetch(Appthis.ConstApp.home_api+"subjectuser/staticgroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.StaticsGroupMonthMarketing(data.data);
            }

        }).catch((e)=>{
            toast("Lấy dữ liệu thất bại");
        });

    }


    StaticsGroupMonthMarketing(data){

        var dataPoints=[];

        if(data.length>0){

            data.map(function (value,key){
                dataPoints.push({
                    label: value.name, y: value.total_send_month,color:value.color
                });
            });
        }

        const options={
            animationEnabled: true,
            theme: "light2",
            title: {
            },
            axisY: {
                title: "Số"
            },
            axisX: {
                title: ""
            },
            data: [{
                type: "column",
                dataPoints: dataPoints
            }]
        };

        var statics_marketing=this.state.statics_marketing;

        statics_marketing.group=options;

        this.setState({
            statics_marketing:statics_marketing
        });

    }

    getReportPush(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        const self=this;
        fetch(Appthis.ConstApp.home_api+"subjectuser/reportpush", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.SetUpStaticsMonthPushMarketing(data.data);
            }

        }).catch((e)=>{
            toast("Lấy dữ liệu thất bại");
        });

    }

    SetUpStaticsMonthPushMarketing(data) {

        var data_report_push = [];

        Object.entries(data).forEach(entry => {
            const [key, value] = entry;

            data_report_push.push({
                x:new Date(key * 1000),
                y:value
            });


        });

        const options = {
            animationEnabled: true,
            theme: "light2",
            axisX: {
                valueFormatString: "DD MMM"
            },
            axisY: {
                title: "",
                suffix: " Số",
                minimum: 0
            },
            toolTip: {
                shared: true
            },
            legend: {
                cursor: "pointer",
                dockInsidePlotArea: true,
            },
            data: [
                {
                    type: "line",
                    showInLegend: true,
                    name: "Đẩy số",
                    lineDashType: "dash",
                    yValueFormatString: "#,##0",
                    dataPoints: data_report_push
                }

            ]
        };


        var statics_marketing=this.state.statics_marketing;

        statics_marketing.push=options;

        this.setState({
            statics_marketing:statics_marketing
        });


    }

    getStaticPush(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }
        const self=this;
        fetch(Appthis.ConstApp.home_api+"subjectuser/staticpush", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.SetUpStaticsPushMarketing(data.data);
            }


        }).catch((e)=>{
            toast("Lấy dữ liệu thất bại");
        });

    }

    SetUpStaticsPushMarketing(data){

        const dataPoints=[];

        data.data.map(function(value,key){

            dataPoints.push({
                y:value.percent,
                label:value.label,
                color:value.color
            });

        });

        const options={
            animationEnabled: true,
            title: {
                text: ""
            },
            data: [{
                type: "pie",
                startAngle: 240,
                indexLabel: "{label} {y}%",
                dataPoints: dataPoints
            }]
        };


        var statics_marketing=this.state.statics_marketing;

        statics_marketing.pushstatic=options;

        this.setState({
            statics_marketing:statics_marketing
        });



    }

    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"subjectuser/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=data.data;

                ListAdmin.unshift({
                    _id:null,
                    username:"Tất cả"
                });

                self.setState({
                    ListAdmin:ListAdmin
                });
            }

        }).catch((e)=>{

        });
    }

    CallUser(id){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('_id', id);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"subjectuser/getphone", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                if(typeof data.data!=='undefined'){
                    window.open("tel:"+data.data,"_blank");
                }
            }


        }).catch((e)=>{

        });
    }


    thisRenderData(){

        const self=this;

        var AppThis=this.props.AppThis;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix box-table-w300"}>
                    <div className={"col-12 overflow-auto"}>
                        <table className={"mt-3 w-100 table border-0 w-100 mw1500 table-style-old"}>
                            <thead>
                            <tr className={"border-bottom bg-light"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên khách hàng</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã khách hàng</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>TG gọi cuối</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>Bộ môn</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>Giới tính</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>NV chăm sóc</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                            </tr>
                            </thead>

                            <tbody>

                            {this.state.data.data.map(function(value,index){
                                return(
                                    <tr>
                                        <td>
                                            {value['count_proccess']!==0?(
                                                <i className="font16 text-danger far fa-minus-square me-2" />
                                            ):(
                                                <i className="font16 text-success fas fa-check-circle me-2" />
                                            )}

                                            {value['username']?value['username']:value['tmp_user']}</td>
                                        <td>{value['code_user']}

                                            {typeof value['id_admin'] && value['id_admin']!==null && value['id_admin'].length>0?(
                                                <>
                                                    <span className={"ms-1 badge bg-danger"}>MKT</span>
                                                </>
                                            ):(
                                                <></>
                                            )}

                                        </td>
                                        <td>{value['time_call']?timeConverter(value['time_call']):"___"}
                                            {value['number_call']?(
                                                <span className={"badge bg-success font10 ms-2"}>{value['number_call']}</span>
                                            ):(
                                                <></>
                                            )}

                                        </td>
                                        <td>{typeof value['statics']!=='undefined' && value['statics']['user_statics']?value['statics']['user_statics']:"___"}</td>
                                        <td>{typeof value['statics']!=='undefined' && value['statics']['user_subject']?(
                                            <>
                                                {value['statics']['user_subject'].map(function(it){
                                                    return(
                                                        <span className={"badge bg-danger m-1"}>{it}</span>
                                                    );
                                                })}
                                            </>
                                        ):("___")}</td>
                                        <td>{value['sex']===1?(
                                            <span className={"badge bg-success p-1 font11"}>Nam</span>
                                        ):(
                                            <span className={"badge bg-danger p-1 font11"}>Nữ</span>
                                        )}</td>

                                        <td>
                                            {typeof value['id_admin_club']!=='undefined' && value['id_admin_club']!==null && _isset_array(value['id_admin_club'])?(
                                                <>
                                                    {value['id_admin_club'][0]['username']+" - "+value['id_admin_club'][0]['code_user']}
                                                </>
                                            ):(
                                                <>
                                                    ___
                                                </>
                                            )}

                                        </td>


                                        <td className={"fix-item-table"}>


                                            <a onClick={()=>{
                                                self.setState({
                                                    InfoUser:value
                                                });
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết tài khoản khách hàng">

                                                    <i className="fas fa-user-tie" />
                                                </Floater>
                                            </a>


                                            <a onClick={()=>{
                                                self.ShowHistoryPush(value);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-warning p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Lịch sử chuyển số">
                                                    <i className="fas fa-history" />
                                                </Floater>
                                            </a>

                                            <a onClick={()=>{
                                                self.setState({
                                                    UpdateAdminUserClub:value
                                                });
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật NV chăm sóc KH">
                                                    <i className="fas fa-users-cog" />
                                                </Floater>
                                            </a>


                                            <a onClick={()=>{
                                                self.CallUser(value._id);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Gọi ngay">
                                                    <i className="fas fa-phone-alt" />
                                                </Floater>
                                            </a>


                                            {in_module('mypersonal',AppThis.GetInfoProfile().module)===true?(
                                                <a onClick={()=>{
                                                    self.setState({
                                                        newContract:{
                                                            status:true,
                                                            phone:value.user_login
                                                        }
                                                    });
                                                }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-info p-1 ps-2 pe-2">
                                                    <Floater  event={"hover"} title={"Chức năng"} placement={"top"} content="Thêm HD cho khách hàng này">
                                                        <i className="fas fa-plus-circle" />
                                                    </Floater>
                                                </a>
                                            ):(<></>)}

                                        </td>




                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;
        if(this.state.data!==null){

            if (this.state.newContract.status===true){
                return(
                    <NewContract DefaultUser={this.state.newContract.phone} Fingerprint={false} path={"mypersonal"} AppThis={this.props.AppThis} onClose={()=>{
                        this.setState({
                            newContract:{
                                status:false,
                                phone:null
                            }
                        });
                    }} />
                );
            }
            else{
                return(
                    <div className={"container-fluid container-main-template mb-5"}>


                        <div className={"col-12 pt-4 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center">
                                    <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold">
                                        Chăm sóc khách hàng theo bộ môn
                                    </h3>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right text-end">

                                    <div className="col-12 p-0 mb-3">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>


                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>

                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>



                                            <input onChange={(event)=>{
                                                this.UpdateQuerySearch("input_search",event.target.value);
                                            }} autoComplete={"off"} type="text" name="input_search"
                                                   className="font13 form-control d-inline-block w-auto rounded-0"
                                                   placeholder="Số điện thoại/Họ và tên"/>

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>


                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:true,
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-dark rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm theo tiêu chí</a>


                                        </div>

                                    </div>



                                </div>

                            </div>



                            <div className={"row mt-4 m-0"}>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6"}>

                                    <h3 className="font16 mb-3 text-uppercase font-weight-bold font-weight-bold">
                                        Thống kê theo nhóm nhận số Marketing
                                    </h3>

                                    {this.state.statics_marketing.group!==null?(
                                        <CanvasJSChart options={this.state.statics_marketing.group} />
                                    ):(
                                        <ItemLoading/>
                                    )}


                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6"}>

                                    <h3 className="font16 mb-3 text-uppercase font-weight-bold font-weight-bold">
                                        Trạng thái khách hàng nhận số từ Marketing
                                    </h3>

                                    {this.state.statics_marketing.pushstatic!==null?(
                                        <CanvasJSChart options={this.state.statics_marketing.pushstatic} />
                                    ):(
                                        <ItemLoading/>
                                    )}


                                </div>

                                <div className={"col-12 mt-4"}>

                                    <h3 className="font16 mb-3 text-uppercase font-weight-bold font-weight-bold">
                                        Nhận số trong tháng
                                    </h3>

                                    {this.state.statics_marketing.push!==null?(
                                        <CanvasJSChart options={this.state.statics_marketing.push} />
                                    ):(
                                        <ItemLoading/>
                                    )}

                                </div>



                            </div>





                            <div className={"col-12 ps-3 pe-3 mt-3"}>
                                {this.thisRenderData()}
                            </div>


                            <div className={"col-12 ps-3 pe-3"}>
                                <ul className="pagination">
                                    {this.state.data.prev>=0?(
                                        <li className="page-item" >
                                            <a className="page-link" onClick={()=>{
                                                var page=this.state.page-1;
                                                this.setState({
                                                    page:page
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Trước
                                            </a>
                                        </li>
                                    ):(<></>)}

                                    <li className="page-item">
                                        <a className="page-link">
                                            <span>{this.state.data.curent+1}</span>
                                        </a></li>

                                    {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                        <li className="page-item">
                                            <a className="page-link" onClick={()=>{
                                                this.setState({
                                                    page:this.state.data.next
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Kế tiếp
                                            </a>
                                        </li>
                                    ):(<></>)}

                                </ul>
                            </div>


                        </div>


                        {this.state.SearchAdvanced===true?(
                            <div className="modal d-block position-fixed">
                                <div className={"modal-dialog modal-lg"}>
                                    <div className="modal-content shadow border-0">
                                        <div className="modal-header">
                                            <strong className={"font18 text-uppercase"}>Tìm kiếm</strong>
                                        </div>
                                        <div className="modal-body">

                                            <div className={"row mb-2 mt-2"}>

                                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                                    <p className={"mb-1"}>SDT/Họ tên khách hàng</p>
                                                    <input type={"text"} onChange={(event)=>{
                                                        var query=this.state.query;
                                                        query.input_search=event.target.value;
                                                        this.setState({
                                                            query:query
                                                        });


                                                    }} defaultValue={this.state.query.input_search} className={"form-control"} placeholder={"Từ khoá tìm kiếm"} />
                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                    <p className={"mb-1"}>Nhân viên chăm sóc</p>

                                                    <>
                                                        {this.state.ListAdmin!==null?(
                                                            <Select menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{
                                                                var query=this.state.query;
                                                                query.id_admin=selectedOption.value;
                                                                this.setState({
                                                                    query:query
                                                                });

                                                            }} options={array_to_select(this.state.ListAdmin,'username','_id')} />
                                                        ):(
                                                            <ItemLoading />
                                                        )}</>

                                                </div>

                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                    <p className={"mb-1"}>Team</p>

                                                    <>
                                                        {this.state.AllGroups!==null?(
                                                            <Select menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{

                                                                this.UpdateQuerySearch("id_group",selectedOption.value);

                                                            }} defaultValue={this.state.query.id_group!==null?defaultValueObject(getItemInArray(this.state.query.id_group,"_id",self.state.AllGroups),"name","_id"):null}  options={array_to_select(this.state.AllGroups,'name','_id')} />
                                                        ):(
                                                            <ItemLoading />
                                                        )}</>

                                                </div>


                                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                                    <DatePicker isClearable selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                                        if(date!==null){
                                                            this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                                        }
                                                        else{
                                                            this.UpdateQuerySearch("start_time",null);
                                                        }

                                                    }} placeholder={"Thời gian bắt đầu"} />


                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-6 pb-3 box_input"}>
                                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                                    <DatePicker isClearable selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {

                                                        if(date!==null){
                                                            this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                                        }
                                                        else{
                                                            this.UpdateQuerySearch("end_time",null);
                                                        }

                                                    }} placeholder={"Thời gian kết thúc"} />

                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                    <p className={"mb-1"}>Phân loại SDT</p>
                                                    <select onChange={(event)=>{
                                                        var query=this.state.query;
                                                        query.is_phone=event.target.value;
                                                        this.setState({
                                                            query:query
                                                        });
                                                    }} className={"form-control rounded-0 w-100"}>
                                                        <option value={"none"}>Tất cả</option>

                                                        {home_network.map(function(v,k){
                                                            if(v===self.state.query.is_phone){
                                                                return(
                                                                    <option selected value={v}>{v}</option>
                                                                );
                                                            }
                                                            else{
                                                                return(
                                                                    <option value={v}>{v}</option>
                                                                );
                                                            }

                                                        })}

                                                    </select>
                                                </div>


                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                    <p className={"mb-1"}>Trạng thái KH</p>
                                                    {this.state.ListStatics!==null?(
                                                        <select onChange={(event)=>{
                                                            this.UpdateQuerySearch("id_static",event.target.value);
                                                        }} className={"form-control rounded-0 w-100"}>
                                                            {this.state.ListStatics.map(function(v,k){
                                                                if(v['_id']===self.state.query.id_static){
                                                                    return(
                                                                        <option selected value={v['_id']}>{v['name']}</option>
                                                                    );
                                                                }
                                                                else{
                                                                    return(
                                                                        <option value={v['_id']}>{v['name']}</option>
                                                                    );
                                                                }
                                                            })}

                                                        </select>
                                                    ):(
                                                        <>
                                                            <div className={"col-12 text-center"}>
                                                                <ItemLoading />
                                                            </div>
                                                        </>
                                                    )}

                                                </div>


                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                    <p className={"mb-1"}>Số lần gọi</p>

                                                    <select onChange={(event)=>{
                                                        this.UpdateQuerySearch("number_call",event.target.value);
                                                    }} className={"form-control rounded-0 w-100"}>
                                                        {[
                                                            {
                                                                _id:'none',
                                                                name:"Tất cả"
                                                            },
                                                            {
                                                                _id:"1",
                                                                name:"1 lần gọi"
                                                            }, {
                                                                _id:"22",
                                                                name:"2 lần gọi"
                                                            },
                                                            {
                                                                _id:"3",
                                                                name:"3 lần gọi"
                                                            },
                                                            {
                                                                _id:"4",
                                                                name:"4 lần gọi"
                                                            },
                                                            {
                                                                _id:"5",
                                                                name:"5 lần gọi"
                                                            },
                                                            {
                                                                _id:">5",
                                                                name:">5 lần gọi"
                                                            },

                                                        ].map(function(v,k){
                                                            if(v._id===self.state.query.number_call){
                                                                return(
                                                                    <option selected value={v._id}>{v.name}</option>
                                                                );
                                                            }
                                                            else{
                                                                return(
                                                                    <option value={v._id}>{v.name}</option>
                                                                );
                                                            }

                                                        })}

                                                    </select>
                                                </div>



                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                    <p className={"mb-1"}>Bộ môn</p>
                                                    {this.state.ListSubject!==null?(
                                                        <select onChange={(event)=>{
                                                            this.UpdateQuerySearch("id_subject",event.target.value);
                                                        }} className={"form-control rounded-0 w-100"}>
                                                            {this.state.ListSubject.map(function(v,k){
                                                                if(v['_id']===self.state.query.id_subject){
                                                                    return(
                                                                        <option selected value={v['_id']}>{v['name']}</option>
                                                                    );
                                                                }
                                                                else{
                                                                    return(
                                                                        <option value={v['_id']}>{v['name']}</option>
                                                                    );
                                                                }
                                                            })}

                                                        </select>
                                                    ):(
                                                        <>
                                                            <div className={"col-12 text-center"}>
                                                                <ItemLoading />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>


                                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                                    <p className={"mb-1"}>Nguồn</p>

                                                    <select onChange={(event)=>{
                                                        this.UpdateQuerySearch("source",event.target.value);
                                                    }} className={"form-control rounded-0 w-100"}>
                                                        {[
                                                            {
                                                                _id:null,
                                                                name:"Tất cả"
                                                            },
                                                            {
                                                                _id:"1",
                                                                name:"Marketing"
                                                            }, {
                                                                _id:"2",
                                                                name:"Tự kiếm / Hợp đồng"
                                                            },
                                                        ].map(function(v,k){
                                                            if(v._id===self.state.query.source){
                                                                return(
                                                                    <option selected value={v._id}>{v.name}</option>
                                                                );
                                                            }
                                                            else{
                                                                return(
                                                                    <option value={v._id}>{v.name}</option>
                                                                );
                                                            }

                                                        })}

                                                    </select>

                                                </div>


                                            </div>

                                        </div>


                                        <div className={"modal-footer w-100 bg-light"}>


                                            <div className={"col-12 col-sm-12 col-md-6 offset-md-8"}>

                                                <div className={"row w-100"}>

                                                    <div className={"col-12 col-sm-12 col-lg-4"}>
                                                    </div>


                                                    <div className={"col-12 col-sm-12 col-lg-4"}>
                                                        <a onClick={()=>{

                                                            this.setState({
                                                                SearchAdvanced:false,
                                                            },function(){
                                                                self.getData(true);
                                                            });

                                                        }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                            Tìm kiếm
                                                        </a>
                                                    </div>



                                                    <div className={"col-12 col-sm-12 col-lg-4"}>
                                                        <a onClick={()=>{
                                                            this.setState({
                                                                SearchAdvanced:false
                                                            });

                                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                            Đóng
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>



                                    </div>
                                </div>
                                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                            </div>

                        ):(
                            <></>
                        )}


                        {this.state.InfoUser!==null?(

                            <InfoUser data={this.state.InfoUser} onClose={()=>{
                                this.setState({
                                    InfoUser:null
                                });
                            }} />
                        ):(<></>)}


                        {this.state.HistoryPush.status===true?(
                            <HistoryPush DisableUpdate={true} path={"subjectuser"} AppThis={this.props.AppThis} data={this.state.HistoryPush.data}
                                         onClose={()=>{
                                             this.setState({
                                                 HistoryPush:{
                                                     status:false,
                                                     data:null
                                                 }
                                             });

                                         }} />
                        ):(<></>)}


                        {this.state.UpdateAdminUserClub!==null?(
                            <UpdateAdminUserClub path={"subjectuser"} data={this.state.UpdateAdminUserClub} onClose={()=>{
                                this.setState({
                                    UpdateAdminUserClub:null
                                });
                                this.getData(true);
                            }} AppThis={this.props.AppThis} />
                        ):(
                            <></>
                        )}


                    </div>
                );
            }

        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
