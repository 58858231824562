/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import {
    array_to_select,
    base64_encode, get_data_in_args,
    get_name_day,
    getItemInArray,
} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import Select, {components} from "react-select";

export default class UpdateGroupStatics extends Component {

    constructor(props) {
        super(props);
        this.state={
            data:null,
            GroupStatics:null,
            ListAdmin:null,
            DataUpdate:{
                id_admin:null,
                id_group_statics:null,
                sub_id_admin:[]
            }
        }
    }

    AlertSubmitStaticsGroup(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn cập nhật nhóm HD cố định trong ngày ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.SubmitStaticsGroup();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    SubmitStaticsGroup(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        var DataUpdate=this.state.DataUpdate;

        const self=this;

        var number_error=0;

        if(DataUpdate.id_admin===null || DataUpdate.id_group_statics===null){
            number_error+=1;
        }

        var data_send=base64_encode(DataUpdate);

        if(number_error===0){

            formData.append('data',data_send);

            AppThis.PageLoad(true);

            fetch(AppThis.ConstApp.home_api+this.props.path+"/updategroup", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){
                    toast("Cập nhật thành công");
                }
                else{
                    toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
                }

                AppThis.PageLoad(false);

                self.props.onClose();

            }).catch((e)=>{
                toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
                AppThis.PageLoad(false);
                self.props.onClose();

            });
        }
        else{
            toast("Vui lòng kiểm tra lại");
        }



    }

    componentDidMount() {

        this.GetGroupStatics();

        this.getAdmin();

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{

        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getAdmin(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=[];

                ListAdmin=ListAdmin.concat(data.data);

                self.setState({
                    ListAdmin:ListAdmin
                });

            }

        }).catch((e)=>{

        });


    }

    GetGroupStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());

        fetch(Appthis.ConstApp.home_api+"groupstatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                this.setState({
                    GroupStatics:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    OptionLayout(props,GroupStatics){
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{props.data.label}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>
                    <p className={"m-0"}>HLV : {get_data_item.id_admin_pt.username+" - "+get_data_item.id_admin_pt.code_user}</p>
                    <p className={"m-0"}>Ngày :
                        {Object.keys(get_data_item.days).length>0?(
                            Object.entries(get_data_item.days).map(function(item,index){
                                return(<span className={"m-1 badge font10 bg-black-50 p-1"}>{get_name_day(item[1])}</span>);
                            })
                        ):(<></>)}
                    </p>
                    <p className={"m-0"}>giờ : {get_data_item.time_start} - {get_data_item.time_end}</p>
                </components.Option>
            );
        }
        else{
            return(<></>);
        }

    }

    render(){

        const self=this;

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-md"}>
                    <div className="modal-content">
                        <div className="modal-header bg-dark">
                            <h5 className="modal-title font16 text-uppercase">Cập nhật nhóm HD cố định</h5>
                        </div>
                        <div className="modal-body InputMask">

                            <div className={"alert alert-warning rounded-0 w-100"}>
                                <i className="fas fa-exclamation-triangle me-2" />
                                Cập nhật PT cho nhóm HD cố định trong ngày
                            </div>


                            <div className={"row mt-3"}>


                                <div className={"col-12 col-sm-12 col-md-6 Box-Select-Mutiple"}>

                                    <label className={"d-block mb-2"}>Chọn nhóm cố định :
                                    <span className={"text-red"}>( * )</span>
                                    </label>


                                    {this.state.GroupStatics!==null?(
                                        <Select  isSearchable={true} onChange={(selectedOption)=>{

                                            var DataUpdate=this.state.DataUpdate;

                                            DataUpdate['id_group_statics']=selectedOption['value'];

                                            this.setState({
                                                DataUpdate:DataUpdate
                                            });


                                        }} options={array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                            Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                        }} />
                                    ):(
                                        <ItemLoading />
                                    )}



                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 Box-Select-Mutiple"}>
                                    <label className={"d-block mb-2"}>Chọn HLV :
                                        <span className={"text-red"}>( * )</span>
                                    </label>


                                    {this.state.ListAdmin!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{

                                            var DataUpdate=this.state.DataUpdate;

                                            DataUpdate['id_admin']=selectedOption['value'];

                                            this.setState({
                                                DataUpdate:DataUpdate
                                            });

                                        }} options={array_to_select(this.state.ListAdmin,"value","key")} />


                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 Box-Select-Mutiple mt-2"}>
                                    <label className={"d-block mb-2"}>Trợ giảng :
                                        <span className={"text-red"}>( * )</span>
                                    </label>


                                    {this.state.ListAdmin!==null?(

                                        <Select isMulti={true} isSearchable={true} onChange={(selectedOption)=>{

                                            var DataUpdate=this.state.DataUpdate;

                                            DataUpdate['sub_id_admin']=get_data_in_args(selectedOption,"value");

                                            this.setState({
                                                DataUpdate:DataUpdate
                                            });

                                        }} options={array_to_select(this.state.ListAdmin,"value","key")} />


                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>

                                <div className={"col-12 mt-3"} />

                                <div className={"col-12 col-sm-12 col-md-6 mt-3"}>

                                    <button type="button" onClick={()=>{
                                        this.AlertSubmitStaticsGroup();
                                    }} className="btn rounded-0 w-100 btn-primary">
                                        Cập nhật
                                    </button>
                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 mt-3"}>

                                    <button onClick={()=>{
                                        this.props.onClose();
                                    }} type="button" className="btn rounded-0 w-100 btn-secondary">
                                        Huỷ
                                    </button>
                                </div>

                            </div>

                        </div>



                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }
}
