/* eslint-disable */
import React,{Component} from "react";
import {timeConverter, timeConverterDate, formatNumber, _isset_object} from "../../helper/array";
import lodash from "lodash";
import {number_did_contract} from "../../helper/member";
import no_avatar from "../../assets/images/_no_avatar.png";
import ConstApp from "../../ConstApp";
export default class BoxContract extends Component{

    constructor(props) {
        super(props);

        this.state={
            data:null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.data!==null){

            if (this.state.data._id!==this.props.data._id){

                this.setState({
                    data:lodash.cloneDeep(this.props.data)
                });

            }

        }
    }

    componentDidMount() {

        if(this.props.data){

            this.setState({
                data:lodash.cloneDeep(this.props.data)
            });

        }

    }

    getDetailContract(id=null){

        if(typeof this.props.EventDetail!=='undefined'){

            this.props.EventDetail(id);
        }

    }

    TypeContract(data){
        switch (data.type_member){
            case 1:
                return (<span className={"badge bg-primary font12 rounded-0"}> New</span>);
            case 2:
                return (<span className={"badge bg-info font12 rounded-0"}> Renew</span>);
            case 3:
                return (<span className={"badge bg-danger font12 rounded-0"}> Upgrade</span>);
            case 4:
                return (<span className={"badge bg-warning font12 rounded-0"}> Transfer</span>);

            case 5:
                return (<span className={"badge bg-dark font12 rounded-0"}> New Sale</span>);
        }

    }

    render(){

        const self=this;

        if(this.state.data!=null){

            var data=this.state.data;

            return(
                <>
                    <div className={"row m-0"}>

                        <div className={"col-12 col-sm-12 col-md-12 col-xl-6 p-0"}>

                            <div className={"col-12 p-2 ps-3 text-white font16 font-weight-bold text-uppercase TitleDetailContract"}>THÔNG TIN KHÁCH HÀNG</div>

                            <div className={"col-12 pe-2"}>


                                <div className={"col-12 ps-3 pe-3 mt-4 mb-4"}>

                                    {data.id_user.avatar!==null ?(
                                            <img className={"w-100 h-auto"} src={ConstApp.home_url +"/" +data.id_user.avatar} />

                                    ):(
                                            <img className={"w-100 h-auto"} src={no_avatar} />
                                    )}

                                </div>

                                <table className={"w-100 mw-auto table"}>
                                    <tbody>
                                    <tr>
                                        <td>Họ và tên</td>
                                        <td><i className="far fa-user me-1" /> {data.id_user.username}</td>
                                    </tr>

                                    {data.id_user.email!==null?(
                                        <tr>
                                            <td>Email</td>
                                            <td><i className="far fa-envelope me-1" /> {data.id_user.email}</td>
                                        </tr>
                                    ):(
                                        <></>
                                    )}


                                    {data.type_target===1?(
                                        <></>
                                    ):(
                                        <tr>
                                            <td>SDT/ID</td>
                                            <td><i className="fas fa-mobile-alt me-1" /> {data.id_user.user_login}</td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>Giới tính</td>
                                        <td><i className="fas fa-genderless me-1" />
                                            {data.id_user.sex===1?(
                                                <span>Nam</span>
                                            ):(
                                                <span>Nữ</span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>mã hội viên</td>
                                        <td><i className="fas fa-shield-alt" /> {data.id_user.code_user}</td>
                                    </tr>
                                    <tr>
                                        <td>Mã số thẻ</td>
                                        <td><i className="far fa-credit-card" /> {data.id_user.card_user?data.id_user.card_user:(
                                            <span className={"badge bg-danger"}>Chưa có</span>
                                        )}</td>
                                    </tr>
                                    </tbody>

                                </table>




                            </div>


                        </div>

                        <div className={"col-12 col-sm-12 col-md-12 col-xl-6 p-0"}>

                            <div className={"col-12 p-2 ps-3 text-white font16 font-weight-bold text-uppercase TitleDetailContract"}>
                                THÔNG TIN GÓI TẬP
                            </div>


                            <div className={"col-12 ps-2"}>
                                <table className={"w-100 mw-auto table"}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <i className="fas fa-map-marker-alt me-1" />
                                            Cơ sở</td>
                                        <td>
                                            <span className={"font18 text-uppercase font-weight-bold text-red"}>{data.id_address.name}</span>
                                        </td>
                                    </tr>


                                    {data.id_card.type!==5?(
                                        <tr>
                                            <td><i className="far fa-clock me-1" /> Số buổi còn lại</td>
                                            <td>
                                                <span className={"font-weight-bold text-red font20"}>{number_did_contract(data)} Buổi</span>
                                            </td>
                                        </tr>
                                    ):(
                                        <></>
                                    )}





                                    <tr>
                                        <td>
                                            <i className="fas fa-sort-numeric-down me-1" />
                                            Số HD</td>
                                        <td>{data.number_contract}</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <i className="fab fa-salesforce me-1" />
                                            Chương trình KM
                                        </td>
                                        <td>
                                           <span className={"badge bg-warning font11"}> {data.id_saleprogram && typeof data.id_saleprogram!=='undefined'?data.id_saleprogram.name:"Không có"}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <i className="far fa-credit-card me-1" />
                                            Gói thẻ</td>
                                        <td>{data.id_card.name}</td>
                                    </tr>


                                    {data.id_card.type!==5?(
                                        <tr>
                                            <td>
                                                <i className="fas fa-font me-1" />
                                                Loại HD
                                            </td>
                                            <td>
                                                {this.TypeContract(data)}
                                            </td>
                                        </tr>

                                    ):(
                                        <></>
                                    )}


                                    {data.id_card.type===5?(
                                        <>
                                            <tr>
                                                <td><i className="fas fa-info me-1" />Số Tủ
                                                </td>
                                                <td>{data.number_coffer}</td>
                                            </tr>
                                        </>
                                    ):(
                                        <></>
                                    )}

                                    {data.id_card.type!==2 && data.id_card.type!==5?(
                                        <>
                                            <tr>
                                                <td><i className="far fa-clock me-1" /> Số buổi PT</td>
                                                <td>{data.info_contract_data.number_card}</td>
                                            </tr>


                                                <tr>
                                                    <td><i className="far fa-clock me-1" /> Số buổi PT KM </td>
                                                    <td>
                                                        {data.id_card.type===1?(data.info_contract_data.sale_discount.number_pt_sale):(data.id_card.number_day_in_month*data.info_contract_data.sale_discount.number_month_sale)}
                                                        <i className={"ms-1 font11"}>( Đã bao gồm )</i>
                                                    </td>
                                                </tr>


                                            <tr>
                                                <td><i className="far fa-clock me-1" /> Số buổi PT đã tập</td>
                                                <td>{data.info_contract_data.number_did_pt}</td>
                                            </tr>
                                            {data.id_admin_pt!==null?(
                                                <tr>
                                                    <td>
                                                        <i className="far fa-user me-1" />
                                                        Huấn luyện viên</td>
                                                    <td>
                                                        {data.id_admin_pt.username+" - "+data.id_admin_pt.code_user}
                                                    </td>
                                                </tr>
                                            ):(
                                                <></>
                                            )}


                                        </>
                                    ):(<>

                                       <></>

                                    </>)}




                                    <tr>
                                        <td><i className="far fa-clock me-1" />  Ngày hết hạn đặt cọc</td>
                                        <td>{timeConverterDate(data.info_contract_data.end_day_stop)}</td>
                                    </tr>


                                    {data.id_card.type!==5?(
                                        <>
                                            <tr>
                                                <td><i className="far fa-question-circle me-1" /> Yêu cầu</td>
                                                <td>
                                                    {data.id_requires.length>0?(
                                                        data.id_requires.map(function(item,index){
                                                            return(
                                                                <span className={"m-1 font11 badge bg-primary"}>{item.name}</span>
                                                            );
                                                        })
                                                    ):(
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><i className="fab fa-accessible-icon me-1" /> Bộ môn</td>
                                                <td>
                                                    {data.id_subject.length>0?(
                                                        data.id_subject.map(function(item,index){
                                                            return(
                                                                <span className={"m-1 font11 badge bg-danger"}>{item.name}</span>
                                                            );
                                                        })
                                                    ):(
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    ):(
                                        <></>
                                    )}

                                    <tr>
                                        <td><i className="far fa-clock me-1" /> Ngày bắt đầu</td>
                                        <td>
                                            <span>{timeConverterDate(data.start_day)}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><i className="far fa-clock me-1" /> Ngày kết thúc</td>
                                        <td>{timeConverterDate(data.end_day_contract)}</td>
                                    </tr>

                                    {typeof data.source_contract!=='undefined' && data.source_contract!==null?(
                                        <tr>
                                            <td><i className="fas fa-info me-1" /> Nguồn HD
                                            </td>
                                            <td>{data.source_contract.name}</td>
                                        </tr>
                                    ):(
                                        <></>
                                    )}


                                    <tr>
                                        <td colSpan={2}><i className="far fa-sticky-note me-1" /> Ghi chú gói tập
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>{data.note_member}</td>
                                    </tr>





                                    {data.group_member!==null?(
                                        <tr>
                                            <td><i className="fas fa-user-friends me-1" />HD doanh nghiệp</td>
                                            <td>{data.group_member.name+" - "+data.group_member.code_group}</td>
                                        </tr>
                                    ):(<></>)}


                                    {data.group_member_statics!==null?(
                                        <>
                                            <tr>
                                                <td><i className="fas fa-user-friends me-1" /> Nhóm cố định</td>
                                                <td>{data.group_member_statics.name+" - "+data.group_member_statics.code_group}</td>
                                            </tr>
                                            <tr>
                                                <td><i className="far fa-clock me-1" /> Khung giờ</td>
                                                <td>
                                                    <span>{data.group_member_statics.time_start}</span> -
                                                    <span>{data.group_member_statics.time_end}</span>
                                                </td>
                                            </tr>

                                        </>
                                    ):(<></>)}







                                    </tbody>

                                </table>
                            </div>






                            {data.id_user_sub!==null && _isset_object(data.id_user_sub)?(
                                <>
                                    <p className={"col-12 font-weight-bold text-uppercase font14 p-0 mb-2 mt-2"}>Người đi kèm cố định</p>
                                    <table className={"w-100 mw-auto table"}>

                                        <thead>
                                        <tr>
                                            <td>Họ và tên</td>

                                            <td>SDT/ID</td>

                                            <td>Giới tính</td>

                                            <td>Mã thẻ</td>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {data.id_user_sub.map(function(item){
                                            return(
                                                <tr>
                                                    <td>{item.username}</td>
                                                    <td>{item.user_login}</td>
                                                    <td>{item.sex===0?("Nữ"):("Nam")}</td>
                                                    <td>{item.card_user}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </>
                            ):(
                                <></>
                            )}




                            {data.protector!==null?(
                                <>
                                    <p className={"col-12 font-weight-bold text-uppercase font14 p-0 mb-2 mt-2"}>Người liên hệ khẩn cấp</p>
                                    <table className={"w-100 mw-auto table"}>
                                        <tbody>
                                        <tr>
                                            <td>Họ và tên</td>
                                            <td>
                                                <i className={"far fa-user me-1"} />
                                                {data.protector.username}</td>
                                        </tr>

                                        <tr>
                                            <td>SDT/ID</td>
                                            <td>
                                                <i className={"fas fa-mobile-alt me-1"} />
                                                {data.protector.user_login}</td>
                                        </tr>
                                        <tr>
                                            <td>Giới tính</td>
                                            <td>
                                                <i className={"fas fa-genderless me-1"} />
                                                {data.protector.sex===1?(
                                                    <span>Nam</span>
                                                ):(
                                                    <span>Nữ</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Mối quan hệ</td>
                                            <td>
                                                {data.protector.relationship}
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </>
                            ):(
                                <></>
                            )}

                            {data.contact_person!==null?(
                                <>
                                    <p className={"col-12 font-weight-bold text-uppercase font14 p-0 mb-2 mt-2"}>Người đồng ký tên</p>
                                    <table className={"w-100 mw-auto table"}>
                                        <tbody>
                                        <tr>
                                            <td>Họ và tên</td>
                                            <td>
                                                <i className={"far fa-user me-1"} />
                                                {data.contact_person.username}</td>
                                        </tr>

                                        <tr>
                                            <td>SDT/ID</td>
                                            <td>
                                                <i className={"fas fa-mobile-alt me-1"} />
                                                {data.contact_person.user_login}</td>
                                        </tr>
                                        <tr>
                                            <td>Giới tính</td>
                                            <td>
                                                <i className={"fas fa-genderless me-1"} />
                                                {data.contact_person.sex===1?(
                                                    <span>Nam</span>
                                                ):(
                                                    <span>Nữ</span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Mối quan hệ</td>
                                            <td>
                                                {data.contact_person.relationship}
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </>
                            ):(
                                <></>
                            )}

                            {typeof data.id_admin_marketing!=='undefined'?(
                                <div className={"col-12 col-sm-12"}>

                                    <div className={"alert alert-success rounded-0 font-weight-bold"}>
                                        <i className="fas fa-font me-1" /> Nhân viên Marketing
                                    </div>

                                    {data.id_admin_marketing.length>0?(
                                        <>
                                            <table className="table w-100 min-width-auto">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Thời gian</th>
                                                    <th scope="col">Nhân viên</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {data.id_admin_marketing.map(function(item){
                                                    return(
                                                        <tr>
                                                            <td>{timeConverter(item.time)}</td>
                                                            <td>{item.username} - {item.code_user}</td>
                                                        </tr>
                                                    );
                                                })}


                                                </tbody>
                                            </table>

                                        </>
                                    ):(
                                        <div className={"alert alert-warning"}>
                                            Không có NV marketing nào được ghi nhận
                                        </div>
                                    )}


                                </div>
                            ):(
                                <></>
                            )}




                        </div>

                    </div>

                    <div className={"row m-0"}>

                        {typeof this.props.HiddenPayment!=='undefined'?(
                            <></>
                        ):(
                            <div className={"col-12 col-sm-12 col-md-12 col-xl-6 p-0"}>

                                <div className={"col-12 p-2 ps-3 mb-3 text-white font16 font-weight-bold text-uppercase TitleDetailContract"}>
                                    THÔNG TIN THANH TOÁN
                                </div>

                                <div className={"col-12 pe-2"}>
                                    {data.billing_information.data.length>0?(
                                        <table className={"w-100 mw-auto table"}>
                                            <tbody>
                                            <tr>
                                                <td>SỐ PT</td>
                                                <td>TỔNG TIỀN</td>
                                                <td>THỜI GIAN</td>
                                            </tr>

                                            {data.billing_information.data.map(function(item,index){
                                                return(
                                                    <tr>
                                                        <td>{item.number_votes}</td>
                                                        <td>{formatNumber(item.price)}</td>
                                                        <td>{timeConverterDate(item.time)}</td>
                                                    </tr>
                                                );
                                            })}

                                            </tbody>

                                        </table>

                                    ):(
                                        <div className={"alert alert-danger rounded-0"}>
                                            Chưa có lịch sử giao dịch
                                        </div>
                                    )}
                                </div>


                                <div className={"col-12 mt-2 mb-2 pe-2"}>
                                    <div className="col-12 mt-4 font-weight-bold p-0 text-uppercase">

                                        Đã Thanh toán : <span className="text-red font-weight-bold">
                                                {formatNumber(data.billing_information.total_price)}
                                            </span> VNĐ

                                    </div>

                                    <hr/>

                                    <div className="col-12 font-weight-bold text-uppercase p-0 mb-4">
                                        Tổng tiền hòan trả :
                                        <span className="font-weight-bold">{formatNumber(data.number_refund)}</span> VNĐ
                                    </div>

                                </div>




                            </div>
                        )}

                        {typeof this.props.HiddenCardInfo!=='undefined'?(
                            <></>
                        ):(
                            <div className={"col-12 col-sm-12 col-md-12 col-xl-6 p-0"}>

                                <div className={"col-12 p-2 ps-3 text-white font16 font-weight-bold text-uppercase TitleDetailContract"}>
                                    THÔNG TIN THẺ TẬP
                                </div>

                                <div className={"col-12 pt-2 ps-2"}>

                                    <label className="text-uppercase w-100 mb-2 d-block font-weight-bold">Dịch
                                        vụ đi kèm thẻ tập</label>

                                    {Object.keys(data.id_card['id_service']).length>0?(

                                        data.id_card['id_service'].map(function(item){
                                            return(
                                                <span className={"badge bg-success font13 m-1"}>
                                                          {item.name}
                                                      </span>
                                            );
                                        })

                                    ):(
                                        <span className={"badge bg-warning font13 m-1"}>Không có</span>
                                    )}


                                    <label className="text-uppercase mt-2 mb-2 d-block w-100 font-weight-bold">Sản
                                        phẩm khuyến mại</label>

                                    {Object.keys(data.product_sale).length>0?(
                                        <table className="w-100 mw-auto table">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    Tên sản phẩm
                                                </td>
                                                <td>
                                                    Số lượng
                                                </td>
                                                <td>
                                                    Đã lấy
                                                </td>
                                            </tr>

                                            {data.product_sale.map(function(item,index){
                                                return(
                                                    <tr>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                        <td>
                                                            {item.number}
                                                        </td>
                                                        <td>
                                                            {item.took}
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                            </tbody>

                                        </table>
                                    ):(
                                        <div className={"alert alert-warning rounded-0"}>
                                            Không có sản phẩm nào
                                        </div>
                                    )}


                                    {data.id_card.note.length>0?(
                                        <div className={"col-12"}>
                                            <label className="text-uppercase w-100 mb-2 mt-2 d-block font-weight-bold">GHI CHÚ THẺ</label>

                                            <div className={"alert alert-warning rounded-0"}>
                                                {data.id_card.note}
                                            </div>
                                        </div>
                                    ):(<></>)}




                                </div>


                            </div>
                        )}



                    </div>

                    <div className={"row m-0"}>

                        {typeof this.props.HistoryContract!=='undefined'?(
                            <></>
                        ):(
                            <div className={"col-12 col-sm-12 col-md-12 col-xl-6 p-0"}>

                                <div className={"col-12 p-2 ps-3 mb-3 text-white font16 font-weight-bold text-uppercase TitleDetailContract"}>
                                    Lịch sử hợp đồng
                                </div>

                                <div className={"col-12 pe-2"}>
                                    {Object.keys(data.member_convert).length>0?(
                                        <ul className="nav d-block w-100">
                                            {data.member_convert.map(function(item,index){
                                                return(
                                                    <li className="mb-2 d-block pb-2 border-bottom">
                                                        <a href={"#"} onClick={()=>{
                                                            self.getDetailContract(item._id);
                                                        }}>
                                                            <i className="far fa-check-circle me-1" />
                                                            {item.key===1?(
                                                                <span>Chuyển nhượng từ HD :</span>
                                                            ):(<></>)}

                                                            {item.key===2?(
                                                                <span>Chuyển nhượng tới HD :</span>
                                                            ):(<></>)}

                                                            {item.key===3?(
                                                                <span>Upgrade từ HD :</span>
                                                            ):(<></>)}


                                                            {item.key===4?(
                                                                <span>Upgrade tới HD :</span>
                                                            ):(<></>)}

                                                            {item.key===5?(
                                                                <span>Renew từ HD :</span>
                                                            ):(<></>)}


                                                            {item.key===6?(
                                                                <span>Renew tới HD :</span>
                                                            ):(<></>)}

                                                            <span className="font-weight-bold ms-2">{item.number_contract}</span>

                                                            <span className={"font10 ms-2"}>( {timeConverter(item.time)} )</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    ):(<div className={"alert alert-warning rounded-0"}>
                                        Chưa có lịch sử
                                    </div>)}
                                </div>


                            </div>
                        )}


                        <div className={"col-12 col-sm-12 col-md-12 col-xl-6 p-0"}>

                            <div className={"col-12 p-2 ps-3 text-white font16 font-weight-bold text-uppercase TitleDetailContract"}>
                                KHOẢN THU
                            </div>

                            <div className={"col-12 ps-2"}>

                                <table className={"w-100 mw-auto table"}>

                                    <tbody>

                                    <tr>
                                        <td className={"font-weight-bold text-uppercase"}>GIÁ BÁN</td>
                                        <td>
                                                        <span className={"font-weight-bold text-red font16"}>
                                                            {formatNumber(data.info_contract_data.price_sale)} VNĐ
                                                        </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"font-weight-bold text-uppercase"}>PHÍ THÀNH VIÊN</td>
                                        <td>
                                                        <span className={"font-weight-bold"}>
                                                            {formatNumber(data.info_contract_data.price_manager_card)} VNĐ
                                                        </span>
                                        </td>
                                    </tr>

                                    {data.id_card.type!==5?(
                                        <>
                                            <tr>
                                                <td>VAT <span>{data.vat} (%)</span>
                                                </td>
                                                <td>
                                                    {formatNumber(data.info_contract_data.price_vat)} VNĐ
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Phí bảo hiểm :</td>
                                                <td>
                                                    {formatNumber(data.info_contract_data.price_insurance)} VNĐ
                                                </td>
                                            </tr>
                                        </>
                                    ):(
                                        <></>
                                    )}


                                    <tr>
                                        <td>Mã giảm giá</td>
                                        <td>

                                            {data.id_discount.length>0?(
                                                data.id_discount.map(function(item,index){
                                                    return(
                                                        <span className="badge bg-primary font12 m-1">
                                                                        {item['code']}
                                                                    </span>
                                                    );
                                                })
                                            ):(
                                                <span className={"badge bg-danger font11"}>Chưa có</span>
                                            )}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Chi phí giảm trừ :</td>
                                        <td>
                                            {formatNumber(data.info_contract_data.sale_discount.total_price_discount_sale)} VNĐ
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>% mã giảm giá :</td>
                                        <td>
                                            {data.info_contract_data.sale_discount.price_discount_percent_sale?data.info_contract_data.sale_discount.price_discount_percent_sale:0} %
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Nhân viên bán hàng :</td>
                                        <td>
                                            <span>{data.id_admin_create.username}</span>
                                            -  <span> {formatNumber(data.id_admin_create.code_user)}
                                                    </span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ngày lên hợp đồng</td>
                                        <td>
                                            {timeConverter(data.time_create,true)}
                                        </td>
                                    </tr>

                                    </tbody>


                                </table>

                            </div>

                        </div>



                    </div>
                </>
            );
        }
        else{
            return(<></>);
        }
    }
}
