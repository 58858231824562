/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_string,
    base64_encode,
    formatNumber
} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import EditModal from "../../global/EditModal";
import Warehouse from "../../global/warehouse/Warehouse";
import Floater from 'react-floater';
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
    },
    DataModal:{
        status:false,
        title:null,
        data:null,
        _id:null
    },
    DataWarehouse:{
        status:false,
        info:{},
    },

    page:0
};

export default class cproducts extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    OpenModal(data=null){


        var DataModal={
            status:true,
            title:'Quản lý Nhóm',
            data:[
                {
                    title:"Tên sản phẩm",
                    value:null,
                    name:"name",
                    type:"input"
                },
                {
                    title:"Màu sắc",
                    value:"#000",
                    name:"color",
                    type:"color"
                },
                {
                    title:"Giá",
                    value:0,
                    name:"price",
                    type:"number"
                },
                {
                    title:"Phân loại",
                    value:0,
                    name:"type",
                    type:"select",
                    data:[
                        {
                            value:"Nước",
                            key:0
                        },
                        {
                            value:"Khăn tắm",
                            key:1
                        },
                        {
                            value:"Khăn tập",
                            key:2
                        },
                        {
                            value:"Loại khác",
                            key:3
                        }
                    ]
                },
            ],
            _id:null
        };

        if(data!==null){
            DataModal={
                status:true,
                title:'Quản lý sản phẩm',
                data:[
                    {
                        title:"Tên sản phẩm",
                        value:data.name,
                        name:"name",
                        type:"input"
                    },
                    {
                        title:"Màu sắc",
                        value:data.color?data.color:"#FFF",
                        name:"color",
                        type:"color"
                    },
                    {
                        title:"Giá",
                        value:data.price?data.price:0,
                        name:"price",
                        type:"number"
                    },
                    {
                        title:"Phân loại",
                        value:data.type?data.type:0,
                        name:"type",
                        type:"select",
                        data:[
                            {
                                value:"Nước",
                                key:0
                            },
                            {
                                value:"Khăn tắm",
                                key:1
                            },
                            {
                                value:"Khăn tập",
                                key:2
                            },
                            {
                                value:"Loại khác",
                                key:3
                            }
                        ]
                    },
                ],
                _id:data._id
            }
        }

        this.setState({
            DataModal:DataModal
        });

    }

    ResetDataModal(){
        var Reset_DataModal={
            status:false,
            title:null,
            data:null,
            _id:null
        };

        this.setState({
            DataModal:Reset_DataModal
        })

    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"cproducts", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==="error"){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
    }


    AlertVisible(data){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.UpdateVisible(data);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    UpdateVisible(data){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('_id', data._id);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"cproducts/status", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{
            if(data.status==="error"){
                toast("Cập nhật dữ liệu thất bại");
            }
            else{
                toast("Cập nhật dữ liệu thành công");
                self.getData(true);
            }
            Appthis.PageLoad(false);
        }).catch((e)=>{

            toast("Cập nhật dữ liệu thất bại");
            Appthis.PageLoad(false);
        });
    }


    GetWarehouse(data){
        this.setState({
            DataWarehouse:{
                status:true,
                info:data,
            }
        });

    }

    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){
            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>STT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Sản phẩm</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Màu sắc</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Giá</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Phân loại</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        {index+1}
                                    </td>
                                    <td>{value['name']}</td>

                                    <td>{value['color']?(
                                        <span className={"boxcolor d-inline-block"} style={{
                                            backgroundColor:value['color']
                                        }}>
                                    </span>
                                    ):(
                                        <p className={"m-0"}>Chưa rõ</p>
                                    )}</td>

                                    <td>
                                        {formatNumber(value['price'])}
                                    </td>

                                    <td>
                                        {typeof value.type!=='undefined'?(
                                            <>
                                                {value.type===0?("Nước"):(<></>)}
                                                {value.type===1?("Khăn tắm"):(<></>)}
                                                {value.type===2?("Khăn tập"):(<></>)}
                                                {value.type===3?("Loại khác"):(<></>)}
                                            </>
                                        ):(
                                            <>___</>
                                        )}
                                    </td>

                                    <td>
                                        {value['visible']===1?(
                                            <span>Active</span>
                                        ):(
                                            <span>Inactive</span>
                                        )}
                                    </td>


                                    <td className={"fix-item-table"}>

                                       <div className={"col-12 pt-1"}>
                                           <a onClick={()=>{
                                               self.OpenModal(value);

                                           }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                               <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật sản phẩm">
                                                   <i className="far fa-edit" />
                                               </Floater>
                                           </a>

                                           {value['visible']===1?(
                                               <a onClick={()=>{
                                                   self.AlertVisible(value);
                                               }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                                   <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Ngừng kích hoạt sản phẩm">
                                                       <i className="fas fa-minus" />
                                                   </Floater>
                                               </a>
                                           ):(
                                               <a onClick={()=>{
                                                   self.AlertVisible(value);
                                               }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                   <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="kích hoạt sản phẩm">
                                                       <i className="far fa-check-circle" />
                                                   </Floater>
                                               </a>
                                           )}


                                           <a onClick={()=>{
                                               self.GetWarehouse(value);
                                           }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                               <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Kho sản phẩm">
                                                   <i className="fas fa-industry" />
                                               </Floater>
                                           </a>
                                       </div>

                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <div className={"col-12 ps-3 pe-3"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>
                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    async UpdateModal(response){

        var Appthis= this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var url_post=Appthis.ConstApp.home_api+"cproducts/update";

        var data_submit=response;

        if(this.state.DataModal._id!==null){
            data_submit._id=this.state.DataModal._id;
        }

        formData.append("data",base64_encode(data_submit));

        var get_data=await fetch(url_post, {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Cập nhật dữ liệu thất bại");
        }
        else{
            toast("Cập nhật dữ liệu thành công");
        }

        this.ResetDataModal();

        await this.getData(false);

        Appthis.PageLoad(false);


    }



    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0 pt-3">

                            <div className="col-12 col-sm-12  d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý sản phẩm</h3>
                            </div>

                            <div className="col-12 col-sm-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>



                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>


                                        <input onChange={(event)=>{
                                            this.UpdateQuerySearch("input_search",event.target.value);
                                        }} autoComplete={"off"} type="text" name="input_search"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Từ khóa tìm kiếm"/>

                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>


                                        <a onClick={()=>{
                                            this.OpenModal(null);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                            <i className="fas fa-plus" /> Thêm mới </a>


                                    </div>

                                </div>




                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.state.data!==null?(
                                this.thisRenderData()
                            ):(<ItemLoading />)}
                        </div>



                    </div>


                    {this.state.DataModal.status===true?(
                        <EditModal size={"small"} onClose={()=>{
                            this.ResetDataModal();
                        }} GetDataSubmit={(response,required)=>{

                            if(required===true){
                                self.UpdateModal(response);
                            }
                            else{
                                toast("Vui lòng nhập đầy đủ thông tin");
                            }
                        }} title={this.state.DataModal.title} data={this.state.DataModal.data} />
                    ):(<></>)}



                    {this.state.DataWarehouse.status===true?(
                        <Warehouse onClose={()=>{
                            this.setState({
                                DataWarehouse:{
                                    status:false,
                                    info:{},
                                }
                            });

                        }} AppThis={this.props.AppThis} path={'cproducts'} data={this.state.DataWarehouse.info} />
                    ):(
                        <></>
                    )}




                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
