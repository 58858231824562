const ModuleRegister = {
    admin: [
        {
            name: 'madmin',
            path: require('./components/admin/madmin').default,
        },
        {
            name: 'mmachine',
            path: require('./components/admin/mmachine').default,
        },
        {
            name: 'mstaff',
            path: require('./components/admin/mstaff').default,
        },
        {
            name: 'mstaffclub',
            path: require('./components/admin/mstaffclub').default,
        },
        {
            name: 'mposition',
            path: require('./components/admin/mposition').default,
        },
        {
            name: 'msubject',
            path: require('./components/admin/msubject').default,
        },
        {
            name: 'msource',
            path: require('./components/admin/msource').default,
        },
        {
            name: 'mrequires',
            path: require('./components/admin/mrequires').default,
        },
        {
            name: 'typegroup',
            path: require('./components/admin/typegroup').default,
        },
        {
            name: 'mservice',
            path: require('./components/admin/mservice').default,
        },
        {
            name: 'maddress',
            path: require('./components/admin/maddress').default,
        },
        {
            name: 'mstatics',
            path: require('./components/admin/mstatics').default,
        },
        {
            name: 'payment',
            path: require('./components/admin/payment').default,
        },
        {
            name: 'percent',
            path: require('./components/admin/percent').default,
        },
        {
            name: 'mgroup',
            path: require('./components/admin/mgroup').default,
        },
        {
            name: 'machine',
            path: require('./components/admin/machine').default,
        },
        {
            name: 'mcard',
            path: require('./components/admin/mcard').default,
        },
        {
            name: 'saleprogram',
            path: require('./components/admin/saleprogram').default,
        },
        {
            name: 'mdiscount',
            path: require('./components/admin/mdiscount').default,
        },
        {
            name: 'morders',
            path: require('./components/admin/morders').default,
        },
        {
            name: 'fingerprintadmin',
            path: require('./components/admin/fingerprintadmin').default,
        },
        {
            name: 'employeetimekeeping',
            path: require('./components/admin/employeetimekeeping').default,
        },
        {
            name: 'groupmodule',
            path: require('./components/admin/groupmodule').default,
        },
        {
            name: 'mexport',
            path: require('./components/admin/mexport').default,
        },
        {
            name: 'myexport',
            path: require('./components/admin/myexport').default,
        },
        {
            name: 'mproducts',
            path: require('./components/admin/mproducts').default,
        },
        {
            name: 'moptions',
            path: require('./components/admin/moptions').default,
        },
        {
            name: 'autoreport',
            path: require('./components/admin/autoreport').default,
        },
        {
            name: 'staticsmarketing',
            path: require('./components/admin/staticsmarketing').default,
        },
        {
            name: 'staticsclub',
            path: require('./components/admin/staticsclub').default,
        },
        {
            name: 'mcustomer',
            path: require('./components/admin/mcustomer').default,
        },
        {
            name: 'mnotification',
            path: require('./components/admin/mnotification').default,
        },
        {
            name: 'personal',
            path: require('./components/admin/personal').default,
        },
        {
            name: 'marketingcontract',
            path: require('./components/admin/marketingcontract').default,
        },
        {
            name: 'mqrcode',
            path: require('./components/admin/mqrcode').default,
        },
        {
            name: 'staticsmember',
            path: require('./components/admin/staticsmember').default,
        },
        {
            name: 'infopersonal',
            path: require('./components/admin/infopersonal').default,
        },
        {
            name: 'transaction',
            path: require('./components/admin/transaction').default,
        },
        {
            name: 'percentcontract',
            path: require('./components/admin/percentcontract').default,
        },
        {
            name: 'checkinmember',
            path: require('./components/admin/checkinmember').default,
        },
        {
            name: 'checkinpt',
            path: require('./components/admin/checkinpt').default,
        },
        {
            name: 'useronline',
            path: require('./components/admin/useronline').default,
        },
        {
            name: 'historycheckin',
            path: require('./components/admin/historycheckin').default,
        },
        {
            name: 'groupmember',
            path: require('./components/admin/groupmember').default,
        },
        {
            name: 'reportaccountant',
            path: require('./components/admin/reportaccountant').default,
        },


    ],
    club: [
        {
            name: 'creportaccountant',
            path: require('./components/club/creportaccountant').default,
        },
        {
            name: 'corders',
            path: require('./components/club/corders').default,
        },
        {
            name: 'cgroup',
            path: require('./components/club/cgroup').default,
        },
        {
            name: 'ccard',
            path: require('./components/club/ccard').default,
        },
        {
            name: 'cproducts',
            path: require('./components/club/cproducts').default,
        },
        {
            name: 'csubject',
            path: require('./components/club/csubject').default,
        },
        {
            name: 'userclub',
            path: require('./components/club/userclub').default,
        },
        {
            name: 'clubfingerprintadmin',
            path: require('./components/club/clubfingerprintadmin').default,
        },
        {
            name: 'clubtimekeeping',
            path: require('./components/club/clubtimekeeping').default,
        },
        {
            name: 'clubexport',
            path: require('./components/club/clubexport').default,
        },
        {
            name: 'personalteam',
            path: require('./components/club/personalteam').default,
        },
        {
            name: 'myclubtimekeeping',
            path: require('./components/club/myclubtimekeeping').default,
        },
        {
            name: 'clubreport',
            path: require('./components/club/clubreport').default,
        },
        {
            name: 'mcclub',
            path: require('./components/club/mcclub').default,
        },
        {
            name: 'mcgroup',
            path: require('./components/club/mcgroup').default,
        },
        {
            name: 'clubuser',
            path: require('./components/club/clubuser').default,
        },
        {
            name: 'teamuser',
            path: require('./components/club/teamuser').default,
        },
        {
            name: 'myuser',
            path: require('./components/club/myuser').default,
        },
        {
            name: 'dataclub',
            path: require('./components/club/dataclub').default,
        },
        {
            name: 'subjectuser',
            path: require('./components/club/subjectuser').default,
        },
        {
            name: 'mcsubject',
            path: require('./components/club/mcsubject').default,
        },
        {
            name: 'cqrcode',
            path: require('./components/club/cqrcode').default,
        },
        {
            name: 'cpersonal',
            path: require('./components/club/cpersonal').default,
        },
         {
            name: 'ceditpersonal',
            path: require('./components/club/ceditpersonal').default,
        },

        {
            name: 'clubhistorycheckin',
            path: require('./components/club/clubhistorycheckin').default,
        },
        {
            name: 'cmypercentcontract',
            path: require('./components/club/cmypercentcontract').default,
        },
        {
            name: 'clubpercent',
            path: require('./components/club/clubpercent').default,
        },

        {
            name: 'historycheckinpt',
            path: require('./components/club/historycheckinpt').default,
        },

        {
            name: 'clubcheckin',
            path: require('./components/club/clubcheckin').default,
        },
        {
            name: 'clubcheckinmember',
            path: require('./components/club/clubcheckinmember').default,
        },
        {
            name: 'clubcheckinpt',
            path: require('./components/club/clubcheckinpt').default,
        },

        {
            name: 'clubinfopersonal',
            path: require('./components/club/clubinfopersonal').default,
        },
        {
            name: 'mypersonal',
            path: require('./components/club/mypersonal').default,
        },
        {
            name: 'ctransaction',
            path: require('./components/club/ctransaction').default,
        },
        {
            name: 'cstaticsmember',
            path: require('./components/club/cstaticsmember').default,
        },
    ],
    marketing:[
        {
            name: 'marketinguser',
            path: require('./components/marketing/marketinguser').default,
        },
        {
            name: 'mkttimekeeping',
            path: require('./components/marketing/mkttimekeeping').default,
        },
        {
            name: 'mymarketingtimekeeping',
            path: require('./components/marketing/mymarketingtimekeeping').default,
        },
        {
            name: 'marketingexport',
            path: require('./components/marketing/marketingexport').default,
        },
        {
            name: 'usermarketing',
            path: require('./components/marketing/usermarketing').default,
        },
        {
            name: 'marketingfingerprint',
            path: require('./components/marketing/marketingfingerprint').default,
        },
        {
            name: 'mycustomerteam',
            path: require('./components/marketing/mycustomerteam').default,
        },
        {
            name: 'mypushteam',
            path: require('./components/marketing/mypushteam').default,
        },
        {
            name: 'mycustomer',
            path: require('./components/marketing/mycustomer').default,
        },
        {
            name: 'mynotification',
            path: require('./components/marketing/mynotification').default,
        },
        {
            name: 'mystoragenotification',
            path: require('./components/marketing/mystoragenotification').default,
        },
        {
            name: 'marketingreport',
            path: require('./components/marketing/marketingreport').default,
        },
        {
            name: 'mktcontract',
            path: require('./components/marketing/mktcontract').default,
        },
        {
            name: 'teamcontract',
            path: require('./components/marketing/teamcontract').default,
        },
    ],
};

export default ModuleRegister;
