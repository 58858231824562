/* eslint-disable */
import React,{Component} from "react";
import {
    base64_encode,
    formatNumber,
    timeConverter,
} from "../../helper/array";
import no_avatar from "../../assets/images/_no_avatar.png";
import ConstApp from "../../ConstApp";
import {toast} from "react-toastify";
export default class DetailCheckin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            DetailCheckin:null,
            cancelCheckin:{
                status:false,
                content:null,
                is_pause:0,
                title:null
            }
        };
    }

    componentDidMount() {
        if(this.props.data){
            this.setState({
                DetailCheckin:this.props.data
            });
        }
        document.addEventListener("keydown", this.KeyEventFunction);
    }
    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.eventClose!=='undefined'){
                this.props.eventClose();
            }
        }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }
    updateItemStatusCheckin(name=null,value=null){

        var cancelCheckin=this.state.cancelCheckin;

        cancelCheckin[name]=value;

        this.setState({
            cancelCheckin:cancelCheckin
        });

    }

    submitStatusCheckin(){

        var AppThis=this.props.AppThis;

        AppThis.PageLoad(true);
        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('id_checkin', this.state.DetailCheckin._id);

        formData.append('data', base64_encode(this.state.cancelCheckin));

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/statuscheckin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công");
            }
            self.props.eventClose();

        }).catch((e)=>{
            toast("Thao tác thất bại");
            self.props.eventClose();

        });


    }
    RenderDetailCheckin(){
        var DetailCheckin=this.state.DetailCheckin;
        const self=this;


        return(
            <div className="modal d-block position-fixed modal-dialog-end">
                <div className={"modal-dialog modal-lg modal-dialog-scrollable container-fluid"}>
                    <div className="modal-content shadow border-0 rounded-0">
                        <div className="modal-header rounded-0 active_tab">
                            <strong className={"font16 text-uppercase text-white"}>
                                <i className="far fa-user me-2" />
                                Chi tiết buổi tập
                            </strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-3"}>

                                    <div className={"col-12 mb-4 text-center"}>


                                        <div className={"position-relative d-inline-block"}>
                                            {DetailCheckin.id_user.avatar!==null ?(
                                                <div className={"box-avatar-150 m-auto"}>
                                                    <img src={ConstApp.home_url +"/" +DetailCheckin.id_user.avatar} />
                                                </div>

                                            ):(
                                                <div className={"box-avatar-150 m-auto"}>
                                                    <img  src={no_avatar} />
                                                </div>
                                            )}

                                        </div>


                                    </div>

                                    <div className={"row mb-1 bg-light p-2"}>
                                        <div className={"col-6 col-sm-6 col-md-5"}>
                                            <i className="far fa-user" /> Họ và tên
                                        </div>
                                        <div className={"col-6 col-sm-6 col-md-7"}>
                                            <span className={"font-weight-bold"}>{DetailCheckin.id_user.username}</span>
                                        </div>
                                    </div>

                                    <div className={"row mb-1 p-2"}>
                                        <div className={"col-6 col-sm-6 col-md-5"}>
                                            <i className="far fa-envelope" /> Số HD
                                        </div>
                                        <div className={"col-6 col-sm-6 col-md-7"}>
                                            {typeof DetailCheckin.id_member!=='undefined' && DetailCheckin.id_member!==null?(
                                                <span className={"text-line"}>{DetailCheckin.id_member.number_contract}</span>
                                            ):(
                                                <>
                                                <span className={"badge bg-danger rounded-0"}>Tập thử</span>
                                                </>
                                            )}

                                        </div>
                                    </div>


                                    <div className={"row mb-1 bg-light p-2"}>
                                        <div className={"col-6 col-sm-6 col-md-5"}>
                                            Mã khách hàng
                                        </div>
                                        <div className={"col-6 col-sm-6 col-md-7"}>
                                            <span className={"font-weight-bold"}>{DetailCheckin.id_user.code_user}</span>

                                        </div>
                                    </div>

                                    <div className={"row mb-1 p-2"}>
                                        <div className={"col-6 col-sm-6 col-md-5"}>
                                            <i className="fas fa-phone-alt" /> SDT/ID
                                        </div>
                                        <div className={"col-6 col-sm-6 col-md-7"}>
                                            <span>{DetailCheckin.id_user.user_login}</span>
                                        </div>
                                    </div>




                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-9 "}>



                                        <>
                                           <div className={"row"}>

                                               {typeof DetailCheckin['is_pause']!=='undefined' && DetailCheckin['is_pause']===1?(
                                                   <div className={"col-12 w-100 alert alert-danger rounded-0"}>
                                                       <strong>SHOW PT đang hủy</strong>
                                                   </div>
                                               ):(
                                                   <></>
                                               )}


                                           </div>
                                        </>


                                    <div className={"col-12 mb-3 RenderRenderHistoryCheckin"}>
                                        <div className={"row mb-3"}>
                                            <div className={"col-12 col-sm-12 col-md-12 bg-light-bold font-weight-bold text-start font15 p-2"}>
                                                CHI TIẾT BUỔI TẬP
                                            </div>
                                        </div>

                                        <div className={"row m-0"}>
                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="far fa-clock me-1" />Thời gian vào
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>
                                                    {timeConverter(DetailCheckin.time)}
                                                </span>
                                            </div>
                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="far fa-clock me-1" />Thời gian ra
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>

                                                    {typeof DetailCheckin.time_update!=='undefined'?timeConverter(DetailCheckin.time_update):(
                                                        <span>___</span>
                                                    )}

                                                </span>
                                            </div>


                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-400 mb-1">
                                                    <i className="far fa-user me-1" />PT dạy
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>

                                                    {DetailCheckin.admin_pt_session!==null?(
                                                        <span>{DetailCheckin.admin_pt_session.username+" - "+DetailCheckin.admin_pt_session.code_user}</span>
                                                    ):(
                                                        <span>___</span>
                                                    )}

                                                </span>
                                            </div>



                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-400 mb-1">
                                                    <i className="fas fa-headset me-1" />PT Trợ giảng
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>

                                                    {DetailCheckin.sub_admin_pt.length>0?(
                                                        <>
                                                            {DetailCheckin.sub_admin_pt.map(function(item){
                                                                return(
                                                                    <span className={"badge bg-primary font10 rounded-0 m-1"}>{item.code_user}</span>
                                                                );
                                                            })}
                                                        </>
                                                    ):(
                                                        <span>___</span>
                                                    )}
                                                </span>
                                            </div>



                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-400 mb-1">
                                                    <i className="fas fa-layer-group me-1" />Lớp nhóm
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>
                                                        {DetailCheckin.statics_member_group!==null?(
                                                            <span>{DetailCheckin.statics_member_group.name+" - "+DetailCheckin.statics_member_group.code_group}</span>
                                                        ):(
                                                            <span>___</span>
                                                        )}
                                                </span>
                                            </div>



                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-400 mb-1">
                                                    <i className="fas fa-dumbbell me-1" /> Bộ môn
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>

                                                     {DetailCheckin.current_subject.length>0?(
                                                         <>
                                                             {DetailCheckin.current_subject.map(function(item){
                                                                 return(
                                                                     <span className={"badge bg-primary font10 m-1"}>{item.name}</span>
                                                                 );
                                                             })}
                                                         </>
                                                     ):(
                                                         <span>___</span>
                                                     )}
                                                </span>
                                            </div>



                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="fas fa-user-cog me-1" />
                                                    NV Lễ tân
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>
                                                    {DetailCheckin.id_admin.username+" - "+DetailCheckin.id_admin.code_user}
                                                </span>
                                            </div>


                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                <i className="fas fa-sign-out-alt me-1"></i>
                                                    Phương thức checkin
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>
                                                    {typeof DetailCheckin.method_checkin!=='undefined'?DetailCheckin.method_checkin:"___"}
                                                </span>
                                            </div>


                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="fas fa-sign-in-alt me-1"></i>
                                                    Phương thức checkout
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>
                                                    {typeof DetailCheckin.method_checkout!=='undefined'?DetailCheckin.method_checkout:"___"}
                                                </span>
                                            </div>

                                             <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                  <i className="fa-solid fa-table me-2"></i>
                                                    Số tủ đồ
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>
                                                    {typeof DetailCheckin.number_coffer!=='undefined' && DetailCheckin.number_coffer!==null ?DetailCheckin.number_coffer:"___"}
                                                </span>
                                            </div>


                                        </div>



                                        <div className={"row"}>
                                            <div className={"col-12 col-sm-12 font15 col-md-12 bg-light-bold p-2 mt-2 text-uppercase font-weight-bold mb-2"}>
                                                CHI TIẾT SẢN PHẨM
                                            </div>
                                        </div>




                                            <div className={"row mt-4 mb-3"}>

                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-5 pb-3"}>
                                                    <label className={"w-100 text-uppercase font-weight-bold font16 mb-2"}>
                                                        <i className="fab fa-free-code-camp me-2" />
                                                        Sản phẩm đi kèm HD</label>

                                                    {DetailCheckin.product_in_card.length>0?(
                                                        <div className={"col-12 overflow-auto"}>
                                                            <table className={"table mw-100 mw300"}>
                                                                <thead>
                                                                <tr>
                                                                    <th className={"bg-light-bold"}>Tên SP</th>
                                                                    <th className={"bg-light-bold"}>Số Lượng</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {DetailCheckin.product_in_card.map(function(item){
                                                                    return(
                                                                        <tr>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.number}</td>
                                                                        </tr>
                                                                    );
                                                                })}


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ):(
                                                        <div className={"alert alert-danger rounded-0"}>
                                                            Không có SP nào
                                                        </div>
                                                    )}


                                                </div>


                                                <div className={"col-12 col-sm-12 col-md-12 col-lg-7 pb-3"}>
                                                    <label className={"text-uppercase font-weight-bold font16 mb-2"}>
                                                        <i className="fas fa-plus me-2" />
                                                        Sản phẩm mua thêm</label>


                                                    {DetailCheckin.product_buy_session.length>0?(
                                                        <>
                                                            <div className={"col-12 overflow-auto"}>
                                                            <table className={"table mw-100 mw400"}>
                                                                <thead>
                                                                <tr>
                                                                    <th className={"bg-light-bold"}>Tên SP</th>
                                                                    <th className={"bg-light-bold"}>Số Lượng</th>
                                                                    <th className={"bg-light-bold"}>Đơn giá</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {DetailCheckin.product_buy_session.map(function(item){
                                                                    return(
                                                                        <tr>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.number}</td>
                                                                            <td>
                                                                                <span className={"font-weight-bold text-red"}>{formatNumber(item.price)} VNĐ</span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}

                                                                </tbody>
                                                            </table>
                                                            </div>
                                                        </>

                                                    ):(
                                                        <div className={"alert alert-danger rounded-0"}>
                                                            Không có sp nào
                                                        </div>
                                                    )}




                                                </div>

                                            </div>





                                    </div>


                                    {this.state.cancelCheckin.status===true?(
                                        <>

                                            <div className={"row mb-3"}>
                                                <div className={"col-12 col-sm-12 col-md-12 bg-light-bold font-weight-bold text-start font15 p-2"}>
                                                    HỦY BUỔI TẬP
                                                </div>
                                            </div>



                                            {this.state.cancelCheckin.is_pause===1?(
                                                <>
                                                    <label className={"me-3"}>
                                                        <input type={"radio"} checked={true} name={"typeCancel"} onChange={(event)=>{
                                                            this.updateItemStatusCheckin("is_pause",1);
                                                        }} /> Hủy Show
                                                    </label>


                                                    <label>
                                                        <input type={"radio"} name={"typeCancel"} onChange={(event)=>{
                                                            this.updateItemStatusCheckin("is_pause",0);
                                                        }} /> Không hủy show
                                                    </label>

                                                </>
                                            ):(
                                                <>
                                                    <label className={"me-3"}>
                                                        <input type={"radio"} name={"typeCancel"} onChange={(event)=>{
                                                            this.updateItemStatusCheckin("is_pause",1);
                                                        }} /> Hủy Show
                                                    </label>

                                                    <label>
                                                        <input type={"radio"} checked={true} name={"typeCancel"} onChange={(event)=>{
                                                            this.updateItemStatusCheckin("is_pause",0);
                                                        }} /> Không hủy show
                                                    </label>
                                                </>
                                            )}

                                            <div className={"col-12 mt-3"}>

                                                <p className={"m-0 font-weight-bold"}>Lý do hủy Show</p>
                                                <input type={"text"} className={"form-control w-100 rounded-0 mb-3"} placeholder={"Lý do hủy show"} onChange={(event)=>{
                                                    this.updateItemStatusCheckin("title",event.target.value);
                                                }} />

                                                <p className={"m-0 font-weight-bold"}>Ghi chú</p>
                                                <textarea className={"form-control w-100 rounded-0 height130 font14"} placeholder={"Nội dung"} onChange={(event)=>{
                                                    this.updateItemStatusCheckin("content",event.target.value);
                                                }}></textarea>
                                            </div>

                                        </>
                                    ):(
                                        <></>
                                    )}


                                </div>



                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>


                            <div className={"col-12 col-sm-12 col-lg-12"}>

                                <div className={"col-12 col-sm-12 col-lg-4 offset-lg-8"}>

                                    {this.state.cancelCheckin.status===false?(
                                        <div className={"row"}>

                                            <div className={"col-12 col-sm-6"}>

                                                {typeof this.props.enableCancelCheckin==='undefined'?(
                                                    <a onClick={()=>{
                                                        this.setState({
                                                            cancelCheckin:{
                                                                status:true,
                                                                content:null,
                                                                is_pause:0,
                                                                title:null
                                                            }
                                                        })
                                                    }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                        Hủy Show
                                                    </a>
                                                ):(
                                                    <></>
                                                )}

                                            </div>

                                            <div className={"col-12 col-sm-6"}>
                                                <a onClick={()=>{
                                                    this.props.eventClose();
                                                }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                    Đóng
                                                </a>
                                            </div>


                                        </div>
                                    ):(
                                        <div className={"row"}>

                                            <div className={"col-12 col-sm-6"}>
                                                <a onClick={()=>{
                                                    this.submitStatusCheckin();
                                                }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                    Cập nhật
                                                </a>
                                            </div>

                                            <div className={"col-12 col-sm-6"}>
                                                <a onClick={()=>{

                                                    this.setState({
                                                        cancelCheckin:{
                                                            status:false,
                                                            content:null,
                                                            is_pause:0,
                                                            title:null
                                                        }
                                                    });

                                                }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                    Hủy
                                                </a>
                                            </div>


                                        </div>
                                    )}


                                </div>

                            </div>


                        </div>



                    </div>
                </div>
                <div onClick={()=>{
                    this.props.eventClose();
                }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }

    render(){

        if(this.state.DetailCheckin!==null){
            return this.RenderDetailCheckin();
        }
        else{
            return(
                <></>
            );
        }

    }
}
