/* eslint-disable */
import React,{Component} from "react";
import {_isset_object, formatNumber, timeConverter, timeConverterDate} from "../../helper/array";
import {get_name_type_receipts, type_wallet} from "../../helper/member";
export default class DetailWallet extends Component{

    constructor(props) {
        super(props);

        this.state={
           data:null
        };

    }

    componentDidMount() {

        if (typeof this.props.data!=='undefined'){

            this.setState({
                data:this.props.data
            });
        }

    }


    render() {

        if (this.state.data!==null){

            var item=this.state.data;

            return(
               <div className={"col-12"}>
                   <div className={"alert alert-danger w-100 rounded-0 m-0"}>
                       <i className="fas fa-exclamation-triangle me-1"></i>  <strong>Chi tiết phiếu thu</strong>
                   </div>

                   <div className={"row bg-light m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fas fa-sort-numeric-up-alt me-1"></i> Trạng thái
                       </div>
                       <div className={"col-6 col-md-8"}>
                           {item.status===1?(
                               <span className="badge bg-primary font12">Thành công</span>
                           ):(
                               <></>
                           )}

                           {item.status===2?(
                               <span className="badge bg-danger font12">Hủy</span>
                           ):(
                               <></>
                           )}

                           {item.status===0?(
                               <span className="badge bg-warning font12">Đang chờ</span>
                           ):(
                               <></>
                           )}
                       </div>

                   </div>

                   <div className={"row m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fas fa-list-ol me-1"></i> Số phiếu thu
                       </div>
                       <div className={"col-6 col-md-8"}>
                          <strong> {item.number_votes}</strong>
                       </div>

                   </div>

                   <div className={"row bg-light m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="far fa-clock me-1"></i> Ngày thu tiền
                       </div>
                       <div className={"col-6 col-md-8"}>
                           {timeConverter(item.time)}
                       </div>

                   </div>

                   <div className={"row m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="far fa-user me-1"></i> Tên hội viên
                       </div>
                       <div className={"col-6 col-md-8"}>
                           {item.id_user.username+" - " +item.id_user.code_user}
                       </div>

                   </div>

                   <div className={"row m-0 p-2 bg-light"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fas fa-mobile-alt me-1"></i> SDT /ID tài khoản
                       </div>
                       <div className={"col-6 col-md-8"}>
                           {item.id_user.user_login}
                       </div>

                   </div>

                   <div className={"row m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fab fa-paypal me-1"></i> Loại thanh toán
                       </div>
                       <div className={"col-6 col-md-8"}>
                           {item.type_of_paid===1?(
                               <span className={"badge bg-primary font12"}>Trả hết</span>
                           ):(<></>)}
                           {item.type_of_paid===2?(
                               <span className={"badge bg-danger font12"}>Đặt cọc</span>
                           ):(<></>)}
                           {item.type_of_paid===3?(
                               <span className={"badge bg-success font12"}>Trả nốt</span>
                           ):(<></>)}
                       </div>

                   </div>

                   <div className={"row m-0 p-2 bg-light"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fab fa-amazon-pay me-1"></i> Phương thức TT
                       </div>
                       <div className={"col-6 col-md-8"}>
                           {item.id_payment?item.id_payment.name:'Chưa rõ'}
                       </div>

                   </div>


                   <div className={"row m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fas fa-university me-1"></i> TK ngân hàng
                       </div>
                       <div className={"col-6 col-md-8"}>

                           {item.id_bank!==null && typeof item.id_bank==='object'?(
                               <span className="badge bg-primary font12 rounded-0">
                                   {item.id_bank.name}
                               </span>
                           ):(<span className={"badge bg-danger font12 rounded-0"}>Chưa rõ</span>)}


                       </div>

                   </div>



                   <div className={"row m-0 p-2 bg-light"}>
                       <div className={"col-6 col-md-4"}>
                          <strong> <i className="far fa-file-excel me-1"></i> Số HD</strong>
                       </div>
                       <div className={"col-6 col-md-8"}>
                           {item.id_member && item.id_member.number_contract?item.id_member.number_contract:(
                               <span className={"badge bg-warning font12"}>Chưa rõ</span>
                           )}
                       </div>

                   </div>

                   <div className={"row m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fas fa-money-bill me-1"></i> Số tiền thu
                       </div>
                       <div className={"col-6 col-md-8"}>
                           <strong className={"text-red"}>{formatNumber(item.price)}</strong> VND
                       </div>

                   </div>

                   <div className={"row m-0 p-2 bg-light"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fas fa-tag me-1 text-red"></i> Hình thức thu
                       </div>
                       <div className={"col-6 col-md-8"}>
                          <strong> {type_wallet(item)}</strong>
                       </div>

                   </div>

                   <div className={"row m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                           <i className="fas fa-tag me-1 text-red"></i> Loại phiếu
                       </div>
                       <div className={"col-6 col-md-8"}>
                           <strong> {get_name_type_receipts(item.type_receipts)}</strong>
                       </div>
                   </div>

                   <div className={"row m-0 p-2 bg-light"}>

                       <div className={"col-6 col-md-4"}>
                           <strong>  <i className="far fa-user me-1"></i> NV bán hàng</strong>
                       </div>
                       <div className={"col-6 col-md-8"}>

                           {_isset_object(item.id_admin)?(
                               <>
                                   {item.id_admin.username+" - "+item.id_admin.code_user}
                               </>
                           ):(
                               <span className={"badge bg-warning font12"}>Chưa rõ</span>
                           )}

                       </div>

                   </div>

                   <div className={"row m-0 p-2"}>

                       <div className={"col-6 col-md-4"}>
                          <i className="far fa-user me-1"></i> NV xác nhận
                       </div>
                       <div className={"col-6 col-md-8"}>

                           {_isset_object(item.id_admin_update)?(
                               <span>{item.id_admin_update.username+" - "+item.id_admin_update.code_user}</span>
                           ):(
                               "Chưa rõ"
                           )}

                       </div>

                   </div>

                   <div className={"alert alert-primary w-100 rounded-0 m-0"}>
                       <i className="fas fa-exclamation-triangle me-1"></i>  <strong>Chi thanh toán HD</strong>
                   </div>


                   {_isset_object(item.id_member)?(
                       <>

                           <div className={"row m-0 p-2"}>

                               <div className={"col-6 col-md-4"}>

                                  <strong>
                                      <i className="fas fa-sort-numeric-down me-1" />
                                      Giá trị HD
                                  </strong>

                               </div>
                               <div className={"col-6 col-md-8 font-weight-bold text-red"}>
                                   <span className={"font-weight-bold me-1"}>{formatNumber(item.id_member.info_contract_data.price_sale)}</span>
                                   VNĐ
                               </div>

                           </div>


                           <div className={"row m-0 p-2 bg-light"}>

                               <div className={"col-6 col-md-4"}>
                                   <i className="fas fa-sort-numeric-down me-1" />
                                   Đã thanh toán
                               </div>
                               <div className={"col-6 col-md-8 font-weight-bold text-red"}>
                                   {formatNumber(item.id_member.billing_information.total_price)} VNĐ
                               </div>

                           </div>


                           <div className={"row m-0 p-2"}>

                               <div className={"col-6 col-md-4"}>

                                   <i className="far fa-credit-card me-1" />
                                   Gói thẻ
                               </div>
                               <div className={"col-6 col-md-8"}>
                                   <span>{item.id_member.id_card.name}</span>
                               </div>

                           </div>

                           <div className={"row m-0 p-2 bg-light"}>

                               <div className={"col-6 col-md-4"}>
                                   <i className="far fa-clock me-1"></i>
                                   Ngày bắt đầu
                               </div>
                               <div className={"col-6 col-md-8"}>
                                   <span>{timeConverterDate(item.id_member.start_day)}</span>
                               </div>

                           </div>
                           <div className={"row m-0 p-2"}>

                               <div className={"col-6 col-md-4"}>
                                   <i className="far fa-clock me-1"></i>
                                   Ngày kết thúc
                               </div>
                               <div className={"col-6 col-md-8"}>
                                   <span>{timeConverterDate(item.id_member.end_day_contract)}</span>
                               </div>

                           </div>

                           <div className={"row m-0 p-2 bg-light"}>

                               <div className={"col-6 col-md-4"}>
                                   <i className="far fa-clock me-1"></i>
                                   Ngày hết hạn đặt cọc
                               </div>
                               <div className={"col-6 col-md-8"}>
                                   <span>{timeConverterDate(item.id_member.info_contract_data.end_day_stop)}</span>
                               </div>

                           </div>


                           <div className={"row m-0 p-2"}>

                               <div className={"col-6 col-md-4"}>
                                   <i className="fas fa-percent me-1"></i>
                                   % mã giảm giá
                               </div>
                               <div className={"col-6 col-md-8"}>

                                   {item.id_member && item.id_member.id_discount.length>0?(
                                       item.id_member.id_discount.map(function(value,index){
                                           return(
                                               <span className="badge bg-primary font11 mr-2">{value.code}</span>
                                           );
                                       })
                                   ):(
                                       <span className={"badge bg-warning font12"}>Chưa rõ</span>
                                   )}

                               </div>

                           </div>

                           <div className={"col-12 p-2 ps-3 mb-3 text-white font14 font-weight-bold text-uppercase TitleDetailContract"}>
                               Lịch sử thanh toán
                           </div>

                           <div className={"col-12 overflow-auto"}>
                               {item.id_member.billing_information.data.length>0?(
                                   <table className={"w-100 mw400 table"}>
                                       <tbody>
                                       <tr>
                                           <td>THANH TOÁN</td>
                                           <td>TỔNG TIỀN</td>
                                           <td>THỜI GIAN</td>
                                       </tr>
                                       {item.id_member.billing_information.data.map(function(item,index){
                                           return(
                                               <tr>
                                                   <td>
                                                       {item.id_payment!==null?(
                                                           <span>{item.id_payment.name}</span>
                                                       ):(
                                                           <span className={"badge bg-danger font12"}>Chưa rõ</span>
                                                       )}
                                                   </td>
                                                   <td>{formatNumber(item.price)}</td>
                                                   <td>{timeConverterDate(item.time)}</td>
                                               </tr>
                                           );
                                       })}

                                       </tbody>

                                   </table>

                               ):(
                                   <div className={"alert alert-danger rounded-0"}>
                                       Chưa có lịch sử giao dịch
                                   </div>
                               )}
                           </div>



                       </>
                   ):(
                       <div className={"alert alert-warning round-0 w-100"}>
                           Phiếu thu chưa có HD
                       </div>
                   )}




               </div>
            );
        }
        else{
            return(<></>);
        }
    }
}
