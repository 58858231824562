/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_string,
    array_to_select,
    base64_encode,
    timeConverter,
    timeConverterDate,
    validateEmail
} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import EditModal from "../../global/EditModal";
import EditModule from "../../global/EditModule";
import Floater from 'react-floater';

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        status:2
    },
    DataModal:{
        status:false,
        title:null,
        data:null,
        _id:null
    },
    EditModule:{
        status:false,
        data:null
    },
    page:0
};

export default class madmin extends Component {
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }
    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){

            var [key,value]=item;

            if(value!==null){
                query_send[key]=value;
            }

        });

        return query_send;
    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }


        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"madmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
    }

    async DeleteId(id,type=1){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        formData.append('type',type);

        var get_status=await fetch(Appthis.ConstApp.home_api+"madmin/delete", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Cập nhật dữ liệu thất bại");
        }
        else{
            toast("Cập nhật dữ liệu thành công");
        }

        this.setState({
            page:0
        },function(){
            this.getData(true);
        });



    }

    ResetPassword(id){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {

                                self.ActionResetPassWord(id);

                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    async ActionResetPassWord(id){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        Appthis.PageLoad(true);

        var get_status=await fetch(Appthis.ConstApp.home_api+"madmin/resetpassword", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Cập nhật thất bại");
        }
        else{


            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Thông báo !</h1>
                            <p>Mật khẩu đã được cấp lại : {get_status['password']}</p>
                            <button onClick={onClose}>Đồng ý</button>
                        </div>
                    );
                }
            });

        }

        Appthis.PageLoad(false);


    }


    AlertActiveAdmin(id,type=1){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn cập nhật tài khoản này không</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DeleteId(id,type);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    OpenModal(data=null){

        var DataModal={
            status:true,
            title:'Tài khoản quản trị',
            data:[
                {
                    title:"Họ và tên",
                    value:null,
                    name:"username",
                    type:"input"
                },
                {
                    title:"Email",
                    value:null,
                    name:"email",
                    type:"input"
                },
                {
                    title:"Mật khẩu",
                    value:null,
                    name:"password",
                    type:"password"
                },
                {
                    title:"Nhập lại mật khẩu",
                    value:null,
                    name:"repeat_password",
                    type:"password"
                }
            ],
            _id:null
        };

        if(data!==null){
             DataModal={
                status:true,
                title:'Tài khoản quản trị',
                data:[
                    {
                        title:"Họ và tên",
                        value:data.username,
                        name:"username",
                        type:"input"
                    },
                    {
                        title:"Email",
                        value:data.email,
                        name:"email",
                        type:"input"
                    }
                ],
                _id:data._id
            }
        }

        this.setState({
            DataModal:DataModal
        });

    }

    ResetDataModal(){
        var Reset_DataModal={
            status:false,
            title:null,
            data:null,
            _id:null
        };

        this.setState({
            DataModal:Reset_DataModal
        })

    }

    async UpdateModal(response){

        var DataModal=this.state.DataModal;

        var Appthis= this.props.AppThis;

        Appthis.PageLoad(true);

        if(validateEmail(response['email'])){

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            var url_post=null;

            var data_submit={};

            if(this.state.DataModal._id ===null){

                data_submit.password=response.password;


                url_post=Appthis.ConstApp.home_api+"madmin/quickadd";

                if(response.password!==response.repeat_password){

                    toast("Mật khẩu không khớp");

                    Appthis.PageLoad(false);

                    return;
                }
                if( response.password.length<8){

                    toast("Yêu cầu mật khẩu lớn hơn 8 ký tự");

                    Appthis.PageLoad(false);

                    return;
                }

            }
            else{

                url_post=Appthis.ConstApp.home_api+"madmin/quickupdate";

                data_submit._id=DataModal._id;
            }

            data_submit.username=response.username;
            data_submit.email=response.email;

            formData.append("data",base64_encode(data_submit));

            const self=this;

            var get_data=await fetch(url_post, {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            var get_data=await get_data.json();

            if(get_data.status==='error'){
                toast("Cập nhật dữ liệu thất bại");
            }
            else{
                toast("Cập nhật dữ liệu thành công");
            }

        }
        else{
            toast("Email không hợp lệ");
        }

        this.ResetDataModal();

        await this.getData(false);

        Appthis.PageLoad(false);
    }


    SubmitModule(data){

        const self=this;

        const Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id', this.state.EditModule.data._id);

        formData.append('data', data);

        fetch(Appthis.ConstApp.home_api+"madmin/setmodule", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            self.setState({
                EditModule:{
                    status:false,
                    data:null
                }
            });

            if(data.status==='error'){
                toast("Cập nhật dữ liệu thất bại");
                Appthis.PageLoad(false);
            }
            else{

                toast("Cập nhật tài khoản thành công");

                self.getData(true);
            }


        }).
        catch((e)=>{

            self.setState({
                EditModule:{
                    status:false,
                    data:null
                }
            });

            toast("Cập nhật dữ liệu thất bại");

            Appthis.PageLoad(false);


        });

    }


    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Họ và tên</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Email</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã NV</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Truy cập</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>

                                    <td>{value['username']}</td>
                                    <td>{value['email']}</td>
                                    <td>
                                        <span>{value['code_user']}</span>
                                    </td>

                                    <td>
                                        {value['time_ping']!==null?timeConverter(value['time_ping']):""}
                                    </td>

                                    <td className={"fix-item-table"}>

                                        {value.visible===1?(
                                            <>
                                                <a onClick={()=>{

                                                    self.setState({
                                                        EditModule:{
                                                            status:true,
                                                            data:value
                                                        }
                                                    });

                                                }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cập nhật chức năng">
                                                        <i className="fas fa-charging-station" />
                                                    </Floater>
                                                </a>



                                                <a onClick={()=>{
                                                    self.ResetPassword(value['_id'])
                                                }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cấp lại mật khẩu">
                                                        <i className="fas fa-unlock-alt" />
                                                    </Floater>
                                                </a>

                                                <a onClick={()=>{
                                                    self.OpenModal(value);
                                                }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Sửa tài khoản">
                                                        <i className="fas font12 fa-edit" />
                                                    </Floater>
                                                </a>

                                                <a onClick={()=>{
                                                    self.AlertActiveAdmin(value['_id'],1)
                                                }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Hủy tư cách tài khoản">
                                                        <i className="far font12 fa-trash-alt" />
                                                    </Floater>
                                                </a>
                                            </>
                                        ):(
                                            <>
                                                <a onClick={()=>{
                                                    self.AlertActiveAdmin(value['_id'],2)
                                                }} className="rounded-0 d-inline-block me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Kích hoạt tài khoản">
                                                        <i className="fas fa-check-circle" />
                                                    </Floater>
                                                </a>
                                            </>
                                        )}

                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>

            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-2 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-12 pt-3 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Tài khoản quản trị</h3>
                            </div>

                            <div className="col-12 col-sm-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>


                                        <input onChange={(event)=>{
                                            var query=this.state.query;
                                            query.input_search=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} autoComplete={"off"} type="text" name="input_search"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Từ khóa tìm kiếm"/>

                                        <select onChange={(event)=>{
                                            var query=this.state.query;
                                            query.status=event.target.value;
                                            this.setState({
                                                query:query
                                            });
                                        }} className="mr-2 font13 rounded-0 w-auto form-control">
                                            <option value="2">Kích hoạt</option>
                                            <option value="1">Không Kích hoạt</option>
                                        </select>


                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>

                                        <a onClick={()=>{
                                            this.OpenModal(null);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>




                                    </div>

                                </div>



                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>


                        {this.state.DataModal.status===true?(
                            <EditModal size={"small"} onClose={()=>{
                                this.ResetDataModal();
                            }} GetDataSubmit={(response,required)=>{

                                if(required===true){
                                    self.UpdateModal(response);
                                }
                                else{
                                    toast("Vui lòng nhập đầy đủ thông tin");
                                }
                            }} title={this.state.DataModal.title} data={this.state.DataModal.data} />
                        ):(<></>)}



                        {this.state.EditModule.status!==false?(
                            <EditModule AppThis={this.props.AppThis} path={"madmin/module"} onClose={()=>{
                                this.setState({
                                    EditModule:{
                                        status:false,
                                        data:null
                                    }
                                });
                            }} GetDataSubmit={(data)=>{
                                var encode_data=base64_encode(data);
                                this.SubmitModule(encode_data);

                            }} data={this.state.EditModule.data} />
                        ):(<></>)}




                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
