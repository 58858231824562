/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    array_to_select, base64_encode,
    timeConverter,
} from "../../helper/array";
import Select from "react-select";
import DetailTimeKeeping from "../../global/timekeeping/DetailTimeKeeping";
import {confirmAlert} from "react-confirm-alert";
import DatePicker from "react-datepicker";
import Floater from 'react-floater';
import EditModal from "../../global/EditModal";

const INIT_STATE={
    data:null,
    query:{
        id_admin:null,
        start_time:null,
        end_time:null
    },
    AllAdmin:null,
    DataModal:{
        status:false,
        data:null,
    },
    page:0
};

export default class employeetimekeeping extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;
        var query_send={};
        Object.entries(get_query).map(function(item){

            var [key,value]=item;

            if(value!==null){
                query_send[key]=value;
            }

        });

        return query_send;
    }
    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"employeetimekeeping", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getAllAdminAddress();
    }

    AlertExports(){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn xuất dữ liệu ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ExportData();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    async ExportData(){

       this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        var get_data=await fetch(Appthis.ConstApp.home_api+"employeetimekeeping/export", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_data=await get_data.json();

        if(get_data.status==='error'){
            toast("Xuất dữ liệu thất bại");
        }
        else{
            toast("Đã gửi dữ liệu tới máy chủ");
        };
        Appthis.PageLoad(false);

    }

    getAllAdminAddress(){


        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"employeetimekeeping/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var update_admin=[];

                update_admin.push({
                    username:"Chọn tất cả",
                    _id:null
                });
                var update_admin=update_admin.concat(data.data);
                self.setState({
                    AllAdmin:update_admin
                });

            }


        }).catch((e)=>{


        });

    }

    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Họ và tên</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã NV</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Địa chỉ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Thời gian</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>

                                    <td>{value['id_admin']['username']}</td>
                                    <td>{value['id_admin']['code_user']}</td>
                                    <td>{value['id_address']['name']}</td>
                                    <td>{timeConverter(value['time'])}</td>
                                    <td className={"fix-item-table"}>


                                        <a className={"d-inline-block btn btn-primary rounded-0"} onClick={()=>{

                                            self.setState({
                                                DataModal:{
                                                    status:true,
                                                    data:value['id_admin']
                                                }
                                            });

                                        }}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết chấm công">
                                                <i className={"far fa-hand-point-right"} />
                                            </Floater>
                                        </a>

                                    </td>

                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>

            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-4 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Chấm công nhân viên</h3>
                            </div>

                            <div className="col-12 col-sm-12 col-md-8 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>
                            </div>


                            <div className={"col-12"}>
                                <button onClick={()=>{
                                    this.setState({
                                        ModalSearchMobile:true
                                    });
                                }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                            </div>



                            <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                    <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                        <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                        <a onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                            <i className="fas fa-times"></i>
                                        </a>
                                    </div>



                                    {this.state.AllAdmin!==null?(
                                        <>

                                            <div className={"box_input d-flex"}>

                                                {this.state.query.start_time!==null?(
                                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                                        var query=this.state.query;

                                                        var tmp_time=null;

                                                        if(date!==null){
                                                            tmp_time=date.getTime()/1000;
                                                        }
                                                        query['start_time']=tmp_time;
                                                        this.setState({
                                                            query:query
                                                        });


                                                    }} selected={new Date(this.state.query.start_time*1000)} placeholderText={"Ngày bắt đầu"} />
                                                ):(
                                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                                        var query=this.state.query;

                                                        var tmp_time=null;
                                                        if(date!==null){
                                                            tmp_time=date.getTime()/1000;
                                                        }

                                                        query['start_time']=tmp_time;
                                                        this.setState({
                                                            query:query
                                                        });


                                                    }} placeholderText={"Ngày bắt đầu"} />
                                                )}



                                                {this.state.query.end_time!==null?(
                                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                                        var query=this.state.query;

                                                        var tmp_time=null;

                                                        if(date!==null){
                                                            tmp_time=date.getTime()/1000;
                                                        }
                                                        query['end_time']=tmp_time;
                                                        this.setState({
                                                            query:query
                                                        });



                                                    }} selected={new Date(this.state.query.end_time*1000)} placeholderText={"Ngày kết thúc"} />
                                                ):(
                                                    <DatePicker isClearable dateFormat="dd/MM/yyyy" onChange={(date) => {
                                                        var query=this.state.query;

                                                        var tmp_time=null;

                                                        if(date!==null){
                                                            tmp_time=date.getTime()/1000;
                                                        }

                                                        query['end_time']=tmp_time;
                                                        this.setState({
                                                            query:query
                                                        });


                                                    }} placeholderText={"Ngày kết thúc"} />
                                                )}



                                            </div>


                                            <div className={"customCssSelect w200px me-1"}>
                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var select_id=selectedOption['value'];
                                                    var query=this.state.query;
                                                    query.id_admin=select_id;

                                                    self.setState({
                                                        query:query
                                                    });

                                                }} options={array_to_select(this.state.AllAdmin,'username','_id')} />
                                            </div>

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>


                                            <a onClick={()=>{
                                                this.AlertExports();
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="btn me-1 btn-outline-danger rounded-0 btn-event-search">
                                                <i className="fas fa-cloud-download-alt"/> Xuất dữ liệu </a>


                                        </>
                                    ):(
                                        <ItemLoading />
                                    )}




                                </div>

                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>

                        {this.state.DataModal.status===true?(
                            <DetailTimeKeeping onClose={()=>{
                                this.setState({
                                    DataModal:{
                                        status:false,
                                        data:null,
                                    }
                                });
                            }} AppThis={this.props.AppThis} path={"employeetimekeeping"} data={this.state.DataModal.data}  />
                        ):(<></>)}



                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
