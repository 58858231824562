/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {formatNumber, timeConverter, timeConverterDate} from "../../helper/array";
import {number_did_contract, status_contract, status_payment} from "../../helper/member";
import DetailContract from "../../global/contract/DetailContract";
import no_avatar from "../../assets/images/_no_avatar.png";
import Floater from 'react-floater';
import {toast} from "react-toastify";

export default class ContractUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            page: 0,
            query: {
                text_search: null
            },
            InfoUser: null,
            HistoryPayUser:null,
            DataDetailContract: null,
            newContract: false,
            UpdateDataContract:null,
        };

    }

    componentDidMount() {

        const self=this;

        if(this.props.data && typeof this.props.data!=='undefined'){

            this.setState({
                InfoUser:this.props.data
            },function(){
                self.GetHistoryWallet();
                self.getContractUser();
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    GetHistoryWallet(){

        const self=this;

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('id_user', this.state.InfoUser._id);

        fetch(Appthis.ConstApp.home_api+this.props.path+"/history", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    HistoryPayUser:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getContractUser(){

        const self=this;

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('id_user', this.state.InfoUser._id);

        fetch(Appthis.ConstApp.home_api+this.props.path+"/contracthistory", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    data:data.data
                });

            }


        }).catch((e)=>{

        });


    }


    RenderContractUser(){

        var InfoUser=this.state.InfoUser;

        const self=this;
        return(
            <div className={"row m-0"}>

                <div className={"col-12 col-sm-12 col-lg-4 d-none d-lg-block"}>

                    <div className={"col-12 col-sm-12 col-md-10"}>
                        <div className="col-12 mb-2 font-weight-bold border-bottom text-uppercase me-2 font16 text-danger pt-2 pb-2">
                            <i className="far fa-user me-1" /> Thông tin khách hàng
                        </div>

                        <div className={"col-12 mb-3 mt-3"}>

                            {InfoUser.avatar!==null ?(
                                <div className={"box-avatar-150 m-auto"}>
                                    <img src={self.props.AppThis.ConstApp.home_url +"/" +InfoUser.avatar} />
                                </div>

                            ):(
                                <div className={"box-avatar-150 m-auto"}>
                                    <img src={no_avatar} />
                                </div>
                            )}


                        </div>

                        <div className={"row bg-light m-0"}>
                            <div className={"col-6 col-sm-6 p-2 font-weight-bold"}>
                                <i className="far fa-user me-1"/>  Họ và tên
                            </div>
                            <div className={"col-6 col-sm-6 p-2"}>
                                {InfoUser.username?InfoUser.username:InfoUser.tmp_user}
                            </div>
                        </div>

                        <div className={"row border-bottom m-0"}>
                            <div className={"col-6 col-sm-6 p-2 font-weight-bold"}>
                                <i className="far fa-envelope me-1" /> Email
                            </div>
                            <div className={"col-6 col-sm-6 p-2 text-break"}>
                                {InfoUser.email}
                            </div>
                        </div>

                        <div className={"row bg-light border-bottom m-0"}>
                            <div className={"col-6 col-sm-6 p-2 font-weight-bold"}>
                                <i className="fas fa-mobile-alt me-1" /> SDT/ID
                            </div>
                            <div className={"col-6 col-sm-6 p-2"}>
                                {InfoUser.user_login}
                            </div>
                        </div>

                        <div className={"row border-bottom m-0"}>
                            <div className={"col-6 col-sm-6 p-2 font-weight-bold"}>
                                <i className="fas fa-genderless me-1" /> Giới tính
                            </div>
                            <div className={"col-6 col-sm-6 p-2"}>
                                {InfoUser.sex===1?(
                                    <span className={"badge bg-primary rounded-0 font10"}>Nam</span>
                                ):(
                                    <span className={"badge bg-danger rounded-0 font10"}>Nữ</span>
                                )}
                            </div>
                        </div>

                        <div className={"row bg-light border-bottom m-0"}>
                            <div className={"col-6 col-sm-6 p-2 font-weight-bold"}>
                                <i className="fab fa-jsfiddle me-1" /> ID tài khoản
                            </div>
                            <div className={"col-6 col-sm-6 p-2"}>
                                {InfoUser.code_user}
                            </div>
                        </div>


                    </div>

                </div>

                <div className={"col-12 col-sm-12 col-md-12 col-lg-8"}>

                     <div className={"col-12 text-end pt-2"}>
                          <span onClick={()=>{
                              this.props.onClose();
                          }}><i className="font26 far fa-window-close" /></span>
                     </div>

                    <div className="col-12 mb-2 font-weight-bold border-bottom text-uppercase me-2 font16 pt-2 pb-2">
                        <i className="fas fa-history me-1" /> Giao dịch gần nhất
                    </div>


                    {this.state.HistoryPayUser!==null?(
                        this.RenderHistoryPayUser()
                    ):(
                        <div className={"col-12 d-flex flex-row justify-content-center align-items-center mt-4 mb-3"}>
                            <ItemLoading />
                        </div>
                    )}

                </div>

                <div className={"col-12 mt-4"}>

                    <div
                        className="col-12 mb-3 font-weight-bold border-bottom text-uppercase me-2 font16 pt-2 pb-2">
                        <i className="fas fa-file-signature me-1" /> Danh sách HD khách hàng
                    </div>


                    {this.state.data!==null?(
                      <div className={"col-12 mt-3"}>

                          {this.RenderContract()}

                      </div>
                    ):(
                        <div className={"col-12 d-flex flex-row justify-content-center align-items-center mt-4 mb-3"}>
                            <ItemLoading />
                        </div>
                    )}




                </div>

            </div>
        );
    }


    RenderContract(){

        const self=this;
        if(this.state.data.data.length===0){

            return(
                <div className={"col-12 alert alert-danger rounded-0"}>
                    <i className="fas fa-history me-1" /> Không có HD nào
                </div>
            );
        }
        else{

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"overflow-auto col-12"}>
                    <table className={"mt-3 w-100 table border-0 mw2700 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-user me-2"/> TÊN HỘI VIÊN</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i
                                    className="fas fa-mobile-alt me-2" /> SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-shield-alt me-2" />
                                MÃ KH</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-credit-card me-2" />
                                DỊCH VỤ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-file-excel me-2" />
                                SỐ HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-question-circle me-2" />
                                TRẠNG THÁI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-dollar-sign me-2" />
                                GIÁ TRỊ HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-clock me-2" />
                                NGÀY BẮT ĐẦU</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-history me-2" />
                                NGÀY KẾT THÚC</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-calendar-times me-2" />
                                TỔNG SỐ BUỔI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-calendar-times me-2" />
                                TỔNG SỐ BUỔI CÒN LẠI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-user-edit me-2" />
                                NHÂN VIÊN BÁN HÀNG</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-info me-2" />
                                PT PHỤ TRÁCH</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                <i className="fas fa-directions me-2" />
                                THAO TÁC</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        <span>{value.id_user.username}</span>
                                    </td>
                                    <td>
                                        <span>{value.id_user.user_login}</span>
                                        {value.id_user.is_phone!==null?(
                                            <>
                                                <span className={"text-danger font-weight-bold font10 ms-2"}>{value.id_user.is_phone}</span>
                                            </>
                                        ):(<></>)}

                                    </td>
                                    <td>
                                        <span>{value.id_user.code_user}</span>
                                    </td>
                                    <td>
                                        <span className={"me-2"}>{value.id_card.name}</span>
                                        {value.id_card.type===1?(<span className={"badge p-1 bg-primary"}>PT</span>):(
                                            <></>
                                        )}

                                        {value.id_card.type===2?(<span className={"badge p-1 bg-primary"}>MB</span>):(
                                            <></>
                                        )}
                                        {value.id_card.type===3?(<span className={"badge p-1 bg-warning"}>LH</span>):(
                                            <></>
                                        )} {value.id_card.type===4?(<span className={"badge p-1 bg-primary"}>CD</span>):(
                                        <></>
                                    )}
                                    </td>

                                    <td>
                                        {value.group_member!==null?(
                                            <i className="fas fa-check-circle text-primary me-1" />
                                        ):(<></>)}

                                        <span className={"me-1"}>{value.number_contract}</span>
                                        {status_payment(value)}
                                    </td>
                                    <td>
                                        {status_contract(value)}

                                        {typeof value.status_print!=='undefined' && value.status_print!==0?(
                                            <i className="fas fa-print ms-2 text-success" />
                                        ):(
                                            <></>
                                        )}

                                    </td>

                                    <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.info_contract_data.price_sale)} VNĐ
                                       </span>
                                    </td>

                                    <td>
                                        <span>{timeConverterDate(value.start_day)}</span>
                                    </td>

                                    <td>
                                        <span>{timeConverterDate(value.end_day_contract)}</span>
                                    </td>

                                    <td>
                                        {value.id_card.type!==2?(
                                            <span>{value.info_contract_data.number_card}</span>
                                        ):(<></>)}


                                    </td>

                                    <td>
                                        {value.id_card.type!==2?(
                                            <>
                                                {number_did_contract(value)}
                                            </>
                                        ):(<></>)}
                                    </td>

                                    <td>
                                        <span>{value.id_admin_create.username+ " - "+value.id_admin_create.code_user}</span>
                                    </td>

                                    <td>
                                       <span>{value.id_admin_pt!==null?(
                                           value.id_admin_pt.username+" - "+value.id_admin_pt.code_user
                                       ):(<></>)}</span>
                                    </td>


                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.setState({
                                                DataDetailContract:value
                                            });
                                        }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết HD">
                                            <i className={"far fa-folder-open"}></i>
                                            </Floater>
                                        </a>





                                    </td>

                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>
            );
        }

    }

    RenderHistoryPayUser(){

        if(this.state.HistoryPayUser.data.length===0){

            return(
                <div className={"col-12 alert alert-danger rounded-0"}>
                    <i className="fas fa-history me-1" /> Không có giao dịch nào
                </div>
            );
        }
        else{

            return(

                <div className={"col-12 mt-3"}>


                    {this.state.HistoryPayUser.data.map(function (item,index){

                        if(index<3){
                            return(
                                <div className={"col-12 mb-4"}>

                                    <div className="row m-0">

                                        <div className={"col-12 col-sm-12 col-md-8 pt-3 pb-3 bg-light-bold"}>

                                            <div className={"row"}>

                                                <div className={"col-12 col-sm-12 col-md-12"}>
                                                    <p className={"m-0"}>Số HD :
                                                        <strong>{item.id_member.number_contract}</strong>
                                                    </p>
                                                    <p className={"m-0"}><i className="far fa-money-bill-alt me-1" /> Số tiền : <span className={"font-weight-bold text-red"}>{formatNumber(item.price)} VNĐ</span></p>

                                                    <p className={"m-0"}><i className="fab fa-google-pay me-1 font18" /> Phương thức thanh toán : {item.id_payment?item.id_payment.name:"___"}</p>
                                                </div>



                                            </div>

                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-4 d-flex flex-row align-items-end justify-content-end font12"}>
                                      <span>
                                           <i className="far fa-clock me-1" /> <span>{timeConverter(item.time)}</span>
                                      </span>
                                        </div>

                                    </div>

                                </div>
                            );
                        }
                        else{
                            return(<></>);
                        }


                    })}



                </div>


            );
        }

    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }

    render(){

        const self=this;
        return(
            <>
                <div className="d-block mt-5 pt-4">

                        <div className="border-0 bg-light mt-3">

                            {this.state.InfoUser!==null?(

                                <div className={"container bg-white mb-5 pt-3 pb-5 shadow"}>

                                    {this.RenderContractUser()}

                                </div>
                            ):(
                                <div className={"col-12 pt-4 pb-3 d-flex justify-content-center align-items-center"}>
                                    <ItemLoading size={"Large"} />
                                </div>
                            )}




                        </div>
                </div>


                {this.state.DataDetailContract!==null?(
                    <DetailContract OnClose={()=>{
                        this.setState({
                            DataDetailContract:null
                        });
                    }} EventDetail={(id)=>{
                        this.setState({
                            DataDetailContract:null
                        },function(){
                            self.getDetailContract(id);
                        });
                    }} data={this.state.DataDetailContract} />
                ):(
                    <></>
                )}

            </>
        );
    }

}
