/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import logo from "../../assets/images/logo.png";

export default class cqrcode extends Component {

    constructor(props) {
        super(props);

        this.state={
            data:null
        };

    }


    getData(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"cqrcode", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }


        }).catch((e)=>{


        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
    }


    render(){
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 text-center pt-5 offset-lg-3 d-flex justify-content-center align-items-center"}>

                        <div className={"col-12 p-0 rounded box-qrcode bg-white pb-5 shadow"}>

                            <div className={"text-center mb-4 pt-3 pb-3 bg-primary font80 text-white"}>
                                <i className={"fas fa-wifi"}></i>
                            </div>

                            <div className={"col-12 mb-3 mt-3 text-center"}>

                                <img src={logo} width={100} />

                            </div>

                            <div className="col-12 mb-3">
                                <p
                                    className="text-uppercase font18 text-black-50 font-weight-bold">Quét mã QR
                                    code để checkin</p>
                            </div>

                            <div className={"col-12 p-0"}>

                                <img src={this.state.data} />

                            </div>


                        </div>

                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
