/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import {
     _isset_object, _isset_string,
    array_to_select, base64_encode,
    get_data_in_args, get_name_day, getItemInArray,
} from "../../helper/array";
import Select, {components} from "react-select";
import lodash from "lodash";
import {confirmAlert} from "react-confirm-alert";
import InputMask from "react-input-mask";
import no_avatar from "../../assets/images/_no_avatar.png";

export default class TryCheckin extends Component {
    constructor(props) {
        super(props);

        this.state={
            user:{
                username:null,
                address_contact:null,
                citizen_identification:null,
                sex:0,
                user_login:null
            },
            data:{
                id_user:null,
                id_card:null,
                current_subject:null,
                statics_member_group:null,
                admin_pt_session:null
            },
            ProfileInfo:null,
            ListSubject:null,
            ListCard:{
                data:null,
                type:null,
                DetailCard:null,
            },
            GroupStatics:null,
            ListAdmin:null,
            page:0,
            id_admin:null,
            TmpUser:null
        };
        this.SelectCard=React.createRef();
    }
    componentDidMount() {

        const self=this;

        this.getSubject();

        this.getCard();

        this.GetGroupStatics();

        this.getAdmin();


        if(this.props.AppThis){
            this.setState({
                id_admin:this.props.AppThis.GetInfoProfile()
            });
        }

        if(this.props.data){
            var get_data=this.props.data;
            this.setState({
                data:get_data,
            },function (){
                self.forceUpdate();
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.CloseModal!=='undefined'){
                this.props.CloseModal();
            }
        }
        if(event.key==='Enter'){
            this.SaveCheckin();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListAdmin:data.data
                });
            }


        }).catch((e)=>{

        });


    }

    GetGroupStatics(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        fetch(AppThis.ConstApp.home_api+"groupstatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                this.setState({
                    GroupStatics:data.data
                });

            }
        }).catch((e)=>{

        });


    }


    async getCard(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        if(this.state.ListCard.type!==null){
            formData.append('type', this.state.ListCard.type);
        }

        var data=await fetch(AppThis.ConstApp.home_api+"card", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        data=await data.json();

        if(data.status==='ok'){

            var ListCard=this.state.ListCard;

            ListCard.data=data.data;

            ListCard['DetailCard']=null;

            this.setState({
                ListCard:ListCard
            },function(){
                if(this.SelectCard.current!==null && typeof this.SelectCard.current!=='undefined'){
                    this.SelectCard.current.clearValue();
                }
            });

        }

    }

    async DetailCard(id){
        if(id!==null){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('id_card',id);
            const self=this;

            var get_data=await fetch(Appthis.ConstApp.home_api+"card/detail", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();

            if(get_data.status==='ok'){
                var ListCard=this.state.ListCard;
                ListCard['DetailCard']=get_data.data;
                self.setState({
                    ListCard:ListCard
                });
            }

        }
    }

    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListSubject:data.data
                });

            }


        }).catch((e)=>{

        });


    }
    OptionLayout(props,GroupStatics){
        const self=this;
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{props.data.label}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>
                    <p className={"m-0"}>HLV : {get_data_item.id_admin_pt.username+" - "+get_data_item.id_admin_pt.code_user}</p>
                    <p className={"m-0"}>Ngày :
                        {Object.keys(get_data_item.days).length>0?(
                            Object.entries(get_data_item.days).map(function(item,index){
                                return(<span className={"m-1 badge font10 bg-black-50 p-1"}>{get_name_day(item[1])}</span>);
                            })
                        ):(<></>)}
                    </p>
                    <p className={"m-0"}>giờ : {get_data_item.time_start} - {get_data_item.time_end}</p>
                </components.Option>
            );
        }
        else{
            return(<></>);
        }
    }
    SaveCheckin(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;

        var number_error=0;

        var data_send=lodash.cloneDeep(this.state.data);

        ['id_user','id_card','current_subject'].map(function(item){
            if(typeof data_send[item]==='undefined' || data_send[item]===null){
                number_error+=1;
            }
        });

        var data_send_base=base64_encode(data_send);

        if(number_error===0){

            formData.append('data',data_send_base);

            AppThis.PageLoad(true);

            fetch(AppThis.ConstApp.home_api+this.props.path+"/trycheckin", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){
                    toast("Cập nhật thành công");
                    self.props.SuccessModal();
                }
                else{
                    toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
                    self.props.CloseModal();
                }

                AppThis.PageLoad(false);

            }).catch((e)=>{
                toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
                AppThis.PageLoad(false);
                self.props.CloseModal();
            });
        }
        else{
            toast("Vui lòng kiểm tra lại");
        }

    }

    AlertSaveCheckin(){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h4 className={"mb-2"}>CHECK-IN TẬP THỬ</h4>
                        <p className={"text-center"}>Bạn có chắc thực hiện thao tác này ?</p>
                        <div className={"row w-100 mt-3"}>
                            <div className={"col-12 col-sm-6"}>
                                <button className={"w-100 d-block"} onClick={onClose}>Từ chối</button>
                            </div>
                            <div className={"col-12 col-sm-6"}>
                                <button className={"w-100 d-block"}
                                   onClick={() => {
                                       self.SaveCheckin();
                                       onClose();
                                   }}
                                > Đồng ý
                                </button>
                            </div>

                        </div>


                    </div>
                );
            }
        });

    }

    RenderActionCard(){

        var DetailCard=this.state.ListCard.DetailCard;

        const self=this;

        if(DetailCard!==null){
            var type_card=DetailCard['type'];

            switch (type_card){
                    case 1:
                        return(
                            <div className={"row mt-3 mb-3"}>
                                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 customCssSelect pb-2"}>
                                    <p className={"mb-1"}>Huấn Luyện viên <span className={"text-red"}>( * )</span></p>

                                    {self.state.ListAdmin!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var data=self.state.data;
                                            data.admin_pt_session=selectedOption.value;
                                            self.setState({
                                                data:data
                                            });

                                        }} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                    ):(
                                        <ItemLoading />
                                    )}

                                </div>
                            </div>
                        )
                    case 3:
                    case 4:
                        return(
                            <div className={"row mt-3 mb-3"}>
                                {type_card===4?(
                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                        <p className={"mb-1"}>Lớp nhóm cố định <span className={"text-red"}>( * )</span></p>
                                        {self.state.GroupStatics!==null?(
                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                var data=self.state.data;
                                                data.statics_member_group=selectedOption.value;
                                                self.setState({
                                                    data:data
                                                });

                                            }} options={array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                                Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                            }} />

                                        ):(
                                            <ItemLoading />
                                        )}

                                    </div>
                                ):(<></>)}


                            </div>
                        )

                }

        }
        else{
            return(<></>);
        }

    }

    UpdateUserData(name=null,value=null){

        var user=this.state.user;

        user[name]=value;

        this.setState({
            user:user
        });

    }


    async fetchUser(phone,loading=false){
        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }
        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/user", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        data=await data.json();

        if(loading===true){
            Appthis.PageLoad(false);
        }

        return data;

    }

    async getInfoUser(phone,loading=false){

        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }

        var get_data=await this.fetchUser(phone);

        //TmpUser
        if(get_data.status==='ok'){

            var data_update=this.state.data;

            if(Object.keys(get_data.data).length>0){

                data_update.id_user=get_data.data._id;

                this.setState({
                    user:get_data.data,
                    data:data_update,
                    TmpUser:lodash.cloneDeep(get_data.data)
                });
            }

            else{

                data_update.id_user=null;

                this.setState({
                    user:{
                        user_login:phone
                    },
                    data:data_update,
                    TmpUser:null
                });

            }



        }

        if(loading===true) {
            Appthis.PageLoad(false);
        }

    }

    async SubmitUpdateUser(){

        var get_user=this.state.user;

        var number_error=0;

        ['username','user_login'].map(function(item,index){
            if(get_user[item]===null || typeof get_user[item]==='undefined' || get_user[item].length<3){
                number_error+=1;
            }
        });

        if(get_user['user_login'].length!==10){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{
            var user_update={};

            ['sex','username','user_login'].map(function(item){
                user_update[item]=get_user[item];
            });

            if(typeof get_user['_id']!=='undefined'){
                user_update['_id']=get_user['_id'];
            }

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(user_update));

            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updateuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();

            if(get_data.status==='ok'){

                var data=this.state.data;

                data['id_user']=get_data.data;

                this.setState({
                    data:data,
                    page:1
                });

            }
            else{
                toast("Thao tác không thành công");
            }

            Appthis.PageLoad(false);

        }

    }

    Step2(){

        const self=this;
        return(
            <div className={"col-12"}>

                <div className={"col-12 mb-5"}>

                    <div className={"alert alert-danger rounded-0"}>
                        <i className="fas fa-exclamation-triangle" /> Vui lòng cập nhật buổi tập hôm nay của khách hàng
                    </div>

                    <div className={"row"}>

                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 mb-3 Box-Select-Card customCssSelect pb-2 position-relative"}>
                            <p className={"mb-1"}>Gói thẻ <span className={"text-red"}>( * )</span></p>
                            {this.state.ListCard.data!==null?(
                                <Select menuPosition={"bottom"} menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{

                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                        var data=this.state.data;
                                        data.id_card=selectedOption.value;
                                        data.admin_pt_session = null;
                                        data.statics_member_group = null;
                                        this.setState({
                                            data:data
                                        },function(){
                                            self.DetailCard(selectedOption.value);
                                        });
                                    }
                                }} ref={this.SelectCard} options={array_to_select(this.state.ListCard.data, "name","_id")} />
                            ):(
                                <ItemLoading />
                            )}

                            {this.RenderActionCard()}

                        </div>

                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>

                            <p className={"mb-1"}>Bộ môn <span className={"text-red"}>( * )</span></p>

                            {this.state.ListSubject!==null?(
                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                    var get_data=self.state.data;
                                    get_data['current_subject']=get_data_in_args(selectedOption,"value");
                                    self.setState({
                                        data:get_data
                                    });

                                }} isMulti={true} options={array_to_select(this.state.ListSubject,'name','_id')} />

                            ):(
                                <ItemLoading />
                            )}
                        </div>

                    </div>



                    <div className={"row"}>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 offset-lg-6 p-0 mt-2 d-flex flex-row align-items-center">

                            <div className={"row m-0 w-100"}>

                                <div className={"col-12 col-sm-6 offset-sm-6"}>
                                    <a onClick={()=>{
                                        self.AlertSaveCheckin();
                                    }} className={"btn btn-outline-primary rounded-0 w-100"}>
                                        <i className="far fa-save me-2" />  Checkin
                                    </a>

                                </div>
                            </div>

                        </div>



                    </div>

                </div>


            </div>
        );

    }

    Step1(){

        const self=this;

        var profile=self.props.AppThis.GetInfoProfile();

        return(<>
            <p className={"text-uppercase font16 mb-3 font-weight-bold"}>THÔNG TIN KHÁCH HÀNG</p>

            <div className={"row"}>
                <div className={"col-12 col-sm-6 col-md-6 mb-3 box_input"}>

                    <p className={"m-0"}>SDT/ID : <span className={"text-red"}>( * )</span></p>

                    <InputMask mask="9999999999" onChange={event=>{
                        var input=event.target.value;
                        input=input.replaceAll("_","");
                        self.UpdateUserData("user_login",input);

                        if(input.length===10){
                            self.getInfoUser(input,true);
                        }

                    }} placeholder={"Số điện thoại"} />

                </div>
                <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                    <p className={"m-0"}>Họ và tên : <span className={"text-red"}>( * )</span></p>
                    {this.state.TmpUser!==null && _isset_string(self.state.TmpUser.username) && profile.type!==1?(
                        <div className={"is-verify-data"}>
                            <input type={"text"} defaultValue={self.state.TmpUser.username} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                        </div>
                    ):(
                        <input type={"text"} onChange={(text)=>{
                            self.UpdateUserData("username",text.target.value);
                        }} defaultValue={this.state.TmpUser!==null?self.state.TmpUser.username:""} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                    )}

                </div>

                <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                    <p className={"m-0"}>Địa chỉ : </p>

                    {this.state.TmpUser!==null && _isset_string(self.state.TmpUser.address_contact) && profile.type!==1?(
                        <div className={"is-verify-data"}>
                            <input type={"text"} defaultValue={self.state.TmpUser.address_contact} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                        </div>
                    ):(
                        <input type={"text"} onChange={(text)=>{
                            self.UpdateUserData("address_contact",text.target.value);
                        }}  defaultValue={this.state.TmpUser!==null?self.state.TmpUser.address_contact:""} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                    )}


                </div>



                <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                    <p className={"m-0"}>Số căn cước : </p>

                    {this.state.TmpUser!==null && _isset_string(self.state.TmpUser.citizen_identification) && profile.type!==1?(
                        <div className={"is-verify-data"}>
                            <input type={"text"} defaultValue={self.state.TmpUser.citizen_identification} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />
                        </div>
                    ):(
                        <input type={"text"} onChange={(text)=>{
                            self.UpdateUserData("citizen_identification",text.target.value);
                        }} defaultValue={this.state.TmpUser!==null?self.state.TmpUser.citizen_identification:""} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />

                    )}


                </div>


            </div>

            <div className={"row mt-3"}>

                <div className={"col-12 col-sm-12 col-md-6 col-lg-3 offset-lg-9 offset-md-6"}>
                    <a onClick={()=>{
                        this.SubmitUpdateUser();
                    }} className={"btn btn-primary w-100 text-white mt-3"} >
                        Tiếp theo
                    </a>
                </div>

            </div>

        </>);
    }

    ViewTryCheckin(){

        if(this.state.page===0){
            return this.Step1();
        }
        else{
            return this.Step2();
        }

    }
    render(){
        return(
            <div className="modal d-block position-fixed modal-dialog-end">

                <div className={"modal-dialog container-fluid modal-dialog-scrollable"}>

                    <div className="modal-content shadow border-0 rounded-0">

                        <div className="modal-header bg-header-user rounded-0 pt-3 pb-3">
                            <div className={"container position-relative"}>
                                <strong className={"font18 text-uppercase"}>
                                    <i className="fas fa-plus-circle me-2" />KH Tập thử</strong>

                                <a onClick={()=>{
                                    this.props.CloseModal();
                                }} className={"font-weight-bold position-absolute top-0 right-0 font15 text-white"}>
                                    <i className="far fa-times-circle" /> Đóng
                                </a>

                            </div>
                        </div>


                        <div className="modal-body">

                            <div className={"container position-relative p-0"}>


                                {this.state.id_admin!==null?(
                                    <div className={"row h-100 DetailNewsTransaction"}>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-3 d-none d-lg-block left-info-transaction border-light p-0 border-start border-end"}>

                                            <div className={"col-12 p-2 alert alert-primary mb-4 font-weight-bold rounded-0 text-uppercase font16"}>
                                                <i className="far fa-user me-2" />  Chi tiết nhân viên
                                            </div>

                                            <div className={"col-12 mb-4 text-center"}>

                                                {this.state.id_admin.avatar!==null ?(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img src={this.props.AppThis.ConstApp.home_url +"/" +this.state.id_admin.avatar} />
                                                    </div>

                                                ):(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img  src={no_avatar} />
                                                    </div>
                                                )}
                                            </div>

                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="far fa-user" /> Họ và tên
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{this.state.id_admin.username}</span>
                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-terminal" /> Mã NV
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    {this.state.id_admin.code_user}

                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="far fa-envelope" />  Email
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {this.state.id_admin.email}
                                                </div>
                                            </div>

                                            <div className={"row m-0 mb-2 p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-phone-alt" />   Phone
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {this.state.id_admin.phone_number!==null?this.state.id_admin.phone_number:"Chưa rõ"}
                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-paper-plane" />  Chức vụ
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {typeof this.state.id_admin.position!=='undefined' && this.state.id_admin.position!==null?this.state.id_admin.position.name:"Chưa rõ"}
                                                </div>
                                            </div>


                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-9 pt-4 right-info-transaction"}>
                                            {this.ViewTryCheckin()}
                                        </div>

                                    </div>
                                ):(
                                    <ItemLoading />
                                )}


                            </div>


                        </div>



                    </div>

                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />


            </div>
        );
    }
}
