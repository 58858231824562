/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_object,
    array_to_select,
    base64_encode, defaultValueObject,
    formatNumber, getItemInArray, in_module,
    timeConverterDate,
} from "../../helper/array";
import Floater from 'react-floater';
import Select from "react-select";
import DetailContract from "../../global/contract/DetailContract";
import DatePicker from "react-datepicker";
import SidebarWallet from "../../global/transaction/SidebarWallet";
import {get_name_type_receipts} from "../../helper/member";
import InfoPercentContract from "../../global/transaction/InfoPercentContract";
import {confirmAlert} from "react-confirm-alert";

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        start_time:null,
        end_time:null,
        number_contract:null,
        id_wallet:null
    },
    page:0,
    DataDetailContract:null,
    DataPercentContract:null,
    ListAdminClub:null,
    SearchAdvanced:false
};

export default class clubpercent extends Component {
    constructor(props) {
        super(props);
        this.state = lodash.cloneDeep(INIT_STATE);
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }


    AlertExport(){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ActionExport();
                                self.setState({
                                    SearchAdvanced:false
                                });
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    ActionExport(){

        this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        fetch(Appthis.ConstApp.home_api+"clubpercent/exports", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác đã gửi tới máy chủ");
            }

            Appthis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            Appthis.PageLoad(false);
        });


    }

    getAdminCLub(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"clubpercent/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListAdminClub:data.data
                });
            }

        }).catch((e)=>{

        });


    }


    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"clubpercent", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentDidMount() {

        this.getData();


        this.getAdminCLub();
    }

    thisRenderData(){
        const self=this;
        return(
            <div className={"row m-0 mt-4"}>
                <div className={"col-12"}>

                    {this.state.data.data.length>0?(
                        <div className={"col-12 box-table-fix"}>
                        <div className={"col-12 overflow-auto"}>
                            <table className={"mt-2 w-100 table border-0 mw1800 table-style-old"}>

                                <thead>
                                <tr className={"border-bottom bg-light"}>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-list-ol me-1" /> Số phiếu thu
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-money-check me-2"/> Loại phiếu thu</td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="far fa-clock me-1" /> Ngày thu tiền
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="far fa-user me-1" />  Tên hội viên
                                    </td>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fab fa-amazon-pay me-1" /> Loại TT
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="far fa-credit-card me-1" /> Phương thức TT
                                    </td>


                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-file-contract me-1" /> Số HD
                                    </td>
                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="far fa-money-bill-alt me-1" /> Số tiền thu
                                    </td>


                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-user-circle me-1" /> Nhân viên bán hàng
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                        <i className="fas fa-user-edit me-1" />  Nhân viên lễ tân
                                    </td>

                                    <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                        <i className="fas fa-location-arrow me-1" /> Thao tác
                                    </td>

                                </tr>
                                </thead>

                                <tbody>

                                {this.state.data.data.map(function(item){
                                    return(
                                        <tr>
                                            <td>
                                                {item.data===null || item.data.length===0?(
                                                    <span className={'me-2 text-danger'}>
                                                        <i className="fas fa-minus-circle"></i>
                                                    </span>
                                                ):(
                                                    <span className={'me-2 text-primary'}>
                                                      <i className="far fa-check-circle"></i>
                                                  </span>
                                                )}

                                                {item.id_wallet.number_votes}
                                            </td>

                                            <td>
                                                {get_name_type_receipts(item.id_wallet.type_receipts)}
                                            </td>

                                            <td>
                                                {timeConverterDate(item.id_wallet.time)}
                                            </td>
                                            <td>
                                                {item.id_wallet.id_user.username}
                                            </td>
                                            <td>
                                                {item.id_wallet.type_of_paid===1?(
                                                    <span className={"badge bg-primary font12"}>Trả hết</span>
                                                ):(<></>)}
                                                {item.id_wallet.type_of_paid===2?(
                                                    <span className={"badge bg-danger font12"}>Đặt cọc</span>
                                                ):(<></>)}
                                                {item.id_wallet.type_of_paid===3?(
                                                    <span className={"badge bg-success font12"}>Trả nốt</span>
                                                ):(<></>)}
                                            </td>
                                            <td>
                                                {item.id_wallet.id_payment?item.id_wallet.id_payment.name:null}
                                            </td>
                                            <td>
                                                {_isset_object(item.id_member) && item.id_member.group_member!==null?(
                                                    <i className="fas fa-check-circle text-primary me-1" />
                                                ):(<></>)}

                                                {_isset_object(item.id_member)?item.id_member.number_contract:"Chưa rõ"}
                                            </td>
                                            <td>
                                                <span className={"font-weight-bold text-red"}> {formatNumber(item.price)} VND</span>
                                            </td>


                                            <td>
                                                {item.id_wallet.id_admin.username+" - "+item.id_wallet.id_admin.code_user}
                                            </td>
                                            <td>
                                                {item.id_wallet.id_admin_update!==null?(
                                                    item.id_wallet.id_admin_update.username+" - "+item.id_wallet.id_admin_update.code_user
                                                ):(
                                                    <span className={"badge bg-danger font12"}>Chưa rõ</span>
                                                )}
                                            </td>
                                            <td className={"fix-item-table"}>
                                                {_isset_object(item.id_member)?(
                                                    <a onClick={()=>{
                                                        self.setState({
                                                            DataDetailContract:item.id_member
                                                        });
                                                    }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>

                                                        <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xem chi tiết HD">
                                                            <i className={"far fa-folder-open"}></i>
                                                        </Floater>
                                                    </a>
                                                ):(<></>)}


                                                <a onClick={()=>{
                                                    self.SetPercentContract(item);
                                                }} className={"rounded-0 font12 d-inline-block btn btn-outline-danger p-2 me-1"}>
                                                    <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chia % phiếu thu">
                                                        <i className="fas fa-percent" />
                                                    </Floater>
                                                </a>




                                            </td>

                                        </tr>
                                    );
                                })}


                                </tbody>

                            </table>

                        </div>
                        </div>
                    ):(
                        <div className={"alert alert-danger rounded-0"}>
                            <i className="fas fa-exclamation-triangle me-1" />  Không có dữ liệu
                        </div>
                    )}


                    <div className={"col-12 p-3 mt-4"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>



                </div>
            </div>
        );
    }

    SetPercentContract(item){
        this.setState({
            DataPercentContract:item
        });

    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"clubpercent/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }

    render(){
        const self=this;
        if(this.state.data!==null){
                return (
                    <div className={"container-fluid container-main-template mb-5"}>


                        <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center">
                                    <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Chia % Hợp đồng</h3>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right text-end">
                                    <div className="col-12 p-0 mb-2">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>

                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>

                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>



                                            <input onChange={(event)=>{
                                                var query=this.state.query;
                                                query.input_search=event.target.value;
                                                this.setState({
                                                    query:query
                                                });
                                            }} autoComplete={"off"} type="text" name="input_search"
                                                   className="font13 form-control d-inline-block w-auto rounded-0"
                                                   placeholder="Họ và tên/Mã NV"/>

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>


                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:true,
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                                <i className="fas fa-search-plus" /> Tìm kiếm nâng cao </a>





                                        </div>

                                    </div>



                                </div>

                            </div>




                            {this.thisRenderData()}


                        </div>


                        {this.state.DataDetailContract!==null?(
                            <DetailContract OnClose={()=>{
                                this.setState({
                                    DataDetailContract:null
                                });

                            }} EventDetail={(id)=>{
                                this.setState({
                                    DataDetailContract:null
                                },function(){
                                    self.getDetailContract(id);
                                });
                            }} data={this.state.DataDetailContract} />
                        ):(
                            <></>
                        )}

                        {this.state.SearchAdvanced===true?(
                            this.SearchAdvanced()
                        ):(<></>)}


                        {this.state.DataPercentContract!==null?(
                            <InfoPercentContract AppThis={this.props.AppThis} path={"clubpercent"} data={this.state.DataPercentContract} onClose={()=>{
                                this.setState({
                                    DataPercentContract:null
                                });
                            }} />
                        ):(
                            <></>
                        )}


                    </div>
                );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );

        }


    }

    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm nâng cao</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row box_input"}>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian bắt đầu"} />


                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>

                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Tên khách hàng/SDT</p>
                                    <input defaultValue={this.state.query.input_search} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("input_search",event.target.value);
                                    }} className={"form-control"} placeholder={"Tên khách hàng/SDT"} />
                                </div>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số Hợp đồng</p>
                                    <input defaultValue={this.state.query.number_contract} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("number_contract",event.target.value);
                                    }} className={"form-control"} placeholder={"Số hợp đồng"} />
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Số phiếu thu</p>
                                    <input defaultValue={this.state.query.id_wallet} type={"text"} onChange={(event)=>{
                                        self.UpdateQuerySearch("id_wallet",event.target.value);
                                    }} className={"form-control"} placeholder={"Số phiếu thu"} />
                                </div>


                                <div className={"col-12 col-sm-12 col-md-6 pb-3 Box-Select-Mutiple"}>
                                    <p className={"mb-1"}>NV bán hàng</p>
                                    {this.state.ListAdminClub!==null?(
                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                self.UpdateQuerySearch("id_admin",selectedOption.value);
                                            }
                                            else {
                                                self.UpdateQuerySearch("id_admin",null);
                                            }
                                        }} defaultValue={this.state.query.id_admin!==null?defaultValueObject(getItemInArray(this.state.query.id_admin,"key",self.state.ListAdminClub),"value","key"):null} options={array_to_select(self.state.ListAdminClub,'value','key')} />

                                    ):(
                                        <ItemLoading />
                                    )}


                                </div>


                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"row"}>


                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                self.getData(true);

                                                self.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>



                                        {in_module('clubexport',this.props.AppThis.GetInfoProfile().module)===true?(
                                            <div className={"col-12 col-sm-12 col-lg-3"}>
                                                <a onClick={()=>{
                                                    this.AlertExport();
                                                }} className={"btn btn-danger w-100 text-white mt-3 font14"} >
                                                    <i className="fas fa-cloud-download-alt" /> Xuất % doanh thu
                                                </a>
                                            </div>
                                        ):(
                                            <></>
                                        )}




                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-dark w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }
}
