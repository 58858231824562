/* eslint-disable */
import React,{Component} from "react";
import {
    _isset_object, _isset_string,
    array_to_select, base64_encode,
    getItemInArray,
    input_price, remove_empty_array,
    remove_input_price,
} from "../../helper/array";

import InputMask from "react-input-mask";
import ItemLoading from "../ItemLoading";
import no_avatar from "../../assets/images/_no_avatar.png";
import Select from "react-select";
import {toast} from "react-toastify";
import InfoProfile from "../profile/InfoProfile";
import lodash from "lodash";
export default class NewsTransaction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user:{
                username:null,
                address_contact:null,
                citizen_identification:null,
                sex:0,
                user_login:null
            },
            id_admin:null,
            data:{
                id_user:null,
                id_payment:null,
                id_bank:null,
                note:null,
                price:0,
                type_receipts:1
            },
            PaymentMethod:null,
            InfoPayment:null,
            paged:0,
            ListAdminClub:null,
            ListPercent:null,
            CalculationPercent:0,
            percent:{
                _id:null,
                data:[
                    {
                        id_admin:null,
                        id_percent:null,
                        type:true
                    }
                ]
            },
            ProfileInfo:null,
            TmpUser:null
        };

        this.TimeOutEvent=null;
    }

    UpdateUserData(name=null,value=null){

        var user=this.state.user;

        user[name]=value;

        this.setState({
            user:user
        });

    }

    getAdminCLub(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListAdminClub:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    getPercent(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/percent", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    ListPercent:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    componentDidMount() {

        this.setState({
            id_admin:this.props.AppThis.GetInfoProfile()
        });

        this.getPaymentMethod();

        this.getAdminCLub();

        this.getPercent();

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getPaymentMethod(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"getpayment", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    PaymentMethod:data.data
                });
            }

        }).catch((e)=>{

        });


    }

    async fetchUser(phone,loading=false){
        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }
        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/infouser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        data=await data.json();

        if(loading===true){
            Appthis.PageLoad(false);
        }

        return data;

    }

    async getInfoUser(phone,loading=false){

        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }

        var get_data=await this.fetchUser(phone);

        if(get_data.status==='ok'){

            var data_update=this.state.data;

            if(Object.keys(get_data.data).length>0){

                data_update.id_user=get_data.data._id;

                this.setState({
                    user:get_data.data,
                    data:data_update,
                    TmpUser:lodash.cloneDeep(get_data.data)
                });
            }
            else{

                data_update.id_user=null;

                this.setState({
                    TmpUser:null,
                    user:{
                        user_login:phone
                    },
                    data:data_update
                });

            }

        }


        if(loading===true) {
            Appthis.PageLoad(false);
        }

    }

    async UpdateUser(){

        var get_user=this.state.user;

        var number_error=0;
        ['username','user_login'].map(function(item,index){
            if(get_user[item]===null || typeof get_user[item]==='undefined' || get_user[item].length<3){
                number_error+=1;
            }
        });

        if(get_user['user_login'].length!==10){
            number_error+=1;
        }


        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{
            var user_update={};

            ['sex','username','user_login'].map(function(item){
                user_update[item]=get_user[item];
            });

            if(typeof get_user['_id']!=='undefined'){
                user_update['_id']=get_user['_id'];
            }

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(user_update));

            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updateuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();

            if(get_data.status==='ok'){

                var data=this.state.data;

                data['id_user']=get_data.data;

                this.setState({
                    data:data,
                    paged:2
                });

            }
            else{

                toast("Thao tác không thành công");
            }

            Appthis.PageLoad(false);

        }

    }

    SaveNewTransaction(type=0){

        var data=this.state.data;

        var number_error=0;

        ['id_user','price','id_payment'].map(function(item){

            if(data[item]===null){
                number_error+=1;
            }
        });

        if(data['price']<=0){
            number_error+=1;
        }

        if(this.state.InfoPayment && this.state.InfoPayment.bank.length>0){

            if(data['id_bank']===null){
                number_error+=1;
            }
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            this.props.AppThis.PageLoad(true);

            var AppThis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', AppThis.getToken());

            formData.append('id_address', AppThis.getAddress());

            formData.append('data', base64_encode(data));

            const self=this;

            fetch(AppThis.ConstApp.home_api+this.props.path+"/new", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{
                if(data.status==='error'){
                    toast("Thao tác thất bại");
                }
                else{
                    toast("Thao tác thành công");
                }

                AppThis.PageLoad(false);

                if(type===0){
                    self.props.onClose();
                }
                else{
                    var data_percent=self.state.percent;
                    data_percent._id=data.data;
                    self.setState({
                        paged:3,
                        percent:data_percent
                    });
                }

            }).catch((e)=>{
                self.props.onClose();
                toast("Thao tác thất bại");
                AppThis.PageLoad(false);
            });

        }

    }
    render(){
        return(
            <div className="modal d-block position-fixed modal-dialog-end">
                <div className={"modal-dialog container-fluid modal-dialog-scrollable"}>
                    <div className="modal-content shadow border-0 rounded-0">
                        <div className="modal-header rounded-0 pt-3 pb-3">

                            <div className={"container position-relative"}>
                                <strong className={"font16 text-uppercase text-black"}>
                                    <i className="far fa-user me-2" /> Thêm phiếu thu</strong>

                                <button onClick={()=>{
                                    this.props.onClose();
                                }} className={"btn btn-sm rounded-0 p-0 position-absolute end-0 top-0"}>
                                    <i className="far fa-times-circle font26 text-black" />
                                </button>
                            </div>

                        </div>
                        <div className="modal-body p-0">

                            <div className={"container"}>
                                {this.state.id_admin!==null?(
                                    <div className={"row h-100 DetailNewsTransaction"}>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-3 left-info-transaction border-light p-0 border-start border-end"}>

                                            <div className={"col-12 p-2 alert alert-primary mb-4 font-weight-bold rounded-0 text-uppercase font16"}>
                                                <i className="far fa-user me-2" />  Chi tiết nhân viên
                                            </div>

                                            <div className={"col-12 mb-4 text-center"}>

                                                {this.state.id_admin.avatar!==null ?(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img src={this.props.AppThis.ConstApp.home_url +"/" +this.state.id_admin.avatar} />
                                                    </div>

                                                ):(
                                                    <div className={"box-avatar-150 m-auto"}>
                                                        <img  src={no_avatar} />
                                                    </div>
                                                )}
                                            </div>

                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="far fa-user" /> Họ và tên
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    <span className={"font-weight-bold"}>{this.state.id_admin.username}</span>
                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-terminal" /> Mã NV
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7"}>
                                                    {this.state.id_admin.code_user}

                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="far fa-envelope" />  Email
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {this.state.id_admin.email}
                                                </div>
                                            </div>

                                            <div className={"row m-0 mb-2 p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-phone-alt" />   Phone
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {this.state.id_admin.phone_number!==null?this.state.id_admin.phone_number:"Chưa rõ"}
                                                </div>
                                            </div>


                                            <div className={"row m-0 mb-2 bg-light p-1"}>
                                                <div className={"col-12 col-sm-12 col-md-5"}>
                                                    <i className="fas fa-paper-plane" />  Chức vụ
                                                </div>
                                                <div className={"col-12 col-sm-12 col-md-7 text-line"}>
                                                    {typeof this.state.id_admin.position!=='undefined' && this.state.id_admin.position!==null?this.state.id_admin.position.name:"Chưa rõ"}
                                                </div>
                                            </div>


                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 pt-4 right-info-transaction"}>
                                            {this.ViewPage()}
                                        </div>

                                    </div>
                                ):(
                                    <ItemLoading />
                                )}
                            </div>


                        </div>

                        <div className={"modal-footer bg-light pt-3"} />


                    </div>
                </div>
                <div  className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />


                {this.state.ProfileInfo!==null?(
                    <InfoProfile onClose={()=>{
                        this.setState({
                            ProfileInfo:null
                        });
                    }} data={this.state.ProfileInfo} />
                ):(<></>)}


            </div>
        );
    }

    ViewPage(){
        const self=this;
        var profile=self.props.AppThis.GetInfoProfile();

        switch (this.state.paged) {

            case 0:
                return(
                    <>
                      <p className={"text-uppercase font16 mb-3 text-center font-weight-bold"}>CHỌN LOẠI PHIẾU THU</p>

                        <div className={"alert alert-danger rounded-0 text-center"}>
                            <i className="fas fa-exclamation-triangle" /> Vui lòng chọn loại phiếu thu của bạn
                        </div>

                        <div className={"col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-4"}>

                            <div className={"row mt-4 mb-5"}>
                                <div className={"col-12 col-sm-6 col-md-4 mb-3"}>
                                    <a href={"#"} onClick={()=>{

                                        var data=self.state.data;
                                        data['type_receipts']=1;

                                        this.setState({
                                            paged:1,
                                            data:data
                                        });

                                    }} className={"btn btn-danger rounded-0 w-100 p-2"}>
                                        <i className="fas fa-money-bill-alt me-2" /> Phiếu thu trước HD
                                    </a>

                                    <p className={"font12 mt-2 text-center"}>
                                        <em>Phiếu thu lên trước HD</em>
                                    </p>
                                </div>
                                <div className={"col-12 col-sm-6 col-md-4 mb-3"}>
                                    <a href={"#"} onClick={()=>{

                                        var data=self.state.data;
                                        data['type_receipts']=2;

                                        this.setState({
                                            paged:1,
                                            data:data
                                        });
                                    }} className={"btn btn-primary rounded-0 w-100 p-2"}>
                                        <i className="fas fa-money-check-alt me-2" />  Chuyển đổi PTTT
                                    </a>
                                    <p className={"font12 mt-2 text-center"}>
                                        <em>Chuyển đổi phương thức thanh toán</em>
                                    </p>

                                </div>

                            </div>

                        </div>
                    </>
                );
            case 1:
                return(<>
                    <p className={"text-uppercase font16 mb-3 font-weight-bold"}>THÔNG TIN KHÁCH HÀNG</p>

                    <div className={"row"}>
                        <div className={"col-12 col-sm-6 col-md-6 mb-3 box_input"}>

                            <p className={"m-0"}>SDT/ID : <span className={"text-red"}>( * )</span></p>

                            <InputMask key={"new_transaction_phone"} mask="9999999999" onChange={event=>{
                                var input=event.target.value;
                                input=input.replaceAll("_","");
                                self.UpdateUserData("user_login",input);

                                if(input.length===10){
                                    self.getInfoUser(input,true);
                                }

                            }} placeholder={"Số điện thoại"} />

                        </div>
                        <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                            <p className={"m-0"}>Họ và tên : <span className={"text-red"}>( * )</span></p>

                            {this.state.TmpUser!==null && _isset_string(self.state.TmpUser.username) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                    <input key={"new_transaction_user"} type={"text"} defaultValue={self.state.TmpUser.username} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                                </div>
                            ):(
                                <input key={"new_transaction_user"} type={"text"} onChange={(text)=>{
                                    self.UpdateUserData("username",text.target.value);
                                }} defaultValue={self.state.TmpUser!==null?self.state.TmpUser.username:""} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                            )}

                        </div>

                        <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                            <p className={"m-0"}>Địa chỉ : </p>


                            {this.state.TmpUser!==null &&  _isset_string(self.state.TmpUser.address_contact) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                    <input key={"new_transaction_address_contact"} type={"text"} defaultValue={self.state.TmpUser.address_contact} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                                </div>
                            ):(
                                <input key={"new_transaction_address_contact"} type={"text"} onChange={(text)=>{
                                    self.UpdateUserData("address_contact",text.target.value);
                                }}  defaultValue={this.state.TmpUser!==null?self.state.user.address_contact:""} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                            )}


                        </div>



                        <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                            <p className={"m-0"}>Số căn cước : </p>

                            {this.state.TmpUser!==null && _isset_string(self.state.TmpUser.citizen_identification) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                    <input key={"new_transaction_citizen_identification"} type={"text"} defaultValue={self.state.TmpUser.citizen_identification} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />
                                </div>
                            ):(
                                <input key={"new_transaction_citizen_identification"} type={"text"} onChange={(text)=>{
                                    self.UpdateUserData("citizen_identification",text.target.value);
                                }} defaultValue={this.state.TmpUser!==null?self.state.TmpUser.citizen_identification:""} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />

                            )}


                        </div>


                    </div>

                    <div className={"row mt-3"}>

                        <div className={"col-12 col-sm-12 col-md-6 col-lg-3 offset-lg-9 offset-md-6"}>
                            <a onClick={()=>{
                                this.UpdateUser();
                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                Tiếp theo
                            </a>
                        </div>

                    </div>

                </>);

            case 2:
                return(<>
                    <p className={"text-uppercase font16 mb-3 font-weight-bold"}>THANH TOÁN</p>

                    <div className={"row"}>
                        <div className="col-12 col-sm-12 col-md-6 box_input pb-3">
                            <p className="mb-2">Số tiền <span className="text-red">( * )</span></p>

                            <input value={input_price(self.state.data.price)} type={"text"} onChange={(event)=>{

                                try{
                                    var data=self.state.data;
                                    data['price']=remove_input_price(event.target.value);
                                    this.setState({
                                        data:data
                                    });
                                }
                                catch (e){

                                }

                            }} placeholder={"Số tiền"} className={"form-control w-100 rounded-0"} />

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6"}>
                            <p className={"d-block w-100 mb-2"}> Phương thức thanh toán <span className={"text-red"}>( * )</span></p>

                            {this.state.PaymentMethod!==null?(
                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){

                                        var data=self.state.data;

                                        data['id_payment']=selectedOption.value;

                                        var InfoPayment=getItemInArray(selectedOption.value,"_id",this.state.PaymentMethod);
                                        self.setState({
                                            InfoPayment:InfoPayment,
                                            data:data
                                        });

                                    }
                                    else {
                                        var data=self.state.data;
                                        data['id_payment']=null;
                                        self.setState({
                                            InfoPayment:null,
                                            data:data
                                        });
                                    }

                                }} options={array_to_select(this.state.PaymentMethod,'name','_id')} />
                            ):(
                                <ItemLoading />
                            )}


                        </div>


                        {this.state.InfoPayment!==null && this.state.InfoPayment.bank.length>0?(
                            <div className={"d-flex flex-wrap flex-row"}>
                                {this.state.InfoPayment.bank.map(function(item,index){
                                    return(
                                        <div className={"col-12 col-sm-6 col-md-4 col-lg-4 mb-3 p-2"}>

                                            <div onClick={()=>{
                                                var data=self.state.data;
                                                data['id_bank']=item._id;

                                                self.setState({
                                                    data:data
                                                });

                                            }} className={self.state.data.id_bank===item._id?("col-12 border position-relative active_tab_box"):("col-12 border position-relative")}>

                                                <div className={"col-12 p-2 mb-3 font-weight-bold border-bottom text-center font15 text-uppercase left-box-info-bank"}>
                                                    <p className={"m-0"}><i
                                                        className="fab fa-cc-amazon-pay" /> {item.name}</p>
                                                </div>

                                                <div className={"col-12 p-3 right-box-info-bank"}>

                                                    <p className={"m-0"}>
                                                        <i
                                                            className="fas fa-user"/> Chủ tài khoản :
                                                        {item.user_account}
                                                    </p>
                                                    <p className={"m-0"}>
                                                        <i
                                                            className="fab fa-cc-paypal" /> Số Tài khoản :
                                                        {item.account}
                                                    </p>

                                                </div>

                                            </div>


                                        </div>
                                    );
                                })}
                            </div>
                        ):(<></>)}

                        <div className="col-12 col-sm-12 col-md-6 box_input pb-3">
                            <p className="mb-2">Ghi chú :</p>
                            <input type="text" id="note" name="note" className="form-control" onChange={(event)=>{

                                var data=self.state.data;
                                data['note']=event.target.value;
                                self.setState({
                                    data:data
                                });

                            }} placeholder="Ghi chú"/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 offset-md-6 box_input pb-3 text-end">
                            <div className={"row"}>


                                {this.state.data.type_receipts===1?(
                                    <div className={"col-12 col-sm-12 col-md-6"}>
                                        <button type={"button"} onClick={()=>{
                                            this.SaveNewTransaction(1);
                                        }} className={"btn w-100 btn-danger rounded-0"}>
                                            Thêm và chia %
                                        </button>
                                    </div>

                                ):(
                                    <div className={"col-12 col-sm-12 col-md-6"}></div>
                                )}

                                <div className={"col-12 col-sm-12 col-md-6"}>

                                    <button type={"button"} onClick={()=>{
                                        this.SaveNewTransaction();
                                    }} className={"btn w-100 btn-primary rounded-0"}>
                                        Thêm phiếu thu
                                    </button>
                                </div>



                            </div>

                        </div>


                    </div>

                </>);

            case 3:
                return(
                    <>
                        <p className={"text-uppercase font16 mb-3 font-weight-bold"}>Chia % Phiếu thu</p>

                        <div className={"col-12"}>

                            <div className={"alert alert-danger rounded-0 mb-4"}>
                                <i className="fas fa-exclamation-triangle" /> Vui lòng nhập đầy đủ thông tin
                            </div>



                            {this.state.percent.data.map(function(item,index){
                                return(
                                    <div className={"row pb-3 pt-3 border-bottom"}>

                                        <div className={"col-12 col-md-6 col-lg-3 d-flex flex-row flex-wrap align-items-center mb-2"}>

                                            <label>
                                                <input type={'checkbox'} className={"form-check-input"} onChange={()=>{
                                                    var type_update=!item.type;
                                                    self.UpdatePercentData(index,'type',type_update);
                                                    self.UpdatePercentData(index,'id_admin', null);
                                                }} /> Ngoài Club
                                            </label>

                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-3 mb-2"}>

                                            {item.type===true?(
                                                <>
                                                    {self.state.ListAdminClub!==null?(
                                                        <Select menuPlacement={'bottom'} isSearchable={true} onChange={(selectedOption)=>{
                                                            if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                                self.UpdatePercentData(index,'id_admin',selectedOption.value);
                                                            }
                                                            else {
                                                                self.UpdatePercentData(index,'id_admin',null);
                                                            }
                                                        }} options={array_to_select(self.state.ListAdminClub,'value','key')} />
                                                    ):(
                                                        <ItemLoading />
                                                    )}
                                                </>
                                            ):(
                                                <>
                                                    <input type={'text'} onKeyUp={(event)=>{
                                                        if(event.keyCode===8){
                                                            self.UpdatePercentData(index,'id_admin',null);
                                                            event.target.value='';
                                                        }
                                                        else{
                                                            self.EventInputCode(index,event);
                                                        }
                                                    }} value={item.id_admin!==null?item.id_admin.code_user:null} defaultValue={item.id_admin!==null?item.id_admin.code_user:null}
                                                           className={"form-control w-100"} placeholder={"Mã nhân viên"} />
                                                </>
                                            )}

                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-3 mb-2"}>

                                            {self.state.ListPercent!==null?(
                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                                        self.UpdatePercentData(index,'id_percent',selectedOption.value);
                                                    }
                                                    else {
                                                        self.UpdatePercentData(index,'id_percent',null);

                                                    }

                                                }} options={array_to_select(self.state.ListPercent,'name','_id')} />
                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-3 align-items-end d-flex flex-row mb-2"}>
                                            <a onClick={()=>{
                                                self.DeletePercentData(index);
                                            }} className={"text-black font16 me-3"}>
                                                <i className="fas fa-trash" />
                                            </a>

                                            <a onClick={()=>{
                                                self.AddPercentData();
                                            }} className={"text-black font16"}>
                                                <i className="text-black-50 fas fa-plus-circle" />
                                            </a>

                                        </div>

                                    </div>
                                );
                            })}


                            <div className={"row mt-5"}>

                                <div className={"col-12 col-sm-12 col-md-4 offset-md-8"}>
                                    <a onClick={()=>{
                                        this.SavePercentContract();
                                    }} className={"btn btn-primary rounded-0 w-100"}>
                                        <i className="fas fa-save me-1" /> Cập nhật
                                    </a>

                                </div>


                            </div>






                        </div>



                    </>
                );
        }




    }

    SavePercentContract(){

        var percent=this.state.percent;

        var percent_data=percent.data;

        var ListPercent=this.state.ListPercent;

        var number_error=0;

        var total_price=0;

        if(percent_data._id===null){
            number_error+=1;
        }

        if(percent_data.length===0){
            number_error+=1;
        }
        else{

            percent_data.map(function(item,index){

                if(item.id_admin===null || item.id_percent===null){
                    number_error+=1;
                }
                else{
                    var get_item=getItemInArray(item.id_percent,'_id',ListPercent);

                    total_price+=parseInt(get_item['number']);

                }


            });

        }

        if(total_price!==100){
            number_error+=1;
        }


        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var filter_percent_data=[];

            percent_data.map(function(item,index){

                var tmp={};

                tmp['id_admin']=item['id_admin'];

                if(typeof item['id_admin']['_id']!=='undefined'){
                    tmp['id_admin']=item['id_admin']['_id'];
                }

                tmp['id_percent']=item['id_percent'];

                filter_percent_data.push(tmp);

            });


            this.setState({
                Loading:true
            });


            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('id_wallet', this.state.percent._id);

            var base_64data=base64_encode(filter_percent_data);

            formData.append('data', base_64data);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/updatepercent", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){
                    toast("Cập nhật thành công");
                }
                else{
                    toast("Có lỗi trong quá trình xử lý xin vui lòng thử lại sau");
                }

                this.props.onClose();

            }).catch((e)=>{
                toast("Có lỗi trong quá trình xử lý xin vui lòng thử lại sau");
                this.props.onClose();
            });

        }

    }

    UpdatePercentData(number,key,value){
        var percent=this.state.percent;

        if(percent['data'][number]===null ||typeof percent['data'][number]==='undefined'){
            percent['data'][number]={};
        }
        percent['data'][number][key]=value;

        this.setState({
            percent:percent
        });
    }

    EventInputCode(index=null,event=null){
        const self=this;
        if(this.TimeOutEvent){
            clearTimeout(this.TimeOutEvent);
        }

        this.TimeOutEvent=setTimeout(function(){
            self.verifyAdmin(index,event.target.value,event);
        },1000);
    }


    verifyAdmin(index=null,code=null,event){

        if(index===null || code===null || code.length===0){
            return;
        }

        this.props.AppThis.PageLoad(true);

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('code_user', code);

        const self=this;

        fetch(AppThis.ConstApp.home_api+"staffinfo", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Không tìm thấy thông tin tài khoản");
                self.UpdatePercentData(index,"id_admin",null);
                event.target.value='';

                self.setState({
                    ProfileInfo:null
                });
            }

            else{

                self.setState({
                    ProfileInfo:data.data
                });

                self.UpdatePercentData(index,"id_admin",data.data);

            }
            AppThis.PageLoad(false);

        }).catch((e)=>{

            self.UpdatePercentData(index,"id_admin",null);

            event.target.value='';

            toast("Lấy dữ liệu thất bại");

            AppThis.PageLoad(false);

            self.setState({
                ProfileInfo:null
            });

        });

    }

    DeletePercentData(number){

        var percent=this.state.percent;

        var data_percent=percent.data;

        if (data_percent.length>1){
            delete data_percent[number];
        }

        data_percent=remove_empty_array(data_percent);

        percent.data=data_percent;

        this.setState({
            percent:percent
        });
    }

    AddPercentData(){

        var get_percent=this.state.percent;

        var get_percent_data=get_percent.data;

        get_percent_data.push({
            id_admin:null,
            id_percent:null,
            type:true
        });

        get_percent.data=get_percent_data;

        this.setState({
            percent:get_percent
        });

    }

}
