/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_object,
    base64_encode, timeConverter,
    timeConverterDate
} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import Floater from 'react-floater';
import DatePicker from "react-datepicker";
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        start_time:null,
        end_time:null,
    },
    page:0,
    SearchAdvanced:false
};

export default class mexport extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }


    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){

            var [key,value]=item;

            if(value!==null){
                query_send[key]=value;
            }

        });

        return query_send;
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }



        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mexport", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==="error"){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
    }

    AlertDownload(data,view=false){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn tải xuống tệp tin này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DownloadItem(data,view);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }
    AlertDeleteItem(data){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn xoá tệp tin này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DeleteItem(data);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    DownloadItem(data,view){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_export', data._id);

        fetch(Appthis.ConstApp.home_api+"mexport/download", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{
            if(data.status==="error"){
                toast("Tải dữ liệu thất bại");
            }
            else{
                toast("Tải dữ liệu thành công");

                if(view===true){
                    window.open("https://view.officeapps.live.com/op/view.aspx?src="+data.data,"_blank");
                }
                else{
                    window.open(data.data,"_blank");
                }


         }
            Appthis.PageLoad(false);
        }).catch((e)=>{

            toast("Tải dữ liệu thất bại");
            Appthis.PageLoad(false);
        });
    }
    DeleteItem(data){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_export', data._id);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"mexport/delete", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==="error"){
                toast("Xoá dữ liệu thất bại");
            }
            else{
                toast("Xoá dữ liệu thành công");
                self.getData(true);
            }

             Appthis.PageLoad(false);
        }).catch((e)=>{

            toast("Xoá dữ liệu thất bại");
                Appthis.PageLoad(false);
        });


    }


    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){
            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>STT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Tên tệp tin</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Kích cỡ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Thời gian thực hiện</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Người thực hiện</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        {index+1}
                                    </td>
                                    <td>{value['name']}</td>
                                    <td>{value['file_size']} KB</td>

                                    <td><span className={"badge bg-success p-1 font12"}>Thành công</span></td>
                                    <td>
                                        {timeConverter(value['time'],true)}
                                    </td>
                                    <td>
                                        {value['id_admin'] && _isset_object(value['id_admin'])?(
                                            <span className={"badge bg-primary p-1 font11"}>{value['id_admin']['username']}</span>
                                        ):(
                                            <span className={"badge bg-danger p-1 font11"}>Chưa rõ</span>
                                        )}
                                    </td>


                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.AlertDeleteItem(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xoá tệp tin">
                                            <i className="fas fa-trash" />
                                            </Floater>
                                        </a>

                                        <a onClick={()=>{
                                            self.AlertDownload(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Tải xuống tệp tin">
                                            <i className="fas fa-download" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.AlertDownload(value,true);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Xem online">
                                            <i className="fas fa-file-excel" />
                                            </Floater>
                                        </a>



                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <div className={"col-12 ps-3 pe-3"}>
                        <ul className="pagination">
                            {this.state.data.prev>=0?(
                                <li className="page-item" >
                                    <a className="page-link" onClick={()=>{
                                        var page=this.state.page-1;
                                        this.setState({
                                            page:page
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Trước
                                    </a>
                                </li>
                            ):(<></>)}

                            <li className="page-item">
                                <a className="page-link">
                                    <span>{this.state.data.curent+1}</span>
                                </a></li>

                            {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                <li className="page-item">
                                    <a className="page-link" onClick={()=>{
                                        this.setState({
                                            page:this.state.data.next
                                        },function(){
                                            self.getData(true);
                                        });
                                    }}>
                                        Kế tiếp
                                    </a>
                                </li>
                            ):(<></>)}

                        </ul>
                    </div>
                </div>
                </div>
        );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center">
                                <h3 className="font18 mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý tệp tin</h3>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>


                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>


                                        <input onChange={(event)=>{
                                            var query=this.state.query;
                                            query.input_search=event.target.value;
                                            this.setState({
                                                query:query
                                            });

                                        }} autoComplete={"off"} type="text" name="input_search"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Từ khóa tìm kiếm"/>

                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>


                                        <a onClick={()=>{
                                            this.setState({
                                                SearchAdvanced:true,
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                            <i className="fas fa-search-plus" /> Tìm kiếm nâng cao </a>



                                    </div>

                                </div>



                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.state.data!==null?(
                                this.thisRenderData()
                            ):(<ItemLoading />)}
                        </div>



                    </div>



                    {this.state.SearchAdvanced===true?(
                        this.SearchAdvanced()
                    ):(<></>)}



                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


    SearchAdvanced(){
        const self=this;
        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header bg-header-user">
                            <strong className={"font18 text-uppercase"}>Tìm kiếm nâng cao</strong>
                        </div>
                        <div className="modal-body">


                            <div className={"row box_input"}>

                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian bắt đầu</p>

                                    <DatePicker selected={this.state.query.start_time?new Date(this.state.query.start_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("start_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian bắt đầu"} />


                                </div>
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                    <p className={"mb-1"}>Thời gian kết thúc</p>
                                    <DatePicker selected={this.state.query.end_time?new Date(this.state.query.end_time*1000):null} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                        this.UpdateQuerySearch("end_time",Math.ceil(date.getTime()/1000));
                                    }} placeholder={"Thời gian kết thúc"} />

                                </div>


                            </div>

                        </div>


                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-12"}>

                                    <div className={"row"}>


                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                self.getData(true);

                                                self.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-primary w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-search me-1" />  Tìm kiếm
                                            </a>
                                        </div>



                                        <div className={"col-12 col-sm-12 col-lg-3"}>
                                            <a onClick={()=>{
                                                this.setState({
                                                    SearchAdvanced:false
                                                });

                                            }} className={"btn btn-dark w-100 text-white mt-3 font14"} >
                                                <i className="fas fa-ban me-1" /> Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );
    }

}
