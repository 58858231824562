/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import {
    _isset_array, _isset_object, add_to_item_array,
    array_to_select, ArrayInArray, base64_encode, defaultValueObject, formatNumber,
    get_data_in_args, get_name_day, getItemInArray,
    remove_empty_array,
} from "../../helper/array";
import Select, {components} from "react-select";
import lodash from "lodash";

export default class EditCheckin extends Component {
    constructor(props) {
        super(props);
        this.state={
            data:null,
            ListAdmin:null,
            ListSubject:null,
            ListProduct:null,
            CurrentSessionCheckin:{
                admin_pt_session:null,
                current_subject:[],
                product_in_card:[],
                product_buy_session:[],
                statics_member_group:null
            }
        };

    }

    componentDidMount() {

        this.getAdmin();

        this.getSubject();

        this.GetGroupStatics();

        this.getProduct();

        if(this.props.data){

            var get_data=this.props.data;

            var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

            var current_subject=[];

            var admin_pt_session=null;

            if(Object.keys(get_data).length>0){

                var product_in_card=[];

                var statics_member_group=null;

                // product in card
                var get_product_in_card=get_data['product_in_card'];
                if(get_product_in_card.length>0){
                    product_in_card=add_to_item_array(get_product_in_card,'status',true);
                }
                var get_product_sale=get_data['id_member']['product_sale'];
                if(_isset_array(get_product_sale)){

                    get_product_sale.map(function(item){

                        if(getItemInArray(item._id,"_id",get_data['product_in_card'])===null){

                            product_in_card.push({
                                _id:item._id,
                                number:1,
                                status:false
                            });

                        }

                    });

                }

                // buy product
                var product_buy_session=get_data['product_buy_session'];

                /*
                     if(product_buy_session.length>0){
                    product_buy_session=add_to_item_array(product_buy_session,'status',true);
                }
                 */

                // get subject
                var get_subject=get_data['current_subject'];
                if(_isset_array(get_subject)){
                    current_subject=get_data_in_args(get_subject,"_id");
                }


                if (get_data['admin_pt_session']!==null){
                    admin_pt_session=get_data['admin_pt_session']['_id'];
                }


                if(get_data['statics_member_group']!==null && _isset_object(get_data['statics_member_group'])){
                    statics_member_group=get_data['statics_member_group']['_id'];
                }

                CurrentSessionCheckin['id_checkin']=get_data['_id'];
            }


            CurrentSessionCheckin['product_in_card']=product_in_card;
            CurrentSessionCheckin['product_buy_session']=product_buy_session;
            CurrentSessionCheckin['current_subject']=current_subject;
            CurrentSessionCheckin['admin_pt_session']=admin_pt_session;
            CurrentSessionCheckin['statics_member_group']=statics_member_group;

            this.setState({
                data:get_data,
                CurrentSessionCheckin:CurrentSessionCheckin
            });

        }


        document.addEventListener("keydown", this.KeyEventFunction);

    }
    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.CloseModal!=='undefined'){
                this.props.CloseModal();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getProduct(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"product", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListProduct:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    deleteProductInCard(index){

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_in_card=CurrentSessionCheckin['product_in_card'];

        if(typeof product_in_card[index]!=='undefined'){

            delete product_in_card[index];
        }

        CurrentSessionCheckin['product_in_card']=remove_empty_array(product_in_card);

        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        });

    }

    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListSubject:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getAdmin(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListAdmin=[];

                ListAdmin.push({
                    key:null,
                    value:"Không tập PT"
                });

                ListAdmin=ListAdmin.concat(data.data);

                self.setState({
                    ListAdmin:ListAdmin
                });

            }

        }).catch((e)=>{

        });


    }

    GetGroupStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());

        fetch(Appthis.ConstApp.home_api+"groupstatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                this.setState({
                    GroupStatics:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    OptionLayout(props,GroupStatics){
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{props.data.label}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>
                    <p className={"m-0"}>HLV : {get_data_item.id_admin_pt.username+" - "+get_data_item.id_admin_pt.code_user}</p>
                    <p className={"m-0"}>Ngày :
                        {Object.keys(get_data_item.days).length>0?(
                            Object.entries(get_data_item.days).map(function(item,index){
                                return(<span className={"m-1 badge font10 bg-black-50 p-1"}>{get_name_day(item[1])}</span>);
                            })
                        ):(<></>)}
                    </p>
                    <p className={"m-0"}>giờ : {get_data_item.time_start} - {get_data_item.time_end}</p>
                </components.Option>
            );
        }
        else{
            return(<></>);
        }

    }

    DeleteItemBuyProduct(index){

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_buy_session=CurrentSessionCheckin['product_buy_session'];

        if(typeof product_buy_session[index]!=='undefined'){

            delete product_buy_session[index];
        }

        CurrentSessionCheckin['product_buy_session']=remove_empty_array(product_buy_session);

        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        });

    }

    UpdateItemBuyProduct(index,value,type){

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_buy_session=CurrentSessionCheckin['product_buy_session'];

        if(typeof product_buy_session[index]!=='undefined'){

            product_buy_session[index][type]=value;
        }

        CurrentSessionCheckin['product_buy_session']=remove_empty_array(product_buy_session);

        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        });

    }

    GetTotalPriceProduct(){

        var response=0;

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_buy_session=CurrentSessionCheckin['product_buy_session'];

        var ListProduct=this.state.ListProduct;

        if (_isset_array(product_buy_session) && ListProduct!==null){

            product_buy_session.map(function(item,index){

                var get_tmp_product=getItemInArray(item['_id'],"_id",ListProduct);

                if(get_tmp_product!==null){
                    response+=Math.ceil(get_tmp_product.price*parseInt(item['number']));
                }


            });

        }


        return response;
    }

    SaveCheckin(){

        var data_send=lodash.cloneDeep(this.state.CurrentSessionCheckin);

        var update_product_in_card=[];

        var product_in_card=data_send['product_in_card'];

        if(_isset_array(product_in_card)){

            product_in_card.map(function(item){

                if(item['status']===true){

                    update_product_in_card.push({
                        _id:item['_id'],
                        number:parseInt(item['number'])
                    });
                }

            })

        }

        data_send['product_in_card']=update_product_in_card;

        var update_product_buy=[];

        var get_update_product_buy=data_send['product_buy_session'];

        if(_isset_array(get_update_product_buy)){

            get_update_product_buy.map(function(item){

                if(item['_id']!==null && item['number']!==null){

                    update_product_buy.push({
                        _id:item['_id'],
                        number:parseInt(item['number'])
                    });
                }

            })

        }

        data_send['product_buy_session']=update_product_buy;

        data_send['id_contract']=this.state.data['id_member']['_id'];

        var AppThis=this.props.AppThis;

        AppThis.PageLoad(true);

        const formData = new FormData();

        const self=this;

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('data', base64_encode(data_send));

        fetch(AppThis.ConstApp.home_api+this.props.path+"/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Cập nhật thành công");
            }
            else{
                toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
            }

            self.props.CloseModal();

        }).catch((e)=>{
            toast("Có lỗi trong quá trình xử lý.Vui lòng thử lại");
            AppThis.PageLoad(false);
            self.props.CloseModal();
        });


    }

    changeStatusProduct(index){

        var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

        var product_in_card=CurrentSessionCheckin['product_in_card'];

        if(_isset_array(product_in_card)){

            if(typeof product_in_card[index]!=='undefined'){

                var get_status=product_in_card[index]['status'];

                if(get_status===true){
                    product_in_card[index]['status']=false;
                }
                else{
                    product_in_card[index]['status']=true;
                }

            }
        }
        CurrentSessionCheckin['product_in_card']=product_in_card;

        this.setState({
            CurrentSessionCheckin:CurrentSessionCheckin
        });

    }

    ViewCheckin(){

        var data=this.state.data;
        const self=this;

        return(
            <div className={"row"}>

                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 p-0"}>

                    {this.state.data!==null?(

                        <div className={"col-12 mb-3"}>

                            <div className={"col-12 p-0 mb-5"}>

                                <div className={"alert alert-primary rounded-0"}>
                                    <i className="fas fa-exclamation-triangle" /> Vui lòng cập nhật buổi tập hôm nay của khách hàng
                                </div>


                                <div className={"row"}>

                                    {data.id_member.id_card.id_product.length>0?(
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                                            <div className={"col-12 col-sm-12 col-md-12"}>

                                                <label className="d-block w-100 font-weight-bold pb-2 border-bottom text-uppercase mb-2">
                                                    Sản phẩm đi kèm thẻ tập
                                                </label>

                                                {self.state.CurrentSessionCheckin.product_in_card.map(function(item,index){

                                                        return(
                                                            <div className={"row mb-2"}>

                                                                <div className={"col-2 col-sm-2 d-flex flex-row justify-content-center align-items-center"}>
                                                                    {item.status===true?(
                                                                        <input onChange={()=>{
                                                                            self.changeStatusProduct(index);
                                                                        }} type={"checkbox"} checked={true} className={"font18 rounded-0 form-check-input"} />

                                                                    ):(
                                                                        <input onChange={()=>{
                                                                            self.changeStatusProduct(index);
                                                                        }} type={"checkbox"} checked={false} className={"font18 rounded-0 form-check-input"} />

                                                                    )}

                                                                </div>


                                                                <div className={"col-6 col-sm-6 d-flex flex-row flex-wrap align-items-center"}>
                                                                    {getItemInArray(item._id,"_id",data['id_member']['id_card']['id_product']).name}
                                                                </div>
                                                                <div className={"col-4 col-sm-4 p-2 ps-4 pe-4 bg-light-bold"}>
                                                                    {item.number}
                                                                </div>

                                                            </div>
                                                        );

                                                })}

                                            </div>

                                        </div>
                                    ):(

                                        <div className={"col-12"}>
                                        <div className="alert alert-info rounded-0 w-100">
                                            <i className="far fa-heart"></i> HD không có sản phẩm khuyến mãi
                                        </div>
                                        </div>
                                    )}




                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">

                                        <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                            Mua thêm sản phẩm

                                            <a className={"ms-2 font16"} onClick={()=>{

                                                var CurrentSessionCheckin=this.state.CurrentSessionCheckin;

                                                var product_buy_session=CurrentSessionCheckin['product_buy_session'];

                                                product_buy_session.push({
                                                    _id:null,
                                                    number:null
                                                });

                                                CurrentSessionCheckin['product_buy_session']=product_buy_session;

                                                self.setState({
                                                    CurrentSessionCheckin:CurrentSessionCheckin
                                                });

                                            }}><i className="fas fa-plus-circle" /></a>
                                        </label>

                                        <div className={"alert alert-warning p-2 rounded-0"}>
                                            Mua thêm sản phẩm ngoài hợp đồng
                                        </div>

                                        {this.state.ListProduct!==null?(
                                            <>
                                                {this.state.CurrentSessionCheckin.product_buy_session.length>0?(

                                                    this.state.CurrentSessionCheckin.product_buy_session.map(function(item,index){
                                                        return(
                                                            <div className={"row mb-2"}>
                                                                <div className={"col-5 col-sm-5"}>

                                                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                                                        if(selectedOption!==null && typeof selectedOption!=='undefined'){
                                                                            self.UpdateItemBuyProduct(index,selectedOption.value,'_id');
                                                                        }
                                                                    }} defaultValue={item._id?defaultValueObject(getItemInArray(item._id,"_id",self.state.ListProduct),"name","_id"):null} options={array_to_select(self.state.ListProduct,"name","_id")} />
                                                                </div>
                                                                <div className={"col-5 col-sm-5"}>
                                                                    <input className={"form-control w-100 rounded-0"} defaultValue={item.number?item.number:0} onChange={(event)=>{
                                                                        self.UpdateItemBuyProduct(index,parseInt(event.target.value),'number');
                                                                    }}  type={"number"} />
                                                                </div>
                                                                <div className={"col-2 col-sm-2 d-flex flex-row align-items-end"}>
                                                                    <a onClick={()=>{
                                                                        self.DeleteItemBuyProduct(index)
                                                                    }}><i className="font16 fas fa-trash"></i>
                                                                    </a>

                                                                </div>
                                                            </div>
                                                        );

                                                    })
                                                ):(
                                                    <></>
                                                )}

                                            </>
                                        ):(
                                            <div className={"col-12 text-center"}>
                                                <ItemLoading />
                                            </div>
                                        )}




                                    </div>


                                    {this.state.ListAdmin!==null && self.state.data['id_member'].info_contract_data.number_did_pt<self.state.data['id_member'].info_contract_data.number_card && self.state.data['id_member'].id_card['type']===1 && self.state.data['id_member'].id_admin_pt!==null && Object.keys(self.state.data['id_member'].id_admin_pt).length>0?(

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">

                                            <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                                PT hôm nay
                                            </label>

                                            <div className='col-12 col-sm-12 col-md-12'>

                                                <Select isSearchable={true} onChange={(selectedOption)=>{

                                                    var CurrentSessionCheckin=self.state.CurrentSessionCheckin;

                                                    CurrentSessionCheckin['admin_pt_session']=selectedOption.value;

                                                    self.setState({
                                                        CurrentSessionCheckin:CurrentSessionCheckin
                                                    });

                                                }} defaultValue={self.state.data.admin_pt_session!==null?(defaultValueObject(self.state.data.admin_pt_session,"username","_id","code_user")):null} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                            </div>

                                        </div>
                                    ):(
                                        <></>
                                    )}

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">

                                        <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                            Bộ môn hôm nay
                                        </label>

                                        {this.state.ListSubject!==null?(

                                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                                var CurrentSessionCheckin=self.state.CurrentSessionCheckin;
                                                CurrentSessionCheckin['current_subject']=get_data_in_args(selectedOption,"value");
                                                self.setState({
                                                    CurrentSessionCheckin:CurrentSessionCheckin
                                                });
                                            }} isMulti={true} defaultValue={ArrayInArray(self.state.CurrentSessionCheckin.current_subject,array_to_select(this.state.ListSubject,'name','_id'))} options={array_to_select(this.state.ListSubject,'name','_id')} />


                                        ):(
                                            <ItemLoading />
                                        )}

                                    </div>

                                    {self.state.data['id_member'].id_card.type===3 ?(

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                                            <label className="d-block w-100 mb-2 text-uppercase font-weight-bold">
                                                Chọn nhóm cố định
                                            </label>

                                            {self.state.GroupStatics!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{

                                                    var CurrentSessionCheckin=self.state.CurrentSessionCheckin;
                                                    CurrentSessionCheckin.statics_member_group=selectedOption.value;
                                                    self.setState({
                                                        CurrentSessionCheckin:CurrentSessionCheckin
                                                    });


                                                }} defaultValue={self.state.data.statics_member_group!==null?(defaultValueObject(self.state.data.statics_member_group,"name","_id")):null}  options={array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                                    Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                                }} />

                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>

                                    ):(
                                        <></>
                                    )}



                                    <div className={"col-12"} />


                                    {this.state.CurrentSessionCheckin.product_buy_session.length>0?(
                                        <div className={"col-12 mt-3"}>
                                            <p>Tổng tiền sản phẩm : <span className={"font-weight-bold text-red font15"}> {formatNumber(this.GetTotalPriceProduct())} VNĐ</span>
                                            </p>
                                        </div>
                                    ):(<></>)}


                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 offset-lg-6 p-0 mt-2 d-flex flex-row align-items-center">

                                        <div className={"row m-0 w-100"}>

                                            <div className={"col-12 col-sm-6 pb-3"}>
                                                <a onClick={()=>{
                                                    self.SaveCheckin();
                                                }} className={"btn btn-outline-primary rounded-0 w-100"}>
                                                    <i className="far fa-save me-2" />  Cập nhật
                                                </a>

                                            </div>

                                            <div className={"col-12 col-sm-6 pb-3"}>
                                                <a onClick={()=>{
                                                    this.props.CloseModal();
                                                }} className={"btn btn-outline-danger rounded-0 w-100"}>
                                                    <i className="far fa-times-circle me-2" /> Đóng
                                                </a>

                                            </div>



                                        </div>

                                    </div>



                                </div>


                            </div>

                        </div>

                    ):(
                        <div className={"col-12 mt-5 d-flex flex-row justify-content-center align-items-center"}>
                            <ItemLoading size={"Large"} />
                        </div>
                    )}

                </div>


            </div>
        );

    }

    render(){
        return(

            <div className="modal d-block position-fixed DataCheckin">

                <div className={"modal-dialog modal-lg"}>

                    <div className="modal-content bg-white shadow border-0">

                        <div className="modal-header bg-light p-2 pb-2">
                            <div className={"container position-relative"}>
                                <strong className={"font16 text-black text-uppercase"}>
                                    <i className="fas fa-plus-circle me-2" /> Cập nhật buổi tập</strong>

                                <a onClick={()=>{
                                    this.props.CloseModal();
                                }} className={"font-weight-bold position-absolute top-0 right-0 font15"}>
                                    <i className="far fa-times-circle" /> Đóng
                                </a>

                            </div>
                        </div>


                        <div className="modal-body">

                            <div className={"container position-relative"}>

                                {this.state.data?(
                                    this.ViewCheckin()
                                ):(
                                    <div className={"col-12 pt-5 mb-5 d-flex flex-row justify-content-center align-items-center"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>


                        </div>



                    </div>


                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

            </div>
        );
    }
}
