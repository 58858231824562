/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset,
    _isset_string, array_to_select, base64_encode,
    timeConverterDate,
} from "../../helper/array";
import FingerAdmin from "../../global/finger/FingerAdmin";
import Floater from 'react-floater';
import Select from "react-select";
const INIT_STATE={
    data:null,
    query:{
        input_search:null,
        position:null
    },
    DataModal:{
        status:false,
        info:{},
    },
    page:0,
    ModalSearchGlobal:{
        status:false,
        data:null,
        text:null
    },
    ListPosition:null
};

export default class clubfingerprintadmin extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }

    SearchGlobalUser(){

        var number_error=0;

        if(this.state.ModalSearchGlobal.text===null || this.state.ModalSearchGlobal.text.length===0){
            number_error+=1;
        }

        if(number_error>1){

            toast("Vui lòng nhập đầy đủ thông tin");
            return;
        }

       this.props.AppThis.PageLoad(true);

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('input_search', this.state.ModalSearchGlobal.text);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"clubfingerprintadmin/globaluser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                var ModalSearchGlobal=this.state.ModalSearchGlobal;

                ModalSearchGlobal['data']=data.data;

                self.setState({
                    ModalSearchGlobal:ModalSearchGlobal
                });
            }

            Appthis.PageLoad(false);


        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");

             Appthis.PageLoad(false);

        });


    }

    getPosition(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type',2);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"position", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='ok'){

                var ListPosition=data.data;

                ListPosition.unshift({
                    _id:null,
                    name:"Tất cả"
                });

                self.setState({
                    ListPosition:ListPosition
                });
            }


        }).
        catch((e)=>{

        });

    }


    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var SearchAdvanced=this.getQuerySearch();

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"clubfingerprintadmin/getuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    GetFingerprint(data){

        this.setState({
            DataModal:{
                status:true,
                info:data
            },
            ModalSearchGlobal:{
                status:false,
                data:null,
                text:null
            }
        });

    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();

        this.getPosition();
    }



    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Họ và tên</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Email</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Mã NV</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Số vân tay</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Ngày cập nhật</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>{value['username']}</td>
                                    <td>{value['email']}</td>
                                    <td>
                                        <span>{value['code_user']}</span>
                                    </td>

                                    <td>
                                        {value['number_fingerprint']}
                                    </td>


                                    <td>
                                        {value['last_time']!==null?timeConverterDate(value['last_time']):(
                                            <span className={"badge font10 bg-primary"}>Chưa rõ</span>
                                        )}
                                    </td>


                                    <td>
                                        {value.visible===1?(
                                            <span>Kích hoạt</span>
                                        ):(
                                            <span>Không kích hoạt</span>
                                        )}
                                    </td>


                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{
                                            self.GetFingerprint(value);
                                        }} className="rounded-0 d-inline-block mt-1 ms-1 me-1 font13 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết Vân tay">
                                                <i className="fas fa-fingerprint" />
                                            </Floater>
                                        </a>

                                    </td>


                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                </div>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>

                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Vân tay nhân viên</h3>
                            </div>

                            <div className="col-12 col-sm-12 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>



                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>




                                        <input onChange={(event)=>{
                                            this.UpdateQuerySearch("input_search",event.target.value);

                                        }} autoComplete={"off"} type="text"
                                               className="font13 form-control d-inline-block w-auto rounded-0"
                                               placeholder="Từ khóa tìm kiếm"/>


                                        {this.state.ListPosition!==null?(
                                            <div className={"customCssSelect w200px text-start me-1"}>
                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var select_id=selectedOption['value'];
                                                    this.UpdateQuerySearch("position",select_id);
                                                }} options={array_to_select(this.state.ListPosition,'name','_id')} />
                                            </div>

                                        ):(<></>)}



                                        <a onClick={()=>{
                                            this.getData(true);
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm NV trong Club </a>


                                        <a onClick={()=>{
                                            this.setState({
                                                ModalSearchGlobal:{
                                                    status:true,
                                                    data:null,
                                                    text:null
                                                },
                                                ModalSearchMobile:false
                                            })
                                        }} className="font13 btn me-1 btn-outline-danger rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm NV ngoài Club </a>


                                    </div>

                                </div>




                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>



                    </div>


                    {this.state.DataModal.status===true?(
                        <FingerAdmin onClose={()=>{
                            this.setState({
                                DataModal:{
                                    status:false,
                                    info:{},
                                }
                            });

                        }} AppThis={this.props.AppThis} path={"clubfingerprintadmin"} data={this.state.DataModal.info} />
                    ):(
                        <></>
                    )}



                    {this.state.ModalSearchGlobal.status===true?(
                        <div className="modal d-block position-fixed">
                            <div className={"modal-dialog modal-lg"}>
                                <div className="modal-content shadow border-0">
                                    <div className="modal-header">
                                        <strong className={"font18 text-uppercase"}>Tìm kiếm mã nhân viên</strong>
                                    </div>
                                    <div className="modal-body">


                                        <div className={"row"}>

                                            <div className={"col-12 col-sm-12 col-md-12"}>
                                                <input onChange={(text)=>{
                                                    var ModalSearchGlobal=this.state.ModalSearchGlobal;
                                                    ModalSearchGlobal.text=text.target.value;
                                                    this.setState({
                                                        ModalSearchGlobal:ModalSearchGlobal
                                                    });

                                                }} type={"text"} className={"form-control w-100 rounded-0"} placeholder={"Mã nhân viên"} />
                                            </div>



                                            {this.state.ModalSearchGlobal.data!==null?(
                                                <div className={"row mt-3 mb-3 Edit-Profile"}>
                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>
                                                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="far fa-user"/> Họ và tên </label>
                                                        <p className={"m-0 p-0"}>{this.state.ModalSearchGlobal.data.username}</p>
                                                    </div>
                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>
                                                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="far fa-envelope" /> Email</label>
                                                        <p className={"m-0 p-0"}>{this.state.ModalSearchGlobal.data.email}</p>
                                                    </div>
                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>

                                                        <label className="font-weight-400 mb-1 font-weight-bold"><i
                                                            className="fas fa-birthday-cake me-1" />Sinh nhật</label>



                                                    </div>

                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>
                                                        {_isset(this.state.ModalSearchGlobal.data.birthday)?(
                                                            <span className={"m-0 p-0 ms-3"}>{timeConverterDate(this.state.ModalSearchGlobal.data.birthday)}</span>
                                                        ):(
                                                            <span className={"m-0 p-1 ms-3 badge bg-danger"}>Chưa rõ</span>
                                                        )}
                                                    </div>

                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>

                                                        <label className="font-weight-400 mb-1 font-weight-bold"><i className="fas fa-phone-alt" /> Điện thoại</label>



                                                    </div>
                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>
                                                        {this.state.ModalSearchGlobal.data.phone_number?(
                                                            <span className={"m-0 p-0 ms-3"}>{this.state.ModalSearchGlobal.data.phone_number}</span>
                                                        ):(
                                                            <span className={"m-0 p-1 badge bg-danger span"}>Chưa rõ</span>
                                                        )}
                                                    </div>


                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>
                                                        <label className="font-weight-400 mb-1 font-weight-bold me-3"><i className="fas fa-genderless" /> Giới tính</label>

                                                    </div>


                                                    <div className={"col-6 col-md-6 border-bottom mb-2 pb-2"}>
                                                        {this.state.ModalSearchGlobal.data.sex?(
                                                            <p className={"d-inline-block m-0 p-0"}>Nam</p>
                                                        ):(
                                                            <p className={"d-inline-block m-0 p-0"}>Nữ</p>
                                                        )}
                                                    </div>

                                                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>


                                                        <a onClick={()=>{

                                                            this.GetFingerprint(this.state.ModalSearchGlobal.data);

                                                        }} className={"btn btn-warning rounded-0 w-100 font-weight-bold"} href={"#"}>Lấy vân tay</a>


                                                    </div>



                                                </div>

                                            ):(
                                                <></>
                                            )}


                                            <div className={"col-12 col-sm-12 col-md-12 mt-3"}>
                                                <div className={"row"}>

                                                    <div className={"col-6"}>
                                                        <a onClick={()=>{
                                                            this.SearchGlobalUser();
                                                        }} className={"w-100 d-block btn btn-primary rounded-0"} href={"#"}>Tìm kiếm</a>
                                                    </div>
                                                    <div className={"col-6"}>
                                                        <a className={"w-100 d-block btn btn-danger rounded-0"} onClick={()=>{
                                                            this.setState({
                                                                ModalSearchGlobal:{
                                                                    status:false,
                                                                    text:null,
                                                                    data:null
                                                                }
                                                            })
                                                        }} >
                                                            Đóng
                                                        </a>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>






                                </div>
                            </div>
                            <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                        </div>
                    ):(
                        <></>
                    )}


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

}
