/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {base64_encode, formatNumber, timeConverter, timeConverterDate} from "../../helper/array";
import {toast} from "react-toastify";
import ConstApp from "../../ConstApp";
import {confirmAlert} from "react-confirm-alert";
import {Fancybox} from "@fancyapps/ui";

export default class CSRContract extends Component {

    constructor() {
        super();
        this.state={
            data:null,
            InfoContract:null,
            page:0,
            isNewCSR:false,
            DataSubmit:{
                title:null,
                content:null,
                image:null,
                number_contract_old:null,
                month_term:null,
                session_term:null,
                day_renew:null,
                csr_paper:null
            },
            TmpName:null,
            ActiveCollapse:null
        };

        this.hiddenFileInput=React.createRef();
    }

    componentDidMount() {

        if(typeof this.props.data!=='undefined'){

            const self=this;

            this.setState({
                InfoContract:this.props.data
            },function(){
                self.getData();
            });

        }
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        var data={
            id_member:this.state.InfoContract._id,
            action:"get"
        };

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('page', this.state.page);

        formData.append('data', base64_encode(data));

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/csr", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }


    DeleteCSR(id){

        var AppThis=this.props.AppThis;

        AppThis.PageLoad(true);

        var data={
            id_member:this.state.InfoContract._id,
            action:"delete",
            data:{
                _id:id
            }
        };

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('data', base64_encode(data));

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/csr", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
                AppThis.PageLoad(false);
            }
            else{
                toast("Thao tác thành công");
                self.getData(true);

            }


        }).catch((e)=>{

            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });


    }

    AlertDelete(id){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn xóa CSR này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DeleteCSR(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }


    thisRenderData(){
        const self=this;
        return(
                <div className={"row"}>

                    {this.state.data.data.map(function(item){
                        return(
                            <div className={"col-12"}>
                                <div className={self.state.ActiveCollapse===item._id?"col-12 border mb-2 shadow":"mb-2 col-12 border"}  >
                                    <div className={self.state.ActiveCollapse===item._id?"col-12 position-relative pe-150 bg-dark text-white p-3":"col-12 position-relative pe-150 bg-dark p-3 text-white"}
                                         onClick={()=>{
                                             var UpdateActiveCollapse=item._id;
                                             if(self.state.ActiveCollapse===item._id) {
                                                 UpdateActiveCollapse=null;
                                             }
                                             self.setState({
                                                 ActiveCollapse:UpdateActiveCollapse
                                             });
                                         }}
                                    >
                                        <div className={"row"}>
                                            <div className={"col-12 col-sm-12 col-md-6"}>
                                                <div className={"text-line m-0 d-flex flex-row align-items-center position-relative font-weight-bold text-uppercase"}>
                                                    <div className={"font13 me-2 d-inline-flex text-white flex-row align-items-center"}><i
                                                        className="fas fa-unlock me-1 text-white"></i> {item.id_admin.username} - {item.id_admin.code_user}
                                                        <span className={"badge bg-danger ms-2 font11"}>{item.slug}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col-12 col-sm-12 col-md-6"}>
                                                <p className="font-weight-300 mb-0 font11 text-end" ><em>{timeConverter(item.time)}</em></p>
                                            </div>

                                        </div>


                                    </div>

                                    <div className={self.state.ActiveCollapse===item._id?"text-black col-12 text-justify body-item-csr p-3 d-block":"text-black d-none text-justify col-12 body-item-csr p-3"}>

                                        <p className={"mb-0 bg-light p-3 text-uppercase"}><strong>{item.title}
                                        </strong></p>


                                        <div className={"col-12 mt-4 mb-4"}>


                                            <div className={"row m-0 mt-1 mb-1"}>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-file-contract me-1"></i> SỐ HD cũ : </strong> <span>{item.number_contract_old?item.number_contract_old:"___"}</span>
                                                </div>

                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="far fa-clock me-1"></i> Thời hạn tháng : </strong> <span>{item.month_term?item.month_term:"___"}</span>
                                                </div>
                                            </div>

                                            <div className={"row m-0 bg-light  mt-1 mb-1"}>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-history me-1"></i> Thời hạn buổi : </strong> <span>{item.session_term?item.session_term:"___"}</span>
                                                </div>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-money-bill me-1"></i> Tổng tiền HD : </strong> <span>{item.price_contract?item.price_contract:"___"}</span>
                                                </div>
                                            </div>


                                            <div className={"row m-0  mt-1 mb-1"}>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="far fa-calendar-alt me-1"></i> Ngày bắt đầu kích hoạt HD : </strong> <span>{item.start_day_contract?item.start_day_contract:"___"}</span>
                                                </div>

                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="far fa-calendar me-1"></i> Ngày hết hạn : </strong> <span>{item.end_day_contract?item.end_day_contract:"___"}</span>
                                                </div>
                                            </div>

                                            <div className={"row m-0 bg-light  mt-1 mb-1"}>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="far fa-clock me-1"></i> Giá trị còn lại HĐ cũ : </strong> <span>{item.price_contract_old?item.price_contract_old:"___"}</span>
                                                </div>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-user-lock me-1"></i> Thời hạn tháng còn lại : </strong> <span>{item.month_remaining?item.month_remaining:"___"}</span>
                                                </div>
                                            </div>


                                            <div className={"row m-0  mt-1 mb-1"}>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-lock-open me-1"></i> Thời hạn buổi còn lại : </strong> <span>{item.session_remaining?item.session_remaining:"___"}</span>
                                                </div>

                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-file-signature me-1"></i> Số HD mới : </strong> <span>{item.number_contract_new?item.number_contract_new:"___"}</span>
                                                </div>
                                            </div>

                                            <div className={"row m-0 bg-light mt-1 mb-1"}>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="far fa-money-bill-alt me-1"></i> Giá trị CSR : </strong> <span>{item.price?item.price:"___"}</span>
                                                </div>

                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-file-signature me-1"></i> Số ngày bảo lưu / gia hạn : </strong> <span>{item.day_renew?item.day_renew:"___"}</span>
                                                </div>

                                            </div>
                                            <div className={"row m-0 bg-light mt-1 mb-1"}>
                                                <div className={"col-12 col-sm-6 p-2"}>
                                                    <strong><i className="fas fa-file-signature me-1"></i> Số CSR Giấy : </strong> <span>{item.csr_paper?item.csr_paper:"___"}</span>
                                                </div>
                                            </div>


                                        </div>

                                        <div className={"col-12 mt-3"}>
                                            {item.content}
                                        </div>

                                        <div className={"col-12 mt-2 text-end text-primary font12"}>
                                            {item.image!==null?(
                                                <a href={ConstApp.home_url+item.image} target={"_blank"} className={"text-primary"}> <i className="fas fa-link me-2"></i>  Xem tệp đính kèm</a>
                                            ):(
                                                <></>
                                            )}

                                            {self.props.AppThis.GetInfoProfile().type===1?(
                                                <a href={"#"} onClick={()=>{
                                                    self.AlertDelete(item._id);
                                                }} className={"text-danger font13 ms-3"}>
                                                    Xóa <i className="fas fa-trash ms-1"></i>
                                                </a>
                                            ):(
                                                <></>
                                            )}

                                        </div>

                                    </div>



                                </div>
                            </div>
                        );
                    })}

                </div>
        );
    }

    SubmitCSR(){

        var DataSubmit=this.state.DataSubmit;

        var number_error=0;

        if(DataSubmit.title===null || DataSubmit.title.length<3 || DataSubmit.content===null || DataSubmit.title.content<3){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            var AppThis=this.props.AppThis;

            AppThis.PageLoad(true);

            var data={
              id_member:this.state.InfoContract._id,
                action:"add",
                data:DataSubmit
            };

            const formData = new FormData();

            formData.append('token', AppThis.getToken());

            formData.append('id_address', AppThis.getAddress());

            formData.append('data', base64_encode(data));

            const self=this;

            fetch(AppThis.ConstApp.home_api+this.props.path+"/csr", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='error'){
                    toast("Thao tác thất bại");
                    AppThis.PageLoad(false);
                }
                else{
                    toast("Thao tác thành công");

                    self.getData(true);

                }
                self.setState({
                    isNewCSR:false
                });

            }).catch((e)=>{

                toast("Thao tác thất bại");
                AppThis.PageLoad(false);
                self.setState({
                    isNewCSR:false
                });
            });

        }




    }

    UploadFileImage(event){


        var fileUploaded = event.target.files[0];

        const self=this;

        if((fileUploaded.size/1024)<3000){

            if(fileUploaded.type==='image/png' || fileUploaded.type==='image/jpg' || fileUploaded.type==='image/jpeg'){

                this.setState({
                    TmpName:fileUploaded.name
                });

                let reader = new FileReader();

                reader.readAsDataURL(fileUploaded);

                reader.onload = (e) => {
                    self.UpdateItemSave("image",e.target.result);
                }

            }
            else{
                toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
            }


        }
        else{
            toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
        }


        //file_confirmation
    }

    UpdateItemSave(name,value){

        var DataSubmit=this.state.DataSubmit;

        DataSubmit[name]=value;

        this.setState({
            DataSubmit:DataSubmit
        });

    }

    render() {

        const self=this;

        if(this.state.data!==null){
            return(
                <>
                    <div className={"col-12"}>


                        {this.state.isNewCSR===false?(
                            <div className={"col-12 text-end mb-3"}>
                                <a href={"#"} onClick={()=>{
                                    this.setState({
                                        isNewCSR:true
                                    })
                                }} className={"btn font13 btn-success rounded-0"}><i className="fas fa-plus"></i> Thêm CSR</a>
                            </div>
                        ):(
                          <div className={"row mb-4"}>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Tiêu đề <span className={"text-red"}>( * )</span></label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("title",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Tiêu đề"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>SỐ HD cũ </label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("number_contract_old",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"SỐ HD cũ"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Thời hạn tháng </label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("month_term",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Thời hạn tháng"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Thời hạn buổi </label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("session_term",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Thời hạn buổi"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Tổng tiền HD </label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("price_contract",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Tổng tiền HD"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Ngày bắt đầu kích hoạt HD </label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("start_day_contract",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Ngày bắt đầu kích hoạt HD"} />
                              </div>


                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Ngày hết hạn </label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("end_day_contract",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Ngày hết hạn HD"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Giá trị còn lại HĐ cũ </label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("price_contract_old",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Giá trị còn lại HĐ cũ"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Thời hạn tháng còn lại</label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("month_remaining",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Thời hạn tháng còn lại"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Thời hạn buổi còn lại</label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("session_remaining",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Thời hạn buổi còn lại"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Số HĐ mới</label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("number_contract_new",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Số HĐ mới"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Phí phát sinh CSR</label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("price",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Phí phát sinh CSR"} />
                              </div>


                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>Số ngày bảo lưu/ gia hạn</label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("day_renew",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"Số ngày bảo lưu/ gia hạn"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                  <label className={"d-block mb-1"}>CSR Giấy</label>
                                  <input onChange={(event)=>{
                                      this.UpdateItemSave("csr_paper",event.target.value);
                                  }} className={"form-control bg-white rounded-0 w-100"} placeholder={"CSR giấy"} />
                              </div>

                              <div className={"col-12 col-sm-12 col-md-6"}>
                                  <label className={"d-block mb-1"}>Hình ảnh</label>

                                  <div className={"col-12 mt-2 mb-2 p-3 border_uploads_file text-red font14 text-center"}>
                                      <a onClick={()=>{
                                          this.hiddenFileInput.current.click();
                                      }} className={"font22 upload-images-avatar font16"}>
                                          <i className={"fas fa-cloud-upload-alt me-2"} />
                                          <span className={"font14 text-red"}>Click để tải lên tệp</span>
                                          <input onChange={(event)=>{
                                              this.UploadFileImage(event);
                                          }} ref={this.hiddenFileInput} accept="image/*"  type={"file"} className={"d-none"} />
                                      </a>

                                      {this.state.TmpName!==null?(
                                          <p className={"m-0 text-red p-0 font12"}>{this.state.TmpName}</p>
                                      ):(<></>)}

                                  </div>

                              </div>

                              <div className={"col-12 mt-3 mb-3"}>
                                  <label className={"d-block mb-1"}>Nội dung <span className={"text-red"}>( * )</span></label>
                                  <textarea onChange={(event)=>{
                                      this.UpdateItemSave("content",event.target.value);
                                  }} className={"min_h100px box_input form-control w-100 font13 rounded-0"} placeholder={"Nội dung"}></textarea>
                              </div>


                              <div className={"col-12 col-sm-12 col-md-6 offset-md-6 text-end"}>

                                  <div className={"row"}>

                                      <div className={"col-6"}>
                                          <a href={"#"} onClick={()=>{
                                              this.SubmitCSR();
                                          }} className={"btn btn-primary w-100 font14 rounded-0 me-2 pt-2 pb-2"}><i className="fas fa-save me-1"></i> Thêm CSR</a>

                                      </div>
                                      <div className={"col-6"}>
                                          <a href={"#"} onClick={()=>{
                                              this.setState({
                                                  isNewCSR:false
                                              })
                                          }} className={"btn btn-danger w-100 font14 rounded-0 pt-2 pb-2"}><i className="fas fa-ban me-1"></i>  Hủy</a>

                                      </div>

                                  </div>



                              </div>

                          </div>
                        )}


                        {this.state.data.data.length>0?(
                            <>
                                {this.thisRenderData()}
                            </>
                        ):(
                            <div className={"alert alert-danger"}>
                                <i className="fas fa-exclamation-triangle"></i> Không có CSR nào
                            </div>
                        )}
                    </div>


                    {this.state.data.total_page>0?(
                        <div className={"col-12 p-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>
                    ):(
                        <></>
                    )}


                </>
            );
        }
        else{
            return(
                <div className={"col-12 d-flex flex-row mt-2 mb-2 justify-content-center align-items-center"}>
                    <ItemLoading />
                </div>
            );
        }
    }
}
