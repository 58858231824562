/* eslint-disable */
import React,{Component} from "react";
import logo from "../assets/images/logo.png";
import ItemLoading from "./ItemLoading";
import { toast } from 'react-toastify';
import {
    _isset_array,
    _isset_string,
    array_to_select,
    base64_encode,
    secondsToHms,
    timeConverter
} from "../helper/array";
import parse from "html-react-parser";
import Select from "react-select";
import lodash from "lodash";
export default class Header extends Component{

    constructor(props) {
        super(props);
        this.state={
            ChangesAddress:false,
            All_Address:null,
            SelectState:null,
            ArgsModule:null,
            NameAddress:null,
            ShowAdminOnline:false,
            StatusMachine:true,
            HiddenAlert:false
        };

    }

    getAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        })
            .then(data=>data.json()).
        then(data=>{

            if(data.status==='ok'){

            }


        }).
        catch((e)=>{

        });


    }


    async setAddress(){

        if(this.state.SelectState!==null && _isset_string(this.state.SelectState)){

            this.setState({
                ChangesAddress:false
            });

            await this.props.AppThis.SaveAddress(this.state.SelectState);

            this.props.AppThis.SetUrlRedirect("/");
            //window.location.reload();
        }
        else{
            toast("Vui lòng chọn địa chỉ");
        }

    }

     LoadHeader(){

        if(this.state.All_Address===null && this.props.AppThis.GetInfoProfile()!==null){

            this.setState({
                All_Address:this.props.AppThis.GetInfoProfile().address_id,
            });

        }

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.LoadHeader();
    }


    componentDidMount() {
        this.props.AppThis.SetActionModule(this);
        this.getNameAddress();
       this.getStatusMachine();
    }

    UNSAFE_componentWillMount() {

    }

    verfyAddress(){

        this.LoadHeader();

        if(this.state.ChangesAddress===false && this.props.AppThis.state.current_address===null){

            var pathname=window.location.pathname;

            if(pathname!=='/dashboard'){
                this.props.AppThis.SetUrlRedirect("/dashboard");
            }
            else{

                this.setState({
                    ChangesAddress:true
                });

            }

        }

    }

    GetSearchModule(txt){

        if(this.props.AppThis.GetInfoProfile()!==null){
            var get_module=Object.entries(this.props.AppThis.GetInfoProfile().module);
            var get_list_module=[];
            get_module.map(function(value,index){
                value[1].map(function (item,key){
                    if(item['title'].toLowerCase().indexOf(txt.toLowerCase())!==-1){
                        if(Object.keys(get_list_module).length<5){
                            get_list_module.push(item);
                        }

                    }

                });
            });

            if(get_list_module.length>0){

                this.setState({
                    ArgsModule:get_list_module
                });
            }
        }

    }


    OpenMenuThis(){
        const AppThis=this.props.AppThis;
        AppThis.setState({
            OpenMenu:true
        });
    }

    getNameAddress(){

        const AppThis=this.props.AppThis;
        var profile=AppThis.GetInfoProfile();
        var current_address=AppThis.state.current_address;

        if(current_address!==null && profile!==null){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('_id',current_address);

            const self=this;
            fetch(Appthis.ConstApp.home_api+"address/info", {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
                .then(data=>data.json()).
            then(data=>{

                if(data.status==='ok'){

                    self.setState({
                        NameAddress:data.data['slug']
                    });

                    Appthis.setInfoAddress(lodash.cloneDeep(data.data));
                }

                if(load===true) {
                    Appthis.PageLoad(false);
                }

            }).
            catch((e)=>{


            });

        }

    }
    getStatusMachine(){

        const AppThis=this.props.AppThis;
        var profile=AppThis.GetInfoProfile();
        var current_address=AppThis.state.current_address;

        if(current_address!==null && profile!==null){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('_id',current_address);

            const self=this;
            fetch(Appthis.ConstApp.home_api+"address/machine", {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
                .then(data=>data.json()).
            then(data=>{

                if(data.status==='ok'){

                    if(data.data.status>0){

                        self.setState({
                            StatusMachine:false
                        });
                    }
                    else{
                        self.setState({
                            StatusMachine:true
                        });
                    }
                }

                if(load===true) {
                    Appthis.PageLoad(false);
                }

            }).
            catch((e)=>{


            });

        }

    }

    render() {
        this.verfyAddress();
        const AppThis=this.props.AppThis;
        const self=this;

        return(

          <>
              <div key={"header_component"} className={'col-12 pt-2 pb-2 bg-white Header-Template-Admin w-100 border-bottom position-fixed'}>
                  <div className={"container-fluid position-relative"}>
                      <div className={"row"}>
                          <div className={"col-12 col-sm-12 col-md-12 col-lg-6 Left-Header-Admin"}>
                              <div className={"row"}>
                                  <div className={"col-12 col-sm-8 col-md-8 col-lg-4 item-header-menu justify-content-center justify-content-sm-start d-flex align-items-center"}>
                                      <a href={"javascript:void(0)"} onClick={()=>{
                                          self.OpenMenuThis();
                                      }} className={"d-inline-block mr-3 Btn-Menu-Admin"}>
                                          <i className="fas fa-bars" />
                                      </a>
                                      <a  className={"Logo-Mobile-Header"} onClick={()=>{
                                          AppThis.SetUrlRedirect("/dashboard");
                                      }} href={"#"}>
                                          <img src={logo} />
                                      </a>
                                  </div>

                                  <div className={"col-12 col-sm-12 col-md-4 col-lg-8 item-header-search d-none d-xl-block"}>
                                      <div className={" position-relative quick-search-form rounded d-inline-block bg-light"}>
                                          <div className={"input-group-prepend"}>
                                              <input onChange={(event)=>{
                                                  var get_search=event.target.value;
                                                  this.GetSearchModule(get_search);
                                              }} placeholder={"Tìm kiếm"} onBlur={()=>{
                                                  this.setState({
                                                      ArgsModule:null
                                                  });
                                              }} className={"form-control shadow-none input_search_module"} />
                                          </div>

                                          {this.state.ArgsModule!==null?(
                                              <div id="box_search" className="position-absolute hover-search shadow bg-white w-100 pt-2">
                                                  {this.state.ArgsModule.map(function(index,item){
                                                      return(
                                                          <div className={"search_module_item p-2 pl-3 pr-3"}>
                                                              <a href={AppThis.ConstApp.home_url+index.path}>
                                                                  <span> {parse(index['title'])}</span>
                                                              </a>
                                                          </div>
                                                      );
                                                  })}

                                              </div>
                                          ):(<></>)}

                                      </div>
                                  </div>

                              </div>
                          </div>

                          <div className={"col-12 col-sm-12  position-relative col-md-12 col-lg-6 d-sm-flex justify-content-end align-items-center text-end right-header-admin"}>

                              <div className={"box-Header position-relative"}>
                                  {AppThis.state.token!==null?(
                                      <>

                                          {this.state.NameAddress!==null?(
                                              <a href="javascript:void(0)" onClick={()=>{
                                                  this.setState({
                                                      ChangesAddress:true
                                                  });
                                              }} className="d-inline-block me-3">
                                       <span className="d-inline-block me-3 text-uppercase font-weight-bold badge bg-primary font12">
                                           {this.state.NameAddress}
                                       </span>
                                              </a>
                                          ):(
                                              <div className={"me-3"} />
                                          )}

                                          <a href="javascript:void(0)" onClick={()=>{
                                              AppThis.SetUrlRedirect("/profile");
                                          }} className="d-inline-block me-3"><i className={"fas fa-user-edit"} /> Tài khoản </a>


                                          <a href="javascript:void(0)" onClick={()=>{
                                              AppThis.SetUrlRedirect("/login");
                                          }} className="d-inline-block"><i className={"fas fa-sign-out-alt"} /> Đăng xuất </a>

                                      </>
                                  ):(<ItemLoading />)}





                              </div>

                          </div>

                      </div>
                  </div>


                  {this.state.ChangesAddress===true?(
                      <div className="modal d-block position-fixed">
                          <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                  <div className="modal-header">
                                      <strong className={"font18 text-uppercase"}>Địa chỉ quản lý</strong>
                                  </div>
                                  <div className="modal-body">

                                      <div className={"alert alert-danger"}>Vui lòng chọn địa chỉ quản lý</div>
                                      <div className={"w-100 mb-2 mt-2 justify-content-center"}>

                                          {this.state.All_Address!==null?(
                                              <Select isSearchable={true} onChange={(selectedOption)=>{
                                                  this.setState({
                                                      SelectState:selectedOption['value']
                                                  });
                                              }}  options={array_to_select(this.state.All_Address,'name','_id')} />

                                          ):( <div className={"w-100 mb-2 mt-2 d-flex justify-content-center"}><ItemLoading /></div>)}

                                      </div>

                                      <a onClick={()=>{
                                          this.setAddress();
                                      }} className={"btn btn-primary text-center d-block w-100 text-white mt-3"} href={"javascript:void(0)"}>
                                          Chọn Địa chỉ
                                      </a>
                                  </div>

                              </div>
                          </div>
                          <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                      </div>
                  ):(<></>)}


              </div>



              {this.state.StatusMachine===false && this.state.HiddenAlert===false?(

                  <div className={"col-12 position-fixed zindex9999 w-100 h-100 overflow-hidden position-relative d-flex flex-row flex-wrap justify-content-center align-items-center top-0 start-0"}>

                      <div className={"col-12 col-sm-12 col-md-10 col-lg-8 position-relative zindex10 text-center m-auto d-flex flex-row justify-content-center"}>
                              <div className="alert alert-danger m-0 shadow rounded-2 text-start font14 width320 p-5">
                                  <i className="fas fa-exclamation-triangle me-1" />
                                  Máy chấm công tại cơ sở này không thể kết nối.Vui lòng kiểm tra lại

                                  <a href={"#"} onClick={()=>{
                                      this.setState({
                                          HiddenAlert:true
                                      });

                                  }} className={"position-absolute font22 top0 end-0 font-weight-bold mt-2 me-2"}>
                                      <i className="far fa-times-circle"></i>
                                  </a>

                              </div>
                      </div>


                      <div className={"col-12 position-absolute w-100 h-100 bg-modal"}></div>

                  </div>

              ):(
                  <></>
              )}


          </>
        );


    }
}
