/* eslint-disable */
import React,{Component} from "react";
import no_avatar from "../../assets/images/_no_avatar.png";
import ItemLoading from "../ItemLoading";
import {base64_encode, object_to_select, timeConverterDate} from "../../helper/array";
import {toast} from "react-toastify";
import Select from "react-select";
import {confirmAlert} from "react-confirm-alert";
import Webcam from "react-webcam";

export default class FingerPrintUser extends Component {

    constructor(props) {
        super(props);

        this.state={
            data:null,
            machine:null,
            select_machine:null,
            InfoUser:null,
            EnableWebcam:false
        };

        this.hiddenFileInput=React.createRef();

        this.webcamRef=React.createRef();
    }

    componentDidMount() {

        if(this.props.data!==null){
            var user_login=this.props.data.user_login;

            const self=this;
            this.setState({
                InfoUser:this.props.data
            },function(){
                self.GetFingerprint(user_login);
            });

        }

        this.GetMachine();

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    GetFingerprint(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('user_login', id);

        const self=this;
        fetch(AppThis.ConstApp.home_api+this.props.path+"/fingerprint", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    data:data.data
                });
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");

        });

    }

    AlertAdd(id){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn lấy vân tay mới không ? </p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.AddFingerprint(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    AlertDelete(id){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn xoá vân tay ? </p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.DeleteFingerprint(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    AddFingerprint(id){

        if(this.state.select_machine===null){
            return;
        }

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_user', id);

        formData.append('id_machine', this.state.select_machine);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/addfingerprint", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thêm vân tay thất bại");
            }
            else{
                toast("Thêm vân tay thành công");

                self.setState({
                    data:null,
                },function(){
                    var get_id=self.props.data.user_login;
                    self.GetFingerprint(get_id);
                });
            }
        }).
        catch((e)=>{
            toast("Thêm vân tay thất bại");
        });



    }

    DeleteFingerprint(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_fingerprint', id);

        const self=this;
        fetch(AppThis.ConstApp.home_api+this.props.path+"/deletefingerprint", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Xoá dữ liệu thất bại");
            }
            else{
                toast("Xoá dữ liệu thành công");

                self.setState({
                    data:null,
                },function(){

                    var get_id=self.state.InfoUser.user_login;

                    self.GetFingerprint(get_id);

                });



            }

        }).
        catch((e)=>{

            toast("Xoá dữ liệu thất bại");

        });



    }

    AlertCopy(id){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn sao chép vân tay ? </p>

                        {self.state.machine!==null?(
                            <select onChange={(event)=>{
                                self.setState({
                                    select_machine:event.target.value
                                });
                            }} className={"form-control w-100 font13 border-0 rounded-0 mb-2"}>
                                {object_to_select(self.state.machine,'name').map(function(item,index){
                                    return(
                                        <option value={item.value}>{item.label}</option>
                                    );
                                })}
                            </select>

                        ):(<></>)}


                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.CopyFingerprint(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    CopyFingerprint(id){

        if(this.state.select_machine===null){
            return;
        }

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_fingerprint', id);

        formData.append('id_machine', this.state.select_machine);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/copyfingerprint", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("sao chép dữ liệu thất bại");
            }
            else{
                toast("sao chép dữ liệu thành công");

                self.setState({
                    data:null,
                },function(){

                    var get_id=self.state.InfoUser._id;

                    self.GetFingerprint(get_id);

                });



            }

        }).
        catch((e)=>{

            toast("sao chép dữ liệu thất bại");

        });



    }

    GetMachine(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        const self=this;
        fetch(AppThis.ConstApp.home_api+this.props.path+"/machine", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    machine:data.data
                });
            }

        }).
        catch((e)=>{

            toast("Lấy dữ liệu thất bại");

        });

    }

    RenderGetFingerprint(){
        const self=this;
        return(
            <div className={"col-12 RenderGetFingerprint"}>

                {this.state.machine!==null?(
                    <div className={"col-12 d-flex flex-row flex-wrap justify-content-end mb-3"}>

                        <div className={"customCssSelect w200px me-1"}>
                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                var select_machine=selectedOption['value'];
                                self.setState({
                                    select_machine:select_machine
                                });
                            }} options={object_to_select(this.state.machine,'name','_id')} />
                        </div>

                        <a onClick={()=>{
                            if(self.state.InfoUser!==null){
                                self.AlertAdd(self.state.InfoUser._id);
                            }
                        }} className={"btn btn-outline-danger rounded-0 font13"}><i className="fas fa-fingerprint me-1" /> Lấy vân tay</a>
                    </div>

                ):( <div className={"col-12 text-center d-flex justify-content-center align-items-center mb-3"}><ItemLoading /></div>)}

                {this.state.data.length>0?(
                    <div className={"col-12 overflow-auto"}>

                        <table className={"mt-3 w-100 table border-0 mw1000"}>
                            <thead>
                            <tr className={"border-bottom bg-light"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Tên máy
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Thời gian
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    ID vân tay
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Địa chỉ
                                </td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Trạng thái
                                </td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    Thao tác
                                </td>

                            </tr>
                            </thead>

                            <tbody>

                            {this.state.data.map(function(item,index){
                                return(
                                    <tr>

                                        <td>
                                            {item.id_machine?item.id_machine.name:"chưa rõ"}
                                        </td>

                                        <td>
                                            {timeConverterDate(item.time)}
                                        </td>

                                        <td>
                                            {item.number_index}
                                        </td>

                                        <td>
                                            {item.id_address.name}
                                        </td>

                                        <td>
                                            {item.encode_finger===true?(
                                                <i className="font15 text-primary far fa-check-circle" />
                                            ):(
                                                <i className="font15 text-danger fas fa-exclamation-circle" />
                                            )}
                                        </td>

                                        <td>
                                            {item.encode_finger===true?(
                                                <a onClick={()=>{
                                                    self.AlertCopy(item._id);
                                                }}>
                                                    <i className="font18 text-primary far fa-copy" />
                                                </a>
                                            ):(
                                                <></>
                                            )}

                                            <a onClick={()=>{
                                                self.AlertDelete(item._id);
                                            }}>
                                                <i className="ms-3 far text-danger fa-trash-alt font18" />
                                            </a>
                                        </td>

                                    </tr>
                                );
                            })}

                            </tbody>

                        </table>


                    </div>
                ):(
                    <div className={"alert alert-danger rounded-0"}>
                        Không có dữ liệu
                    </div>
                )}

            </div>
        );
    }

    UpdateInfoUser(id){

        const self=this;

        const formData = new FormData();

        var AppThis = this.props.AppThis;

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_user', id);

        fetch(AppThis.ConstApp.home_api + this.props.path + "/infouser", {
            method: 'POST',
            body: formData
        }).then(data => data.json()).then(data => {

            if(data['status']==='ok'){
                self.setState({
                    InfoUser:data.data
                });
            }
            AppThis.PageLoad(false);

        }).catch((e) => {
            AppThis.PageLoad(false);
        });

    }

    EventUploadAvatarNow(event){

        const self=this;

        var fileUploaded = event.target.files[0];

        if((fileUploaded.size/1024)<3000){

            if(fileUploaded.type==='image/png' || fileUploaded.type==='image/jpg' || fileUploaded.type==='image/jpeg'){

                let reader = new FileReader();

                reader.readAsDataURL(fileUploaded);

                reader.onload = (e) => {
                    self.UploadAvatarNow(e.target.result);
                }

            }
            else{
                toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
            }



        }
        else{
            toast("Kích thước tệp tin nhỏ hơn 3M và ảnh PNG, JPG , JPEG");
        }

    }

    UploadAvatarNow(string=null){

        if(this.state.InfoUser===null){
            return;
        }

        var DataUser={};

        var AppThis=this.props.AppThis;

        const self=this;

        AppThis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        DataUser['avatar']= string;

        DataUser['_id']=self.state.InfoUser._id;

        formData.append('data', base64_encode(DataUser));

        fetch(AppThis.ConstApp.home_api+this.props.path+"/avatar", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
                AppThis.PageLoad(false);
            }
            else{
                self.UpdateInfoUser(data.data);
            }
        }).
        catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);
        });


    }


    AlertUploadAvatar(){

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Thông báo !</h1>
                        <p>Tải lên hình ảnh cho khách hàng ?</p>
                        <button onClick={()=>{
                            self.hiddenFileInput.current.click();
                            onClose();
                        }}>
                            Từ máy tính
                        </button>
                        <button
                            onClick={() => {

                                self.setState({
                                    EnableWebcam:true
                                });

                                onClose();
                            }}
                        > Sử dụng Camera
                        </button>
                    </div>
                );
            }
        });

    }

    CaptureCamera(){

        var image=this.webcamRef.current.getScreenshot();

        if(image!==null){
            this.UploadAvatarNow(image);
        }

        this.setState({
            EnableWebcam:false
        });

    }
    render(){

        const self=this;

        if(self.state.InfoUser!==null){

                return(
                    <>
                        <div className="modal d-block position-fixed modal-dialog-end">
                            <div className={"modal-dialog modal-dialog-scrollable modal-lg container-fluid"}>
                                <div className="modal-content shadow border-0 rounded-0">
                                    <div className="modal-header bg-light rounded-0">
                                        <strong className={"font16 text-uppercase text-black"}>
                                            <i className="far fa-user me-2" />
                                            Chi tiết vân tay khách hàng
                                        </strong>
                                    </div>
                                    <div className="modal-body">

                                        <div className={"row mb-2 mt-2"}>

                                            <div className={"col-12 col-sm-12 col-md-12 col-lg-3 d-none d-lg-block left-info-user"}>

                                                <div className={"col-12 mb-4 text-center"}>

                                                    <div className={"position-relative d-inline-block"}>



                                                        {self.state.InfoUser.avatar!==null ?(
                                                            <div className={"box-avatar-150 m-auto"}>
                                                                <img src={this.props.AppThis.ConstApp.home_url +"/" +self.state.InfoUser.avatar} />
                                                            </div>

                                                        ):(
                                                            <div className={"box-avatar-150 m-auto"}>
                                                                <img  src={no_avatar} />
                                                            </div>
                                                        )}

                                                        {typeof this.props.DisableUpload!=='undefined'?(
                                                            <></>
                                                        ):(
                                                            <div className={"position-absolute top-0 start-0"}>

                                                                <a onClick={()=>{

                                                                    this.AlertUploadAvatar();

                                                                }} className={"font26 upload-images-avatar text-dark"}>
                                                                    <i className="fas fa-camera-retro" />
                                                                    <input onChange={(event)=>{
                                                                        this.EventUploadAvatarNow(event);
                                                                    }} ref={this.hiddenFileInput} accept="image/*"  type={"file"} className={"d-none"} />
                                                                </a>


                                                            </div>
                                                        )}


                                                    </div>

                                                </div>

                                                <div className={"row mb-2 bg-light p-2"}>
                                                    <div className={"col-6 col-sm-6 col-md-5"}>
                                                        <i className="far fa-user" /> Họ và tên
                                                    </div>
                                                    <div className={"col-6 col-sm-6 col-md-7"}>
                                                        <span className={"font-weight-bold"}>{self.state.InfoUser.username}</span>
                                                    </div>
                                                </div>

                                                <div className={"row mb-2 p-2"}>
                                                    <div className={"col-6 col-sm-6 col-md-5"}>
                                                        <i className="far fa-envelope" /> Email
                                                    </div>
                                                    <div className={"col-6 col-sm-6 col-md-7"}>
                                                        <span className={"text-line"}>{self.state.InfoUser.email}</span>
                                                    </div>
                                                </div>


                                                <div className={"row mb-2 bg-light p-2"}>
                                                    <div className={"col-6 col-sm-6 col-md-5"}>
                                                        Mã khách hàng
                                                    </div>
                                                    <div className={"col-6 col-sm-6 col-md-7"}>
                                                        <span className={"font-weight-bold"}>{self.state.InfoUser.code_user}</span>

                                                    </div>
                                                </div>

                                                <div className={"row mb-2 p-2"}>
                                                    <div className={"col-6 col-sm-6 col-md-5"}>
                                                        <i className="fas fa-phone-alt" /> SDT/ID
                                                    </div>
                                                    <div className={"col-6 col-sm-6 col-md-7"}>
                                                        <span>{self.state.InfoUser.user_login}</span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col-12 col-sm-12 col-md-12 col-lg-9 right-info-user"}>

                                                {this.state.data!==null?(
                                                    this.RenderGetFingerprint()
                                                ):(
                                                    <div className={"col-12 text-center d-flex align-items-center justify-content-center align-self-center h-100"}>
                                                        <ItemLoading size={"Large"} />
                                                    </div>
                                                )}

                                            </div>



                                        </div>

                                    </div>


                                    <div className={"modal-footer bg-light"}>

                                        <div className={"col-12 col-sm-12 col-lg-12"}>

                                            <div className={"col-12 col-sm-12 col-lg-2 offset-lg-10"}>
                                                <a onClick={()=>{
                                                    this.props.onClose();
                                                }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                    Đóng
                                                </a>
                                            </div>

                                        </div>


                                    </div>



                                </div>
                            </div>
                            <div onClick={()=>{
                                this.props.onClose();
                            }} className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                        </div>

                        {this.state.EnableWebcam===true?(

                            <div className="modal position-fixed d-flex w-100 h-100 flex-row align-items-end justify-content-end">

                                <div className={"modal-dialog m-0"}>

                                    <div className="modal-content shadow bg-white border-0 rounded-0">
                                        <div className="modal-header bg-header-user text-white rounded-0">
                                            <strong className={"font16 text-uppercase"}>
                                                <i className="fas fa-camera me-2" />
                                                CAMERA CHỤP ẢNH
                                            </strong>
                                        </div>

                                        <div className={"modal-body"}>

                                            <div className={"box-WebCam"}>
                                                <Webcam
                                                    audio={false}
                                                    height={320}
                                                    ref={this.webcamRef}
                                                    screenshotFormat="image/png"
                                                    width={320}
                                                    videoConstraints={{
                                                        width: 320,
                                                        height: 320,
                                                        facingMode: "environment"
                                                    }}
                                                />
                                            </div>

                                            <div className={"row mt-3"}>

                                                <div className={"col-6"}>
                                                    <a href={"#"} onClick={()=>{
                                                        this.setState({
                                                            EnableWebcam:false
                                                        });
                                                    }} className={"btn btn-dark rounded-0 w-100"}>
                                                        <i className="fas fa-window-close me-1" />
                                                        Hủy</a>
                                                </div>
                                                <div className={"col-6"}>
                                                    <a href={"#"} onClick={()=>{
                                                        this.CaptureCamera();
                                                    }} className={"btn btn-primary rounded-0 w-100"}>
                                                        <i className="fas fa-camera me-1" />
                                                        Chụp ảnh</a>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div onClick={()=>{

                                    this.setState({
                                        EnableWebcam:false
                                    });
                                }} className={"bg-modal position-absolute w-100 vh-100 top0 left0"} />

                            </div>

                        ):(
                            <></>
                        )}


                    </>

                );

        }
        else{
            return(<></>);
        }

    }
}
