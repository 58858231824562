/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import {toast} from "react-toastify";
import {
    base64_encode, formatNumber,
    timeConverter,
} from "../../helper/array";
import Floater from "react-floater";
import DetailCheckin from "../checkin/DetailCheckin";
export default class SessionHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
            DetailMember:null,
            query:{
                input_search:null
            },
            page:0,
            DetailCheckin:null,
            Statics:null
        };
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }
        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('id_contract', this.state.DetailMember._id);

        var get_query=this.state.query;

        var SearchAdvanced={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(typeof value!=='undefined' && value!==null){
                SearchAdvanced[key]=value;
            }
        });

        if(Object.keys(SearchAdvanced).length>0){
            formData.append('query', base64_encode(SearchAdvanced));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/history", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    getStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('_id', this.state.DetailMember._id);

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/staticscheckin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Statics:data.data
                });

            }

        }).catch((e)=>{


        });


    }

    componentDidMount() {

        if(this.props.data){

            const self=this;

            var get_id=this.props.data;

            this.setState({
                DetailMember:get_id
            },function(){
                self.getData();
                self.getStatics();
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    LoopData(){

        var data=this.state.data.data;

        const self=this;

        var DetailMember=this.state.DetailMember;

        return(
            <div className={"col-12"}>


                <div className={"row mt-4"}>
                    <div className={"col-12 col-sm-12 col-md-6"}>
                        <p className={"font16 text-uppercase font-weight-bold"}>
                            <i className="fas fa-history me-2" />
                            Lịch sử buổi tập HD : {DetailMember.number_contract}</p>
                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 text-end"}>
                        Tổng bản ghi : {this.state.data.total_row}
                    </div>
                </div>

                {this.state.Statics!==null?(
                    <div className={"row mt-4 mb-3"}>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                            <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                                <div
                                    className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                    <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-primary"></i>
                                </div>
                                <div className="right-item">
                                    <p className="text-uppercase mt-2 mb-0 text-dark font14">Số buổi tập PT</p>
                                    <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.Statics.personal_trainer}</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                            <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                                <div
                                    className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                    <i className="d-flex justify-content-center text-white align-items-center fas fa-font rounded-circle bg-warning"></i>
                                </div>
                                <div className="right-item">
                                    <p className="text-uppercase mt-2 mb-0 text-dark font14">Số buổi tập Member</p>
                                    <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.Statics.personal}</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                            <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                                <div
                                    className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                    <i className="d-flex justify-content-center text-white align-items-center fas fa-layer-group rounded-circle bg-danger"></i>
                                </div>
                                <div className="right-item">
                                    <p className="text-uppercase mt-2 mb-0 text-dark font14">Số buổi tập lớp nhóm</p>
                                    <p className="number-info-dashboard m-0 font16 font-weight-bold">{this.state.Statics.statics_group}</p>
                                </div>
                            </div>
                        </div>


                    </div>

                ):(
                    <></>
                )}



                <div className={"col-12 box-table-fix box-table-w150"}>
                    <div className={"overflow-auto col-12"}>
                        <table className={"mt-3 w-100 table border-0 mw1600 table-style-old"}>
                            <thead>
                            <tr className={"border-bottom bg-light"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-clock me-2" />  Thời gian vào</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-clock me-2" />  Thời gian ra</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-user me-2" /> PT dạy</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-headset me-2" /> PT trợ giảng</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-layer-group me-2" /> Lớp nhóm</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-dumbbell me-2" /> Bộ môn</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-user-cog me-2" /> NV lễ tân</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2 mw150px fix-item-table"}>
                                    <i className="fas fa-directions me-2" />
                                    THAO TÁC</td>
                            </tr>
                            </thead>


                            <tbody>

                            {data.map(function(item){
                                return(
                                    <tr>
                                        <td>{timeConverter(item.time)}</td>

                                        <td>{typeof item.time_update!=='undefined'?timeConverter(item.time_update):(
                                            <span>___</span>
                                        )}</td>

                                        <td>{item.admin_pt_session!==null?(
                                            <span>{item.admin_pt_session.username+" - "+item.admin_pt_session.code_user}</span>
                                        ):(
                                            <span>___</span>
                                        )}</td>

                                        <td>{item.sub_admin_pt.length>0?(
                                           <>
                                               {item.sub_admin_pt.map(function(item){
                                                   return(
                                                       <span className={"badge bg-primary font10 rounded-0 m-1"}>{item.code_user}</span>
                                                   );
                                               })}
                                           </>
                                        ):(
                                            <span>___</span>
                                        )}</td>

                                        <td>
                                            {item.statics_member_group!==null?(
                                                <span>{item.statics_member_group.name+" - "+item.statics_member_group.code_group}</span>
                                            ):(
                                                <span>___</span>
                                            )}
                                        </td>

                                        <td>
                                            {item.current_subject.length>0?(
                                                <>
                                                    {item.current_subject.map(function(item){
                                                        return(
                                                            <span>{item.name} , </span>
                                                        );
                                                    })}
                                                </>
                                            ):(
                                                <span>___</span>
                                            )}
                                        </td>

                                        <td>
                                            {item.id_admin.username+" - "+item.id_admin.code_user}
                                        </td>

                                        <td className={"fix-item-table mw150px"}>

                                            <a onClick={()=>{
                                                self.setState({
                                                    DetailCheckin:item
                                                });

                                            }} className={"rounded-0 font12 d-inline-block btn btn-outline-primary p-2 me-1"}>
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết buổi tập">
                                                    <i className="fas fa-info-circle" />
                                                </Floater>
                                            </a>

                                        </td>
                                    </tr>
                                );
                            })}

                            </tbody>



                        </table>
                    </div>
                </div>

                <div className={"col-12 mt-4"}>
                    <ul className="pagination">
                        {this.state.data.prev>=0?(
                            <li className="page-item" >
                                <a className="page-link" onClick={()=>{
                                    var page=this.state.page-1;
                                    this.setState({
                                        page:page
                                    },function(){
                                        self.getData(true);
                                    });
                                }}>
                                    Trước
                                </a>
                            </li>
                        ):(<></>)}

                        <li className="page-item">
                            <a className="page-link">
                                <span>{this.state.data.curent+1}</span>
                            </a></li>

                        {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                            <li className="page-item">
                                <a className="page-link" onClick={()=>{
                                    this.setState({
                                        page:this.state.data.next
                                    },function(){
                                        self.getData(true);
                                    });
                                }}>
                                    Kế tiếp
                                </a>
                            </li>
                        ):(<></>)}

                    </ul>
                </div>
            </div>
        );

    }

    render(){

        const self=this;

        var data=this.state.data;
        if(this.state.DetailMember!==null){
            return (
                <>
                    <div className="modal d-block position-fixed modal-dialog-end">
                        <div className={"modal-dialog modal-lg modal-lg modal-dialog-scrollable container-fluid"}>
                            <div className="modal-content shadow border-0">
                                <div className="modal-header bg-light-bold">

                                    <div className={"container"}>

                                        <div className={"row"}>
                                            <div className={"col-8"}>
                                                <strong className={"font18 text-black text-uppercase"}>Lịch sử buổi tập
                                                </strong>
                                            </div>

                                            <div className={"col-4 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.onClose();
                                    }} className={"text-black right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                            </div>

                                        </div>
                                    </div>


                                </div>


                                <div className="modal-body">


                                    <div className={"container p-0"}>

                                        {this.state.data!==null?(
                                            <div className={"row"}>

                                                <div className={"col-12 col-sm-12 col-md-12"}>

                                                    {data.data.length>0?(
                                                        self.LoopData()
                                                    ):(
                                                        <div className={"alert alert-danger rounded-0 mt-3"}>
                                                            Không có dữ liệu
                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                        ):(
                                            <div className={"col-12 mt-5 pt-5 d-flex justify-content-center"}>
                                                <ItemLoading size={"Large"} />
                                            </div>
                                        )}


                                    </div>


                                </div>


                            </div>
                        </div>
                        <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                    </div>

                    {this.state.DetailCheckin!==null?(<>
                        <DetailCheckin eventClose={()=>{
                            this.setState({
                                DetailCheckin:null
                            });
                        }} data={this.state.DetailCheckin} />
                    </>):(
                        <></>
                    )}

                </>

            );
        }
        else{
            return(
                <></>
            );
        }

    }
}
