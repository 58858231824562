/* eslint-disable */
import React,{Component} from "react";
import {_isset} from "../../helper/array";
import BoxContract from "./BoxContract";

export default class DetailContract extends Component{

    constructor(props) {
        super(props);

    }
    componentDidMount() {
        document.addEventListener("keydown", this.KeyEventFunction);
    }
    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.OnClose!=='undefined'){
                this.props.OnClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    render() {

        const self=this;

        if(_isset(this.props.data)){
            var data=this.props.data;

            return(
                <div className="modal d-block position-fixed">
                    <div className={"modal-dialog modal-xl"}>
                        <div className="modal-content shadow border-0">
                            <div className="modal-header m-0 bg-light row">

                                <div className={"col-8 p-0"}>
                                    <strong className={"font18 text-black text-black text-uppercase"}>CHI TIẾT HỢP ĐỒNG</strong>
                                </div>

                                <div className={"col-4 p-0 d-flex justify-content-end align-items-center"}>
                                    <span onClick={()=>{
                                        this.props.OnClose();
                                    }} className={"text-black right-0 top-0"}><i className="font26 far fa-window-close" /></span>
                                </div>


                            </div>
                            <div className="modal-body">

                                {data.is_pause!==0?(
                                    <div className={"alert alert-danger text-uppercase font14"}>

                                        <p className={"mb-0"}><strong><i className="fas fa-power-off me-2" /> HD đang bị tạm ngưng.
                                        HD không thể checkin
                                        </strong></p>

                                        {data.description!==null && typeof data.description!=='undefined' && data.description.length>0?(
                                            <p className={" text-uppercase font14"}>
                                                {data.description}
                                            </p>
                                        ):(<></>)}

                                    </div>
                                ):(<></>)}


                                <BoxContract data={data} EventDetail={(id)=>{

                                    if(typeof this.props.EventDetail!=='undefined'){

                                        this.props.EventDetail(id);
                                    }
                                }} />

                            </div>


                        </div>
                    </div>
                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );
        }
        else{
            return(
                <></>
            );
        }


    }

}
