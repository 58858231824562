/* eslint-disable */
import React,{Component} from "react";
import {formatNumber, timeConverter, timeConverterDate} from "../../helper/array";
import ItemLoading from "../ItemLoading";
export default class InfoDiscount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
        };

    }

    componentDidMount() {

        if(typeof this.props.data!=='undefined'){

            this.setState({
                data:this.props.data
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    render(){

            var data=this.state.data;
            return(
                <div className="modal d-block position-fixed">
                    <div className={"modal-dialog modal-lg"}>
                        <div className="modal-content shadow border-0">
                            <div className="modal-header bg-info-discount">
                                <strong className={"font18 text-uppercase"}>Chi tiết mã khuyến mãi</strong>
                            </div>

                            {data!==null?(
                                <>
                                    <div className="modal-body">

                                        <div className={"row"}>
                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1 font-weight-bold">
                                                    <i className="fas fa-info-circle me-1" />
                                                    Mã KM :
                                                </label>
                                                <span className={"m-0 font12 ms-3 font-weight-bold badge bg-primary rounded-0"}>{data.code}</span>
                                            </div>

                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="fab fa-playstation me-1" />  Tên Mã KM :
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>{data.name}</span>
                                            </div>


                                            <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                <label className="font-weight-bold mb-1">
                                                    <i className="far fa-clock me-1" />
                                                    Thời gian bắt đầu :
                                                </label>
                                                <span className={"m-0 p-0 ms-3"}>{timeConverterDate(data.start_time)}</span>
                                            </div>




                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="far fa-clock me-1" />
                                                        Thời gian kết thúc :
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>{timeConverterDate(data.end_time)}</span>
                                                </div>


                                                    <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                        <label className="font-weight-bold mb-1">
                                                            <i className="fas fa-id-card me-1" />
                                                            Thẻ tập áp dụng KM:
                                                        </label>
                                                        <span className={"m-0 p-0 ms-3"}>
                                                        {typeof data.id_card!=='undefined' && data.id_card!==null?(
                                                            <span className={"font-weight-bold badge font12 bg-primary"}>
                                                                   {data.id_card.name}
                                                            </span>
                                                        ):(
                                                            <span className={"badge font12 bg-danger"}>Tất cả</span>
                                                        )}
                                                        </span>
                                                    </div>




                                            {typeof data.number_discount!=='undefined'?(
                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="far fa-lemon me-1" />
                                                        Giới hạn :
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>{data.number_discount===0?"Không giới hạn":data.number_discount}</span>
                                                </div>
                                            ):(
                                                <></>
                                            )}


                                            {typeof data.price_sale!=='undefined'?(
                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="fas fa-money-bill me-1" />
                                                        Số tiền KM :
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>{formatNumber(data.price_sale)} VNĐ</span>
                                                </div>
                                            ):(
                                                <></>
                                            )}


                                            {typeof data.percent_sale!=='undefined'?(
                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="fas fa-percentage me-1" />
                                                        Chiết khấu % :
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>{data.percent_sale}</span>
                                                </div>
                                            ):(
                                                <></>
                                            )}


                                            <div className={"col-12 pt-3 pb-3"}>
                                                <div className={"alert alert-danger"}>
                                                    <i className="fas fa-exclamation-triangle me-1"></i>
                                                    <strong>Buổi tập tặng kèm sẽ được cộng vào HD</strong>
                                                </div>
                                            </div>

                                            {typeof data.number_card_sale!=='undefined'?(
                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="fas fa-plus-circle me-1" />
                                                        Số buổi tập (member) :
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>{data.number_card_sale}</span>
                                                </div>
                                            ):(
                                                <></>
                                            )}

                                            {typeof data.number_pt_sale!=='undefined'?(
                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="fas fa-plus me-1" />
                                                        Số buổi tập PT :
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>{data.number_pt_sale}</span>
                                                </div>
                                            ):(
                                                <></>
                                            )}



                                            {typeof data.number_month_sale!=='undefined'?(
                                                <div className={"col-12 col-sm-12 col-md-6 border-bottom mb-2 pb-2"}>
                                                    <label className="font-weight-bold mb-1">
                                                        <i className="far fa-calendar-alt me-1" />
                                                        Số tháng KM (Linh hoạt ,Cố định) :
                                                    </label>
                                                    <span className={"m-0 p-0 ms-3"}>{data.number_month_sale}</span>
                                                </div>
                                            ):(
                                                <></>
                                            )}


                                        </div>

                                    </div>

                                    <div className={"modal-footer bg-light"}>

                                        <div className={"w-100 row"}>

                                            <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                                <div className={"row"}>

                                                    <div className={"col-12 col-sm-12 col-lg-6"}>
                                                        <a onClick={()=>{

                                                            if(typeof this.props.SubmitModal!=='undefined'){
                                                                this.props.SubmitModal(data)
                                                            }
                                                        }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                            Cập nhật
                                                        </a>
                                                    </div>
                                                    <div className={"col-12 col-sm-12 col-lg-6"}>
                                                        <a onClick={()=>{
                                                            if(typeof this.props.onClose!=='undefined'){
                                                                this.props.onClose()
                                                            };

                                                        }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                            Huỷ
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </>
                            ):(
                                <div className={"col-12 d-flex flex-row justify-content-center align-items-center mt-3 mb-3"}>
                                    <ItemLoading />
                                </div>
                            )}




                        </div>
                    </div>
                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );

    }


}
