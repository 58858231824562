/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import lodash from "lodash";
import {
    array_to_select,
    get_data_in_args,
    _isset_object,
} from "../../helper/array";
import Select from 'react-select';
import {toast} from "react-toastify";

export default class EditCard extends Component{

    constructor(props) {
        super(props);

        this.state= {
            DataModal:null,
            Service:null,
            Product:null,
            data:null
        };

    }

    componentDidMount() {

        this.getService();

        this.getProduct();

        if (typeof this.props.data!=='undefined'){

         var data=this.props.data;

        var DataModal=lodash.cloneDeep(data);

        if(_isset_object(DataModal.id_service)){
            DataModal['id_service']=get_data_in_args(DataModal.id_service,'_id');
        }
        else{
            DataModal['id_service']=[];
        }


        if(_isset_object(DataModal.id_product)){
            DataModal['id_product']=get_data_in_args(DataModal.id_product,'_id');
        }
        else{
            DataModal['id_product']=[];
        }

        this.setState({
            DataModal:DataModal,
            data:data
       });

        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getService(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address',AppThis.getAddress());

        fetch(AppThis.ConstApp.home_api+"service", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{
            this.setState({
                Service:data.data
            });

        });

    }

    getProduct(){

        const formData = new FormData();

        var AppThis=this.props.AppThis;

        formData.append('token',AppThis.getToken());

        formData.append('id_address',AppThis.getAddress());

        fetch(AppThis.ConstApp.home_api+"product", {
            method: 'POST',
            body: formData
        }).then(data=>data.json()).then(data=>{
            this.setState({
                Product:data.data
            });

        });

    }

    UpdateItemData(name,value){

        var DataModal=this.state.DataModal;

        DataModal[name]=value;


        this.setState({
            DataModal:DataModal
        });

    }

    renderData(){

        const self=this;

        var DataModal=this.state.DataModal;

        var data=this.state.data;

            return(
                <div className={"row m-0"}>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                        <label className="font-weight-400 mb-1">Tên thẻ <span className="text-red">( * )</span></label>
                        <input type="text" onChange={(event)=>{
                            this.UpdateItemData('name',event.target.value);
                        }} defaultValue={DataModal.name} placeholder="Tên thẻ" required="required" className="form-control w-100" />
                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Loại thẻ tập <span className="text-red">( * )</span></label>

                        <select onChange={(event)=>{
                            this.UpdateItemData('type',parseInt(event.target.value));

                        }} defaultValue={DataModal.type} className={"form-control w-100 font13"}>
                            <option>Chọn thẻ tập</option>
                            <option value={1}>Thẻ PT</option>
                            <option value={2}>Thẻ Member</option>
                            <option value={3}>Thẻ nhóm LH</option>
                            <option value={4}>Thẻ nhóm CD</option>
                            <option value={5}>Thẻ thuê dịch vụ</option>
                        </select>


                    </div>
                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Giá niêm yết <span className="text-red">( * )</span></label>

                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('price',parseInt(event.target.value));
                        }} defaultValue={DataModal.price} placeholder="Giá niêm yết" required="required" className="form-control w-100" />

                    </div>

                    <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                        <label className="font-weight-400 mb-1">Giá min <span className="text-red">( * )</span></label>

                        <input type="number" onChange={(event)=>{
                            this.UpdateItemData('min_price',parseInt(event.target.value));
                        }} defaultValue={DataModal.min_price} placeholder="Giá min" required="required" className="form-control w-100" />

                    </div>

                    {typeof DataModal.type!=='undefined' && DataModal.type!==null && DataModal.type!==5?(
                        <>
                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                                <label className="font-weight-400 mb-1">Dịch vụ</label>


                                <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                    {this.state.Service!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{

                                            var get_select=get_data_in_args(selectedOption,"value");

                                            var DataModal=self.state.DataModal;

                                            DataModal['id_service']=get_select;

                                            self.setState({
                                                DataModal:DataModal
                                            });

                                        }} defaultValue={array_to_select(data.id_service,'name','_id')} isMulti options={array_to_select(this.state.Service,'name','_id')} />
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                                <label className="font-weight-400 mb-1">Sản phẩm</label>


                                <div className={"col-12 p-0 Box-Select-Mutiple"}>
                                    {this.state.Product!==null?(

                                        <Select isSearchable={true} onChange={(selectedOption)=>{

                                            var get_select=get_data_in_args(selectedOption,"value");

                                            var DataModal=self.state.DataModal;

                                            DataModal['id_product']=get_select;

                                            self.setState({
                                                DataModal:DataModal
                                            });

                                        }} defaultValue={array_to_select(data.id_product,'name','_id')} isMulti options={array_to_select(this.state.Product,'name','_id')} />
                                    ):(
                                        <ItemLoading />
                                    )}
                                </div>

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                                <label className="font-weight-400 mb-1">Ghi chú thẻ</label>

                                <input type="text" onChange={(event)=>{
                                    this.UpdateItemData('note',event.target.value);
                                }} defaultValue={DataModal.note} placeholder="Ghi chú thẻ" required="required" className="form-control w-100" />

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                                <label className="font-weight-400 mb-1">Số người đi kèm cố định</label>

                                <input type="number" onChange={(event)=>{
                                    this.UpdateItemData('number_people',parseInt(event.target.value));
                                }} defaultValue={DataModal.number_people} placeholder="Số người đi kèm cố định" required="required" className="form-control w-100" />


                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                                {DataModal.type===3 || DataModal.type===4?(
                                    <label className="font-weight-400 mb-1">Số tháng tối thiểu</label>

                                ):(
                                    <label className="font-weight-400 mb-1">Số buổi tập hoặc số ngày tập tối thiểu</label>

                                )}

                                <input type="number" onChange={(event)=>{
                                    this.UpdateItemData('min_session',parseInt(event.target.value));
                                }} defaultValue={DataModal.min_session} placeholder="Số buổi tập hoặc số ngày tập tối thiểu" required="required" className="form-control w-100" />

                            </div>

                            {DataModal.type===3 || DataModal.type===4?(
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                                    <label className="font-weight-400 mb-1">Số buổi tập trong tháng</label>

                                    <input type="number" onChange={(event)=>{
                                        this.UpdateItemData('number_day_in_month',parseInt(event.target.value));
                                    }} defaultValue={DataModal.number_day_in_month} placeholder="Số buổi tập trong tháng" required="required" className="form-control w-100" />

                                </div>
                            ):(<></>)}

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Thẻ được phép chuyển đổi sang nhóm cố định</label>

                                <select className={"form-control w-100"} onChange={(event)=>{
                                    self.UpdateItemData("is_convert_to_statics_group",event.target.value);
                                }}>
                                    {[
                                        {
                                            key:1,
                                            value:"Không"
                                        },
                                        {
                                            key:2,
                                            value:"Có"
                                        }
                                    ].map(function(item){
                                        return(
                                            <option value={item.key} selected={self.state.DataModal.is_convert_to_statics_group === item.key}>{item.value}</option>
                                        );
                                    })}
                                </select>


                            </div>

                            {DataModal.type===1?(
                                <div className={"col-12 col-sm-12 col-md-6 pb-3"}>

                                    <label className="font-weight-400 mb-1">Hệ số nhân buổi tập</label>

                                    <input type="number" onChange={(event)=>{
                                        this.UpdateItemData('multiplier',parseInt(event.target.value));
                                    }} defaultValue={DataModal.multiplier} placeholder="Hệ số nhân buổi tập" required="required" className="form-control w-100" />

                                </div>
                            ):(<></>)}
                        </>
                    ):(
                        <></>
                    )}




                </div>
            );
    }

    SubmitModal(){

        var DataModal=this.state.DataModal;

        var number_error=0;


        ['name','price','min_price'].map(function(value,key){

            if(typeof DataModal[value]==="undefined" || DataModal[value].length<2){
                number_error+=1;
            }

        });

        if(typeof DataModal['type']==='undefined'){
            number_error+=1;
        }

        if(number_error===0){
            this.props.GetDataSubmit(DataModal);

        }
        else{
            toast("Vui lòng nhập đầy đủ thông tin");
        }

    }

    render() {

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-dialog-scrollable modal-lg"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Cập nhật thẻ tập</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                {this.state.DataModal!==null?(this.renderData()):(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>

                        </div>

                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.SubmitModal();
                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                Cập nhật
                                            </a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );

    }

}
