/* eslint-disable */
import React,{Component} from "react";
import {
    base64_encode, convertArrayToSingle, filter_args,
} from "../../../helper/array";
import lodash from "lodash";
import {toast} from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import ItemLoading from "../../ItemLoading";
const INIT_STATE={
    data:{
        _id:null,
        id_discount:null
    },
    DetailContract:null,
    InputDiscount:null
};

export default class EditDiscountContract extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
        this.RefComponent=React.createRef();
    }

    componentDidMount() {

        if(typeof this.props.data!=='undefined'){
            this.UpdateDataMember(this.props.data);
        }
    }

    UpdateDataMember(data){

        var update_data=this.state.data;

        update_data['_id']=data['_id'];

        ['id_discount'].map(function (item){
            if (typeof data[item]!=='undefined'){
                update_data[item]=data[item];
            }
        });

        this.setState({
            data:update_data,
            DetailContract:data
        });
    }

    async SaveContract() {

        var GetData = this.state.data;

        var Appthis = this.props.AppThis;

        var number_error = 0;

        if (number_error > 0) {
            toast("Vui lòng kiểm tra lại");
        }
        else {
            var update_data={};

            update_data['_id']=GetData['_id'];

            update_data['id_discount']=convertArrayToSingle(GetData['id_discount']);

            Appthis.PageLoad(true);

            var base64_data = base64_encode(update_data);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data', base64_data);

            var fetch_data = await fetch(Appthis.ConstApp.home_api + this.props.path + "/editdiscount", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            fetch_data = await fetch_data.json();

            if (fetch_data.status === 'ok') {
                toast("Sửa hợp đồng thành công");
            } else {
                toast("Sửa hợp đồng không thành công vui lòng kiểm tra lại");
            }

            Appthis.PageLoad(false);

            if(typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }


        }

    }

    async ValidationDisCount(){

        if(this.state.InputDiscount!==null && typeof this.state.InputDiscount==='string'){

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('discount_code', this.state.InputDiscount);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/discount", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    var getdata=this.state.data;

                    var id_discount=[];

                    if(getdata.id_discount===null){
                        id_discount.push(data.data);
                    }
                    else{
                        id_discount=getdata.id_discount;
                        id_discount.push(data.data);
                    }

                    getdata.id_discount=id_discount;

                    this.setState({
                        InputDiscount:null,
                    });
                    toast("Thêm mã giảm giá thành công");
                }
                else{
                    toast("Thêm mã giảm giá không thành công");
                }
                Appthis.PageLoad(false);


            }).catch((e)=>{
                Appthis.PageLoad(false);
                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");
            });

        }
        else{
            toast("Vui lòng nhập mã giảm giá");
        }

        if(this.RefComponent.current!==null && typeof this.RefComponent.current!=='undefined'){
            this.RefComponent.current.value="";
        }

    }


    DeleteDiscount(key=null){

        var update_data=this.state.data;
        var get_id_discount=update_data.id_discount;
        delete get_id_discount[key];
        update_data.id_discount=filter_args(get_id_discount);
        this.setState({
            data:update_data
        });

    }

    render(){

        const self=this;

        if(this.state.DetailContract!==null){
            return(
                <div className={"col-12"}>

                    <h2 className="d-inline-block mb-4 font18 mt-2 font-weight-bold text-uppercase text-dark border-primary border-bottom pb-2">
                        Mã giảm giá
                    </h2>


                    <div className={"col-12"}>
                        {this.state.data.id_discount.length>0?(
                            <>
                                <div className={"alert alert-danger rounded-0"}>
                                    <i className="fas fa-exclamation-triangle me-1"></i> Thay đổi mã giảm giá không giảm số tiền trên HD
                                </div>

                                <table className="w-100 mw-100 border-0 min-width-auto table-responsive">
                                    <thead>
                                    <tr className={"bg-light text-uppercase font-weight-bold"}>
                                        <th className={"p-2"}>Tên mã KM</th>
                                        <th className={"p-2"}>Mã giảm giá</th>
                                        <th className={"p-2"}>Thao tác</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.data.id_discount.map(function(value,key){
                                            if(value!==null && typeof value!=='undefined' && typeof value.code!=='undefined'){
                                                return(
                                                    <tr>
                                                        <td className={"p-2"}>{value.name}</td>
                                                        <td className={"p-2"}>{value.code}</td>
                                                        <td className={"p-2"}>
                                            <span onClick={()=>{
                                                confirmAlert({
                                                    customUI: ({ onClose }) => {
                                                        return (
                                                            <div className='custom-ui'>
                                                                <h1>Cảnh báo !</h1>
                                                                <p>Bạn có chắc chắn muốn xóa mã giảm giá này ?</p>
                                                                <button onClick={()=>{
                                                                    onClose();
                                                                }}>Không</button>
                                                                <button
                                                                    onClick={() => {
                                                                        self.DeleteDiscount(key);
                                                                        onClose();
                                                                    }}
                                                                >Chắc chắn
                                                                </button>
                                                            </div>
                                                        );
                                                    }
                                                });

                                            }} className={"d-inline-block"}>
                                                <i className="far font12 fa-trash-alt font18 m-2"></i>
                                     </span>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            else{
                                                return(
                                                    <></>
                                                );
                                            }

                                        })
                                    }

                                    </tbody>
                                </table>

                            </>
                        ):(
                            <div className={"alert alert-danger rounded-0"}>
                                <i className="fas fa-info-circle me-1"></i> HD không có mã giảm giá
                            </div>
                        )}

                    </div>

                    <div className="col-12 col-sm-12 col-md-12 offset-lg-5 col-lg-7">

                    <div className="d-flex align-self-center mt-2 flex-wrap flex-row position-relative">
                        <input type="text" ref={this.RefComponent} onChange={(event)=>{
                            this.setState({
                                InputDiscount:event.target.value
                            });
                        }} className="form-control h40px" placeholder="Mã KM" />
                        <a onClick={()=>{
                            this.ValidationDisCount();
                        }} className={"btn h-100 btn-dark ps-3 pe-3 btn-sm rounded-0 position-absolute right-0 top-0"}>
                            Thêm mã KM
                        </a>
                    </div>

                    </div>


                    <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">
                        <div className="row mt-4">
                            <div className="col-12 col-sm-12 col-md-6"></div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <a onClick={()=>{

                                    confirmAlert({
                                        customUI: ({ onClose }) => {
                                            return (
                                                <div className='custom-ui'>
                                                    <h1>Cảnh báo !</h1>
                                                    <p>Bạn có chắc chắn muốn cập nhật mã giảm giá này ?</p>
                                                    <button onClick={()=>{

                                                        onClose();

                                                    }}>Không</button>
                                                    <button
                                                        onClick={() => {
                                                            self.SaveContract();
                                                            onClose();
                                                        }}
                                                    >Chắc chắn
                                                    </button>
                                                </div>
                                            );
                                        }
                                    });

                                }}
                                   className="font16 btn btn-primary rounded-0 w-100">
                                    <i className="fas fa-save me-2"></i> Cập nhật
                                </a>
                            </div>
                        </div>
                    </div>


                    <div className={"col-12 mt-3"} />


                </div>
            );
        }
        else{
            return(
                <div className={"col-12 pt-3 d-flex justify-content-center align-items-center"}>
                    <ItemLoading />
                </div>
            );
        }

    }

}
