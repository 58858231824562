/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    base64_encode,
    array_to_select,
    randomNumber,
    formatNumber,
    getItemInArray,
    NextDay,
    NextMonth,
    between_two_dates,
    filter_args,
    input_price,
    remove_input_price,
    get_data_in_args,
    timeConverterDate,
    get_name_day,
    _isset_object,
    _isset_string,
} from "../../helper/array";
import lodash from "lodash";
import InputMask from "react-input-mask";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import Select, { components } from 'react-select';
import ModalUser from "../user/ModalUser";
import { confirmAlert } from 'react-confirm-alert';
import {number_did_contract, status_contract, status_payment} from "../../helper/member";
import FingerPrintUser from "../finger/FingerPrintUser";
import Receipts from "./Receipts";
import InfoDiscount from "../discount/InfoDiscount";
var random_Number=randomNumber();

const LIST_TYPE_SALE=[
    {
        type:1,
        title:"Thẻ PT"
    },
    {
        type:2,
        title:"Thẻ Member"
    },
    {
        type:3,
        title:"Thẻ Lớp nhóm linh hoạt"
    },
    {
        type:4,
        title:"Thẻ Lớp nhóm cố định"
    }
];
const INIT_STATE={
    type_service:0,
    data:{
        number_card:0,
        id_requires:[],
        id_subject:[],
        group_member_statics:null,
        group_member:null,
        id_card:null,
        id_user:null,
        id_user_sub:[],
        id_discount:[],
        protector:null,
        contact_person:null,
        start_day:Math.ceil(new Date().getTime()/1000),
        custom_price:0,
        id_admin_pt:null,
        source_contract:null,
        type_target:0,
        end_day_contract:null,
        collection_date:Math.ceil(new Date().getTime()/1000),
        note_member:null,
        id_saleprogram:null,
        number_coffer:null
    },
    user:{
      user_login:null
    },
    DetailSubUser:null,
    tmp_price:{
        price:0,
        vat:0,
        insurance:0,
        price_discount:null
    },
    number_vat:0,
    number_insurance:0,
    calculator:1,
    number_calculator:0,
    page:1,
    Required:null,
    Card:null,
    Group:null,
    Source:null,
    Subject:null,
    GetFingerprint:null,
    HistoryUser:null,
    GetUser:{
        user_login:null,
        username:null,
        tmp_user:null,
        birthday:null
    },
    Relationship:null,
    CoSigner:{
        _id:null,
        user_login:null,
        relationship:null
    },
    Protection:{
        _id:null,
        user_login:null,
        relationship:null
    },
    TmpUser:null,
    ListCard:{
        data:null,
        type:null,
        DetailCard:null,
    },
    GroupStatics:null,
    GroupMember:null,
    Loadding:false,
    InfoUser:{
        status:false,
        data:{}
    },
    ChangeCustomPrice:{
        status:false,
        number:''
    },
    ListContract:[],
    ListAdmin:null,
    EventSuccess:null,
    CurrentContract:null,
    DiscountContract:{
        InputDiscount:null,
        InfoDiscount:null
    },
    DataSaleProgram:{
        status:true,
        data:null
    },
    AllSaleProgram:null,
    StatusRenderSaleProgram:true
};

export default class NewContract extends Component{
    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
        this.SelectCard=React.createRef();
        this.InputCode=React.createRef();

    }

    componentDidMount() {

         random_Number=randomNumber();

        this.getRequired();

        this.getSubject();

        this.getSource();

        this.GetGroupStatics();

        this.GetGroupMember();

        this.getPublicOption();

        this.getAdmin();

        this.getSaleProgram();

        if(typeof this.props.DefaultUser!=='undefined'){

            var user=this.state.user;
            user["user_login"]=this.props.DefaultUser;
            this.setState({
                user:user,
                page:2
            },function(){
                this.VerifyPhone(false);
            });
        }

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getSaleProgram(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"getsale", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    AllSaleProgram:data.data
                });
            }
            else{
                self.setState({
                    AllSaleProgram:[]
                });
            }


        }).catch((e)=>{
            self.setState({
                AllSaleProgram:[]
            });
        });


    }

    ResetContract(){

        var get_data=this.state.data;

        get_data['number_card']=0;

        get_data['id_requires']=[];

        get_data['id_subject']=[];

        get_data['id_card']=null;

        get_data['id_user_sub']=[];

        get_data['id_discount']=[];

        get_data['start_day']=Math.ceil(new Date().getTime()/1000);

        get_data['custom_price']=0;

        get_data['id_admin_pt']=null;

        get_data['end_day_contract']=null;

        get_data['collection_date']=Math.ceil(new Date().getTime()/1000);

        get_data['source_contract']=null;

        get_data['group_member_statics']=null;

        get_data['group_member']=null;

        get_data['id_saleprogram']=null;

        var ListCard=this.state.ListCard;

        ListCard['type']=null;

        ListCard['DetailCard']=null;

        this.setState({
            data:get_data,
            DiscountContract:{
                InputDiscount:null,
                InfoDiscount:null
            },
            user:{
                user_login:null
            },
            tmp_price:{
                price:0,
                vat:0,
                insurance:0,
                price_discount:null
            },
            number_vat:0,
            number_insurance:0,
            calculator:1,
            number_calculator:0,
            EventSuccess:null,
            ListCard:ListCard,
            DataSaleProgram:{
                status:true,
                data:null
            },
            StatusRenderSaleProgram:true
        });
    }

    GetGroupStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());

        fetch(Appthis.ConstApp.home_api+"groupstatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                this.setState({
                    GroupStatics:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    GetGroupMember(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{


            if(data.status==='ok'){

                var GroupMember=[
                    {
                        _id:null,
                        name:"Không"
                    }
                ];

                 GroupMember=GroupMember.concat(data.data);

                self.setState({
                    GroupMember:GroupMember
                });

            }


        }).catch((e)=>{

        });


    }


    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListAdmin:data.data
                });

            }


        }).catch((e)=>{

        });


    }


    async getPublicOption(){

        var AppThis=this.props.AppThis;

        var get_vat_options=await AppThis.getOption("number_vat");

        var get_vat=Math.ceil(parseInt(get_vat_options));

        var number_insurance_options=await AppThis.getOption("number_insurance");

        var number_insurance=Math.ceil(parseInt(number_insurance_options));

        this.setState({
            number_insurance:number_insurance,
            number_vat:get_vat
        });


    }

    getRequired(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"requires", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Required:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Subject:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getSource(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type',2);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"source", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Source:data.data
                });

            }


        }).catch((e)=>{

        });

    }


    async getCard(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());

        if(this.state.ListCard.type!==null){
            formData.append('type', this.state.ListCard.type);
        }

       var data=await fetch(Appthis.ConstApp.home_api+"card", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        data=await data.json();

        if(data.status==='ok'){

            var ListCard=this.state.ListCard;
            ListCard.data=data.data;
            ListCard['DetailCard']=null;

            var tmp_price={
                price:0,
                vat:0,
                insurance:0,
                price_discount:null
            };

            var UpdateData=this.state.data;
            UpdateData.custom_price=0;
            UpdateData.end_day_contract=null;
            UpdateData.id_user_sub=[];

            this.setState({
                ListCard:ListCard,
                tmp_price:tmp_price,
                data:UpdateData,
                DetailSubUser:null,
            },function(){
                if(this.SelectCard.current!==null && typeof this.SelectCard.current!=='undefined'){
                    this.SelectCard.current.clearValue();
                }

            });

        }

    }

    UpdateItemData(key,value){
        var user=this.state.user;
        user[key]=value;
        this.setState({
            user:user
        });

    }

    async VerifyPhone(loading=true){

        var get_phone=this.state.user.user_login;

        get_phone=get_phone.replaceAll("_",'');

        if(get_phone.length!==10 || this.state.user.user_login===null){
            toast("Vui lòng nhập số điện thoại");
        }
        else{

            this.fetchContractUser(get_phone,loading);


        }

    }

    async fetchContractUser(phone,loading=true){
        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }

        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/verifycontract", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        const self=this;

        data=await data.json();

        if(loading===true){
            Appthis.PageLoad(false);
        }


        if(data.status==='ok'){

            const self=this;

            if(typeof self.props.DefaultUser!=='undefined'){

                this.GetFingerprint(phone);

                this.HistoryUser(phone);
            }
            else{
                if(_isset_object(data['data'])){

                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className='custom-ui'>
                                    <h1>Cảnh báo !</h1>
                                    <p>Khách hàng có hợp đồng số {data.data.number_contract} chưa thanh toán bạn muốn thanh toán HD cũ không ?</p>
                                    <button onClick={()=>{

                                        this.setState({
                                            page:2
                                        });

                                        this.GetFingerprint(phone);

                                        this.HistoryUser(phone);

                                        onClose();

                                    }}>Không</button>
                                    <button
                                        onClick={() => {

                                            self.setState({
                                                EventSuccess: "receipts",
                                                CurrentContract: data.data
                                            });

                                            onClose();
                                        }}
                                    > Có. Lập phiếu thu
                                    </button>
                                </div>
                            );
                        }
                    });

                }
                else{

                    this.setState({
                        page:2
                    });

                    this.GetFingerprint(phone);

                    this.HistoryUser(phone);

                }
            }





        }
        else{
            toast("Thao tác không thành công");
            this.props.onClose();
        }


    }

    async fetchUser(phone,loading=false){
        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }
        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/infouser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         data=await data.json();

        if(loading===true){
            Appthis.PageLoad(false);
        }

        return data;

    }

    async getInfoUser(phone,loading=false){

        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }

        var get_data=await this.fetchUser(phone);

        if(Object.keys(get_data.data).length>0 && get_data.status==='ok'){

            this.setState({
                GetUser:lodash.cloneDeep(get_data.data),
                user:lodash.cloneDeep(get_data.data)
            });
        }

        this.setState({
            page:3
        });
        if(loading===true) {
            Appthis.PageLoad(false);
        }

    }

    GetFingerprint(phone){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('user_login', phone);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/fingerprint", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            var GetFingerprint=[];

            if(data.status==='ok'){
                GetFingerprint=data.data;
            }
            self.setState({
                GetFingerprint:GetFingerprint
            });



        }).catch((e)=>{

        });
    }

    HistoryUser(phone){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('user_login', phone);

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/historyuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            var HistoryUser=[];

            if(data.status==='ok'){
                HistoryUser=data.data;
            }

            self.setState({
                HistoryUser:HistoryUser
            });


        }).catch((e)=>{

        });
    }


    RenderHistoryContract(){

        return(
            <div className={"overflow-auto col-12"}>
                <table className={"mt-3 w-100 table border-0 mw2000 table-style-old"}>
                    <thead>
                    <tr className={"border-bottom bg-light"}>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="far fa-credit-card me-2" />
                            DỊCH VỤ</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="far fa-file-excel me-2" />
                            SỐ HD</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="far fa-question-circle me-2" />
                            TRẠNG THÁI</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="fas fa-dollar-sign me-2" />
                            GIÁ TRỊ HD</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="far fa-clock me-2" />
                            NGÀY BẮT ĐẦU</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="fas fa-history me-2" />
                            NGÀY KẾT THÚC</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="far fa-calendar-times me-2" />
                            TỔNG SỐ BUỔI</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="far fa-calendar-times me-2" />
                            TỔNG SỐ BUỔI CÒN LẠI</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="fas fa-user-edit me-2" />
                            NHÂN VIÊN BÁN HÀNG</td>
                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                            <i className="fas fa-info me-2" />
                            PT PHỤ TRÁCH</td>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.HistoryUser.map(function(value,index){
                        return(
                            <tr>
                                <td>
                                    <span className={"me-2"}>{value.id_card.name}</span>
                                    {value.id_card.type===1?(<span className={"badge p-1 bg-primary"}>PT</span>):(
                                        <></>
                                    )}

                                    {value.id_card.type===2?(<span className={"badge p-1 bg-primary"}>MB</span>):(
                                        <></>
                                    )}
                                    {value.id_card.type===3?(<span className={"badge p-1 bg-warning"}>LH</span>):(
                                        <></>
                                    )} {value.id_card.type===4?(<span className={"badge p-1 bg-primary"}>CD</span>):(
                                    <></>
                                )}
                                </td>

                                <td>
                                    <span className={"me-1"}>{value.number_contract}</span>
                                    {status_payment(value)}
                                </td>
                                <td>
                                    {status_contract(value)}
                                </td>

                                <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.info_contract_data.price_sale)} VNĐ
                                       </span>
                                </td>

                                <td>
                                    <span>{timeConverterDate(value.start_day)}</span>
                                </td>

                                <td>
                                    <span>{timeConverterDate(value.end_day_contract)}</span>
                                </td>

                                <td>
                                    {value.id_card.type!==2?(
                                        <span>{value.info_contract_data.number_card}</span>
                                    ):(<></>)}

                                </td>

                                <td>

                                    {value.id_card.type!==2?(
                                        <>
                                            {number_did_contract(value)}
                                        </>
                                    ):(<></>)}


                                </td>

                                <td>
                                    <span>{value.id_admin_create.username+ " - "+value.id_admin_create.code_user}</span>
                                </td>

                                <td>
                                       <span>{value.id_admin_pt!==null?(
                                           value.id_admin_pt.username+" - "+value.id_admin_pt.code_user
                                       ):(<></>)}</span>
                                </td>


                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );

    }

    async UpdateSubUser(data){

        var number_error=0;

        if(data.user_login===null || typeof data.user_login==='undefined' || data.user_login.length<10){
            number_error+=1;
        }

        if(number_error>0){
            toast("Số điện thoại không chính xác");
        }
        else{



            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(data));

            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updateuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

             get_data=await get_data.json();


            if(get_data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{


                const self=this;

                var get_data_user=await this.fetchUser(data.user_login);


                if(get_data_user.status==='ok' && Object.keys(get_data_user.data).length>0){

                    var DetailSubUser=[];
                    if(this.state.DetailSubUser!==null){
                         DetailSubUser=this.state.DetailSubUser;
                    }

                    DetailSubUser.push(get_data_user.data);

                    var get_current_data=this.state.data;

                    var id_user_sub=get_current_data.id_user_sub;

                    id_user_sub.push(get_data_user.data._id);

                    get_current_data['id_user_sub']=id_user_sub;

                    this.setState({
                        DetailSubUser:DetailSubUser,
                        data:get_current_data,
                        InfoUser:{
                            status:false,
                            data:{}
                        }
                    },function(){
                        self.AutoCalculator();
                    });

                }

            }

            Appthis.PageLoad(false);

        }

    }

    async UpdateUser(){

        var get_user=this.state.user;
        var number_error=0;

        var type_target=this.state.data.type_target;

        if(type_target===1){
            ['username','birthday','address_contact'].map(function(item,index){
                if(get_user[item]===null || typeof get_user[item]==='undefined' || get_user[item].length<3){
                    number_error+=1;
                }
            });

        }
        else{

            ['username','birthday','address_contact','citizen_identification'].map(function(item,index){
                if(get_user[item]===null || typeof get_user[item]==='undefined' || get_user[item].length<3){
                    number_error+=1;
                }
            });

        }


        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{
            var user_update={};

            if(this.state.data.type_target===0){
                if(this.state.user.user_login && typeof this.state.user.user_login!=='undefined'){
                    user_update['user_login']=this.state.user.user_login;
                }

            }
            else{
                user_update['user_login']=random_Number;
            }

            ['email','sex','username','birthday','address_contact','citizen_identification'].map(function(item){
                user_update[item]=get_user[item];
            });

            if(typeof get_user['_id']!=='undefined'){
                user_update['_id']=get_user['_id'];
            }

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(user_update));

            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updateuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

             get_data=await get_data.json();

            if(get_data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công");

                var StateData=this.state.data;

                StateData.id_user=get_data.data;

                this.GetRelationship();

                var pageUpdate=4;

                const self=this;

                var ListCard=this.state.ListCard;

                if(this.state.type_service===1){

                    pageUpdate=5;

                    ListCard.type=5;
                }

                this.setState({
                    user:{
                        user_login:null
                    },
                    GetUser:null,
                    data:StateData,
                    page:pageUpdate,
                    ListCard:ListCard
                },function(){
                    self.getCard();
                });

            }

            Appthis.PageLoad(false);

        }

    }

    GetRelationship(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"gtypegroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            var Relationship=[];

            if(data.status==='ok'){
                Relationship=data.data;
            }

            self.setState({
                Relationship:Relationship
            });


        }).catch((e)=>{

        });
    }

   async UpdateProtection(input){

        if(input.length===10){

            if(input!==this.state.data.id_user){

                var Protection=this.state.Protection!==null?this.state.Protection:{};

                var Appthis=this.props.AppThis;

                Appthis.PageLoad(true);

                var get_info=await this.fetchUser(input);

                if(get_info.status==='ok' && Object.keys(get_info.data).length>0){

                     Protection=get_info.data;

                    Protection['relationship']=null;

                    this.setState({
                        Protection:lodash.cloneDeep(Protection),
                        TmpUser:lodash.cloneDeep(Protection)
                    });

                }
                else{

                    this.setState({
                        Protection:{
                            _id:null,
                            relationship:null,
                            user_login:input,
                            sex:0
                        },
                        TmpUser:null
                    });

                }

                if(this.state.Protection._id!==null){

                    var getRelationship=await this.getRelationshipUser(this.state.Protection._id);

                    if(getRelationship['status']==='ok' && Object.keys(getRelationship['data']).length>0){

                        Protection=this.state.Protection;

                        Protection['relationship']=getRelationship['data'];

                        this.setState({
                            Protection:Protection
                        });

                    }
                }



                Appthis.PageLoad(false);

            }
            else{
                toast("Vui lòng kiểm tra lại");
            }

        }

    }

   async getRelationshipUser(id){

        var Appthis=this.props.AppThis;

         Appthis.PageLoad(true);

         var users=[id,this.state.data.id_user];

        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('users', base64_encode(users));

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/getrelationship", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         data=await data.json();

        return data;

    }

    async UpdateCoSigner(input){

        if(input.length===10){

            if(input!==this.state.data.id_user){

                var CoSigner=this.state.CoSigner!==null?this.state.CoSigner:{};

                CoSigner.user_login=input;

                var Appthis=this.props.AppThis;

                Appthis.PageLoad(true);

                this.setState({
                    CoSigner:CoSigner
                });

                var get_info=await this.fetchUser(input);

                if(get_info['status']==='ok' && Object.keys(get_info['data']).length>0){

                    CoSigner=get_info['data'];

                    CoSigner['relationship']=null;

                    this.setState({
                        CoSigner:lodash.cloneDeep(CoSigner),
                        TmpUser:lodash.cloneDeep(CoSigner)
                    });
                }
                else{
                    this.setState({
                        CoSigner:{
                            _id:null,
                            user_login:input,
                            relationship:null
                        },
                        TmpUser:null
                    });
                }

                var getRelationship=await this.getRelationshipUser(this.state.CoSigner._id);

                if(getRelationship['status']==='ok' && Object.keys(getRelationship['data']).length>0){

                     CoSigner=this.state.CoSigner;

                    CoSigner['relationship']=getRelationship['data'];

                    this.setState({
                        CoSigner:CoSigner
                    });

                }

                Appthis.PageLoad(false);

            }
            else{
                toast("Vui lòng kiểm tra lại");
            }

        }

    }


    async VerifyProtectorCoSigner(){

        const DataSubmit={
            protection:{},
            cosigner:{}
        };

        var CoSigner=this.state.CoSigner;

        var Protection=this.state.Protection;

        var number_error=0;

        if(this.state.data.type_target===0){

            ['username','user_login','relationship'].map(function(val){

                if(Protection[val]===null || typeof Protection[val]==='undefined' || Protection[val].length<3 || Protection['user_login'].length!==10){
                    number_error+=1;
                }
                else{

                    if(val==='relationship'){
                        DataSubmit['protection'][val]=Protection[val]['_id'];
                    }
                    else{
                        DataSubmit['protection'][val]=Protection[val];
                    }

                }
            });


            ['address_contact','citizen_identification'].map(function(val){
                DataSubmit['protection'][val]=Protection[val];
            });

        }
        else{

            ['username','user_login','relationship'].map(function(val){
                if(CoSigner[val]===null || typeof CoSigner[val]==='undefined' || CoSigner[val].length<3 || CoSigner['user_login'].length!==10){
                    number_error+=1;
                }
                else{

                    if(val==='relationship'){
                        DataSubmit['cosigner'][val]=CoSigner[val]['_id'];
                    }
                    else{
                        DataSubmit['cosigner'][val]=CoSigner[val];
                    }

                }
            });

            ['address_contact','citizen_identification'].map(function(val){
                DataSubmit['cosigner'][val]=CoSigner[val];
            });


        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            this.setState({
                TmpUser:null
            });

            if(this.state.data.type_target===0){
                DataSubmit['protection']['sex']=Protection['sex'];
            }
            else{
                DataSubmit['cosigner']['sex']=CoSigner['sex'];
            }
            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(DataSubmit));

            formData.append('id_user',this.state.data.id_user);

            const self=this;

            fetch(Appthis.ConstApp.home_api+this.props.path+"/setrelationship", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    if(Object.keys(data.data).length>0){

                        var get_data=data.data;

                        var update_data=self.state.data;

                        if(typeof get_data['protection']!=='undefined'){
                            update_data.protector=get_data['protection'];
                        }

                        if(typeof get_data['cosigner']!=='undefined'){
                            update_data.contact_person=get_data['cosigner'];
                        }

                        self.setState({
                            data:update_data,
                            page:5
                        });

                    }

                }
                else{
                    toast("Thao tác không thành công.Vui lòng thử lại");
                }

                Appthis.PageLoad(false);

            }).catch((e)=>{
                Appthis.PageLoad(false);
            });


        }
    }

    async DetailCard(id){
        if(id!==null){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('id_card',id);
            const self=this;

           var get_data=await fetch(Appthis.ConstApp.home_api+"card/detail", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

           get_data=await get_data.json();

            if(get_data.status==='ok'){
                var ListCard=this.state.ListCard;
                ListCard['DetailCard']=get_data.data;
                self.setState({
                    ListCard:ListCard,
                    Loadding:false
                });
            }

        }
    }


    VerifyMinCustomPrice(){

        var ChangeCustomPrice=this.state.ChangeCustomPrice;

        var get_number=ChangeCustomPrice.number;
        var status=false;

        var data=this.state.data;
        var calculator=this.state.calculator;
        var DetailCard=this.state.ListCard.DetailCard;
        var number_calculator=this.state.number_calculator;
        var day=0;

        if(DetailCard!==null && get_number.length>0) {

            get_number=eval(remove_input_price(get_number));

            var min_price=this.state.ListCard.DetailCard.min_price;

            var type_card = DetailCard['type'];

            if(type_card===2){

                if(calculator===1){
                    day=NextDay(data.start_day,number_calculator);
                }
                else{
                    day=NextMonth(data.start_day,number_calculator);
                }
            }
            else if (type_card === 1) {
                day = NextDay(data.start_day, number_calculator * 3);
            } else {
                day = NextMonth(data.start_day, number_calculator);
            }

            if(day!==0){

                var end_day_contract=Math.ceil(day.getTime()/1000);

                var day_gap=Math.ceil((end_day_contract-this.state.data.start_day)/86400);


                 if (type_card === 2) {
                  if (get_number >= (min_price * day_gap) && day_gap>=parseInt(DetailCard['min_session'])) {
                    status = true;
                  }
                }
                 else if (type_card === 1) {
                     if (get_number >= number_calculator * min_price) {
                         status = true;
                     }
                 } else {
                     if (
                         get_number >=
                         number_calculator * DetailCard.number_day_in_month * min_price
                     ) {
                         status = true;
                     }
                 }

            }

        }

        if(status===true){

            var dataUpdate=this.state.data;

            dataUpdate.custom_price=get_number;

            this.setState({
                data:dataUpdate,
                ChangeCustomPrice:{
                    status:false,
                    number:0
                }
            });
        }
        else{
            toast("Giá bạn nhập nhỏ hơn giá tối thiểu");
        }

    }

   async ValidationDisCount(){

        if(this.state.DiscountContract.InputDiscount!==null && typeof this.state.DiscountContract.InputDiscount==='string'){

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            const self=this;

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('discount_code', this.state.DiscountContract.InputDiscount);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/discount", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    var DiscountContract=self.state.DiscountContract;

                    DiscountContract.InfoDiscount=data.data;

                    DiscountContract.InputDiscount=null;

                    this.setState({
                        DiscountContract:DiscountContract
                    },function(){
                        self.AutoCalculator();
                    });

                    toast("Thêm mã giảm giá thành công");

                }
                else{
                    toast("Thêm mã giảm giá không thành công");

                    self.setState({
                        DiscountContract:{
                            InputDiscount:null,
                            InfoDiscount:null
                        }
                    });

                }
                Appthis.PageLoad(false);


                if(self.InputCode.current!==null && typeof self.InputCode.current!=='undefined'){
                    self.InputCode.current.value="";
                }


            }).catch((e)=>{

                self.setState({
                    DiscountContract:{
                        InputDiscount:null,
                        InfoDiscount:null
                    }
                });

                Appthis.PageLoad(false);
                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");

                if(self.InputCode.current!==null && typeof self.InputCode.current!=='undefined'){
                    self.InputCode.current.value="";
                }


            });




        }
        else{
            toast("Vui lòng nhập mã giảm giá");
        }
    }

    OptionLayoutGroupMember(props,GroupStatics){
        const self=this;
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{get_data_item.name}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>

                    {get_data_item.id_user?(<>
                        <p className={"m-0"}>Đại diện : {get_data_item.id_user.username+" - "+get_data_item.id_user.code_user}</p>
                    </>):(<></>)}

                </components.Option>
            );
        }
        else{
            return(<></>);
        }
    }

    OptionLayout(props,GroupStatics){
        const self=this;
       var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{props.data.label}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>
                    <p className={"m-0"}>HLV : {get_data_item.id_admin_pt.username+" - "+get_data_item.id_admin_pt.code_user}</p>
                    <p className={"m-0"}>Ngày :
                        {Object.keys(get_data_item.days).length>0?(
                              Object.entries(get_data_item.days).map(function(item,index){
                                  return(<span className={"m-1 badge font10 bg-black-50 p-1"}>{get_name_day(item[1])}</span>);
                              })
                        ):(<></>)}
                    </p>
                    <p className={"m-0"}>giờ : {get_data_item.time_start} - {get_data_item.time_end}</p>
                </components.Option>
            );
        }
        else{
            return(<></>);
        }

    }

    NumberPeople(){

        var DetailCard=this.state.ListCard.DetailCard;

        const self=this;

        var DetailSubUser=this.state.DetailSubUser;

        if(DetailCard.number_people>0 && this.state.type_service===0){
            return(

                <div className={"col-12"}>

                    <div className={"alert alert-primary mt-3 rounded-0"}>
                        <i className="fas fa-user-plus" />  Số người đi kèm tối đa : {DetailCard.number_people}
                    </div>


                    {DetailSubUser!==null?(
                        <>
                            <div className={"col-12 overflow-auto"}>
                                <table className={"mt-3 w-100 table border-0 mw400"}>
                                    <thead>
                                    <tr className={"border-bottom bg-light"}>
                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                            Họ và tên
                                        </td>
                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                            SDT/ID tài khoản
                                        </td>

                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                            Mã số thẻ
                                        </td>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {DetailSubUser.map(function(item,index){
                                        return(
                                            <>
                                                <tr>
                                                    <td>{item.username}</td>
                                                    <td>{item.user_login}</td>
                                                    <td className={'position-relative'}>
                                                        {item.card_user}
                                                        <a className={"text-danger font12 mt-1 ms-3"} onClick={()=>{

                                                            var update_data=self.state.data;

                                                            var id_user_sub=update_data.id_user_sub;

                                                            delete id_user_sub[index];

                                                            update_data.id_user_sub=filter_args(id_user_sub);

                                                            var UpdateDetailSubUser=self.state.DetailSubUser;

                                                            delete UpdateDetailSubUser[index];

                                                            self.setState({
                                                                data:update_data,
                                                                DetailSubUser:filter_args(UpdateDetailSubUser)
                                                            },function(){
                                                                self.AutoCalculator();
                                                            });

                                                        }}>
                                                            <i className="far font18 fa-trash-alt"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                    </tbody>


                                </table>
                            </div>

                        </>

                    ):(
                        <div className={"alert m-0 mb-3 alert-danger"}>
                            <i className="fas fa-times-circle"/> Không có dữ liệu
                        </div>
                    )}

                    {this.state.DetailSubUser===null || this.state.DetailSubUser.length<DetailCard.number_people?(
                        <div className={"col-12 mt-3 mb-4"}>
                            <a onClick={()=>{
                                this.setState({
                                    InfoUser:{
                                        status:true,
                                        data:{}
                                    }
                                });
                            }} className={"btn btn-danger ps-3 pe-3 rounded-0"}>
                                <i className="far fa-plus-square me-2" /> Thêm mới
                            </a>
                        </div>
                    ):(
                        <></>
                    )}




                </div>
            );
        }
        else{
            return(<></>);
        }
    }

    RenderActionCard(){

        var DetailCard=this.state.ListCard.DetailCard;

        const self=this;

        if(DetailCard!==null){

            var type_card=DetailCard['type'];

            if(this.state.Loadding===true){
                return(
                    <div className={"col-12 pt-4 d-flex justify-content-center align-self-center"}>
                        <ItemLoading />
                    </div>
                );
            }
            else{

                switch (type_card){

                    case 1:
                        return(
                            <div className={"row"}>
                                {self.state.data.id_saleprogram===null?(
                                    <>
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                            <p className={"mb-1"}>Số buổi tập <span className={"text-red"}>( * )</span></p>

                                            <div className={"col-12 d-flex align-content-start position-relative"}>

                                                <select onChange={(event)=>{
                                                    self.setState({
                                                        calculator:Math.ceil(event.target.value)
                                                    });

                                                }} className={"form-control w80px"}>
                                                    <option value={1}>Buổi</option>
                                                </select>
                                                <i className="fas fa-caret-down position-absolute select-type-input" />
                                                <input onChange={(event)=>{

                                                    var data_update=self.state.data;
                                                    data_update['custom_price']=0;
                                                    data_update['id_discount']=[];
                                                    self.setState({
                                                        number_calculator:Math.ceil(event.target.value),
                                                        data:data_update
                                                    },function(){
                                                        self.AutoCalculator();
                                                    })
                                                }} type={"number"} placeholder={"số buổi"} defaultValue={0} className={"form-control w-100 rounded-0"} />

                                            </div>


                                        </div>
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                            <p className={"mb-1"}>Huấn Luyện viên <span className={"text-red"}>( * )</span></p>

                                            {self.state.ListAdmin!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var data=self.state.data;
                                                    data.id_admin_pt=selectedOption.value;
                                                    self.setState({
                                                        data:data
                                                    });

                                                }} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>


                                        {this.state.type_service===0?(
                                            <>
                                                <div className={"col-12 alert bg-danger text-white"}>
                                                    Số buổi tập tối thiểu : {DetailCard.min_session}
                                                </div>
                                            </>
                                        ):(
                                            <></>
                                        )}


                                    </>
                                ):(
                                    <>
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 customCssSelect pb-2"}>
                                            <p className={"mb-1"}>Huấn Luyện viên <span className={"text-red"}>( * )</span></p>

                                            {self.state.ListAdmin!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var data=self.state.data;
                                                    data.id_admin_pt=selectedOption.value;
                                                    self.setState({
                                                        data:data
                                                    });

                                                }} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>
                                    </>
                                )}
                            </div>
                        )

                    case 2:
                    case 5:

                        if(self.state.data.id_saleprogram===null){
                            return(
                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                    <p className={"mb-1"}>Số buổi tập <span className={"text-red"}>( * )</span></p>

                                    <div className={"col-12 d-flex align-content-start position-relative"}>

                                        <select onChange={(event)=>{
                                            self.setState({
                                                calculator:Math.ceil(event.target.value)
                                            },function(){
                                                self.AutoCalculator();
                                            });

                                        }} className={"form-control w80px"}>
                                            <option value={1}>Buổi</option>
                                            <option value={2}>Tháng</option>
                                        </select>
                                        <i className="fas fa-caret-down position-absolute select-type-input" />
                                        <input type={"number"} onChange={(event)=>{


                                            var data_update=self.state.data;

                                            data_update['custom_price']=0;
                                            data_update['id_discount']=[];
                                            self.setState({
                                                number_calculator:Math.ceil(event.target.value),
                                                data:data_update
                                            },function(){
                                                self.AutoCalculator();
                                            })
                                        }} placeholder={"số buổi hoặc tháng"} defaultValue={0} className={"form-control w-100 rounded-0"} />

                                    </div>


                                    {this.state.type_service===0?(
                                        <>
                                            <div className={"col-12 alert bg-danger text-white"}>
                                                Số buổi tập tối thiểu : {DetailCard.min_session}
                                            </div>
                                        </>
                                    ):(
                                        <></>
                                    )}


                                </div>
                            )
                        }
                        else{
                            return(<></>);
                        }

                    case 3:
                    case 4:
                        return(

                            <div className={"row"}>


                                {self.state.data.id_saleprogram===null?(
                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                        <p className={"mb-1"}>Số tháng tập <span className={"text-red"}>( * )</span></p>

                                        <div className={"col-12 d-flex align-content-start position-relative"}>

                                            <select onChange={(event)=>{
                                                self.setState({
                                                    calculator:Math.ceil(event.target.value)
                                                },function(){
                                                    self.AutoCalculator();
                                                });

                                            }} className={"form-control w80px"}>
                                                <option value={2}>Tháng</option>
                                            </select>
                                            <i className="fas fa-caret-down position-absolute select-type-input" />
                                            <input type={"number"} onChange={(event)=>{

                                                var data_update=self.state.data;

                                                data_update['custom_price']=0;
                                                data_update['id_discount']=[];

                                                self.setState({
                                                    number_calculator:Math.ceil(event.target.value),
                                                    data:data_update
                                                },function(){
                                                    self.AutoCalculator();
                                                })
                                            }} placeholder={"số tháng"} defaultValue={0} className={"form-control w-100 rounded-0"} />

                                        </div>

                                    </div>
                                ):(
                                    <></>
                                )}

                                {type_card===4?(
                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                        <p className={"mb-1"}>Lớp nhóm cố định <span className={"text-red"}>( * )</span></p>

                                        {self.state.GroupStatics!==null?(

                                            <Select isSearchable={true} onChange={(selectedOption)=>{

                                                var data=self.state.data;
                                                data.group_member_statics=selectedOption.value;
                                                self.setState({
                                                    data:data
                                                });

                                            }} options={array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                                Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                            }} />

                                        ):(
                                            <ItemLoading />
                                        )}

                                    </div>
                                ):(<></>)}


                                {self.state.data.id_saleprogram===null?(
                                    <div className={"col-12 alert bg-danger text-white"}>
                                        Số tháng tối thiểu : {DetailCard.min_session} tháng
                                    </div>
                                ):(
                                    <></>
                                )}

                            </div>
                        )

                }
            }



        }
        else{
            return(<></>);
        }

    }

    async AutoCalculator(){

        var calculator=this.state.calculator;

        var number_calculator=this.state.number_calculator;

        var price=0;

        var DetailCard=this.state.ListCard.DetailCard;

        var data=this.state.data;

        var day=0;

        var insurance=Math.ceil(this.state.number_insurance);


        try{
            if(DetailCard!==null) {

                var custom_price = this.state.data.custom_price;

                var number_card_update=0;

                var type_card = DetailCard['type'];

                if(type_card===2 || type_card===5){

                    if(calculator===1){
                        day=NextDay(data.start_day,number_calculator);
                        number_card_update=number_calculator;
                        price=Math.ceil(number_calculator*DetailCard.price);
                    }
                    else{
                        day=NextMonth(data.start_day,number_calculator);
                        number_card_update=Math.ceil(between_two_dates(new Date(data.start_day*1000),day)/86400);
                        price=Math.ceil(number_card_update*DetailCard.price);

                    }
                }
                else if (type_card===1){
                    number_card_update=number_calculator;
                    day=NextDay(data.start_day,number_calculator*3);
                    price=Math.ceil(number_calculator*DetailCard.price);
                }
                else{

                    day = NextMonth(data.start_day, number_calculator);
                    number_card_update =number_calculator * Math.ceil(DetailCard.number_day_in_month);
                    price = Math.ceil(number_card_update * DetailCard.price);

                }


                if(price<0){
                    price=0;
                }


                var end_day_contract=Math.ceil(day.getTime()/1000);

                data.end_day_contract=end_day_contract;

                var day_gap=Math.ceil((end_day_contract-this.state.data.start_day)/86400);

                if(day_gap>367){
                    insurance=Math.ceil(this.state.number_insurance*Math.ceil(day_gap/367));
                }

                if(data.id_user_sub!==null){
                    insurance=insurance+data.id_user_sub.length*insurance;
                }

                data['number_card']=number_card_update;

                var get_vat=this.state.number_vat;

                 var vat = 0;

                var price_discount=null;

                if (custom_price > 0) {
                    price_discount=custom_price;

                } else {
                    price_discount=price;
                }


                if(data.id_discount.length>0){

                    data.id_discount.map(function(item,index){

                        if(item.price_sale && typeof item.price_sale!=='undefined'){
                            price_discount=Math.ceil(price_discount-item.price_sale);
                        }

                        if(item.percent_sale && typeof item.percent_sale!=='undefined'){
                                price_discount=price_discount-Math.ceil((price_discount*parseInt(item.percent_sale))/100);
                        }

                    });

                    if(price_discount<0){
                        price_discount=0;
                    }

                }
                else{
                    price_discount=null;
                }


                if (price_discount !== null) {
                   vat = Math.ceil((price_discount/1.05)* (get_vat / 100));
                } else {
                  if (custom_price > 0) {
                    vat = Math.ceil((custom_price/1.05)* (get_vat / 100));
                  } else {
                    vat = Math.ceil((price/1.05)* (get_vat / 100));
                  }
                }


                var tmp_price={
                    price:price,
                    vat:vat,
                    insurance:insurance,
                    price_discount:price_discount
                };

                const self=this;

                this.setState({
                    tmp_price:tmp_price,
                    data:data
                },function(){
                    self.forceUpdate();
                });


            }
        }
        catch (e) {

        }

    }

    async SubmitContract(){

        var GetData = this.state.data;

        var Appthis = this.props.AppThis;

        var number_error = 0;


        if(this.state.type_service===0){
            ['id_subject', 'id_requires'].map(function (item, index) {
                if (GetData[item].length <= 0) {
                    number_error += 1;
                }
            });

        }
        else{
            ['number_coffer'].map(function (item, index) {

                if (GetData[item].length <= 0 || GetData[item]===null) {
                    number_error += 1;
                }

            });

        }

        //

        ['id_user'].map(function (item, index) {
            if (GetData[item] === null || typeof GetData[item] === 'undefined' || GetData[item].length < 5) {
                number_error += 1;
            }
        });
        if(this.state.type_service===0) {

            ['source_contract'].map(function (item, index) {
                if (GetData[item] === null || typeof GetData[item] === 'undefined' || GetData[item].length < 5) {
                    number_error += 1;
                }
            });
        }

        if(GetData['id_saleprogram']===null){

            if (GetData['id_card'] === null || typeof GetData['id_card'] === 'undefined' || GetData['id_card'].length < 5) {
                number_error += 1;
            }

            ['number_card'].map(function (item, index) {
                if (GetData[item] === null || typeof GetData[item] === 'undefined' || isNaN(GetData[item])===true || GetData[item]<=0) {
                    number_error += 1;
                }
            });

        }

        var DetailCard = this.state.ListCard.DetailCard;

        if(DetailCard!==null){

            /*
            switch (DetailCard.type) {

                case 1:

                    if (GetData['id_admin_pt'] === null) {
                        number_error += 1;
                    }
                    break;
                case 4:
                    if (GetData['group_member_statics'] === null) {
                        number_error += 1;
                        break;
                    }
            }
             */
        }
        else{
            number_error += 1;
        }

        if (number_error > 0) {
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else {
            Appthis.PageLoad(true);
            var update_id_discount=[];
            if(GetData.id_discount.length>0){
                GetData.id_discount.map(function(item){
                    if(_isset_object(item)){
                        update_id_discount.push(item._id);
                    }
                });
            }

            GetData['id_discount']=update_id_discount;

            var base64_data = base64_encode(GetData);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('data', base64_data);

            const self = this;

            var fetch_data = await fetch(Appthis.ConstApp.home_api + this.props.path + "/add", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            fetch_data = await fetch_data.json();

            if (fetch_data.status === 'ok') {

                var ListContract = this.state.ListContract;

                ListContract.push(fetch_data.data);

                var update_data=self.state.data;

                update_data.id_discount=[];

                this.setState({
                    ListContract: ListContract,
                    data:update_data
                },function(){
                    self.AutoCalculator();
                });

                confirmAlert({
                    customUI: ({onClose}) => {
                        return (
                            <div className='custom-ui contract-custom-ui'>
                                <h1>Thông báo !</h1>
                                <p>Đã thêm hợp đồng thành công.Chọn thao tác tiếp theo</p>

                                {typeof self.props.Fingerprint!=='undefined' && self.props.Fingerprint===false?(
                                    <div className={"col-12 col-sm-6 col-md-6 col-lg-8 offset-lg-2"}>
                                        <div className={"row w-100"}>
                                            <div className={"col-12 col-sm-6 col-md-6"}>
                                                <button onClick={() => {
                                                    self.ResetContract();
                                                    onClose();
                                                }}><i className="far fa-times-circle"/> Đóng
                                                </button>
                                            </div>

                                            <div className={"col-12 col-sm-6 col-md-6"}>
                                                <button onClick={() => {

                                                    self.setState({
                                                        EventSuccess: "receipts",
                                                        CurrentContract: fetch_data.data
                                                    });

                                                    onClose();
                                                }}><i className="fas fa-file-invoice-dollar"/> phiếu thu
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                ):(
                                    <div className={"row"}>
                                        <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                            <button onClick={() => {
                                                self.ResetContract();
                                                onClose();
                                            }}><i className="far fa-times-circle"/> Đóng
                                            </button>
                                        </div>


                                        <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                            <button onClick={() => {
                                                self.setState({
                                                    EventSuccess: "fingerprint",
                                                    CurrentContract: fetch_data.data
                                                });
                                                onClose();
                                            }}><i className="fas fa-fingerprint"/> vân tay
                                            </button>
                                        </div>

                                        <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                            <button onClick={() => {

                                                self.setState({
                                                    EventSuccess: "receipts",
                                                    CurrentContract: fetch_data.data
                                                });

                                                onClose();
                                            }}><i className="fas fa-file-invoice-dollar"/> phiếu thu
                                            </button>
                                        </div>

                                    </div>
                                )}



                            </div>
                        );
                    },
                    closeOnClickOutside: false
                });


            } else {
                toast("Thêm hợp đồng không thành công vui lòng thử lại");
            }

            Appthis.PageLoad(false);

        }
    }

    async VerifySubmitContract() {

        const self=this;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn cập nhật thao tác này ?</p>
                        <button onClick={()=>{

                            onClose();

                        }}>Không</button>
                        <button
                            onClick={() => {
                                self.SubmitContract();
                                onClose();
                            }}
                        > Có.Tôi chắc chắn
                        </button>
                    </div>
                );
            }
        });



    }

    getListContractAdd(){

        const self=this;
        if(this.state.ListContract.length>0){

            return(
                <div className={"overflow-auto mt-3 col-12"}>

                    <p className={"font18 text-uppercase mt-3 text-danger font-weight-bold"}>Danh sách HD mới nhất</p>
                    <table className={"mt-3 w-100 table border-0 mw1600 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-user me-2"/> TÊN HỘI VIÊN</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i
                                    className="fas fa-mobile-alt me-2" /> SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-shield-alt me-2" />
                                MÃ KH</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-credit-card me-2" />
                                DỊCH VỤ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-file-excel me-2" />
                                SỐ HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-dollar-sign me-2" />
                                GIÁ TRỊ HD</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-clock me-2" />
                                NGÀY BẮT ĐẦU</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-history me-2" />
                                NGÀY KẾT THÚC</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-calendar-times me-2" />
                                TỔNG SỐ BUỔI</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-calendar-times me-2" />
                                TỔNG SỐ BUỔI CÒN LẠI</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.ListContract.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        <span>{value.id_user.username}</span>
                                    </td>
                                    <td>
                                        <span>{value.id_user.user_login}</span>
                                    </td>
                                    <td>
                                        <span>{value.id_user.code_user}</span>
                                    </td>
                                    <td>
                                        <span className={"me-2"}>{value.id_card.name}</span>
                                        {value.id_card.type===1?(<span className={"badge p-1 bg-primary"}>PT</span>):(
                                            <></>
                                        )}

                                        {value.id_card.type===2?(<span className={"badge p-1 bg-primary"}>MB</span>):(
                                            <></>
                                        )}
                                        {value.id_card.type===3?(<span className={"badge p-1 bg-warning"}>LH</span>):(
                                            <></>
                                        )} {value.id_card.type===4?(<span className={"badge p-1 bg-primary"}>CD</span>):(
                                        <></>
                                    )}
                                    </td>

                                    <td>
                                        <span className={"me-1"}>{value.number_contract}</span>
                                        {status_payment(value)}
                                    </td>

                                    <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.info_contract_data.price_sale)} VNĐ
                                       </span>
                                    </td>

                                    <td>
                                        <span>{timeConverterDate(value.start_day)}</span>
                                    </td>

                                    <td>
                                        <span>{timeConverterDate(value.end_day_contract)}</span>
                                    </td>

                                    <td>

                                        {value.id_card.type!==2?(
                                            <span>{value.info_contract_data.number_card}</span>
                                        ):(<></>)}

                                    </td>

                                    <td>
                                        {value.id_card.type===2?(
                                            <></>
                                        ):(
                                            <span>
                                              {value.info_contract_data.number_card-value.info_contract_data.number_did_pt}
                                          </span>
                                        )}
                                    </td>


                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }

    }

    RenderView(){

        switch (this.state.page) {
            case 1:
                return this.Step1();
            case 2:
                return this.Step2();
            case 3:
                return this.Step3();
            case 4:
                return this.Step4();
            case 5:
                return this.Step5();
        }

    }

    render() {

        const self=this;

        if(this.state.EventSuccess==null){
            return(
                <div className="modal d-block position-fixed">

                    <div className={"modal-dialog modal-fullscreen modal-dialog-scrollable"}>

                        <div className="modal-content bg-white shadow border-0">
                            <div className="modal-header bg-light p-2 pb-2">
                                <div className={"container position-relative"}>
                                    <strong className={"font18 text-black text-uppercase"}>
                                        <i className="fas fa-plus-circle me-2" />
                                        Thêm Hợp Đồng</strong>

                                    <a onClick={()=>{
                                        this.props.onClose();
                                    }} className={"font-weight-bold position-absolute top-0 right15 font15"}>
                                        <i className="far fa-times-circle" /> Đóng
                                    </a>

                                </div>
                            </div>
                            <div className="modal-body">


                                <div className={"container"}>


                                    <div className={"col-12 mt-3 wizard-template-new-contract wizard-template"}>
                                        <ul className="m-0 p-0 position-relative nav nav-pills nav-fill">
                                            <li className="nav-item">
                                            <span
                                                className="font-weight-bold text-uppercase d-block w-100 font16">Bước 1</span>
                                                <i className="position-relative font26 mt-3 mb-3 bg-primary m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-mobile-alt" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Nhập SDT/ID </span>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 2</span>
                                                <i className="position-relative m-auto mt-3 mb-3 font26 bg-warning rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-mobile-alt" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Lịch sử người dùng </span>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 3</span>
                                                <i className="position-relative m-auto mt-3 mb-3 font26 bg-info rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-user-edit" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Thông tin khách hàng </span>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 4</span>
                                                <i className="position-relative m-auto mt-3 mb-3 font26 bg-danger ml-auto mr-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-file-invoice" />
                                                <div className="col-12 p-0">

                                                    {this.state.data.type_target===0?(
                                                        <span className="description-wizard">
                                                            Người liên lạc khẩn cấp
                                                        </span>
                                                    ):(
                                                        <span className="description-wizard">
                                                            Người đồng ký tên
                                                        </span>
                                                    )}

                                                </div>
                                            </li>
                                            <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 5</span>
                                                <i className="position-relative font26 m-auto mt-3 mb-3 p26 bg-secondary m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-hand-holding-heart" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Thông tin gói tập </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>



                                    <div className={"row mb-2 mt-2"}>

                                        {this.RenderView()}

                                    </div>
                                </div>



                            </div>


                        </div>

                    </div>

                    {this.state.InfoUser.status===true?(
                        <ModalUser onClose={()=>{
                            self.setState({
                                InfoUser:{
                                    status:false,
                                    data:{}
                                }});
                        }} path={this.props.path} data={this.state.InfoUser.data} SubmitUser={(response)=>{
                            self.UpdateSubUser(response);
                        }} AppThis={this.props.AppThis} />
                    ):(
                        <></>
                    )}


                    <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                </div>
            );

        }
        else{

            if(this.state.EventSuccess==='fingerprint'){

                return(
                    <FingerPrintUser onClose={()=>{
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <div className='custom-ui contract-custom-ui'>
                                        <h1>Thông báo !</h1>
                                        <p>Chọn chức năng bạn muốn tiếp tục</p>
                                        <div className={"row"}>
                                            <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                                <button onClick={()=>{
                                                    self.props.onClose();
                                                    onClose();
                                                }}><i className="far fa-times-circle me-2" /> Đóng</button>
                                            </div>
                                            <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                                <button onClick={()=>{
                                                    self.ResetContract();
                                                    onClose();
                                                }}><i className="far fa-times-circle me-2" /> HD mới</button>
                                            </div>

                                            <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                                                <button onClick={()=>{

                                                    self.setState({
                                                        EventSuccess:"receipts"
                                                    });

                                                    onClose();
                                                }}><i className="fas fa-file-invoice-dollar me-2" /> phiếu thu</button>
                                            </div>

                                        </div>


                                    </div>
                                );
                            },
                            closeOnClickOutside:false
                        });
                    }} DisableUpload={true} AppThis={this.props.AppThis} path={this.props.path} data={this.state.CurrentContract.id_user} />
                );

            }
            else{

                return(
                    <Receipts onClose={()=>{
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <div className='custom-ui contract-custom-ui'>
                                        <h1>Thông báo !</h1>
                                        <p>Bạn có muốn thêm tiếp HD cho người dùng không ?</p>
                                        <div className={"row"}>
                                            <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                                <button onClick={()=>{
                                                    self.props.onClose();
                                                    onClose();
                                                }}><i className="far fa-times-circle me-2" /> không</button>
                                            </div>

                                            <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                                <button onClick={()=>{
                                                    self.ResetContract();
                                                    onClose();
                                                }}><i className="fas fa-file-invoice-dollar me-2" />
                                                Có
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                );
                            },
                            closeOnClickOutside:false
                        });

                    }} onSuccess={()=>{

                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <div className='custom-ui contract-custom-ui'>
                                        <h1>Thông báo !</h1>
                                        <p>Bạn có muốn thêm tiếp HD cho người dùng không ?</p>
                                        <div className={"row"}>
                                            <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                                <button onClick={()=>{
                                                    self.props.onClose();
                                                    onClose();
                                                }}><i className="far fa-times-circle me-2" /> không</button>
                                            </div>

                                            <div className={"col-12 col-sm-6 col-md-6 col-lg-6"}>
                                                <button onClick={()=>{

                                                    self.ResetContract();

                                                    onClose();
                                                }}><i className="fas fa-file-invoice-dollar me-2" />
                                                    Có
                                                </button>
                                            </div>

                                        </div>


                                    </div>
                                );
                            },
                            closeOnClickOutside:false
                        });

                    }} AppThis={this.props.AppThis} path={this.props.path} data={this.state.CurrentContract}  />
                );

            }

        }


    }


    Step1(){
        const self=this;
        return(
            <div className={"col-12"}>

                <h2 className="d-inline-block mb-3 font18 mt-5 text-dark border-primary border-bottom pb-2 font-weight-bold">
                    Bước 1 : SDT/ID </h2>



                        <div className={"alert alert-warning rounded-0"}>
                            <i className="fas fa-exclamation-triangle" /> Vui lòng nhập đầy đủ thông tin
                        </div>

                        <div className={"col-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 InputMask"}>

                            <label className="font-weight-400 d-block mt-4 w-100 mb-2">SDT/ID : <span className="text-red">( * )</span></label>

                            <InputMask mask="9999999999" onChange={event=>{
                                this.UpdateItemData('user_login',event.target.value);
                            }} defaultValue={typeof this.props.DefaultUser!=='undefined'?this.props.DefaultUser:""} placeholder={"Số điện thoại"} />


                            <div className={"col-12 mt-3 text-center"}>

                                {this.state.type_service===0?(
                                    <>
                                        <label className={"d-inline-flex flex-row align-items-center me-3"}>
                                            <input type={"radio"} checked={true} className={"d-inline-block w-auto"} name={"type_service"} onChange={()=>{
                                                this.setState({
                                                    type_service:0
                                                });
                                            }} value={0} />
                                            <span className={"d-inline-block ms-1"}>Mua dịch vụ</span>
                                        </label>
                                        <label className={"d-inline-flex flex-row align-items-center"}>
                                            <input type={"radio"} className={"d-inline-block w-auto"} name={"type_service"} value={1} onChange={()=>{
                                                this.setState({
                                                    type_service:1
                                                });
                                            }} />
                                            <span className={"d-inline-block ms-1"}>
                                        Thuê dịch vụ
                                        </span>
                                        </label>
                                    </>
                                ):(
                                    <>
                                        <label className={"d-inline-flex flex-row align-items-center me-3"}>
                                            <input type={"radio"} className={"d-inline-block w-auto"} name={"type_service"} onChange={()=>{
                                                this.setState({
                                                    type_service:0
                                                });
                                            }} value={0} />
                                            <span className={"d-inline-block ms-1"}>Mua dịch vụ</span>
                                        </label>
                                        <label className={"d-inline-flex flex-row align-items-center"}>
                                            <input type={"radio"} checked={true} className={"d-inline-block w-auto"} name={"type_service"} value={1} onChange={()=>{
                                                this.setState({
                                                    type_service:1
                                                });
                                            }} />
                                            <span className={"d-inline-block ms-1"}>
                                        Thuê dịch vụ
                                        </span>
                                        </label>
                                    </>
                                )}




                            </div>

                            <p className="hr-text text-center w-100 position-relative mt-3 mb-3"><span
                                className="position-relative ps-3 pe-3 d-inline-block bg-white">Hoặc</span></p>

                            <a onClick={()=>{
                                var data=this.state.data;
                                data.type_target=1;
                                this.setState({
                                    data:data,
                                    user:{
                                        user_login:random_Number
                                    },
                                    page:3,
                                },function(){
                                    self.getInfoUser(random_Number);
                                });

                            }} className={"btn rounded-0 w-100 mt-3 btn-sm btn-outline-success"}>
                                Không có SDT/ID
                            </a>




                            <div className={"col-12 col-sm-12 p-0 col-md-12 text-center pt-3"}>
                                <a onClick={()=>{
                                    this.VerifyPhone();
                                }} className={"text-uppercase font-weight-bold btn btn-sm btn-outline-primary rounded-0 w-100 pt-3 pb-3"}>
                                    <i className={"fas fa-forward"} /> Tiếp theo
                                </a>
                            </div>

                        </div>



            </div>
        );
    }

    Step2(){
        return(
            <div className={"col-12"}>

                <h2 className="d-inline-block mb-3 font18 mt-5 text-dark border-primary border-bottom pb-2 font-weight-bold">Bước
                    2 : Lịch sử người dùng</h2>
                <div className={"col-12 pt-3"}>

                    <div className="col-12 col-sm-12 col-md-12"><p className="text-uppercase font-weight-bold font16">Vân
                        tay và thẻ khách hàng</p></div>

                    {this.state.GetFingerprint!==null?(
                        Object.keys(this.state.GetFingerprint).length>0?(
                            <div className={"col-12 overflow-auto"}>
                                <table className={"w-100 table border-0 table-style-old"}>
                                    <thead>
                                    <tr className={"border-bottom bg-light"} >
                                        <td className={"font-weight-bold text-uppercase font14 p-3"}>
                                            <i className="fas fa-map-marker me-2" />
                                            Địa chỉ</td>
                                        <td className={"font-weight-bold text-uppercase font14 p-3"}>
                                            <i className="far fa-hdd me-2" />
                                            Tên máy</td>
                                        <td className={"font-weight-bold text-uppercase font14 p-3"}>
                                            <i className="fas fa-sort-numeric-down me-2" />
                                            ID Máy</td>
                                        <td className={"font-weight-bold text-uppercase font14 p-3"}>
                                            <i className="far fa-bell me-2" />
                                            Trạng thái</td>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {this.state.GetFingerprint.map(function(item){
                                        return(
                                            <tr>
                                                <td>{item.id_address.name}</td>
                                                <td>{item.id_machine.name}</td>
                                                <td>{item.number_index}</td>
                                                <td>{item.encode_finger===true?(
                                                    <span className={"badge bg-primary font11 rounded-0"}>
                                                    <i className="far fa-check-circle me-1" />
                                                    Đã nhận</span>
                                                ):(
                                                    <span className={"badge bg-danger font11 rounded-0"}>
                                                    <i className="far fa-minus-square me-1" />
                                                    Chưa nhận</span>
                                                )}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        ):(
                            <div className={"alert alert-danger"}>
                                Không có lịch sử người dùng
                            </div>
                        )
                    ):(
                        <div className={"col-12 d-flex justify-content-center align-self-center"}>
                            <ItemLoading />
                        </div>
                    )}



                </div>

                <div className={"col-12 pt-3"}>

                    <div className="col-12 col-sm-12 col-md-12"><p className="text-uppercase font-weight-bold font16">
                        Lịch sử hợp đồng
                    </p></div>

                    {this.state.HistoryUser!==null?(
                        Object.keys(this.state.HistoryUser).length>0?(
                            this.RenderHistoryContract()
                        ):(
                            <div className={"alert alert-danger"}>
                                Không có lịch sử người dùng
                            </div>
                        )
                    ):(
                        <div className={"col-12 d-flex justify-content-center align-self-center"}>
                            <ItemLoading />
                        </div>
                    )}



                </div>

                <div className={"col-12 mt-3"}>

                    <div className={"row"}>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-9"}>

                        </div>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-3"}>

                            <a onClick={()=>{
                                this.getInfoUser(this.state.user.user_login);
                            }} className={"w-100 p-3 btn btn-primary rounded-0"}>
                                <i className="fas fa-chevron-right"/> Tiếp theo
                            </a>

                        </div>
                    </div>

                </div>

            </div>
        );
    }

    Step3(){

        const self=this;

        var profile=self.props.AppThis.GetInfoProfile();

        return(
            <div className={"col-12 col-sm-12"}>

                <h2 className="d-inline-block mb-3 font18 mt-5 text-dark border-primary border-bottom pb-2 font-weight-bold">Bước
                    3 : Thông tin khách hàng </h2>
                {this.state.GetUser!==null?(
                    <div className={"row mt-4"}>

                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                            <p className={"m-0"}>Họ và tên : <span className={"text-red"}>( * )</span></p>
                            {_isset_string(this.state.GetUser.username) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                    <input type={"text"} defaultValue={this.state.GetUser.username?this.state.GetUser.username:this.state.GetUser.tmp_user} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                                </div>
                            ):(
                                <input type={"text"} onChange={(text)=>{
                                    this.UpdateItemData("username",text.target.value);
                                }} defaultValue={this.state.GetUser.username?this.state.GetUser.username:this.state.GetUser.tmp_user} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                            )}

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                            <p className={"m-0"}>Email : </p>


                            {_isset_string(this.state.GetUser.email) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                <input type={"email"} defaultValue={this.state.GetUser.email} className={"form-control rounded-0 w-100"} placeholder={"Email"} />
                                </div>
                            ):(
                                <input onChange={(text)=>{
                                    this.UpdateItemData("email",text.target.value);
                                }} type={"email"} defaultValue={this.state.GetUser.email} className={"form-control rounded-0 w-100"} placeholder={"Email"} />
                            )}

                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 mb-3 box_input"}>
                            <p className={"m-0"}>Sinh nhật <span className={"text-red"}>( * )</span></p>

                                <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat="dd/MM/yyyy" onChange={(date) => {
                                    if(date!==null){
                                            this.UpdateItemData('birthday',date.getTime()/1000);
                                    }
                                }} selected={this.state.user.birthday?new Date(this.state.user.birthday*1000):""} placeholderText={"Sinh nhật"} />
                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                            <p className={"m-0"}>Địa chỉ <span className={"text-red"}>( * )</span></p>

                            {_isset_string(this.state.GetUser.address_contact) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                <input type={"text"} defaultValue={this.state.GetUser.address_contact} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                                </div>
                            ):(
                                <input onChange={(text)=>{
                                    this.UpdateItemData("address_contact",text.target.value);
                                }} type={"text"} defaultValue={this.state.GetUser.address_contact} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                            )}

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                            <p className={"m-0"}>Số căn cước :

                                {this.state.data.type_target===0?(
                                    <span className={"text-red"}>( * )</span>
                                ):(<></>)}
                            </p>

                            {_isset_string(this.state.GetUser.citizen_identification) && profile.type!==1?(
                                <div className={"is-verify-data"}>
                                <input type={"text"} defaultValue={this.state.GetUser.citizen_identification} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />
                                </div>
                            ):(
                                <input onChange={(text)=>{
                                    this.UpdateItemData("citizen_identification",text.target.value);
                                }} type={"text"} defaultValue={this.state.GetUser.citizen_identification} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />
                            )}

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                            <p>Giới tính </p>

                            {this.state.user.sex && this.state.user.sex===1?(
                                <div className={"d-flex flex-row flex-wrap"}>
                                    <label className={"me-2"}><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",1);
                                    }} checked type={"radio"} /> Nam</label>
                                    <label><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",0);
                                    }} type={"radio"} /> Nữ</label>
                                </div>
                            ):(
                                <div className={"d-flex flex-row flex-wrap"}>
                                    <label className={"me-2"}><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",1);
                                    }} type={"radio"} /> Nam</label>
                                    <label><input name={"sex"} onChange={()=>{
                                            this.UpdateItemData("sex",0);
                                    }} type={"radio"} checked={true} /> Nữ</label>
                                </div>
                            )}

                        </div>

                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                        </div>


                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 offset-lg-6">
                            <div className="row mt-4">
                                <div className="col-12 col-sm-12 col-md-6">

                                </div>
                                <div className="col-12 col-sm-12 col-md-6">
                                    <a onClick={()=>{
                                        this.UpdateUser();
                                    }}
                                       className="font16 font-weight-bold text-uppercase btn btn-outline-primary rounded-0 w-100 btn-sm pt-3 pb-3">
                                        <i className="fas fa-angle-right me-2" /> Tiếp theo
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                ):(
                    <div className={"col-12 d-flex justify-content-center align-self-center"}>
                        <ItemLoading />
                    </div>
                )}

            </div>
        );

    }

    Step4(){
        const self=this;
        var profile=self.props.AppThis.GetInfoProfile();

        return(
            <div className={"col-12"}>


                {this.state.data.type_target===0?(
                    <h2 className="d-inline-block mb-3 font18 mt-5 text-dark border-primary border-bottom pb-2 font-weight-bold">
                        Bước 4 : Người liên lạc khẩn cấp
                    </h2>
                ):(
                    <h2 className="d-inline-block mb-3 font18 mt-5 text-dark border-primary border-bottom pb-2 font-weight-bold">
                        Bước 4 : Người đồng ký tên
                    </h2>
                )}

                {this.state.data.type_target===0?(
                    <div className={"col-12"}>

                        <div className={"row"}>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"}>

                                <h3 className={"font15"}>Người liên lạc khẩn cấp</h3>

                                <div className={"row"}>
                                    <div className={"col-12 col-sm-6 col-md-6 mb-3 box_input"}>

                                        <p className={"m-0"}>SDT/ID : <span className={"text-red"}>( * )</span></p>

                                        <InputMask key={"protection_user"} mask="9999999999" onChange={event=>{
                                            var input=event.target.value;
                                             input=input.replaceAll("_","");
                                            self.UpdateProtection(input);

                                        }} placeholder={"SDT/ID"} />

                                    </div>
                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                        <p className={"m-0"}>Họ và tên : <span className={"text-red"}>( * )</span></p>

                                        {self.state.TmpUser!==null && _isset_string(self.state.TmpUser.username) && profile.type!==1?(
                                            <div className={"is-verify-data"}>
                                                <input key={"username_protector"} type={"text"} defaultValue={self.state.Protection?self.state.Protection.username:''} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                                            </div>
                                        ):(
                                            <input key={"username_protector"} type={"text"} onChange={(text)=>{
                                                var Protection=self.state.Protection;
                                                Protection.username=text.target.value;
                                                this.setState({
                                                    Protection:Protection
                                                });
                                            }} defaultValue={self.state.TmpUser?self.state.TmpUser.username:''} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                                        )}

                                    </div>

                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                        <p className={"m-0"}>Địa chỉ : </p>

                                        {self.state.TmpUser!==null && _isset_string(self.state.TmpUser.address_contact) && profile.type!==1?(
                                           <div className={"is-verify-data"}>
                                               <input key={"protector_address_contact"} type={"text"} defaultValue={self.state.Protection?self.state.Protection.address_contact:''} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />

                                           </div>
                                        ):(
                                            <input key={"protector_address_contact"} type={"text"} onChange={(text)=>{
                                                var Protection=self.state.Protection;
                                                Protection.address_contact=text.target.value;
                                                this.setState({
                                                    Protection:Protection
                                                });
                                            }} defaultValue={self.state.TmpUser?self.state.TmpUser.address_contact:''} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                                        )}

                                    </div>



                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                        <p className={"m-0"}>Số căn cước : </p>
                                          {self.state.TmpUser!==null && _isset_string(self.state.TmpUser.citizen_identification) && profile.type!==1?(
                                              <div className={"is-verify-data"}>
                                                  <input key={"protector_citizen_identification"} type={"text"} defaultValue={self.state.Protection?self.state.Protection.citizen_identification:''} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />
                                              </div>
                                          ):(
                                              <input key={"protector_citizen_identification"} type={"text"} onChange={(text)=>{
                                                  var Protection=self.state.Protection;
                                                  Protection.citizen_identification=text.target.value;
                                                  this.setState({
                                                      Protection:Protection
                                                  });
                                              }} defaultValue={self.state.TmpUser?self.state.TmpUser.citizen_identification:''} className={"form-control rounded-0 w-100"} placeholder={"Số căn cước"} />

                                          )}


                                    </div>




                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>
                                        <p className={"m-0 mb-2"}>Giới tính</p>

                                        {this.state.TmpUser!==null && this.state.TmpUser.sex===1?(
                                            <div className={"d-flex flex-row flex-wrap"}>
                                                <label className={"me-2"}><input name={"protector_sex"} onChange={()=>{
                                                    var Protection=self.state.Protection;
                                                    Protection.sex=1;
                                                    this.setState({
                                                        Protection:Protection
                                                    });

                                                }} checked={true} type={"radio"} /> Nam</label>
                                                <label><input name={"protector_sex"} onChange={()=>{
                                                    var Protection=self.state.Protection;
                                                    Protection.sex=0;
                                                    this.setState({
                                                        Protection:Protection
                                                    });
                                                }} type={"radio"} /> Nữ</label>
                                            </div>
                                        ):(
                                            <div className={"d-flex flex-row flex-wrap"}>
                                                <label className={"me-2"}><input name={"protector_sex"} onChange={()=>{

                                                    var Protection=self.state.Protection;
                                                    Protection.sex=1;
                                                    this.setState({
                                                        Protection:Protection
                                                    });


                                                }} type={"radio"} /> Nam</label>
                                                <label><input name={"protector_sex"} onChange={()=>{
                                                    var Protection=self.state.Protection;
                                                    Protection.sex=0;
                                                    this.setState({
                                                        Protection:Protection
                                                    });

                                                }} type={"radio"} checked={true} /> Nữ</label>
                                            </div>
                                        )}
                                    </div>


                                    <div className={"col-12 col-sm-6 col-md-6 mb-3 Box-Select-Mutiple"}>
                                        <p className={"mb-1"}>Mối quan hệ <span className={"text-red"}>( * )</span></p>
                                        {this.state.Relationship!==null?(
                                            Object.keys(self.state.Relationship).length>0?(
                                                <select onChange={(event)=>{

                                                    var Protection=this.state.Protection;

                                                    Protection.relationship={
                                                        _id:event.target.value,
                                                        name:event.target.innerText
                                                    };

                                                    this.setState({
                                                        Protection:Protection
                                                    });


                                                }} className={"form-control font14 w-100 rounded-0"}>
                                                    <option value={null}>Vui lòng chọn</option>

                                                    {self.state.Relationship.map(function(value,key){
                                                        if(self.state.Protection.relationship!==null){
                                                            if(typeof self.state.Protection.relationship['_id']!=='undefined' && self.state.Protection.relationship['_id']===value['_id']){
                                                                return(
                                                                    <option selected={true} value={value['_id']}>{value['name']}</option>
                                                                );
                                                            }
                                                            else{
                                                                return(
                                                                    <option value={value['_id']}>{value['name']}</option>
                                                                );
                                                            }
                                                        }
                                                        else{
                                                            return(
                                                                <option value={value['_id']}>{value['name']}</option>
                                                            );
                                                        }


                                                    })}

                                                </select>

                                            ):(
                                                <></>
                                            )
                                        ):(
                                            <ItemLoading />
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                ):(
                    <div className={"col-12"}>

                        <div className={"row"}>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"}>

                                <h3 className={"font15"}>Người đồng ký tên</h3>

                                <div className={"row"}>
                                    <div className={"col-12 col-sm-6 col-md-6 mb-3 box_input"}>

                                        <p className={"m-0"}>người đồng ký tên : <span className={"text-red"}>( * )</span></p>

                                        <InputMask key={"co_signer_user"} mask="9999999999" onChange={event=>{
                                            var input=event.target.value;
                                            input=input.replaceAll("_","");
                                            self.UpdateCoSigner(input);

                                        }} placeholder={"Số điện thoại"} />

                                    </div>
                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                        <p className={"m-0"}>Họ và tên : <span className={"text-red"}>( * )</span></p>

                                        {self.state.TmpUser!==null && _isset_string(this.state.TmpUser.username) && profile.type!==1?(
                                            <div className={"is-verify-data"}>
                                                <input key={"co_signer_username"} type={"text"} defaultValue={self.state.CoSigner?self.state.CoSigner.username:''} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />
                                            </div>
                                                ):(
                                            <input key={"co_signer_username"} type={"text"} onChange={(text)=>{
                                                var CoSigner=this.state.CoSigner;
                                                CoSigner.username=text.target.value;
                                                this.setState({
                                                    CoSigner:CoSigner
                                                });

                                            }} defaultValue={self.state.TmpUser?self.state.TmpUser.username:''} className={"form-control rounded-0 w-100"} placeholder={"Họ và tên"} />

                                        )}


                                    </div>


                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                        <p className={"m-0"}>Địa chỉ : </p>


                                        {self.state.TmpUser!==null && _isset_string(this.state.TmpUser.address_contact) && profile.type!==1?(
                                            <div className={"is-verify-data"}>
                                                <input key={"co_signer_address_contact"} type={"text"} defaultValue={self.state.CoSigner?self.state.CoSigner.address_contact:''} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />
                                            </div>
                                        ):(
                                            <input key={"co_signer_address_contact"} type={"text"} onChange={(text)=>{

                                                var CoSigner=this.state.CoSigner;

                                                CoSigner.address_contact=text.target.value;

                                                this.setState({
                                                    CoSigner:CoSigner
                                                });

                                            }} defaultValue={self.state.TmpUser?self.state.TmpUser.address_contact:''} className={"form-control rounded-0 w-100"} placeholder={"Địa chỉ"} />

                                        )}

                                    </div>


                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>

                                        <p className={"m-0"}>Số căn cước : </p>

                                        {self.state.TmpUser!==null && _isset_string(this.state.TmpUser.citizen_identification) && profile.type!==1?(
                                            <div className={"is-verify-data"}>
                                                <input key={"co_signer_citizen_identification"} type={"text"} defaultValue={self.state.CoSigner?self.state.CoSigner.citizen_identification:''} className={"form-control rounded-0 w-100"} placeholder={"Căn cước"} />
                                            </div>
                                        ):(
                                            <input key={"co_signer_citizen_identification"} type={"text"} onChange={(text)=>{

                                                var CoSigner=this.state.CoSigner;

                                                CoSigner.citizen_identification=text.target.value;

                                                this.setState({
                                                    CoSigner:CoSigner
                                                });

                                            }} defaultValue={self.state.TmpUser!==null?self.state.TmpUser.citizen_identification:''} className={"form-control rounded-0 w-100"} placeholder={"Căn cước"} />
                                        )}


                                    </div>



                                    <div className={"col-12 col-sm-6 col-md-6 mb-3"}>
                                        <p className={"m-0 mb-2"}>Giới tính</p>

                                        {this.state.TmpUser!==null && this.state.TmpUser.sex===1?(
                                            <div className={"d-flex flex-row flex-wrap"}>
                                                <label className={"me-2"}><input name={"co_signer_sex"} onClick={()=>{

                                                    var CoSigner=this.state.CoSigner;
                                                    CoSigner.sex=1;
                                                    this.setState({
                                                        CoSigner:CoSigner
                                                    });

                                                }} checked={true} type={"radio"} /> Nam</label>
                                                <label><input name={"co_signer_sex"} onClick={()=>{
                                                    var CoSigner=this.state.CoSigner;
                                                    CoSigner.sex=0;
                                                    this.setState({
                                                        CoSigner:CoSigner
                                                    });
                                                }} type={"radio"} /> Nữ</label>
                                            </div>
                                        ):(
                                            <div className={"d-flex flex-row flex-wrap"}>
                                                <label className={"me-2"}><input name={"co_signer_sex"} onClick={()=>{
                                                    var CoSigner=this.state.CoSigner;
                                                    CoSigner.sex=1;
                                                    this.setState({
                                                        CoSigner:CoSigner
                                                    });
                                                }} type={"radio"} /> Nam</label>
                                                <label><input name={"co_signer_sex"} onClick={()=>{
                                                    var CoSigner=this.state.CoSigner;
                                                    CoSigner.sex=0;
                                                    this.setState({
                                                        CoSigner:CoSigner
                                                    });
                                                }} type={"radio"} checked={true} /> Nữ</label>
                                            </div>
                                        )}

                                    </div>
                                    <div className={"col-12 col-sm-6 col-md-6 mb-3 Box-Select-Mutiple"}>
                                        <p className={"mb-1"}>Mối quan hệ <span className={"text-red"}>( * )</span></p>

                                        {this.state.Relationship!==null?(
                                            Object.keys(self.state.Relationship).length>0?(
                                                <select onChange={(event)=>{

                                                    var CoSigner=this.state.CoSigner;

                                                    CoSigner.relationship={
                                                        _id:event.target.value,
                                                        name:event.target.innerText
                                                    };

                                                    this.setState({
                                                        CoSigner:CoSigner
                                                    });


                                                }} className={"form-control font14 w-100 rounded-0"}>
                                                    <option value={null}>Vui lòng chọn</option>
                                                    {self.state.Relationship.map(function(value,key){
                                                        if(self.state.CoSigner.relationship!==null){
                                                            if(typeof self.state.CoSigner.relationship['_id']!=='undefined' && self.state.CoSigner.relationship['_id']===value['_id']){
                                                                return(
                                                                    <option selected={true} value={value['_id']}>{value['name']}</option>
                                                                );
                                                            }
                                                            else{
                                                                return(
                                                                    <option value={value['_id']}>{value['name']}</option>
                                                                );
                                                            }
                                                        }
                                                        else{
                                                            return(
                                                                <option value={value['_id']}>{value['name']}</option>
                                                            );
                                                        }

                                                    })}

                                                </select>
                                            ):(
                                                <></>
                                            )
                                        ):(
                                            <ItemLoading />
                                        )}

                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                )}


                <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">
                    <div className="row mt-4">
                        <div className="col-12 col-sm-12 col-md-6"></div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <a onClick={()=>{
                                this.VerifyProtectorCoSigner();
                            }}
                               className="font16 font-weight-bold text-uppercase btn btn-outline-primary rounded-0 w-100 btn-sm pt-3 pb-3">
                                <i className="fas fa-angle-right me-2" /> Tiếp theo
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    Step5(){

        const self=this;
        return(
            <div className={"col-12"}>

                <h2 className="d-inline-block mb-2 font18 mt-5 text-dark border-primary border-bottom pb-2 font-weight-bold">
                    Bước 5 : Thông tin gói tập
                </h2>

                {this.state.type_service===0 && this.state.DataSaleProgram.status===true?(
                    <>
                        {this.RenderSaleProgram()}
                    </>
                ):(
                    <>
                        {this.RenderCustomNewContract()}
                    </>
                )}

                {this.state.ListContract.length>0?(
                    self.getListContractAdd()
                ):(
                    <></>
                )}

                {this.state.ChangeCustomPrice.status===true?(
                    <div className="modal d-block position-fixed modal-dialog-end">
                        <div className={"modal-dialog modal-md"}>
                            <div className="modal-content">
                                <div className="modal-header bg-danger">
                                    <h5 className="modal-title">Cập nhật giá thực tế bán</h5>
                                </div>
                                <div className="modal-body">

                                    <div className={"alert alert-warning rounded-0 w-100"}>
                                        <i className="fas fa-exclamation-triangle"></i> Giá bán không được nhỏ hơn giá tối thiểu
                                    </div>

                                    <input value={input_price(this.state.ChangeCustomPrice.number)} type={"text"} onChange={(event)=>{

                                        try{
                                            var ChangeCustomPrice=this.state.ChangeCustomPrice;
                                            ChangeCustomPrice.number=remove_input_price(event.target.value);
                                            this.setState({
                                                ChangeCustomPrice:ChangeCustomPrice
                                            });
                                        }
                                        catch (e){

                                        }

                                    }} placeholder={"Gía thực tế"} className={"form-control w-100 rounded-0"} />

                                    <div className={"row mt-3"}>

                                        <div className={"col-12 col-sm-12 col-md-6"}>

                                            <button type="button" onClick={()=>{
                                                this.VerifyMinCustomPrice();
                                            }} className="btn rounded-0 w-100 btn-primary">
                                                Cập nhật
                                            </button>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-md-6"}>

                                            <button onClick={()=>{
                                                this.setState({
                                                    ChangeCustomPrice:{
                                                        status:false,
                                                        number:''
                                                    }
                                                });
                                            }} type="button" className="btn rounded-0 w-100 btn-secondary">
                                                Huỷ
                                            </button>
                                        </div>

                                    </div>

                                </div>



                            </div>
                        </div>
                        <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                    </div>
                ):(<></>)}


                {this.state.DiscountContract.InfoDiscount!==null?(
                    <InfoDiscount data={this.state.DiscountContract.InfoDiscount} onClose={()=>{
                        this.setState({
                            DiscountContract:{
                                InputDiscount:null,
                                InfoDiscount:null
                            }
                        });
                    }} SubmitModal={(data)=>{
                    var getdata=this.state.data;
                    var id_discount=getdata.id_discount;
                    id_discount.push(data);
                    getdata.id_discount=id_discount;
                     this.setState({
                        data:getdata,
                        DiscountContract:{
                            InputDiscount:null,
                            InfoDiscount:null
                        }
                    },function(){
                        self.AutoCalculator();
                    });

                    }} />
                ):(
                    <></>
                )}


            </div>
        );

    }


    RenderCustomNewContract(){
        const self=this;
        return(
            <>
                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>

                        <div className={"row"}>
                            {this.state.type_service===0?(
                                <>
                                    <div className={"col-12 col-sm-6 col-md-6 mb-3 customCssSelect pb-2"}>
                                        <p className={"mb-1"}>Loại HD</p>

                                        <Select isSearchable={true} onChange={(selectedOption)=>{
                                            var ListCard=this.state.ListCard;
                                            ListCard.type=selectedOption.value;
                                            var data=this.state.data;
                                            data.id_card=null;
                                            this.setState({
                                                ListCard:ListCard,
                                                data:data
                                            },function(){
                                                self.getCard();
                                            });

                                        }} options={[
                                            {
                                                label:"Tất cả",
                                                value:null
                                            },
                                            {
                                                label:"HD PT",
                                                value:1
                                            },
                                            {
                                                label:"HD Member",
                                                value:2
                                            },
                                            {
                                                label:"HD lớp nhóm Linh Hoạt",
                                                value:3
                                            },
                                            {
                                                label:"HD lớp nhóm cố định",
                                                value:4
                                            }
                                        ]} />

                                    </div>
                                </>
                            ):(
                                <></>
                            )}

                            <div className={"col-12 col-sm-6 col-md-6 mb-3 Box-Select-Card customCssSelect pb-2 position-relative"}>
                                <p className={"mb-1"}>Gói thẻ <span className={"text-red"}>( * )</span></p>

                                {this.state.ListCard.data!==null?(

                                    <Select menuPosition={"bottom"} menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{

                                        if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                            var data=this.state.data;
                                            data.id_card=selectedOption.value;
                                            data.group_member_statics=null;
                                            data.id_user_sub = [];
                                            data.id_admin_pt = null;
                                            this.setState({
                                                data:data,
                                                Loadding:true,
                                                tmp_price:{
                                                    price:0,
                                                    vat:0,
                                                    insurance:0,
                                                    price_discount:null
                                                }
                                            },function(){
                                                self.DetailCard(selectedOption.value);
                                            });
                                        }
                                    }} ref={this.SelectCard} options={array_to_select(this.state.ListCard.data, "name","_id")} />


                                ):(
                                    <ItemLoading />
                                )}


                            </div>
                        </div>


                        {this.state.type_service===0?(
                            <>
                                {this.state.ListCard.DetailCard!==null && typeof this.state.ListCard.DetailCard.note!=='undefined' && this.state.ListCard.DetailCard.note.length>0?(<>
                                    <div className={"alert alert-info rounded-0 mt-1 mb-2"}>
                                        <i className="fas fa-exclamation-triangle" /> {this.state.ListCard.DetailCard.note}
                                    </div>
                                </>):(<></>)}

                            </>
                        ):(
                            <></>
                        )}

                        {this.RenderExtractContract()}



                    </div>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>

                        {this.state.ListCard.DetailCard!==null?(
                            <div className={"col-12 p-0"}>
                                {self.RenderActionCard()}
                                {self.NumberPeople()}
                            </div>
                        ):(<></>)}



                        {this.state.type_service===0?(
                            <>
                                {this.RenderGroupMember()}
                            </>
                        ):(
                            <></>
                        )}



                        <div className="col-12 border-top mt-4 pt-3"></div>

                        <p className={"font-weight-bold p16 text-uppercase d-block w-100 mb-2 font16 pb-2"}>MÃ GIẢM GIÁ</p>

                        <div className={"alert alert-warning w-100 rounded-0"}>
                            <i className="fas fa-exclamation-triangle" /> bạn có thể nhập nhiều mã giảm giá tại đây
                        </div>

                        <div className={"col-12 mb-3"}>
                            {this.state.data.id_discount.length>0?(
                                this.state.data.id_discount.map(function(value,key){
                                    if(value!==null && typeof value!=='undefined' && typeof value.code!=='undefined'){
                                        return(
                                            <a href={"#"} onClick={()=>{
                                                var update_data=self.state.data;
                                                var get_id_discount=update_data.id_discount;
                                                delete get_id_discount[key];
                                                update_data.id_discount=filter_args(get_id_discount);
                                                self.setState({
                                                    data:update_data
                                                },function(){
                                                    self.AutoCalculator();
                                                });
                                            }} className={"m-1 badge bg-primary text-white rounded-0 font12"}>
                                                {value.code}
                                                <i className="far font12 fa-trash-alt ms-2"></i>
                                            </a>
                                        );
                                    }
                                    else{
                                        return(
                                            <></>
                                        );
                                    }

                                })
                            ):(<></>)}
                        </div>

                        {this.state.data.id_discount.length>0?(
                            <div className={"alert alert-danger rounded-0"}>
                                <i className="fas fa-exclamation-triangle me-1"></i> Vui lòng kiểm tra mã giảm giá phù hợp với thẻ tập
                            </div>
                        ):(<></>)}

                        <div className="d-flex align-self-center mt-2 flex-wrap flex-row position-relative">
                            <input type="text" onChange={(event)=>{
                                var DiscountContract=this.state.DiscountContract;
                                DiscountContract.InputDiscount=event.target.value;
                                this.setState({
                                    DiscountContract:DiscountContract
                                });
                            }} value={this.state.DiscountContract.InputDiscount} ref={this.InputCode} className="form-control" placeholder="Mã KM" />
                            <a onClick={()=>{
                                this.ValidationDisCount();
                            }} className={"btn h-100 btn-primary rounded-0 position-absolute right-0 top-0"}>
                                Thêm mã KM
                            </a>
                        </div>



                        <div className={"row"}>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-8 mt-4"}>
                                <div className="col-12 p-0 text-uppercase font-weight-bold font15 mb-2 ps-3 p-2">
                            <span className="mr-3">
                           Giá bán : </span>

                                    <span className={"text-red"}>
                                         <span>{formatNumber(this.state.tmp_price.price)}</span> VNĐ
                                    </span>
                                </div>

                                {this.state.tmp_price.price_discount!==null?(
                                    <div className={"col-12 p-0  ps-3 p-2 d-flex bg-light flex-row flex-wrap align-self-center font16 text-uppercase font-weight-bold"}>
                                        <span>Giá thực tế : </span>
                                        <span className={"text-red"}>
                                        <span className={"ms-2 me-2"}>{formatNumber(this.state.tmp_price.price_discount)}</span> VNĐ
                                        </span>
                                    </div>
                                ):(
                                    <div className={"col-12 p-0 ps-3 p-2 d-flex flex-row bg-light flex-wrap align-self-center font16 text-uppercase font-weight-bold"}>
                                        <span>Giá thực tế : </span>

                                        {this.state.data.custom_price!==0?(
                                            <>
                                                <span className={"text-red"}>
                                                    <span className={"ms-2 me-2"}>{formatNumber(this.state.data.custom_price)}</span> VNĐ
                                                </span>
                                            </>
                                        ):(
                                            <>
                                                <span className={"text-red"}>
                                                     <span className={"ms-2 me-2"}>
                                        {formatNumber(this.state.tmp_price.price)}
                                    </span> VNĐ
                                                </span>
                                            </>
                                        )}


                                        <a className={"ms-2 text-primary font12 mt-2"} onClick={()=>{
                                            this.setState({
                                                ChangeCustomPrice:{
                                                    status:true,
                                                    number:''
                                                }
                                            });
                                        }}>
                                            Thay đổi
                                        </a>

                                    </div>

                                )}



                                <div className={"col-12 p-0 mt-3"}>
                                </div>


                                {this.state.type_service===0?(
                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12"}>

                                        <table className="table min-width-auto">
                                            <tbody>
                                            <tr>
                                                <th scope="row"><i className="fas fa-percent me-1"></i>   VAT</th>
                                                <td>{formatNumber(this.state.tmp_price.vat)} VNĐ <span className={"ms-2 font10"}>( Đã bao gồm )</span></td>
                                            </tr>

                                            <tr>
                                                <td>  <i className="fas fa-university me-1"></i> Phí bảo hiểm</td>
                                                <td>
                                                    {formatNumber(this.state.tmp_price.insurance)} VNĐ <span className={"ms-2 font10"}>( Đã bao gồm )</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>



                                    </div>
                                ):(
                                    <></>
                                )}

                            </div>

                        </div>


                    </div>

                </div>


                <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">
                    <div className="row mt-4">
                        <div className="col-12 col-sm-12 col-md-6"></div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <a onClick={()=>{
                                this.VerifySubmitContract();
                            }}
                               className="font16 font-weight-bold text-uppercase btn btn-outline-primary rounded-0 w-100 btn-sm pt-3 pb-3">
                                <i className="fas fa-angle-right me-2" /> Hoàn tất
                            </a>
                        </div>
                    </div>
                </div>

            </>
        );
    }

    setDataProgram(item){

        var data=this.state.data;

        if(item===null){
            data['id_saleprogram']=null;
        }
        else{
            data['id_saleprogram']=item._id;
        }

        var DataSaleProgram=this.state.DataSaleProgram;

        DataSaleProgram.data=item;

        this.setState({
            data:data,
            DataSaleProgram:DataSaleProgram
        });
    }

    async EventNextSale(){

        var data=this.state.data;

        if(data.id_saleprogram===null){

            this.getCard();

            this.setState({
                DataSaleProgram:{
                    status:false,
                    data:null
                }
            });
        }
        else{

            this.setState({
                StatusRenderSaleProgram:false
            });

            await this.getCard();

            var DataSaleProgram=this.state.DataSaleProgram.data;

            await this.DetailCard(DataSaleProgram.id_card._id);

        }
    }

    RenderSaleProgram(){

        const self=this;

        if(this.state.AllSaleProgram!==null){

            if(this.state.StatusRenderSaleProgram===true){
                return(
                    <div className={"col-12 mb-4"}>

                        <p className={"mb-4"}><em>Chương trình khuyến mãi</em></p>

                        {this.state.AllSaleProgram.length===0?(
                            <div className={"alert alert-danger"}>
                                <i className="fas fa-exclamation-triangle me-1" />  Không có chương trình KM nào
                            </div>
                        ):(
                            <div className={"row"}>

                                {LIST_TYPE_SALE.map(function(index){
                                    return(
                                        <>

                                            <div className={"col-12"}>
                                                <div className={"col-12 pb-2 ps-4 border-start border-3 border-success mt-3 bg-light font-weight-bold font14 mb-3 text-uppercase pt-2"}>
                                                    {index.title}
                                                </div>
                                            </div>


                                            {getItemInArray(index.type,"type",self.state.AllSaleProgram,true).length===0?(
                                                <div className={"col-12"}>
                                                    <div className={"alert alert-danger rounded-0"}>
                                                        <i className="fas fa-exclamation-triangle me-2" /> Không có chương trình khuyến mãi nào
                                                    </div>
                                                </div>
                                            ):(
                                                <>
                                                    {getItemInArray(index.type,"type",self.state.AllSaleProgram,true).map(function(item){
                                                        return(
                                                            <div onClick={()=>{
                                                                self.setDataProgram(item);
                                                            }} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3">
                                                                <div className={self.state.data.id_saleprogram===item._id?"col-12 border position-relative active_tab_box":"col-12 border position-relative"}>
                                                                    <div className="col-12 p-2 font-weight-bold border-bottom text-center font15 text-uppercase left-box-info-bank">
                                                                        <p className="m-0">
                                                                            <i className="fab fa-cc-amazon-pay"></i> {item.name}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-12 p-3 right-box-info-bank">
                                                                        <p className="m-0 mb-1">
                                                                            <i className="fas fa-bell" /> Tên thẻ : {item.id_card.name}
                                                                        </p>

                                                                        {item.note?(
                                                                            <p className="m-0">
                                                                                <i className="far fa-comment-alt" /> Ghi chú : {item.note}
                                                                            </p>
                                                                        ):(
                                                                            <></>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}



                                        </>
                                    );
                                })}


                                <div className={"col-12 mt-3"}>
                                    <div className={"col-12 pb-2 ps-4 border-start border-3 border-success mt-3 bg-light font-weight-bold font14 mb-3 text-uppercase pt-2"}>
                                        Không chọn chương trình bán hàng
                                    </div>
                                </div>


                                <div onClick={()=>{
                                    self.setDataProgram(null);
                                }} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                                    <div className={self.state.data.id_saleprogram===null?"col-12 border pt-4 pb-4 position-relative active_tab_box h-100 d-flex flex-row justify-content-center align-items-center":"col-12 border position-relative h-100 d-flex flex-row justify-content-center align-items-center pt-4 pb-4"}>
                                        <div className={"col-12 text-center"}>
                                            <p className="m-0 mt-1">
                                                <i className="fas fa-trash me-2" />
                                                Không chọn
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )}


                        <div className={"col-12 col-sm-12 offset-lg-9 col-lg-3 d-flex flex-wrap flex-row justify-content-end align-items-end"}>

                            <div className="col-12 p-0 text-uppercase text-end font-weight-bold font15 mb-2 ps-3 p-2">
                                {this.state.DataSaleProgram.data!==null?(
                                    <>
                              <span className="mr-3">
                           Giá bán : </span>

                                        <span className={"text-red"}>
                                         <span>{formatNumber(this.state.DataSaleProgram.data.price)}</span> VNĐ
                                    </span>
                                    </>

                                ):(
                                    <></>
                                )}
                            </div>

                            <a onClick={()=>{
                                self.EventNextSale();
                            }}
                               className="font16 font-weight-bold text-uppercase btn btn-danger rounded-0 w-100 btn-sm pt-3 pb-3">
                                <i className="fas fa-angle-right me-2" /> Tiếp theo
                            </a>


                        </div>

                    </div>
                );
            }
            else{

                if(this.state.ListCard.DetailCard===null){

                    return(
                        <div className={"col-12 d-flex flex-row mt-5 mb-5 justify-content-center align-items-center"}>
                            <ItemLoading size={"Large"} />
                        </div>
                    );
                }
                else{
                    return(
                        <div className={"col-12 mb-4"}>

                            <p className={"mb-4"}><em>Chi tiết gói khuyến mãi</em></p>

                            <div className={"row"}>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    {this.RenderExtractContract()}
                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>

                                    {this.state.ListCard.DetailCard!==null?(
                                        <>
                                            {self.RenderActionCard()}
                                            {self.NumberPeople()}
                                        </>
                                    ):(<></>)}

                                    <div className={"row"}>
                                        {this.RenderGroupMember()}
                                    </div>

                                    <div className="col-12 border-top mt-4 pt-3"></div>
                                </div>


                            </div>


                            <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">
                                <div className="row mt-4">
                                    <div className="col-12 col-sm-12 col-md-6"></div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <a onClick={()=>{
                                            this.VerifySubmitContract();
                                        }}
                                           className="font16 font-weight-bold text-uppercase btn btn-outline-primary rounded-0 w-100 btn-sm pt-3 pb-3">
                                            <i className="fas fa-angle-right me-2" /> Hoàn tất
                                        </a>
                                    </div>
                                </div>
                            </div>




                        </div>
                    );
                }


            }


        }
        else{

            return(
                <div className={"col-12 d-flex flex-row mt-5 mb-5 justify-content-center align-items-center"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


    RenderGroupMember(){

        const self=this;

        return(
            <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                <p className={"mb-1 mt-2"}>Nhóm HD doanh nghiệp</p>

                {self.state.GroupMember!==null?(

                    <Select isSearchable={true} onChange={(selectedOption)=>{

                        var data=self.state.data;
                        data.group_member=selectedOption.value;
                        self.setState({
                            data:data
                        });

                    }} options={array_to_select(self.state.GroupMember,"name","_id")}
                            components={{
                                Option:(props)=>self.OptionLayoutGroupMember(props,self.state.GroupMember)
                            }}
                    />

                ):(
                    <ItemLoading />
                )}

            </div>
        );
    }

    RenderExtractContract(){

        const self=this;
        return(
            <>

                {this.state.type_service===0?(
                    <div className={"row"}>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                            <p className={"mb-1"}>Bộ môn <span className={"text-red"}>( * )</span></p>

                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                var get_data=self.state.data;
                                get_data['id_subject']=get_data_in_args(selectedOption,"value");
                                self.setState({
                                    data:get_data
                                });

                            }} isMulti={true} options={array_to_select(this.state.Subject,'name','_id')} />

                        </div>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                            <p className={"mb-1"}>Mục tiêu tập luyện <span className={"text-red"}>( * )</span></p>

                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                var get_data=self.state.data;


                                get_data.id_requires=get_data_in_args(selectedOption,"value");

                                self.setState({
                                    data:get_data
                                });

                            }} isMulti={true} options={array_to_select(this.state.Required,'name','_id')} />

                        </div>
                    </div>
                ):(
                    <></>
                )}


                <div className={"row"}>
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                        <p className={"mb-1"}>Ngày bắt đầu <span className={"text-red"}>( * )</span></p>

                        <DatePicker  dateFormat="dd/MM/yyyy" selected={new Date(this.state.data.start_day*1000)} onChange={(date) => {
                            if(date!==null){

                                var data=self.state.data;

                                data.start_day=Math.ceil(date.getTime()/1000);

                                self.setState({
                                    data:data
                                },function(){
                                    self.AutoCalculator();
                                });

                            }

                        }} placeholderText={"Ngày bắt đầu"} />

                    </div>

                    {this.state.type_service===0?(
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                            <p className={"mb-1"}>Nguồn HD <span className={"text-red"}>( * )</span></p>

                            <Select isSearchable={true} onChange={(selectedOption)=>{

                                if(selectedOption!==null && typeof selectedOption.value!=='undefined'){
                                    var data=this.state.data;
                                    data['source_contract']=selectedOption.value;
                                    this.setState({
                                        data:data
                                    });
                                }

                            }} options={array_to_select(this.state.Source,'name','_id')} />

                        </div>
                    ):(
                        <>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                            <p className={"mb-1"}>Số tủ <span className={"text-red"}>( * )</span></p>

                            <input onChange={(event)=>{
                                var data=this.state.data;
                                data.number_coffer=event.target.value;
                                this.setState({
                                    data:data
                                });
                            }} type={"text"} className={"form-control w-100 rounded-0"} placeholder={"Số tủ"} />

                        </div>
                        </>
                    )}

                </div>

                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 customCssSelect pb-2"}>
                        <p className={"mb-1"}>Ghi chú gói tập </p>
                        <input onChange={(event)=>{
                            var data=this.state.data;
                            data.note_member=event.target.value;
                            this.setState({
                                data:data
                            });
                        }} type={"text"} className={"form-control w-100 rounded-0"} placeholder={"Ghi chú gói tập"} />
                    </div>


                </div>
            </>
        );
    }
}
