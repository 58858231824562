/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
    base64_encode,
    array_to_select,
    formatNumber,
    getItemInArray,
    convertArrayToSingle,
    NextDay,
    NextMonth,
    between_two_dates,
    filter_args,
    input_price,
    remove_input_price,
    get_data_in_args,
    timeConverterDate,
    get_name_day,
    randomNumber,
    _isset_object,
    remove_empty_array, get_item_in_array
} from "../../helper/array";
import lodash from "lodash";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import Select, { components } from 'react-select';
import ModalUser from "../user/ModalUser";
import BoxContract from "./BoxContract";
import {number_did_contract, status_contract, status_payment} from "../../helper/member";
import DetailContract from "./DetailContract";
import InfoDiscount from "../discount/InfoDiscount";
import {confirmAlert} from "react-confirm-alert";

const LIST_TYPE_SALE=[
    {
        type:1,
        title:"Thẻ PT"
    },
    {
        type:2,
        title:"Thẻ Member"
    },
    {
        type:3,
        title:"Thẻ Lớp nhóm linh hoạt"
    },
    {
        type:4,
        title:"Thẻ Lớp nhóm cố định"
    }
];

const INIT_STATE={
    InfoContract:null,
    data:{
        number_card:0,
        id_requires:[],
        id_subject:[],
        group_member_statics:null,
        group_member:null,
        id_card:null,
        id_user:null,
        id_user_sub:null,
        id_discount:[],
        protector:null,
        contact_person:null,
        start_day:Math.ceil(new Date().getTime()/1000),
        custom_price:0,
        id_admin_pt:null,
        source_contract:null,
        type_target:0,
        end_day_contract:null,
        collection_date:Math.ceil(new Date().getTime()/1000),
        note_member:null,
        id_saleprogram:null,
        number_coffer:null
    },
    user:{
        user_login:null
    },
    DetailSubUser:null,
    tmp_price:{
        price:0,
        vat:0,
        insurance:0,
        price_discount:null
    },
    number_vat:0,
    number_insurance:0,
    calculator:1,
    number_calculator:0,
    page:0,
    Required:null,
    Card:null,
    Group:null,
    Source:null,
    Subject:null,
    Relationship:null,
    CoSigner:{
        _id:null,
        user_login:null,
        relationship:null
    },
    Protection:{
        _id:null,
        user_login:null,
        relationship:null
    },
    ListCard:{
        data:null,
        type:null,
        DetailCard:null,
    },
    GroupStatics:null,
    GroupMember:null,
    Loadding:false,
    InfoUser:{
        status:false,
        data:{}
    },
    ChangeCustomPrice:{
        status:false,
        number:''
    },
    ListAdmin:null,
    type_upgrade:1,
    HistoryUser:null,
    DataDetailContract:null,
    ListContractUpgrade:[],
    DiscountContract:{
        InputDiscount:null,
        InfoDiscount:null
    },
    isMutiple:false,
    DataSaleProgram:{
        status:true,
        data:null
    },
    StatusRenderSaleProgram:true,
    AllSaleProgram:null
};


export default class UpgradeContract extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
        this.SelectCard=React.createRef();
        this.InputCode=React.createRef();
    }


    componentDidMount() {


        const self=this;

        this.getRequired();

        this.getSubject();

        this.getSource();

        this.GetGroupStatics();

        this.GetGroupMember();

        this.getPublicOption();

        this.getAdmin();

        if(this.props.InfoContract!==null){

            var InfoContract=this.props.InfoContract;

            this.getSaleProgram();

            var data=this.state.data;

            data['id_user']=InfoContract['id_user']['_id'];

            this.setState({
                InfoContract:InfoContract,
                data:data
            },function(){
                self.getCard();
            });

        }

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    getSaleProgram(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"getsale", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    AllSaleProgram:data.data
                });
            }
            else{
                self.setState({
                    AllSaleProgram:[]
                });
            }


        }).catch((e)=>{
            self.setState({
                AllSaleProgram:[]
            });
        });


    }

    GetGroupStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());

        fetch(Appthis.ConstApp.home_api+"groupstatics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                this.setState({
                    GroupStatics:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    GetGroupMember(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/group", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{


            if(data.status==='ok'){

                var GroupMember=[
                    {
                        _id:null,
                        name:"Không"
                    }
                ];

                GroupMember=GroupMember.concat(data.data);

                self.setState({
                    GroupMember:GroupMember
                });

            }


        }).catch((e)=>{

        });


    }

    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListAdmin:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    async getPublicOption(){

        var AppThis=this.props.AppThis;

        var get_vat_options=await AppThis.getOption("number_vat");

        var get_vat=Math.ceil(parseInt(get_vat_options));

        var number_insurance_options=await AppThis.getOption("number_insurance");

        var number_insurance=Math.ceil(parseInt(number_insurance_options));

        this.setState({
            number_insurance:number_insurance,
            number_vat:get_vat
        });


    }

    getRequired(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"requires", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Required:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getSubject(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"subject", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Subject:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getSource(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('type',2);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"source", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Source:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    getCard(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        if(this.state.ListCard.type!==null){
            formData.append('type', this.state.ListCard.type);
        }

        if(this.state.InfoContract.id_card.type===5){
            formData.append('type', 5);
        }

        const self=this;

        fetch(Appthis.ConstApp.home_api+"card", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var ListCard=this.state.ListCard;
                ListCard.data=data.data;
                ListCard['DetailCard']=null;

                var tmp_price={
                    price:0,
                    vat:0,
                    insurance:0,
                    price_discount:null
                };

                var UpdateData=self.state.data;
                UpdateData.custom_price=0;
                UpdateData.end_day_contract=null;
                UpdateData.id_user_sub=null;

                self.setState({
                    ListCard:ListCard,
                    tmp_price:tmp_price,
                    data:UpdateData,
                    DetailSubUser:null,
                },function(){

                    if(self.SelectCard.current!==null && typeof self.SelectCard.current!=='undefined'){
                        self.SelectCard.current.clearValue();
                    }

                });

            }


        }).catch((e)=>{

        });


    }

    async fetchUser(phone,loading=false){
        var Appthis=this.props.AppThis;

        if(loading===true){
            Appthis.PageLoad(true);
        }
        const formData = new FormData();
        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());
        formData.append('user_login', phone);

        var data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/infouser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        data=await data.json();

        if(loading===true){
            Appthis.PageLoad(false);
        }

        return data;

    }

    async UpdateSubUser(data){

        var number_error=0;

        if(data.user_login===null || typeof data.user_login==='undefined' || data.user_login.length<10){
            number_error+=1;
        }

        if(number_error>0){
            toast("Số điện thoại không chính xác");
        }
        else{



            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data',base64_encode(data));

            var get_data=await fetch(Appthis.ConstApp.home_api+this.props.path+"/updateuser", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            get_data=await get_data.json();


            if(get_data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{

                var get_data_user=await this.fetchUser(data.user_login);


                if(get_data_user.status==='ok' && Object.keys(get_data_user.data).length>0){

                    var DetailSubUser=[];
                    if(this.state.DetailSubUser!==null){
                        DetailSubUser=this.state.DetailSubUser;
                    }

                    DetailSubUser.push(get_data_user.data);

                    var get_current_data=this.state.data;

                    var id_user_sub=[];

                    if(get_current_data.id_user_sub===null){
                        id_user_sub=[];
                    }

                    id_user_sub.push(get_data_user.data._id);

                    const self=this;

                    get_current_data['id_user_sub']=convertArrayToSingle(id_user_sub);

                    this.setState({
                        DetailSubUser:DetailSubUser,
                        data:get_current_data,
                        InfoUser:{
                            status:false,
                            data:{}
                        }
                    },function(){
                        self.AutoCalculator();
                    });

                }

            }

            Appthis.PageLoad(false);

        }

    }


    async DetailCard(id){
        if(id!==null){

            var Appthis=this.props.AppThis;

            const formData = new FormData();

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('id_card',id);
            const self=this;

            var get_data=await fetch(Appthis.ConstApp.home_api+"card/detail", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

             get_data=await get_data.json();

            if(get_data.status==='ok'){
                var ListCard=this.state.ListCard;
                ListCard['DetailCard']=get_data.data;
                self.setState({
                    ListCard:ListCard,
                    Loadding:false
                });
            }

        }
    }


    VerifyMinCustomPrice(){

        var ChangeCustomPrice=this.state.ChangeCustomPrice;

        var get_number=ChangeCustomPrice.number;
        var status=false;

        var data=this.state.data;
        var calculator=this.state.calculator;
        var DetailCard=this.state.ListCard.DetailCard;
        var number_calculator=this.state.number_calculator;
        var day=0;

        if(DetailCard!==null && get_number.length>0) {

            get_number=eval(remove_input_price(get_number));

            var min_price=this.state.ListCard.DetailCard.min_price;

            var type_card = DetailCard['type'];

            if(type_card===2 || type_card===5){

                if(calculator===1){
                    day=NextDay(data.start_day,number_calculator);
                }
                else{
                    day=NextMonth(data.start_day,number_calculator);
                }
            }
            else if (type_card === 1) {
                day = NextDay(data.start_day, number_calculator * 3);
            } else {
                day = NextMonth(data.start_day, number_calculator);
            }

            if(day!==0){

                var end_day_contract=Math.ceil(day.getTime()/1000);

                var day_gap=Math.ceil((end_day_contract-this.state.data.start_day)/86400);


                if (type_card === 2 || type_card === 5) {
                    if (get_number >= (min_price * day_gap) && day_gap>=parseInt(DetailCard['min_session'])) {
                        status = true;
                    }
                }
                else if (type_card === 1) {
                    if (get_number >= number_calculator * min_price) {
                        status = true;
                    }
                } else {
                    if (
                        get_number >=
                        number_calculator * DetailCard.number_day_in_month * min_price
                    ) {
                        status = true;
                    }
                }

            }

        }

        if(status===true){

            var dataUpdate=this.state.data;

            dataUpdate.custom_price=get_number;

            this.setState({
                data:dataUpdate,
                ChangeCustomPrice:{
                    status:false,
                    number:0
                }
            });
        }
        else{
            toast("Giá bạn nhập nhỏ hơn giá tối thiểu");
        }

    }

    async ValidationDisCount(){

        if(this.state.DiscountContract.InputDiscount!==null && typeof this.state.DiscountContract.InputDiscount==='string'){

            var Appthis=this.props.AppThis;

            Appthis.PageLoad(true);

            const formData = new FormData();

            const self=this;

            formData.append('token', Appthis.getToken());
            formData.append('id_address', Appthis.getAddress());
            formData.append('discount_code', this.state.DiscountContract.InputDiscount);

            fetch(Appthis.ConstApp.home_api+this.props.path+"/discount", {
                method: 'POST',
                mode: 'cors',
                body: formData
            }).then(data=>data.json()).then(data=>{

                if(data.status==='ok'){

                    toast("Thêm mã giảm giá thành công");

                    var DiscountContract=self.state.DiscountContract;

                    DiscountContract.InfoDiscount=data.data;

                    DiscountContract.InputDiscount=null;

                    this.setState({
                        DiscountContract:DiscountContract
                    },function(){
                        self.AutoCalculator();
                    });

                }
                else{
                    toast("Thêm mã giảm giá không thành công");

                    self.setState({
                        DiscountContract:{
                            InputDiscount:null,
                            InfoDiscount:null
                        }
                    });

                }
                Appthis.PageLoad(false);

                if(self.InputCode.current!==null && typeof self.InputCode.current!=='undefined'){
                    self.InputCode.current.value="";
                }


            }).catch((e)=>{
                Appthis.PageLoad(false);
                toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại");

                if(self.InputCode.current!==null && typeof self.InputCode.current!=='undefined'){
                    self.InputCode.current.value="";
                }

                self.setState({
                    DiscountContract:{
                        InputDiscount:null,
                        InfoDiscount:null
                    }
                });

            });




        }
        else{
            toast("Vui lòng nhập mã giảm giá");
        }
    }

    OptionLayoutGroupMember(props,GroupStatics){
        const self=this;
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{get_data_item.name}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>

                    {get_data_item.id_user?(<>
                        <p className={"m-0"}>Đại diện : {get_data_item.id_user.username+" - "+get_data_item.id_user.code_user}</p>
                    </>):(<></>)}

                </components.Option>
            );
        }
        else{
            return(<></>);
        }
    }

    OptionLayout(props,GroupStatics){
        const self=this;
        var get_data_item=getItemInArray(props.data.value,"_id",GroupStatics);
        if(get_data_item!==null){
            return(
                <components.Option {...props}>
                    <p className={"m-0"}><strong>{props.data.label}</strong><span className={"ms-1 font10 badge p-1 bg-primary"}>{get_data_item.code_group}</span>
                    </p>
                    <p className={"m-0"}>HLV : {get_data_item.id_admin_pt.username+" - "+get_data_item.id_admin_pt.code_user}</p>
                    <p className={"m-0"}>Ngày :
                        {Object.keys(get_data_item.days).length>0?(
                            Object.entries(get_data_item.days).map(function(item,index){
                                return(<span className={"m-1 badge font10 bg-black-50 p-1"}>{get_name_day(item[1])}</span>);
                            })
                        ):(<></>)}
                    </p>
                    <p className={"m-0"}>giờ : {get_data_item.time_start} - {get_data_item.time_end}</p>
                </components.Option>
            );
        }
        else{
            return(<></>);
        }

    }

    NumberPeople(){

        var DetailCard=this.state.ListCard.DetailCard;

        const self=this;

        var DetailSubUser=this.state.DetailSubUser;

        if(DetailCard.number_people>0){
            return(

                <div className={"col-12"}>

                    <div className={"alert alert-primary mt-3 rounded-0"}>
                        <i className="fas fa-user-plus" />  Số người đi kèm tối đa : {DetailCard.number_people}
                    </div>


                    {DetailSubUser!==null?(
                        <>

                            <div className={"col-12 overflow-auto"}>
                                <table className={"mt-3 w-100 table border-0 mw400"}>
                                    <thead>
                                    <tr className={"border-bottom bg-light"}>
                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                            Họ và tên
                                        </td>
                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                            SDT/ID tài khoản
                                        </td>

                                        <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                            Mã số thẻ
                                        </td>
                                    </tr>
                                    </thead>

                                    <tbody>

                                    {DetailSubUser.map(function(item,index){
                                        return(
                                            <tr>
                                                <td>
                                                    {item.username}
                                                </td>
                                                <td>
                                                    {item.user_login}
                                                </td>
                                                <td>
                                                    {item.card_user}

                                                    <a className={"text-danger font12 mt-1 ms-3"} onClick={()=>{

                                                        var update_data=self.state.data;

                                                        var id_user_sub=update_data.id_user_sub;

                                                        delete id_user_sub[index];

                                                        update_data.id_user_sub=filter_args(id_user_sub);

                                                        var UpdateDetailSubUser=self.state.DetailSubUser;

                                                        delete UpdateDetailSubUser[index];

                                                        self.setState({
                                                            data:update_data,
                                                            DetailSubUser:filter_args(UpdateDetailSubUser)
                                                        },function(){
                                                            self.AutoCalculator();
                                                        });

                                                    }}>
                                                        <i className="far font18 fa-trash-alt"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    </tbody>
                                </table>
                            </div>

                        </>
                    ):(
                        <div className={"alert alert-danger"}>
                            <i className="fas fa-times-circle"/> Không có dữ liệu
                        </div>
                    )}

                    {this.state.DetailSubUser===null || this.state.DetailSubUser.length<DetailCard.number_people?(
                        <div className={"col-12 mt-2 mb-4"}>
                            <a onClick={()=>{
                                this.setState({
                                    InfoUser:{
                                        status:true,
                                        data:{}
                                    }
                                });
                            }} className={"btn btn-danger ps-3 pe-3 rounded-0"}>
                                <i className="far fa-plus-square me-2" /> Thêm mới
                            </a>
                        </div>
                    ):(
                        <></>
                    )}




                </div>
            );
        }
        else{
            return(<></>);
        }
    }

    RenderActionCard(){

        var DetailCard=this.state.ListCard.DetailCard;

        const self=this;

        if(DetailCard!==null){

            var type_card=DetailCard['type'];

            if(this.state.Loadding===true){
                return(
                    <div className={"col-12 pt-4 d-flex justify-content-center align-self-center"}>
                        <ItemLoading />
                    </div>
                );
            }
            else{

                switch (type_card){

                    case 1:

                        if(self.state.data.id_saleprogram===null){
                            return(
                                <div className={"row"}>
                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                        <p className={"mb-1"}>Số buổi tập <span className={"text-red"}>( * )</span></p>

                                        <div className={"col-12 d-flex align-content-start position-relative"}>

                                            <select  onChange={(event)=>{
                                                self.setState({
                                                    calculator:Math.ceil(event.target.value)
                                                });

                                            }} className={"form-control w80px"}>
                                                <option value={1}>Buổi</option>
                                            </select>
                                            <i className="fas fa-caret-down position-absolute select-type-input" />
                                            <input onChange={(event)=>{
                                                var data_update=self.state.data;
                                                data_update['custom_price']=0;
                                                data_update['id_discount']=[];

                                                self.setState({
                                                    number_calculator:Math.ceil(event.target.value),
                                                    data:data_update
                                                },function(){
                                                    self.AutoCalculator();
                                                })
                                            }} type={"number"} placeholder={"số buổi"} defaultValue={0} className={"form-control w-100 rounded-0"} />

                                        </div>


                                    </div>

                                      <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                            <p className={"mb-1"}>Huấn Luyện viên <span className={"text-red"}>( * )</span></p>

                                            {self.state.ListAdmin!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var data=self.state.data;
                                                    data.id_admin_pt=selectedOption.value;
                                                    self.setState({
                                                        data:data
                                                    });

                                                }} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                            ):(
                                                <ItemLoading />
                                            )}
                                        </div>

                                    <div className={"col-12 alert bg-danger text-white"}>
                                        Số buổi tập tối thiểu : {DetailCard.min_session}
                                    </div>

                                </div>
                            )
                        }
                        else{
                            return(
                                <div className={"row"}>
                                  <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                            <p className={"mb-1"}>Huấn Luyện viên <span className={"text-red"}>( * )</span></p>

                                            {self.state.ListAdmin!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var data=self.state.data;
                                                    data.id_admin_pt=selectedOption.value;
                                                    self.setState({
                                                        data:data
                                                    });

                                                }} options={array_to_select(this.state.ListAdmin,"value","key")} />

                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>
                                </div>
                            )
                        }


                    case 2:
                    case 5:

                        if(self.state.data.id_saleprogram===null){
                            return(
                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                    <p className={"mb-1"}>Số buổi tập <span className={"text-red"}>( * )</span></p>
                                    <div className={"col-12 d-flex align-content-start position-relative"}>

                                        <select onChange={(event)=>{
                                            self.setState({
                                                calculator:Math.ceil(event.target.value)
                                            },function(){
                                                self.AutoCalculator();
                                            });

                                        }} className={"form-control w80px"}>
                                            <option value={1}>Buổi</option>
                                            <option value={2}>Tháng</option>
                                        </select>
                                        <i className="fas fa-caret-down position-absolute select-type-input" />
                                        <input type={"number"} onChange={(event)=>{

                                            var data_update=self.state.data;
                                            data_update['custom_price']=0;
                                            data_update['id_discount']=[];

                                            self.setState({
                                                number_calculator:Math.ceil(event.target.value),
                                                data:data_update
                                            },function(){
                                                self.AutoCalculator();
                                            })
                                        }} placeholder={"số buổi hoặc tháng"} defaultValue={0} className={"form-control w-100 rounded-0"} />

                                    </div>

                                    {type_card!==5?(
                                        <div className={"col-12 alert bg-danger text-white"}>
                                            Số buổi tập tối thiểu : {DetailCard.min_session}
                                        </div>
                                    ):(
                                        <></>
                                    )}


                                </div>
                            );

                        }
                        else{
                            return(
                                <></>
                            );

                        }


                    case 3:
                    case 4:

                        if(self.state.data.id_saleprogram===null){

                            return(
                                <div className={"row w-100"}>
                                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                        <p className={"mb-1"}>Số tháng tập <span className={"text-red"}>( * )</span></p>

                                        <div className={"col-12 d-flex align-content-start position-relative"}>

                                            <select onChange={(event)=>{
                                                self.setState({
                                                    calculator:Math.ceil(event.target.value)
                                                },function(){
                                                    self.AutoCalculator();
                                                });

                                            }} className={"form-control w80px"}>
                                                <option value={2}>Tháng</option>
                                            </select>
                                            <i className="fas fa-caret-down position-absolute select-type-input" />
                                            <input type={"number"} onChange={(event)=>{

                                                var data_update=self.state.data;
                                                data_update['custom_price']=0;
                                                data_update['id_discount']=[];

                                                self.setState({
                                                    number_calculator:Math.ceil(event.target.value),
                                                    data_update:data_update
                                                },function(){
                                                    self.AutoCalculator();
                                                })
                                            }} placeholder={"số tháng"} defaultValue={0} className={"form-control w-100 rounded-0"} />

                                        </div>
                                    </div>

                                    {type_card===4?(
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                            <p className={"mb-1"}>Lớp nhóm cố định <span className={"text-red"}>( * )</span></p>

                                            {self.state.GroupStatics!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var data=self.state.data;
                                                    data.group_member_statics=selectedOption.value;
                                                    self.setState({
                                                        data:data
                                                    });
                                                }} options={array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                                    Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                                }} />

                                            ):(
                                                <ItemLoading />
                                            )}



                                        </div>
                                    ):(<></>)}



                                    <div className={"col-12 alert bg-danger text-white"}>
                                        Số tháng tối thiểu : {DetailCard.min_session} tháng
                                    </div>

                                </div>
                            );

                        }
                        else{
                            return(
                                <div className={"row m-0 w-100"}>
                                    {type_card===4?(
                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                            <p className={"mb-1"}>Lớp nhóm cố định <span className={"text-red"}>( * )</span></p>

                                            {self.state.GroupStatics!==null?(

                                                <Select isSearchable={true} onChange={(selectedOption)=>{

                                                    var data=self.state.data;
                                                    data.group_member_statics=selectedOption.value;
                                                    self.setState({
                                                        data:data
                                                    });

                                                }} options={array_to_select(this.state.GroupStatics,"name","_id")} components={{
                                                    Option:(props)=>self.OptionLayout(props,self.state.GroupStatics)
                                                }} />

                                            ):(
                                                <ItemLoading />
                                            )}



                                        </div>
                                    ):(<></>)}
                                </div>
                            );

                        }



                }
            }

        }
        else{
            return(<></>);
        }

    }

    async AutoCalculator(){

        var calculator=this.state.calculator;

        var number_calculator=this.state.number_calculator;

        var price=0;

        var DetailCard=this.state.ListCard.DetailCard;

        var data=this.state.data;

        var day=0;

        var insurance=Math.ceil(this.state.number_insurance);


        try{
            if(DetailCard!==null) {

                var custom_price = this.state.data.custom_price;

                var number_card_update=0;

                var type_card = DetailCard['type'];

                if(type_card===2 || type_card===5){

                    if(calculator===1){
                        day=NextDay(data.start_day,number_calculator);
                        number_card_update=number_calculator;
                        price=Math.ceil(number_calculator*DetailCard.price);
                    }
                    else{
                        day=NextMonth(data.start_day,number_calculator);
                        number_card_update=Math.ceil(between_two_dates(new Date(data.start_day*1000),day)/86400);
                        price=Math.ceil(number_card_update*DetailCard.price);

                    }
                }
                else if (type_card===1){
                    number_card_update=number_calculator;
                    day=NextDay(data.start_day,number_calculator*3);
                    price=Math.ceil(number_calculator*DetailCard.price);
                }
                else{

                    day = NextMonth(data.start_day, number_calculator);
                    number_card_update =number_calculator * Math.ceil(DetailCard.number_day_in_month);
                    price = Math.ceil(number_card_update * DetailCard.price);

                }


                if(price<0){
                    price=0;
                }

                var end_day_contract=Math.ceil(day.getTime()/1000);

                data.end_day_contract=end_day_contract;

                var day_gap=Math.ceil((end_day_contract-this.state.data.start_day)/86400);

                if(day_gap>367){
                    insurance=Math.ceil(this.state.number_insurance*Math.ceil(day_gap/367));
                }

                if(data.id_user_sub!==null){
                    insurance=insurance+data.id_user_sub.length*insurance;
                }

                data['number_card']=number_card_update;

                var get_vat=this.state.number_vat;

                var vat = 0;

                var price_discount=null;

                if (custom_price > 0) {
                    price_discount=custom_price;
                    vat = Math.ceil((custom_price * get_vat) / 100);

                } else {
                    vat = Math.ceil((price * get_vat) / 100);
                    price_discount=price;
                }


                if(data.id_discount.length>0){

                    data.id_discount.map(function(item,index){

                        if(item.price_sale && typeof item.price_sale!=='undefined'){
                            price_discount=Math.ceil(price_discount-item.price_sale);
                        }

                        if(item.percent_sale && typeof item.percent_sale!=='undefined'){
                            price_discount=price_discount-Math.ceil((price_discount*parseInt(item.percent_sale))/100);
                        }

                    });

                }
                else{
                    price_discount=null;
                }

                var tmp_price={
                    price:price,
                    vat:vat,
                    insurance:insurance,
                    price_discount:price_discount
                };

                const self=this;

                this.setState({
                    tmp_price:tmp_price,
                    data:data
                },function(){
                    self.forceUpdate();
                });


            }
        }
        catch (e) {

        }

    }

    async SubmitContract() {

        var GetData = this.state.data;

        var Appthis = this.props.AppThis;

        var number_error = 0;


        if(this.state.InfoContract.id_card.type!==5){
            ['id_subject', 'id_requires'].map(function (item, index) {
                if (GetData[item].length <= 0 || GetData[item].length <= 0) {
                    number_error += 1;
                }
            });

            ['source_contract'].map(function (item, index) {
                if (GetData[item] === null || typeof GetData[item] === 'undefined' || GetData[item].length < 5) {
                    number_error += 1;
                }
            });

        }

        ['id_user'].map(function (item, index) {
            if (GetData[item] === null || typeof GetData[item] === 'undefined' || GetData[item].length < 5) {
                number_error += 1;
            }
        });


        if(GetData['id_saleprogram']===null){

            if (GetData['id_card'] === null || typeof GetData['id_card'] === 'undefined' || GetData['id_card'].length < 5) {
                number_error += 1;
            }

            ['number_card'].map(function (item, index) {
                if (GetData[item] === null || typeof GetData[item] === 'undefined' || isNaN(GetData[item])===true || GetData[item]<=0) {
                    number_error += 1;
                }
            });

        }


        var DetailCard = this.state.ListCard.DetailCard;

        if(DetailCard!==null){

            switch (DetailCard.type) {

                case 1:
                    if (GetData['id_admin_pt'] === null) {
                        number_error++;
                    }
                    break;
                case 4:
                    if (GetData['group_member_statics'] === null) {
                        number_error++;
                        break;
                    }
            }
        }
        else{
            number_error += 1;
        }



        if (number_error > 0) {
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else {
            Appthis.PageLoad(true);

            var update_id_discount=[];

                GetData.id_discount.map(function(item){
                    if(_isset_object(item)){
                        update_id_discount.push(item._id);
                    }

                });

            GetData['id_discount']=update_id_discount;


            var base64_data = base64_encode(GetData);

            const formData = new FormData();

            formData.append('token', Appthis.getToken());

            formData.append('id_address', Appthis.getAddress());

            formData.append('data', base64_data);

            var ListContractUpgrade=this.state.ListContractUpgrade;

            if(ListContractUpgrade.length>0){

                var list_id_member=[];

                ListContractUpgrade.map(function(item){
                    list_id_member.push(item.key);
                });

                formData.append('id_member', base64_encode(list_id_member));
            }
            else{
                formData.append('id_member', base64_encode([this.state.InfoContract._id]));
            }

            formData.append('type',this.state.type_upgrade);

            var fetch_data = await fetch(Appthis.ConstApp.home_api + this.props.path + "/upgrade", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            fetch_data = await fetch_data.json();

            if (fetch_data.status === 'ok') {
                toast("Thao tác thành công");
                Appthis.PageLoad(false);
                this.props.onSuccess();

            } else {

                Appthis.PageLoad(false);
                toast("Thao tác không thành công vui lòng thử lại");
                this.props.onClose();

            }


        }

    }

    async VerifySubmitContract() {

        const self=this;

        var old_price=this.getTotalPriceSaleUpgrade();

        var price_curent=0;

        if(this.state.tmp_price.price_discount!==null){
            price_curent=parseInt(this.state.tmp_price.price_discount);
        }
        else{

            if(this.state.data.custom_price!==0){
                price_curent=parseInt(this.state.data.custom_price);
            }
            else{
                price_curent=parseInt(this.state.tmp_price.price);
            }
        }

        var DataSaleProgram=this.state.DataSaleProgram;

        if(DataSaleProgram.data!==null){
            price_curent=parseInt(DataSaleProgram.data.price);
        }

        if(old_price>=price_curent && this.state.type_upgrade===2){
            toast("Giá trị HD mới phải lớn hơn giá trị HD cũ");
        }
        else{
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Cảnh báo !</h1>
                            <p>Bạn có chắc chắn muốn cập nhật thao tác này ?</p>
                            <button onClick={()=>{

                                onClose();

                            }}>Không</button>
                            <button
                                onClick={() => {
                                    self.SubmitContract();
                                    onClose();
                                }}
                            > Có.Tôi chắc chắn
                            </button>
                        </div>
                    );
                }
            });
        }

    }

    RenderExtractContract(){
        const self=this;

        return(
            <>

                {this.state.InfoContract.id_card.type!==5?(
                    <div className={"row"}>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                            <p className={"mb-1"}>Bộ môn <span className={"text-red"}>( * )</span></p>

                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                var get_data=self.state.data;
                                get_data['id_subject']=get_data_in_args(selectedOption,"value");
                                self.setState({
                                    data:get_data
                                });

                            }} isMulti={true} options={array_to_select(this.state.Subject,'name','_id')} />

                        </div>
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                            <p className={"mb-1"}>Mục tiêu tập luyện <span className={"text-red"}>( * )</span></p>

                            <Select isSearchable={true} onChange={(selectedOption)=>{
                                var get_data=self.state.data;


                                get_data.id_requires=get_data_in_args(selectedOption,"value");

                                self.setState({
                                    data:get_data
                                });

                            }} isMulti={true} options={array_to_select(this.state.Required,'name','_id')} />

                        </div>


                    </div>
                ):(
                    <></>
                )}


                <div className={"row mt-2"}>
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 box_input pb-2"}>
                        <p className={"mb-1"}>Ngày bắt đầu <span className={"text-red"}>( * )</span></p>
                        <DatePicker dateFormat="dd/MM/yyyy" selected={new Date(this.state.data.start_day*1000)} onChange={(date) => {
                            if(date!==null){

                                var data=self.state.data;

                                data.start_day=Math.ceil(date.getTime()/1000);

                                self.setState({
                                    data:data
                                },function(){
                                    self.AutoCalculator();
                                });

                            }

                        }} placeholderText={"Ngày bắt đầu"} />
                    </div>

                    {this.state.InfoContract.id_card.type!==5?(
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                            <p className={"mb-1"}>Nguồn HD <span className={"text-red"}>( * )</span></p>

                            <Select isSearchable={true} onChange={(selectedOption)=>{

                                if(selectedOption!==null && typeof selectedOption.value!=='undefined'){
                                    var data=this.state.data;
                                    data['source_contract']=selectedOption.value;
                                    this.setState({
                                        data:data
                                    });
                                }

                            }} options={array_to_select(this.state.Source,'name','_id')} />
                        </div>
                    ):(
                        <>
                            <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                                <p className={"mb-1"}>Số tủ <span className={"text-red"}>( * )</span></p>

                                <input onChange={(event)=>{
                                    var data=this.state.data;
                                    data.number_coffer=event.target.value;
                                    this.setState({
                                        data:data
                                    });
                                }} type={"text"} className={"form-control w-100 rounded-0"} placeholder={"Số tủ"} />

                            </div>

                        </>
                    )}
                </div>

                <div className={"row mt-2"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 customCssSelect pb-2"}>
                        <p className={"mb-1"}>Ghi chú gói tập </p>
                        <input type={"text"} className={"form-control w-100 rounded-0"} placeholder={"Ghi chú gói tập"} />
                    </div>

                </div>

            </>
        );
    }

    RenderGroupMember(){
        const self=this;
        return(
            <>
                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 customCssSelect pb-2"}>
                    <p className={"mb-1 mt-2"}>Nhóm HD doanh nghiệp</p>

                    {self.state.GroupMember!==null?(

                        <Select isSearchable={true} onChange={(selectedOption)=>{

                            var data=self.state.data;
                            data.group_member=selectedOption.value;
                            self.setState({
                                data:data
                            });

                        }} options={array_to_select(self.state.GroupMember,"name","_id")}
                                components={{
                                    Option:(props)=>self.OptionLayoutGroupMember(props,self.state.GroupMember)
                                }}
                        />

                    ):(
                        <ItemLoading />
                    )}

                </div>
            </>
        );
    }

    RenderVerifyPrice(){

        return(
            <>
                {this.state.isMutiple===false?(
                    <>
                        <div className={"alert alert-info"}>
                            <i className="far fa-check-circle me-2" />  Vui lòng chọn loại nâng cấp HD
                        </div>
                        <div className={"row mb-4"}>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3 text-uppercase font-weight-bold font16"}>
                                <span>Loại nâng cấp</span>
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 pb-3 d-flex flex-row align-items-center font14"}>

                                {this.state.type_upgrade===1?(
                                    <>
                                        <label className={"me-2"} onClick={()=>{
                                            this.setState({
                                                type_upgrade:1
                                            })
                                        }} >
                                            <input checked={true} type={"radio"} name={"type_upgrade"} /> Renew
                                        </label>


                                        <label  onClick={()=>{
                                            this.setState({
                                                type_upgrade:2
                                            })
                                        }} >
                                            <input type={"radio"} name={"type_upgrade"} /> Upgrade
                                        </label>

                                    </>
                                ):(
                                    <>
                                        <label className={"me-2"} onClick={()=>{
                                            this.setState({
                                                type_upgrade:1
                                            })
                                        }} >
                                            <input type={"radio"} name={"type_upgrade"} /> Renew
                                        </label>

                                        <label  onClick={()=>{
                                            this.setState({
                                                type_upgrade:2
                                            })
                                        }} >
                                            <input checked={true} type={"radio"} name={"type_upgrade"} /> Upgrade
                                        </label>
                                    </>
                                )}


                            </div>

                        </div>

                    </>
                ):(
                    <></>
                )}

                {this.state.type_upgrade===2?(
                    <div className={"col-12"}>
                        <div className={"alert alert-primary"}>
                            <i className="far fa-check-circle me-2" /> Giá trị HD Upgrade phải lớn hơn giá trị HD cũ.
                            <strong className={"ms-2 font16 text-red"}> > {formatNumber(this.getTotalPriceSaleUpgrade())} VNĐ</strong>
                        </div>
                    </div>

                ):(
                    <></>
                )}
            </>
        );
    }
    RenderCustomNewContract(){

        const self=this;

        return(
            <>
                <div className={"row"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>

                        <div className={"row"}>

                            {this.state.InfoContract.id_card.type!==5?(
                                <div className={"col-12 col-sm-6 col-md-6 mb-3 customCssSelect pb-2"}>
                                    <p className={"mb-1"}>Loại HD</p>

                                    <Select isSearchable={true} onChange={(selectedOption)=>{
                                        var ListCard=this.state.ListCard;
                                        ListCard.type=selectedOption.value;
                                        var data=this.state.data;
                                        data.id_card=null;
                                        this.setState({
                                            ListCard:ListCard,
                                            data:data
                                        },function(){
                                            self.getCard();
                                        });

                                    }} options={[
                                        {
                                            label:"Tất cả",
                                            value:null
                                        },
                                        {
                                            label:"HD PT",
                                            value:1
                                        },
                                        {
                                            label:"HD Member",
                                            value:2
                                        },
                                        {
                                            label:"HD lớp nhóm Linh Hoạt",
                                            value:3
                                        },
                                        {
                                            label:"HD lớp nhóm cố định",
                                            value:4
                                        }
                                    ]} />

                                </div>
                            ):(
                                <></>
                            )}

                            <div className={"col-12 col-sm-6 col-md-6 mb-3 Box-Select-Card customCssSelect pb-2 position-relative"}>


                                <p className={"mb-1"}>Gói thẻ <span className={"text-red"}>( * )</span></p>

                                {this.state.ListCard.data!==null?(

                                    <Select menuPosition={"bottom"} menuPlacement={"bottom"} isSearchable={true} onChange={(selectedOption)=>{

                                        if(selectedOption!==null && typeof selectedOption!=='undefined' && typeof selectedOption.value!=='undefined'){
                                            var data=this.state.data;
                                            data.id_card=selectedOption.value;
                                            data.group_member_statics=null;
                                            data.id_user_sub = null;
                                            data.id_admin_pt = null;
                                            this.setState({
                                                data:data,
                                                Loadding:true,
                                                tmp_price:{
                                                    price:0,
                                                    vat:0,
                                                    insurance:0,
                                                    price_discount:0
                                                }
                                            },function(){
                                                self.DetailCard(selectedOption.value);
                                            });
                                        }
                                    }} ref={this.SelectCard} options={array_to_select(this.state.ListCard.data, "name","_id")} />


                                ):(
                                    <ItemLoading />
                                )}


                            </div>
                        </div>

                        {this.RenderExtractContract()}

                    </div>

                    <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>

                        {this.state.ListCard.DetailCard!==null?(
                            <div className={"col-12 p-0"}>
                                {self.RenderActionCard()}
                                {self.NumberPeople()}
                            </div>
                        ):(<></>)}




                        {this.state.InfoContract.id_card.type!==5?(
                            <>
                                {this.RenderGroupMember()}
                            </>
                        ):(
                            <></>
                        )}


                        <div className="col-12 border-top mt-4 pt-3"></div>


                        {this.RenderVerifyPrice()}


                        <p className={"font-weight-bold p16 text-uppercase d-block w-100 mb-2 font16 pb-2"}>MÃ GIẢM GIÁ</p>

                        <div className={"alert alert-warning w-100 rounded-0"}>
                            <i className="fas fa-exclamation-triangle" /> bạn có thể nhập nhiều mã giảm giá tại đây.Mã giảm giá sẽ áp dụng cho từng loại thẻ tập
                        </div>

                        <div className={"col-12 mb-3"}>
                            {this.state.data.id_discount.length>0?(
                                this.state.data.id_discount.map(function(value,key){
                                    if(value!==null && typeof value!=='undefined' && typeof value.code!=='undefined'){
                                        return(
                                            <a href={"#"} onClick={()=>{
                                                var update_data=self.state.data;
                                                var get_id_discount=update_data.id_discount;
                                                delete get_id_discount[key];
                                                update_data.id_discount=filter_args(get_id_discount);
                                                self.setState({
                                                    data:update_data
                                                },function(){
                                                    self.AutoCalculator();
                                                });
                                            }} className={"m-1 badge bg-primary text-white rounded-0 font12"}>
                                                {value.code}
                                                <i className="far font12 fa-trash-alt ms-2"></i>
                                            </a>
                                        );
                                    }
                                    else{
                                        return(
                                            <></>
                                        );
                                    }

                                })
                            ):(<></>)}
                        </div>


                        <div className="d-flex align-self-center mt-2 flex-wrap flex-row position-relative">
                            <input type="text" onChange={(event)=>{

                                var DiscountContract=this.state.DiscountContract;
                                DiscountContract.InputDiscount=event.target.value;
                                this.setState({
                                    DiscountContract:DiscountContract
                                });

                            }} value={this.state.DiscountContract.InputDiscount} ref={this.InputCode} className="form-control" placeholder="Mã KM" />
                            <a onClick={()=>{
                                this.ValidationDisCount();
                            }} className={"btn h-100 btn-primary rounded-0 position-absolute right-0 top-0"}>
                                Thêm mã KM
                            </a>
                        </div>

                        <div className={"row"}>

                            <div className={"col-12 col-sm-12 col-md-12 col-lg-8 mt-4"}>
                                <div className="col-12 p-0 ps-3 p-2 text-uppercase font-weight-bold font15 mb-2">
                            <span className="mr-3">
                           Giá bán : </span>
                                    <span className={"text-red"}>{formatNumber(this.state.tmp_price.price)} VNĐ</span>
                                </div>

                                {this.state.tmp_price.price_discount!==null?(
                                    <div className={"col-12 p-0 ps-3 p-2 bg-light w-100 d-flex flex-row flex-wrap align-self-center font16 text-uppercase font-weight-bold"}>
                                        <span>Giá thực tế : </span>
                                        <span className={"ms-2 me-2"}>{formatNumber(this.state.tmp_price.price_discount)}</span> VNĐ
                                    </div>
                                ):(
                                    <div className={"col-12 p-0 ps-3 p-2 bg-light w-100 d-flex flex-row flex-wrap align-self-center font16 text-uppercase font-weight-bold"}>
                                        <span>Giá thực tế : </span>

                                        {this.state.data.custom_price!==0?(
                                            <>
                                               <span className={"text-red"}>
                                                    <span className={"ms-2 me-2 text-red"}>{formatNumber(this.state.data.custom_price)}</span>
                                                VNĐ
                                               </span>
                                            </>
                                        ):(
                                            <>
                                                <span className={"text-red"}>
                                                       <span className={"ms-2 me-2 "}>
                                        {formatNumber(this.state.tmp_price.price)}
                                    </span>
                                                VNĐ
                                                </span>
                                            </>
                                        )}

                                        <a className={"ms-2 text-primary font10 mt-2"} onClick={()=>{
                                            this.setState({
                                                ChangeCustomPrice:{
                                                    status:true,
                                                    number:''
                                                }
                                            });
                                        }}>
                                            Thay đổi
                                        </a>

                                    </div>

                                )}



                                {this.state.InfoContract.id_card.type!==5?(
                                    <>
                                        <div className={"col-12 p-0 mt-3"}>
                                        </div>

                                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12"}>


                                            <table className="table min-width-auto">
                                                <tbody>
                                                <tr>
                                                    <th scope="row"><i className="fas fa-percent me-1"></i>   VAT</th>
                                                    <td>{formatNumber(this.state.tmp_price.vat)} VNĐ <span className={"ms-2 font10"}>( Đã bao gồm )</span></td>

                                                </tr>

                                                <tr>
                                                    <td>  <i className="fas fa-university me-1"></i> Phí bảo hiểm</td>
                                                    <td>
                                                        {formatNumber(this.state.tmp_price.insurance)} VNĐ <span className={"ms-2 font10"}>( Đã bao gồm )</span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>


                                        </div>
                                    </>
                                ):(
                                    <></>
                                )}



                            </div>

                        </div>

                    </div>

                </div>

                <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">
                    <div className="row mt-4">
                        <div className="col-12 col-sm-12 col-md-6 pb-3">

                            <a onClick={()=>{
                                this.setPageAction(0);
                            }}
                               className="font16 font-weight-bold text-uppercase btn btn-danger rounded-0 w-100 btn-sm pt-2 pb-2">
                                <i className="fas fa-angle-left me-2"></i> Trở về
                            </a>

                        </div>
                        <div className="col-12 col-sm-12 col-md-6 pb-3">
                            <a onClick={()=>{
                                this.VerifySubmitContract();
                            }}
                               className="font16 font-weight-bold text-uppercase btn btn-primary rounded-0 w-100 btn-sm pt-2 pb-2">
                                <i className="fas fa-angle-right me-2" /> Hoàn tất
                            </a>
                        </div>
                    </div>
                </div>


            </>
        );
    }

    setDataProgram(item){

        var data=this.state.data;

        if(item===null){
            data['id_saleprogram']=null;
        }
        else{
            data['id_saleprogram']=item._id;
        }

        var DataSaleProgram=this.state.DataSaleProgram;

        DataSaleProgram.data=item;

        this.setState({
            data:data,
            DataSaleProgram:DataSaleProgram
        });
    }

    async EventNextSale(){

        var data=this.state.data;

        if(data.id_saleprogram===null){

            this.setState({
                DataSaleProgram:{
                    status:false,
                    data:null
                }
            });
        }
        else{

            this.setState({
                StatusRenderSaleProgram:false
            });

            var DataSaleProgram=this.state.DataSaleProgram.data;

             this.DetailCard(DataSaleProgram.id_card._id);

        }
    }
    RenderSaleProgram(){

        const self=this;

        if(this.state.AllSaleProgram!==null){

            if(this.state.StatusRenderSaleProgram===true){
                return(
                    <div className={"col-12 mb-4"}>

                        <p className={"mb-4"}><em>Chương trình khuyến mãi</em></p>

                        {this.state.AllSaleProgram.length===0?(
                            <div className={"alert alert-danger"}>
                                <i className="fas fa-exclamation-triangle me-1" />  Không có chương trình KM nào
                            </div>
                        ):(
                            <div className={"row"}>


                                {LIST_TYPE_SALE.map(function(index){
                                    return(
                                        <>

                                            <div className={"col-12"}>
                                                <div className={"col-12 pb-2 ps-4 border-start border-3 border-success mt-3 bg-light font-weight-bold font16 mb-3 text-uppercase pt-2"}>
                                                    {index.title}
                                                </div>
                                            </div>


                                            {getItemInArray(index.type,"type",self.state.AllSaleProgram,true).length===0?(
                                                <div className={"col-12"}>
                                                    <div className={"alert alert-danger rounded-0"}>
                                                        <i className="fas fa-exclamation-triangle me-2" /> Không có chương trình khuyến mãi nào
                                                    </div>
                                                </div>
                                            ):(
                                                <>
                                                    {getItemInArray(index.type,"type",self.state.AllSaleProgram,true).map(function(item){
                                                        return(
                                                            <div onClick={()=>{
                                                                self.setDataProgram(item);
                                                            }} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                                                                <div className={self.state.data.id_saleprogram===item._id?"col-12 border position-relative active_tab_box":"col-12 border position-relative"}>
                                                                    <div className="col-12 p-2 font-weight-bold border-bottom text-center font15 text-uppercase left-box-info-bank">
                                                                        <p className="m-0">
                                                                            <i className="fab fa-cc-amazon-pay"></i> {item.name}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-12 p-3 right-box-info-bank">
                                                                        <p className="m-0 mb-1">
                                                                            <i className="fas fa-bell" /> Tên thẻ : {item.id_card.name}
                                                                        </p>

                                                                        {item.note?(
                                                                            <p className="m-0">
                                                                                <i className="far fa-comment-alt" /> Ghi chú : {item.note}
                                                                            </p>
                                                                        ):(
                                                                            <></>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}



                                        </>
                                    );
                                })}


                                <div className={"col-12 mt-3"}>
                                    <div className={"col-12 pb-2 ps-4 border-start border-3 border-success mt-3 bg-light font-weight-bold font16 mb-3 text-uppercase pt-2"}>
                                        Không chọn chương trình bán hàng
                                    </div>
                                </div>

                                <div onClick={()=>{
                                    self.setDataProgram(null);
                                }} className="col-6 col-sm-6 col-md-4 col-lg-3 mb-3">
                                    <div className={self.state.data.id_saleprogram===null?"col-12 border pt-4 pb-4 position-relative active_tab_box h-100 d-flex flex-row justify-content-center align-items-center":"col-12 border position-relative h-100 d-flex flex-row justify-content-center align-items-center pt-4 pb-4"}>
                                        <div className={"col-12 text-center"}>
                                            <p className="m-0 mt-1">
                                                <i className="fas fa-trash me-2" />
                                                Không chọn
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )}


                        <div className={"col-12 col-sm-6 offset-sm-6 col-md-6 offset-md-6 col-xl-3 offset-xl-9 d-flex flex-wrap flex-row justify-content-end align-items-end"}>

                            <div className="col-12 p-0 text-uppercase text-end font-weight-bold font15 mb-2 ps-3 p-2">
                                {this.state.DataSaleProgram.data!==null?(
                                    <>
                              <span className="mr-3">
                           Giá bán : </span>

                                        <span className={"text-red"}>
                                         <span>{formatNumber(this.state.DataSaleProgram.data.price)}</span> VNĐ
                                    </span>
                                    </>

                                ):(
                                    <></>
                                )}
                            </div>

                            <a onClick={()=>{
                                self.EventNextSale();
                            }}
                               className="font16 font-weight-bold text-uppercase btn btn-danger rounded-0 w-100 btn-sm pt-3 pb-3">
                                <i className="fas fa-angle-right me-2" /> Tiếp theo
                            </a>


                        </div>

                    </div>
                );
            }
            else{

                if(this.state.ListCard.DetailCard===null){

                    return(
                        <div className={"col-12 d-flex flex-row mt-5 mb-5 justify-content-center align-items-center"}>
                            <ItemLoading size={"Large"} />
                        </div>
                    );
                }
                else{
                    return(
                        <div className={"col-12 mb-4"}>

                            <p className={"mb-4"}><em>Chi tiết gói khuyến mãi</em></p>

                            <div className={"row"}>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>
                                    {this.RenderExtractContract()}
                                </div>

                                <div className={"col-12 col-sm-12 col-md-12 col-lg-6 pb-2"}>

                                    {this.RenderVerifyPrice()}

                                    {this.state.ListCard.DetailCard!==null?(
                                        <>
                                            {self.RenderActionCard()}
                                            {self.NumberPeople()}
                                        </>
                                    ):(<></>)}

                                    <div className={"row m-0 w-100"}>
                                        {this.RenderGroupMember()}
                                    </div>


                                    <div className="col-12 border-top mt-4 pt-3" />

                                </div>
                            </div>



                            <div className="col-12 col-sm-12 col-md-12 offset-lg-6 col-lg-6">

                                <div className="col-12 p-0 text-uppercase text-end font-weight-bold font15 mb-2 ps-3 p-2">
                                    {this.state.DataSaleProgram.data!==null?(
                                        <>
                              <span className="mr-3">
                           Giá bán : </span>

                                            <span className={"text-red"}>
                                         <span>{formatNumber(this.state.DataSaleProgram.data.price)}</span> VNĐ
                                    </span>
                                        </>

                                    ):(
                                        <></>
                                    )}
                                </div>


                                <div className="row mt-4">
                                    <div className="col-12 col-sm-12 col-md-6"></div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                        <a onClick={()=>{
                                            this.VerifySubmitContract();
                                        }}
                                           className="font16 font-weight-bold text-uppercase btn btn-outline-primary rounded-0 w-100 btn-sm pt-3 pb-3">
                                            <i className="fas fa-angle-right me-2" /> Hoàn tất
                                        </a>
                                    </div>
                                </div>
                            </div>


                        </div>
                    );
                }


            }


        }
        else{

            return(
                <div className={"col-12 d-flex flex-row mt-5 mb-5 justify-content-center align-items-center"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    Step3(){

        const self=this;
        return(
            <div className={"col-12"}>

                <h2 className="d-inline-block mb-3 font18 mt-0 text-dark border-primary font-weight-bold border-bottom pb-2">
                    Bước 3 : Thông tin gói tập
                </h2>

                {this.state.InfoContract.id_card.type!==5 && this.state.DataSaleProgram.status===true?(
                    <>
                        {this.RenderSaleProgram()}
                    </>
                ):(
                    <>
                        {this.RenderCustomNewContract()}
                    </>
                )}


                {this.state.ChangeCustomPrice.status===true?(
                    <div className="modal d-block position-fixed modal-dialog-end">
                        <div className={"modal-dialog modal-md"}>
                            <div className="modal-content">
                                <div className="modal-header bg-danger">
                                    <h5 className="modal-title">Cập nhật giá thực tế bán</h5>
                                </div>
                                <div className="modal-body">

                                    <div className={"alert alert-warning rounded-0 w-100"}>
                                        <i className="fas fa-exclamation-triangle"></i> Giá bán không được nhỏ hơn giá tối thiểu
                                    </div>

                                    <input value={input_price(this.state.ChangeCustomPrice.number)} type={"text"} onChange={(event)=>{

                                        try{
                                            var ChangeCustomPrice=this.state.ChangeCustomPrice;
                                            ChangeCustomPrice.number=remove_input_price(event.target.value);
                                            this.setState({
                                                ChangeCustomPrice:ChangeCustomPrice
                                            });
                                        }
                                        catch (e){

                                        }

                                    }} placeholder={"Gía thực tế"} className={"form-control w-100 rounded-0"} />

                                    <div className={"row mt-3"}>

                                        <div className={"col-12 col-sm-12 col-md-6"}>

                                            <button type="button" onClick={()=>{
                                                this.VerifyMinCustomPrice();
                                            }} className="btn rounded-0 w-100 btn-primary">
                                                Cập nhật
                                            </button>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-md-6"}>

                                            <button onClick={()=>{
                                                this.setState({
                                                    ChangeCustomPrice:{
                                                        status:false,
                                                        number:''
                                                    }
                                                });
                                            }} type="button" className="btn rounded-0 w-100 btn-secondary">
                                                Huỷ
                                            </button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
                    </div>
                ):(<></>)}



                {this.state.DiscountContract.InfoDiscount!==null?(
                    <InfoDiscount data={this.state.DiscountContract.InfoDiscount} onClose={()=>{
                        this.setState({
                            DiscountContract:{
                                InputDiscount:null,
                                InfoDiscount:null
                            }
                        });
                    }} SubmitModal={(data)=>{
                        var getdata=this.state.data;
                        var id_discount=getdata.id_discount;
                        id_discount.push(data);
                        getdata.id_discount=id_discount;
                        this.setState({
                            data:getdata,
                            DiscountContract:{
                                InputDiscount:null,
                                InfoDiscount:null
                            }
                        },function(){
                            self.AutoCalculator();
                        });

                    }} />
                ):(
                    <></>
                )}



            </div>
        );

    }

    Step2(){

        var InfoContract=this.state.InfoContract;

        return(
            <div className={"col-12"}>

                <div className="col-12 col-sm-12 col-md-12">
                    <h2 className="d-inline-block mb-3 font18 text-dark border-primary border-bottom pb-2 font-weight-bold">Bước 2 : Xác nhận HD</h2>
                </div>

                <div className={"row m-0 mb-4"}>

                    <div className={"col-12 col-sm-12 col-md-12 col-xl-8 offset-xl-4"}>

                        <div className={"row"}>

                            <div className={"col-12 col-sm-12 col-lg-6 pb-3"}>
                                <a  onClick={()=>{
                                    this.setPageAction(0);
                                }} className={"btn btn-outline-danger rounded-0 w-100 d-block ps-5 pe-5 p-2"}>
                                    <i className="fas fa-angle-left me-1"></i> Trở về
                                </a>
                            </div>
                            <div className={"col-12 col-sm-12 col-lg-6 pb-3"}>
                                <a  onClick={()=>{
                                    this.setPageAction(3);
                                }} className={"btn btn-outline-primary rounded-0 w-100 d-block ps-5 pe-5 p-2"}>
                                    <i className="fab fa-ups me-1"></i> Upgrade và Renew <i className="fas fa-caret-right ms-2" />
                                </a>
                            </div>


                        </div>

                    </div>

                </div>


                <div className={"row m-0"}>

                    <BoxContract data={InfoContract} />

                </div>


            </div>
        );
    }


    GetListContractUser(){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('user_login', this.state.InfoContract.id_user.user_login);

        if(this.state.InfoContract.id_card.type===5){
            formData.append('type_card', 5);
        }

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/historyuser", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            var HistoryUser=[];

            if(data.status==='ok'){
                HistoryUser=data.data;
            }

            self.setState({
                HistoryUser:HistoryUser
            });


        }).catch((e)=>{

        });


    }
    setPageAction(number=null){

        var isMutiple=this.state.isMutiple;

        var type_upgrade=this.state.type_upgrade;

        if(number===1){
            this.GetListContractUser();
            isMutiple=true;
            type_upgrade=2;
        }


        this.setState({
            page:number,
            isMutiple:isMutiple,
            type_upgrade:type_upgrade
        });

    }

    Step0(){

        return(
            <div className={"col-12"}>

                <div className="col-12 col-sm-12 col-md-12">
                    <h2 className="d-inline-block mb-3 font18 text-dark border-primary border-bottom pb-2 font-weight-bold">Bước 1 : Upgrade và Renew</h2>
                </div>


                <div className={"alert alert-danger mb-4 font14 text-center rounded-0"}>
                    <i className="fab fa-ups me-1"></i> Vui lòng chọn loại Upgrade một hoặc nhiều
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-sm-12 col-md-12 col-xl-6 offset-xl-3"}>

                        <div className={"row"}>

                            <div className={"col-12 col-sm-12 col-lg-6 pb-3"}>
                                <a  onClick={()=>{
                                    this.setPageAction(1);
                                }} className={"btn btn-danger rounded-0 w-100 d-block ps-5 pe-5 p-2"}>
                                    <i className="fab fa-ups me-1"></i>  Upgrade nhiều HD <i className="fas fa-caret-right ms-2" />
                                </a>
                            </div>
                            <div className={"col-12 col-sm-12 col-lg-6 pb-3"}>
                                <a  onClick={()=>{
                                    this.setPageAction(2);
                                }} className={"btn btn-primary rounded-0 w-100 d-block ps-5 pe-5 p-2"}>
                                    Upgrade và Renew HD <i className="fas fa-caret-right ms-2" />
                                </a>
                            </div>


                        </div>

                    </div>

                </div>


            </div>
        );
    }

    RemoveItemFromUpgrade(data){

        var ListContractUpgrade=this.state.ListContractUpgrade;

        var UpdateList=[];

        ListContractUpgrade.map(function(item){
            if(item.key!==data._id){
                UpdateList.push({
                    key:data._id,
                    value:data
                });
            }
        });

        this.setState({
            ListContractUpgrade:UpdateList
        });

    }

    AddItemFromUpgrade(data){

        var ListContractUpgrade=this.state.ListContractUpgrade;

        ListContractUpgrade.push({
            key:data._id,
            value:data
        });

        this.setState({
            ListContractUpgrade:ListContractUpgrade
        });

    }

    getTotalPriceSaleUpgrade(){

        var ListContractUpgrade=this.state.ListContractUpgrade;

        var response=0;

        if(this.state.isMutiple===true){
            ListContractUpgrade.map(function(item){
                response+=Math.ceil(item.value.info_contract_data.price_sale);
            });
        }
        else{
            response+=this.state.InfoContract.info_contract_data.price_sale;
        }

        return response;
    }

    getTotalPriceremaining(){

        var ListContractUpgrade=this.state.ListContractUpgrade;

        var response=0;

        if(this.state.isMutiple===true){

            ListContractUpgrade.map(function(item){
                response+=Math.ceil(item.value.billing_information.total_price-item.value.info_contract_data.money_used);
            });
        }
        else{
            response+=Math.ceil(this.state.InfoContract.billing_information.total_price-this.state.InfoContract.info_contract_data.money_used);
        }

        return response;
    }

    ContinueUpgrade(){

        var number_error=0;

        var ListContractUpgrade=this.state.ListContractUpgrade;

        if(ListContractUpgrade.length===0){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng chọn HD Upgrade");
        }
        else{
            this.setPageAction(3);
        }
    }

    RenderHistoryContract(){
        const self=this;
        return(

            <div className={"col-12"}>

                <div className={"col-12 box-table-fix box-table-w150"}>
                    <div className={"overflow-auto col-12"}>
                        <table className={"mt-3 w-100 table border-0 mw2000 table-style-old"}>
                            <thead>
                            <tr className={"border-bottom bg-light"}>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-credit-card me-2" />
                                    DỊCH VỤ</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-file-excel me-2" />
                                    SỐ HD</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-question-circle me-2" />
                                    TRẠNG THÁI</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-dollar-sign me-2" />
                                    GIÁ TRỊ HD</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-clock me-2" />
                                    NGÀY BẮT ĐẦU</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-history me-2" />
                                    NGÀY KẾT THÚC</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-calendar-times me-2" />
                                    TỔNG SỐ BUỔI</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="far fa-calendar-times me-2" />
                                    TỔNG SỐ BUỔI CÒN LẠI</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-user-edit me-2" />
                                    NHÂN VIÊN BÁN HÀNG</td>
                                <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                    <i className="fas fa-info me-2" />
                                    PT PHỤ TRÁCH</td>

                                <td className={"font-weight-bold text-uppercase font14 p-2 mw150px fix-item-table"}>
                                    <i className="fas fa-directions me-2" />
                                    THAO TÁC</td>

                            </tr>
                            </thead>
                            <tbody>
                            {this.state.HistoryUser.map(function(value,index){
                                return(
                                    <tr>
                                        <td>
                                            <span className={"me-2"}>{value.id_card.name}</span>
                                            {value.id_card.type===1?(<span className={"badge p-1 bg-primary"}>PT</span>):(
                                                <></>
                                            )}

                                            {value.id_card.type===2?(<span className={"badge p-1 bg-primary"}>MB</span>):(
                                                <></>
                                            )}
                                            {value.id_card.type===3?(<span className={"badge p-1 bg-warning"}>LH</span>):(
                                                <></>
                                            )} {value.id_card.type===4?(<span className={"badge p-1 bg-primary"}>CD</span>):(
                                            <></>
                                        )}
                                        </td>

                                        <td>
                                            <span className={"me-1"}>{value.number_contract}</span>
                                            {status_payment(value)}
                                        </td>
                                        <td>
                                            {status_contract(value)}
                                        </td>

                                        <td>
                                       <span className={"text-red font-weight-bold"}>
                                           {formatNumber(value.info_contract_data.price_sale)} VNĐ
                                       </span>
                                        </td>

                                        <td>
                                            <span>{timeConverterDate(value.start_day)}</span>
                                        </td>

                                        <td>
                                            <span>{timeConverterDate(value.end_day_contract)}</span>
                                        </td>

                                        <td>
                                            {value.id_card.type!==2?(
                                                <span>{value.info_contract_data.number_card}</span>
                                            ):(<></>)}

                                        </td>

                                        <td>

                                            {value.id_card.type!==2?(
                                                <>
                                                    {number_did_contract(value)}
                                                </>
                                            ):(<></>)}


                                        </td>

                                        <td>
                                            <span>{value.id_admin_create.username+ " - "+value.id_admin_create.code_user}</span>
                                        </td>

                                        <td>
                                       <span>{value.id_admin_pt!==null?(
                                           value.id_admin_pt.username+" - "+value.id_admin_pt.code_user
                                       ):(<></>)}</span>
                                        </td>


                                        <td className={"fix-item-table mw150px"}>

                                            <a onClick={()=>{
                                                self.setState({
                                                    DataDetailContract:value
                                                });
                                            }} className={"rounded-0 font12 d-inline-block btn btn-outline-info p-2 me-1"}>
                                                <i className={"far fa-folder-open"}></i>
                                            </a>


                                            {value.status===1 || value.status===2 ?(
                                                <>
                                                    {get_item_in_array("key",value._id,self.state.ListContractUpgrade)!==null?(
                                                        <>
                                                            <a onClick={()=>{
                                                                self.RemoveItemFromUpgrade(value);
                                                            }} className={"rounded-0 font12 d-inline-block btn btn-danger p-2 me-1"}>
                                                                <i className="fas fa-minus"></i>
                                                            </a>
                                                        </>
                                                    ):(
                                                        <>
                                                            <a onClick={()=>{
                                                                self.AddItemFromUpgrade(value);
                                                            }} className={"rounded-0 font12 d-inline-block btn btn-primary p-2 me-1"}>
                                                                <i className="fas fa-plus"></i>
                                                            </a>
                                                        </>
                                                    )}
                                                </>
                                            ):(<></>)}



                                        </td>


                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className={"col-12 text-end mt-4"}>

                    <div className={"col-12 col-sm-12 col-xl-6 offset-xl-6"}>

                        <div className={"row"}>

                            <div className={"col-12 col-sm-6"}>

                            </div>

                            <div className={"col-12 col-sm-6"}>

                                <div className={"col-12 mb-3 font15"}>

                                    <div className={"row"}>

                                        <div className={"col-6 font-weight-bold mb-2"}>
                                            <span>Tổng giá trị HD</span>
                                        </div>
                                        <div className={"col-6 font-weight-bold mb-2"}>
                                            <span className={"text-red"}>{formatNumber(this.getTotalPriceSaleUpgrade())} VNĐ</span>
                                        </div>
                                        <div className={"col-6 mb-2"}>
                                            <span>Tổng tiền HD còn lại</span>
                                        </div>
                                        <div className={"col-6 mb-2"}>
                                            <span className={"text-red"}>{formatNumber(this.getTotalPriceremaining())} VNĐ</span>
                                        </div>

                                    </div>

                                </div>


                                <a  onClick={()=>{
                                    this.ContinueUpgrade();
                                }} className={"btn btn-primary rounded-0 w-100 d-block ps-5 pe-5 p-2"}>
                                    <i className="fab fa-ups me-1"></i> Tiếp tục <i className="fas fa-caret-right ms-2" />
                                </a>

                            </div>



                        </div>


                    </div>

                </div>

            </div>
        );

    }

    Step1(){
        return(
            <>
                <div className={"col-12"}>

                    <div className="col-12 col-sm-12 col-md-12">
                        <h2 className="d-inline-block mb-3 font18 text-dark font-weight-bold border-primary border-bottom pb-2">Bước 2 : Xác nhận HD</h2>
                    </div>

                    {this.state.HistoryUser!==null?(
                        Object.keys(this.state.HistoryUser).length>0?(
                            this.RenderHistoryContract()
                        ):(
                            <div className={"alert alert-danger"}>
                               Không có HD nào
                            </div>
                        )
                    ):(
                        <div className={"col-12 d-flex justify-content-center align-self-center"}>
                            <ItemLoading />
                        </div>
                    )}



                </div>

            </>
        );
    }

    RenderView(){

        switch (this.state.page) {
            case 0:
                return this.Step0();
            case 1:
                return this.Step1();
            case 2:
                return this.Step2();
            case 3:
                return this.Step3();
        }

    }

    getDetailContract(id){

        var AppThis=this.props.AppThis;

        const formData = new FormData();

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('_id', id);

        const self=this;

        fetch(AppThis.ConstApp.home_api+this.props.path+"/infomation", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                self.setState({
                    DataDetailContract:data.data
                });
            }

            AppThis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
            AppThis.PageLoad(false);

        });

    }

    render() {

        const self=this;
        return(
            <div className="modal d-block position-fixed">

                <div className={"modal-dialog modal-lg modal-fullscreen"}>

                    <div className="modal-content border-0">
                        <div className="modal-header p-2 pb-2">
                            <div className={"container position-relative"}>
                                <strong className={"font18 text-white text-uppercase"}>
                                    <i className="fas fa-sync-alt me-2" />
                                    Nâng cấp HD</strong>

                                <a onClick={()=>{
                                    this.props.onClose();
                                }} className={"text-white font-weight-bold position-absolute top-0 right-0 font15"}>
                                    <i className="far fa-times-circle" /> Đóng
                                </a>

                            </div>
                        </div>
                        <div className="modal-body bg-white">

                            <div className={"container p-0 pb-5"}>

                                <div className={"row mb-2 pt-3"}>

                                    <div className={"col-12 mt-3 mb-5 wizard-template d-none d-lg-block"}>
                                        <ul className="m-0 p-0 position-relative nav nav-pills nav-fill">
                                            <li className="nav-item">
                                            <span className="font-weight-bold text-uppercase d-block w-100 font16">Bước 1</span>
                                                <i className="position-relative font26 mt-3 mb-3 bg-primary m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fab fa-ups" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Upgrade và Renew </span>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                            <span
                                                className="font-weight-bold text-uppercase d-block w-100 font16">Bước 2</span>
                                                <i className="position-relative font26 mt-3 mb-3 bg-danger m-auto rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-user-check" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Xác nhận HD </span>
                                                </div>
                                            </li>

                                            <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 3</span>
                                                <i className="position-relative m-auto mt-3 mb-3 font26 bg-warning rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard fas fa-info" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Thông tin gói tập</span>
                                                </div>
                                            </li>
                                            <li className="nav-item">
                                            <span
                                                className="title-wizard font-weight-bold text-uppercase d-block w-100 font16">Bước 4</span>
                                                <i className="position-relative m-auto mt-3 mb-3 font26 bg-info rounded-circle text-center d-flex justify-content-center align-items-center text-white icon-wizard far fa-check-circle" />
                                                <div className="col-12 p-0">
                                                    <span className="description-wizard"> Xác nhận từ quản lý</span>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    {this.state.InfoContract!==null?(
                                        this.RenderView()
                                    ):(
                                        <div className={"col-12 d-flex justify-content-center align-items-center pt-4 pb-4"}>
                                            <ItemLoading />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {this.state.InfoUser.status===true?(
                    <ModalUser onClose={()=>{
                        self.setState({
                            InfoUser:{
                                status:false,
                                data:{}
                            }});
                    }} path={this.props.path} data={this.state.InfoUser.data} SubmitUser={(response)=>{
                        self.UpdateSubUser(response);
                    }} AppThis={this.props.AppThis} />
                ):(
                    <></>
                )}



                {this.state.DataDetailContract!==null?(
                    <DetailContract OnClose={()=>{
                        this.setState({
                            DataDetailContract:null
                        });

                    }} EventDetail={(id)=>{
                        this.setState({
                            DataDetailContract:null
                        },function(){
                            self.getDetailContract(id);
                        });
                    }} data={this.state.DataDetailContract} />
                ):(
                    <></>
                )}




                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );



    }

}
