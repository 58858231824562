/* eslint-disable */
import React,{Component} from "react";

export default class SupportBrowser extends Component {


    render(){

        return(
            <div className={"col-12 mh-100vh w-100 d-flex flex-row flex-wrap align-items-center justify-content-center"}>

                <div className={"col-12 container text-center"}>

                    <h1 className={"mb-3"}>Trình duyệt hỗ trợ</h1>

                            <div className={"Icon-SupportBrowser m-3 Icon-Chrome"}></div>

                            <div className={"Icon-SupportBrowser m-3 Icon-Safari"}></div>

                            <div className={"Icon-SupportBrowser m-3 Icon-Firefox"}></div>

                            <div className={"Icon-SupportBrowser m-3 Icon-Edge"}></div>




                </div>

            </div>
        );

    }

}
