/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {
    _isset_string,
    base64_encode,
} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import ModalReport from "../../global/ModalReport";
import Floater from 'react-floater';

const INIT_STATE={
    data:null,
    query:{
        id_admin:null,
        name_report:null,
    },
    AllAdmin:null,
    AllNameReport:null,
    page:0,
    DataModal:{
        status:false,
        data:null
    },
    AllAddress:null
};

export default class autoreport extends Component{

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){
        var get_query=this.state.query;
        var query_send={};
        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }



        formData.append('page', this.state.page);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"autoreport", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.getData();
        this.GetAdmin();
        this.GetNameReport();

        this.GetAddress();
    }

    GetAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"autoreport/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                self.setState({
                    AllAdmin:data.data
                });
            }



        }).catch((e)=>{



        });


    }

    GetNameReport(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"autoreport/getname", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    AllNameReport:data.data
                });
            }

        }).catch((e)=>{



        });


    }

    GetAddress(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('type', 3);

        const self=this;

        fetch(Appthis.ConstApp.home_api+"address", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                var address=[{'name':"Tất cả","_id":"all"}];

               var AllAddress=address.concat(data.data);
                self.setState({
                    AllAddress:AllAddress
                });
            }

        }).catch((e)=>{



        });


    }

    AlertInActive(id){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn ngừng kích hoạt</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ItemEvent(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    AlertActive(id){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn kích hoạt</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ItemEvent(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }

    async ItemEvent(id){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id',id);

        var get_status=await fetch(Appthis.ConstApp.home_api+"autoreport/status", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

         get_status=await get_status.json();

        if(get_status.status==='error'){
            toast("Thao tác thất bại");
        }
        else{
            toast("Thao tác thành công");
        }

        this.getData(false);

        Appthis.PageLoad(false);

    }

    OpenModal(data=null){


        var DataModal={
            status:true,
            data:{
                id_admin:null,
                name_report:null,
                id_address:[],
                _id:null
            }
        };


        if(data!==null){

            if(data['id_address'].includes('all')){

                data.id_address=[{'name':"Tất cả","_id":"all"}];
            }

             DataModal={
                status:true,
                data:{
                    id_admin:data.id_admin._id,
                    name_report:data.key_name_report,
                    id_address:data.id_address,
                    _id:data._id
                }
            };

        }

        this.setState({
            DataModal:DataModal
        });

    }

    ResetDataModal(){
        var Reset_DataModal={
            status:false,
            data:null,
        };

        this.setState({
            DataModal:Reset_DataModal
        })

    }


    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>STT</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Tài khoản</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Loại báo cáo</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Địa chỉ</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>Thao tác</td>
                        </tr>
                        </thead>

                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>
                                    <td>
                                        {index+1}
                                    </td>
                                    <td>{value['id_admin']?(value['id_admin']['username']+" "+value['id_admin']['code_user']):("Chưa rõ")}</td>
                                    <td>{value['name_report']}</td>
                                    <td>
                                        {value['id_address'].includes('all')?('Tất cả'):(
                                            value['id_address'].map(function(item){
                                                return(
                                                    <span className={"badge bg-success p-1 font10 m-1"}>
                                                    {item.name}
                                                </span>
                                                );
                                            })
                                        )}

                                    </td>
                                    <td>
                                        {value['visible']===1?(
                                            <span className={"badge p-1 bg-primary"}>Active</span>
                                        ):(
                                            <span className={"badge p-1 bg-danger"}>InActive</span>
                                        )}
                                    </td>
                                    <td className={"fix-item-table"}>

                                        <a onClick={()=>{

                                            self.OpenModal(value);


                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Sửa tài khoản">
                                            <i className="fas font12 fa-edit" />
                                            </Floater>
                                        </a>


                                        {value.visible===1?(
                                            <a onClick={()=>{
                                                self.AlertInActive(value._id);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Ngừng kích hoạt">
                                                <i className="fas fa-minus" />
                                                </Floater>
                                            </a>
                                        ):(
                                            <a onClick={()=>{
                                                self.AlertActive(value._id);
                                            }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                                <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="kích hoạt">
                                                <i className="far fa-check-circle" />
                                                </Floater>
                                            </a>
                                        )}


                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>

            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }


    AlertGetUrlConnectGoogle(){
        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn thực hiện thao tác này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.getUrlConnectGoogle();
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });
    }
    getUrlConnectGoogle(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        Appthis.PageLoad(true);

        fetch(Appthis.ConstApp.home_api+"autoreport/googledriver", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{
                toast("Tạo liên kết thành công");

                window.location.href=data.data;
            }

            Appthis.PageLoad(false);


        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");

                Appthis.PageLoad(false);


        });

    }

    render(){
        const self=this;
        if(this.state.data!==null){

            return(
                <div className={"container-fluid container-main-template mb-5"}>


                    <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                        <div className="row m-0">

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 d-flex align-items-center">
                                <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Báo cáo tự động</h3>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 text-right text-end">

                                <div className="col-12 p-0 mb-2">
                                    Tổng bản ghi : {this.state.data.total_row}
                                </div>

                                <div className={"col-12"}>
                                    <button onClick={()=>{
                                        this.setState({
                                            ModalSearchMobile:true
                                        });
                                    }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                </div>


                                <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                    <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                        <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                            <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                            <a onClick={()=>{
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                <i className="fas fa-times"></i>
                                            </a>
                                        </div>



                                        <a onClick={()=>{
                                            this.AlertGetUrlConnectGoogle();
                                        }} className="font13 btn me-1 btn-outline-primary rounded-0 btn-event-search">
                                            <i className="fab fa-google-drive" />Kết nối google driver </a>


                                        {this.state.AllAdmin!==null?(
                                            <select onChange={(event)=>{
                                                var query=this.state.query;
                                                query['id_admin']=event.target.value;
                                                this.setState({
                                                    query:query
                                                });

                                            }} className={"w-auto font13 me-1 form-control rounded-0"}>
                                                <option value={""}>Chọn tài khoản</option>
                                                {this.state.AllAdmin.map(function(item){
                                                    return(
                                                        <option value={item.key}>{item.value}</option>
                                                    );
                                                })}
                                            </select>
                                        ):(
                                            <ItemLoading />
                                        )}


                                        {this.state.AllNameReport!==null?(
                                            <select onChange={(event)=>{
                                                var query=this.state.query;
                                                query['name_report']=event.target.value;
                                                this.setState({
                                                    query:query
                                                });
                                            }} className={"w-auto font13 me-1 form-control rounded-0"}>
                                                <option value={""}>Chọn loại báo cáo</option>

                                                {this.state.AllNameReport.map(function(value,key){
                                                    return(
                                                        <optgroup label={value.name}>
                                                            {Object.entries(value['group']).map(function(item){
                                                                return(
                                                                    <option value={item[0]}>{item[1]}</option>
                                                                );
                                                            })}
                                                        </optgroup>
                                                    );
                                                })}
                                            </select>):(
                                            <ItemLoading />
                                        )}

                                        <a onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:false
                                            });
                                            this.getData(true);
                                        }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                            <i className="fas fa-search" />Tìm kiếm </a>

                                        <a onClick={()=>{
                                            this.OpenModal(null);
                                        }} className="me-2 ibtn btn rounded-0 btn-outline-danger font13 font-weight-600"><i className="fas fa-plus-circle" /> Thêm mới</a>





                                    </div>

                                </div>




                            </div>

                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            {this.thisRenderData()}
                        </div>


                        <div className={"col-12 ps-3 pe-3"}>
                            <ul className="pagination">
                                {this.state.data.prev>=0?(
                                    <li className="page-item" >
                                        <a className="page-link" onClick={()=>{
                                            var page=this.state.page-1;
                                            this.setState({
                                                page:page
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Trước
                                        </a>
                                    </li>
                                ):(<></>)}

                                <li className="page-item">
                                    <a className="page-link">
                                        <span>{this.state.data.curent+1}</span>
                                    </a></li>

                                {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                    <li className="page-item">
                                        <a className="page-link" onClick={()=>{
                                            this.setState({
                                                page:this.state.data.next
                                            },function(){
                                                self.getData(true);
                                            });
                                        }}>
                                            Kế tiếp
                                        </a>
                                    </li>
                                ):(<></>)}

                            </ul>
                        </div>


                        {this.state.DataModal.status===true?(
                            <ModalReport size={"small"} onClose={()=>{
                                this.ResetDataModal();
                            }} GetDataSubmit={(response)=>{
                                this.UpdateAutoReport(response);

                            }} AppThis={this.props.AppThis} AllAddress={this.state.AllAddress} AllNameReport={this.state.AllNameReport} AllAdmin={this.state.AllAdmin} data={this.state.DataModal.data} />
                        ):(<></>)}




                    </div>


                </div>
            );
        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }

    async UpdateAutoReport(response){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        if(this.state.DataModal.data._id!==null){
            response['_id']=this.state.DataModal.data._id;
        }
        formData.append('data', base64_encode(response));

        var get_data=await fetch(Appthis.ConstApp.home_api+"autoreport/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        });

        get_data=await get_data.json();


        this.ResetDataModal();

        if(get_data.status==='ok'){
            toast("Cập nhật dữ liệu thành công");
            await this.getData();

        }
        else{
            toast("Cập nhật dữ liệu thất bại");
        }


        Appthis.PageLoad(false);



    }


}
