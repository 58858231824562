/* eslint-disable */
import React,{Component} from "react";
import GroupLoading from "./GroupLoading";

export default class AppLoading extends Component{

    componentDidMount() {

        this.props.AppThis.SetActionModule(this);

        this.VerifyToken();
    }

    async VerifyToken(){

        await this.props.AppThis.Start_App();

        if(this.props.AppThis.getToken()===null){

            this.props.AppThis.SetUrlRedirect("/login");
        }
        else{
            this.props.AppThis.SetUrlRedirect("/dashboard");
        }
    }

    render() {
        return(<GroupLoading />);
    }
}
