/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../../global/ItemLoading";
import lodash from "lodash";
import {toast} from "react-toastify";
import {_isset_string, array_to_select, base64_encode, timeConverter} from "../../helper/array";
import { confirmAlert } from 'react-confirm-alert';
import InfoUser from "../../global/user/InfoUser";
import ContractUser from "../../global/contract/ContractUser";
import FingerPrintUser from "../../global/finger/FingerPrintUser";
import Floater from 'react-floater';
import ModalUser from "../../global/user/ModalUser";

const INIT_STATE={
    data:null,
    query:{
        input_search:null,
    },
    InfoUser:null,
    page:0,
    ContractUser:null,
    UserFingerprint:null,
    Statics:null,
    DataModal:{
        status:false,
        data:{}
    },
};

export default class clubinfopersonal extends Component {

    constructor(props) {
        super(props);
        this.state=lodash.cloneDeep(INIT_STATE);
    }

    getQuerySearch(){

        var get_query=this.state.query;

        var query_send={};

        Object.entries(get_query).map(function(item){
            var [key,value]=item;
            if(value!==null && value!=='none'){
                query_send[key]=value;
            }
        });

        return query_send;
    }

    UpdateQuerySearch(key,value){

        var query=this.state.query;

        query[key]=value;

        this.setState({
            query:query
        });

    }


    OpenModal(data){
        var DataModal={
            status:true,
            data:data
        };
        this.setState({
            DataModal:DataModal
        });

    }

    getData(load=false){

        if(load===true){
            this.props.AppThis.PageLoad(true);
        }

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());
        formData.append('id_address', Appthis.getAddress());

        var query_send=this.getQuerySearch();

        if(Object.keys(query_send).length>0){
            formData.append('query', base64_encode(query_send));
        }

        formData.append('page', this.state.page);

        const self=this;
        fetch(Appthis.ConstApp.home_api+"clubinfopersonal", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Lấy dữ liệu thất bại");
            }
            else{

                self.setState({
                    data:data.data
                });
            }

            if(load===true) {
                Appthis.PageLoad(false);
            }

        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");
            if(load===true) {
                Appthis.PageLoad(false);
            }

        });


    }

    componentWillUnmount() {
        this.props.AppThis.RemoveActionModule();
    }

    componentDidMount() {
        this.props.AppThis.SetActionModule(this);
        this.getData();
        this.GetStatics();
    }

    GetStatics(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+"clubinfopersonal/statics", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    Statics:data.data
                });
            }


        }).catch((e)=>{


        });


    }


    SubmitUser(response){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('data', base64_encode(response));

        this.setState({
            DataModal:{
                status:false,
                data:{}
            }
        });

        const self=this;
        fetch(Appthis.ConstApp.home_api+"clubinfopersonal/add", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thêm dữ liệu thất bại");
                            Appthis.PageLoad(false);
            }
            else{
                toast("Cập nhật thành công");
                self.getData(true);
            }


        }).catch((e)=>{

            toast("Lấy dữ liệu thất bại");

            Appthis.PageLoad(false);

        });


    }



    AlertResetPassword(id){

        const self=this;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Cảnh báo !</h1>
                        <p>Bạn có chắc chắn muốn cấp lại mật khẩu cho người dùng này ?</p>
                        <button onClick={onClose}>Không</button>
                        <button
                            onClick={() => {
                                self.ResetPassword(id);
                                onClose();
                            }}
                        > Vâng
                        </button>
                    </div>
                );
            }
        });

    }

    ResetPassword(id){

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('_id', id);

        fetch(Appthis.ConstApp.home_api+"clubinfopersonal/reset", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='error'){
                toast("Thao tác thất bại");
            }
            else{
                toast("Thao tác thành công");

                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <h1>Thông báo !</h1>
                                <p>Mật khẩu đã đặt lại : {data.data}</p>
                                <button
                                    onClick={() => {
                                        onClose();
                                    }}
                                > Đồng ý
                                </button>
                            </div>
                        );
                    }
                });


            }
            Appthis.PageLoad(false);

        }).catch((e)=>{
            toast("Thao tác thất bại");
        });


    }

    getContractUser(data){

        this.setState({
            ContractUser:data
        });
    }

    RenderStatics(){

        var Statics=this.state.Statics;

        if(Statics!==null){
            return(
                <div className={"row m-0 mt-4"}>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center far fa-handshake rounded-circle bg-warning"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">KH nguồn marketing</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.from_marketing}</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-2 item-admin-dashboard">
                        <div className="col-12 d-flex bg-white p-2 shadow box-item-dashboard">
                            <div
                                className="left-item me-3 text-center d-flex justify-content-center align-items-center font26">
                                <i className="d-flex justify-content-center text-white align-items-center fas fa-tenge rounded-circle bg-danger"></i>
                            </div>
                            <div className="right-item">
                                <p className="text-uppercase mt-2 mb-0 text-dark font14">KH tự nhiên</p>
                                <p className="number-info-dashboard m-0 font16 font-weight-bold">{Statics.from_nature}</p>
                            </div>
                        </div>
                    </div>


                </div>
            );
        }
        else{
            return(
                <div className={"col-12 justify-content-center d-flex flex-row mb-4 pt-5"}>
                    <ItemLoading />
                </div>
            );
        }
    }


    thisRenderData(){

        const self=this;

        if(this.state.data.data.length>0){

            return(
                <div className={"col-12 box-table-fix"}>
                <div className={"col-12 overflow-auto"}>
                    <table className={"mt-3 w-100 table border-0 table-style-old"}>
                        <thead>
                        <tr className={"border-bottom bg-light"}>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-user-circle me-1" />
                                Tên khách hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-mobile-alt me-1" />
                                SDT/ID</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-user-edit me-1" />
                                Mã khách hàng</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="far fa-clock me-1" />
                                Ngày tạo</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2"}>
                                <i className="fas fa-ban me-1" />
                                Trạng thái</td>
                            <td className={"font-weight-bold text-uppercase font14 p-2 fix-item-table"}>
                                <i className="fas fa-directions me-1" />
                                Thao tác</td>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.data.data.map(function(value,index){
                            return(
                                <tr>

                                    <td>{value['username']?value['username']:value['tmp_user']}</td>
                                    <td>{value['user_login']}
                                        {value.is_phone!==null?(
                                            <>
                                                <span className={"text-danger font-weight-bold font10 ms-2"}>{value.is_phone}</span>
                                            </>
                                        ):(<></>)}
                                    </td>
                                    <td>{value['code_user']}</td>
                                    <td>{value['time_create']?timeConverter(value['time_create']):'Chưa rõ'}</td>
                                    <td>{value['visible']===1?(
                                        <span className={"badge bg-success p-1 font11"}>Active</span>
                                    ):(
                                        <span className={"badge bg-danger p-1 font11"}>InActive</span>
                                    )}</td>

                                    <td className={"fix-item-table"}>


                                     <a onClick={()=>{
                                            self.OpenModal(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-primary p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Sửa tài khoản">
                                            <i className="fas font12 fa-edit" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.setState({
                                                InfoUser:value
                                            });
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-success p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Chi tiết tài khoản">
                                                <i className="fas fa-info" />
                                            </Floater>
                                        </a>

                                        <a onClick={()=>{
                                            self.AlertResetPassword(value._id);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-warning p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Cấp lại mật khẩu">
                                                <i className="fas fa-unlock-alt" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.getContractUser(value);
                                        }} className="rounded-0 d-inline-block ms-1 me-1 btn btn-outline-danger p-1 ps-2 pe-2">
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Danh sách HD của khách hàng">
                                                <i className="fas fa-file-contract" />
                                            </Floater>
                                        </a>


                                        <a onClick={()=>{
                                            self.setState({
                                                UserFingerprint:value
                                            });
                                        }} className={"rounded-0 font12 d-inline-block btn btn-outline-warning p-2 me-1"}>
                                            <Floater event={"hover"} title={"Chức năng"} placement={"top"} content="Quản lý vân tay tài khoản">
                                                <i className="fas fa-fingerprint" />
                                            </Floater>
                                        </a>



                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                </div>

            );
        }
        else{
            return(
                <div className={"alert mt-3 alert-danger"}>
                    Không có dữ liệu
                </div>
            );
        }
    }

    render(){
        const self=this;

        if(this.state.data!==null){

            if(this.state.ContractUser!==null){
                return(
                    <ContractUser onClose={()=>{
                        self.setState({
                            ContractUser:null
                        });
                    }} path={"clubinfopersonal"} AppThis={this.props.AppThis} data={this.state.ContractUser} />
                );
            }
            else{

                return(
                    <div className={"container-fluid container-main-template mb-5"}>

                        <div className={"col-12 pt-3 pb-3 mt-1 shadow bg-white"}>

                            <div className="row m-0">

                                <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
                                    <h3 className="font18  mb-0 text-uppercase font-weight-bold font-weight-bold"> Quản lý khách hàng</h3>
                                </div>

                                <div className="col-12 col-sm-12 text-right text-end">

                                    <div className="col-12 p-0 mb-2">
                                        Tổng bản ghi : {this.state.data.total_row}
                                    </div>


                                    <div className={"col-12"}>
                                        <button onClick={()=>{
                                            this.setState({
                                                ModalSearchMobile:true
                                            });
                                        }} type={"button"} className={"ModalSearchMobile font13 w-100 btn btn-danger p-2 rounded-0"}>Tìm kiếm nâng cao</button>
                                    </div>




                                    <div className={this.state.ModalSearchMobile===false || typeof this.state.ModalSearchMobile==='undefined'?("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end"):("w-100 action-right-menu-item position-relative d-inline-flex flex-row justify-content-end active-modal")}>

                                        <div className={'d-inline-flex flex-row box-action-right-menu-item flex-wrap'}>

                                            <div className={"header-modal-mobile position-relative w-100 d-flex flex-wrap flex-row"}>
                                                <p className={"title-modal-mobile font18 font-weight-bold text-uppercase"}>Tìm kiếm nâng cao</p>
                                                <a onClick={()=>{
                                                    this.setState({
                                                        ModalSearchMobile:false
                                                    });
                                                }} href={"#"} className={"close-modal-mobile font20 font-weight-bold"}>
                                                    <i className="fas fa-times"></i>
                                                </a>
                                            </div>


                                            <input onChange={(event)=>{
                                                this.UpdateQuerySearch("input_search",event.target.value);
                                            }} autoComplete={"off"} type="text" name="input_search"
                                                   className="font13 form-control d-inline-block w-auto rounded-0"
                                                   placeholder="Họ và tên/SĐT"/>

                                            <a onClick={()=>{
                                                this.getData(true);
                                                this.setState({
                                                    ModalSearchMobile:false
                                                });
                                            }} className="font13 btn me-1 btn-outline-success rounded-0 btn-event-search">
                                                <i className="fas fa-search" />Tìm kiếm </a>

                                        </div>

                                    </div>






                                </div>

                            </div>



                            {this.RenderStatics()}


                            <div className={"col-12 ps-3 pe-3"}>
                                {this.thisRenderData()}
                            </div>


                            <div className={"col-12 ps-3 pe-3"}>
                                <ul className="pagination">
                                    {this.state.data.prev>=0?(
                                        <li className="page-item" >
                                            <a className="page-link" onClick={()=>{
                                                var page=this.state.page-1;
                                                this.setState({
                                                    page:page
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Trước
                                            </a>
                                        </li>
                                    ):(<></>)}

                                    <li className="page-item">
                                        <a className="page-link">
                                            <span>{this.state.data.curent+1}</span>
                                        </a></li>

                                    {this.state.data.next>this.state.data.curent && this.state.data.next<this.state.data.total_page ?(
                                        <li className="page-item">
                                            <a className="page-link" onClick={()=>{
                                                this.setState({
                                                    page:this.state.data.next
                                                },function(){
                                                    self.getData(true);
                                                });
                                            }}>
                                                Kế tiếp
                                            </a>
                                        </li>
                                    ):(<></>)}

                                </ul>
                            </div>

                        </div>



                        {this.state.DataModal.status===true?(
                           <>

                           {Object.keys(this.state.DataModal['data']).length>0?(
                           <>
                            <ModalUser onClose={()=>{
                                self.setState({
                                    DataModal:{
                                        status:false,
                                        data:{}
                                    }});
                            }} path={"clubinfopersonal"} data={this.state.DataModal.data} DisableEditPhone={true} SubmitUser={(response)=>{
                                this.SubmitUser(response);
                            }} AppThis={this.props.AppThis} />
                           </>):(
                           <>
                            <ModalUser onClose={()=>{
                                self.setState({
                                    DataModal:{
                                        status:false,
                                        data:{}
                                    }});
                            }} path={"clubinfopersonal"} data={this.state.DataModal.data} SubmitUser={(response)=>{
                                this.SubmitUser(response);
                            }} AppThis={this.props.AppThis} />
                           </>
                           )}
                           
                           </>
                        ):(
                            <></>
                        )}


                        {this.state.InfoUser!==null?(

                            <InfoUser data={this.state.InfoUser} onClose={()=>{
                                this.setState({
                                    InfoUser:null
                                });
                            }} />
                        ):(<></>)}


                        {this.state.UserFingerprint!==null?(
                            <FingerPrintUser onClose={()=>{
                                this.setState({
                                    UserFingerprint:null
                                },function(){
                                    self.getData(true);
                                });

                            }}  AppThis={this.props.AppThis} path={"clubinfopersonal"} data={this.state.UserFingerprint} />
                        ):(
                            <></>
                        )}


                    </div>
                );
            }

        }
        else{

            return(
                <div className={"container-fluid text-center d-flex justify-content-center mt-5 container-main-template mb-5"}>
                    <ItemLoading size={"Large"} />
                </div>
            );
        }

    }


}
