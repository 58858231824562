/* eslint-disable */
import React,{Component} from "react";
import './App.css';
import Login from "./global/Login";
import Dashboard from "./global/Dashboard";
import AppLoading from "./global/AppLoading";
import { Navigate,Routes, Route} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConstApp from "./ConstApp";
import Header from "./global/Header";
import Cookies from 'js-cookie';
import Sidebar from "./global/Sidebar";
import Profile from "./global/Profile";
import ModuleRegister from "./ModuleRegister";
import Lottie from 'lottie-react-web'
import nointernet from './assets/file/nointernet.json';
import lodash from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { browserName } from 'react-device-detect';
const SupportBrowSerName=['mobile safari','safari','chrome','firefox','edge'];
import SupportBrowser from "./global/SupportBrowser";
import "@fancyapps/ui/dist/fancybox.css";
import ErrorPage from "./global/ErrorPage";
var TIMERUNAPP=new Date().getTime();

const INIT_STATE={
    token:null,
    profile:null,
    AppLoading:false,
    OpenMenu:false,
    current_address:null,
    UrlRedirect:null,
    IsOnline:navigator.onLine,
    VerifyAlert:null,
    SocketConnect:null,
    HiddenHeader:false,
    isCloseTabs:false,
    xPos:0,
    yPos:0,
    showMenu:false,
    InfoAddress:null
};

export default class App extends Component{

  constructor(props) {
    super(props);

    this.state=lodash.cloneDeep(INIT_STATE);

    this.ConstApp=ConstApp;

    this.ActionModule=null;

    this.getStatusOffline();

    this.UpdateCurrentTab();

  }
  GetInfoProfile(){
      return this.state.profile;
  }

  setVerifyAlert(data){
      this.setState({
          VerifyAlert:data
      });
  }

   getToken(){
      var response=this.state.token;
      if(response===null){
          this.DestroyToken();
          return null;
      }
      else{
          return response;
      }
  }

    SetHiddenHeader(){

      this.setState({
          HiddenHeader:true
      });

    }

    SetShowHeader(){

        this.setState({
            HiddenHeader:false
        });

    }
    setInfoAddress(data=null){
      this.setState({
          InfoAddress:data
      });

    }

    getInfoAddress(){
      return this.state.InfoAddress;
    }
    getAddress(){
        var response=this.state.current_address;
        if(response===null){
            this.DestroyToken();
            return null;
        }
        else{
            return response;
        }
    }

  async getStatusOffline(){

      var condition = navigator.onLine;

      if(this.state.IsOnline===true && condition===false){

          this.setState({
              IsOnline:false
          });

      }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

      this.getStatusOffline();

      this.verifyTab();

  }

  componentWillUnmount() {
      this.setState({
          isCloseTabs:true
      });
      document.removeEventListener("click", this.handleClick);
      document.removeEventListener("contextmenu", this.handleContextMenu);
      document.addEventListener("keydown", this.KeyEventFunction);

  }

    KeyEventFunction=(event)=>{




    }
    handleContextMenu = (e) => {
        e.preventDefault();
        this.setState({
            xPos: e.pageX,
            yPos: e.pageY,
            showMenu: true,
        });
    }

    async verifyTab(){

        var TimeConnect=await Cookies.get('TimeConnect');

        if(TIMERUNAPP!==null && typeof TimeConnect!=='undefined' && TimeConnect!==null && this.state.isCloseTabs===false){

            if(TIMERUNAPP!==Math.ceil(TimeConnect)){

                this.setState({
                    isCloseTabs:true
                });


            }


        }

    }


    async Start_App(){

      await this.getStatusOffline();

      await this.LoadToken();

      await this.LoadAddress();

       if(this.state.token!==null){

           await this.GetProfile();

       }
       else{
           this.DestroyToken(true);
       }
    }

    UpdateCurrentTab(){

          var curentTime=new Date().getTime();

           TIMERUNAPP=curentTime;

          Cookies.set('TimeConnect',curentTime,{
              expires:7
          });


    }

   async getOption(name=null){

      var response=null;

        try{
            const formData = new FormData();

            formData.append('token', this.state.token);

            formData.append('name', name);

            const get_data=await fetch(this.ConstApp.home_api+"options", {
                method: 'POST',
                mode: 'cors',
                body: formData
            });

            const data=await get_data.json();

            if(data.status==='error'){
                this.DestroyToken();
            }
            else{
                response=data.data;
            }
        }
        catch (e){
            this.DestroyToken();
        }

        return response;

    }

  SetActionModule(object){
      this.ActionModule=object;
  }

  RemoveActionModule(){
      this.ActionModule=null;
  }

 async setStateAsync(state) {

        return new Promise((resolve) => {
            this.setState(state, resolve)
        })

 }

 async LoadToken(){

          var _token_app=await Cookies.get('token_app');

          if(_token_app!==null && typeof _token_app!=='undefined'){

              await this.setStateAsync({
                  token:_token_app
              });


          }
    }

  async LoadAddress(){
        if(Cookies.get('current_address')){
            await this.setStateAsync({
                current_address:Cookies.get('current_address')
            });
        }
    }

    async SaveAddress(id){

      await this.setStateAsync({current_address:id});

      Cookies.set('current_address', id,{
          expires:7
      });

  }

  async SetUrlRedirect(url){

      await this.setStateAsync({
          UrlRedirect: url,
          OpenMenu:false
      });


  }

  async SaveToken(token){

      await this.setStateAsync({token:token});

      Cookies.set('token_app', token,{
          expires:7
      });
  }

  PageLoad(status){
      this.setState({
          AppLoading:status
      });
  }

  async GetProfile(){

      if(this.state.token!==null){

          try{
              const formData = new FormData();

              formData.append('token', this.state.token);

              formData.append('device_info', navigator.userAgent);

              const get_data=await fetch(this.ConstApp.home_api+"login", {
                  method: 'POST',
                  mode: 'cors',
                  body: formData
              });

              const data=await get_data.json();

              if(data.status==='error'){
                  this.DestroyToken();
                  this.SetUrlRedirect("/login")
              }
              else{
                  await this.setStateAsync({
                      profile:data.data
                  });

                  if(this.state.current_address!==null){
                      this.VerifyProfile();
                  }

              }
          }
          catch (e){
              this.DestroyToken();
              this.SetUrlRedirect("/login")
          }
      }

      this.forceUpdate();
  }

    VerifyProfile(){

      var profile=this.state.profile;

      var number_error=0;

        if(Object.keys(profile['data_profile']).length>0){

            var args_required=['current_address','degree','schools','certificate','citizen_identification','day_citizen_identification','address_citizen_identification','permanent_residence','ethnic','religion','name_protector','phone_protector'];

            args_required.map(function(item){

                if(profile['data_profile'][item]===null || typeof profile['data_profile'][item]==='undefined' || profile['data_profile'][item].length<2){
                    number_error+=1;
                }

            });

        }
        else{
            number_error+=1;
        }

      if(number_error>0){

          this.setVerifyAlert({
              message:"Vui lòng cập nhật thông tin tài khoản",
              path:"/profile"
          });
      }

    }

  DestroyToken(redirect=true){

      this.setState(lodash.cloneDeep(INIT_STATE));

      Cookies.remove('token_app');
      Cookies.remove('current_address');
      if(redirect===true){
          this.SetUrlRedirect("/login");
      }
  }
  EventRedirect(){

      if(this.state.UrlRedirect!==null){

          var GetUrlRedirect=this.state.UrlRedirect;

          this.setState({
              UrlRedirect:null
          });

          return(<Navigate to={GetUrlRedirect} replace={true}  />);
      }
      else{
          return(<></>);
      }
  }


  componentDidMount(){

          this.Start_App();

          const self=this;

          setInterval(function(){
              self.verifyTab();
          },1000);

          document.addEventListener("click", this.handleClick);

          document.addEventListener("contextmenu", this.handleContextMenu);

  }

    handleClick = (e) => {
        if (this.state.showMenu) this.setState({ showMenu: false });
    }


    RegisterSocket(callback){

        if(this.state.current_address!==null && this.state.SocketConnect===null){

            const conn = new WebSocket("wss://phongmkt.com/wss/");

            var get_token=this.state.token;

            var id_address=this.state.current_address;

            conn.onopen = function (e) {
                var data_send={};
                data_send.token=get_token;
                data_send.action="BrowserLogin";
                data_send.id_address=id_address;
                conn.send(JSON.stringify(data_send));
            };

            this.setState({
                SocketConnect:conn
            },function(){
                callback();
            });


        }


    }

    getSocketConnect(){
      return this.state.SocketConnect;
    }

    GlobalAdmin(component){

       if(this.state.token!==null){
          return(<div className={"Main-App-Dev"}>

                  {this.state.HiddenHeader===false?(
                      <Header AppThis={this} />
                  ):(<></>)}

                  <Sidebar AppThis={this} />
                  <main className={"Component-"+window.location.pathname.replace("/","")}>
                      {component}
                  </main>
              </div>
          );

      }
      else{
         return <Navigate to={"/"} replace={true} />
      }

  }

    RegisterSubModule(args,type){

      var name_folder="admin";

      switch (type) {

          case 1:
              name_folder="admin";
              break;
          case 2:
              name_folder="marketing";
              break;
          case 3:
              name_folder="club";
              break;
      }

      const self=this;

      return args.map(function(item,index){

          return self.GetTypeRouter(name_folder,item.path);

      });

    }


    GetTypeRouter(name,path) {

        const self = this;

        return(
            <>
                {ModuleRegister[name].map((currentValue, index) => {

                    if(currentValue.name===path){

                        var PathComponent = currentValue.path;
                        return (
                            <Route path={"/"+path} element={
                                self.GlobalAdmin(<PathComponent AppThis={self} />)
                            } />
                        );
                    }
                })}

                <Route path="*" element={<ErrorPage />} />
            </>
        );
    }

    RegisterModuleProfile(){
      const self=this;
      if(this.state.profile!==null){

          const type_profile=this.state.profile.type;

          if(Object.keys(this.state.profile.module).length>0){

              return Object.entries(this.state.profile.module).map(function(item,index){
                  return(self.RegisterSubModule(item[1],type_profile));
              });
          }
          else{
              return(<></>);
          }

      }
      return(<></>);
    }

  render() {
      const self=this;
      if(SupportBrowSerName.indexOf(browserName.toLowerCase())!==-1){

          if(this.state.isCloseTabs===true){
              return(
                  <div className={"col-12 mh-100vh w-100 d-flex bg-dark flex-wrap flex-row justify-content-center align-items-center"}>
                      <div className={"d-inline-block bg-white rounded-2 shadow text-center p-5 box-active-tab"}>
                          <h4 className={"text-uppercase font-weight-bold text-danger mb-3"}>Cảnh báo !</h4>
                          <p className={"font15"}>Bạn đang sử dụng ứng dụng trên một Tab khác. Nhấn kích hoạt để sử dụng trên Tab này ?</p>

                          <div className={"row mt-2"}>
                              <div className={"col-6 offset-3"}>
                                  <a href={"#"} onClick={()=>{
                                      location.reload(true);
                                  }} className={"btn btn-primary rounded-0 w-100"}>
                                      <i className="far fa-check-circle me-2" />  Kích hoạt
                                  </a>
                              </div>
                          </div>
                      </div>

                  </div>
              );
          }

          else{
              if(this.state.UrlRedirect!==null){

                  var GetUrlRedirect=this.state.UrlRedirect;

                  this.setState({
                      UrlRedirect:null
                  });

                  return(<Navigate to={GetUrlRedirect} replace={true}  />);
              }
              else{

                  return(
                      <div className="App">
                          {this.EventRedirect()}

                          {this.state.showMenu===true?(
                              <ul
                                  className="menuQuick bg-white shadow"
                                  style={{
                                      top: this.state.yPos,
                                      left: this.state.xPos,
                                  }} >
                                  <li className={"bg-dark text-white text-center text-uppercase"}>Kickfit Sports</li>
                                  <li onClick={()=>{
                                      this.SetUrlRedirect("/dashboard");
                                  }}><span><i className="fas fa-tachometer-alt me-1" /> Dashboard</span></li>
                                  <li onClick={()=>{
                                      this.SetUrlRedirect("/profile");
                                  }}><span><i className="far fa-user me-1" /> Thông tin tài khoản</span></li>
                                  <li onClick={()=>{
                                      this.SetUrlRedirect("/login");
                                  }}><span><i className="fas fa-sign-out-alt me-1" /> Đăng xuất</span></li>

                              </ul>
                          ):(<></>)}
                          {this.state.AppLoading===true?(
                              <div className={"App-Loading position-fixed w-100 mh-100vh d-flex justify-content-center align-items-center"}><div className={"Icon-App-Loading"}/>
                              </div>
                          ):(<></>)}
                          <Routes>
                              <Route path="/" element={<AppLoading AppThis={this} />} />
                              <Route path="/login" element={<Login AppThis={this} />} />
                              <Route path="/profile" element={
                                  self.GlobalAdmin(<Profile AppThis={self} />)
                              } />
                              <Route path="/dashboard" element={
                                  self.GlobalAdmin(<Dashboard AppThis={self} />)
                              } />

                              {this.RegisterModuleProfile()}
                          </Routes>

                          <ToastContainer />

                          {this.state.VerifyAlert!==null && window.location.pathname!==this.state.VerifyAlert.path?(
                              <div className={"position-fixed w-100 h-100 top-0 left0 VerifyAlert"}>

                                  <div className={"BgVerifyAlert w-100 h-100 position-absolute left0 top0"}></div>

                                  <div className={"ContentVerifyAlert shadow bg-white position-fixed p-4"}>
                                      <h3 className="font-weight-bold text-uppercase mb-2 font18">
                                          <i className="fas fa-exclamation-triangle me-2"/>
                                          Thông báo</h3>
                                      <div className={"col-12"}>{this.state.VerifyAlert.message}</div>
                                      <div className={"col-12 text-center"}>
                                          <a onClick={()=>{
                                              self.SetUrlRedirect(this.state.VerifyAlert.path);
                                          }} className={"btn btn-outline-danger rounded-0 w-100 mt-2"}>
                                              <i className="fas fa-link me-2" />
                                              Đi tới</a>
                                      </div>
                                  </div>
                              </div>
                          ):(<></>)}


                          {this.state.IsOnline===false?(
                              <div className={"position-fixed justify-content-center align-items-center no-internet left-0 top-0 w-100 h-100 text-white font16 rounded-0 p-2 d-flex flex-row"}>

                                  <div className={"col-12 w-100"}>
                                      <div className={"Section-AppLoading m-auto"}>
                                          <Lottie options={{
                                              animationData:nointernet,
                                              loop:true,
                                              autoplay:true
                                          }}  />
                                      </div>

                                      <div className={"text-center font16 font-weight-bold"}><p>Vui lòng kiểm tra lại đường truyền ...</p></div>
                                  </div>

                              </div>
                          ):(<></>)}
                      </div>
                  );

              }

          }

      }
      else{

          return(
              <SupportBrowser />
          );
      }





  }

}
