/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
     array_to_select,
    base64_encode, getItemInArray,
} from "../../helper/array";
import {toast} from "react-toastify";
import lodash from "lodash";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import Select from "react-select";
export default class EditGroupMember extends Component {

    constructor(props) {
        super(props);
        this.state={
            InfoGroupMember:null,
            DataUpdate:{},
            DataUser:null,
            step:1,
            StatusSave:false,
            TypeGroup:null
        };
    }

    componentDidMount() {

        if(this.props.data){

            var InfoGroupMember=lodash.cloneDeep(this.props.data);

            var DataUpdate={};

            var DataUser={};

            if(Object.keys(InfoGroupMember).length>0){

                ['name','code_group'].map(function(item){
                    DataUpdate[item]=InfoGroupMember[item];
                });

                DataUpdate['id_user']=InfoGroupMember['id_user']['_id'];

                DataUpdate['type_group']=InfoGroupMember['type_group']['_id'];

                DataUpdate['_id']=InfoGroupMember['_id'];

                DataUser=InfoGroupMember['id_user'];
            }
            else{

                DataUpdate={
                  name:null,
                  code_group:null,
                  id_user:null,
                  type_group:null
                };

                DataUser={
                    username:null,
                    birthday:null,
                    user_login:null,
                    email:null,
                    facebook:null,
                    citizen_identification:null,
                    address_contact:null,
                    sex:0,
                };

            }

            this.setState({
                InfoGroupMember:InfoGroupMember,
                DataUpdate:DataUpdate,
                DataUser:DataUser
            });

        }

        this.getTypeGroup();

        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }
    getTypeGroup(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;
        fetch(Appthis.ConstApp.home_api+"gtypegroup", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                self.setState({
                    TypeGroup:data.data
                });
            }



        }).catch((e)=>{


        });

    }


    UpdateDataUser(key,value){

        var DataUser=this.state.DataUser;

        DataUser[key]=value;

        this.setState({
            DataUser:DataUser
        });


        if(key==='user_login' && value.length===10){

            var phone=value.replace("_","");

            if(phone.length===10){
                this.getInfoUser(value);
            }

        }

    }

    VerifyStep1(){

        var number_error=0;

        var DataUpdate=this.state.DataUpdate;

        if(DataUpdate.name===null || DataUpdate.code_group===null){
            number_error+=1;
        }

        if(DataUpdate.type_group===null){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            this.setState({
                step:2
            });

        }
    }

    getInfoUser(phone) {

        const self=this;

        const formData = new FormData();

        var AppThis = this.props.AppThis;

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        formData.append('phone', phone);

        fetch(AppThis.ConstApp.home_api + this.props.path + "/user", {
            method: 'POST',
            body: formData
        }).then(data => data.json()).then(data => {

            if(data['status']==='ok'){

                if(Object.entries(data.data).length>0){

                    self.setState({
                        DataUser:data.data
                    });

                }

            }

            AppThis.PageLoad(false);

        }).catch((e) => {
            AppThis.PageLoad(false);
        });

    }

    async SaveUser(){

        const self=this;

        const formData = new FormData();

        var AppThis = this.props.AppThis;

        AppThis.PageLoad(true);

        formData.append('token', AppThis.getToken());

        formData.append('id_address', AppThis.getAddress());

        var DataUser=this.state.DataUser;

        var number_error=0;

        ['user_login','username'].map(function(item){

            if(DataUser[item]===null || DataUser[item].length===0){
                number_error+=1;
            }
            else{

                if(item ==='user_login' && DataUser['user_login'].length!==10){
                    number_error+=1;
                }


            }

        });

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{

            formData.append('data', base64_encode(DataUser));

            AppThis.PageLoad(true);

            fetch(AppThis.ConstApp.home_api + this.props.path + "/updateuser", {
                method: 'POST',
                body: formData
            }).then(data => data.json()).then(data => {

                if(data['status']==='ok'){

                    var DataUpdate=self.state.DataUpdate;

                    DataUpdate['id_user']=data.data;

                    self.setState({
                        StatusSave:true,
                        DataUpdate:DataUpdate
                    },function(){
                        self.SaveDataGroupMember();
                    });


                }
                else{
                    toast("Có lỗi trong quá trình xử lý.Xin vui lòng thử lại sau");
                }

                AppThis.PageLoad(false);

            }).catch((e) => {
                AppThis.PageLoad(false);
            });


        }



    }

    SaveDataGroupMember(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        var DataUpdate=this.state.DataUpdate;

        formData.append('data',base64_encode(DataUpdate));

        const self=this;
        fetch(Appthis.ConstApp.home_api+this.props.path+"/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Thao tác thành công");
            }
            else{
                toast("Thao tác thất bại");
            }

                self.props.onClose();

                Appthis.PageLoad(false);


        }).catch((e)=>{

            toast("Thao tác thất bại");

             Appthis.PageLoad(false);


        });
    }

    async SaveGroupMember(){

        await this.SaveUser();

    }

    UpdateGroupMember(){

        const self=this;

        if(this.state.step===1){
            return(
                <div className={"col-12 pb-3 box_input"}>

                    <p className="font16 font-weight-bold text-uppercase">Cập nhật thông tin nhóm</p>

                    <div className={"row"}>

                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                            <label>Tên nhóm : <span className={"text-red"}>( * )</span></label>
                            <input onChange={(event)=>{
                                var DataUpdate=this.state.DataUpdate;
                                DataUpdate.name=event.target.value;
                                this.setState({
                                    DataUpdate:DataUpdate
                                })
                            }} defaultValue={this.state.InfoGroupMember.name?this.state.InfoGroupMember.name:''} type={"text"} placeholder={"Tên nhóm"} className={"mt-2 form-control w-100"} />
                        </div>


                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                            <label>Mã nhóm : <span className={"text-red"}>( * )</span></label>
                            <input onChange={(event)=>{
                                var DataUpdate=this.state.DataUpdate;
                                DataUpdate.code_group=event.target.value;
                                this.setState({
                                    DataUpdate:DataUpdate
                                })
                            }} type={"text"} defaultValue={this.state.InfoGroupMember.code_group?this.state.InfoGroupMember.code_group:''} placeholder={"Mã nhóm"} className={"mt-2 form-control w-100"} />
                        </div>



                        <div className={"col-12 col-sm-12 col-md-6 mb-3 Box-Select-Mutiple"}>
                            <label className={"mb-2"}>Loại nhóm : <span className={"text-red"}>( * )</span></label>
                            {this.state.TypeGroup!==null?(
                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                    var DataUpdate=this.state.DataUpdate;
                                    DataUpdate.type_group=selectedOption.value;
                                    this.setState({
                                        DataUpdate:DataUpdate
                                    });

                                }} defaultValue={this.state.DataUpdate.type_group?getItemInArray(this.state.DataUpdate.type_group,"value",array_to_select(this.state.TypeGroup,'name','_id')):''}  options={array_to_select(this.state.TypeGroup,'name','_id')} />

                            ):(
                                <ItemLoading />
                            )}

                        </div>


                        <div className={"col-12 col-sm-6 col-md-4 offset-md-8 mt-3 mb-3"}>
                            <a onClick={()=>{
                                this.VerifyStep1();
                            }} className={"btn btn-danger rounded-0 w-100"}>
                                <i className="fas fa-caret-right me-2"></i>  Tiếp theo
                            </a>
                        </div>



                    </div>

                </div>
            );
        }
        else{
            return(
                <div className={"col-12 pt-3 pb-3 box_input"}>

                    <div className={"row"}>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">SDT/ID <span className="text-red">( * )</span></label>

                                {<InputMask mask="9999999999" onChange={event=>{
                                    self.UpdateDataUser("user_login",event.target.value);
                                }} key={"user_login_modal"}  defaultValue={this.state.DataUser.user_login} />}

                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Họ và tên <span className="text-red">( * )</span></label>
                                <input type="text" onChange={(event)=>{
                                    this.UpdateDataUser('username',event.target.value);
                                }} key={'username'} defaultValue={this.state.DataUser.username} placeholder="Họ và tên" required="required" className="form-control w-100" />
                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Email</label>
                                <input type="email" onChange={(event)=>{
                                    this.UpdateDataUser('email',event.target.value);
                                }} key={'email_modal'} defaultValue={this.state.DataUser.email} placeholder="Email" required="required" className="form-control w-100" />
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Sinh nhật</label>

                                {this.state.DataUser.birthday && true?(
                                    <DatePicker maxDate={new Date(new Date().getFullYear() - 5, 0)} dateFormat="dd/MM/yyyy" selected={new Date(this.state.DataUser.birthday*1000)} onChange={(date) => {
                                        this.UpdateDataUser('birthday',date.getTime()/1000);
                                    }} />

                                ):(
                                    <DatePicker  dateFormat="dd/MM/yyyy" selected={new Date()} onChange={(date) => {
                                        this.UpdateDataUser('birthday',date.getTime()/1000);
                                    }} />

                                )}


                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Giới tính <span className="text-red">( * )</span></label>

                                {this.state.DataUser.sex===1?(
                                    <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateDataUser("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" checked="checked"  /> Nam</span>
                                        <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateDataUser("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" /> Nữ</span>
                                    </div>
                                ):(
                                    <></>
                                )}

                                {this.state.DataUser.sex===0?(
                                    <div className="col-12 p-0 mt-2 d-flex flex-row">
                                <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateDataUser("sex",1);
                                    }} type="radio" id="sex" name="sex" value="1" /> Nam</span>
                                        <span className={"w50px d-flex flex-row align-items-center"}>
                                    <input onChange={()=>{
                                        self.UpdateDataUser("sex",0);
                                    }} type="radio" id="sex" name="sex" value="0" checked="checked" /> Nữ</span>
                                    </div>
                                ):(
                                    <></>
                                )}



                            </div>


                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Địa chỉ liên hệ
                                </label>
                                <input type="text" onChange={(event)=>{
                                    self.UpdateDataUser("address_contact",event.target.value);
                                }} key={'address_contact_modal'} defaultValue={this.state.DataUser.address_contact} placeholder="Địa chỉ liên hệ" required="required" className="form-control w-100" />
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">CMTND/CCCD
                                </label>
                                <input type="text" onChange={(event)=>{
                                    self.UpdateDataUser("citizen_identification",event.target.value);
                                }} key={'citizen_identification_modal'}  defaultValue={this.state.DataUser.citizen_identification} placeholder="CNND/CCCD" required="required" className="form-control w-100" />
                            </div>

                            <div className={"col-12 col-sm-12 col-md-6 pb-3"}>
                                <label className="font-weight-400 mb-1">Mã số thẻ</label>
                                <input onChange={event=>{
                                    self.UpdateDataUser("card_user",event.target.value);
                                }} type={"text"} key={"card_user_modal"} placeholder={"Mã số thẻ"} defaultValue={this.state.DataUser.card_user} />
                            </div>



                            <div className={"col-12 col-sm-6 col-md-4 mt-3 mb-3"}>
                                <a onClick={()=>{
                                    this.SaveGroupMember();
                                }} className={"btn btn-danger rounded-0 w-100"}>
                                    <i className="fas fa-save me-2"></i> Cập nhật
                                </a>
                            </div>



                    </div>

                </div>
            );
        }

    }


    render(){
        const self=this;
        return(
            <div className="modal d-block UpdateGroupStatics"  data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title font16 text-uppercase font-weight-bold">Cập nhật nhóm doanh nghiệp</h5>

                            <a onClick={()=>{
                                this.props.onClose();
                            }} ><i className="far text-white font26 fa-times-circle" /></a>

                        </div>
                        <div className="modal-body">

                            {this.state.InfoGroupMember!==null?(
                                this.UpdateGroupMember()
                            ):(
                                <div className={"col-12 pt-4 mb-3 text-center d-flex flex-row justify-content-center"}>
                                    <ItemLoading />
                                </div>
                            )}


                        </div>

                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

            </div>
        );
    }

}
