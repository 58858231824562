/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "../ItemLoading";
import {
   base64_encode, array_to_select, ArrayInArray, get_data_in_args, defaultValueObject
} from "../../helper/array";
import TimePicker from 'react-time-picker';
import {toast} from "react-toastify";
import Select from "react-select";
import lodash from "lodash";

export default class EditGroupStatics extends Component {

    constructor(props) {
        super(props);
        this.state={
            InfoGroupStatics:null,
            DataUpdate:[],
            ListAdmin:null,
            ListDays:[
                {
                    label:"Thứ 2",
                    value:1
                },
                {
                    label:"Thứ 3",
                    value:2
                },
                {
                    label:"Thứ 4",
                    value:3
                },
                {
                    label:"Thứ 5",
                    value:4
                },
                {
                    label:"Thứ 6",
                    value:5
                },
                {
                    label:"Thứ 7",
                    value:6
                },
                {
                    label:"Chủ nhật",
                    value:7
                }
            ]
        };
    }

    componentDidMount() {

        if(this.props.data){

            var props_data=lodash.cloneDeep(this.props.data);

            var DataUpdate=lodash.cloneDeep(this.props.data);

            if(DataUpdate['id_admin_pt']!==null && typeof DataUpdate['id_admin_pt']!=='undefined' && Object.keys(DataUpdate['id_admin_pt']).length>0){

                DataUpdate['id_admin_pt']=DataUpdate['id_admin_pt']['_id'];

            }

            this.setState({
                InfoGroupStatics:props_data,
                DataUpdate:DataUpdate
            });
        }

        this.getAdmin();

        document.addEventListener("keydown", this.KeyEventFunction);

    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }


    getAdmin(){

        var Appthis=this.props.AppThis;

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/getadmin", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){

                self.setState({
                    ListAdmin:data.data
                });

            }


        }).catch((e)=>{

        });


    }

    SaveGroupData(){

        var data_update=this.state.DataUpdate;

        var number_error=0;

        ['name','code_group','days','time_start','time_end','id_admin_pt'].map(function(item){

            if(data_update[item]===null || typeof data_update[item]==='undefined'){

                number_error+=1;

            }
        });

        if(number_error>0){
            toast("Vui lòng kiểm tra lại");
            return;
        }

        var Appthis=this.props.AppThis;

        Appthis.PageLoad(true);

        const formData = new FormData();

        formData.append('token', Appthis.getToken());

        formData.append('id_address', Appthis.getAddress());

        formData.append('data', base64_encode(data_update));

        const self=this;

        fetch(Appthis.ConstApp.home_api+this.props.path+"/update", {
            method: 'POST',
            mode: 'cors',
            body: formData
        }).then(data=>data.json()).then(data=>{

            if(data.status==='ok'){
                toast("Thao tác thành công");
            }
            else{
                toast("Thao tác thất bại");
            }

            Appthis.PageLoad(false);

            self.props.onClose();

        }).catch((e)=>{

            toast("Thao tác thất bại");

            self.props.onClose();

            Appthis.PageLoad(false);
        });



    }

    render(){
        const self=this;
        return(
            <div className="modal d-block UpdateGroupStatics"  data-bs-backdrop="static" data-bs-keyboard="false"
                 tabIndex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title font16 text-uppercase font-weight-bold">Cập nhật nhóm CD</h5>

                            <a onClick={()=>{
                                this.props.onClose();
                            }} ><i className="far text-white font26 fa-times-circle" /></a>

                        </div>
                        <div className="modal-body">

                            {this.state.InfoGroupStatics!==null?(
                                <div className={"col-12 pt-3 pb-3 box_input"}>

                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                                            <label>Tên nhóm : <span className={"text-red"}>( * )</span></label>
                                            <input onChange={(event)=>{
                                                var data=this.state.DataUpdate;
                                                data.name=event.target.value;
                                                this.setState({
                                                    data:data
                                                })
                                            }} defaultValue={this.state.InfoGroupStatics.name?this.state.InfoGroupStatics.name:''} type={"text"} placeholder={"Tên nhóm"} className={"mt-2 form-control w-100"} />
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 mb-3"}>
                                            <label>Mã nhóm : <span className={"text-red"}>( * )</span></label>
                                            <input onChange={(event)=>{
                                                var data=this.state.DataUpdate;
                                                data.code_group=event.target.value;
                                                this.setState({
                                                    data:data
                                                })
                                            }} type={"text"}  defaultValue={this.state.InfoGroupStatics.code_group?this.state.InfoGroupStatics.code_group:''} placeholder={"Mã nhóm"} className={"mt-2 form-control w-100"} />
                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 mb-3 Box-Select-Mutiple"}>
                                            <label className={"d-block w-100 mb-2"}>Huấn Luyện Viên : <span className={"text-red"}>( * )</span></label>

                                            {self.state.ListAdmin!=null?(
                                                <Select isSearchable={true} onChange={(selectedOption)=>{
                                                    var data=self.state.DataUpdate;
                                                    data.id_admin_pt=selectedOption.value;
                                                    self.setState({
                                                        data:data
                                                    });

                                                }} defaultValue={defaultValueObject(self.state.InfoGroupStatics.id_admin_pt,"username","_id")} options={array_to_select(this.state.ListAdmin,"value","key")} />
                                            ):(
                                                <ItemLoading />
                                            )}

                                        </div>


                                        <div className={"col-12 col-sm-12 col-md-6 mb-3 Box-Select-Mutiple"}>
                                            <label className={"mb-2"}>Ngày tập : <span className={"text-red"}>( * )</span></label>

                                            {this.state.DataUpdate.days?(
                                                <Select isMulti={true} isSearchable={true} onChange={(selectedOption)=>{
                                                    var DataUpdate=self.state.DataUpdate;
                                                    DataUpdate['days']=get_data_in_args(selectedOption,"value");
                                                    self.setState({
                                                        DataUpdate:DataUpdate
                                                    });
                                                }} defaultValue={ArrayInArray(this.state.DataUpdate.days,this.state.ListDays)} options={this.state.ListDays} />

                                            ):(<ItemLoading/>)}

                                        </div>


                                        <div className={"col-6 col-sm-6 col-md-6 mb-3"}>
                                            <label className={"w-100 d-block mb-2"}>Thời gian bắt đầu : <span className={"text-red"}>( * )</span></label>

                                            <TimePicker onChange={(onChange)=>{
                                                var DataUpdate=this.state.DataUpdate;
                                                DataUpdate['time_start']=onChange;
                                                this.setState({
                                                    DataUpdate:DataUpdate
                                                });

                                            }} value={this.state.InfoGroupStatics.time_start?this.state.InfoGroupStatics.time_start:""} />

                                        </div>


                                        <div className={"col-6 col-sm-6 col-md-6 mb-3"}>
                                            <label className={"w-100 d-block mb-2"}>Thời gian kết thúc : <span className={"text-red"}>( * )</span></label>

                                            <TimePicker onChange={(onChange)=>{
                                                var DataUpdate=this.state.DataUpdate;
                                                DataUpdate['time_end']=onChange;
                                                this.setState({
                                                    DataUpdate:DataUpdate
                                                });

                                            }} value={this.state.InfoGroupStatics.time_end?this.state.InfoGroupStatics.time_end:""} />


                                          </div>

                                        <div className={"col-12 col-sm-6 col-md-4 mt-3 mb-3"}>
                                            <a onClick={()=>{
                                                self.SaveGroupData();
                                            }} className={"btn btn-danger rounded-0 w-100"}>
                                                <i className="far fa-save me-2" />  Cập nhật
                                            </a>
                                        </div>





                                    </div>

                                </div>
                            ):(
                                <div className={"col-12 pt-4 mb-3 text-center d-flex flex-row justify-content-center"}>
                                    <ItemLoading />
                                </div>
                            )}


                        </div>

                    </div>
                </div>

                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />

            </div>
        );
    }

}
