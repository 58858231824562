/* eslint-disable */
import React,{Component} from "react";
import ItemLoading from "./ItemLoading";
import lodash from "lodash";
import {
    _isset_object,
    _isset_string,
    array_to_select,
    get_data_in_args,
    itemExitObject
} from "../helper/array";
import Select from 'react-select';
import {toast} from "react-toastify";

export default class ModalReport extends Component{

    constructor(props) {
        super(props);
        this.state={
            DataModal:null
        };

    }

    componentDidMount() {
        document.addEventListener("keydown", this.KeyEventFunction);
    }

    KeyEventFunction=(event)=>{
        if (event.key === "Escape") {
            if(typeof this.props!=='undefined' && typeof this.props.onClose!=='undefined'){
                this.props.onClose();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.KeyEventFunction);
    }

    RemoveDuplicate(args1,args2){

        var args3=[];


        args2.map(function(item){

            if(itemExitObject(item.label,"name",args1)===false){
                args3.push(item);
            }

        });

        return args3;
    }

    renderData(item){

        const self=this;

        let data=item;

        if(this.state.DataModal===null){

            const DataModal=lodash.cloneDeep(data);

            if(_isset_object(data.id_address)){
                DataModal['id_address']=get_data_in_args(DataModal.id_address,'_id');
            }
            else{
                DataModal['id_address']=[];
            }

            this.setState({
                DataModal:DataModal
            });
        };


        if(this.state.DataModal!==null){
            return(
                <div className={"row m-0"}>

                    <div className={"col-12 col-sm-12 col-md-12 mb-3"}>
                        <label className={"d-block mb-1"}>Địa chỉ
                            <span className={"text-red"}>( * )</span>
                        </label>
                        {this.props.AllAddress?(
                            <Select isMulti={true} isSearchable={true} onChange={(selectedOption)=>{
                                var save_id_address=[];
                                selectedOption.map(function(value){
                                    save_id_address.push(value.value);
                                });
                                var DataModal=this.state.DataModal;
                                DataModal.id_address=save_id_address;
                                this.setState({
                                    DataModal:DataModal
                                });

                            }} defaultValue={array_to_select(data.id_address,'name','_id')} options={this.RemoveDuplicate(data.id_address,array_to_select(this.props.AllAddress,"name","_id"))}  />
                        ):(
                            <ItemLoading />
                        )}

                    </div>
                    <div className={"col-12 col-sm-12 col-md-12 mb-3"}>
                        <label className={"d-block mb-1"}>Loại báo cáo <span className={"text-red"}>( * )</span></label>

                        <select onChange={(event)=>{
                            var DataModal=this.state.DataModal;
                            DataModal.name_report=event.target.value;
                            this.setState({
                                DataModal:DataModal
                            });
                        }} defaultValue={this.state.DataModal.name_report} className={"form-control w-100 font13 rounded-0"}>

                            <option value={''}>Vui lòng chọn báo cáo</option>
                            {this.props.AllNameReport.map(function(index){
                                return(
                                    <optgroup label={index.name}>
                                        {Object.entries(index['group']).map(function(value,key){
                                            if(self.state.DataModal.name_report && self.state.DataModal.name_report===value[0]){
                                                return(<option selected={true} value={value[0]}>{value[1]}</option>);
                                            }
                                            else{
                                                return(<option value={value[0]}>{value[1]}</option>);
                                            }
                                        })}
                                    </optgroup>
                                );
                            })}
                        </select>

                    </div>
                    <div className={"col-12 col-sm-12 col-md-12 mb-3"}>
                        <label className={"d-block mb-1"}>Tài khoản <span className={"text-red"}>( * )</span></label>

                        <select onChange={(event)=>{
                            var DataModal=this.state.DataModal;
                            DataModal.id_admin=event.target.value;
                            this.setState({
                                DataModal:DataModal
                            });
                        }} defaultValue={this.state.DataModal.id_admin} className={"form-control w-100 font13 rounded-0"}>
                            <option value={''}>Chọn tài khoản</option>
                            {self.props.AllAdmin.map(function(value){

                                if(_isset_string(self.state.DataModal.id_admin) && self.state.DataModal.id_admin===value.key){
                                    return(
                                        <option selected={true} value={value.key}>{value.value}</option>
                                    );
                                }
                                else{
                                    return(<option value={value.key}>{value.value}</option>);
                                }

                            })}
                        </select>

                    </div>

                </div>
            );
        }
        else{
            return(
                <ItemLoading />
            );
        }

    }

    SubmitModal(){

        if(this.state.DataModal===null){
            return;
        }

        const self=this;

        var number_error=0;

        ['id_admin','name_report'].map(function(item){

            if(self.state.DataModal[item]===null || !_isset_string(self.state.DataModal[item]) || self.state.DataModal[item].length<5){
                number_error+=1;
            }
        });

        if(this.state.DataModal.id_address.length===0){
            number_error+=1;
        }

        if(number_error>0){
            toast("Vui lòng nhập đầy đủ thông tin");
        }
        else{
            this.props.GetDataSubmit(this.state.DataModal);
        }

    }

    render() {

        return(
            <div className="modal d-block position-fixed">
                <div className={"modal-dialog modal-md"}>
                    <div className="modal-content shadow border-0">
                        <div className="modal-header">
                            <strong className={"font18 text-uppercase"}>Báo cáo tự động</strong>
                        </div>
                        <div className="modal-body">

                            <div className={"row mb-2 mt-2"}>

                                {this.props.data!==null?(this.renderData(this.props.data)):(
                                    <div className={"col-12 justify-content-center align-content-center d-flex"}>
                                        <ItemLoading />
                                    </div>
                                )}

                            </div>



                        </div>

                        <div className={"modal-footer bg-light"}>

                            <div className={"w-100 row"}>

                                <div className={"col-12 col-sm-12 col-lg-8 offset-lg-4"}>


                                    <div className={"row"}>

                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.SubmitModal()
                                            }} className={"btn btn-primary w-100 text-white mt-3"} >
                                                Cập nhật
                                            </a>
                                        </div>
                                        <div className={"col-12 col-sm-12 col-lg-6"}>
                                            <a onClick={()=>{
                                                this.props.onClose()
                                            }} className={"btn btn-danger w-100 text-white mt-3"} >
                                                Huỷ
                                            </a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div className={"bg-modal position-fixed w-100 vh-100 top0 left0"} />
            </div>
        );


    }

}
